import I18n, { I18nEventTypes, I18nInitOptions } from 'packages/i18n'
import { setLocale } from 'packages/utils/dateHelpers'

export { useI18n } from 'packages/i18n'
export { default as Slugs } from './translations/slugs'

const i18n = new I18n()
export const resources = {
  cs: require('./translations/cs.json'),
  en: require('./translations/en-US.json'), // Default English translations
  'en-US': require('./translations/en-US.json'),
  es: require('./translations/es-419.json'), // Default Spanish translation
  'es-419': require('./translations/es-419.json'),
  'es-ES': require('./translations/es-ES.json'),
  fr: require('./translations/fr-FR.json'), // Default French translation
  'fr-FR': require('./translations/fr-FR.json'),
  hu: require('./translations/hu.json'),
  it: require('./translations/it-IT.json'), // Default Italian translation
  'it-IT': require('./translations/it-IT.json'),
}

export const options: I18nInitOptions = {
  fallbackLng: 'en',
  resources,
}

/**
 * Update date-fns when locale changes
 */
const setDateLocale = (locale: string) => {
  setLocale(locale)
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const initI18n = async () => {
  i18n.subscribe(I18nEventTypes.languageChanged, setDateLocale)
  return await i18n.init(options)
}
