import { createAsyncThunk } from '@reduxjs/toolkit'

import { RequestOptions } from 'packages/utils/store/jsonapi.types'

import { crossCoverageService } from '../crossCoverage.service'
import { CrossCoverageApiFields } from '../crossCoverage.types'

export const getParams = (): RequestOptions => ({
  fields: {
    crossCoverage: CrossCoverageApiFields,
  },
  include: ['user_covering'],
})

export const fetchCrossCoverageById = createAsyncThunk(
  'crossCoverage/api/fetchCrossCoverageById',
  async (crossCoverageId: string) => {
    const params = getParams()
    const response = await crossCoverageService.fetchCrossCoverageById(
      crossCoverageId,
      params,
    )

    return response
  },
)
