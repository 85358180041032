import { RequestOptions } from 'packages/utils/store/jsonapi.types'

import { api } from '../axiosInstance'
import {
  AssignmentsApiResponse,
  AssignmentsServiceResponse,
} from './assignments.types'
import { emptyAssignmentsResponse } from './assignments.utils'

const URL = '/assignments'

export const assignmentsService = {
  async updateAssignment(
    assignmentId: string,
    patchData: RequestOptions,
  ): AssignmentsServiceResponse {
    const response: AssignmentsApiResponse = await api.patch(
      `${URL}/${assignmentId}`,
      patchData,
    )

    return response?.data || emptyAssignmentsResponse
  },
}
