import { AxiosResponse } from 'axios'
import { difference, snakeCase } from 'lodash/fp'

import { AllUnitAttributeNames, AllUnitAttributes } from 'packages/grimoire'
import {
  JSONApiObjectMap,
  JSONApiObjectWithRelationships,
  JSONApiObjectWithRelationshipsMap,
  NormalizedJSONApiResponse,
  ToManyRelationship,
  ToOneRelationship,
} from 'packages/utils/store'

import { JSONApiHousekeepersMap } from '../housekeepers'
import { JSONApiInspectionFlagMap } from '../inspectionFlags/inspectionFlags.types'
import { JSONApiReservationsMap } from '../reservations'
import { JSONApiTicketsMap } from '../tickets'
import { JSONApiUsersMap } from '../users'
import { JSONApiVisitMap } from '../visits'

export type { Amenities } from 'packages/grimoire/src/unit'
export {
  BathAmenities,
  BedAmenities,
  HotTubAmenities,
  NetworkAmenities,
  OrderedBathAmenities,
  OrderedBedAmenities,
} from 'packages/grimoire/src/unit'

/**********************************************************
 * SERVICE TYPES
 *********************************************************/
export type UnitResponse = {
  housekeeper: JSONApiHousekeepersMap
  inspectionFlag?: JSONApiInspectionFlagMap
  reservation: JSONApiReservationsMap
  task: JSONApiVisitMap
  ticket: JSONApiTicketsMap
  unit: JSONApiUnitsMap
  user: JSONApiUsersMap
}

export type NormalizedUnitsApiResponse = NormalizedJSONApiResponse<UnitResponse>

export type UnitsApiResponse = AxiosResponse<NormalizedUnitsApiResponse>
export type UnitsServiceResponse = Promise<NormalizedUnitsApiResponse>

export type UnitSearchApiResponse =
  AxiosResponse<NormalizedUnitSearchApiResponse>

export type UnitSearchServiceResponse = Promise<NormalizedUnitSearchApiResponse>

export type NormalizedUnitSearchApiResponse =
  NormalizedJSONApiResponse<UnitsSearchResults>

/**********************************************************
 * REDUX TYPES
 *********************************************************/
export type UnitsState = {
  data: JSONApiUnitsMap
  searchResults: UnitsSearchResults
}

export type UnitSearchRelationships = {
  zone: ToOneRelationship
}

export type UnitsSearchResults = {
  unit: JSONAPIUnitSearchMap
  zone: JSONApiObjectMap<{ name: string }>
}

export enum UnitsActionTypes {
  FETCH_UNIT_BY_ID = 'UNITS/API/FETCH_UNIT_BY_ID',
  FETCH_UNIT_BY_ID_FAILURE = 'UNITS/FETCH_UNIT_BY_ID_FAILURE',
  FETCH_UNIT_BY_ID_SUCCESS = 'UNITS/FETCH_UNIT_BY_ID_SUCCESS',

  SEARCH_UNITS = 'UNITS/API/SEARCH_UNITS',
  SEARCH_UNITS_FAILURE = 'UNITS/SEARCH_UNITS_FAILURE',
  SEARCH_UNITS_SUCCESS = 'UNITS/SEARCH_UNITS_SUCCESS',
}

/**********************************************************
 * UNIT
 *********************************************************/
export type UnitManagerInfo = {
  firstName: string
  lastName: string
  mobilePhone: string
}

export type Unit = {
  currentResId: string | null
  id: string
  managerInfo: UnitManagerInfo
  nextResId: string | null
  openTicketIds: string[]
  openVisitIds: string[]
  previousResId: string | null
  unitFamilyIds?: string[]
  zone: { id: string }
} & UnitAttributes

export type SearchUnit = {
  id: string
  zone: { id: string; name: string }
} & UnitSearchAttributes

export type CreateTicketDrawerUnit = Unit | SearchUnit

export type UnitAttributes = Omit<
  AllUnitAttributes,
  'isActive' | 'nextDeepCleanDueAt'
> & {
  managerInfo: UnitManagerInfo
}

export type UnitSearchAttributes = Pick<
  UnitAttributes,
  'address' | 'city' | 'name' | 'state' | 'unitCode'
>

export const AllFAUnitAttributeNames = [...AllUnitAttributeNames, 'managerInfo']

export const UnitAttributeNames = difference(AllFAUnitAttributeNames)([
  'isActive',
  'nextDeepCleanDueAt',
  'rateContractorDeepClean',
  'rateContractorPostStay',
])

export const UnitApiFields: string[] = UnitAttributeNames.map(snakeCase)

export type UnitRelationships = {
  currentReservation: ToOneRelationship
  lockBox: ToOneRelationship
  nextReservation: ToOneRelationship
  openTickets: ToManyRelationship
  openVisits: ToManyRelationship
  previousReservation: ToOneRelationship
  unitFamily: ToOneRelationship
  zone: ToOneRelationship
}

export type RawUnit = JSONApiObjectWithRelationships<
  UnitAttributes,
  UnitRelationships
>

export type JSONApiUnitsMap = JSONApiObjectWithRelationshipsMap<
  UnitAttributes,
  UnitRelationships
>

export type JSONAPIUnitSearchMap = JSONApiObjectWithRelationshipsMap<
  UnitSearchAttributes,
  UnitSearchRelationships
>
