import classNames from 'classnames'
import upperFirst from 'lodash/upperFirst'
import * as React from 'react'
import { useSelector } from 'react-redux'

import { distanceToNowLocalized } from 'packages/utils/dateHelpers'
import { useOnlineStatus } from 'packages/utils/hooks/useOnlineStatus'

import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { fanOutDataBatchRequests } from 'app/fieldapp/store/app/actions'
import { useAppDispatch } from 'app/fieldapp/store/hooks'
import { getCoreDataIsLoading } from 'app/fieldapp/store/utils/selectors/getCoreDataIsLoading'

import styles from './LastFetchBanner.module.scss'

// the interval at which we will update the "last updated" string
// we probably want this to be just under a minute, so we can ensure we have a fairly accurate readout
const FETCH_STRING_UPDATE_INTERVAL = 55 * 1000

export type LastFetchBannerProps = {
  /** (Optional) Additional class name to apply to the component */
  className?: string

  /**
   * The timestamp of the last successful fetch.
   * This will be used to parse the "last updated xxx ago" string.
   * If this value is falsey, the component will assume there has been a successful
   * fetch, and thus will render nothing at all.
   */
  lastFetch: number
}

/**
 * A small component to display the time since the user last made a successful GET request
 * to fetch. Something like "Last updated 5 minutes ago"
 *
 * This will be useful particularly when the user is offline, to remind them if their last
 * fetch was a long time ago, as there may be potential for their data to be stale.
 *
 * The component uses setInterval to ensure the time string gets updated regularly,
 * and when the user is online, there will be an "Update" link to allow them to force a re-fetch.
 */
export const LastFetchBanner: React.FC<LastFetchBannerProps> = ({
  className = '',
  lastFetch,
}) => {
  const dispatch = useAppDispatch()
  const { t, ut } = useI18n()
  const isOnline = useOnlineStatus().isOnline()

  const [lastFetchTimestamp, setLastFetchTimestamp] = React.useState(lastFetch)
  const [lastFetchString, setLastFetchString] = React.useState('')

  const isLoading = useSelector(getCoreDataIsLoading)

  // this will be used to keep track of the setInterval instance, so we can remove it in useEffect
  const updateLastFetchIntervalRef = React.useRef<any>(0) // eslint-disable-line

  React.useEffect(
    () => {
      if (lastFetch && lastFetch !== lastFetchTimestamp) {
        setLastFetchTimestamp(lastFetch)
      }
    },
    // eslint-disable-next-line
    [lastFetch],
  )

  // when our local "last fetch" data changes, parse a new string from it
  React.useEffect(
    () => {
      const updateLastFetchString = () => {
        if (lastFetchTimestamp === 0) return

        const newLastFetchString = distanceToNowLocalized(lastFetchTimestamp)

        if (newLastFetchString !== lastFetchString) {
          setLastFetchString(newLastFetchString)
        }
      }

      // call the update function once immediately, then set up the interval
      updateLastFetchString()
      updateLastFetchIntervalRef.current = setInterval(
        updateLastFetchString,
        FETCH_STRING_UPDATE_INTERVAL,
      )

      return () => {
        clearInterval(updateLastFetchIntervalRef.current)
      }
    },
    // eslint-disable-next-line
    [lastFetchString, lastFetchTimestamp],
  )

  const shouldRender = isLoading || lastFetchString
  const updatingText = ut(Slugs.updating)
  const lastUpdatedText = `${t(Slugs.lastUpdated)} ${lastFetchString}`

  const handleUpdateClick = React.useCallback(() => {
    dispatch(fanOutDataBatchRequests())
  }, [dispatch])

  return shouldRender ? (
    <div className={classNames(styles.lastFetchBanner, className)}>
      {isLoading ? updatingText : lastUpdatedText}
      {!isLoading && isOnline && (
        <span
          className={styles.updateLink}
          data-testid={'update-timeline-button'}
          onClick={handleUpdateClick}
        >
          {upperFirst(t(Slugs.update))}
        </span>
      )}
    </div>
  ) : null
}
