import { convertFileToDataUrl, generateImgElement } from 'packages/utils/misc'

export const MAX_FILE_SIZE_MB = 0.1

/* eslint-disable @typescript-eslint/naming-convention */
// field definitions for Segment tracking of compression-related stats
// if no compression occurs, we will send these all to Segment as null values
type CompressionStats = {
  compressed_file_size: number | null
  compressed_height: number | null
  compressed_width: number | null
  compression_time_ms: number | null
}

const nullStats: CompressionStats = Object.freeze({
  compressed_file_size: null,
  compressed_height: null,
  compressed_width: null,
  compression_time_ms: null,
})
/* eslint-enable @typescript-eslint/naming-convention */

type ImgCompressionData = {
  compressionStats: CompressionStats
  fileForUpload: File
  imgElements: {
    compressed?: HTMLImageElement
    original: HTMLImageElement
  }
}

/**
 * Inspects the provided Image data, and it meets the requirements for
 * needing compression before uploading, processes said compression and
 * returns the compressed Image data.
 *
 * If no compression is necessary, the original Image is returned directly as 'fileForUpload'.
 *
 * In the return value:
 * - 'compressionStats' - data for tracking the compression process, if any (all values null if no compression occurs)
 * - 'fileForUpload' - the File data to use for uploading; either the original image
 *      if no compression is required, or the compressed, or the compressed one if it is
 * - 'imgElements' - HtmlImgElements for original and compressed (when applicable); these can
 *      be used for operations that the File object do not provide (e.g. measuring height/width)
 *
 * @param originalImage
 */
export const compressImageIfNecessary = async (
  originalImage: File,
): Promise<ImgCompressionData> => {
  const origImgDataUrl = await convertFileToDataUrl(originalImage)
  const origImgElement = await generateImgElement(origImgDataUrl)

  // if the original image's file size is okay, return early with original image
  if (originalImage.size <= MAX_FILE_SIZE_MB) {
    return {
      compressionStats: nullStats,
      fileForUpload: originalImage,
      imgElements: {
        original: origImgElement,
      },
    }
  }

  // otherwise, compress the image based on max width/height constraints
  try {
    let compressImage
    await import('browser-image-compression')
      .then(bic => {
        compressImage = bic.default
      })
      .catch(err => {
        throw new Error(
          `Error loading img compression library: ${err?.message}`,
        )
      })

    const compressionStartTime = Date.now() // eslint-disable-line
    const compressedImage: File = await compressImage(originalImage, {
      maxIteration: 30,
      maxSizeMB: MAX_FILE_SIZE_MB,
      maxWidthOrHeight: 1280,
    })
    const compressionDuration = Date.now() - compressionStartTime // eslint-disable-line

    const compressedImgDataUrl = await convertFileToDataUrl(compressedImage)
    const compressedImgElement = await generateImgElement(compressedImgDataUrl)

    /* eslint-disable @typescript-eslint/naming-convention */
    return {
      compressionStats: {
        compressed_file_size: compressedImage.size,
        compressed_height: compressedImgElement.height,
        compressed_width: compressedImgElement.width,
        compression_time_ms: compressionDuration,
      },
      fileForUpload: compressedImage,
      imgElements: {
        compressed: compressedImgElement,
        original: origImgElement,
      },
    }
    /* eslint-enable @typescript-eslint/naming-convention */
  } catch (err) {
    throw new Error(`Error compressing photo: ${err?.message}`)
  }
}
