import { noop } from 'lodash/fp'
import { createAsyncAction } from 'typesafe-actions'

import { ReduxActionCallbacks } from 'packages/grimoire/src/utils'
import {
  createDateObject,
  DateFormat,
  format,
} from 'packages/utils/dateHelpers'
import {
  makeRelationship,
  RequestConfig,
  RequestOptions,
} from 'packages/utils/store'

import { fetchContactsByUnitId } from '../../contacts/actions/fetchContactsByUnitId'
import { fetchReviewsByUnitIdAndDateSince } from '../../reviews/actions/fetchReviewsByUnitId'
import { getReviewDateSince } from '../../reviews/reviews.utils'
import { NormalizedTasksApiResponse, TaskServiceType } from '../../tasks'
import { tasksService } from '../../tasks/tasks.service'
import { setVisitDrawer } from '../../ui/actions'
import { VisitPostData, VisitsActionTypes } from '../visits.types'
import { fetchVisitById } from './fetchVisitById'

export const createVisitAction = createAsyncAction(
  VisitsActionTypes.CREATE_VISIT,
  VisitsActionTypes.CREATE_VISIT_SUCCESS,
  VisitsActionTypes.CREATE_VISIT_FAILURE,
)<
  RequestConfig<NormalizedTasksApiResponse>,
  NormalizedTasksApiResponse,
  Error
>()

export const getParams = (postData: VisitPostData): RequestOptions => ({
  data: {
    attributes: {
      effective_date: format(createDateObject(), DateFormat.ApiUtcShort),
      job_type: ['inspection'],
      service_type: TaskServiceType.adhoc,
    },
    relationships: {
      assigned_housekeepers: {
        data: [{ id: postData.housekeeperId, type: 'housekeeper' }],
      },
      unit: makeRelationship('unit', postData.unitId),
    },
    type: 'task',
  },
})

export const createVisit =
  (postData: VisitPostData, callbacks: ReduxActionCallbacks = {}) =>
  async dispatch => {
    const { onError = noop, onSuccess = noop } = callbacks

    try {
      const params = getParams(postData)
      const unitId = params.data?.relationships?.unit.data.id
      const request = tasksService.createTask.bind(null, params)
      const result = await dispatch(createVisitAction.request({ request }))
      dispatch(createVisitAction.success(result))

      const newVisitId = Object.keys(result.normalized.task)[0]
      await dispatch(fetchVisitById(newVisitId))
      await dispatch(
        fetchReviewsByUnitIdAndDateSince([unitId], getReviewDateSince()),
      )

      await dispatch(fetchContactsByUnitId([unitId]))

      // once we have a valid visit ID, open the drawer for said visit
      // the timeout here is purely for aesthetic reasons,
      // as this will give the "create visit" drawer time to close before we open the next one.
      setTimeout(() => {
        dispatch(
          setVisitDrawer({
            isOpen: true,
            tab: 'tickets',
            visitId: newVisitId,
          }),
        )
      }, 250)

      onSuccess(newVisitId)
      return result.normalized
    } catch (error) {
      dispatch(createVisitAction.failure(error))
      onError()
      throw error
    }
  }
