import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { useToast } from 'packages/common'
import { TicketStatus } from 'packages/grimoire/src/ticket'
import { useI18n } from 'packages/i18n'
import { useAsyncFnWithReset } from 'packages/utils/hooks'

import { useTicket } from 'app/fieldapp/components/tickets/hooks'
import { Slugs } from 'app/fieldapp/i18n'
import { AppDispatch } from 'app/fieldapp/store/store'
import { updateTicket } from 'app/fieldapp/store/tickets/actions'
import { Visit } from 'app/fieldapp/store/visits'
import { updateVisit } from 'app/fieldapp/store/visits/actions'

import { useActiveUser } from '../useActiveUser'

const useTranslations = () => {
  const { ut } = useI18n()

  return {
    addTicketSuccess: ut(Slugs.addTicketSuccess),
  }
}

export type UseAddTicketToVisitArgs = {
  ticketId: string
  visit?: Visit
}

export type UseAddTicketToVisit = {
  initiateAcceptAndAddTicket: () => Promise<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ((dispatch: any) => Promise<void>) | undefined
  >
  loading: boolean
}

export const useAddTicketToVisit = ({
  ticketId,
  visit,
}: UseAddTicketToVisitArgs): UseAddTicketToVisit => {
  const dispatch: AppDispatch = useDispatch()
  const { user: activeUser } = useActiveUser()

  const { showToast } = useToast()

  const { ticket } = useTicket(ticketId)

  const strings = useTranslations()

  // Ticket PATCH handling; primarily for changing status to "ACCEPTED"
  const [updateTicketState, updateTicketFn] = useAsyncFnWithReset(async () => {
    if (ticket) {
      return dispatch(
        updateTicket({
          assigneeId: activeUser?.id,
          id: ticket.id,
          status: TicketStatus.ACCEPTED,
        }),
      )
    }
  }, [activeUser?.id, dispatch, ticket])

  // Visit PATCH handling; primarily for adding tickets to a visit
  const [updateVisitState, updateVisitFn] = useAsyncFnWithReset(async () => {
    if (ticket?.id) {
      return !!visit
        ? dispatch(
            updateVisit(
              {
                id: visit.id,
                ticketIds: [...visit.ticketIds, ticket.id],
              },
              {
                onSuccess: () => {
                  showToast({ message: strings.addTicketSuccess })
                },
              },
            ),
          )
        : void 0
    }
  }, [dispatch, showToast, strings.addTicketSuccess, ticket?.id, visit])

  // auto-handling for chained PATCH requests
  // if a ticket has just been accepted, but not yet attached to the visit,
  // we need to send a separate request to attach the ticket to the visit
  useEffect(() => {
    const needToUpdateVisit =
      updateTicketState.value &&
      !updateVisitState.value &&
      !visit?.ticketIds.includes(ticketId) &&
      !updateVisitState.loading

    if (needToUpdateVisit) {
      updateVisitFn()
    }
  }, [
    updateTicketState.value,
    ticketId,
    updateVisitFn,
    updateVisitState.loading,
    updateVisitState.value,
    visit?.ticketIds,
  ])

  const loading = useMemo(() => {
    return updateTicketState.loading || updateVisitState.loading
  }, [updateTicketState.loading, updateVisitState.loading])

  const initiateAcceptAndAddTicket = React.useMemo(
    () =>
      ticket?.assigneeId &&
      ticket.assigneeId === activeUser?.id &&
      ticket.status === TicketStatus.ACCEPTED
        ? updateVisitFn
        : updateTicketFn,
    [activeUser, ticket, updateTicketFn, updateVisitFn],
  )

  return { initiateAcceptAndAddTicket, loading }
}
