import styled from '@emotion/styled'
import React from 'react'

import { Button, Card, Tooltip, TooltipPosition } from 'packages/common'
import { IconName, SvgIcon } from 'packages/iconic'
import { text, colors, centerWithFlex } from 'packages/styles'
import { useOnlineStatus } from 'packages/utils/hooks'
import { useWrappedEvent } from 'packages/utils/hooks/useWrappedEvent'

import { useTranslateTickets } from 'app/fieldapp/components/tickets/hooks'
import { UseTicketDetails } from 'app/fieldapp/components/tickets/TicketDetail/useTicketDetails'
import { StartTimerButton } from 'app/fieldapp/components/timers/components/Buttons/StartTimerButton'
import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { Ticket } from 'app/fieldapp/store/tickets'
import { fullName, User } from 'app/fieldapp/store/users'

import {
  TicketStatusBar,
  YouBadge,
} from '../../../TaskSharedStyles/VisitTicket.styles'

const St = {
  AddTicketButton: styled(Button)`
    ${text.bodyBoldSmall};
    color: ${colors.lake};
    display: flex;
    justify-content: flex-end;
    height: 20px;
    margin-left: auto;
  `,
  Assignee: styled.div`
    align-items: center;
    display: flex;
    grid-gap: 4px;
    grid-column: 1;
    grid-row: 2;
  `,
  AssigneeName: styled.span`
    ${text.bodyRegularSmall};
    padding-top: 1px;
  `,
  CardContent: styled.div`
    padding: 12px;
  `,
  InfoAndTimerControls: styled.div`
    display: grid;
    grid-column-gap: 4px;
    grid-template-columns: repeat(2, auto);
    grid-template-rows: repeat(2, auto);
    justify-content: space-between;
  `,
  OverdueIcon: styled(SvgIcon)`
    background: white;
    border-radius: 36px;
    border: 1px solid ${colors.dusk};
    color: ${colors.alert};
    padding: 2px 16px;
  `,
  SeverityText: styled.span`
    ${text.bodyBoldTiny};
    text-transform: uppercase;
  `,
  StartTimerButtonWrapper: styled.div`
    ${centerWithFlex};
    align-self: center;
    grid-column: 2;
    grid-row: 1/3;
    height: 100%;
    justify-self: flex-end;
  `,
  StatusBar: TicketStatusBar,
  TicketTitle: styled.div`
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
    margin-bottom: 2px;
    grid-column: 1;
    grid-row: 1;
  `,
  VisitTicketCard: styled(Card)`
    margin-bottom: 8px;
  `,
  You: YouBadge,
}

type headerDateProps = {
  headerDate: string
  headerDateLabel: string
}

export type VisitTicketCardProps = {
  addTicketText?: string
  assignee: User | undefined
  headerDateDisplay: headerDateProps
  isAssignedToCurrentUser: boolean
  // this boolean checks if the ticket has any openVisitId's
  isAttachedToOtherVisit: boolean
  // this boolean checks if the visit currently includes this ticket
  isAttachedToVisit?: boolean
  isLoading: boolean
  isOverdue: boolean
  onAddTicketClick?: (event: React.MouseEvent<HTMLInputElement>) => void
  onClick?: () => void
  ticket: Ticket
  ticketTimerProps?: UseTicketDetails
}

export enum VisitTicketCardTestIds {
  addTicketButton = 'VisitTicketCard__addTicketButton',
  container = 'VisitTicketCard__container',
  startTimerBtn = 'VisitTicketCard__startTimerBtn',
}

export const VisitTicketCard: React.FC<VisitTicketCardProps> = ({
  addTicketText = '',
  assignee,
  headerDateDisplay,
  isAttachedToVisit,
  isAttachedToOtherVisit,
  isAssignedToCurrentUser,
  isOverdue,
  isLoading,
  onAddTicketClick,
  onClick,
  ticket,
  ticketTimerProps,
}) => {
  const { ut } = useI18n()

  const { severity, title } = ticket
  const { translateSeverity } = useTranslateTickets()
  const { stopEvent } = useWrappedEvent()

  const assigneeName = assignee?.id ? fullName(assignee) : 'Unassigned'
  const canRunTimers = isAssignedToCurrentUser && isAttachedToVisit

  const isOnline = useOnlineStatus().isOnline()

  return (
    <St.VisitTicketCard
      onClick={onClick}
      dataTestId={VisitTicketCardTestIds.container}
    >
      <St.StatusBar severity={ticket.severity}>
        <St.SeverityText>{translateSeverity(severity)}</St.SeverityText>

        {isOverdue && (
          <Tooltip
            message={ut(Slugs.overdueTooltipText)}
            position={TooltipPosition.Top}
          >
            <St.OverdueIcon
              centerItems={true}
              icon={IconName.alertTriangle}
              size={14}
            />
          </Tooltip>
        )}

        <span>
          <b>{headerDateDisplay.headerDateLabel}: </b>
          {headerDateDisplay.headerDate}
        </span>
      </St.StatusBar>

      <St.CardContent>
        <St.InfoAndTimerControls>
          <St.TicketTitle>{title}</St.TicketTitle>

          <St.Assignee>
            <SvgIcon centerItems={true} icon={IconName.user2} size={14} />
            <St.AssigneeName>{assigneeName}</St.AssigneeName>
            {isAssignedToCurrentUser && <St.You>{ut(Slugs.you)}</St.You>}
          </St.Assignee>

          {canRunTimers && ticketTimerProps && (
            <St.StartTimerButtonWrapper>
              <StartTimerButton
                dataTestId={VisitTicketCardTestIds.startTimerBtn}
                disabled={ticketTimerProps.isTimerDisabled}
                isLoading={ticketTimerProps.isTimerLoading}
                onClick={stopEvent(ticketTimerProps.handleStartTimer)}
              />
            </St.StartTimerButtonWrapper>
          )}
        </St.InfoAndTimerControls>

        {!isAttachedToVisit && (
          <St.AddTicketButton
            buttonType="text"
            dataTestId={VisitTicketCardTestIds.addTicketButton}
            disabled={!isOnline || isAttachedToOtherVisit}
            isLoading={isLoading}
            onClick={onAddTicketClick}
          >
            {addTicketText}
          </St.AddTicketButton>
        )}
      </St.CardContent>
    </St.VisitTicketCard>
  )
}
