import { createSelector } from 'reselect'

import { hydrateRawTicketComment, TicketComment } from '..'
import { ApplicationState } from '../../store'

export const getTicketCommentsByIds = createSelector(
  (state: ApplicationState) => state.ticketComments,
  (_, ticketCommentIds: string[]) => ticketCommentIds,
  (ticketCommentsState, ticketCommentIds): TicketComment[] =>
    ticketCommentIds.reduce((acc, id) => {
      const rawTicketComment = ticketCommentsState.data[id]

      if (rawTicketComment) {
        acc.push(hydrateRawTicketComment(rawTicketComment))
      }

      return acc
    }, [] as TicketComment[]),
)
