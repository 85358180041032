import { produce } from 'immer'
import { difference } from 'lodash/fp'

export type DisabledVisitsInspectionType =
  | null
  | 'offline'
  | 'noActiveTimer'
  | 'taskIsComplete'
  | 'taskNotStarted'
  | 'otherTimerRunning'

export type InspectionChecklistUiState = {
  completeBtn: {
    error: boolean
    loading: boolean
  }
  errorIds: string[]
  loadingIds: string[]
}

type IdPayload = {
  payload: { id: string }
}

export type InspectionChecklistUiStateAction =
  | ({
      type: 'removeLoadingItem' | 'addErrorItem' | 'addLoadingItem'
    } & IdPayload)
  | {
      payload: { error?: boolean; loading?: boolean }
      type: 'setCompleteButtonState'
    }

export const initialInspectionChecklistUIState = {
  completeBtn: {
    error: false,
    loading: false,
  },
  errorIds: [] as string[],
  loadingIds: [] as string[],
}

export const inspectionChecklistUiStateReducer = (
  state: InspectionChecklistUiState,
  action: InspectionChecklistUiStateAction,
): InspectionChecklistUiState =>
  produce(state, draft => {
    switch (action.type) {
      case 'removeLoadingItem': {
        draft.loadingIds = difference(draft.loadingIds, [action.payload.id])
        return
      }

      case 'addErrorItem': {
        draft.errorIds.push(action.payload.id)
        return
      }

      /* add toggled item to loading state, and (maybe) remove from error state */
      case 'addLoadingItem': {
        const { id } = action.payload
        draft.errorIds = difference(draft.errorIds, [id])
        draft.loadingIds.push(id)
        return
      }

      case 'setCompleteButtonState': {
        const { error, loading } = action.payload
        if (error !== undefined) draft.completeBtn.error = error
        if (loading !== undefined) draft.completeBtn.loading = loading
        return
      }

      default:
        return draft
    }
  })
