import React from 'react'

import { useOnlineStatus } from 'packages/utils/hooks'

import { transformTimers } from 'app/fieldapp/components/timers/components/SubmitTimers/helpers'
import { SubmitTimersFormProps } from 'app/fieldapp/components/timers/components/SubmitTimers/types'
import { getTimerTypeForTask } from 'app/fieldapp/components/timers/timers.helpers'
import { Clean } from 'app/fieldapp/store/cleans'
import { CleanTime } from 'app/fieldapp/store/cleantimes'
import { Visit } from 'app/fieldapp/store/visits'

import { SubmitTimersForm } from '../SubmitTimersForm'
import { useHandleMissingClean } from './useHandleMissingClean'

export enum SubmitCleanTimersTestIds {
  container = 'SubmitCleanTimers__container',
}

type SubmitTimerActions = Pick<
  SubmitTimersFormProps,
  | 'dispatchAbortSubmission'
  | 'dispatchDeleteTimer'
  | 'dispatchCompleteStartedOffline'
  | 'dispatchConfirmSubmission'
>

export type SubmitCleanTimersProps = SubmitTimerActions & {
  displayValue: string
  task: Clean | Visit | undefined
  timer: CleanTime
}

export const SubmitCleanTimers: React.FC<SubmitCleanTimersProps> = React.memo(
  ({
    dispatchAbortSubmission,
    dispatchConfirmSubmission,
    dispatchCompleteStartedOffline,
    dispatchDeleteTimer,
    displayValue,
    task,
    timer,
  }) => {
    const isOnline = useOnlineStatus().isOnline()
    const submissionTimers = transformTimers([timer])

    const { handleMissingClean } = useHandleMissingClean()
    if (!task) {
      handleMissingClean(timer.id, timer.assignment.id)
      return null
    }

    const timerIsOnlineOnly = isOnline && !timer.startedOffline
    const inspectionIsIncomplete = !task.inspectionCompletedAt
    const showInspectionWarning = timerIsOnlineOnly && inspectionIsIncomplete
    const timerType = getTimerTypeForTask(task)

    return (
      <SubmitTimersForm
        dataTestId={SubmitCleanTimersTestIds.container}
        dispatchAbortSubmission={dispatchAbortSubmission}
        dispatchCompleteStartedOffline={dispatchCompleteStartedOffline}
        dispatchConfirmSubmission={dispatchConfirmSubmission}
        dispatchDeleteTimer={dispatchDeleteTimer}
        displayValue={displayValue}
        showInspectionWarning={showInspectionWarning}
        timerType={timerType}
        timers={submissionTimers}
      />
    )
  },
)
