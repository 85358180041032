import { snakeCase } from 'lodash/fp'

import {
  JSONApiObjectWithRelationships,
  JSONApiObjectWithRelationshipsMap,
  ToManyRelationship,
  ToOneRelationship,
} from 'packages/grimoire/src/utils'
import {
  AllVisitAttributeNames,
  AllVisitAttributes,
} from 'packages/grimoire/src/visit/visit.types'

import { JSONApiAssignmentsMap } from '../assignments'
import { JSONApiHousekeepersMap } from '../housekeepers'
import { JSONApiLockboxesMap } from '../lockboxes'
import { JSONApiSmartLocksMap } from '../smartlocks'
import {
  InspectionCategory,
  InspectionCategoryType,
  isCompleteType,
  TaskServiceType,
} from '../tasks'
import { JSONApiUnitsMap } from '../units'
import { JSONApiUsersMap } from '../users'

/**********************************************************
 * SERVICE TYPES
 *********************************************************/

export type VisitPostData = {
  housekeeperId: string
  unitId: string
}

export type VisitPatchApiAttributes = {
  completed_at: string
  inspection_checklist: {
    checklists: {
      category: {
        category_type?: InspectionCategoryType
        id?: string
        title?: string
      }
      items: {
        id?: string
        is_complete?: isCompleteType
        title?: string
      }[]
    }[]
  }
  inspection_completed_at: string
  inspection_notes: string
  is_active: false
  started_at: string
}

export type VisitPatchAttributes = {
  completedAt: Date
  inspectionCompletedAt: Date
  inspectionNotes: string
  inspectionUpdateConfig: {
    category: InspectionCategory
    isEnabled?: boolean
    item: {
      id: string
      isComplete: isCompleteType
      title: string
    }
  }
  /** We should only ever be setting this to false, even though the type is a boolean  */
  isActive: false
  startedAt: Date
  ticketIds: string[]
}

export type VisitPatchData = {
  id: string
} & Partial<VisitPatchAttributes>

export { TaskServiceType as VisitServiceType }

/**********************************************************
 * REDUX TYPES
 *********************************************************/
export enum VisitsActionTypes {
  CREATE_VISIT = 'VISITS/API/CREATE_VISIT',
  CREATE_VISIT_FAILURE = 'VISITS/CREATE_VISIT_FAILURE',
  CREATE_VISIT_SUCCESS = 'VISITS/CREATE_VISIT_SUCCESS',

  DELETE_VISIT = 'VISITS/API/DELETE_VISIT',
  DELETE_VISIT_FAILURE = 'VISITS/DELETE_VISIT_FAILURE',
  DELETE_VISIT_SUCCESS = 'VISITS/DELETE_VISIT_SUCCESS',

  FETCH_VISIT = 'VISITS/API/FETCH_VISIT',
  FETCH_VISITS = 'VISITS/API/FETCH_VISITS',
  FETCH_VISITS_FAILURE = 'VISITS/FETCH_VISITS_FAILURE',

  FETCH_VISITS_SUCCESS = 'VISITS/FETCH_VISITS_SUCCESS',
  FETCH_VISIT_FAILURE = 'VISITS/FETCH_VISIT_FAILURE',
  FETCH_VISIT_SUCCESS = 'VISITS/FETCH_VISIT_SUCCESS',

  TOGGLE_VISIT_INSPECTION_ITEM = 'VISITS/API/TOGGLE_INSPECTION_ITEM',
  TOGGLE_VISIT_INSPECTION_ITEM_FAILURE = 'VISITS/TOGGLE_INSPECTION_ITEM_FAILURE',
  TOGGLE_VISIT_INSPECTION_ITEM_SUCCESS = 'VISITS/TOGGLE_INSPECTION_ITEM_SUCCESS',

  UPDATE_VISIT = 'VISITS/API/UPDATE_VISIT',
  UPDATE_VISIT_FAILURE = 'VISITS/UPDATE_VISIT_FAILURE',
  UPDATE_VISIT_SUCCESS = 'VISITS/UPDATE_VISIT_SUCCESS',
}

export type VisitsState = {
  data: JSONApiVisitMap
  loading: boolean
  unitAssociatedVisits: JSONApiVisitMap
}

export type VisitRelationships = {
  activeAssignments: ToManyRelationship
  assignedHousekeepers: ToManyRelationship
  lastCleanCompletedHousekeepers: ToManyRelationship
  smartLocks: ToManyRelationship
  taskPhotos: ToManyRelationship
  tickets: ToManyRelationship
  unit: ToOneRelationship
}

export type VisitsResponse = {
  assignment: JSONApiAssignmentsMap
  housekeeper: JSONApiHousekeepersMap
  lockBox: JSONApiLockboxesMap
  smartLock: JSONApiSmartLocksMap
  unit: JSONApiUnitsMap
  user: JSONApiUsersMap
}

export type VisitPriorityScoreType = {
  b2BScore: number
  bedroomCountScore: number
  isHighestScore: boolean
  lastCompletedInspectionScore: number
  ownerArrivalScore: number
  ticketSeverityScore: number
  totalScore: number
  upcomingReservationScore: number
}

export type Visit = {
  assignedHkIds: string[]
  assignmentIds: string[]
  id: string
  lastCleanCompletedHkIds: string[]
  metadata: {
    priorityScore?: VisitPriorityScoreType
  }
  taskPhotoIds: string[]
  ticketIds: string[]
  unitId: string
} & VisitAttributes

export type VisitAttributes = Omit<
  AllVisitAttributes,
  'predictedCleanTime' | 'realtimeStatus' | 'hasCleanPhotos'
> & { isActive: boolean }

export const VisitApiFields: string[] =
  AllVisitAttributeNames.map(snakeCase).concat('is_active')

export type RawVisit = JSONApiObjectWithRelationships<
  VisitAttributes,
  VisitRelationships
>

export type JSONApiVisitMap = JSONApiObjectWithRelationshipsMap<
  VisitAttributes,
  VisitRelationships
>
