import { createAsyncAction } from 'typesafe-actions'

import {
  RequestConfig,
  RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import { ApplicationState } from 'app/fieldapp/store/store'

import { otherTimersService } from '../otherTimers.service'
import {
  OtherTimeApiFields,
  OtherTimersActionTypes,
  NormalizedOtherTimersApiResponse,
} from '../otherTimers.types'

export const fetchActiveOtherTimersForUserAction = createAsyncAction(
  OtherTimersActionTypes.FETCH_OTHER_TIMERS,
  OtherTimersActionTypes.FETCH_OTHER_TIMERS_SUCCESS,
  OtherTimersActionTypes.FETCH_OTHER_TIMERS_FAILURE,
)<
  RequestConfig<NormalizedOtherTimersApiResponse>,
  NormalizedOtherTimersApiResponse,
  Error
>()

export const getParams = (userId: string): RequestOptions => ({
  fields: {
    task_time: OtherTimeApiFields,
    user: [],
  },
  filter: {
    is_finalized: false,
    user: userId,
  },
  page: { size: 1500 },
})

export const fetchActiveOtherTimersForUser = () => {
  return async (dispatch, getState: () => ApplicationState) => {
    try {
      const userId = getState().users.activeUserId
      const params = getParams(userId)
      const request = otherTimersService.fetchOtherTimers.bind(null, params)
      const result = await dispatch(
        fetchActiveOtherTimersForUserAction.request({ request }),
      )
      dispatch(fetchActiveOtherTimersForUserAction.success(result))
      return result
    } catch (error) {
      dispatch(fetchActiveOtherTimersForUserAction.failure(error))
      throw error
    }
  }
}
