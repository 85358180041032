import { createSelector } from 'reselect'

import { getCleanTimeById } from '../../cleantimes/selectors'
import { ApplicationState } from '../../store'
import { Visit } from '../../visits'
import { getVisitById } from './getVisitById'

const getVisitsState = (state: ApplicationState) => state.visits
const getCleanTime = (state: ApplicationState, cleanTimeId: string) =>
  getCleanTimeById(state, cleanTimeId)

export const getVisitByCleanTimerId = createSelector(
  getVisitsState,
  getCleanTime,
  (visitsState, cleanTime): Visit | undefined => {
    const assignmentId = cleanTime?.assignment.id

    const visits = visitsState.data

    const visitId = Object.values(visits).find(visit =>
      Object.values(visit.relationships.activeAssignments.data).find(
        ({ id }) => id === assignmentId,
      ),
    )?.id

    const visit = visitId
      ? getVisitById({ visits: visitsState } as ApplicationState, visitId)
      : undefined

    return visit
  },
)
