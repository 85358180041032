import { MIN_REQUIRED_PHOTOS } from 'app/fieldapp/components/schedule/components/VisitInspectionChecklist/components/VisitInspectionCategory'

import { TaskPhoto } from '../../taskPhotos'
import { InspectionChecklist } from '../../tasks'

export const getHasAllInspectionPhotos = (
  photos: TaskPhoto[],
  inspection: InspectionChecklist,
) =>
  !inspection.checklists.filter(checklist => {
    const uploadedPhotosCount = photos.reduce((acc, photo) => {
      if (photo.categoryId === checklist.category.id) {
        return acc + 1
      }

      return acc
    }, 0)

    return uploadedPhotosCount < MIN_REQUIRED_PHOTOS
  }).length
