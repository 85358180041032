import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AppDispatch } from 'app/fieldapp/store/store'
import { setReviewDrawer } from 'app/fieldapp/store/ui/actions'
import { getReviewDrawerState } from 'app/fieldapp/store/ui/selectors'

import { ReviewDrawer } from './ReviewDrawer'

export const ReviewDrawerContainer: React.FC = () => {
  const dispatch: AppDispatch = useDispatch()
  const { isOpen } = useSelector(getReviewDrawerState)

  const afterExit = React.useCallback(() => {
    dispatch(
      setReviewDrawer({
        isOpen: false,
        reviewId: '',
        tab: 'reviews',
      }),
    )
  }, [dispatch])

  return <ReviewDrawer afterExit={afterExit} isOpen={isOpen} />
}
