import * as React from 'react'
import { useSelector } from 'react-redux'

import { Button } from 'packages/common'
import { IconName, SvgIcon } from 'packages/iconic'
import { differenceInSeconds } from 'packages/utils/dateHelpers'

import { TimerDetail } from 'app/fieldapp/components/timers/components'
import { TimerDetailWrapper } from 'app/fieldapp/components/timers/components/Timers.styles'
import { useCleanTimerActions } from 'app/fieldapp/components/timers/hooks'
import { getTimerTypeForTask } from 'app/fieldapp/components/timers/timers.helpers'
import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { CleanTime } from 'app/fieldapp/store/cleantimes'
import { ApplicationState } from 'app/fieldapp/store/store'
import { getTasksByAssignmentIds } from 'app/fieldapp/store/tasks/selectors'
import { useTaskUnit } from 'app/fieldapp/store/tasks/utils/useTaskUnit'

import styles from '../PausedTimerDisplay.module.scss'

export enum PausedCleanTimerDisplayTestIds {
  completeBtn = 'PausedCleanTimerDisplay__completeBtn',
  container = 'PausedCleanTimerDisplay__container',
  deleteBtn = 'PausedCleanTimerDisplay__deleteBtn',
}

export type PausedCleanTimerDisplayProps = {
  cleanTime: CleanTime
  loading: boolean
  requestPending: boolean
}

export const PausedCleanTimerDisplay: React.FC<PausedCleanTimerDisplayProps> =
  React.memo(({ cleanTime, loading, requestPending }) => {
    const { t } = useI18n()
    const {
      dispatchBeginCleanTimeSubmission,
      dispatchDeleteCleanTimerWithModalWarning,
    } = useCleanTimerActions()

    // find the task associated with this timer (by way of the shared assignment ID)
    const tasks = useSelector((state: ApplicationState) =>
      getTasksByAssignmentIds(state, [cleanTime.assignment.id]),
    )
    const task = Object.values(tasks)[0]

    const time = differenceInSeconds(
      cleanTime.stoppedAt ?? cleanTime.startedAt,
      cleanTime.startedAt,
    )

    const handleCompleteClick = React.useCallback(async (): Promise<void> => {
      dispatchBeginCleanTimeSubmission(cleanTime)
    }, [cleanTime, dispatchBeginCleanTimeSubmission])

    const handleDeleteClick = React.useCallback(async (): Promise<void> => {
      dispatchDeleteCleanTimerWithModalWarning(cleanTime.id)
    }, [cleanTime, dispatchDeleteCleanTimerWithModalWarning])

    const cleanType = getTimerTypeForTask(task)

    const unit = useTaskUnit(task)

    return (
      <TimerDetailWrapper
        data-testid={PausedCleanTimerDisplayTestIds.container}
        timerColorType={cleanType}
      >
        <div
          className={styles.closeButton}
          data-testid={PausedCleanTimerDisplayTestIds.deleteBtn}
          onClick={handleDeleteClick}
        >
          <SvgIcon className={styles.closeX} icon={IconName.x} />
        </div>

        <div className={styles.timerDetailWrapper}>
          <TimerDetail time={time} unit={unit} />
        </div>

        <div className={styles.timerControls}>
          <Button
            ariaLabel="Submit this timer"
            dataTestId={PausedCleanTimerDisplayTestIds.completeBtn}
            disabled={requestPending && !loading}
            isLoading={loading}
            onClick={handleCompleteClick}
            buttonType={'primary'}
          >
            {t(Slugs.submit)}
          </Button>
        </div>
      </TimerDetailWrapper>
    )
  })
