import { useI18n, Slugs } from 'app/fieldapp/i18n'
import { TicketSeverity } from 'app/fieldapp/store/tickets'

const slugMap = {
  [TicketSeverity.LOW]: Slugs.severityLow,
  [TicketSeverity.PLANNED]: Slugs.severityPlanned,
  [TicketSeverity.MEDIUM]: Slugs.severityMedium,
  [TicketSeverity.PRIOR_TO_NEXT_GUEST]: Slugs.severityPriorToNextGuest,
  [TicketSeverity.TODAY]: Slugs.severityToday,
  [TicketSeverity.URGENT]: Slugs.severityUrgent,
}

type UseTranslateTickets = {
  translateSeverity: (severity: TicketSeverity) => string
}

export function useTranslateTickets(): UseTranslateTickets {
  const { t } = useI18n()

  function translateSeverity(severity: TicketSeverity) {
    return t(slugMap[severity])
  }

  return { translateSeverity }
}
