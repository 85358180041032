import { produce } from 'immer'
import { pick } from 'lodash/fp'
import { createAsyncAction } from 'typesafe-actions'

import {
  RequestConfig,
  RequestOptions,
} from 'packages/utils/store/jsonapi.types'
import { makeRelationship } from 'packages/utils/store/jsonapi.utils'

import { ticketsService } from '../tickets.service'
import {
  TicketsActionTypes,
  NormalizedTicketsApiResponse,
  TicketPostData,
  TicketPostAPIAttributes,
  TicketPostAPIRelationships,
} from '../tickets.types'

export const createTicketAction = createAsyncAction(
  TicketsActionTypes.CREATE_TICKET,
  TicketsActionTypes.CREATE_TICKET_SUCCESS,
  TicketsActionTypes.CREATE_TICKET_FAILURE,
)<
  RequestConfig<NormalizedTicketsApiResponse>,
  NormalizedTicketsApiResponse,
  Error
>()

export const buildRequestData = (postData: TicketPostData): RequestOptions => {
  const attributes: TicketPostAPIAttributes = pick([
    'title',
    'description',
    'severity',
    'visibility',
  ])(postData) as TicketPostAPIAttributes

  const requiredRelationships: TicketPostAPIRelationships = {
    creator: makeRelationship('user', postData.userId),
    disposition: makeRelationship('disposition', postData.dispositionId),
    unit: makeRelationship('unit', postData.unitId),
  }

  const relationships: TicketPostAPIRelationships = produce(
    requiredRelationships,
    draft => {
      if (postData.inspectionFlagIds) {
        const flags = postData.inspectionFlagIds.map(flagId => {
          return { id: flagId, type: 'inspection_flag' }
        })
        draft.inspection_flags = { data: flags }
      }

      if (postData.assignToSelf) {
        draft.assignee = makeRelationship('user', postData.userId)
        return
      } else if (postData.assigneeId === 'default') {
        // default assigneeId is no assignee
        return
      } else if (postData.assigneeId) {
        draft.assignee = makeRelationship('user', postData.assigneeId)
        return
      }
    },
  )

  return {
    data: {
      attributes,
      relationships,
      type: 'ticket',
    },
  }
}

export const createTicket = (postData: TicketPostData) => async dispatch => {
  const requestData = buildRequestData(postData)

  const request = ticketsService.createTicket.bind(null, requestData)

  try {
    const result = await dispatch(createTicketAction.request({ request }))

    dispatch(createTicketAction.success(result))
    return result
  } catch (error) {
    dispatch(createTicketAction.failure(error))

    throw error
  }
}
