import {
  NormalizedReservationsApiResponse,
  ReservationsResponse,
} from './reservations.types'

/**
 * Empty data in the shape of the Reservations service's `normalized` payload.
 * Use this as a fallback when there are issues with the API data being undefined or malformed.
 */
export const emptyNormalizedReservationsData =
  Object.freeze<ReservationsResponse>({
    reservation: {},
  })

export const emptyReservationsResponse: NormalizedReservationsApiResponse =
  Object.freeze({
    normalized: emptyNormalizedReservationsData,
    raw: { data: [] },
  })
