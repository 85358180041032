import { createAsyncAction } from 'typesafe-actions'

import { OfflineError } from 'packages/offline'
import { apiDateStringWithSeconds } from 'packages/utils/dateHelpers'
import { OfflineRequestConfig, RequestOptions } from 'packages/utils/store'

import { offlineTimers } from 'app/fieldapp/store/utils/timers/offlineTimers.db'

import { ApplicationState } from '../../store'
import { createTicketComment } from '../../ticketComments/actions'
import { getActiveUser } from '../../users/selectors'
import { ticketTimesService } from '../ticket-times.service'
import { TicketTimesActionTypes } from '../ticket-times.types'
import {
  TicketTimePatchApiAttributes,
  NormalizedTicketTimesApiResponse,
  TicketTimePatchData,
} from '../ticket-times.types'
import { fetchActiveTicketTimesForUser } from './fetchActiveTicketTimesForUser'

export const updateTicketTimeAction = createAsyncAction(
  TicketTimesActionTypes.UPDATE_TICKET_TIME,
  TicketTimesActionTypes.UPDATE_TICKET_TIME_SUCCESS,
  TicketTimesActionTypes.UPDATE_TICKET_TIME_FAILURE,
)<
  OfflineRequestConfig<NormalizedTicketTimesApiResponse, TicketTimePatchData>,
  NormalizedTicketTimesApiResponse,
  Error
>()

export const buildRequestData = (
  patchData: TicketTimePatchData,
): RequestOptions => {
  const { id, isFinalized, startedAt, stoppedAt } = patchData

  const attributes: Partial<TicketTimePatchApiAttributes> = {
    is_finalized: isFinalized,
    started_at: startedAt && apiDateStringWithSeconds(startedAt),
    stopped_at: stoppedAt && apiDateStringWithSeconds(stoppedAt),
  }

  return {
    data: {
      attributes,
      id,
      type: 'ticket_time',
    },
  }
}

export const updateTicketTime =
  (patchData: TicketTimePatchData & { isExternalComment?: boolean }) =>
  async (dispatch, getState) => {
    try {
      const ticketTimeId = patchData.id
      const requestData = buildRequestData(patchData)
      const request = ticketTimesService.updateTicketTimer.bind(
        null,
        ticketTimeId,
        requestData,
      )
      const result = await dispatch(
        updateTicketTimeAction.request({ data: patchData, request }),
      )

      const state: ApplicationState = getState()

      const ticketId =
        state.ticketTimes.data[ticketTimeId].relationships.ticket.data.id
      const user = getActiveUser(state)

      if (ticketId && user && patchData.notes) {
        await dispatch(
          createTicketComment({
            external: !!patchData.isExternalComment,
            note: patchData.notes,
            ticketId,
            userId: user.id,
          }),
        )
      }

      // remove any existing copies of this timer from local cache
      await offlineTimers.removeTicketTime(ticketTimeId)

      dispatch(updateTicketTimeAction.success(result))

      // re-fetch all timers to ensure we update the PWA cache
      await dispatch(fetchActiveTicketTimesForUser())

      return result.normalized
    } catch (error) {
      /* No need to do anything if there is a middleware-handled offline error */
      if (error === OfflineError) return
      dispatch(updateTicketTimeAction.failure(error))
      throw error
    }
  }
