import styled from '@emotion/styled'
import React from 'react'

import { useI18n } from 'packages/i18n'
import { IconName, SvgIcon } from 'packages/iconic'
import { colors, text } from 'packages/styles'

import { Slugs } from 'app/fieldapp/i18n'

import { DetailsTicketListContainer } from '../DetailsTicketList/DetailsTicketList.container'

const St = {
  OpenTicketsBody: styled.p`
    ${text.bodyRegularSmall};
    margin: 0 0 40px 0;
    color: ${colors.dusk80};
  `,
  OpenTicketsContainer: styled.div`
    padding: 0 16px;
  `,
  OpenTicketsHeader: styled.h2`
    ${text.fieldLabelMobile};
    color: ${colors.dusk};
    margin: 0 0 0 8px;
  `,
  OpenTicketsHeaderContainer: styled.div`
    align-items: center;
    display: flex;
    margin-bottom: 10px;
  `,
}

const useTranslations = () => {
  const { t } = useI18n()

  return {
    body: t(Slugs.detailsTicketBody),
    header: t(Slugs.ticketsOnThisUnit),
  }
}

export enum DetailTicketsTestIds {
  container = 'DetailTickets__container',
}

export type DetailTicketsProps = {
  unitId: string
}

export const DetailTickets: React.FC<DetailTicketsProps> = React.memo(
  ({ unitId }) => {
    const strings = useTranslations()

    return (
      <St.OpenTicketsContainer data-testid={DetailTicketsTestIds.container}>
        <St.OpenTicketsHeaderContainer>
          <SvgIcon icon={IconName.alertTriangle2} size={22} />
          <St.OpenTicketsHeader>{strings.header}</St.OpenTicketsHeader>
        </St.OpenTicketsHeaderContainer>

        <St.OpenTicketsBody>{strings.body}</St.OpenTicketsBody>

        <DetailsTicketListContainer unitId={unitId} />
      </St.OpenTicketsContainer>
    )
  },
)
