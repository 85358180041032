import { produce } from 'immer'
import get from 'lodash/get' // eslint-disable-line
import { ActionType, getType } from 'typesafe-actions'

import { TimerType } from 'packages/grimoire/src/utils/timers.types'
import { getIdAndDataTypeFromAction } from 'packages/utils/store/store.utils'

import { makeOfflineTimer } from 'app/fieldapp/components/timers/timers.helpers'

import {
  createCleanTimeOfflineAction,
  createFinalizedCleanTimeAction,
  createCleanTimeAction,
  deleteCleanTimeAction,
  fetchActiveCleanTimesForUserAction,
  fetchCleanTimeByIdAction,
  reloadOfflineCleanTimesAction,
  removeLocalCleanTimeAction,
  updateCleanTimeAction,
  updateCleanTimeOfflineAction,
} from './actions'
import { CleanTimesState, RawCleanTime } from './cleantimes.types'
import { emptyNormalizedCleanTimesData } from './cleantimes.utils'

const initialState: CleanTimesState = {
  data: {},
  error: undefined,
  offlineData: {},
  requestPending: false,
}

const actions = {
  createCleanTimeAction,
  createCleanTimeOfflineAction,
  createFinalizedCleanTimeAction,
  deleteCleanTimeAction,
  fetchActiveCleanTimesForUserAction,
  fetchCleanTimeByIdAction,
  reloadOfflineCleanTimesAction,
  removeLocalCleanTimeAction,
  updateCleanTimeAction,
  updateCleanTimeOfflineAction,
}

const getIdAndCleanTime = action =>
  getIdAndDataTypeFromAction<RawCleanTime>(action, 'cleanTime')

type CleanTimeActionsTypes = ActionType<typeof actions>

export const DEFAULT_ERROR = new Error('Unknown Error in cleanTimesReducer')

export const cleanTimesReducer = (
  state = initialState,
  action: CleanTimeActionsTypes,
): CleanTimesState =>
  produce(state, (draft: CleanTimesState) => {
    switch (action.type) {
      case getType(deleteCleanTimeAction.request):
      case getType(createCleanTimeAction.request):
      case getType(fetchCleanTimeByIdAction.request):
      case getType(fetchActiveCleanTimesForUserAction.request):
      case getType(updateCleanTimeAction.request):
      case getType(createFinalizedCleanTimeAction.request): {
        draft.requestPending = true
        return
      }

      case getType(createFinalizedCleanTimeAction.success): {
        /* If localId exists, we're working with a timer started offline,
        if we're here, we've successfully posted a timer with a real id, so we
        need to delete the local timer
        */
        const localId = action.payload.localId
        /** We only need to delete from offlineData because timers started offline won't touch the data section */
        delete draft.offlineData[localId]

        return
      }

      case getType(updateCleanTimeAction.success): {
        const id = Object.keys(action.payload.normalized.cleanTime)[0]
        delete draft.offlineData[id]
        return
      }

      case getType(removeLocalCleanTimeAction):
      case getType(deleteCleanTimeAction.success): {
        const { id } = action.payload

        delete draft.data[id]
        delete draft.offlineData[id]
        draft.error = undefined
        draft.requestPending = false
        return
      }

      case getType(fetchActiveCleanTimesForUserAction.success): {
        const normalized = get(
          action,
          'payload.normalized',
          emptyNormalizedCleanTimesData,
        )

        draft.data = normalized.cleanTime || {}
        draft.error = undefined
        draft.requestPending = false
        return
      }

      case getType(fetchCleanTimeByIdAction.success): {
        const [id, cleanTime] = getIdAndCleanTime(action)

        draft.data[id] = cleanTime as RawCleanTime
        draft.error = undefined
        draft.requestPending = false
        return
      }

      case getType(deleteCleanTimeAction.failure):
      case getType(createFinalizedCleanTimeAction.failure):
      case getType(fetchActiveCleanTimesForUserAction.failure):
      case getType(fetchCleanTimeByIdAction.failure):
      case getType(updateCleanTimeAction.failure): {
        draft.error = get(action, 'payload', DEFAULT_ERROR)
        draft.requestPending = false
        return
      }

      /**************************************************
       * Offline CleanTimes Handling
       **************************************************/
      case getType(createCleanTimeOfflineAction.success):
      case getType(updateCleanTimeOfflineAction.success): {
        const { data } = action.payload

        draft.offlineData[data.id] = makeOfflineTimer<TimerType.CLEAN>(data)

        draft.requestPending = false
        return
      }

      case getType(reloadOfflineCleanTimesAction.success): {
        draft.offlineData = action.payload || {}
        return
      }
    }
  })
