import { createSelector } from 'reselect'

import { ApplicationState } from '../../store'
import { RawReview, Review } from '../reviews.types'
import { hydrateRawReview } from '../reviews.utils'

/**
 * Returns all reviews for a specific unit ID (cached via reselect) should recompute if state changes
 *
 * @param state
 */
export const getReviewsByUnitId = createSelector(
  (state: ApplicationState) => state.reviews.data, // Get all reviews from state
  (_, unitId: string) => unitId, // Get the unit ID as an argument
  (reviewsState, unitId): Review[] => {
    if (!unitId) {
      return []
    }

    if (!reviewsState) {
      return []
    }

    // Filter reviews by the unit's ID
    return Object.values(reviewsState)
      .filter((rawReview: RawReview) => rawReview.attributes.unitId === unitId)
      .map(hydrateRawReview)
  },
)
