import { createAsyncThunk } from '@reduxjs/toolkit'

import { logError } from 'packages/wiretap/logging'

import { useTimelineDateRange } from 'app/fieldapp/utils/timelineHelpers'

import { fetchCleans } from '../../cleans/actions'
import { fetchTickets } from '../../tickets/actions'
import { getActiveUser } from '../../users/selectors'
import { fetchVisits } from '../../visits/actions'

export const fetchPrimaryDataBatch = createAsyncThunk(
  'app/fetchPrimaryDataBatch',
  async (_, { dispatch, getState, rejectWithValue }) => {
    // useTimelineDateRange isn't actually a hook so it'll work here
    // consider refactoring this function into a helper in order to
    // disambiguate its usage
    const { startDate, endDate } = useTimelineDateRange()
    const activeUser = getActiveUser(getState())

    try {
      if (!activeUser) {
        throw new Error('No active user found')
      }

      await Promise.all([
        dispatch(fetchCleans(activeUser.id, startDate, endDate)),
        dispatch(fetchTickets(activeUser.id)),
      ])

      await dispatch(fetchVisits(activeUser.id, startDate, endDate))
    } catch (err) {
      logError(err)
      rejectWithValue(err)
    }
  },
)
