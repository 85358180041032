import React from 'react'

import { ExternalLink } from 'packages/common'
import { useI18n } from 'packages/i18n'
import { IconName } from 'packages/iconic'
import {
  getAdminViewUnitTicketsURL,
  getVacasaViewUnitURL,
} from 'packages/utils/links'
import { logInfo } from 'packages/wiretap/logging'

import { CreateTicketDrawer } from 'app/fieldapp/components/schedule/components/common'
import {
  Details,
  DetailIcon,
  SectionSubTitle,
  UnitInfoContainer,
} from 'app/fieldapp/components/schedule/components/TaskSharedStyles'
import { Slugs } from 'app/fieldapp/i18n'
import { useAppSelector } from 'app/fieldapp/store/hooks'
import { ApplicationState } from 'app/fieldapp/store/store'
import { getUnitById } from 'app/fieldapp/store/units/selectors'

import { isViewingSharedClean } from '../../schedule.utils'

const St = {
  DetailIcon,
  Details,
  SectionSubTitle,
  UnitInfoContainer,
}

export type TaskDetailUnitLinksProps = {
  taskId?: string
  unitId?: string
}

const useTranslations = () => {
  const { t, ut } = useI18n()

  return {
    additionalHomeInfo: t(Slugs.additionalHomeInfo),
    unitTickets: `${ut(Slugs.unit)} ${ut(Slugs.tickets)}`,
    viewHome: t(Slugs.viewHome),
    viewTickets: t(Slugs.viewOpenTickets),
  }
}

export const TaskDetailUnitLinks: React.FC<TaskDetailUnitLinksProps> =
  React.memo(({ taskId, unitId }) => {
    const strings = useTranslations()
    const unit = useAppSelector((state: ApplicationState) =>
      getUnitById(state, unitId),
    )

    if (!unit) {
      logInfo('Missing unit info when trying to render unit links')
    }

    const shouldHideAdminLinks = isViewingSharedClean()

    return (
      <>
        {!shouldHideAdminLinks && (
          <>
            <St.UnitInfoContainer>
              <St.DetailIcon icon={IconName.tag} size={16} />
              <St.Details>
                <St.SectionSubTitle>{strings.unitTickets}</St.SectionSubTitle>

                <ExternalLink
                  text={strings.viewTickets}
                  url={getAdminViewUnitTicketsURL(unit?.id || '')}
                />
              </St.Details>
            </St.UnitInfoContainer>

            <St.UnitInfoContainer>
              <St.DetailIcon icon={IconName.home} size={16} />
              <St.Details>
                <St.SectionSubTitle>
                  {strings.additionalHomeInfo}
                </St.SectionSubTitle>

                <ExternalLink
                  text={strings.viewHome}
                  url={getVacasaViewUnitURL(unit?.id || '')}
                />
              </St.Details>
            </St.UnitInfoContainer>
          </>
        )}
        <CreateTicketDrawer taskId={taskId} unit={unit} />
      </>
    )
  })
