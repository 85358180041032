import styled from '@emotion/styled'
import React from 'react'

import { Loader, LoaderAlignment } from 'packages/common'
import { colors, text } from 'packages/styles'

import { Slugs, useI18n } from 'app/fieldapp/i18n'

import {
  VisitTicketSection,
  VisitTicketSectionTitle,
} from '../../schedule/components/TaskSharedStyles/VisitTicket.styles'
import { useVisitTicketDrawerContext } from '../../schedule/components/VisitTicketDrawer/VisitTicketDrawer.context'
import { TicketCommentDisplayContainer } from '../TicketCommentDisplay'
import { TicketCommentFormContainer } from '../TicketCommentForm'

const St = {
  LoaderWrapper: styled.div`
    display: inline-flex;
    justify-content: flex-start;
    position: relative;
    width: 100%;
  `,
  NoComments: styled.div`
    ${text.bodyRegularSmall}
    font-style: italic;
    margin-top: 24px;
  `,
  SectionTitle: styled(VisitTicketSectionTitle)`
    display: flex;
    flex-direction: row;
    grid-gap: 8px;
  `,
  VisitTicketSection,
}

const useTranslations = () => {
  const { ut } = useI18n()

  return {
    comments: ut(Slugs.comments),
    noComments: ut(Slugs.noComments),
  }
}

export type TicketCommentsProps = {
  commentIds: string[]
  ticketId: string
}

export const TicketComments: React.FC<TicketCommentsProps> = React.memo(
  ({ commentIds, ticketId }) => {
    const strings = useTranslations()
    const { fetchTicketState } = useVisitTicketDrawerContext()

    return (
      <>
        <St.SectionTitle>
          <div>{strings.comments}</div>

          {fetchTicketState.loading && (
            <St.LoaderWrapper>
              <Loader
                alignment={LoaderAlignment.left}
                color={colors.dusk}
                size={6}
              />
            </St.LoaderWrapper>
          )}
        </St.SectionTitle>

        <TicketCommentFormContainer ticketId={ticketId} />

        {commentIds.length ? (
          commentIds.map(id => (
            <TicketCommentDisplayContainer commentId={id} key={id} />
          ))
        ) : (
          <St.NoComments>{strings.noComments}</St.NoComments>
        )}
      </>
    )
  },
)
