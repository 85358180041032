import { createSelector } from 'reselect'

import { ApplicationState } from 'app/fieldapp/store/store'
import { hydrateRawUser, JSONApiUsersMap, User } from 'app/fieldapp/store/users'

const getUsersSearchResultsState = (state: ApplicationState): JSONApiUsersMap =>
  state.users.searchResults

export const getUsersSearchResults = createSelector(
  getUsersSearchResultsState,
  (searchResults): User[] => {
    return Object.values(searchResults).map(hydrateRawUser)
  },
)
