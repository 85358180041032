import { filter, flatMap, pipe, prop, values } from 'lodash/fp'
import { useSelector } from 'react-redux'

import { isDefined } from 'packages/utils/misc'

import { TimersMap } from 'app/fieldapp/store/utils/timers'
import { getAllOfflineMergedTimers } from 'app/fieldapp/store/utils/timers/selectors'

type UseOfflineMergedTimers = {
  offlineTimers: TimersMap
  offlineTimersCount: number
  stoppedOfflineTimersCount: number
}

const isStopped = pipe(prop('stoppedAt'), isDefined)

export const useOfflineMergedTimers = (): UseOfflineMergedTimers => {
  const offlineTimers = useSelector(getAllOfflineMergedTimers)

  const stoppedOfflineTimersCount = pipe(
    values,
    flatMap(filter(isStopped)),
    prop('length'),
  )(offlineTimers)

  const offlineTimersCount =
    offlineTimers.cleanTimes.length +
    offlineTimers.otherTimers.length +
    offlineTimers.ticketTimes.length

  return {
    offlineTimers,
    offlineTimersCount,
    stoppedOfflineTimersCount,
  }
}
