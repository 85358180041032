import { produce } from 'immer'
import { noop } from 'lodash/fp'
import React from 'react'
import { useDispatch } from 'react-redux'
import { usePrevious } from 'react-use'

import { ImageUploader, ImageUploaderProps } from 'packages/common'
import { Events, track } from 'packages/wiretap'

import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { AppDispatch } from 'app/fieldapp/store/store'
import { TaskPhoto } from 'app/fieldapp/store/taskPhotos'
import { fetchTaskPhotoById } from 'app/fieldapp/store/taskPhotos/actions'
import { useActiveUser } from 'app/fieldapp/utils/hooks/useActiveUser'

import { useInspectionContext } from '../../context/VisitInspectionChecklist.context'
import { useInspectionVisitWithRelationships } from '../../useInspectionVisitWithRelationships'
import { RequestStatus, useDeletePhoto } from './hooks'
import { useUploadPhoto } from './hooks/useUploadPhoto'

const useTranslations = (hasPendingUpload: boolean) => {
  const { t } = useI18n()

  return {
    fallback: hasPendingUpload
      ? t(Slugs.inspectionPhotoProcessing)
      : t(Slugs.addPhoto),
    inspection: t(Slugs.inspection),
    title: t(Slugs.inspectionPhotoUploadTooltip),
  }
}

type StateType = {
  error: string
  state:
    | 'idle'
    | 'uploading'
    | 'uploaded'
    | 're-fetching'
    | 're-fetched'
    | 'error'
}

export type VisitInspectionImageUploaderProps = Pick<
  ImageUploaderProps,
  'id'
> & {
  categoryId: string
  onDeletePhotoStatusChange?: (newStatus: RequestStatus) => void
  onThumbnailClick?: () => void
  onUploadError?: (err: Error) => void
  onUploadSuccess?: (taskPhotoId: string, file: File) => void
  taskPhoto?: TaskPhoto
  tempThumbnailUri?: string
}

export const VisitInspectionImageUploader: React.FC<VisitInspectionImageUploaderProps> =
  React.memo(props => {
    const {
      categoryId,
      onDeletePhotoStatusChange = noop,
      onThumbnailClick = noop,
      onUploadError = noop,
      onUploadSuccess = noop,
      taskPhoto,
      tempThumbnailUri = '',
      ...imageUploaderProps
    } = props

    const dispatch: AppDispatch = useDispatch()
    const { delegateUserId, user } = useActiveUser()

    const { uiDisabledState } = useInspectionContext()
    const { visit } = useInspectionVisitWithRelationships()
    const hasPendingUpload = taskPhoto?.originalImage === null
    const strings = useTranslations(hasPendingUpload)

    const [uiState, setUiState] = React.useState<StateType>({
      error: '',
      state: hasPendingUpload ? 're-fetching' : 'idle',
    })

    //------------------------------------------------
    // Thumbnail handling
    //------------------------------------------------
    const [thumbnailUri, setThumbnailUri] = React.useState(
      taskPhoto?.thumbnails?.medium ||
        taskPhoto?.originalImage ||
        tempThumbnailUri ||
        '',
    )

    /* If/when taskPhoto updates, we need to see if it has a valid
     * img URL, and if so, use that instead of our current one.
     * This will allow us to transition from "processing" state to showing the img. */
    React.useEffect(() => {
      setThumbnailUri(
        taskPhoto?.thumbnails?.medium ||
          taskPhoto?.originalImage ||
          tempThumbnailUri,
      )
    }, [
      taskPhoto?.originalImage,
      taskPhoto?.thumbnails?.medium,
      tempThumbnailUri,
    ])

    const [timesClicked, setTimesClicked] = React.useState(0)
    const prevTimesClicked = usePrevious(timesClicked)

    const trackUploaderIssues = React.useCallback(
      (segmentEvent, includeClickCount = false) => {
        // For some reason, Segment events fail to send when including a data URI.
        // Since we don't need the URI itself, we can replace it with a simple string indicating its presence instead.
        const sanitizedThumbnailUri = (thumbnailUri || '').includes(
          'data:image',
        )
          ? '[TEMPORARY DATA URI]'
          : thumbnailUri

        /* eslint-disable @typescript-eslint/naming-convention */
        track(segmentEvent, {
          current_task_photo_count: (visit.taskPhotoIds || []).length,
          has_pending_upload: hasPendingUpload,
          original_image_uri: taskPhoto?.originalImage,
          task_photo_id: taskPhoto?.id || null,
          thumbnail_uri: sanitizedThumbnailUri,
          times_clicked: includeClickCount ? timesClicked : undefined,
          ui_disabled_state: uiDisabledState,
          ui_state: uiState.state,
          ui_state__error: uiState.error,
          user_id: user?.id,
          visit_id: visit.id,
        })
        /* eslint-enable @typescript-eslint/naming-convention */
      },
      [
        taskPhoto?.id,
        taskPhoto?.originalImage,
        hasPendingUpload,
        thumbnailUri,
        timesClicked,
        uiDisabledState,
        uiState.error,
        uiState.state,
        user?.id,
        visit.id,
        visit.taskPhotoIds,
      ],
    )

    React.useEffect(() => {
      const MAX_CLICKS = 5
      if (timesClicked !== prevTimesClicked && timesClicked >= MAX_CLICKS) {
        trackUploaderIssues(Events.fieldAppInspectionPhotoMultiClick, true)
      }
    }, [prevTimesClicked, timesClicked, trackUploaderIssues])

    const handleClick = React.useCallback(
      (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
        setTimesClicked(prev => prev + 1)

        if (!!thumbnailUri || hasPendingUpload) {
          event.preventDefault()
          // track how often we are manually preventing this dialog
          trackUploaderIssues(Events.fieldAppInspectionPreventDialog)

          if (!!thumbnailUri) {
            onThumbnailClick()
          }
        }
      },
      [hasPendingUpload, onThumbnailClick, thumbnailUri, trackUploaderIssues],
    )

    /* When we load in "pending" state, we need to send a request to re-fetch
     * the associated CleanTime, hopefully this time with a populated URL.
     * However, we need to ensure this only happens a single time. */
    React.useEffect(() => {
      if (!hasPendingUpload) return
      if (!taskPhoto?.id) return
      if (uiState.state !== 're-fetching') return

      const asyncRefetchTaskPhoto = async () => {
        await dispatch(fetchTaskPhotoById(taskPhoto.id))
        setUiState(prev =>
          produce(prev, draft => {
            draft.state = 're-fetched'
          }),
        )
      }

      asyncRefetchTaskPhoto()
    }, [dispatch, hasPendingUpload, taskPhoto?.id, uiState.state])

    //------------------------------------------------
    // Photo upload handling
    //------------------------------------------------
    const handleUploadStart = React.useCallback(() => {
      setUiState({
        error: '',
        state: 'uploading',
      })
    }, [])

    const handleUploadSuccess = React.useCallback(
      (taskPhotoId: string, file: File) => {
        setUiState(prev =>
          produce(prev, draft => {
            draft.state = 'uploaded'
          }),
        )

        onUploadSuccess(taskPhotoId, file)
      },
      [onUploadSuccess],
    )

    const handleUploadError = React.useCallback(
      err => {
        setUiState({
          error: err?.message || '',
          state: 'error',
        })

        onUploadError(err)
      },
      [onUploadError],
    )

    const { uploadPhoto } = useUploadPhoto({
      categoryId,
      onError: handleUploadError,
      onSuccess: handleUploadSuccess,
      onUploadStart: handleUploadStart,
      taskId: visit.id,
      userId: user?.id || delegateUserId,
    })

    //------------------------------------------------
    // Photo delete handling
    //------------------------------------------------
    const { deletePhoto, deletePhotoStatus } = useDeletePhoto(
      taskPhoto?.id,
      visit.id,
    )

    /* Handle changes to status when deleting photos */
    React.useEffect(() => {
      if (deletePhotoStatus === 'success') {
        setThumbnailUri('')
      }

      onDeletePhotoStatusChange(deletePhotoStatus)
    }, [deletePhotoStatus, onDeletePhotoStatusChange])

    const isLoading =
      uiState.state === 'uploading' ||
      uiState.state === 're-fetching' ||
      deletePhotoStatus === 'loading'

    return (
      <ImageUploader
        {...imageUploaderProps}
        altText={strings.inspection}
        disabled={!!uiDisabledState}
        error={uiState.error}
        fallbackText={strings.fallback}
        htmlTitle={strings.title}
        isLoading={isLoading}
        onClick={handleClick}
        onDelete={deletePhoto}
        onImageSelected={uploadPhoto}
        size={120}
        thumbnailUri={thumbnailUri}
      />
    )
  })
