import React from 'react'

import { Alert, Select } from 'packages/common'
import { User, fullName } from 'packages/grimoire'

import CoveragePartnerSelector from '../../../../CoveragePartnerSelector/CoveragePartnerSelector'
import { useCrossCoverageDrawerTranslations } from '../../../useCrossCoverageDrawerTranslations'
import St from '../../../utils/CrossCoverageDrawer.styles'

export enum AssigneeSelectionFormTestIds {
  assignedCoverageField = 'assignedCoverageField',
  overrideAssigneeButton = 'overrideAssigneeButton',
}

export type AssigneeSelectionFormProps = {
  isAssigneeBeingOverridden: boolean
  isDisabled: boolean
  isEditing: boolean
  loadOptions: (inputValue, callback) => void
  onAssigneeChange: (user: User) => void
  onAssigneeOverrideClick: () => void
  overrideSelectButtonTextValue: string
  selectedAssignee: User | undefined
  systemAssignedUser: User | undefined
  systemAssigneeOptions: User[]
}

export const AssigneeSelectionForm: React.FC<AssigneeSelectionFormProps> =
  React.memo(
    ({
      isAssigneeBeingOverridden,
      isDisabled,
      isEditing,
      onAssigneeChange,
      onAssigneeOverrideClick,
      overrideSelectButtonTextValue,
      selectedAssignee,
      systemAssigneeOptions,
      systemAssignedUser,
      loadOptions,
    }) => {
      const strings = useCrossCoverageDrawerTranslations()

      return (
        <>
          <St.FormField
            data-testid={AssigneeSelectionFormTestIds.assignedCoverageField}
          >
            <St.FormFieldLabel htmlFor={strings.requestedCoverage}>
              {strings.requestedCoverage}
              <St.FormFieldLabelRequiredAsterisk>
                *
              </St.FormFieldLabelRequiredAsterisk>
            </St.FormFieldLabel>
            {isAssigneeBeingOverridden ? (
              <CoveragePartnerSelector
                loadOptions={loadOptions}
                onUserSelectionChange={onAssigneeChange}
                selectedUser={selectedAssignee}
                disabled={isDisabled}
                label={strings.requestedCoverage}
                placeholder={'Select coverage partner'}
              />
            ) : (
              <St.FormFieldOverrideSelectButton
                data-testid={
                  AssigneeSelectionFormTestIds.overrideAssigneeButton
                }
                onClick={onAssigneeOverrideClick}
              >
                {overrideSelectButtonTextValue}
              </St.FormFieldOverrideSelectButton>
            )}
          </St.FormField>
          {isEditing && (
            <St.FormField>
              <St.FormFieldLabel htmlFor={'systemAssignee'}>
                {strings.systemAssignee}
              </St.FormFieldLabel>
              {systemAssignedUser && (
                <Select
                  disabled={true}
                  ariaLabel={'systemAssignee'}
                  getOptionLabel={user => fullName(user)}
                  id={'systemAssignee'}
                  onChange={() => void 0}
                  options={systemAssigneeOptions}
                  selectedValue={systemAssignedUser}
                />
              )}
              {!systemAssignedUser && (
                <Alert alertType="info">
                  {strings.seeDetailViewForSystemAssignee}
                </Alert>
              )}
            </St.FormField>
          )}
        </>
      )
    },
  )
