import React from 'react'

/**
 * A simple hook to provide memoization for objects based on their IDs. When using this,
 * two different object references that share identical ID values will be considered the same,
 * and you will always get the "previous" value.
 *
 * Consider this a LAST RESORT to use when you experience issues getting a selector
 * to memoize properly, and/or you have problems with object references being incorrectly
 * reset causing re-render issues.
 *
 * Note that this ONLY checks the 'id' value of the two objects, so you could very easily
 * get false positives (e.g. if a Timer's "startedAt" field has changed but its ID has not,
 * you would still get the previous version).
 *
 * Please use with caution and only when truly necessary. In most cases, you should be fine with:
 * - createSelector as memoization for Redux values
 * - React.useMemo for values that require memoization within components
 */
export function useMemoById<T extends { id: string }>(
  newValue?: T,
): T | undefined {
  const value = React.useRef<T | undefined>(undefined)

  if (newValue?.id === value.current?.id) {
    return value.current
  }

  value.current = newValue
  return newValue
}
