import { merge, isEmpty } from 'lodash/fp'
import { createSelector } from 'reselect'

import { ApplicationState } from 'app/fieldapp/store/store'

import { OtherTimer, OtherTimersState } from '../otherTimers.types'
import { hydrateRawOtherTime } from '../otherTimers.utils'

const getOtherTimersState = (state: ApplicationState): OtherTimersState =>
  state.otherTimers

const getOtherTimerId = (
  _state: ApplicationState,
  otherTimerId: string | undefined,
): string | undefined => otherTimerId

/**
 * Builds a OtherTimer that is the result of combining a non-offline OtherTimer with
 * the properties from an offline OtherTimer (with the latter taking precedence).
 *
 * Use this in place of the regular "getOtherTimeById" selector when you need to ensure
 * that you have any potential offline data merged in. (If no offline data is present,
 * it will simply return the original OtherTimer.)
 */
export const getMergedOtherTimerById = createSelector(
  getOtherTimersState,
  getOtherTimerId,
  (otherTimersState, id): OtherTimer | undefined => {
    if (!id) return undefined

    const rawOtherTime = otherTimersState.data[id] || {}

    const offlineOtherTime = otherTimersState.offlineData[id] || {}

    const noData = isEmpty(rawOtherTime) && isEmpty(offlineOtherTime)
    const partialDataNotStartedOffline =
      isEmpty(rawOtherTime) && !offlineOtherTime?.attributes?.startedOffline

    if (noData || partialDataNotStartedOffline) return undefined

    return hydrateRawOtherTime(merge(rawOtherTime, offlineOtherTime))
  },
)
