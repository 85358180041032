import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

export type DrawerState = {
  forceClose: boolean
  isOpen: boolean
}

type AdditionalDrawerUIState = Record<
  string,
  string | number | boolean | undefined
>

type ActionPayload = {
  forceClose?: boolean
  isOpen?: boolean
} & AdditionalDrawerUIState

type UseManageDrawerStateArgs<ApplicationState> = {
  /**
   * Extra data to pass to the action on closing a drawer. This is useful if we store an id in redux.
   * For insance, this could be used to set `unitId` to an empty string when closing the Unit Drawer.
   */
  closeDrawerExtraData?: AdditionalDrawerUIState
  /**
   * An action that takes at minimum `{ isOpen: boolean }` and updates redux ui state
   * Any additional key/values passed to the exposed `openDrawer` function will be passed to this action
   */
  dispatchableToggleDrawerAction: (payload: ActionPayload) => void
  /** A selector that retrieves at minimum `{ isOpen: boolean }` from redux ui state */
  selector: (state: ApplicationState) => {
    forceClose: boolean
    isOpen: boolean
  }
}

export type UseManageDrawerState = {
  closeDrawer: () => void
  completeDrawerClose: () => void
  drawerState: DrawerState
  openDrawer: (extras?: AdditionalDrawerUIState) => void
}

/** Just like `useManageDrawerState` but for cases where `isOpen` is managed in redux
 * Exposes functions for managing drawer state and correctly implementing UI transitions
 *
 * This directly interfaces with the common redux hooks, so this will blow up if used outside a Redux Provider
 * */
export const useManageConnectedDrawerState = <ApplicationState>({
  closeDrawerExtraData = {},
  dispatchableToggleDrawerAction,
  selector,
}: UseManageDrawerStateArgs<ApplicationState>): UseManageDrawerState => {
  const dispatch = useDispatch()

  const { isOpen, forceClose } = useSelector(selector)

  const openDrawer = React.useCallback(
    (extras: AdditionalDrawerUIState = {}) => {
      dispatch(
        dispatchableToggleDrawerAction({
          forceClose: false,
          isOpen: true,
          ...extras,
        }),
      )
    },
    [dispatch, dispatchableToggleDrawerAction],
  )

  const closeDrawer = React.useCallback(() => {
    dispatch(
      dispatchableToggleDrawerAction({
        forceClose: true,
      }),
    )
  }, [dispatch, dispatchableToggleDrawerAction])

  const completeDrawerClose = React.useCallback(() => {
    dispatch(
      dispatchableToggleDrawerAction({
        forceClose: false,
        isOpen: false,
        ...closeDrawerExtraData,
      }),
    )
  }, [closeDrawerExtraData, dispatch, dispatchableToggleDrawerAction])

  const drawerState = React.useMemo<DrawerState>(
    () => ({
      forceClose,
      isOpen,
    }),
    [forceClose, isOpen],
  )

  return { closeDrawer, completeDrawerClose, drawerState, openDrawer }
}
