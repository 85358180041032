import classNames from 'classnames'
import React from 'react'

import { useI18n } from 'packages/i18n'
import { IconName, SvgIcon } from 'packages/iconic'

import { Slugs } from 'app/fieldapp/i18n'

import styles from './ExternalCommentToggle.module.scss'

export type ExternalCommentToggleProps = {
  checked: boolean
  className?: string
  dataTestId?: string
  disabled?: boolean
  id?: string
  onToggle: (value: boolean) => void
}

const useTranslations = () => {
  const { ut } = useI18n()
  return {
    staffAndOwner: ut(Slugs.staffAndOwner),
    staffOnly: ut(Slugs.staffOnly),
  }
}

export const ExternalCommentToggle: React.FC<ExternalCommentToggleProps> =
  React.memo(({ checked, className, dataTestId, disabled, id, onToggle }) => {
    const strings = useTranslations()

    const [focused, setFocused] = React.useState(false)

    const toggleChecked = React.useCallback(() => {
      if (disabled) return
      onToggle(!checked)
    }, [checked, disabled, onToggle])

    return (
      <div className={styles.toggleContainer}>
        <div className={styles.toggleInnerContainer}>
          <div
            className={classNames(styles.switch, className, {
              [styles.checked]: checked,
              [styles.disabled]: disabled,
              [styles.focused]: focused,
            })}
          >
            <input
              checked={checked}
              className={styles.inputElement}
              data-testid={dataTestId}
              disabled={disabled}
              id={id}
              onBlur={() => setFocused(false)}
              onChange={toggleChecked}
              onFocus={() => setFocused(true)}
              type="checkbox"
            />

            <div className={styles.well} onClick={toggleChecked}>
              <SvgIcon
                className={classNames(styles.toggleIcon, styles.internal)}
                icon={IconName.lock}
                size={16}
              ></SvgIcon>
              <SvgIcon
                className={classNames(styles.toggleIcon, styles.external)}
                icon={IconName.users}
                size={16}
              ></SvgIcon>
              <div className={styles.tab} />
            </div>
          </div>
          <div className={styles.label}>
            {checked ? strings.staffAndOwner : strings.staffOnly}
          </div>
        </div>
      </div>
    )
  })
