import { produce } from 'immer'
import { createAsyncAction } from 'typesafe-actions'
import { v4 as uuid } from 'uuid'

import { OfflineRequestConfig } from 'packages/utils/store'

import { makeOfflineTimer } from 'app/fieldapp/components/timers/timers.helpers'
import {
  NormalizedCleanTimesApiResponse,
  CleanTimePatchData,
  CleanTimesActionTypes,
} from 'app/fieldapp/store/cleantimes'
import { offlineTimers } from 'app/fieldapp/store/utils'

type CreateCleanTimeOfflinePayload = OfflineRequestConfig<
  NormalizedCleanTimesApiResponse,
  Omit<CleanTimePatchData, 'id'>
>

type CreateCleanTimeOfflinePayloadWithId = OfflineRequestConfig<
  NormalizedCleanTimesApiResponse,
  CleanTimePatchData
>

export const createCleanTimeOfflineAction = createAsyncAction(
  CleanTimesActionTypes.OFFLINE_CREATE_CLEAN_TIME,
  CleanTimesActionTypes.OFFLINE_CREATE_CLEAN_TIME_SUCCESS,
  CleanTimesActionTypes.OFFLINE_CREATE_CLEAN_TIME_FAILURE,
)<
  CreateCleanTimeOfflinePayloadWithId,
  CreateCleanTimeOfflinePayloadWithId,
  Error
>()

export const createCleanTimeOffline =
  (payload: CreateCleanTimeOfflinePayload) => async dispatch => {
    const id = uuid()

    const updatedPayload: CreateCleanTimeOfflinePayloadWithId = produce(
      payload,
      (draft: CreateCleanTimeOfflinePayloadWithId) => {
        draft.data.id = id
        draft.data.startedOffline = true
      },
    ) as CreateCleanTimeOfflinePayloadWithId

    await offlineTimers.saveCleanTime(makeOfflineTimer(updatedPayload.data))

    await dispatch(createCleanTimeOfflineAction.success(updatedPayload))
  }
