import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { withMaintenanceMode } from 'packages/common'

import { BadDelegateLink } from './core/components'
import { ConnectedProfilePage } from './profile'
import {
  SHARED_CLEAN_INVALID_URL,
  SHARED_CLEAN_ROOT_URL,
} from './schedule/schedule.utils'
import { SchedulePage } from './schedule/SchedulePage/SchedulePage'
import { SharedCleanPageContainer } from './schedule/SharedCleanPage'
import { ConnectedTicketsPage } from './tickets'

export type FieldAppRouterProps = {
  initialUrl: string
}

const FieldAppRouter: React.FC<FieldAppRouterProps> = ({ initialUrl }) => {
  return (
    <Routes>
      <Route path={SHARED_CLEAN_INVALID_URL} element={<BadDelegateLink />} />
      <Route
        path={SHARED_CLEAN_ROOT_URL}
        element={<SharedCleanPageContainer />}
      />
      <Route
        path="/shared/*"
        element={<Navigate to={SHARED_CLEAN_INVALID_URL} replace={true} />}
      />

      <Route element={<SchedulePage />} path="/cleans" />
      <Route element={<ConnectedTicketsPage />} path="/tickets" />
      <Route element={<ConnectedProfilePage />} path="/profile" />
      <Route
        path="/oauth/callback"
        element={<Navigate to={initialUrl || '/cleans'} />}
      />
      <Route path="*" element={<Navigate to="/cleans" />} />
    </Routes>
  )
}

export default withMaintenanceMode(FieldAppRouter)
