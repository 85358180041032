import { anyPass, filter, map, pipe, prop, propEq, values } from 'lodash/fp'

import { ApplicationState } from '../../store'
import { Disposition } from '../tickets.types'
import { hydrateRawDisposition } from '../tickets.utils'

export const getHousekeepingAndMaintenanceDispositions: (
  state: ApplicationState,
) => Disposition[] = pipe(
  prop('tickets.dispositions'),
  values,
  filter(
    anyPass([
      propEq('attributes.tierOne', 'maintenance'),
      propEq('attributes.tierOne', 'housekeeping'),
    ]),
  ),
  map(hydrateRawDisposition),
)
