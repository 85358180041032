import { produce } from 'immer'
import { ActionType, getType } from 'typesafe-actions'

import {
  fetchCleanByIdAction,
  fetchCleansAction,
  fetchSharedCleanByIdAction,
} from '../cleans/actions'
import { fetchVisitsAction, fetchVisitByIdAction } from '../visits/actions'
import { SmartLocksState } from './smartlocks.types'
import { emptyNormalizedSmartLocksData } from './smartlocks.utils'

const initialState: SmartLocksState = {
  data: {},
  error: undefined,
}

const actions = {
  fetchCleanByIdAction,
  fetchCleansAction,
  fetchSharedCleanByIdAction,
  fetchVisitByIdAction,
  fetchVisitsAction,
}

type SmartLockActionsTypes = ActionType<typeof actions>

export const smartLocksReducer = (
  state = initialState,
  action: SmartLockActionsTypes,
): SmartLocksState =>
  produce(state, (draft: SmartLocksState) => {
    switch (action.type) {
      case getType(fetchVisitByIdAction.success):
      case getType(fetchVisitsAction.success):
      case getType(fetchCleanByIdAction.success):
      case getType(fetchCleansAction.success):
      case getType(fetchSharedCleanByIdAction.success): {
        const normalized =
          action.payload?.normalized || emptyNormalizedSmartLocksData

        // save the smartLock data to usual spot
        const normalizedSmartLockData = normalized.smartLock || {}
        draft.error = undefined
        draft.data = {
          ...state.data,
          ...normalizedSmartLockData,
        }

        return
      }
    }
  })
