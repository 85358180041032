import * as React from 'react'

import { useOnlineStatus } from 'packages/utils/hooks'

import {
  useOfflineMergedTimers,
  useOtherTimerActions,
  useTimersRequestPending,
} from 'app/fieldapp/components/timers/hooks'
import {
  OtherTimerSubType,
  OtherTimerType,
} from 'app/fieldapp/store/otherTimers'
import { useActiveUser } from 'app/fieldapp/utils/hooks/useActiveUser'

import { useTimers as useTimersContext } from '../../state/timers.context'
import { useTimersSubMenu } from './hooks/useTimersSubMenu'
import { OtherTimers } from './OtherTimers'

export const OtherTimersContainer: React.FC = () => {
  const { isAgency, isContractor, user } = useActiveUser()

  const { isOnline } = useOnlineStatus()

  const { displayedTimerConfig, isSubMenuOpen, setDisplayedTimerConfigType } =
    useTimersSubMenu()

  const { offlineTimersCount } = useOfflineMergedTimers()
  const requestPending = useTimersRequestPending()
  const { dispatchCreateOtherTime } = useOtherTimerActions()
  const { state: timersState } = useTimersContext()
  const { loadingTimerId } = timersState

  const isDisabled = (offlineTimersCount > 0 && isOnline()) || requestPending
  const [isExpanded, setIsExpanded] = React.useState(false)
  const toggleIsExpanded = () => setIsExpanded(!isExpanded)

  const handleStartOtherTimer = React.useCallback(
    async (timerType: OtherTimerType, subTimerType?: OtherTimerSubType) => {
      if (user)
        dispatchCreateOtherTime({ subTimerType, timerType, userId: user.id })
    },
    [user, dispatchCreateOtherTime],
  )

  const otherTimerDrawerStatus = React.useMemo(
    () => ({
      isDisabled,
      isExpanded,
      isSubMenuOpen,
      loadingTimerId,
    }),
    [isDisabled, isExpanded, isSubMenuOpen, loadingTimerId],
  )

  return !isAgency && !isContractor ? (
    <OtherTimers
      displayedTimerConfig={displayedTimerConfig}
      onOpenMenu={setDisplayedTimerConfigType}
      onStartOtherTimer={handleStartOtherTimer}
      otherTimerDrawerStatus={otherTimerDrawerStatus}
      toggleIsExpanded={toggleIsExpanded}
    />
  ) : null
}
