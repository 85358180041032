/* eslint-disable @typescript-eslint/naming-convention */
import { ActionType, getType } from 'typesafe-actions'

import { isPWA } from 'packages/utils/pwa'
import { track, Events } from 'packages/wiretap'

import {
  createCleanTimeAction,
  createFinalizedCleanTimeAction,
} from 'app/fieldapp/store/cleantimes/actions'
import {
  createFinalizedOtherTimerAction,
  createOtherTimerAction,
} from 'app/fieldapp/store/otherTimers/actions'
import { ApplicationState } from 'app/fieldapp/store/store'
import {
  createFinalizedTicketTimeAction,
  createTicketTimeAction,
} from 'app/fieldapp/store/ticket-times/actions'
import { getActiveUser } from 'app/fieldapp/store/users/selectors'

const actions = {
  createCleanTimeAction,
  createFinalizedCleanTimeAction,
  createFinalizedOtherTimerAction,
  createFinalizedTicketTimeAction,
  createOtherTimerAction,
  createTicketTimeAction,
}

export const createTimerActions = new Set(
  Object.values(actions).map(a => a.success.toString()),
)

type CreateTimerActions = ActionType<typeof actions>

enum TimerName {
  CLEAN = 'clean',
  OTHER = 'other',
  TICKET = 'ticket',
}

type ActionProps = {
  started_at: string
  started_offline?: boolean
  stopped_at?: string | null
  timer_name: TimerName
}

function getPropsFromAction(action: CreateTimerActions): ActionProps {
  switch (action.type) {
    case getType(createFinalizedCleanTimeAction.success): {
      const cleanTime = Object.values(
        action.payload.result.normalized.cleanTime,
      )[0]

      return {
        started_at: cleanTime.attributes.startedAt,
        started_offline: true,
        stopped_at: cleanTime.attributes.stoppedAt,
        timer_name: TimerName.CLEAN,
      }
    }

    case getType(createCleanTimeAction.success): {
      const cleanTime = Object.values(action.payload.normalized.cleanTime)[0]
      return {
        started_at: cleanTime.attributes.startedAt,
        timer_name: TimerName.CLEAN,
      }
    }

    case getType(createFinalizedOtherTimerAction.success): {
      const otherTimer = Object.values(
        // TODO: rename to other timer when API ready
        action.payload.result.normalized.taskTime,
      )[0]

      return {
        started_at: otherTimer.attributes.startedAt,
        started_offline: true,
        stopped_at: otherTimer.attributes.stoppedAt,
        timer_name: TimerName.OTHER,
      }
    }

    case getType(createOtherTimerAction.success): {
      // TODO: rename to other timer when API ready
      const otherTimer = Object.values(action.payload.normalized.taskTime)[0]
      return {
        started_at: otherTimer.attributes.startedAt,
        timer_name: TimerName.OTHER,
      }
    }

    case getType(createFinalizedTicketTimeAction.success): {
      const ticketTime = Object.values(
        action.payload.result.normalized.ticketTime,
      )[0]

      return {
        started_at: ticketTime.attributes.startedAt,
        started_offline: true,
        stopped_at: ticketTime.attributes.stoppedAt,
        timer_name: TimerName.TICKET,
      }
    }

    case getType(createTicketTimeAction.success): {
      const ticketTime = Object.values(action.payload.normalized.ticketTime)[0]
      return {
        started_at: ticketTime.attributes.startedAt,
        timer_name: TimerName.TICKET,
      }
    }

    default:
      throw new Error(`Invalid action type (${action.type})`)
  }
}

export function trackCreateTimer(
  action: CreateTimerActions,
  state: ApplicationState,
): void {
  const user = getActiveUser(state)
  const user_id = user?.id
  const pwa = isPWA()

  const { started_offline, started_at, stopped_at, timer_name } =
    getPropsFromAction(action)

  track(Events.fieldAppTimerNew, {
    action: 'new_timer',
    pwa,
    started_at,
    started_offline,
    stopped_at,
    timer_name,
    user_id,
  })
}
