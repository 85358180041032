import classNames from 'classnames'
import upperFirst from 'lodash/upperFirst'
import React from 'react'

import { CleanCheckOutDisplay } from 'packages/common'

import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { Clean } from 'app/fieldapp/store/cleans/cleans.types'
import { cleanDueDateString } from 'app/fieldapp/utils'

import styles from '../../CleanDetail.module.scss'

export enum DateInfoTestIds {
  cleanCheckOutDisplay = 'dateInfo__cleanCheckOutDisplay',
}

export type DateInfoProps = {
  clean: Clean
}

export const DateInfo: React.FC<DateInfoProps> = React.memo(({ clean }) => {
  const { t } = useI18n()

  const dueDateString = cleanDueDateString(clean)

  return (
    <section className={classNames(styles.section, styles.dateInfoSection)}>
      <div className={styles.dateInfoContainer}>
        <div>
          <strong>{upperFirst(t(Slugs.startTime))}</strong>
        </div>
        <CleanCheckOutDisplay
          clean={clean}
          dataTestId={DateInfoTestIds.cleanCheckOutDisplay}
        />
      </div>

      <div className={classNames(styles.dateInfoContainer, styles.dueDate)}>
        <div>
          <strong>{upperFirst(t(Slugs.due))}</strong>
        </div>
        <div>{dueDateString}</div>
      </div>
    </section>
  )
})
