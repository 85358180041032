import { createSelector } from 'reselect'

import { transformNormalizedToTyped } from 'packages/utils/store/store.utils'

import { ApplicationState } from '../../store'
import { Lockbox, LockboxAttributeNames } from '../lockboxes.types'

export const getLockboxById = createSelector(
  (state: ApplicationState) => state.lockboxes,
  (_, id: string) => id,
  (lockboxesState, lockboxId): Lockbox | undefined => {
    const rawLockbox = lockboxesState.data?.[lockboxId]
    return (
      rawLockbox &&
      transformNormalizedToTyped<Lockbox>(rawLockbox, LockboxAttributeNames)
    )
  },
)
