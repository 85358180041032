import React from 'react'

import { createDateObject, isSameDay } from 'packages/utils/dateHelpers'

import { useAppSelector } from 'app/fieldapp/store/hooks'
import { getReservationById } from 'app/fieldapp/store/reservations/selectors'
import { ApplicationState } from 'app/fieldapp/store/store'
import { getVisitDrawerState } from 'app/fieldapp/store/ui/selectors'
import { getUnitById } from 'app/fieldapp/store/units/selectors'
import { getVisitById } from 'app/fieldapp/store/visits/selectors'

import { VisitStatusIcons } from './VisitStatusIcons'

export const VisitStatusIconsContainer: React.FC = React.memo(() => {
  const { visitId } = useAppSelector(getVisitDrawerState)

  const visit = useAppSelector((state: ApplicationState) =>
    getVisitById(state, visitId),
  )

  const unit = useAppSelector((state: ApplicationState) =>
    getUnitById(state, visit?.unitId),
  )

  const currentRes = useAppSelector((state: ApplicationState) =>
    getReservationById(state, unit?.currentResId || ''),
  )

  const nextRes = useAppSelector((state: ApplicationState) =>
    getReservationById(state, unit?.nextResId || ''),
  )

  const isCheckIn = !!nextRes && isSameDay(nextRes?.checkIn, createDateObject())
  const isLateCheckout = !!currentRes?.lateCheckout
  const isOwnerVisit = !!nextRes && nextRes.bookingType === 'owner_hold'

  return (
    <VisitStatusIcons
      isCheckIn={isCheckIn}
      isLateCheckout={isLateCheckout}
      isOwnerVisit={isOwnerVisit}
    />
  )
})
