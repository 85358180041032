import { produce } from 'immer'
import { merge } from 'lodash'
import { ActionType, getType } from 'typesafe-actions'

import { fetchContactsByUnitIdAction } from './actions/fetchContactsByUnitId'
import { ContactsState } from './contacts.types'
import { emptyNormalizedContactData } from './contacts.utils'

export const initialState: ContactsState = {
  data: {},
}

const actions = {
  fetchContactsByUnitIdAction,
}

type Actions = ActionType<typeof actions>

export const contactsReducer = (
  state = initialState,
  action: Actions,
): ContactsState =>
  produce(state, (draft: ContactsState) => {
    switch (action.type) {
      case getType(fetchContactsByUnitIdAction.success): {
        const normalized =
          action.payload.normalized || emptyNormalizedContactData

        if (normalized.contact) {
          Object.values(normalized.contact).forEach(contact => {
            const existingContact = state.data[contact.id] || {}

            const mergedContact = merge(existingContact, contact)
            draft.data[contact.id] = mergedContact
          })
        }

        return
      }
    }
  })
