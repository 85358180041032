import { createAsyncThunk } from '@reduxjs/toolkit'

import { DateFormat, format } from 'packages/utils/dateHelpers'
import { RequestOptions } from 'packages/utils/store/jsonapi.types'

import { ApplicationState } from '../../store'
import { crossCoverageService } from '../crossCoverage.service'
import { CrossCoverageApiFields } from '../crossCoverage.types'

type Params = {
  startDateGte?: Date
  userCovered?: string
}

export const getParams = (params: Params) => {
  let normalizedParams: RequestOptions = {
    filter: {
      is_active_or_upcoming: true,
    },
  }

  if (params.startDateGte) {
    normalizedParams = {
      ...normalizedParams,
      filter: {
        ...normalizedParams.filter,
        start_date: {
          gte: format(params.startDateGte, DateFormat.ApiUtcShort),
        },
      },
    }
  }

  if (params.userCovered) {
    normalizedParams = {
      ...normalizedParams,
      filter: {
        ...normalizedParams.filter,
        user_covered: params.userCovered,
      },
    }
  }

  return {
    fields: {
      cross_coverage: CrossCoverageApiFields,
    },
    include: ['user_covered', 'user_covering'],
    ...normalizedParams,
  }
}

export const fetchCrossCoverages = createAsyncThunk(
  'crossCoverages/api/fetchCrossCoverages',
  async (params: Params, { getState }) => {
    if (params.userCovered === undefined) {
      params.userCovered = (getState() as ApplicationState).users.activeUserId
    }

    const response = await crossCoverageService.fetchCrossCoverages(
      getParams(params),
    )

    return response
  },
)
