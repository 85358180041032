import styled from '@emotion/styled'
import React from 'react'

import { useI18n } from 'packages/i18n'
import { IconName, SvgIcon } from 'packages/iconic'
import { centerWithFlex } from 'packages/styles'

import { Slugs } from 'app/fieldapp/i18n'

const St = {
  Container: styled.div`
    ${centerWithFlex};

    flex-direction: column;
    grid-gap: 16px;
    padding-top: 40%;
    width: 100%;
  `,

  MessageText: styled.div`
    font-size: 15px;
    width: 80%;
  `,

  MessageTitle: styled.div`
    font-size: 22px;
    font-weight: 700;
  `,
}

const useTranslations = () => {
  const { t } = useI18n()

  return {
    text: t(Slugs.inspectionCompletedText),
    title: t(Slugs.inspectionCompletedTitle),
  }
}

export enum CompletedVisitInspectionTestIds {
  container = 'CompletedVisitInspection__container',
}

export const CompletedVisitInspection: React.FC = React.memo(() => {
  const strings = useTranslations()
  return (
    <St.Container data-testid={CompletedVisitInspectionTestIds.container}>
      <SvgIcon icon={IconName.party} size={42} />
      <St.MessageTitle>{strings.title}</St.MessageTitle>
      <St.MessageText>{strings.text}</St.MessageText>
    </St.Container>
  )
})
