import { createAsyncAction } from 'typesafe-actions'

import {
  OfflineTicketTime,
  TicketTimesActionTypes,
} from 'app/fieldapp/store/ticket-times'
import { offlineTimers } from 'app/fieldapp/store/utils'

export const reloadOfflineTicketTimesAction = createAsyncAction(
  TicketTimesActionTypes.RELOAD_OFFLINE_TICKET_TIMES,
  TicketTimesActionTypes.RELOAD_OFFLINE_TICKET_TIMES_SUCCESS,
  TicketTimesActionTypes.RELOAD_OFFLINE_TICKET_TIMES_FAILURE,
)<null, Record<string, OfflineTicketTime>, Error>()

export const reloadOfflineTicketTimes = () => async dispatch => {
  const offlineTicketTimes = await offlineTimers.getTicketTimes()
  dispatch(reloadOfflineTicketTimesAction.success(offlineTicketTimes))
}
