import styled from '@emotion/styled'

import { CloseButton } from 'packages/common/src/modals/components'
import { centerWithFlex, colors, text } from 'packages/styles'

const HEADER_CONTROLS_HEIGHT = 72
const HEADER_TAB_HEIGHT = 44

export const TaskDetailWrapperStyles = {
  CloseButton: styled(CloseButton)`
    right: 16px;
    top: 24px;
  `,
  Header: styled.div`
    align-items: center;
    background-color: ${colors.midnight4};
    box-shadow: 0 5px 11px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: sticky;
    top: 0;
    z-index: 1;
  `,
  HeaderControls: styled.div`
    ${centerWithFlex};
    height: ${HEADER_CONTROLS_HEIGHT}px;
    margin-right: auto;
    padding-left: 16px;
  `,
  Tab: styled.div<{ active: boolean }>`
    ${text.bodyBold};
    border-bottom: ${({ active }) =>
      active ? `4px ${colors.lake} solid` : 'none'};
    color: ${({ active }) => (active ? colors.lake : colors.midnight70)};
    cursor: pointer;
    flex-grow: 1;
    height: ${HEADER_TAB_HEIGHT}px;
    line-height: ${HEADER_TAB_HEIGHT}px;
    text-align: center;
    text-transform: capitalize;

    &:hover {
      border-bottom: 4px ${colors.lake} solid;
      color: ${colors.lake};
    }
  `,
  TabWrapper: styled.div`
    border-top: 1px solid ${colors.midnight20};
    display: flex;
    margin-bottom: 0;
    width: 100%;
  `,
}
