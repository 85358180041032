import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getType } from 'typesafe-actions'

import { NormalizedJSONApiResponse } from 'packages/utils/store'

import { AssignmentsResponse } from '../assignments'
import { rejectAssignmentAction } from '../assignments/actions'
import { setBanner } from './actions'
import { UiState } from './ui.types'

export const initialState: UiState = {
  banner: {
    link: undefined,
    message: '',
  },
  drawers: {
    CrossCoverageDrawer: {
      crossCoverageId: undefined,
      forceClose: false,
      isOpen: false,
      systemAssignedUserId: undefined,
    },
    onCallTaskDrawer: { coverageId: '', forceClose: false, isOpen: false },
    reviewDrawer: { forceClose: false, isOpen: false, reviewId: '' },
    standardAvailabilityDrawer: { forceClose: false, isOpen: false },
    standardCoveragePartnerDrawer: { forceClose: false, isOpen: false },
    visitDrawer: {
      forceClose: false,
      isOpen: false,
      tab: 'unitInfo',
      visitId: '',
    },
    visitTicketDrawer: { forceClose: false, isOpen: false, ticketId: '' },
  },
  modals: {
    cleanRejection: {
      isRejecting: false,
    },
  },
}

type AssignmentsActions = PayloadAction<
  NormalizedJSONApiResponse<AssignmentsResponse>,
  string
>

const rejectsAssignmentsRequest = (
  action: AssignmentsActions,
): action is AssignmentsActions => {
  switch (action.type) {
    case getType(rejectAssignmentAction.request):
      return true
    default:
      return false
  }
}

const rejectAssignmentSuccessOrFailure = (
  action: AssignmentsActions,
): action is AssignmentsActions => {
  switch (action.type) {
    case getType(rejectAssignmentAction.success):
    case getType(rejectAssignmentAction.failure):
      return true
    default:
      return false
  }
}

const uiSlice = createSlice({
  extraReducers: builder => {
    builder
      .addCase(setBanner.fulfilled, (state, action) => {
        state.banner.link = action.payload.link
        state.banner.message = action.payload.message
      })
      .addMatcher(rejectsAssignmentsRequest, state => {
        state.modals.cleanRejection.isRejecting = true
      })
      .addMatcher(rejectAssignmentSuccessOrFailure, state => {
        state.modals.cleanRejection.isRejecting = false
      })
  },
  initialState,
  name: 'ui',
  reducers: {
    clearBanner: state => {
      state.banner.link = undefined
      state.banner.message = ''
    },
    setCrossCoverageDrawer: (state, action) => {
      const {
        crossCoverageId = undefined,
        isOpen,
        forceClose,
        systemAssignedUserId = undefined,
      } = action.payload

      state.drawers.CrossCoverageDrawer.crossCoverageId = crossCoverageId
      state.drawers.CrossCoverageDrawer.systemAssignedUserId =
        systemAssignedUserId

      if (forceClose !== undefined) {
        state.drawers.CrossCoverageDrawer.forceClose = forceClose
      }

      if (isOpen !== undefined) {
        state.drawers.CrossCoverageDrawer.isOpen = isOpen
      }
    },
    setOnCallTaskDrawer: (state, action) => {
      const { coverageId = '', isOpen, forceClose } = action.payload

      if (forceClose !== undefined) {
        state.drawers.onCallTaskDrawer.forceClose = forceClose
      }

      if (isOpen !== undefined) {
        state.drawers.onCallTaskDrawer.isOpen = isOpen
      }

      state.drawers.onCallTaskDrawer.coverageId = coverageId
    },
    setReviewDrawer: (state, action) => {
      const { isOpen, forceClose, reviewId = '' } = action.payload

      if (forceClose !== undefined) {
        state.drawers.reviewDrawer.forceClose = forceClose
      }

      if (isOpen !== undefined) {
        state.drawers.reviewDrawer.isOpen = isOpen
      }

      state.drawers.reviewDrawer.reviewId = reviewId
    },
    setStandardAvailabilityDrawer: (state, action) => {
      const { isOpen, forceClose } = action.payload

      if (forceClose !== undefined) {
        state.drawers.standardAvailabilityDrawer.forceClose = forceClose
      }

      if (isOpen !== undefined) {
        state.drawers.standardAvailabilityDrawer.isOpen = isOpen
      }
    },
    setStandardCoveragePartnerDrawer: (state, action) => {
      const { isOpen, forceClose } = action.payload

      if (isOpen !== undefined) {
        state.drawers.standardCoveragePartnerDrawer.isOpen = isOpen
      }

      if (forceClose !== undefined) {
        state.drawers.standardCoveragePartnerDrawer.forceClose = forceClose
      }
    },
    setVisitDrawer: (state, action) => {
      const prevDrawerState = state.drawers.visitDrawer
      const {
        isOpen = prevDrawerState.isOpen,
        tab = prevDrawerState.tab || 'unitInfo',
        visitId = prevDrawerState.visitId,
      } = action.payload

      state.drawers.visitDrawer.isOpen = isOpen
      state.drawers.visitDrawer.tab = tab
      state.drawers.visitDrawer.visitId = visitId
    },
    setVisitTicketDrawer: (state, action) => {
      const { isOpen, forceClose, ticketId = '' } = action.payload

      if (forceClose !== undefined) {
        state.drawers.visitTicketDrawer.forceClose = forceClose
      }

      if (isOpen !== undefined) {
        state.drawers.visitTicketDrawer.isOpen = isOpen
      }

      state.drawers.visitTicketDrawer.ticketId = ticketId
    },
  },
})

export default uiSlice.reducer

export const {
  clearBanner,
  setVisitDrawer,
  setVisitTicketDrawer,
  setStandardAvailabilityDrawer,
  setOnCallTaskDrawer,
  setStandardCoveragePartnerDrawer,
  setCrossCoverageDrawer,
  setReviewDrawer,
} = uiSlice.actions
