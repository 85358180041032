import { createSelector } from 'reselect'

import { ApplicationState } from '../../store'
import { InspectionCategory } from '../../tasks'
import { hydrateRawInspectionFlag } from '../inspectionFlag.utils'
import {
  InspectionFlag,
  JSONApiInspectionFlagMap,
} from '../inspectionFlags.types'

const getInspectionFlagsData = (
  state: ApplicationState,
): JSONApiInspectionFlagMap => state.inspectionFlags.data

type categoryAndItemId = {
  category: InspectionCategory
  itemId: string
}

const getItemId = (_, categoryAndItemId: categoryAndItemId) => categoryAndItemId

export const getInspectionFlagByItemId = createSelector(
  getInspectionFlagsData,
  getItemId,
  (inspectionFlagData, categoryAndItemId): InspectionFlag | undefined => {
    const { category, itemId } = categoryAndItemId
    const raw = Object.values(inspectionFlagData).find(
      flag =>
        flag.attributes.itemId === itemId &&
        flag.attributes.categoryId === category.id,
    )
    if (!raw) return undefined

    return hydrateRawInspectionFlag(raw)
  },
)
