import { RequestOptions } from 'packages/utils/store/jsonapi.types'

import {
  TasksServiceResponse,
  TasksApiResponse,
} from 'app/fieldapp/store/tasks'

import { api } from '../axiosInstance'
import { emptyTasksResponse } from './tasks.utils'

const URL = '/tasks'

export const tasksService = {
  async createTask(data: RequestOptions): TasksServiceResponse {
    const response: TasksApiResponse = await api.post(URL, data)

    return response?.data || emptyTasksResponse
  },

  async fetchTasks(params: RequestOptions = {}): TasksServiceResponse {
    const response: TasksApiResponse = await api.get(URL, {
      params,
    })
    return response?.data || emptyTasksResponse
  },

  async fetchTaskById(
    id: string,
    params: RequestOptions = {},
  ): TasksServiceResponse {
    const response: TasksApiResponse = await api.get(`${URL}/${id}`, {
      params,
    })
    return response?.data || emptyTasksResponse
  },

  async updateTask(
    taskId: string,
    requestOptions: RequestOptions,
  ): TasksServiceResponse {
    const response: TasksApiResponse = await api.patch(
      `${URL}/${taskId}`,
      requestOptions,
    )
    return response?.data || emptyTasksResponse
  },

  async deleteTask(id: string): TasksServiceResponse {
    const response: TasksApiResponse = await api.delete(`${URL}/${id}`)
    return response?.data || emptyTasksResponse
  },
}
