import type { Dictionary } from 'lodash'
import { pipe, groupBy, values, prop, map } from 'lodash/fp'
import { createSelector } from 'reselect'

import {
  createDateRange,
  DateFormat,
  formatLocalized,
} from 'packages/utils/dateHelpers'
import { getSystemTZ } from 'packages/utils/timezone.helpers'

import { ApplicationState } from '../../store'
import {
  ComputedWorkingStatus,
  CoverageType,
  isWorkingCoverageTypes,
  ManyComputedWorkingStatuses,
  RawCoverage,
} from '../coverage.types'

const formatAsDate = date =>
  formatLocalized(date, DateFormat.ApiUtcShort, getSystemTZ())

const groupByStartDate: (
  coverageRecords: RawCoverage[],
) => Record<string, RawCoverage[]> = groupBy(
  pipe(prop('attributes.start'), formatAsDate),
)

export type CoverageTypeAndUsers = {
  coverageType: CoverageType
  requestedUserId?: string
  userId?: string
}

const getCoverageTypeAndUsers: (
  coverageRecords: RawCoverage[],
) => CoverageTypeAndUsers[] = coverageRecords =>
  map((coverageRecord: RawCoverage) => ({
    coverageType: coverageRecord.attributes.coverageType,
    requestedUserId: coverageRecord.relationships.requestedUser.data?.id,
    userId: coverageRecord?.relationships?.user?.data?.id,
  }))(coverageRecords)

export const getWorkingStatusFromCoverageTypeAndUsers = (
  coverageTypeAndUsers: CoverageTypeAndUsers,
): ComputedWorkingStatus => {
  const isRequested =
    coverageTypeAndUsers.requestedUserId &&
    coverageTypeAndUsers.userId !== coverageTypeAndUsers.requestedUserId
  const isOncall = isWorkingCoverageTypes.includes(
    coverageTypeAndUsers.coverageType,
  )

  if (isRequested) return 'requested'
  if (isOncall) return 'oncall'
  return 'covered'
}

export const getWorkingStatusFromManyCoverageTypeAndUsers = (
  coverageTypeAndUsers: CoverageTypeAndUsers[],
): ManyComputedWorkingStatuses => {
  const workingStatuses = coverageTypeAndUsers.map(
    getWorkingStatusFromCoverageTypeAndUsers,
  )

  const isRequested =
    workingStatuses.filter(status => status !== 'requested').length === 0
  const isOncall =
    workingStatuses.filter(status => status !== 'oncall').length === 0
  const isCovered =
    workingStatuses.filter(status => status !== 'covered').length === 0

  if (isRequested) return 'requested'
  if (isOncall) return 'oncall'
  if (isCovered) return 'covered'

  return 'multiple'
}

const getCoverageState = (state: ApplicationState) => state.coverage
const getDateBounds = (_, dateBounds: [string, string]) => dateBounds

/** For a date range, get an array of equal length where every entry is a computed working status  */
export const getWorkingStatusesForDateAndRange = createSelector(
  getCoverageState,
  getDateBounds,
  (coverageState, [startDate, endDate]): ComputedWorkingStatus[] => {
    const dateToCoverageMap: Dictionary<RawCoverage[]> = pipe(
      values,
      groupByStartDate,
    )(coverageState.data)

    const dateRange = createDateRange({
      endDate,
      format: formatAsDate,
      startDate,
    })

    return pipe(
      map((date: string) => dateToCoverageMap[date]),
      map(getCoverageTypeAndUsers),
      map(getWorkingStatusFromManyCoverageTypeAndUsers),
    )(dateRange)
  },
)
