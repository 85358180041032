import { AxiosResponse } from 'axios'
import { difference, snakeCase } from 'lodash/fp'

import {
  AllReservationAttributes,
  AllReservationAttributeNames,
} from 'packages/grimoire'
import {
  JSONApiObject,
  JSONApiObjectMap,
  NormalizedJSONApiResponse,
} from 'packages/grimoire/src/utils'

export * from 'packages/grimoire/src/reservation'

/**********************************************************
 * REDUX TYPES
 *********************************************************/
export type ReservationsState = {
  data: JSONApiReservationsMap
}

export enum ReservationsActionTypes {
  FETCH_RESERVATION_BY_ID = 'RESERVATIONS/API/FETCH_RESERVATIONS_BY_ID',
  FETCH_RESERVATION_BY_ID_FAILURE = 'RESERVATIONS/API/FETCH_RESERVATIONS_BY_ID_FAILURE',
  FETCH_RESERVATION_BY_ID_SUCCESS = 'RESERVATIONS/API/FETCH_RESERVATIONS_BY_ID_SUCCESS',
}

/**********************************************************
 * SERVICE TYPES
 *********************************************************/

export type ReservationsResponse = {
  reservation: JSONApiReservationsMap
}

export type NormalizedReservationsApiResponse =
  NormalizedJSONApiResponse<ReservationsResponse>

export type ReservationsApiResponse =
  AxiosResponse<NormalizedReservationsApiResponse>

export type ReservationsServiceResponse =
  Promise<NormalizedReservationsApiResponse>

/**********************************************************
 * RESERVATION
 *********************************************************/

export type Reservation = {
  id: string
} & ReservationAttributes

export type ReservationAttributes = Omit<
  AllReservationAttributes,
  'guestEmail' | 'guestFirstName' | 'guestLastName' | 'guestPhone'
>

export const ReservationAttributeNames = difference(
  AllReservationAttributeNames,
)(['guestEmail', 'guestFirstName', 'guestLastName', 'guestPhone'])

export const ReservationApiFields: string[] =
  ReservationAttributeNames.map(snakeCase)

export type RawReservation = JSONApiObject<ReservationAttributes>

export type JSONApiReservationsMap = JSONApiObjectMap<ReservationAttributes>
