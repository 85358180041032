import { createAsyncAction } from 'typesafe-actions'

import { makeRelationship } from 'packages/grimoire/src/utils/jsonapi.utils'
import {
  RequestConfig,
  RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import { pendingImageUploads } from '../taskPhotos.db'
import { taskPhotosService } from '../taskPhotos.service'
import {
  TaskPhotoPostApiAttributes,
  TaskPhotoPostApiRelationships,
  TaskPhotoPostAttributes,
  TaskPhotosActionTypes,
  NormalizedTaskPhotosApiResponse,
} from '../taskPhotos.types'

export const createTaskPhotoAction = createAsyncAction(
  TaskPhotosActionTypes.CREATE_TASK_PHOTO,
  TaskPhotosActionTypes.CREATE_TASK_PHOTO_SUCCESS,
  TaskPhotosActionTypes.CREATE_TASK_PHOTO_FAILURE,
)<
  RequestConfig<NormalizedTaskPhotosApiResponse>,
  NormalizedTaskPhotosApiResponse,
  Error
>()

export const buildRequestData = (
  postData: TaskPhotoPostAttributes,
): RequestOptions => {
  const { categoryId, taskId, userId } = postData

  const attributes: TaskPhotoPostApiAttributes = {
    category_id: categoryId,
  }

  const relationships: TaskPhotoPostApiRelationships = {
    task: makeRelationship('task', taskId),
    uploaded_by: makeRelationship('user', userId),
  }

  return {
    data: {
      attributes,
      relationships,
      type: 'task_photo',
    },
  }
}

export const createTaskPhoto =
  (postData: TaskPhotoPostAttributes) => async dispatch => {
    try {
      const requestData = buildRequestData(postData)
      const request = taskPhotosService.createTaskPhoto.bind(null, requestData)
      const result: NormalizedTaskPhotosApiResponse = await dispatch(
        createTaskPhotoAction.request({ request }),
      )
      dispatch(createTaskPhotoAction.success(result))

      // return the new object itself, since we need to use this directly at the call site
      const taskPhoto = Object.values(result.normalized.taskPhoto)[0]
      pendingImageUploads.add(taskPhoto?.id)
      return taskPhoto
    } catch (error) {
      dispatch(createTaskPhotoAction.failure(error))
      throw error
    }
  }
