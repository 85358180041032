import { createSelector } from 'reselect'

import { ApplicationState } from '../../store'
import { Clean } from '../cleans.types'
import { getCleans } from './getCleans'

const getFullState = (state: ApplicationState): ApplicationState => state
const getCleanId = (_state: ApplicationState, id: string) => id

export const getCleanById = createSelector(
  getFullState,
  getCleanId,
  (state, cleanId): Clean | undefined =>
    getCleans(state).find(clean => clean.id === cleanId),
)
