import styled from '@emotion/styled'
import React from 'react'

import { IconName, SvgIcon } from 'packages/iconic'
import { colors } from 'packages/styles'

export type FileListItemProps = {
  file: File
  onClick?: () => void
  onRemove: () => void
}

const St = {
  FileIcon: styled(SvgIcon)`
    margin-right: 8px;
  `,
  FileNameWrapper: styled.div`
    display: flex;
    align-items: center;
    flex-grow: 1;
    min-width: 0;
  `,
  RemoveFileButton: styled.button`
    background: none;
    border: none;
    cursor: pointer;
    color: ${colors.midnight60};
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      color: ${colors.midnight};
    }
  `,
  StyledFileItem: styled.div`
    padding: 5px 10px;
    background-color: ${colors.midnight2};
    border: 1px solid ${colors.midnight30};
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
  `,
  StyledFileName: styled.div`
    font-size: 14px;
    color: ${colors.midnight};
    flex: auto 0 0;
    margin-right: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 60px);

    ${({ onClick }) =>
      onClick &&
      `
    cursor: pointer;
    color: #0078ab;
    text-decoration: underline;
    
    &:hover {
      text-decoration: none;
    }
  `}
  `,
}

export const FileUploaderItem: React.FC<FileListItemProps> = ({
  file,
  onRemove,
  onClick,
}) => {
  return (
    <St.StyledFileItem data-testid={`attachment-item-${file.name}`}>
      <St.FileNameWrapper>
        <St.FileIcon icon={IconName.file} size={20} />
        <St.StyledFileName title={file.name} onClick={onClick}>
          {file.name}
        </St.StyledFileName>
      </St.FileNameWrapper>
      <St.RemoveFileButton
        onClick={onRemove}
        data-testid="delete-attachment-button"
      >
        <SvgIcon icon={IconName.x} size={25} />
      </St.RemoveFileButton>
    </St.StyledFileItem>
  )
}
