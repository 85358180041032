import React from 'react'

import { UseManageDrawerState } from 'packages/common'

import { UpcomingCoverageEventsContainer } from '../UpcomingCoverageEvents'
import { CrossCoverageFormContainer } from './CrossCoverageForm'
import { useCrossCoverageDrawerTranslations } from './useCrossCoverageDrawerTranslations'
import St from './utils/CrossCoverageDrawer.styles'

export type CrossCoverageDrawerProps = {
  drawerManager: UseManageDrawerState
  isEditCrossCoverage: boolean
}

export const CrossCoverageDrawer: React.FC<CrossCoverageDrawerProps> = ({
  drawerManager,
  isEditCrossCoverage,
}) => {
  const strings = useCrossCoverageDrawerTranslations()

  return (
    <St.Drawer
      afterExit={drawerManager.completeDrawerClose}
      forceClose={drawerManager.drawerState.forceClose}
      isOpen={drawerManager.drawerState.isOpen}
    >
      <St.Header>
        {isEditCrossCoverage
          ? strings.editCoverageRequest
          : strings.newCrossCoverageRequest}
      </St.Header>
      <St.CoverageDrawerContents>
        <CrossCoverageFormContainer />
        <UpcomingCoverageEventsContainer isEditingEvent={true} />
      </St.CoverageDrawerContents>
    </St.Drawer>
  )
}
