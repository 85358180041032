import { pipe, reduce, values } from 'lodash/fp'
import { createSelector } from 'reselect'

import { ApplicationState } from '../../store'
import { hydrateRawInspectionFlag } from '../inspectionFlag.utils'
import { InspectionFlag, RawInspectionFlag } from '../inspectionFlags.types'

const getFullState = (state: ApplicationState): ApplicationState => state

export const getInspectionFlags = createSelector(
  getFullState,
  (state): InspectionFlag[] => {
    const flagState = state.inspectionFlags.data

    const getFlags = (
      acc: InspectionFlag[],
      rawInspectionFlag: RawInspectionFlag,
    ) => {
      try {
        const inspectionFlag = hydrateRawInspectionFlag(rawInspectionFlag)
        return [...acc, inspectionFlag]
      } catch (err) {
        throw err
      }
    }

    return pipe(values, reduce(getFlags, []))(flagState)
  },
)
