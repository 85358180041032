import { createSelector } from 'reselect'

import { ApplicationState } from 'app/fieldapp/store/store'

import { compareSeverity, hydrateRawTicket } from '../tickets.utils'

const getTicketsData = (state: ApplicationState) => state.tickets.data
const getUserId = (_, userId: string) => userId

/**
 * Returns an array of all ticket IDs for a user sorted by their 'severity,' with most urgent severity first.
 */
export const getSortedTicketIdsForUser = createSelector(
  getTicketsData,
  getUserId,
  (ticketsData, userId): string[] =>
    Object.values(ticketsData)
      .map(rawTicket => hydrateRawTicket(rawTicket, ''))
      .filter(ticket => ticket.assigneeId === userId)
      .sort(compareSeverity)
      .map(({ id }) => id),
)
