import styled from '@emotion/styled'
import React from 'react'

import { Alert } from 'packages/common'

import { Slugs, useI18n } from 'app/fieldapp/i18n'

const St = {
  Alert: styled(Alert)`
    align-items: flex-start;
    font-size: 16px;
    font-weight: normal;
    word-break: break-word;
  `,

  Content: styled.div`
    display: flex;
    flex-direction: column;
  `,

  Title: styled.div`
    font-weight: bold;
  `,
}

const useTranslations = () => {
  const { ut } = useI18n()

  return {
    title: ut(Slugs.cleanNotesTitle),
  }
}

export type CleanNotesDisplayProps = {
  notes: string
}

export const CleanNotesDisplay: React.FC<CleanNotesDisplayProps> = React.memo(
  ({ notes }) => {
    const strings = useTranslations()

    return notes ? (
      <St.Alert alertType={'info'}>
        <St.Content>
          <St.Title>{strings.title}</St.Title>
          <div>{notes}</div>
        </St.Content>
      </St.Alert>
    ) : null
  },
)
