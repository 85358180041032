import styled from '@emotion/styled'
import React from 'react'

import { IconName, SvgIcon } from 'packages/iconic'
import { colors, text } from 'packages/styles'

import { Slugs, useI18n } from 'app/fieldapp/i18n'

export enum OnCallTaskDetailsTestIds {
  container = 'OnCallTaskDetails__container',
  endDate = 'OnCallTaskDetails__endDate',
  regionTitle = 'OnCallTaskDetails__regionTitle',
  startDate = 'OnCallTaskDetails__startDate',
  unitCount = 'OnCallTaskDetails__unitCount',
  userCircleIcon = 'OnCallTaskDetails__userCircleIcon',
}

const St = {
  DateTime: styled.p`
    ${text.bodyRegularDefault};
    color: ${colors.dusk};
    margin-bottom: 0px;
  `,
  DateTimeContainer: styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;

    &:not(:last-child) {
      margin-bottom: 18px;
    }
  `,
  DateTimeLabel: styled.p`
    ${text.headingLabel};
    color: ${colors.midnight80};
    margin: 0 0 0 8px;
  `,
  IconLabelContainer: styled.div`
    align-items: center;
    display: flex;
  `,
  IconUnitCountContainer: styled.div`
    align-items: center;
    display: flex;
  `,
  RegionHeader: styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
  `,
  RegionTitle: styled.h2`
    ${colors.dusk};
    ${text.bodyBold};
    align-items: center;
    display: flex;
    margin-bottom: 0px;
  `,
  Section: styled.div`
    margin: 24px 24px 0 24px;
  `,
  UnitCount: styled.div`
    ${colors.dusk};
    ${text.headingTinyCaps};
    margin-left: 4px;
  `,
  UserCircleIcon: styled(SvgIcon)`
    margin-right: 8px;
  `,
}

const useTranslations = (name: string | undefined) => {
  const { t } = useI18n()

  return {
    end: t(Slugs.end),
    nameRegion: t(Slugs.nameRegion, { name }),
    start: t(Slugs.start),
    yourRegion: t(Slugs.yourRegion),
  }
}

export type OnCallTaskDetailsProps = {
  endDate: string
  firstName?: string
  isCoverageDetails?: boolean
  regionUnitCount: number
  startDate: string
}

export const OnCallTaskDetails: React.FC<OnCallTaskDetailsProps> = React.memo(
  ({ endDate, isCoverageDetails, firstName, regionUnitCount, startDate }) => {
    const strings = useTranslations(firstName)

    return (
      <St.Section data-testid={OnCallTaskDetailsTestIds.container}>
        <St.RegionHeader>
          <St.RegionTitle data-testid={OnCallTaskDetailsTestIds.regionTitle}>
            {isCoverageDetails && (
              <St.UserCircleIcon
                dataTestId={OnCallTaskDetailsTestIds.userCircleIcon}
                icon={IconName.userCircleSwitchNormal}
                size={24}
              />
            )}
            {isCoverageDetails ? strings.nameRegion : strings.yourRegion}
          </St.RegionTitle>
          <St.IconUnitCountContainer>
            <SvgIcon icon={IconName.home} size={16} />
            <St.UnitCount data-testid={OnCallTaskDetailsTestIds.unitCount}>
              {regionUnitCount}
            </St.UnitCount>
          </St.IconUnitCountContainer>
        </St.RegionHeader>

        <St.DateTimeContainer>
          <St.IconLabelContainer>
            <SvgIcon icon={IconName.calendar} size={16} />
            <St.DateTimeLabel>{strings.start}</St.DateTimeLabel>
          </St.IconLabelContainer>
          <St.DateTime data-testid={OnCallTaskDetailsTestIds.startDate}>
            {startDate}
          </St.DateTime>
        </St.DateTimeContainer>

        <St.DateTimeContainer>
          <St.IconLabelContainer>
            <SvgIcon icon={IconName.calendar} size={16} />
            <St.DateTimeLabel>{strings.end}</St.DateTimeLabel>
          </St.IconLabelContainer>
          <St.DateTime data-testid={OnCallTaskDetailsTestIds.endDate}>
            {endDate}
          </St.DateTime>
        </St.DateTimeContainer>
      </St.Section>
    )
  },
)
