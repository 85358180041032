import React from 'react'
import {
  components,
  MultiValueProps,
  ValueContainerProps,
  OptionProps,
} from 'react-select'

import { Select } from 'packages/common'
import { CustomCheckbox } from 'packages/common/src/inputs/Checkbox/Checkbox.styles'
import { useI18n } from 'packages/i18n'
import { colors } from 'packages/styles'

import { Slugs } from 'app/fieldapp/i18n'
import { InspectionFlag } from 'app/fieldapp/store/inspectionFlags/inspectionFlags.types'

import { getInspectionFlagCategoryTitle } from '../CreateTicketForm'

export type InspectionFlagOption = {
  category: string
  flagId: string
  isChecked: boolean
  notes: string
  ticketIds: string[] | undefined
}

export type GroupedOption = {
  readonly label: string
  readonly options: InspectionFlagOption[]
}

export enum InspectionFlagSelectTestIds {
  container = 'InspectionFlagSelect__container',
}

const InspectionFlagValueContainer = (props: ValueContainerProps) => {
  const values = props.getValue()
  return (
    <components.ValueContainer {...props}>
      {values.length} {'flagged items selected'}
    </components.ValueContainer>
  )
}

const InspectionFlagMultiValueLabel = (props: MultiValueProps) => {
  return (
    <components.MultiValueLabel {...props}>
      {props.data.title}
    </components.MultiValueLabel>
  )
}

const InspectionFlagSelectOption = (props: OptionProps) => {
  const isChecked = props
    .getValue()
    .some(
      (option: InspectionFlagOption) => option.flagId === props.value.flagId,
    )
  const label = `From "${getInspectionFlagCategoryTitle(
    props.value.category,
  )}"${props.value.notes !== null ? ` : ${props.value.notes}` : ''}`

  return (
    <components.Option {...props}>
      <div
        style={{
          display: 'flex',
          position: 'relative',
        }}
      >
        <input hidden={true} type="checkbox" checked={isChecked} />
        <CustomCheckbox />
      </div>
      <span style={{ marginLeft: '24px' }}>{label}</span>
    </components.Option>
  )
}

const customComponents = {
  MultiValueLabel: InspectionFlagMultiValueLabel,
  Option: InspectionFlagSelectOption,
  ValueContainer: InspectionFlagValueContainer,
}

const styles = {
  multiValue: () => ({
    backgroundColor: 'transparent',
  }),
  multiValueLabel: () => ({
    backgroundColor: 'transparent',
    color: colors.dusk,
    marginRight: '5px',
  }),
  multiValueRemove: () => ({
    display: 'none',
  }),
  option: () => ({
    fontWeight: '700',
    padding: '6px 11px',
  }),
}

const groupStyles = {
  alignItems: 'center',
  color: colors.dusk60,
  display: 'flex',
  // text.bodyRegularDefault
  fontSize: '16px',
  fontStyle: 'italic',
  fontWeight: 400,
  justifyContent: 'space-between',
  lineHeight: '24px',
  textTransform: 'none',
}

const formatGroupLabel = (data: GroupedOption) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
  </div>
)

export type InspectionFlagSelectProps = {
  disabled?: boolean
  handleChange: (
    options: InspectionFlagOption[],
    flag: { option: InspectionFlagOption },
  ) => void
  inspectionFlags: InspectionFlag[]
  selectedInspectionFlags: string[]
}

export const InspectionFlagSelect: React.FC<InspectionFlagSelectProps> =
  React.memo(
    ({ disabled, handleChange, inspectionFlags, selectedInspectionFlags }) => {
      const { t } = useI18n()

      const flagOptions = inspectionFlags.map(option => ({
        category: option.categoryId,
        flagId: option.id,
        isChecked: selectedInspectionFlags.includes(option.id),
        notes: option.notes,
        ticketIds: option.ticketIds,
      }))

      const groupedOptions: GroupedOption[] = [
        {
          label: t(Slugs.notAddedToTickets),
          options: flagOptions.filter(
            option => !option.ticketIds || option.ticketIds.length === 0,
          ),
        },
        {
          label: t(Slugs.alreadyAddedToTickets),
          options: flagOptions.filter(
            option => option.ticketIds && option.ticketIds.length > 0,
          ),
        },
      ]

      const selectedFlagOptions = [...flagOptions].filter(
        option => option.isChecked,
      )

      return (
        <div data-testid={InspectionFlagSelectTestIds.container}>
          <Select
            clearable={false}
            closeMenuOnSelect={false}
            components={customComponents}
            disabled={disabled}
            formatGroupLabel={formatGroupLabel}
            getOptionLabel={inspectionFlagOption => inspectionFlagOption.notes}
            hideSelectedOptions={false}
            isMulti={true}
            onChange={handleChange}
            options={groupedOptions}
            placeholder={t(Slugs.selectAnOption)}
            searchable={false}
            selectedValue={selectedFlagOptions}
            style={styles}
          />
        </div>
      )
    },
  )
