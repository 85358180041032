import { createSlice } from '@reduxjs/toolkit'

import {
  deleteStandardAvailabilityById,
  fetchStandardAvailability,
} from './actions'
import { StandardAvailabilityState } from './standardAvailability.types'
import { emptyNormalizedStandardAvailabilityData } from './standardAvailability.utils'

const initialState: StandardAvailabilityState = {
  data: {},
}

const standardAvailabilitySlice = createSlice({
  extraReducers: builder => {
    builder
      .addCase(fetchStandardAvailability.fulfilled, (state, action) => {
        state.data =
          action.payload.normalized.standardAvailability ||
          emptyNormalizedStandardAvailabilityData
      })
      .addCase(deleteStandardAvailabilityById.fulfilled, (state, action) => {
        delete state.data[action?.payload?.standardAvailabilityId || '']
      })
  },
  initialState,
  name: 'standardAvailability',
  reducers: {},
})

export default standardAvailabilitySlice.reducer
