import { createSlice } from '@reduxjs/toolkit'
import { jwtDecode } from 'jwt-decode'

import { AuthTokens } from 'packages/auth'
import {
  getImpersonationToken as getImpersonationTokenFromLS,
  setImpersonationToken as setImpersonationTokenToLS,
  removeImpersonationToken as removeImpersonationTokenFromLS,
} from 'packages/utils/misc'

import { fetchImpersonationToken } from './actions/fetchImpersonationToken'

export type AuthState = AuthTokens & {
  delegateToken?: string
  delegateUserId?: string
  error?: Error
  needsFullAuthRedirect: boolean
  needsSilentRefresh: boolean
}

export const initialState = {
  accessToken: undefined,
  delegateToken: undefined,
  delegateUserId: '',
  error: undefined,
  idToken: undefined,
  impersonationToken: undefined,
  needsFullAuthRedirect: true,
  needsSilentRefresh: false,
  refreshToken: undefined,
}

export const authSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(fetchImpersonationToken.fulfilled, (state, action) => {
      const impersonationToken = action.payload

      if (!impersonationToken) {
        return
      }

      setImpersonationTokenToLS(impersonationToken)
      state.needsFullAuthRedirect = true
    })
  },
  initialState,
  name: 'auth',
  reducers: {
    clearImpersonationToken: state => {
      state.impersonationToken = undefined
      removeImpersonationTokenFromLS()
      state.needsFullAuthRedirect = true
    },
    setAuthError: (state, action) => {
      state.accessToken = undefined
      state.delegateToken = undefined
      state.delegateUserId = ''
      state.error = action.payload
      state.needsFullAuthRedirect = false
      state.needsSilentRefresh = false
      state.idToken = undefined
    },
    setNeedsFullAuthRedirect: (state, action) => {
      state.error = undefined
      state.needsFullAuthRedirect = action.payload
      state.needsSilentRefresh = false
    },
    setNeedsSilentRefresh: (state, action) => {
      state.error = undefined
      state.needsFullAuthRedirect = false
      state.needsSilentRefresh = action.payload
    },
    setTokens: (state, action) => {
      state.accessToken = action.payload.accessToken
      state.error = undefined
      state.needsFullAuthRedirect = false
      state.needsSilentRefresh = false
      state.refreshToken = action.payload.refreshToken
      state.idToken = action.payload.idToken

      const impersonationTokenFromLS = getImpersonationTokenFromLS()
      const impersonationTokenFromPayload = action.payload.impersonationToken

      if (
        impersonationTokenFromPayload &&
        impersonationTokenFromPayload !== impersonationTokenFromLS
      ) {
        setImpersonationTokenToLS(impersonationTokenFromPayload)
      }

      state.impersonationToken =
        impersonationTokenFromPayload || impersonationTokenFromLS

      const delegateToken = action.payload.delegateToken
      state.delegateToken = delegateToken

      // assuming we have a valid token, attempt to pull the 'sub' from it,
      // as this is the ID of the user who requested the token; we will use to ID delegate users
      if (delegateToken) {
        try {
          const decoded = jwtDecode(delegateToken)
          state.delegateUserId = decoded?.sub || ''
        } catch (err) {
          state.delegateUserId = ''
          return
        }
      }
    },
  },
})

export const {
  clearImpersonationToken,
  setAuthError,
  setNeedsFullAuthRedirect,
  setNeedsSilentRefresh,
  setTokens,
} = authSlice.actions

export default authSlice.reducer
