import React from 'react'

import { ReviewSource } from 'packages/grimoire/src/review'
import { IconName } from 'packages/iconic'

import {
  cardIsTiny,
  CardSize,
  cardSizeGreaterThan,
} from '../../../../../hkhub/components/schedule/helpers/scheduleMeasure'
import * as St from './ReviewCardHeader.styles'

export type ReviewCardHeaderProps = {
  id: string
  isReplied?: boolean
  overallRating: number
  reviewSource: string
  size?: CardSize
}

const getSourceIcon = (reviewSource: string) => {
  switch (reviewSource) {
    case ReviewSource.AIRBNB:
      return IconName.airbnb
    case ReviewSource.BOOKING:
      return IconName.booking
    case ReviewSource.VRBO:
      return IconName.vrbo
    case ReviewSource.VACASA:
      return IconName.vacasaHold
  }

  return IconName.vacasaHold
}

export const ReviewCardHeader: React.FC<ReviewCardHeaderProps> = React.memo(
  ({ id, overallRating, reviewSource, isReplied, size = 'medium' }) => {
    const reviewSourceIcon = getSourceIcon(reviewSource)

    if (cardIsTiny(size)) {
      return (
        <St.TinyHeader overallRating={overallRating}>
          <St.TinyId>{`#${id}`}</St.TinyId>
        </St.TinyHeader>
      )
    }

    const isCardSizeGreaterThanSmall = cardSizeGreaterThan(size, 'small')

    return (
      <St.ReviewHeader overallRating={overallRating}>
        <St.ReviewId
          isGreaterThanSmall={isCardSizeGreaterThanSmall}
        >{`#${id}`}</St.ReviewId>
        <St.PlatformIconContainer
          overallRating={overallRating}
          isGreaterThanSmall={isCardSizeGreaterThanSmall}
        >
          <St.PlatformIcon
            dataTestId={`platform-icon-${reviewSource}`}
            icon={reviewSourceIcon}
            size={isCardSizeGreaterThanSmall ? 24 : 16}
          />
          {isReplied && (
            <St.PlatformIcon
              dataTestId={'isRepliedIcon'}
              icon={IconName.comment}
              size={isCardSizeGreaterThanSmall ? 24 : 16}
            />
          )}
        </St.PlatformIconContainer>
      </St.ReviewHeader>
    )
  },
)
