import React from 'react'
import { useSelector } from 'react-redux'

import { DateFormat, format } from 'packages/utils/dateHelpers'

import { useAppSelector } from 'app/fieldapp/store/hooks'
import { getReservationById } from 'app/fieldapp/store/reservations/selectors'
import { ApplicationState } from 'app/fieldapp/store/store'
import { getVisitDrawerState } from 'app/fieldapp/store/ui/selectors'
import { getUnitById } from 'app/fieldapp/store/units/selectors'
import { getVisitById } from 'app/fieldapp/store/visits/selectors'

import { VisitResDetails } from './VisitResDetails'

export const VisitResDetailsContainer: React.FC = React.memo(() => {
  const { visitId } = useAppSelector(getVisitDrawerState)

  const visit = useAppSelector((state: ApplicationState) =>
    getVisitById(state, visitId),
  )
  const unit = useSelector((state: ApplicationState) =>
    getUnitById(state, visit?.unitId),
  )

  const previousInspectionDate = useAppSelector(() => {
    const lastInspectionDate = unit?.statistics?.lastInspectionCompletedAt
    if (lastInspectionDate) {
      return format(
        lastInspectionDate,
        DateFormat.MonthAndDayShortWithTimeAndYear,
      )
    }
  })

  const currentRes = useAppSelector((state: ApplicationState) =>
    getReservationById(state, unit?.currentResId || ''),
  )

  const nextRes = useAppSelector((state: ApplicationState) =>
    getReservationById(state, unit?.nextResId || ''),
  )

  const previousRes = useAppSelector((state: ApplicationState) =>
    getReservationById(state, unit?.previousResId || ''),
  )

  return (
    <VisitResDetails
      currentRes={currentRes}
      previousRes={previousRes}
      nextRes={nextRes}
      previousInspectionDate={previousInspectionDate}
    />
  )
})
