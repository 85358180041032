export enum UiState {
  Active = 'active',
  Idle = 'idle',
  Paused = 'paused',
  Submitting = 'submitting',
}

export type Timer = {
  id: string
  startedAt: string
  startedOffline?: boolean
  stoppedAt: string
}
