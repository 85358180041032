import React from 'react'
import { useSelector } from 'react-redux'

import { DateFormat, format } from 'packages/utils/dateHelpers'
import { isBefore, startOfToday } from 'packages/utils/dateHelpers'

import { useTicket } from 'app/fieldapp/components/tickets/hooks'
import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { getActiveUser } from 'app/fieldapp/store/users/selectors'

import { VisitTicketCard } from '../../VisitDetail/components/VisitTicketCard/VisitTicketCard'

const useTranslations = () => {
  const { ut } = useI18n()
  return {
    created: ut(Slugs.created),
    unknown: ut(Slugs.unknown),
  }
}

export type DetailsTicketCardContainerProps = {
  ticketId: string
}

export const DetailsTicketCardContainer: React.FC<DetailsTicketCardContainerProps> =
  React.memo(({ ticketId }) => {
    const { assignee, ticket } = useTicket(ticketId)
    const activeUser = useSelector(getActiveUser)

    const strings = useTranslations()

    const isAssignedToCurrentUser =
      !!activeUser?.id && assignee?.id === activeUser?.id

    const isOverdue = ticket?.dueDate
      ? isBefore(ticket.dueDate, startOfToday())
      : false

    const headerDateDisplay = {
      headerDate: ticket?.createdAt
        ? format(ticket.createdAt, DateFormat.MonthDateAndYear)
        : strings.unknown,
      headerDateLabel: strings.created,
    }

    return ticket ? (
      <VisitTicketCard
        assignee={assignee}
        headerDateDisplay={headerDateDisplay}
        isAssignedToCurrentUser={isAssignedToCurrentUser}
        isAttachedToOtherVisit={false}
        isLoading={false}
        isOverdue={isOverdue}
        ticket={ticket}
      />
    ) : null
  })
