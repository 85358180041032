/**********************************************************
 * REDUX TYPES
 *********************************************************/
export enum UiActionTypes {
  SET_AVAILABILITY_DRAWER_STATE = 'UI/SET_AVAILABILITY_DRAWER_STATE',
  SET_NEW_CROSS_COVERAGE_DRAWER_STATE = 'UI/SET_NEW_CROSS_COVERAGE_DRAWER_STATE',
  SET_ON_CALL_TASK_DRAWER_STATE = 'UI/SET_ON_CALL_TASK_DRAWER_STATE',
  SET_STANDARD_COVERAGE_PARTNER_DRAWER_STATE = 'UI/SET_STANDARD_COVERAGE_PARTNER_DRAWER_STATE',
  SET_VISIT_DRAWER_STATE = 'UI/SET_VISIT_DRAWER_STATE',
  SET_VISIT_TICKET_DRAWER_STATE = 'UI/SET_VISIT_TICKET_DRAWER_STATE',
}

export const validVisitTabs = ['unitInfo', 'tickets', 'reviews', 'inspection']
export type VisitTabType =
  | 'unitInfo'
  | 'tickets'
  | 'reviews'
  | 'inspection'
  | ''

export type BaseDrawerState = {
  forceClose: boolean
  isOpen: boolean
}

export type VisitDrawerState = BaseDrawerState & {
  tab?: VisitTabType
  visitId: string
}

export type VisitTicketDrawerState = BaseDrawerState & {
  ticketId: string
}

export type ReviewDrawerState = BaseDrawerState & {
  reviewId: string
}

export type StandardAvailabilityDrawerState = BaseDrawerState

export type StandardCoveragePartnerDrawerState = BaseDrawerState

export type CrossCoverageDrawerState = BaseDrawerState & {
  crossCoverageId: string | undefined
  systemAssignedUserId: string | undefined
}

export type OnCallTaskDrawerState = BaseDrawerState & {
  coverageId: string
}

export type BannerConfig = {
  link?: {
    text: string
    url: string
  }
  message: string
  timeout?: number
}

export type UiState = {
  banner: BannerConfig
  drawers: {
    CrossCoverageDrawer: CrossCoverageDrawerState
    onCallTaskDrawer: OnCallTaskDrawerState
    reviewDrawer: ReviewDrawerState
    standardAvailabilityDrawer: StandardAvailabilityDrawerState
    standardCoveragePartnerDrawer: StandardCoveragePartnerDrawerState
    visitDrawer: VisitDrawerState
    visitTicketDrawer: VisitTicketDrawerState
  }
  modals: {
    cleanRejection: {
      isRejecting: boolean
    }
  }
}
