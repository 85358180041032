import React from 'react'
import { useSelector } from 'react-redux'

import { useDatePickerModal } from 'packages/common/src/DatePicker/useDatePickerModal'
import {
  addDays,
  createDateObject,
  startOfToday,
  subDays,
} from 'packages/utils/dateHelpers'

import {
  CLEANS_TIMELINE_DATE_RANGE,
  CLEANS_TIMELINE_PREV_DAYS,
} from 'app/fieldapp/FieldApp.constants'
import {
  getCleanBucketsForDateRange,
  getLastFetch,
} from 'app/fieldapp/store/cleans/selectors'
import { ApplicationState } from 'app/fieldapp/store/store'
import { getVisitsOnDate } from 'app/fieldapp/store/visits/selectors/getVisitsOnDate'
import { useTimelineDateRange } from 'app/fieldapp/utils/timelineHelpers'

import { CleansTimeline, FieldAppScheduleView } from './CleansTimeline'

export type CleanTimelinePassThroughProps = {
  onCleanClick: (cleanId: string) => void
}

const TODAY = startOfToday()
const TOMORROW = addDays(TODAY, 1)

export const CleansTimelineContainer: React.FC<
  CleanTimelinePassThroughProps
> = passThroughProps => {
  const { range } = useTimelineDateRange()

  const [date, setDate] = React.useState<Date>(createDateObject())

  const [scheduleViewType, setScheduleViewType] =
    React.useState<FieldAppScheduleView>('all')

  const startDate = React.useMemo(() => {
    return scheduleViewType === 'all'
      ? subDays(TODAY, CLEANS_TIMELINE_PREV_DAYS)
      : date
  }, [scheduleViewType, date])

  const daysInRange = React.useMemo(() => {
    return scheduleViewType === 'all'
      ? range + CLEANS_TIMELINE_PREV_DAYS + 1
      : 1
  }, [scheduleViewType, range])

  const cleanBuckets = useSelector((state: ApplicationState) =>
    getCleanBucketsForDateRange(state, startDate, daysInRange),
  )

  const visitsOnToday = useSelector((state: ApplicationState) =>
    getVisitsOnDate(state, TODAY),
  )

  const visitsOnNextDay = useSelector((state: ApplicationState) =>
    getVisitsOnDate(state, TOMORROW),
  )

  const lastFetchTimestamp = useSelector(getLastFetch)

  const { showDatePickerModal } = useDatePickerModal({
    disableAfterDate: addDays(TODAY, CLEANS_TIMELINE_DATE_RANGE),
    disableBeforeDate: subDays(TODAY, CLEANS_TIMELINE_PREV_DAYS),
    onDateChange: selected => {
      setDate(selected ?? createDateObject())
    },
    selectedDate: date,
  })

  return (
    <CleansTimeline
      {...passThroughProps}
      cleanBuckets={cleanBuckets}
      lastFetchTimestamp={lastFetchTimestamp}
      setView={setScheduleViewType}
      showDatePickerModal={showDatePickerModal}
      view={scheduleViewType}
      visitsOnToday={visitsOnToday}
      visitsOnNextDay={visitsOnNextDay}
    />
  )
}
