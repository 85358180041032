import styled from '@emotion/styled'
import React from 'react'
import { v4 as uuid } from 'uuid'

import { Alert, Drawer, UseManageDrawerState } from 'packages/common'
import { colors, text } from 'packages/styles'
import { DateFormat, format } from 'packages/utils/dateHelpers'

import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { Coverage } from 'app/fieldapp/store/coverage/coverage.types'

import { OnCallTaskDetails } from './OnCallTaskDetails'

const St = {
  Alert: styled(Alert)`
    margin-bottom: 0px;
  `,
  Drawer: styled(Drawer)`
    width: min(420px, 100vw);
  `,
  DrawerContentsContainer: styled.div`
    padding-bottom: 96px;
  `,
  Header: styled.h1`
    ${text.bodyBoldSmall};
    align-items: center;
    background: ${colors.midnight70};
    color: ${colors.white};
    display: flex;
    height: 52px;
    margin-bottom: 0px;
    padding-left: 8px;
  `,
  Section: styled.div`
    margin: 24px 24px 0 24px;
  `,
}

const useTranslations = () => {
  const { t } = useI18n()

  return {
    cannotChangeAssignedCoverage: t(Slugs.cannotChangeAssignedCoverage),
    onCall: t(Slugs.onCall),
  }
}

export type OnCallTaskDrawerProps = {
  coverage: Coverage
  drawerManager: UseManageDrawerState
}

export const OnCallTaskDrawer: React.FC<OnCallTaskDrawerProps> = React.memo(
  ({ coverage, drawerManager }) => {
    const strings = useTranslations()

    const startDate = coverage ? format(coverage.start, DateFormat.Full) : ''
    const endDate = coverage ? format(coverage.end, DateFormat.Full) : ''

    const isCovering =
      coverage?.coverageType ===
      ('standard_covering' || 'cross_coverage_covering')

    return (
      <>
        <St.Drawer
          afterExit={drawerManager.completeDrawerClose}
          forceClose={drawerManager.drawerState.forceClose}
          isOpen={drawerManager.drawerState.isOpen}
        >
          <St.DrawerContentsContainer>
            <St.Header>{strings.onCall}</St.Header>

            <OnCallTaskDetails
              endDate={endDate}
              regionUnitCount={coverage?.details?.myRegionUnitCount}
              startDate={startDate}
            />

            <St.Section>
              {isCovering && (
                <St.Alert alertType={'info'}>
                  {strings.cannotChangeAssignedCoverage}
                </St.Alert>
              )}
            </St.Section>

            {coverage?.details?.coveredRegions.length > 0 &&
              coverage?.details?.coveredRegions.map(coveredRegion => {
                return (
                  <div key={uuid()}>
                    <OnCallTaskDetails
                      endDate={format(coveredRegion?.end, DateFormat.Full)}
                      isCoverageDetails={true}
                      firstName={coveredRegion?.firstName}
                      regionUnitCount={coveredRegion?.unitCount}
                      startDate={format(coveredRegion?.start, DateFormat.Full)}
                    />
                  </div>
                )
              })}
          </St.DrawerContentsContainer>
        </St.Drawer>
      </>
    )
  },
)
