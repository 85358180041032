import { kebabCase } from 'lodash/fp'
import React from 'react'
import { useDispatch } from 'react-redux'

import { Events, track } from 'packages/wiretap'
import { logInfo } from 'packages/wiretap/logging'

import { isViewingSharedClean } from 'app/fieldapp/components/schedule/schedule.utils'
import { AppDispatch } from 'app/fieldapp/store/store'
import { RawTaskPhoto } from 'app/fieldapp/store/taskPhotos'
import { createTaskPhoto } from 'app/fieldapp/store/taskPhotos/actions'
import { fetchVisitById } from 'app/fieldapp/store/visits/actions'

import { compressImageIfNecessary } from '../../../../InspectionChecklist/components/InspectionImageUploader/hooks/useUploadPhoto.utils'

type UseUploadPhoto = {
  uploadPhoto: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void>
}

type UseUploadPhotoProps = {
  categoryId: string
  onError: (err: Error) => void
  onSuccess: (taskPhotoId: string, file: File) => void
  onUploadStart: () => void
  taskId: string
  userId?: string
}

export const useUploadPhoto = ({
  categoryId,
  onError,
  onUploadStart,
  onSuccess,
  taskId,
  userId,
}: UseUploadPhotoProps): UseUploadPhoto => {
  const dispatch: AppDispatch = useDispatch()

  const uploadPhoto = React.useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const originalFile = event.target?.files?.[0]

      // These should never be nullish at this point, but TS requires this.
      // There probably isn't much we can do at this point other than early return.
      if (!userId || !originalFile) return

      onUploadStart()

      try {
        // request a new TaskPhoto object; note that this DOES NOT contain any image data;
        // it only contains relationships + S3 pre-signed upload data
        const newTaskPhoto = (await dispatch(
          createTaskPhoto({
            categoryId,
            taskId: taskId,
            userId,
          }),
        )) as unknown as RawTaskPhoto // TS just won't play nice on this one...
        const { uploadConfig } = newTaskPhoto.attributes

        // make a new Form with all of the S3-specific params attached,
        // append the file itself LAST, and send it all to S3!
        const body = new FormData()
        Object.entries(uploadConfig.fields).forEach(([key, value]) => {
          body.append(kebabCase(key), value)
        })

        const compressionResult = await compressImageIfNecessary(originalFile)
        const { compressionStats, fileForUpload, imgElements } =
          compressionResult

        const uploadStart = Date.now() // eslint-disable-line
        body.append('file', fileForUpload)
        await fetch(uploadConfig.url, { body, method: 'POST' })

        /* eslint-disable @typescript-eslint/naming-convention */
        track(Events.fieldAppInspectionUploadPhoto, {
          ...compressionStats,
          clean_id: taskId,
          file_size: originalFile.size,
          is_delegate: isViewingSharedClean(),
          original_height: imgElements.original.height,
          original_width: imgElements.original.width,
          task_photo_id: newTaskPhoto.id,
          upload_time_ms: Date.now() - uploadStart, // eslint-disable-line
          user_id: userId,
        })
        /* eslint-enable @typescript-eslint/naming-convention */

        await dispatch(fetchVisitById(taskId))
        onSuccess(newTaskPhoto.id, fileForUpload)
      } catch (err) {
        /* eslint-disable @typescript-eslint/naming-convention */
        const trackingPayload = {
          clean_id: taskId,
          error: err?.message || 'unknown error',
        }
        /* eslint-enable @typescript-eslint/naming-convention */

        track(Events.fieldAppInspectionUploadPhotoError, trackingPayload)
        logInfo('Error uploading TaskPhoto', {
          details: trackingPayload,
        })

        onError(err)
      }
    },
    [categoryId, dispatch, onError, onSuccess, onUploadStart, taskId, userId],
  )

  return {
    uploadPhoto,
  }
}
