import { createAsyncThunk } from '@reduxjs/toolkit'
import { noop } from 'lodash'

import { ReduxActionCallbacks } from 'packages/grimoire/src/utils'
import { RequestOptions } from 'packages/utils/store'

import { inspectionFlagService } from '../inspectionFlag.service'
import {
  InspectionFlagPatchAPIAttributes,
  InspectionFlagPatchData,
} from '../inspectionFlags.types'

export const buildPayloadFrom = (
  id: string,
  patchData: InspectionFlagPatchData,
): RequestOptions => {
  const { notes } = patchData

  const attributes: Partial<InspectionFlagPatchAPIAttributes> = {
    notes,
  }

  return {
    data: {
      attributes,
      id,
      type: 'inspection_flag',
    },
  }
}

type UpdateInspectionFlagThunkArgs = {
  callbacks?: ReduxActionCallbacks
  id: string
  patchData: InspectionFlagPatchData
}

export const updateInspectionFlag = createAsyncThunk(
  'inspectionFlag/api/updateInspectionFlag',
  async ({ id, patchData, callbacks = {} }: UpdateInspectionFlagThunkArgs) => {
    const { onError = noop, onSuccess = noop } = callbacks

    try {
      const payload = buildPayloadFrom(id, patchData)
      const response = await inspectionFlagService.updateInspectionFlag(
        id,
        payload,
      )

      onSuccess()

      return response
    } catch (error) {
      onError(error)

      throw error
    }
  },
)
