import classNames from 'classnames'
import { noop } from 'lodash/fp'
import React from 'react'
import { FieldError } from 'react-hook-form'

import { createDateString } from 'packages/utils/dateHelpers'

import styles from '../../SubmitTimers.module.scss'

export const DATE_TIME_ERROR_TEST_ID = 'DATE_TIME_ERROR_TEST_ID'
export const DATE_TIME_PICKER_TEST_ID = 'DATE_TIME_PICKER_TEST_ID'

export type DateTimeInputProps = {
  className: string
  error?: FieldError
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onTimerChange: (e) => void
  trackChange: () => void
  validateOther: () => void
  value?: string
}

export const DateTimeInput: React.FC<DateTimeInputProps> = ({
  className,
  error,
  onBlur = noop,
  onChange = noop,
  onTimerChange,
  trackChange,
  validateOther,
  value,
  ...props
}) => {
  const inputRef = React.useRef<HTMLInputElement>(null)

  const validValue = value || createDateString()

  const cls = classNames(styles.timerInput, {
    [styles.error]: !!error,
  })

  // Note: for mobile browsers, we will hide the native input offscreen and display a styled div instead,
  // which will trigger the input when clicked (mobile inputs cannot be styled correctly)
  // Also note: this is currently non-functional on Firefox for all platforms
  return (
    <div className={classNames(className, styles.timerContainer)}>
      <input
        data-testid={DATE_TIME_PICKER_TEST_ID}
        className={cls}
        ref={inputRef}
        type="datetime-local"
        value={validValue}
        onChange={e => {
          trackChange()
          onChange(e)
          onTimerChange(e)
        }}
        onBlur={e => {
          validateOther()
          onBlur(e)
        }}
        {...props}
      />

      {error && (
        <div
          data-testid={DATE_TIME_ERROR_TEST_ID}
          className={styles.timerInputErrorMessage}
        >
          {error.message}
        </div>
      )}
    </div>
  )
}
