import { RequestOptions } from 'packages/utils/store/jsonapi.types'

import { api } from '../axiosInstance'
import {
  DispositionApiResponse,
  DispositionServiceResponse,
  TicketsApiResponse,
  TicketsServiceResponse,
} from './tickets.types'
import { emptyDispositionResponse, emptyTicketsResponse } from './tickets.utils'

const URL = '/tickets'

export const ticketsService = {
  async fetchTicketById(
    id: string,
    params: RequestOptions = {},
  ): TicketsServiceResponse {
    const response: TicketsApiResponse = await api.get(`${URL}/${id}`, {
      params,
    })

    return response?.data || emptyTicketsResponse
  },
  async updateTicket(
    ticketId: string,
    requestOptions: RequestOptions,
  ): TicketsServiceResponse {
    const response: TicketsApiResponse = await api.patch(
      `${URL}/${ticketId}`,
      requestOptions,
    )

    return response?.data || emptyTicketsResponse
  },
  async fetchTickets(params: RequestOptions = {}): TicketsServiceResponse {
    const response: TicketsApiResponse = await api.get(URL, {
      params,
    })

    return response?.data || emptyTicketsResponse
  },
  async createTicket(data: RequestOptions): TicketsServiceResponse {
    const response: TicketsApiResponse = await api.post(URL, data)

    return response?.data || emptyTicketsResponse
  },
}

//------------------------------------------------
// LEGACY TICKETS API FUNCTIONS
//------------------------------------------------
const URL_DISPOSITIONS = '/dispositions'

export async function fetchTicketingMaintenanceDispositions(
  params: RequestOptions = {},
): DispositionServiceResponse {
  const response: DispositionApiResponse = await api.get(URL_DISPOSITIONS, {
    params,
  })

  return response?.data || emptyDispositionResponse
}
