import { OfflineOtherTimer } from 'packages/grimoire'

export function rejectOfflineOnlyTimers<T extends OfflineOtherTimer>(
  timers: Record<string, T>,
): Record<string, T> {
  return Object.entries(timers).reduce((acc, [k, timer]) => {
    if (!timer.attributes.startedOffline) acc[k] = timer
    return acc
  }, {})
}
