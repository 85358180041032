import React from 'react'
import { useDispatch } from 'react-redux'

import { TimerType } from 'packages/grimoire/src/utils/timers.types'
import { track, Events } from 'packages/wiretap'

import { useTimerErrors } from 'app/fieldapp/components/timers/hooks'
import {
  clearTimerSubmission,
  useTimers as useTimersContext,
} from 'app/fieldapp/components/timers/state'
import { silentDeleteTicketTime } from 'app/fieldapp/store/ticket-times/actions'

type UseHandleMissingTicket = {
  handleMissingTicket: (ticketTimeId: string, assignmentId: string) => void
}

export const useHandleMissingTicket = (): UseHandleMissingTicket => {
  const reduxDispatch = useDispatch()

  const { dispatch: timersDispatch } = useTimersContext()
  const { handleUnassignedEntity } = useTimerErrors()

  const handleMissingTicket = React.useCallback(
    (ticketTimeId: string, ticketId: string) => {
      /* eslint-disable @typescript-eslint/naming-convention */
      track(Events.deleteReassignedTicketTime, {
        ticket_id: ticketId,
        tickettime_id: ticketTimeId,
      })
      /* eslint-enable @typescript-eslint/naming-convention */

      // handle local/UI updates before submitting any API requests
      timersDispatch(clearTimerSubmission())
      handleUnassignedEntity(TimerType.TICKET)

      // send a request to delete the associated timer
      // this is a background request, so we won't show loading state or handle errors
      try {
        reduxDispatch(silentDeleteTicketTime(ticketTimeId))
      } catch (err) {
        // no error handling necessary here
      }
    },
    [handleUnassignedEntity, reduxDispatch, timersDispatch],
  )

  return { handleMissingTicket }
}
