import { filter, map, pipe, values } from 'lodash/fp'
import { createSelector } from 'reselect'

import { pendingImageUploads } from '../taskPhotos.db'
import { TaskPhoto, TaskPhotosState } from '../taskPhotos.types'
import { hydrateRawTaskPhoto } from '../taskPhotos.utils'

const getTaskPhotosState = (tpState: TaskPhotosState) => tpState
const getTaskPhotosIds = (_s, taskPhotosIds: string[]) => taskPhotosIds

/**
 * Processes the TaskPhoto (TP) in relation to storing data locally referencing
 * "pending" image uploads. "Side effects" in this case refers to the fact that we
 * are checking (and possibly even updating) sessionStorage. There are 3 possible outcomes here:
 *
 * - The "original_image" field is populated, in which case the CP is returned directly
 *      - We will also remove any "pending" entries for this CP, since we no longer need it.
 * - The "original_image" field is null, but the ID is currently stored in our "pending" list
 *      - In this case, we return the CP, and the UI will show some "pending" status.
 *  - The "original_image" field is null, and we DO NOT have this stored as a "pending" entry
 *      - This is effectively an invalid CP (e.g. maybe the S3 upload never finished),
 *          so we want to completely ignore this entry.
 *      - We will return 'undefined' in this case, and it will be filtered out by the selector.
 *
 * @param taskPhoto
 */
const processWithSideEffects = (
  taskPhoto: TaskPhoto,
): TaskPhoto | undefined => {
  const { originalImage } = taskPhoto
  if (originalImage) {
    pendingImageUploads.remove(taskPhoto.id)
    return taskPhoto
  } else {
    const isPending = pendingImageUploads.has(taskPhoto.id)
    return isPending ? taskPhoto : undefined
  }
}

/**
 * Returns all valid TaskPhotos whose ID match the provided list of IDs.
 * A 'valid' TaskPhoto is one that matches the following conditions:
 * - The `original_image` field is non-null
 * - The 'original_image' field is null AND we have the id stored as a "pending" upload
 */
export const getTaskPhotosByIds = createSelector(
  getTaskPhotosState,
  getTaskPhotosIds,
  (taskPhotosState, taskPhotoIds): TaskPhoto[] => {
    const idIsInList = ({ id }) => taskPhotoIds.includes(id)

    return pipe(
      values,
      filter(idIsInList),
      map(hydrateRawTaskPhoto),
      map(processWithSideEffects),
      filter(Boolean), // remove any 'undefined' entries
    )(taskPhotosState.data)
  },
)
