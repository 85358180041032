import { produce } from 'immer'
import { every } from 'lodash/fp'

import { Disposition, SeverityType } from 'app/fieldapp/store/tickets'

import { CreateTicketAction } from './createTicket.actions'

export type CreateTicketValuesState = {
  assignToSelf: boolean
  assigneeId: string
  description: string
  disposition: Disposition | null
  inspectionFlagIds: string[]
  severity: SeverityType | null
  title: string
  visibility: string
}

export type CreateTicketState = {
  uiState: {
    descriptionOpen: boolean
    dispositionsLoading: boolean
    error: boolean
    loading: boolean
    readyToSubmit: boolean
  }
  values: CreateTicketValuesState
}

export const initialCreateTicketState: CreateTicketState = {
  uiState: {
    descriptionOpen: false,
    dispositionsLoading: true,
    error: false,
    loading: false,
    readyToSubmit: false,
  },
  values: {
    assigneeId: 'default',
    assignToSelf: false,
    description: '',
    disposition: null,
    inspectionFlagIds: [],
    severity: null,
    title: '',
    visibility: 'internal',
  },
}

const validateValues = (state: CreateTicketState) => {
  const { title, description, disposition, severity } = state.values

  // only require description if additional details is open
  if (state.uiState.descriptionOpen) {
    return every(Boolean)([title, description, disposition, severity])
  }

  return every(Boolean)([title, disposition, severity])
}

export const createTicketReducer = (
  state: CreateTicketState,
  action: CreateTicketAction,
): CreateTicketState =>
  produce(state, draft => {
    switch (action.type) {
      case 'setValue':
        Object.entries(action.payload).forEach(([section, value]) => {
          if (section in draft.values) {
            draft.values[section] = value
          }
        })

        break

      case 'toggleDetailsOpen':
        draft.uiState.descriptionOpen = !state.uiState.descriptionOpen
        break

      case 'setError':
        draft.uiState.error = action.payload
        break

      case 'setLoading':
        draft.uiState.loading = action.payload
        break

      case 'toggleInspectionFlag':
        const flags = action.payload.changedFlagId
        const prevSelectedFlags = state.values.inspectionFlagIds

        const isRemovingSelection = prevSelectedFlags.some(
          flagId => flagId === action.payload.changedFlagId,
        )

        // // If the user is unselecting a flag, we want to remove the flag
        draft.values.inspectionFlagIds = isRemovingSelection
          ? prevSelectedFlags.filter(
              dayOfWeek => dayOfWeek !== action.payload.changedFlagId,
            )
          : [...state.values.inspectionFlagIds, flags]

        break
    }

    draft.uiState.readyToSubmit = validateValues(draft)
    return
  })
