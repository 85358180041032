import { createAsyncAction } from 'typesafe-actions'

import { RequireAllExceptOptional } from 'packages/grimoire/src/utils'
import { apiDateStringWithSeconds } from 'packages/utils/dateHelpers'
import {
  OfflineRequestConfig,
  RequestOptions,
  makeRelationship,
} from 'packages/utils/store'

import {
  NormalizedTicketTimesApiResponse,
  TicketTimePostData,
  TicketTimePostApiAttributes,
  TicketTimesActionTypes,
} from 'app/fieldapp/store/ticket-times'

import { ApplicationState } from '../../store'
import { createTicketComment } from '../../ticketComments/actions'
import { getActiveUser } from '../../users/selectors'
import { offlineTimers } from '../../utils'
import { ticketTimesService } from '../ticket-times.service'
import { fetchActiveTicketTimesForUser } from './fetchActiveTicketTimesForUser'

export const createFinalizedTicketTimeAction = createAsyncAction(
  TicketTimesActionTypes.CREATE_FINALIZED_TICKET_TIME,
  TicketTimesActionTypes.CREATE_FINALIZED_TICKET_TIME_SUCCESS,
  TicketTimesActionTypes.CREATE_FINALIZED_TICKET_TIME_FAILURE,
)<
  OfflineRequestConfig<NormalizedTicketTimesApiResponse, TicketTimePostData>,
  { localId: string; result: NormalizedTicketTimesApiResponse },
  Error
>()

export type FinalizedTicketTimePostData = Omit<
  RequireAllExceptOptional<TicketTimePostData, 'notes'>,
  'isFinalized'
>

export const buildRequestData = (
  postData: TicketTimePostData,
): RequestOptions => {
  const { startedAt, stoppedAt, notes, ticketId, userId } = postData
  const attributes: TicketTimePostApiAttributes = {
    is_finalized: true,
    notes,
    started_at: apiDateStringWithSeconds(startedAt),
    stopped_at: stoppedAt ? apiDateStringWithSeconds(stoppedAt) : undefined,
    ticket_id: ticketId,
  }

  return {
    data: {
      attributes,
      relationships: {
        user: makeRelationship('user', userId),
      },
      type: 'ticket_time',
    },
  }
}

/**
 * Posts a finalized timer. Used for working with timers started offline.
 * The localId is not posted to the API, but is used to delete any offline data.
 */
export const createFinalizedTicketTime =
  (localId: string, postData: TicketTimePostData) =>
  async (dispatch, getState) => {
    try {
      const state: ApplicationState = getState()

      const requestData = buildRequestData(postData)
      const request = ticketTimesService.createTicketTime.bind(
        null,
        requestData,
      )
      const result = await dispatch(
        createFinalizedTicketTimeAction.request({ data: postData, request }),
      )

      await offlineTimers.removeTicketTime(localId)

      dispatch(createFinalizedTicketTimeAction.success({ localId, result }))

      const ticketId = postData.ticketId
      const user = getActiveUser(state)

      if (ticketId && user && postData.notes) {
        await dispatch(
          createTicketComment({
            external: !!postData.isExternalComment,
            note: postData.notes,
            ticketId,
            userId: user.id,
          }),
        )
      }

      await dispatch(fetchActiveTicketTimesForUser())
    } catch (error) {
      dispatch(createFinalizedTicketTimeAction.failure(error))
      throw error
    }
  }
