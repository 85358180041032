import styled from '@emotion/styled'
import React from 'react'

import { colors } from 'packages/styles'

import { formatTicketDate } from 'app/fieldapp/components/tickets/tickets.helpers'
import { Slugs, useI18n } from 'app/fieldapp/i18n'

const Section = styled.section`
  display: flex;
  border-bottom: 1px solid ${colors.midnight20};
`

const Item = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 50%;
  font-size: 14px;
  padding: 16px 24px;

  :last-child {
    border-left: 1px solid ${colors.midnight20};
  }
`

type DatesSectionProps = {
  createdAt: string
  dueDate?: string
}

export const DatesSection: React.FC<DatesSectionProps> = ({
  createdAt,
  dueDate,
}) => {
  const { ut } = useI18n()

  return (
    <Section>
      <Item>
        <b>{ut(Slugs.created)}</b>
        <span>{formatTicketDate(createdAt)}</span>
      </Item>

      <Item>
        <b>{ut(Slugs.due)}</b>
        <span>{dueDate ? formatTicketDate(dueDate) : ut(Slugs.unknown)}</span>
      </Item>
    </Section>
  )
}
