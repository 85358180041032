import { difference, snakeCase } from 'lodash/fp'

import {
  AllCleanAttributeNames,
  AllCleanAttributes,
  CleanJobType,
  JSONApiHousekeepersMap,
  JSONApiUsersMap,
} from 'packages/grimoire'
import {
  JSONApiObjectWithRelationships,
  JSONApiObjectWithRelationshipsMap,
  StringKeyToObjectMap,
  ToManyRelationship,
  ToOneRelationship,
} from 'packages/grimoire/src/utils'

import { Assignment, JSONApiAssignmentsMap } from '../assignments'
import { JSONApiLockboxesMap } from '../lockboxes'
import { JSONApiReservationsMap, Reservation } from '../reservations'
import { JSONApiSmartLocksMap } from '../smartlocks'
import { TaskPhoto, JsonApiTaskPhotosMap } from '../taskPhotos'
import { JSONApiUnitsMap, Unit } from '../units'

export * from 'packages/grimoire/src/clean/clean.types'

/**********************************************************
 * UTILITY TYPES
 *********************************************************/
export type BucketedCleans = {
  [monthKey: string]: {
    [dayKey: string]: Clean[]
  }
}

export type AssignmentToCleanMap = StringKeyToObjectMap<Clean>

/**********************************************************
 * SERVICE TYPES
 *********************************************************/
export type CleansResponse = {
  assignment: JSONApiAssignmentsMap
  clean: JSONApiCleanMap
  housekeeper: JSONApiHousekeepersMap
  lockBox: JSONApiLockboxesMap
  reservation: JSONApiReservationsMap
  smartLock: JSONApiSmartLocksMap
  taskPhoto: JsonApiTaskPhotosMap
  unit: JSONApiUnitsMap
  user: JSONApiUsersMap
}

export type CleanPatchApiAttributes = {
  completed_at: string
  inspection_completed_at: string
  job_type: string[]
  started_at: string
}

export type CleanPatchAttributes = {
  completedAt: Date
  inspectionCompletedAt: Date
  jobType: CleanJobType[]
  startedAt: Date
}

export type CleanPatchData = {
  id: string
} & Partial<CleanPatchAttributes>

/**********************************************************
 * REDUX TYPES
 *********************************************************/
export enum CleansActionTypes {
  FETCH_CLEAN = 'CLEANS/API/FETCH_CLEAN',
  FETCH_CLEANS = 'CLEANS/API/FETCH_CLEANS',
  FETCH_CLEANS_FAILURE = 'CLEANS/FETCH_CLEANS_FAILURE',

  FETCH_CLEANS_SUCCESS = 'CLEANS/FETCH_CLEANS_SUCCESS',
  FETCH_CLEAN_FAILURE = 'CLEANS/FETCH_CLEAN_FAILURE',
  FETCH_CLEAN_SUCCESS = 'CLEANS/FETCH_CLEAN_SUCCESS',

  TOGGLE_INSPECTION_ITEM = 'CLEANS/API/TOGGLE_INSPECTION_ITEM',
  TOGGLE_INSPECTION_ITEM_FAILURE = 'CLEANS/TOGGLE_INSPECTION_ITEM_FAILURE',
  TOGGLE_INSPECTION_ITEM_SUCCESS = 'CLEANS/TOGGLE_INSPECTION_ITEM_SUCCESS',

  UPDATE_CLEAN = 'CLEANS/API/UPDATE_CLEAN',
  UPDATE_CLEAN_FAILURE = 'CLEANS/UPDATE_CLEAN_FAILURE',
  UPDATE_CLEAN_SUCCESS = 'CLEANS/UPDATE_CLEAN_SUCCESS',
}

export type CleansState = {
  data: JSONApiCleanMap
  error?: Error
  lastFetch: number
  loading: boolean
}

/**********************************************************
 * CLEAN
 *********************************************************/
export type Clean = {
  assignments: Assignment[]
  id: string
  reservation: Reservation
  taskPhotos: TaskPhoto[]
  unit: Unit
} & CleanAttributes

export type CleanAttributes = Omit<
  AllCleanAttributes,
  | 'lastCleanCompletedAt'
  | 'realtimeStatus'
  | 'hasCleanPhotos'
  | 'ticketAttachedCount'
  | 'timersSubmitted'
>

export const CleanAttributeNames = difference(AllCleanAttributeNames)([
  'lastCleanCompletedAt',
  'realtimeStatus',
  'hasCleanPhotos',
  'ticketAttachedCount',
  'timersSubmitted',
])

export const CleanApiFields: string[] = CleanAttributeNames.map(snakeCase)

export type CleanRelationships = {
  activeAssignments: ToManyRelationship
  reservation: ToOneRelationship
  smartLocks: ToManyRelationship
  taskPhotos: ToManyRelationship
  unit: ToOneRelationship
}

export type RawClean = JSONApiObjectWithRelationships<
  CleanAttributes,
  CleanRelationships
>

export type JSONApiCleanMap = JSONApiObjectWithRelationshipsMap<
  CleanAttributes,
  CleanRelationships
>
