import { createAsyncThunk } from '@reduxjs/toolkit'

import { fetchPrimaryDataBatch } from './fetchPrimaryDataBatch'
import { fetchSecondaryDataBatch } from './fetchSecondaryDataBatch'

export const fanOutDataBatchRequests = createAsyncThunk(
  'app/fanOutDataBatchRequests',
  async (_, { dispatch }) => {
    await dispatch(fetchPrimaryDataBatch())
    await dispatch(fetchSecondaryDataBatch())
  },
)
