import React from 'react'

import { ExternalLink } from 'packages/common'
import { useI18n } from 'packages/i18n'
import { IconName } from 'packages/iconic'
import {
  getAdminViewUnitTicketsURL,
  getAdminViewReservationUrl,
} from 'packages/utils/links'
import { logInfo } from 'packages/wiretap/logging'

import { CreateTicketDrawer } from 'app/fieldapp/components/schedule/components/common'
import {
  Details,
  DetailIcon,
  SectionSubTitle,
  UnitInfoContainer,
} from 'app/fieldapp/components/schedule/components/TaskSharedStyles'
import { Slugs } from 'app/fieldapp/i18n'
import { Unit } from 'app/fieldapp/store/units'

import { isViewingSharedClean } from '../../../schedule.utils'

export enum ReviewLinksTestIds {
  reservationContainer = 'ReviewLinks__reservationContainer',
  unitTicketsContainer = 'ReviewLinks__unitTicketsContainer',
}

const St = {
  DetailIcon,
  Details,
  SectionSubTitle,
  UnitInfoContainer,
}

export type ReviewLinksProps = {
  reservationId?: string
  taskId?: string
  unit?: Unit
}

const useTranslations = () => {
  const { t, ut } = useI18n()

  return {
    reservation: t(Slugs.reservation),
    unitTickets: `${ut(Slugs.unit)} ${ut(Slugs.tickets)}`,
    viewHome: t(Slugs.viewHome),
    viewReservation: t(Slugs.viewReservation),
    viewTickets: t(Slugs.viewOpenTickets),
  }
}

export const ReviewLinks: React.FC<ReviewLinksProps> = React.memo(
  ({ taskId, reservationId, unit }) => {
    const strings = useTranslations()

    if (!unit) {
      logInfo('Missing unit info when trying to render unit links')
      return null
    }

    const shouldHideAdminLinks = isViewingSharedClean()

    return (
      <>
        {!shouldHideAdminLinks && (
          <>
            <St.UnitInfoContainer
              data-testid={ReviewLinksTestIds.unitTicketsContainer}
            >
              <St.DetailIcon icon={IconName.tag} size={16} />
              <St.Details>
                <St.SectionSubTitle>{strings.unitTickets}</St.SectionSubTitle>

                <ExternalLink
                  text={strings.viewTickets}
                  url={getAdminViewUnitTicketsURL(unit?.id || '')}
                />
              </St.Details>
            </St.UnitInfoContainer>

            {reservationId !== null && (
              <>
                <St.UnitInfoContainer
                  data-testid={ReviewLinksTestIds.reservationContainer}
                >
                  <St.DetailIcon icon={IconName.home} size={16} />
                  <St.Details>
                    <St.SectionSubTitle>
                      {strings.reservation}
                    </St.SectionSubTitle>

                    <ExternalLink
                      text={strings.viewReservation}
                      url={getAdminViewReservationUrl(reservationId || '')}
                    />
                  </St.Details>
                </St.UnitInfoContainer>
              </>
            )}
          </>
        )}
        <CreateTicketDrawer taskId={taskId} unit={unit} />
      </>
    )
  },
)
