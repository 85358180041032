import React from 'react'

import { OtherTimerSubType, OtherTimerType } from 'packages/grimoire'
import { IconName, SvgIcon } from 'packages/iconic'

import {
  getSubType,
  isMenuTimerConfig,
  isTimerConfigWithSubMenu,
} from '../../OtherTimers.helpers'
import {
  OtherTimerDrawerStatus,
  TimerConfig,
  TimerMenuType,
} from '../../OtherTimers.types'
import {
  ArrowWrapper,
  Label,
  StartButton,
  StartTimerIcon,
  StyledTimerRow,
} from './TimerRow.styles'

export const makeOpenSubMenuTestId = (testId: string): string =>
  `${testId}-submenu`
export const makeStartButtonTestId = (
  testId: string,
  subType?: OtherTimerSubType,
): string => `${testId}${subType ? `-${subType}` : ''}-btn`

export enum TimerRowTestIds {
  container = 'TimerRow__container',
}

const getDataTestId = (config: TimerConfig): string => {
  if (isMenuTimerConfig(config)) return makeOpenSubMenuTestId(config.dataTestId)

  const subType = isTimerConfigWithSubMenu(config) ? config.subType : undefined

  return makeStartButtonTestId(config.dataTestId, subType)
}

export type TimerRowProps = {
  config: TimerConfig
  onOpenMenu: (menu: TimerMenuType) => void
  onStartOtherTimer: (
    otherTimerType: OtherTimerType,
    subTaskType?: OtherTimerSubType,
  ) => Promise<void>
  otherTimerDrawerStatus: OtherTimerDrawerStatus
}

export const TimerRow: React.FC<TimerRowProps> = ({
  config,
  onOpenMenu,
  onStartOtherTimer,
  otherTimerDrawerStatus,
}) => {
  const { isDisabled, isExpanded, loadingTimerId } = otherTimerDrawerStatus

  const { slug, type } = config

  const dataTestId = getDataTestId(config)

  const handleClick = React.useMemo(() => {
    const subType = getSubType(config)

    const clickAction = async () =>
      isMenuTimerConfig(config)
        ? onOpenMenu(config.subMenu)
        : await onStartOtherTimer(config.type, subType || undefined)

    return clickAction
  }, [config, onOpenMenu, onStartOtherTimer])

  const isMenu = type === 'MENU'

  return (
    <StyledTimerRow
      key={slug}
      isExpanded={isExpanded}
      onClick={isMenu ? handleClick : undefined}
    >
      <Label isMenu={isMenu}>{slug}</Label>
      {isMenu ? (
        <ArrowWrapper>
          <SvgIcon
            dataTestId={dataTestId}
            icon={IconName.rightArrow}
            height={14}
            width={18}
          />
        </ArrowWrapper>
      ) : (
        <StartButton
          dataTestId={dataTestId}
          disabled={isDisabled}
          isLoading={!!loadingTimerId}
          onClick={handleClick}
          buttonShape={'circle'}
          buttonType={'primary'}
        >
          <StartTimerIcon icon={IconName.playIcon} size={18} />
        </StartButton>
      )}
    </StyledTimerRow>
  )
}
