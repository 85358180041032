import { pipe, omit, merge } from 'lodash/fp'

import { TimerType } from 'packages/grimoire/src/utils/timers.types'
import { apiDateStringWithSeconds } from 'packages/utils/dateHelpers'
import { evolve } from 'packages/utils/misc'

import {
  TimerAPIPatch,
  TimerAttributes,
} from 'app/fieldapp/components/timers/timers.types'

const applyIfNotNull = fn => val => val !== null ? fn(val) : val

/**
 * Transform config for using timer patch data to update the store directly.
 * Transforms started and stopped at values, if not null, while maintaining camel case
 */
export const timerPatchStoreTransforms = {
  startedAt: applyIfNotNull(apiDateStringWithSeconds),
  stoppedAt: applyIfNotNull(apiDateStringWithSeconds),
}

export function mergeTimerUpdate<T extends TimerType>(
  currentAttrs: TimerAttributes<T>,
  patchData: TimerAPIPatch<T>,
): TimerAttributes<T> {
  return pipe(
    evolve(timerPatchStoreTransforms),
    omit('id'),
    merge(currentAttrs),
  )(patchData)
}
