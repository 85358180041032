import { createSelector } from 'reselect'

import { ApplicationState } from '../../store'
import { getTicketsByIds } from '../../tickets/selectors'
import { Ticket } from '../tickets.types'

export const getOpenTicketsByUnitIds = createSelector(
  (state: ApplicationState) => state,
  (_, unitIds: string[]) => unitIds,
  (state, unitIds): Ticket[] => {
    const ticketIds = (unitIds || []).flatMap(id =>
      (state.units.data[id]?.relationships.openTickets.data || []).map(
        ticket => ticket.id,
      ),
    )
    return getTicketsByIds(state, ticketIds)
  },
)
