import React from 'react'
import { useSelector } from 'react-redux'

import { Loader } from 'packages/common'

import { fanOutDataBatchRequests } from 'app/fieldapp/store/app/actions'
import { getFanOutBatchRequests } from 'app/fieldapp/store/app/app.slice'
import { useAppDispatch, useAppSelector } from 'app/fieldapp/store/hooks'
import { ApplicationState } from 'app/fieldapp/store/store'
import { getSortedTicketIdsForUser } from 'app/fieldapp/store/tickets/selectors'
import { User } from 'app/fieldapp/store/users'

import { TicketsPage } from './TicketsPage'

export type TicketsPageContainerProps = {
  activeUser?: User
  lastFetch: number
}

export const TicketsPageContainer: React.FC<TicketsPageContainerProps> = ({
  activeUser,
  lastFetch,
}) => {
  const activeUserId = activeUser?.id || ''
  const fanOutDataBatchRequestsState = useAppSelector(getFanOutBatchRequests)
  const dispatch = useAppDispatch()

  const ticketIds = useSelector((state: ApplicationState) =>
    getSortedTicketIdsForUser(state, activeUserId),
  )

  /*
   * TODO: TPD-6626 - Rewrite this ref and hook using a less clever strategy
   */
  const isInitialAppRender = React.useRef(
    fanOutDataBatchRequestsState !== 'fulfilled',
  )

  React.useEffect(() => {
    if (isInitialAppRender.current) return
    dispatch(fanOutDataBatchRequests())
  }, [dispatch, isInitialAppRender])

  if (fanOutDataBatchRequestsState === 'pending') {
    return <Loader />
  }

  return <TicketsPage lastFetch={lastFetch} ticketIds={ticketIds} />
}
