import styled from '@emotion/styled'
import React from 'react'

import { colors } from 'packages/styles'

const StyledInput = styled.input`
  display: none;
`

const StyledLabel = styled.label`
  display: inline-block;
  padding: 10px 15px;
  background-color: ${colors.midnight10};
  color: ${colors.midnight};
  border: 1px solid ${colors.midnight30};
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${colors.midnight20};
  }
`

export type FileUploaderProps = {
  accept: string
  id: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  title: string
}

export const FileUploader: React.FC<FileUploaderProps> = ({
  accept,
  id,
  onChange,
  title,
}) => {
  return (
    <>
      <StyledInput
        type="file"
        id={id}
        accept={accept}
        onChange={onChange}
        multiple={true}
      />
      <StyledLabel htmlFor={id}>{title}</StyledLabel>
    </>
  )
}
