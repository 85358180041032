import { lowerCase } from 'lodash/fp'
import React from 'react'

import { DayOfWeek, daysOfWeek } from 'packages/grimoire/src/utils'

import {
  CrossCoverageFormAction,
  CrossCoverageFormState,
} from '../../state/CrossCoverageForm.reducer'
import { useCrossCoverageFormDatePickerModals } from '../DatePickers'
import { DaySelectOption } from '../DaySelect'
import { RepeatingCoverageForm } from './RepeatingCoverageForm'

type RepeatingCoverageFormContainerProps = {
  formDispatch: React.Dispatch<CrossCoverageFormAction>
  formState: CrossCoverageFormState
}

export const RepeatingCoverageFormContainer: React.FC<
  RepeatingCoverageFormContainerProps
> = ({ formDispatch, formState }) => {
  const { endDate, isEditable } = formState
  const datePickers = useCrossCoverageFormDatePickerModals({
    formDispatch,
    state: formState,
  })

  const handleRepeatDaysSelectChange = (
    options: DaySelectOption[],
    day: { option: DaySelectOption },
  ) => {
    formDispatch({
      payload: {
        changedDayOfWeek: lowerCase(day.option.longDay) as DayOfWeek,
        repeatDays: options.map(opt => lowerCase(opt.longDay)) as DayOfWeek[],
      },
      type: 'setRepeatDays',
    })
  }

  const currentRepeatDaysValue: DaySelectOption[] = formState.repeatDays.map(
    day => ({
      longDay: day,
      ordinal: daysOfWeek.indexOf(day),
      shortDay: day.slice(0, 3),
      startTimeOfDay: formState.startTime.timeOfDay,
    }),
  )

  return (
    <RepeatingCoverageForm
      currentRepeatDaysValue={currentRepeatDaysValue}
      endDate={endDate}
      isDisabled={!isEditable}
      handleRepeatDaysSelectChange={handleRepeatDaysSelectChange}
      showEndDatePickerModal={datePickers.showEndDatePickerModal}
    />
  )
}
