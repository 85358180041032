import styled from '@emotion/styled'
import { filter, map, pipe } from 'lodash/fp'
import React from 'react'

import { Lightbox, useLightbox } from 'packages/common'
import { convertFileToDataUrl } from 'packages/utils/misc'

import { TaskPhoto } from 'app/fieldapp/store/taskPhotos'

import { useInspectionContext } from '../../context/InspectionChecklist.context'
import { InspectionImageUploader } from '../InspectionImageUploader'
import { RequestStatus } from '../InspectionImageUploader/hooks'

const St = {
  PhotosWrapper: styled.div`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    grid-row-gap: 16px;
    justify-content: space-around;
    padding: 20px;
  `,
}

export type InspectionCategoryPhotosProps = {
  categoryId: string
  onDeletePhotoStatusChange?: (newStatus: RequestStatus) => void
  onUploadError: (err: Error) => void
  onUploadSuccess: () => void
}

export const InspectionCategoryPhotos: React.FC<InspectionCategoryPhotosProps> =
  React.memo(({ categoryId, onDeletePhotoStatusChange, onUploadError }) => {
    const { openLightbox, ...lightboxProps } = useLightbox()

    const { clean } = useInspectionContext()
    const taskPhotos = clean.taskPhotos.filter(
      cp => cp.categoryId === categoryId,
    )

    const showEmpty = taskPhotos?.length < 2

    const [tempThumbnailMap, setTempThumbnailMap] = React.useState({})

    const handleUploadSuccess = React.useCallback(
      async (taskPhotoId: string, file: File) => {
        // because there is a delay in S3 sending the upload data back to our API,
        // we need to use the image data we already have to generate a thumbnail for now
        // ideally, by the next time we fetch this data, the actual image on S3 will be ready for use
        // (it usually only takes a few seconds, but it's long enough to not work immediately in this flow)
        const base64Uri = await convertFileToDataUrl(file)
        setTempThumbnailMap(prev => ({
          ...prev,
          [taskPhotoId]: base64Uri,
        }))
      },
      [],
    )

    const showLightbox = React.useCallback(
      (sourceIndex: number) => {
        // find thumbnail for a taskPhoto, INCLUDING our temp thumbnails where applicable
        const getThumbnail = (taskPhoto: TaskPhoto) => {
          return taskPhoto.originalImage || tempThumbnailMap[taskPhoto.id]
        }

        // just the URL sources for the images pertaining to the selected category
        const newSources = pipe(filter(Boolean), map(getThumbnail))(taskPhotos)

        // find the title of the selected category
        const selectedChecklist = clean?.inspectionChecklist?.checklists.find(
          cl => cl.category.id === categoryId,
        )

        openLightbox({
          newSources,
          sourceIndex,
          title: selectedChecklist?.category.title || '',
          titles: [],
        })
      },
      [
        categoryId,
        clean?.inspectionChecklist?.checklists,
        taskPhotos,
        openLightbox,
        tempThumbnailMap,
      ],
    )

    return (
      <St.PhotosWrapper>
        {taskPhotos.map((taskPhoto, idx) => (
          <InspectionImageUploader
            categoryId={categoryId}
            taskPhoto={taskPhoto}
            key={taskPhoto.id}
            id={`inspection-photo-${taskPhoto.id}`}
            onDeletePhotoStatusChange={onDeletePhotoStatusChange}
            onThumbnailClick={() => showLightbox(idx)}
            tempThumbnailUri={tempThumbnailMap[taskPhoto.id]}
          />
        ))}

        {showEmpty && (
          <InspectionImageUploader
            categoryId={categoryId}
            id={`EmptyInspectionImageUploader`}
            onUploadError={onUploadError}
            onUploadSuccess={handleUploadSuccess}
          />
        )}

        <Lightbox {...lightboxProps} />
      </St.PhotosWrapper>
    )
  })
