import { createAsyncAction } from 'typesafe-actions'

import {
  OfflineOtherTimer,
  OtherTimersActionTypes,
} from 'app/fieldapp/store/otherTimers'
import { offlineTimers } from 'app/fieldapp/store/utils'

export const reloadOfflineOtherTimersAction = createAsyncAction(
  OtherTimersActionTypes.RELOAD_OFFLINE_OTHER_TIMERS,
  OtherTimersActionTypes.RELOAD_OFFLINE_OTHER_TIMERS_SUCCESS,
  OtherTimersActionTypes.RELOAD_OFFLINE_OTHER_TIMERS_FAILURE,
)<null, Record<string, OfflineOtherTimer>, Error>()

export const reloadOfflineOtherTimers = () => async dispatch => {
  const offlineOtherTimers = await offlineTimers.getOtherTimers()
  dispatch(reloadOfflineOtherTimersAction.success(offlineOtherTimers))
}
