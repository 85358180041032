import styled from '@emotion/styled'
import * as React from 'react'

import { CleanTime } from 'app/fieldapp/store/cleantimes'
import { OtherTimer } from 'app/fieldapp/store/otherTimers'
import { TicketTime } from 'app/fieldapp/store/ticket-times'

import {
  PausedCleanTimerDisplay,
  PausedOtherTimerDisplay,
  PausedTicketTimerDisplay,
} from '../PausedTimerDisplay'

const PausedTimersListContainer = styled.div`
  transition: max-height 300ms ease;
`

export enum PausedTimersListTestIds {
  container = 'PausedTimersList__container',
}

export type PausedTimersListProps = {
  isLoading: boolean
  pausedCleanTimes: CleanTime[]
  pausedOtherTimers: OtherTimer[]
  pausedTicketTimes: TicketTime[]
  requestPending: boolean
  wrapperStyles: Pick<React.CSSProperties, 'maxHeight'>
}

export const PausedTimersList: React.FC<PausedTimersListProps> = ({
  isLoading,
  pausedCleanTimes,
  pausedOtherTimers,
  pausedTicketTimes,
  requestPending,
  wrapperStyles,
}) => {
  return (
    <PausedTimersListContainer
      data-testid={PausedTimersListTestIds.container}
      style={wrapperStyles}
    >
      {pausedCleanTimes.map(cleanTime => {
        return (
          <PausedCleanTimerDisplay
            cleanTime={cleanTime}
            key={cleanTime.id}
            loading={isLoading}
            requestPending={requestPending}
          />
        )
      })}

      {pausedTicketTimes.map(ticketTime => {
        return (
          <PausedTicketTimerDisplay
            key={ticketTime.id}
            loading={isLoading}
            requestPending={requestPending}
            ticketTime={ticketTime}
          />
        )
      })}

      {pausedOtherTimers.map(otherTimer => {
        return (
          <PausedOtherTimerDisplay
            key={otherTimer.id}
            loading={isLoading}
            otherTimer={otherTimer}
            requestPending={requestPending}
          />
        )
      })}
    </PausedTimersListContainer>
  )
}
