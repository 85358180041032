import { createAsyncAction } from 'typesafe-actions'

import {
  RequestConfig,
  RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import {
  NormalizedUsersApiResponse,
  UsersActionTypes,
  UserApiFieldsWithoutPII,
  usersService,
} from 'app/fieldapp/store/users'

import { HousekeeperApiFields } from '../../housekeepers'

export const fetchCurrentUserAction = createAsyncAction(
  UsersActionTypes.FETCH_CURRENT_USER,
  UsersActionTypes.FETCH_CURRENT_USER_SUCCESS,
  UsersActionTypes.FETCH_CURRENT_USER_FAILURE,
)<
  RequestConfig<NormalizedUsersApiResponse>,
  NormalizedUsersApiResponse,
  Error
>()

export const getParams = (): RequestOptions => ({
  fields: { housekeeper: HousekeeperApiFields, user: UserApiFieldsWithoutPII },
  include: ['housekeeper'],
})

/**
 * Sends a GET request to the server to fetch the currently logged-in User.
 */
export const fetchCurrentUser = () => async dispatch => {
  try {
    const request = usersService.fetchCurrentUser.bind(null, getParams())
    const result = await dispatch(fetchCurrentUserAction.request({ request }))
    dispatch(fetchCurrentUserAction.success(result))
  } catch (error) {
    dispatch(fetchCurrentUserAction.failure(error))
    throw error
  }
}
