import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useConfirmModal } from 'packages/common'
import { isBefore, startOfToday } from 'packages/utils/dateHelpers'
import { DateFormat, format } from 'packages/utils/dateHelpers'
import { useWrappedEvent } from 'packages/utils/hooks/useWrappedEvent'

import { useTicket } from 'app/fieldapp/components/tickets/hooks'
import { useTicketDetails } from 'app/fieldapp/components/tickets/TicketDetail/useTicketDetails'
import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { AppDispatch } from 'app/fieldapp/store/store'
import { setVisitTicketDrawer } from 'app/fieldapp/store/ui/actions'
import { getActiveUser } from 'app/fieldapp/store/users/selectors'
import { Visit } from 'app/fieldapp/store/visits'
import { useAddTicketToVisit } from 'app/fieldapp/utils/hooks/useAddTicketToVisit/useAddTicketToVisit'

import { VisitTicketCard } from './VisitTicketCard'

const useTranslations = (
  ticketAssigneeId?: string,
  activeUserId?: string,
  openVisitId?: string,
) => {
  const { ut } = useI18n()

  return {
    addTicketButtonText: ut(
      getAddTicketButtonText(ticketAssigneeId, activeUserId, openVisitId),
    ),
    addTicketConfirmText: ut(Slugs.addTicketConfirmText),
    addTicketConfirmTitle: ut(Slugs.addTicketConfirmTitle),
    due: ut(Slugs.due),
    unknown: ut(Slugs.unknown),
  }
}

const getAddTicketButtonText = (
  ticketAssigneeId?: string,
  activeUserId?: string,
  openVisitId?: string,
) => {
  if (openVisitId) return Slugs.alreadyAddedToVisit
  if (!ticketAssigneeId) return Slugs.addTicketToVisitAndAssign
  return ticketAssigneeId === activeUserId
    ? Slugs.addTicketToVisit
    : Slugs.addTicketToVisitAndReassign
}

export const VisitTicketCardContainer: React.FC<{
  ticketId: string
  visit: Visit
}> = React.memo(({ ticketId, visit }) => {
  const dispatch: AppDispatch = useDispatch()
  const activeUser = useSelector(getActiveUser)
  const { stopEvent } = useWrappedEvent()

  const { assignee, ticket } = useTicket(ticketId)
  const ticketTimerProps = useTicketDetails(ticket)

  const strings = useTranslations(
    ticket?.assigneeId,
    activeUser?.id,
    ticket?.openVisitId,
  )
  const isOverdue = ticket?.dueDate
    ? isBefore(ticket.dueDate, startOfToday())
    : false

  // Card click handler; open detail drawer when card is clicked
  const handleCardClick = React.useCallback(() => {
    if (ticket?.id) {
      dispatch(
        setVisitTicketDrawer({
          isOpen: true,
          ticketId: ticket.id,
        }),
      )
    }
  }, [dispatch, ticket?.id])

  const { initiateAcceptAndAddTicket, loading } = useAddTicketToVisit({
    ticketId,
    visit,
  })

  // set up confirm modal for updating ticket/visit (based on current accepted/attached statuses)
  const { showModal } = useConfirmModal({
    onConfirm: initiateAcceptAndAddTicket,
    slugs: {
      message: strings.addTicketConfirmText,
      title: strings.addTicketConfirmTitle,
    },
  })

  const isAssignedToCurrentUser =
    !!activeUser?.id && assignee?.id === activeUser?.id

  const headerDateDisplay = {
    headerDate: ticket?.dueDateNoTimestamp
      ? format(ticket.dueDateNoTimestamp, DateFormat.MonthDateAndYear)
      : strings.unknown,
    headerDateLabel: strings.due,
  }

  const isAttachedToVisit = ticket && visit.ticketIds?.includes(ticket.id)

  return ticket ? (
    <VisitTicketCard
      addTicketText={strings.addTicketButtonText}
      assignee={assignee}
      headerDateDisplay={headerDateDisplay}
      isAttachedToVisit={isAttachedToVisit}
      isAttachedToOtherVisit={!!ticket?.openVisitId}
      isAssignedToCurrentUser={isAssignedToCurrentUser}
      isLoading={loading}
      isOverdue={isOverdue}
      onAddTicketClick={stopEvent(showModal)}
      onClick={handleCardClick}
      ticket={ticket}
      ticketTimerProps={ticketTimerProps}
    />
  ) : null
})
