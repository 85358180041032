import styled from '@emotion/styled'

import { colors, text } from 'packages/styles'

export const FormSection = styled.div`
  margin-top: 24px;
`

export const SectionHeader = styled.div`
  ${text.fieldLabelMobile};
  margin-bottom: 8px;
`

export const Text = styled.div`
  ${text.bodyRegularSmall};
  color: ${colors.dusk80};
`
