import { useDecision } from '@optimizely/react-sdk'
import React from 'react'
import { useLocation } from 'react-router-dom'

import { useManageDrawerState } from 'packages/common'
import { IconName, SvgIcon } from 'packages/iconic'
import { Features } from 'packages/optimizely'
import { ADMIN_TICKETS_URL } from 'packages/utils/links'
import { splitSearchQuery } from 'packages/utils/misc'

import { LastFetchBanner, LinksCard } from 'app/fieldapp/components/core'
import { CreateTicketDrawer } from 'app/fieldapp/components/schedule/components/common'
import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { useActiveUser } from 'app/fieldapp/utils/hooks/useActiveUser'

import { CreateVisitWithTicketDrawer } from '../../core/components/CreateVisit/CreateVisitWithTicketDrawer'
import { VisitDetailDrawerContainer } from '../../schedule/components/VisitDetail/VisitDetailDrawer'
import { VisitTicketDrawerContainer } from '../../schedule/components/VisitTicketDrawer'
import { TicketCardContainer } from '../TicketCard'
import { TicketDetailDrawerContainer as TicketDetailDrawer } from '../TicketDetail/TicketDetailDrawer'
import { CoverageAlert } from './CoverageAlert'

import styles from './TicketsPage.module.scss'

export const MAX_TICKETS = 100

export type TicketsPageProps = {
  lastFetch: number
  ticketIds: string[]
}

export const TicketsPage: React.FC<TicketsPageProps> = ({
  lastFetch,
  ticketIds,
}) => {
  // TODO: Clean up once cross-coverage FF is permanently enabled
  const [decision] = useDecision(Features.CROSS_COVERAGE)
  const isFFCrossCoverage = decision.enabled

  const { isEmployee } = useActiveUser()

  const location = useLocation()
  const { selectedTicket = '' } = splitSearchQuery(location.search)

  const { ut } = useI18n()

  const moreTicketsLink = {
    text: ut(Slugs.moreTickets),
    url: ADMIN_TICKETS_URL,
  }
  const externalLinks = ticketIds.length >= MAX_TICKETS ? [moreTicketsLink] : []

  const drawerStateResult = useManageDrawerState()
  const { closeDrawer, completeDrawerClose, drawerState, openDrawer } =
    drawerStateResult

  const [visitUnitId, setVisitUnitId] = React.useState<undefined | string>(
    undefined,
  )

  const handleOpenVisitDrawer = React.useCallback(
    (unitId: string) => {
      setVisitUnitId(unitId)
      openDrawer()
    },
    [openDrawer],
  )

  return (
    <>
      <header className={styles.header}>
        <h1>{ut(Slugs.myTickets)}</h1>
      </header>

      {isFFCrossCoverage && isEmployee && <CoverageAlert />}

      <LastFetchBanner
        className={styles.lastFetchBanner}
        lastFetch={lastFetch}
      />

      <div className={styles.main}>
        {!ticketIds.length && (
          <div className={styles.noResults}>
            <SvgIcon
              className={styles.noResultsIcon}
              icon={IconName.tag}
              size={82}
            />
            {ut(Slugs.noAssignedTickets)}
          </div>
        )}

        {ticketIds.map(ticketId => (
          <TicketCardContainer
            key={ticketId}
            handleOpenVisitDrawer={handleOpenVisitDrawer}
            ticketId={ticketId}
          />
        ))}

        {!!externalLinks.length && (
          <LinksCard className={styles.linksCard} links={externalLinks} />
        )}

        <CreateTicketDrawer isDrawerSection={false} />
        <TicketDetailDrawer ticketId={selectedTicket} />
        <CreateVisitWithTicketDrawer
          closeDrawer={closeDrawer}
          completeDrawerClose={completeDrawerClose}
          drawerState={drawerState}
          visitUnitId={visitUnitId}
        />
        <VisitDetailDrawerContainer />
        <VisitTicketDrawerContainer />
      </div>
    </>
  )
}
