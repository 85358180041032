import { get } from 'lodash/fp'

import { cleanIsMidstay } from 'packages/grimoire'
import {
  DateFormat,
  MIDSTAY_CLEAN_DUE_TIME_STRING,
  formatLocalized,
  isSameDay,
} from 'packages/utils/dateHelpers'

import type { Clean } from 'app/fieldapp/store/cleans'

import { Reservation } from '../store/reservations'

export function formatCleanDateAtPath(
  clean: Clean,
  pathToDateAttr: string[],
  dateFormat: string,
): string {
  const targetDate = get(pathToDateAttr)(clean)
  const format = isSameDay(targetDate, clean.effectiveDate)
    ? DateFormat.TimeWithAmPm
    : dateFormat

  return formatLocalized(targetDate, format, clean.unit?.tz)
}

type ReservationDateTimeAttributes =
  | 'checkinDate'
  | 'checkinTime'
  | 'checkoutDate'
  | 'checkoutTime'

export function formatReservationDateTimeAttributes(
  reservation?: Reservation,
): Record<ReservationDateTimeAttributes, string> {
  if (!reservation) {
    return {
      checkinDate: '',
      checkinTime: '',
      checkoutDate: '',
      checkoutTime: '',
    }
  }

  return {
    checkinDate: formatLocalized(
      reservation.checkIn,
      DateFormat.MonthAndDayLong,
    ),
    checkinTime: formatLocalized(reservation.checkIn, DateFormat.TimeWithAmPm),
    checkoutDate: formatLocalized(
      reservation.checkOut,
      DateFormat.MonthAndDayLong,
    ),
    checkoutTime: formatLocalized(
      reservation.checkOut,
      DateFormat.TimeWithAmPm,
    ),
  }
}

export const cleanCheckoutStringForShare = (clean: Clean): string =>
  formatCleanDateAtPath(
    clean,
    ['reservation', 'checkOut'],
    DateFormat.ShareText,
  )

export const cleanDueDateStringForShare = (clean: Clean): string =>
  formatCleanDateAtPath(clean, ['dueDate'], DateFormat.ShareText)

export const cleanDueDateString = (clean: Clean): string => {
  if (cleanIsMidstay(clean)) {
    return MIDSTAY_CLEAN_DUE_TIME_STRING
  }

  return formatCleanDateAtPath(clean, ['dueDate'], DateFormat.Full)
}
