import React from 'react'

import { UseFormFns } from 'packages/common'
import {
  createDateString,
  DateFormat,
  format,
} from 'packages/utils/dateHelpers'

import { StandardAvailability } from 'app/fieldapp/store/standardAvailability'

import { StandardAvailabilityFormValues } from './StandardAvailabilityForm.container'

export type AvailabilityRequestTypes =
  | 'PATCH_UPCOMING'
  | 'POST_CURRENT'
  | 'POST_UPCOMING'
  | 'POST_CURRENT_AND_UPCOMING'
  | 'POST_CURRENT_AND_PATCH_UPCOMING'

export type getAvailabilitySubmissionRequestTypeProps = {
  currentAvailability?: StandardAvailability
  hasCurrentChanged: boolean
  hasUpcomingChanged: boolean
  isUpcomingSwitch: boolean
  upcomingAvailability?: StandardAvailability
}

/**
getAvailabilitySubmissionRequestType takes in all of the state related to the standard availability form
and then returns the type of request we want to make as a string (e.g. POST to current, PATCH to upcoming, etc)
*/
export const getAvailabilitySubmissionRequestType = ({
  currentAvailability,
  hasCurrentChanged,
  hasUpcomingChanged,
  isUpcomingSwitch,
  upcomingAvailability,
}: getAvailabilitySubmissionRequestTypeProps):
  | AvailabilityRequestTypes
  | undefined => {
  // PATCH AND POST CASES
  if (
    currentAvailability &&
    hasCurrentChanged &&
    isUpcomingSwitch &&
    !upcomingAvailability
  ) {
    return 'POST_CURRENT_AND_UPCOMING'
  }

  if (
    !currentAvailability &&
    hasUpcomingChanged &&
    isUpcomingSwitch &&
    upcomingAvailability
  ) {
    return 'POST_CURRENT_AND_PATCH_UPCOMING'
  }

  // POST CASES
  if (!currentAvailability && isUpcomingSwitch && !upcomingAvailability) {
    return 'POST_CURRENT_AND_UPCOMING'
  }

  if (!currentAvailability && !isUpcomingSwitch) {
    return 'POST_CURRENT'
  }

  if (
    currentAvailability &&
    !hasCurrentChanged &&
    !upcomingAvailability &&
    isUpcomingSwitch
  ) {
    return 'POST_UPCOMING'
  }

  // PATCH CASES
  if (
    currentAvailability &&
    hasCurrentChanged &&
    hasUpcomingChanged &&
    isUpcomingSwitch &&
    upcomingAvailability
  ) {
    return 'POST_CURRENT_AND_PATCH_UPCOMING'
  }

  if (currentAvailability && hasCurrentChanged && !hasUpcomingChanged) {
    return 'POST_CURRENT'
  }

  if (
    currentAvailability &&
    !hasCurrentChanged &&
    hasUpcomingChanged &&
    isUpcomingSwitch &&
    upcomingAvailability
  ) {
    return 'PATCH_UPCOMING'
  }
}

type SubmissionAction = (
  formValues: StandardAvailabilityFormValues,
  userId: string,
) => Promise<unknown>

type submitChangesToHkWebAPIProps = {
  createAvailabilityFn: SubmissionAction
  currentAvailability?: StandardAvailability
  currentFormManager: UseFormFns<StandardAvailabilityFormValues> | null
  fetchStandardAvailabilityFn: () => Promise<(dispatch) => Promise<void>>
  hasCurrentChanged: boolean
  hasUpcomingChanged: boolean
  isUpcomingSwitch: boolean
  upcomingAvailability?: StandardAvailability
  upcomingFormManager: UseFormFns<StandardAvailabilityFormValues> | null
  updateAvailabilityFn: SubmissionAction
  userId?: string
}

/*
  getRequests uses the submission request types to determine the kind of action we want to do
  and then validates it
*/
export const getRequests = ({
  createAvailabilityFn,
  currentAvailability,
  currentFormManager,
  hasCurrentChanged,
  hasUpcomingChanged,
  isUpcomingSwitch,
  upcomingAvailability,
  upcomingFormManager,
  updateAvailabilityFn,
  userId,
}: submitChangesToHkWebAPIProps): Promise<unknown>[] => {
  const TODAY = format(createDateString(), DateFormat.ApiUtcWithSeconds)

  switch (
    getAvailabilitySubmissionRequestType({
      currentAvailability,
      hasCurrentChanged,
      hasUpcomingChanged,
      isUpcomingSwitch,
      upcomingAvailability,
    })
  ) {
    case 'PATCH_UPCOMING':
      if (upcomingAvailability && upcomingFormManager) {
        return [
          updateAvailabilityFn(
            upcomingFormManager?.formValues,
            upcomingAvailability.id,
          ),
        ]
      }

      return []

    case 'POST_CURRENT':
      if (userId && currentFormManager) {
        return [
          createAvailabilityFn(
            { ...currentFormManager?.formValues, startDate: TODAY },
            userId,
          ),
        ]
      }

      return []

    case 'POST_UPCOMING':
      if (userId && upcomingFormManager) {
        return [createAvailabilityFn(upcomingFormManager?.formValues, userId)]
      }

      return []

    case 'POST_CURRENT_AND_UPCOMING':
      if (userId && currentFormManager && upcomingFormManager) {
        return [
          createAvailabilityFn(
            { ...currentFormManager?.formValues, startDate: TODAY },
            userId,
          ),
          createAvailabilityFn(upcomingFormManager?.formValues, userId),
        ]
      }

      return []

    case 'POST_CURRENT_AND_PATCH_UPCOMING':
      if (
        userId &&
        currentFormManager &&
        upcomingAvailability &&
        upcomingFormManager
      ) {
        return [
          createAvailabilityFn(
            { ...currentFormManager?.formValues, startDate: TODAY },
            userId,
          ),
          updateAvailabilityFn(
            upcomingFormManager?.formValues,
            upcomingAvailability.id,
          ),
        ]
      }

      return []

    default:
      return []
  }
}

type useSubmitAvailabilityChangesProps = {
  deleteAvailabilityFn: (
    availabilityId: string,
  ) => Promise<(dispatch) => Promise<void>>
  fetchStandardAvailabilityFn: () => Promise<(dispatch) => Promise<void>>
  shouldDeleteUpcoming: boolean | undefined
  upcomingAvailabilityId: string | undefined
}

export const useSubmitAvailabilityChanges = (
  args: submitChangesToHkWebAPIProps,
): ((args: useSubmitAvailabilityChangesProps) => void) => {
  return React.useCallback(
    // Before we PATCH or POST standard availability we need to check if the user wants to DELETE
    // and if so ensure that happens synchronously before moving on to any PATCH or POST requests.
    // The reason we DELETE first is to avoid race conditons
    async ({
      deleteAvailabilityFn,
      fetchStandardAvailabilityFn,
      shouldDeleteUpcoming,
      upcomingAvailabilityId,
    }) => {
      if (shouldDeleteUpcoming && upcomingAvailabilityId) {
        await deleteAvailabilityFn(upcomingAvailabilityId)
      }

      await Promise.all(getRequests(args))
      fetchStandardAvailabilityFn()
    },
    [args],
  )
}
