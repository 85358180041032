import { produce } from 'immer'

import {
  SubMenuType,
  OtherTimerSubType,
  OtherTimerType,
} from 'packages/grimoire'
import { TranslatorFn } from 'packages/i18n'

import {
  MenuType,
  RequiredOtherTimeAttrs,
  OtherTimerSubMenuConfig,
  TimerConfig,
  TimerMenuConfig,
} from './OtherTimers.types'

export const translateMenu =
  (ut: TranslatorFn) =>
  (menu: TimerMenuConfig): TimerMenuConfig =>
    produce(menu, draft => {
      menu.timers.forEach((timer, idx) => {
        draft.timers[idx].slug = ut(timer.slug)
      })

      if (menu.title) draft.title = ut(menu.title)

      return
    })

export const isSubMenuType = (
  otherTimerType: OtherTimerType | undefined,
): otherTimerType is SubMenuType => {
  return (
    otherTimerType === OtherTimerType.DRIVE ||
    otherTimerType === OtherTimerType.OTHER
  )
}

export const isTimerConfigWithSubMenu = (
  config: TimerConfig,
): config is OtherTimerSubMenuConfig =>
  config.type === OtherTimerType.OTHER || config.type === OtherTimerType.DRIVE

export const isMenuTimerConfig = (
  config: TimerConfig,
): config is MenuType & RequiredOtherTimeAttrs => config.type === 'MENU'

export const getSubType = (config: TimerConfig): OtherTimerSubType | null => {
  if (isTimerConfigWithSubMenu(config)) return config.subType
  return null
}
