import { produce } from 'immer'

import { UnitRealtimeStatus } from 'packages/grimoire'
import { transformNormalizedToTyped } from 'packages/utils/store'

import {
  NormalizedUnitsApiResponse,
  RawUnit,
  Unit,
  UnitAttributeNames,
  UnitResponse,
} from './units.types'

/**
 * Empty data in the shape of the Units service's `normalized` payload.
 * Use this as a fallback when there are issues with the API data being undefined or malformed.
 */
export const emptyNormalizedUnitsData: UnitResponse = Object.freeze({
  housekeeper: {},
  reservation: {},
  task: {},
  ticket: {},
  unit: {},
  user: {},
})

export const emptyUnitsResponse: NormalizedUnitsApiResponse = Object.freeze({
  normalized: emptyNormalizedUnitsData,
  raw: { data: [] },
})

export const hydrateRawUnit = (rawUnit: RawUnit): Unit => {
  const baseUnit = transformNormalizedToTyped<Unit>(rawUnit, UnitAttributeNames)
  return produce(baseUnit, draft => {
    draft.currentResId = rawUnit.relationships.currentReservation?.data?.id
    draft.nextResId = rawUnit.relationships.nextReservation?.data?.id
    draft.previousResId = rawUnit.relationships.previousReservation?.data?.id

    draft.openTicketIds = (rawUnit.relationships?.openTickets?.data || []).map(
      ({ id }) => id,
    )

    draft.unitFamilyIds = Object.values(
      rawUnit.relationships?.unitFamily?.data || {},
    ).map(unit => unit.id)

    draft.openVisitIds = (rawUnit.relationships?.openVisits?.data || []).map(
      ({ id }) => id,
    )

    draft.zone = {
      id: rawUnit.relationships.zone?.data?.id || '',
    }
  })
}

export const UnknownUnit: Unit = Object.freeze({
  address: '',
  alarmCode: '',
  amenities: {},
  bathrooms: 2,
  bedrooms: 2,
  beds: 3,
  city: 'unknown',
  currentResId: '',
  gateCode: '',
  hasSmartLock: false,
  hottub: true,
  housekeeperNotes: '',
  id: 'unknown',
  isActive: '',
  lastDeepCleanedAt: '',
  managerInfo: {
    firstName: 'Luke',
    lastName: 'Skywalker',
    mobilePhone: '5551235555',
  },
  maxOccupancy: 10,
  name: 'unknown',
  nextDeepCleanDueAt: '',
  nextResId: '',
  openTicketIds: [],
  openVisitIds: [],
  previousResId: '',
  realtimeStatus: UnitRealtimeStatus.UNKNOWN,
  state: 'unknown',
  statistics: null,
  tz: '',
  unitCode: 'unknown',
  zone: {
    id: 'unknown',
  },
})
