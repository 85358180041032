import { createAsyncAction } from 'typesafe-actions'

import { apiFilterExcludeCleans } from 'packages/grimoire'
import {
  RequestConfig,
  RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import { HousekeeperApiFields } from '../../housekeepers'
import { LockboxApiFields } from '../../lockboxes'
import { ReservationApiFields } from '../../reservations'
import { SmartLockApiFields } from '../../smartlocks'
import { TaskPhotoApiFields } from '../../taskPhotos'
import { NormalizedTasksApiResponse } from '../../tasks'
import { tasksService } from '../../tasks/tasks.service'
import { TicketApiFields } from '../../tickets'
import { UnitApiFields } from '../../units'
import { UserApiFieldsWithoutPII } from '../../users'
import { VisitApiFields, VisitsActionTypes } from '../visits.types'

export const fetchVisitByIdAction = createAsyncAction(
  VisitsActionTypes.FETCH_VISIT,
  VisitsActionTypes.FETCH_VISIT_SUCCESS,
  VisitsActionTypes.FETCH_VISIT_FAILURE,
)<
  RequestConfig<NormalizedTasksApiResponse>,
  NormalizedTasksApiResponse,
  Error
>()

export const getParams = (): RequestOptions => ({
  fields: {
    assignment: [],
    housekeeper: HousekeeperApiFields,
    lock_box: LockboxApiFields,
    reservation: ReservationApiFields,
    smart_lock: SmartLockApiFields,
    task: VisitApiFields,
    task_photos: TaskPhotoApiFields,
    ticket: TicketApiFields,
    unit: UnitApiFields,
    user: UserApiFieldsWithoutPII,
  },
  filter: {
    ...apiFilterExcludeCleans,
  },
  include: [
    'active_assignments',
    'active_assignments.housekeeper',
    'active_assignments.housekeeper.user',
    'assigned_housekeepers.user',
    'inspection_flags',
    'last_clean_completed_housekeepers',
    'last_clean_completed_housekeepers.user_no_pii',
    'smart_locks',
    'task_photos',
    'tickets',
    'tickets.assignee',
    'tickets.open_visit',
    'unit',
    'unit.current_reservation',
    'unit.lock_box',
    'unit.next_reservation',
    'unit.open_tickets',
    'unit.open_tickets.assignee',
    'unit.open_tickets.disposition',
    'unit.open_tickets.open_visit',
    'unit.previous_reservation',
    'unit.unit_family.open_tickets',
    'unit.unit_family.open_tickets.assignee',
    'unit.unit_family.open_tickets.open_visit',
  ],
  page: { size: 100, 'unit.open_tickets': { size: 500 } },
})

export const fetchVisitById = (visitId: string) => async dispatch => {
  try {
    const params = getParams()
    const request = tasksService.fetchTaskById.bind(null, visitId, params)
    const result = await dispatch(fetchVisitByIdAction.request({ request }))
    dispatch(fetchVisitByIdAction.success(result))
    return result.normalized
  } catch (error) {
    dispatch(fetchVisitByIdAction.failure(error))
    throw error
  }
}
