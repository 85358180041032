import { createSelector } from 'reselect'

import { isToday } from 'packages/utils/dateHelpers'

import { ApplicationState } from 'app/fieldapp/store/store'

import { TicketTime, RawTicketTime } from '../ticket-times.types'
import { getTicketTimeById } from './getTicketTimeById'

const ticketTimerIsSubmittable = (rtt: RawTicketTime): boolean =>
  !!rtt.attributes.startedAt &&
  !!rtt.attributes.stoppedAt &&
  !rtt.attributes.isFinalized &&
  isToday(rtt.attributes.stoppedAt)

const getFullState = (state: ApplicationState): ApplicationState => state

export const getSubmittableTicketTime = createSelector(
  getFullState,
  (fullState): TicketTime | undefined => {
    const rawTicketTimes: RawTicketTime[] = Object.values(
      fullState.ticketTimes.data,
    )
    const rawSubmittableTicketTime = rawTicketTimes.find(
      ticketTimerIsSubmittable,
    )
    if (!rawSubmittableTicketTime) return undefined

    // ignore any timers with associated offline data (these will show up in offline timers UI)
    const offlineTimer =
      fullState.ticketTimes.offlineData[rawSubmittableTicketTime.id]
    if (offlineTimer) return undefined

    // ignore any timers associated with tickets that are no longer assigned to current user
    const ticketId = rawSubmittableTicketTime.attributes.ticketId
    const rawTicket = fullState.tickets.data[ticketId]
    if (!rawTicket) {
      return undefined
    }

    return getTicketTimeById(fullState, rawSubmittableTicketTime.id)
  },
)
