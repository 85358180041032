import { transformNormalizedToTyped } from 'packages/utils/store'

import {
  Lockbox,
  LockboxAttributeNames,
  NormalizedLockboxesApiResponse,
  RawLockbox,
} from './lockboxes.types'

/**
 * Empty data in the shape of the Lockboxes service's `normalized` payload.
 * Use this as a fallback when there are issues with the API data being undefined or malformed.
 */
export const emptyNormalizedLockboxesData = Object.freeze({
  lockBox: {},
})

/**
 * Empty data to use as a "safety net" any time an API response is undefined for any reason
 */
export const emptyLockboxesResponse: NormalizedLockboxesApiResponse =
  Object.freeze({
    normalized: emptyNormalizedLockboxesData,
    raw: { data: [] },
  })

export const hydrateRawLockbox = (rawLockbox: RawLockbox): Lockbox =>
  transformNormalizedToTyped<Lockbox>(rawLockbox, LockboxAttributeNames)
