import * as React from 'react'

import { StartTimerButton } from 'app/fieldapp/components/timers/components/Buttons/StartTimerButton'

import styles from './CleanTimerControls.module.scss'

export enum CleanTimerControlsTestIds {
  container = 'CleanTimerControls__container',
  startBtn = 'CleanTimerControls__startBtn',
}

export type CleanTimerControlsProps = {
  isDisabled: boolean
  isLoading: boolean
  onStartClick: () => void
}

export const CleanTimerControls: React.FC<CleanTimerControlsProps> = React.memo(
  ({ isDisabled, isLoading, onStartClick }) => {
    return (
      <div
        className={styles.cleanTimerControls}
        data-testid={CleanTimerControlsTestIds.container}
      >
        <StartTimerButton
          ariaLabel="Start a new timer"
          dataTestId={CleanTimerControlsTestIds.startBtn}
          disabled={isDisabled}
          isLoading={isLoading}
          onClick={onStartClick}
        />
      </div>
    )
  },
)
