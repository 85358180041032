import { transformNormalizedToTyped } from 'packages/utils/store'

import {
  StandardAvailabilityResponse,
  NormalizedStandardAvailabiltyApiResponse,
  RawStandardAvailability,
  StandardAvailability,
  StandardAvailabilityAttributeNames,
} from './standardAvailability.types'

export const emptyNormalizedStandardAvailabilityData: StandardAvailabilityResponse =
  Object.freeze({
    standardAvailability: {},
  })

export const emptyStandardAvailabilityResponse: NormalizedStandardAvailabiltyApiResponse =
  Object.freeze({
    normalized: emptyNormalizedStandardAvailabilityData,
    raw: { data: [] },
  })

export const hydrateRawStandardAvailability = (
  rawStandardAvailability: RawStandardAvailability,
): StandardAvailability =>
  transformNormalizedToTyped<StandardAvailability>(
    rawStandardAvailability,
    StandardAvailabilityAttributeNames,
  )
