import { isAfter } from 'packages/utils/dateHelpers'

import { Clean, CleanPatchData } from 'app/fieldapp/store/cleans'

export const getCleanUpdate = (
  existingClean: Clean | undefined,
  startedAt: Date,
): CleanPatchData | undefined => {
  // We can't patch if the clean doesn't exist
  if (!existingClean) return undefined

  const existingStartTime = existingClean.startedAt

  const shouldUpdateClean =
    !existingStartTime ||
    (!!existingStartTime && isAfter(existingStartTime, startedAt))

  const cleanPatchData: CleanPatchData = {
    id: existingClean.id,
    startedAt,
  }

  return shouldUpdateClean ? cleanPatchData : undefined
}
