import { TimerType } from 'packages/grimoire/src/utils/timers.types'

// eslint-disable-next-line no-restricted-imports
import {
  TimerOffline,
  TimerOfflinePatch,
} from 'app/fieldapp/components/timers/timers.types'

export function makeOfflinePatchData<T extends TimerType>(
  data: TimerOfflinePatch<T> & { id: string },
): TimerOffline<T> {
  const { id, ...attributes } = data

  return {
    attributes,
    id,
  }
}
