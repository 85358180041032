import { every, map, pipe, trim, values } from 'lodash/fp'

import { CleanJobTypeEditorProps } from './CleanJobTypeEditor'

type FormValues = CleanJobTypeEditorProps['formState']['values']

export const validateDeepCleanSkipForm = (formValues: FormValues): boolean => {
  const stringsAreValid = pipe(values, map(trim), every(Boolean))(formValues)
  const typeIsValid = formValues.cleanJobType !== 'deep'
  return stringsAreValid && typeIsValid
}
