import { useSelector } from 'react-redux'

import { getActiveCleanTime } from 'app/fieldapp/store/cleantimes/selectors'
import { getActiveOtherTimer } from 'app/fieldapp/store/otherTimers/selectors'
import { getActiveTicketTime } from 'app/fieldapp/store/ticket-times/selectors'

export const useHasActiveTimer = (): boolean => {
  const activeTicketTimer = useSelector(getActiveTicketTime)
  const activeOtherTime = useSelector(getActiveOtherTimer)
  const activeCleanTime = useSelector(getActiveCleanTime)

  return Boolean(activeTicketTimer || activeOtherTime || activeCleanTime)
}
