import { createAsyncAction } from 'typesafe-actions'

import {
  RequestConfig,
  RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import * as ticketsService from '../tickets.service'
import {
  DispositionApiFields,
  NormalizedDispositionResponse,
  TicketsActionTypes,
} from '../tickets.types'

export const fetchTicketingMaintenanceDispositionsAction = createAsyncAction(
  TicketsActionTypes.FETCH_ALL_DISPOSITIONS,
  TicketsActionTypes.FETCH_ALL_DISPOSITIONS_SUCCESS,
  TicketsActionTypes.FETCH_ALL_DISPOSITIONS_FAILURE,
)<
  RequestConfig<NormalizedDispositionResponse>,
  NormalizedDispositionResponse,
  Error
>()

export const getParams = (): RequestOptions => ({
  fields: {
    disposition: DispositionApiFields,
  },
  filter: {
    tier_one: { in: ['housekeeping', 'maintenance'] },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    tier_three__isnull: false,
  },
  page: { size: 75 },
})

export const fetchTicketingMaintenanceDispositions = async dispatch => {
  try {
    const params = getParams()
    const request = ticketsService.fetchTicketingMaintenanceDispositions.bind(
      null,
      params,
    )
    const result = await dispatch(
      fetchTicketingMaintenanceDispositionsAction.request({ request }),
    )
    dispatch(fetchTicketingMaintenanceDispositionsAction.success(result))
    return result.normalized
  } catch (error) {
    dispatch(fetchTicketingMaintenanceDispositionsAction.failure(error))
    throw error
  }
}
