import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Button } from 'packages/common'
import { SvgIcon } from 'packages/iconic'
import { colors } from 'packages/styles'

import { CleanHeader } from '../CleanHeader'

export const CleanDetailSection = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
`

export const CleanDetailHeader = styled(CleanHeader)`
  padding: 0 0 0 24px;
`

export const CleanDetailIcon = styled(SvgIcon)`
  padding-top: 4px;
`

export const CleanDetailSectionHeader = styled.strong`
  margin-bottom: 8px;
`

export const TeamCleanIcon = styled(CleanDetailIcon)`
  color: ${colors.awareness}; // setting color here cascades to the "zap" SVG
`

export const CleanDetailBody = styled.div`
  padding-left: 8px;
  width: 100%;
`

const sectionBorder = css`
  border-top: 1px solid ${colors.midnight20};
  margin: 0 -24px;
  padding: 24px;
  padding-bottom: 0;
`

export const CleanDetailsUnitLinks = styled.div`
  ${sectionBorder};
  margin-bottom: 24px;
  padding-left: 16px;
`

export const EditTypeButton = styled(Button)`
  font-size: 14px;
  font-weight: 700;
  padding-left: 8px;
`

export const Divider = styled.hr`
  background-color: ${colors.midnight10};
  border: 0;
  height: 8px;
  margin-left: -16px;
  margin-right: -16px;
  margin-top: 24px;
  margin-bottom: 24px;
`

export const PrivacyNoticeSection = styled.div`
  ${sectionBorder};
`
