import { createAsyncAction } from 'typesafe-actions'

import { ErrorWithParams, RequestConfig } from 'packages/utils/store'

import {
  OtherTimersActionTypes,
  OtherTimePatchData,
  NormalizedOtherTimersApiResponse,
} from 'app/fieldapp/store/otherTimers'
import { offlineTimers } from 'app/fieldapp/store/utils'

import { otherTimersService } from '../otherTimers.service'
import { getMergedOtherTimerById } from '../selectors'

export const deleteOtherTimerAction = createAsyncAction(
  OtherTimersActionTypes.DELETE_OTHER_TIMER,
  OtherTimersActionTypes.DELETE_OTHER_TIMER_SUCCESS,
  OtherTimersActionTypes.DELETE_OTHER_TIMER_FAILURE,
)<
  RequestConfig<NormalizedOtherTimersApiResponse>,
  string,
  ErrorWithParams<OtherTimePatchData>
>()

export const deleteOtherTimer = (id: string) => async (dispatch, getState) => {
  const timer = getMergedOtherTimerById(getState(), id)

  // If the timer has a started offline flag, it hasn't been synced to the API, so we can just delete it locally
  if (timer?.startedOffline) {
    await offlineTimers.removeOtherTime(id)
    dispatch(deleteOtherTimerAction.success(id))
    return
  }

  try {
    const request = otherTimersService.deleteOtherTimer.bind(null, id)
    const result = await dispatch(deleteOtherTimerAction.request({ request }))

    // remove any existing copies of this timer from local cache
    await offlineTimers.removeOtherTime(id)

    dispatch(deleteOtherTimerAction.success(id))
    return result.normalized
  } catch (error) {
    dispatch(deleteOtherTimerAction.failure({ error, params: { id } }))
    throw error
  }
}
