import styled from '@emotion/styled'
import React from 'react'

import { Alert, Button, Drawer, UseManageDrawerState } from 'packages/common'
import { User } from 'packages/grimoire'
import { colors, text } from 'packages/styles'

import { Slugs, useI18n } from 'app/fieldapp/i18n'

import CoveragePartnerSelector from '../CoveragePartnerSelector/CoveragePartnerSelector'

const useTranslations = () => {
  const { t } = useI18n()
  return {
    cancel: t(Slugs.cancel),
    coveragePartner: t(Slugs.coveragePartner),
    createCoveragePartnerError: t(Slugs.createCoveragePartnerError),
    fetchCoveragePartnerOptionsError: t(Slugs.fetchCoveragePartnerOptionsError),
    fetchCurrentCoveragePartnerError: t(Slugs.fetchCurrentCoveragePartnerError),
    noCoveragePartnerFound: t(Slugs.noCoveragePartnerOptionsFound),
    partner: t(Slugs.partner),
    submit: t(Slugs.submit),
    textBlock: t(Slugs.standardCoverageExplanation),
  }
}

const St = {
  Alert: styled(Alert)`
    margin: 12px;
  `,
  ButtonSection: styled.div`
    display: flex;
    justify-content: space-evenly;
    padding: 12px;
  `,
  Drawer: styled(Drawer)`
    width: min(420px, 100vw);
  `,
  DrawerHeader: styled.div`
    align-items: center;
    background-color: ${colors.midnight10};
    display: flex;
    height: 90px;
    justify-content: center;
    margin-bottom: 20px;
  `,
  DrawerHeaderText: styled.h3`
    ${text.bodyBoldSmall};
    color: ${colors.midnight};
    margin-bottom: 0px;
    text-transform: none;
  `,
  Explanation: styled.div`
    ${text.bodyTiny};
    font-style: italic;
    padding: 12px;
  `,
  Header: styled.h2`
    ${text.headingDefault};
    padding: 12px;
    text-transform: uppercase;
  `,
  SelectContainer: styled.div`
    padding: 12px;
  `,
  SelectLabel: styled.div`
    ${text.fieldLabelMobile};
    padding-bottom: 8px;
  `,
}

export enum StandardCoveragePartnerDrawerTestIds {
  cancel = 'StandardCoveragePartnerDrawer__cancel',
  container = 'StandardCoveragePartnerDrawer__container',
  errorAlert = 'StandardCoveragePartnerDrawer__errorAlert',
  selectContainer = 'StandardCoveragePartnerDrawer__selectContainer',
  submit = 'StandardCoveragePartnerDrawer__submit',
  warningAlert = 'StandardCoveragePartnerDrawer__warningAlert',
}

export enum StandardCoveragePartnerDrawerAriaLabels {
  select = 'StandardCoveragePartnerDrawer__select',
}

const ErrorAlerts = ({ errors, strings }) => {
  return (
    <>
      {Object.entries(errors).map(([key, value]) => {
        return !!value ? (
          <St.Alert
            key={key}
            dataTestId={StandardCoveragePartnerDrawerTestIds.errorAlert}
            alertType={'danger'}
          >
            {strings[key]}
          </St.Alert>
        ) : null
      })}
    </>
  )
}

type crossCoverageRequestErrors = {
  createCoveragePartnerError: string | undefined | Error
  fetchCoveragePartnerOptionsError: string | undefined | Error
  fetchCurrentCoveragePartnerError: string | undefined | Error
}

export type StandardCoveragePartnerDrawerProps = {
  errors: crossCoverageRequestErrors
  hasUserChanged: boolean
  isLoading: boolean
  loadOptions: (inputValue, callback) => void
  onSubmit: () => void
  reset: () => void
  selectedUser: User | undefined
  setSelectedUser: (user: User) => void
} & UseManageDrawerState

export const StandardCoveragePartnerDrawer: React.FC<StandardCoveragePartnerDrawerProps> =
  React.memo(
    ({
      closeDrawer,
      completeDrawerClose,
      errors,
      drawerState,
      hasUserChanged,
      isLoading,
      onSubmit,
      reset,
      selectedUser,
      setSelectedUser,
      loadOptions,
    }) => {
      const strings = useTranslations()

      const hasAnyError = Object.values(errors).some(error => !!error)

      const isSubmitDisabled = !hasUserChanged || hasAnyError

      const handleCancelClick = () => {
        reset()
        closeDrawer()
      }

      const handleSelectChange = (user: User) => {
        reset()
        setSelectedUser(user)
      }

      return (
        <St.Drawer
          afterExit={completeDrawerClose}
          isOpen={drawerState.isOpen}
          forceClose={drawerState.forceClose}
        >
          <St.DrawerHeader>
            <St.DrawerHeaderText>{strings.coveragePartner}</St.DrawerHeaderText>
          </St.DrawerHeader>
          <div data-testid={StandardCoveragePartnerDrawerTestIds.container}>
            <St.Header>{strings.coveragePartner}</St.Header>
            <St.Explanation>{strings.textBlock}</St.Explanation>

            <St.SelectContainer
              data-testid={StandardCoveragePartnerDrawerTestIds.selectContainer}
            >
              <St.SelectLabel>{strings.partner}</St.SelectLabel>
              <CoveragePartnerSelector
                onUserSelectionChange={handleSelectChange}
                loadOptions={loadOptions}
                selectedUser={selectedUser}
                placeholder={'Select a user'}
              />
            </St.SelectContainer>

            <ErrorAlerts errors={errors} strings={strings} />

            <St.ButtonSection>
              <Button
                block={true}
                buttonType="utility"
                dataTestId={StandardCoveragePartnerDrawerTestIds.cancel}
                onClick={handleCancelClick}
              >
                {strings.cancel}
              </Button>
              <Button
                block={true}
                dataTestId={StandardCoveragePartnerDrawerTestIds.submit}
                disabled={isSubmitDisabled}
                isLoading={isLoading}
                onClick={onSubmit}
              >
                {strings.submit}
              </Button>
            </St.ButtonSection>
          </div>
        </St.Drawer>
      )
    },
  )
