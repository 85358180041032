import styled from '@emotion/styled'
import React from 'react'
import { useSelector } from 'react-redux'

import { ApplicationState } from 'app/fieldapp/store/store'
import { getOpenTicketsByUnitIds } from 'app/fieldapp/store/tickets/selectors/getOpenTicketsByUnitIds'
import { getUnitById } from 'app/fieldapp/store/units/selectors'
import { getVisitById } from 'app/fieldapp/store/visits/selectors'

import { useMaintenanceDispositions } from '../../../common/CreateTicket/useMaintenanceDispositions'
import { VisitTicketsTab } from './VisitTicketsTab'

const St = {
  Invalid: styled.div`
    padding: 12px;
  `,
}

export type VisitTicketsTabContainerProps = {
  visitId: string
}

export const VisitTicketsTabContainer: React.FC<VisitTicketsTabContainerProps> =
  React.memo(({ visitId }) => {
    const dispositions = useMaintenanceDispositions(true)
    const dispositionIds = Array.isArray(dispositions)
      ? dispositions.map(disposition => disposition.id)
      : []

    const visit = useSelector((state: ApplicationState) =>
      getVisitById(state, visitId || ''),
    )

    const unit = useSelector((state: ApplicationState) =>
      getUnitById(state, visit?.unitId || ''),
    )

    const unitIds = (unit?.unitFamilyIds || []).concat(unit?.id || [])

    const tickets = useSelector((state: ApplicationState) =>
      getOpenTicketsByUnitIds(state, unitIds),
    )
    // this should never happen, but we need to make TS happy and handle it in a safe way
    if (!visit || !unit) {
      return <St.Invalid>Invalid Tickets Data</St.Invalid>
    }

    return (
      <VisitTicketsTab
        dispositionIds={dispositionIds}
        tickets={tickets}
        unit={unit}
        visit={visit}
      />
    )
  })
