import { useSelector } from 'react-redux'

import { useI18n } from 'packages/i18n'

import { useOtherTimerActions } from 'app/fieldapp/components/timers/hooks'
import { useTimers as useTimersContext } from 'app/fieldapp/components/timers/state'
import { Slugs } from 'app/fieldapp/i18n'
import { getMergedOtherTimerById } from 'app/fieldapp/store/otherTimers/selectors'
import { ApplicationState } from 'app/fieldapp/store/store'
import { getOfflineTimerById, TimerType } from 'app/fieldapp/store/utils/timers'

import { transformTimers } from '../../helpers'
import { SubmitTimersFormProps } from '../../types'

export function useSubmitOtherTimers(): SubmitTimersFormProps | null {
  const { ut } = useI18n()
  const { state } = useTimersContext()
  const { submissionData } = state

  const {
    dispatchCancelStopOtherTimer,
    dispatchCancelStopOfflineOtherTimer,
    dispatchCompleteOtherTimer,
    dispatchCompleteStartedOfflineOtherTimer,
    dispatchDeleteOtherTime,
  } = useOtherTimerActions()

  const otherTimer = useSelector((storeState: ApplicationState) =>
    getMergedOtherTimerById(storeState, submissionData?.timer.id),
  )

  const offlineOtherTime = useSelector((storeState: ApplicationState) =>
    submissionData?.timer.id
      ? getOfflineTimerById(storeState, submissionData?.timer.id)
      : undefined,
  )

  /**
   * Update the timer to not have a stoppedAt value.
   * This will trigger an API update if all current data has been pushed,
   * however if offline data exists, we just update that data.
   */
  const dispatchAbortSubmission = () => {
    if (offlineOtherTime) {
      dispatchCancelStopOfflineOtherTimer(offlineOtherTime.id)
      return
    }

    if (otherTimer) {
      dispatchCancelStopOtherTimer(otherTimer.id)
    }
  }

  return otherTimer
    ? {
        dispatchAbortSubmission,
        dispatchCompleteStartedOffline:
          dispatchCompleteStartedOfflineOtherTimer,
        dispatchConfirmSubmission: timers =>
          dispatchCompleteOtherTimer(timers[0]),
        dispatchDeleteTimer: dispatchDeleteOtherTime,
        displayValue: ut(Slugs[otherTimer.timerType]),
        otherTimerType: otherTimer.timerType,
        timers: transformTimers([otherTimer]),
        timerType: TimerType.OTHER,
      }
    : null
}
