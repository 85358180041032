import React from 'react'
import { useSelector } from 'react-redux'

import { Loader } from 'packages/common'

import { getCrossCoverageDrawerState } from 'app/fieldapp/store/ui/selectors'
import { getCurrentCoveragePartner } from 'app/fieldapp/store/users/selectors'

import St from '../../utils/CrossCoverageDrawer.styles'
import { CrossCoverageForm } from '../CrossCoverageForm'
import { initialState } from '../state/CrossCoverageForm.reducer'
import { useExistingCoverageLoader } from '../state/useExistingCoverageLoader'

export const CrossCoverageFormContainer: React.FC = () => {
  const { crossCoverageId, systemAssignedUserId } = useSelector(
    getCrossCoverageDrawerState,
  )

  const { loading, state } = useExistingCoverageLoader(
    crossCoverageId,
    systemAssignedUserId,
  )

  const currentCoveragePartner = useSelector(getCurrentCoveragePartner)

  // We do not need to wait if we don't need to load existing coverage data into the form state
  if (!crossCoverageId) {
    return (
      <CrossCoverageForm initialState={initialState(currentCoveragePartner)} />
    )
  }

  return !state || loading ? (
    <St.LoaderContainer>
      <Loader />
    </St.LoaderContainer>
  ) : (
    <CrossCoverageForm initialState={state} />
  )
}
