import { createSelector } from 'reselect'

import { ApplicationState } from '../../store'
import { Ticket } from '../tickets.types'
import { hydrateRawTicket } from '../tickets.utils'

export const getTicketsByIds = createSelector(
  (state: ApplicationState) => state.tickets,
  (_, ticketIds: string[]) => ticketIds,
  (ticketsState, ticketIds): Ticket[] =>
    ticketIds.reduce((acc, id) => {
      const rawTicket = ticketsState.data[id]
      const backupUnitId = ticketsState.ticketToUnitMap[id]

      if (rawTicket) {
        acc.push(hydrateRawTicket(rawTicket, backupUnitId))
      }

      return acc
    }, [] as Ticket[]),
)
