import { createAsyncAction } from 'typesafe-actions'

import {
  RequestOptions,
  RequestConfig,
} from 'packages/utils/store/jsonapi.types'

import { contactsService } from '../contacts.service'
import {
  NormalizedContactsApiResponse,
  ContactsActionTypes,
} from '../contacts.types'

export const fetchContactsByUnitIdAction = createAsyncAction(
  ContactsActionTypes.FETCH_CONTACTS_BY_UNIT_ID,
  ContactsActionTypes.FETCH_CONTACTS_BY_UNIT_ID_SUCCESS,
  ContactsActionTypes.FETCH_CONTACTS_BY_UNIT_ID_FAILURE,
)<
  RequestConfig<NormalizedContactsApiResponse>,
  NormalizedContactsApiResponse,
  Error
>()

export const getParams = (unitIds: string[]): RequestOptions => {
  return {
    fields: {},
    filter: {
      owner_contracts__unit_id: {
        in: unitIds,
      },
    },
    include: ['owner_contracts'],
    page: {
      size: 500,
    },
  }
}

type includedOwnerContractInfo = {
  id: string
  unitId: string
}

export const fetchContactsByUnitId = (unitIds: string[]) => async dispatch => {
  try {
    const params = getParams(unitIds)
    const request = contactsService.fetchContacts.bind(null, params)
    const result = await dispatch(
      fetchContactsByUnitIdAction.request({ request }),
    )

    let includedOwnerContracts = []
    if (result.raw !== undefined && result.raw.included !== undefined) {
      includedOwnerContracts = result.raw.included.map(contract => {
        return {
          id: contract.id,
          unitId: contract.attributes.unit_id,
        }
      })
    }

    if (
      result.normalized !== undefined &&
      result.normalized.contact !== undefined
    ) {
      Object.keys(result.normalized.contact).forEach(key => {
        const contactContracts = includedOwnerContracts.filter(
          (contract: includedOwnerContractInfo) => {
            return result.normalized.contact[
              key
            ].attributes.ownerContracts.includes(contract.id)
          },
        )
        result.normalized.contact[key].attributes.unitIds =
          contactContracts.map((contract: includedOwnerContractInfo) => {
            return contract.unitId
          })
      })
    }

    dispatch(fetchContactsByUnitIdAction.success(result))

    return result.normalized
  } catch (error) {
    dispatch(fetchContactsByUnitIdAction.failure(error))
    throw error
  }
}
