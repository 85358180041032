import { isEmpty, merge } from 'lodash/fp'
import { createSelector } from 'reselect'

import { ApplicationState } from 'app/fieldapp/store/store'

import { TicketTime, TicketTimesState } from '../ticket-times.types'
import { hydrateRawTicketTime } from '../ticket-times.utils'

const getTicketTimesState = (state: ApplicationState): TicketTimesState =>
  state.ticketTimes

const getTicketTimeId = (
  _state: ApplicationState,
  ticketTimeId: string | undefined,
): string | undefined => ticketTimeId

/**
 * Builds a TicketTime that is the result of combining a non-offline TicketTime with
 * the properties from an offline TicketTime (with the latter taking precedence).
 *
 * Use this in place of the regular "getTicketTimeById" selector when you need to ensure
 * that you have any potential offline data merged in. (If no offline data is present,
 * it will simply return the original TicketTime.)
 */
export const getMergedTicketTimeById = createSelector(
  getTicketTimesState,
  getTicketTimeId,
  (ticketTimesState, id): TicketTime | undefined => {
    if (!id) return undefined

    const rawTicketTime = ticketTimesState.data[id] || {}
    const offlineTicketTime = ticketTimesState.offlineData[id] || {}

    const noData = isEmpty(rawTicketTime) && isEmpty(offlineTicketTime)
    const partialDataNotStartedOffline =
      isEmpty(rawTicketTime) && !offlineTicketTime?.attributes?.startedOffline

    if (noData || partialDataNotStartedOffline) return undefined
    return hydrateRawTicketTime(merge(rawTicketTime, offlineTicketTime))
  },
)
