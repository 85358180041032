import { produce } from 'immer'
import { createAsyncAction } from 'typesafe-actions'
import { v4 as uuid } from 'uuid'

import { OfflineRequestConfig } from 'packages/utils/store'

import { makeOfflineTimer } from 'app/fieldapp/components/timers/timers.helpers'
import {
  NormalizedTicketTimesApiResponse,
  TicketTimePatchData,
  TicketTimesActionTypes,
} from 'app/fieldapp/store/ticket-times'
import { offlineTimers } from 'app/fieldapp/store/utils'

type CreateTicketTimeOfflinePayload = OfflineRequestConfig<
  NormalizedTicketTimesApiResponse,
  Omit<TicketTimePatchData, 'id'>
>

type CreateTicketTimeOfflinePayloadWithId = OfflineRequestConfig<
  NormalizedTicketTimesApiResponse,
  TicketTimePatchData
>

export const createTicketTimeOfflineAction = createAsyncAction(
  TicketTimesActionTypes.OFFLINE_CREATE_TICKET_TIME,
  TicketTimesActionTypes.OFFLINE_CREATE_TICKET_TIME_SUCCESS,
  TicketTimesActionTypes.OFFLINE_CREATE_TICKET_TIME_FAILURE,
)<
  CreateTicketTimeOfflinePayloadWithId,
  CreateTicketTimeOfflinePayloadWithId,
  Error
>()

export const createTicketTimeOffline =
  (payload: CreateTicketTimeOfflinePayload) => async dispatch => {
    const id = uuid()

    const updatedPayload: CreateTicketTimeOfflinePayloadWithId = produce(
      payload,
      (draft: CreateTicketTimeOfflinePayloadWithId) => {
        draft.data.id = id
        draft.data.startedOffline = true
      },
    ) as CreateTicketTimeOfflinePayloadWithId

    await offlineTimers.saveTicketTime(makeOfflineTimer(updatedPayload.data))

    await dispatch(createTicketTimeOfflineAction.success(updatedPayload))
  }
