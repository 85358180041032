import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useSuperModal, useToast } from 'packages/common'
import { ReduxDispatch } from 'packages/grimoire/src/utils'
import {
  GenericTimer,
  TimerType,
} from 'packages/grimoire/src/utils/timers.types'
import { useI18n } from 'packages/i18n'
import { createDateObject } from 'packages/utils/dateHelpers'
import { useOnlineStatus } from 'packages/utils/hooks'

import {
  Timer,
  clearLoadingTimer,
  clearTimerSubmission,
  setLoadingTimer,
  useTimers as useTimersContext,
  setSubmissionData,
} from 'app/fieldapp/components/timers/state'
import { StartedOfflineTicketTime } from 'app/fieldapp/components/timers/timers.types'
import { Slugs } from 'app/fieldapp/i18n'
import { ApplicationState } from 'app/fieldapp/store/store'
import { TicketTime, TicketTimePostData } from 'app/fieldapp/store/ticket-times'
import {
  createFinalizedTicketTime,
  createTicketTime,
  deleteTicketTime,
  updateTicketTime,
  updateTicketTimeOffline,
} from 'app/fieldapp/store/ticket-times/actions'
import { Ticket } from 'app/fieldapp/store/tickets'
import { getActiveUser } from 'app/fieldapp/store/users/selectors'
import { fetchVisitById } from 'app/fieldapp/store/visits/actions'

import { useTimerErrors } from '../../useTimerErrors'
import {
  createConfirmTimerDeletionModal,
  NEW_TIMER_ID_PLACEHOLDER,
  convertTimestamps,
} from '../useTimerActions.helpers'

type TimerWithNotes = Timer & {
  isExternalComment?: boolean
  notes: string
}

type UseTicketTimerActions = {
  dispatchBeginTicketTimeStartedOfflineSubmission: (
    ticketTime: GenericTimer,
  ) => Promise<void>
  dispatchBeginTicketTimeSubmission: (timer: TicketTime) => Promise<void>
  dispatchCancelStopOfflineTicketTimer: (id: string) => Promise<void>
  dispatchCancelStopTicketTimer: (id: string) => Promise<void>
  dispatchCompleteStartedOfflineTicketTimer: (
    timer: StartedOfflineTicketTime,
    ticket: Ticket | undefined,
  ) => Promise<void>
  dispatchConfirmTicketTimeSubmission: (
    timer: TimerWithNotes,
    visitId?: string,
  ) => Promise<void>
  dispatchDeleteTicketTime: (
    id: string,
    triggerModal?: boolean,
  ) => Promise<void>
  dispatchDeleteTicketTimeWithModalWarning: (id: string) => Promise<void>
  dispatchStartTicketTimer: (data: TicketTimePostData) => Promise<void>
  dispatchStopTicketTimer: (id: string) => Promise<void>
}

export function useTicketTimerActions(): UseTicketTimerActions {
  const { t } = useI18n()
  const reduxDispatch = useDispatch<ReduxDispatch<ApplicationState>>()
  const user = useSelector(getActiveUser)

  const isOnline = useOnlineStatus().isOnline()
  const { dispatch: timersDispatch } = useTimersContext()
  const { clearExistingErrors, handleError: showErrorBanner } = useTimerErrors()
  const { showModal } = useSuperModal()
  const { showToast } = useToast()

  /**************************************************
   * Start Functions
   **************************************************/
  const dispatchStartTicketTimer = async (data: TicketTimePostData) => {
    timersDispatch(setLoadingTimer(NEW_TIMER_ID_PLACEHOLDER))
    await reduxDispatch(createTicketTime(data))
    timersDispatch(clearLoadingTimer())
  }

  /**************************************************
   * Stop Functions
   **************************************************/
  const dispatchStopTicketTimer = async (id: string) => {
    timersDispatch(setLoadingTimer(id))

    try {
      await reduxDispatch(
        updateTicketTime({
          id,
          stoppedAt: createDateObject(),
        }),
      )
    } catch (err) {
      throw Error(err)
    } finally {
      timersDispatch(clearLoadingTimer())
    }
  }

  const dispatchCancelStopTicketTimer = React.useCallback(
    async (id: string) => {
      timersDispatch(setLoadingTimer(id))
      clearExistingErrors()

      try {
        await reduxDispatch(
          updateTicketTime({
            id,
            stoppedAt: null,
          }),
        )

        timersDispatch(clearTimerSubmission())
      } catch (err) {
        showErrorBanner(err)
      } finally {
        timersDispatch(clearLoadingTimer())
      }
    },
    [clearExistingErrors, reduxDispatch, showErrorBanner, timersDispatch],
  )

  const dispatchCancelStopOfflineTicketTimer = React.useCallback(
    async (id: string) => {
      timersDispatch(setLoadingTimer(id))
      clearExistingErrors()

      try {
        // Even though we are not necessarily offline, we only care about updating offline data,
        // since the timer has unsynced data
        await reduxDispatch(
          updateTicketTimeOffline({
            data: {
              id,
              stoppedAt: null,
            },
          }),
        )
      } catch (err) {
        throw Error(err)
      } finally {
        timersDispatch(clearLoadingTimer())
        timersDispatch(clearTimerSubmission())
      }
    },
    [clearExistingErrors, reduxDispatch, timersDispatch],
  )

  /**************************************************
   * Submission Functions
   **************************************************/
  const dispatchConfirmTicketTimeSubmission = React.useCallback(
    async (timer: TimerWithNotes, visitId?: string) => {
      clearExistingErrors()
      timersDispatch(setLoadingTimer(NEW_TIMER_ID_PLACEHOLDER))

      try {
        await reduxDispatch(
          updateTicketTime({
            id: timer.id,
            isExternalComment: timer.isExternalComment,
            isFinalized: true,
            notes: timer.notes,
            ...convertTimestamps(timer.startedAt, timer.stoppedAt),
          }),
        )

        if (visitId) {
          await reduxDispatch(fetchVisitById(visitId))
        }
      } catch (error) {
        showErrorBanner(error)
      } finally {
        timersDispatch(clearTimerSubmission())
        timersDispatch(clearLoadingTimer())
      }
    },
    [clearExistingErrors, reduxDispatch, showErrorBanner, timersDispatch],
  )

  const dispatchBeginTicketTimeSubmission = async (timer: TicketTime) => {
    timersDispatch(clearTimerSubmission())

    try {
      if (!timer.stoppedAt) {
        await dispatchStopTicketTimer(timer.id)
      }

      timersDispatch(
        setSubmissionData({
          timer,
          type: TimerType.TICKET,
        }),
      )
    } catch (err) {
      showErrorBanner(err)
    }
  }

  /**************************************************
   * Delete Functions
   **************************************************/
  const dispatchDeleteTicketTime = React.useCallback(
    async (ticketTimeId: string) => {
      clearExistingErrors()
      await reduxDispatch(deleteTicketTime(ticketTimeId))
      timersDispatch(clearLoadingTimer())
      timersDispatch(clearTimerSubmission())
    },
    [clearExistingErrors, reduxDispatch, timersDispatch],
  )

  const dispatchDeleteTicketTimeWithModalWarning = React.useCallback(
    async (id: string) => {
      showModal(
        createConfirmTimerDeletionModal(t)(async () => {
          timersDispatch(setLoadingTimer(id))
          dispatchDeleteTicketTime(id)
        }),
      )
    },
    [dispatchDeleteTicketTime, showModal, t, timersDispatch],
  )

  /** Will conditionally post a started offline ticket timer or update it locally, depending on online status*/
  const dispatchCompleteStartedOfflineTicketTimer = React.useCallback(
    async (timer: StartedOfflineTicketTime, ticket: Ticket | undefined) => {
      clearExistingErrors()
      try {
        const { startedAt, stoppedAt, notes, id, isExternalComment } = timer

        /*
         * There is no reason these should not be defined, but in the case they are,
         * don't try anything, because we'll end post/patching bad data
         */
        if (user && stoppedAt && startedAt && ticket) {
          /** Attributes for both patch and post requests */
          const sharedPayload = {
            isExternalComment,
            isFinalized: true,
            notes,
            ...convertTimestamps(startedAt, stoppedAt),
          }

          if (isOnline) {
            // Here, we post the whole timer
            await reduxDispatch(
              createFinalizedTicketTime(id, {
                ...sharedPayload,
                ticketId: ticket?.id,
                userId: user.id,
              }),
            )
          } else {
            // If we're offline, we save this so it becomes a "paused timer"
            await reduxDispatch(
              updateTicketTimeOffline({
                data: {
                  id,
                  ...sharedPayload,
                },
              }),
            )
          }
        }

        timersDispatch(clearTimerSubmission())
        showToast({
          message: t(isOnline ? Slugs.hoursSubmitted : Slugs.timerSavedOffline),
        })
      } catch (error) {
        showErrorBanner(error)
      }
    },
    [
      clearExistingErrors,
      isOnline,
      reduxDispatch,
      showErrorBanner,
      showToast,
      t,
      timersDispatch,
      user,
    ],
  )

  const dispatchBeginTicketTimeStartedOfflineSubmission = async (
    ticketTime: GenericTimer,
  ) => {
    timersDispatch(clearTimerSubmission())

    try {
      if (!ticketTime.stoppedAt) {
        timersDispatch(setLoadingTimer(ticketTime.id))

        try {
          await reduxDispatch(
            updateTicketTimeOffline({
              data: {
                id: ticketTime.id,
                stoppedAt: createDateObject(),
              },
            }),
          )
        } catch (err) {
          throw Error(err)
        } finally {
          timersDispatch(clearLoadingTimer())
        }
      }

      timersDispatch(
        setSubmissionData({
          timer: ticketTime,
          type: TimerType.TICKET,
        }),
      )
    } catch (err) {
      showErrorBanner(err)
    }
  }

  return {
    dispatchBeginTicketTimeStartedOfflineSubmission,
    dispatchBeginTicketTimeSubmission,
    dispatchCancelStopOfflineTicketTimer,
    dispatchCancelStopTicketTimer,
    dispatchCompleteStartedOfflineTicketTimer,
    dispatchConfirmTicketTimeSubmission,
    dispatchDeleteTicketTime,
    dispatchDeleteTicketTimeWithModalWarning,
    dispatchStartTicketTimer,
    dispatchStopTicketTimer,
  }
}
