import * as React from 'react'

import { TIMER_DETAIL_HEIGHT } from 'app/fieldapp/components/timers/timers.constants'

export type UsePausedTimersSizing = {
  expanded: boolean
  toggleExpanded: () => void
  wrapperStyles: Pick<React.CSSProperties, 'maxHeight'>
}

export function usePausedTimersSizing(
  timerCount: number,
): UsePausedTimersSizing {
  const [expanded, setExpanded] = React.useState(false)

  // styles for the outer wrapper component
  // these need to be re-calculated based on number of timers and expanded state
  const [wrapperStyles, setWrapperStyles] = React.useState({
    maxHeight: 0,
  })

  React.useEffect(() => {
    const timerHeight = expanded ? TIMER_DETAIL_HEIGHT * timerCount : 0
    setWrapperStyles({
      maxHeight: timerHeight,
    })
  }, [expanded, timerCount])

  const toggleExpanded = React.useCallback((): void => {
    setExpanded(prevExpanded => !prevExpanded)
  }, [setExpanded])

  return { expanded, toggleExpanded, wrapperStyles }
}
