import { createAsyncThunk } from '@reduxjs/toolkit'
import { noop } from 'lodash/fp'

import { ReduxActionCallbacks } from 'packages/grimoire/src/utils'
import { makeRelationship, RequestOptions } from 'packages/utils/store'
import { getSystemTZ } from 'packages/utils/timezone.helpers'

import { setIsCoverageStale } from '../../coverage/actions'
import { standardAvailabilityService } from '../standardAvailability.service'
import { StandardAvailabilityPostData } from '../standardAvailability.types'

export const getParams = (
  postData: StandardAvailabilityPostData,
  userId: string,
): RequestOptions => ({
  data: {
    attributes: {
      friday: postData.friday,
      monday: postData.monday,
      saturday: postData.saturday,
      start_date: postData.startDate,
      sunday: postData.sunday,
      thursday: postData.thursday,
      tuesday: postData.tuesday,
      tz: getSystemTZ(),
      wednesday: postData.wednesday,
    },
    relationships: {
      user: makeRelationship('user', userId),
    },
    type: 'standard_availability',
  },
})

type CreateStandardAvailabilityThunkArgs = {
  callbacks: ReduxActionCallbacks
  postData: StandardAvailabilityPostData
  userId: string
}

/**
In an effort to avoid race conditions we are NOT fetching standard_availability inside this action. This makes this action
an exception to our usual pattern, so just be aware that you will need to fetch standard_availability manually.
 */
export const createStandardAvailability = createAsyncThunk(
  'standardAvailability/api/createStandardAvailability',
  async (args: CreateStandardAvailabilityThunkArgs, { dispatch }) => {
    const { postData, userId, callbacks } = args
    const { onError = noop, onSuccess = noop } = callbacks

    try {
      const params = getParams(postData, userId)
      const response =
        await standardAvailabilityService.createStandardAvailability(params)
      dispatch(setIsCoverageStale(true))
      onSuccess(response)
      return response
    } catch (error) {
      onError()
    }
  },
)
