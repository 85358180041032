import { BannerConfig } from 'packages/common'
import { TranslatorFn } from 'packages/i18n'
import { JsonApiErrorCodes, JsonApiErrorResponse } from 'packages/utils/store'
import { Events, track } from 'packages/wiretap'

import { Slugs } from 'app/fieldapp/i18n'

export const linksToHT = ['0C0503', '0C0502', '0D0503', '0D0502']

const shouldLinkToHoursTracker = code => linksToHT.includes(code)

type HoursTrackerLinkConfig = {
  text: string
  url: string
}

export const createHoursTrackerLinkConfig = (
  t: TranslatorFn,
): HoursTrackerLinkConfig => ({
  text: t(Slugs.checkHoursTracker),
  url: 'https://admin.vacasa.com/admin/tools/hours-tracker',
})

export const createDefaultBannerConfig = (t: TranslatorFn): BannerConfig => ({
  message: t(Slugs.error0D0501), // Generic "Unable to create hours tracker" error
})

/**
 * Handler for creating custom banner configs for each error
 */
export const createTimersErrorBannerConfig =
  (t: TranslatorFn) =>
  (code: JsonApiErrorCodes): BannerConfig => {
    const slug = Slugs[`error${code}`]

    if (slug) {
      const message = `${t(slug)}${shouldLinkToHoursTracker(code) ? '. ' : ''}`
      const config: BannerConfig = { message }
      if (shouldLinkToHoursTracker(code)) {
        config.link = createHoursTrackerLinkConfig(t)
      }

      return config
    }

    return createDefaultBannerConfig(t)
  }

export const parseAndHandleError = (
  errorRes: JsonApiErrorResponse,
): JsonApiErrorCodes => {
  const error = errorRes.response?.data?.errors?.[0]
  const code = error?.code

  if (code === JsonApiErrorCodes.UKG_CATCH_ALL) {
    track(Events.fieldAppTimerUKGCatchAll, {
      detail: error.detail || 'unknown',
      title: error.title || 'unknown',
    })
  }

  return code
}
