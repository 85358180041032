import { createAsyncThunk } from '@reduxjs/toolkit'

import { ApplicationState } from '../../store'
import { User } from '../../users'
import { authService } from '../auth.service'

export const fetchImpersonationToken = createAsyncThunk(
  'auth/api/fetchImpersonationToken',
  async (user: User, { getState }) => {
    const authToken = (getState() as ApplicationState).auth.idToken
    const response = await authService.fetchImpersonationToken(
      user.id,
      authToken,
    )
    return response
  },
)
