import { useOfflineMergedTimers } from 'app/fieldapp/components/timers/hooks'
import { useTimersRequestPending } from 'app/fieldapp/components/timers/hooks'
import { useTimers as useTimersContext } from 'app/fieldapp/components/timers/state/timers.context'
import { TimersMap } from 'app/fieldapp/store/utils/timers'

import { usePausedTimersSizing, UsePausedTimersSizing } from './hooks'

export type UsePausedTimers = UsePausedTimersSizing & {
  isLoading: boolean
  offlineTimers: TimersMap
  requestPending: boolean
  totalTimersCount: number
}

export function usePausedTimers(): UsePausedTimers {
  const { state } = useTimersContext()
  const requestPending = useTimersRequestPending()

  const { offlineTimersCount, offlineTimers } = useOfflineMergedTimers()

  const { expanded, toggleExpanded, wrapperStyles } =
    usePausedTimersSizing(offlineTimersCount)

  return {
    expanded,
    isLoading: !!state.loadingTimerId,
    offlineTimers,
    requestPending,
    toggleExpanded,
    totalTimersCount: offlineTimersCount,
    wrapperStyles,
  }
}
