import * as React from 'react'
import { useSelector } from 'react-redux'
import { useInterval } from 'react-use'

import { CleanTime } from 'packages/grimoire'
import {
  differenceInSeconds,
  createDateObject,
} from 'packages/utils/dateHelpers'

import { useCleanTimerActions } from 'app/fieldapp/components/timers/hooks'
import { useTimers as useTimersContext } from 'app/fieldapp/components/timers/state'
import { Clean } from 'app/fieldapp/store/cleans'
import { getActiveCleanTime } from 'app/fieldapp/store/cleantimes/selectors'
import { ApplicationState } from 'app/fieldapp/store/store'
import { getTasksByAssignmentIds } from 'app/fieldapp/store/tasks/selectors'
import { Visit } from 'app/fieldapp/store/visits'

export type UseActiveCleanTimer = {
  activeCleanTime?: CleanTime
  activeTask?: Clean | Visit
  isLoading: boolean
  stopActiveTimer: () => void
  time: number
}

export function useActiveCleanTimer(): UseActiveCleanTimer {
  const {
    dispatchBeginCleanTimeSubmission,
    dispatchBeginCleanTimeStartedOfflineSubmission,
  } = useCleanTimerActions()
  const activeCleanTime = useSelector(getActiveCleanTime)

  const activeTaskMap = useSelector((storeState: ApplicationState) =>
    getTasksByAssignmentIds(
      storeState,
      activeCleanTime ? [activeCleanTime.assignment.id] : [],
    ),
  )

  const { state } = useTimersContext()
  const { loadingTimerId } = state
  const isLoading = !!loadingTimerId

  const getRunningTimerValue = React.useCallback(
    () =>
      activeCleanTime
        ? differenceInSeconds(createDateObject(), activeCleanTime.startedAt)
        : 0,
    [activeCleanTime],
  )

  const activeTask = activeCleanTime
    ? activeTaskMap[activeCleanTime.assignment.id]
    : undefined

  const stopActiveTimer = React.useCallback(() => {
    if (activeCleanTime) {
      activeCleanTime.startedOffline
        ? dispatchBeginCleanTimeStartedOfflineSubmission(activeCleanTime)
        : dispatchBeginCleanTimeSubmission(activeCleanTime)
    }
  }, [
    activeCleanTime,
    dispatchBeginCleanTimeSubmission,
    dispatchBeginCleanTimeStartedOfflineSubmission,
  ])

  const [time, setTime] = React.useState(getRunningTimerValue())

  /** Set up an interval to update the "running" timer display once per second. */
  useInterval(
    () => {
      setTime(getRunningTimerValue())
    },
    // If the timer is loading (submitting), disable the interval
    loadingTimerId ? null : 1000,
  )

  return {
    activeCleanTime,
    activeTask,
    isLoading,
    stopActiveTimer,
    time,
  }
}
