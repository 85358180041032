import { produce } from 'immer'

import {
  findRelationshipId,
  transformNormalizedToTyped,
} from 'packages/utils/store'

import {
  Disposition,
  DispositionAttributeNames,
  DispositionResponse,
  NormalizedDispositionApiResponse,
  NormalizedTicketsApiResponse,
  RawDisposition,
  RawTicket,
  Ticket,
  TicketAttributeNames,
  TicketSeverity,
  TicketsResponse,
} from './tickets.types'

export const emptyNormalizedTicketsData: TicketsResponse = Object.freeze({
  comment: {},
  lockBox: {},
  maintenanceTicket: {},
  reservation: {},
  ticket: {},
  ticketComment: {},
  unit: {},
  user: {},
})

export const emptyTicketsResponse: NormalizedTicketsApiResponse = Object.freeze(
  {
    normalized: emptyNormalizedTicketsData,
    raw: { data: [] },
  },
)

export const emptyNormalizedDispositionData: DispositionResponse =
  Object.freeze({ disposition: {} })

export const emptyDispositionResponse: NormalizedDispositionApiResponse =
  Object.freeze({
    normalized: emptyNormalizedDispositionData,
    raw: { data: [] },
  })

export const stripTimeZoneFromDateString = (date?: string): string => {
  if (!date) return ''
  return date.slice(0, 10)
}

export const hydrateRawDisposition = (
  disposition: RawDisposition,
): Disposition =>
  transformNormalizedToTyped<Disposition>(
    disposition,
    DispositionAttributeNames,
  )

export const hydrateRawTicket = (
  rawTicket: RawTicket,
  backupUnitId: string,
): Ticket => {
  const baseTicket = transformNormalizedToTyped<Ticket>(
    rawTicket,
    TicketAttributeNames,
  )

  return produce(baseTicket, draft => {
    draft.assigneeId = rawTicket.relationships?.assignee?.data?.id || ''
    draft.commentIds = (rawTicket.relationships?.comments?.data || []).map(
      ({ id }) => id,
    )

    draft.dispositionId = findRelationshipId(rawTicket, 'disposition') || ''

    draft.lockboxId = findRelationshipId(rawTicket, 'lockBox') || ''

    draft.openVisitId = findRelationshipId(rawTicket, 'openVisit') || ''

    // if no unit relationship is present, try to use the fallback
    // this works around a quirk of JSON:API, where tickets included with visits would not =
    // have relationship knowledge of the unit, even though the data is technically there (i.e. sibling includes)
    draft.unitId = findRelationshipId(rawTicket, 'unit') || backupUnitId || ''

    // strip the timezone from the due date because we don't care about it
    draft.dueDateNoTimestamp = stripTimeZoneFromDateString(
      rawTicket.attributes.dueDate,
    )
  })
}

const severityRanking = {
  [TicketSeverity.URGENT]: 1,
  [TicketSeverity.TODAY]: 2,
  [TicketSeverity.PRIOR_TO_NEXT_GUEST]: 3,
  [TicketSeverity.MEDIUM]: 4,
  [TicketSeverity.LOW]: 5,
  [TicketSeverity.PLANNED]: 6,
}

export function compareDueDate(a: Ticket, b: Ticket): number {
  if (!a.dueDate) {
    return 1
  } else if (!b.dueDate) {
    return -1
  }

  return a.dueDate > b.dueDate ? 1 : -1
}

export function compareSeverity(a: Ticket, b: Ticket): number {
  const aSevRank = severityRanking[a.severity]
  const bSevRank = severityRanking[b.severity]

  if (aSevRank < bSevRank) {
    return -1
  }

  if (aSevRank > bSevRank) {
    return 1
  }

  return 0
}

export function compareBySeverityThenDueDate(a: Ticket, b: Ticket): number {
  const comparedSeverity = compareSeverity(a, b)

  return comparedSeverity === 0 ? compareDueDate(a, b) : comparedSeverity
}
