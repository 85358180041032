import { AxiosResponse } from 'axios'
import snakeCase from 'lodash/snakeCase'

import {
  JSONApiObjectWithRelationships,
  JSONApiObjectWithRelationshipsMap,
  NormalizedJSONApiResponse,
  ToOneRelationship,
} from 'packages/utils/store/jsonapi.types'

/**********************************************************
 * REDUX TYPES
 *********************************************************/
export type LockboxesState = {
  data: JSONApiLockboxesMap
  error?: Error
}

/**********************************************************
 * SERVICE TYPES
 *********************************************************/
export type LockboxesResponse = {
  lockBox: JSONApiLockboxesMap
}

export type NormalizedLockboxesApiResponse =
  NormalizedJSONApiResponse<LockboxesResponse>
export type LockboxesServiceResponse = Promise<NormalizedLockboxesApiResponse>
export type LockboxesApiResponse = AxiosResponse<NormalizedLockboxesApiResponse>

/**********************************************************
 * LOCKBOX TYPES
 *********************************************************/
export type LockboxAttributes = {
  backupLock: string
  notes: string
  primaryLock: string
}

export const LockboxAttributeNames = ['backupLock', 'notes', 'primaryLock']

export const LockboxApiFields = LockboxAttributeNames.map<string>(snakeCase)

export type LockboxRelationships = {
  unit: ToOneRelationship
}

export type RawLockbox = JSONApiObjectWithRelationships<
  LockboxAttributes,
  LockboxRelationships
>

export type JSONApiLockboxesMap = JSONApiObjectWithRelationshipsMap<
  LockboxAttributes,
  LockboxRelationships
>

export type Lockbox = {
  id: string
} & LockboxAttributes
