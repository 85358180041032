import * as React from 'react'

import { Button } from 'packages/common'
import { TimerType } from 'packages/grimoire/src/utils/timers.types'
import { IconName, SvgIcon } from 'packages/iconic'
import { differenceInSeconds } from 'packages/utils/dateHelpers'

import { useTicket } from 'app/fieldapp/components/tickets/hooks'
import { TimerDetail } from 'app/fieldapp/components/timers/components'
import { TimerDetailWrapper } from 'app/fieldapp/components/timers/components/Timers.styles'
import { useTicketTimerActions } from 'app/fieldapp/components/timers/hooks'
import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { TicketTime } from 'app/fieldapp/store/ticket-times'

import styles from '../PausedTimerDisplay.module.scss'

export enum PausedTicketTimerDisplayTestIds {
  completeBtn = 'PausedTicketTimer__completeBtn',
  container = 'PausedTicketTimer__container',
  deleteBtn = 'PausedTicketTimer__deleteBtn',
}

export type PausedTicketTimerDisplayProps = {
  loading: boolean
  requestPending: boolean
  ticketTime: TicketTime
}

export const PausedTicketTimerDisplay: React.FC<PausedTicketTimerDisplayProps> =
  React.memo(({ loading, requestPending, ticketTime }) => {
    const { t } = useI18n()
    const {
      dispatchBeginTicketTimeSubmission,
      dispatchDeleteTicketTimeWithModalWarning,
    } = useTicketTimerActions()

    const { unit } = useTicket(ticketTime.ticketId)
    const time = differenceInSeconds(
      ticketTime.stoppedAt ?? ticketTime.startedAt,
      ticketTime.startedAt,
    )

    const handleCompleteClick = React.useCallback(() => {
      dispatchBeginTicketTimeSubmission(ticketTime)
    }, [dispatchBeginTicketTimeSubmission, ticketTime])

    const handleDeleteClick = React.useCallback(() => {
      dispatchDeleteTicketTimeWithModalWarning(ticketTime.id)
    }, [dispatchDeleteTicketTimeWithModalWarning, ticketTime])

    return (
      <TimerDetailWrapper
        data-testid={PausedTicketTimerDisplayTestIds.container}
        timerColorType={TimerType.TICKET}
      >
        <div
          className={styles.closeButton}
          data-testid={PausedTicketTimerDisplayTestIds.deleteBtn}
          onClick={handleDeleteClick}
        >
          <SvgIcon className={styles.closeX} icon={IconName.x} />
        </div>

        <div className={styles.timerDetailWrapper}>
          <TimerDetail time={time} unit={unit} />
        </div>

        <div className={styles.timerControls}>
          <Button
            ariaLabel="Submit this timer"
            dataTestId={PausedTicketTimerDisplayTestIds.completeBtn}
            disabled={requestPending && !loading}
            isLoading={loading}
            onClick={handleCompleteClick}
            buttonType={'primary'}
          >
            {t(Slugs.submit)}
          </Button>
        </div>
      </TimerDetailWrapper>
    )
  })
