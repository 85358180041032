import { createAsyncThunk } from '@reduxjs/toolkit'
import { noop } from 'lodash'

import { ReduxActionCallbacks } from 'packages/grimoire/src/utils'
import { DateFormat, formatLocalized } from 'packages/utils/dateHelpers'
import { RequestOptions } from 'packages/utils/store'
import { parseIntToTimestamp } from 'packages/utils/timestamp'

import { crossCoverageService } from '../crossCoverage.service'
import {
  CrossCoveragePostAPIAttributes,
  CrossCoveragePatchData,
} from '../crossCoverage.types'

export const buildPayloadFrom = (
  patchData: CrossCoveragePatchData,
): RequestOptions => {
  const {
    endDate,
    endTimeLocal,
    friday,
    isRepeating,
    monday,
    saturday,
    startDate,
    startTimeLocal,
    sunday,
    thursday,
    tuesday,
    tz,
    userCoveredId,
    userCoveringId,
    wednesday,
  } = patchData

  const attributes: Partial<CrossCoveragePostAPIAttributes> = {
    end_date: formatLocalized(endDate, DateFormat.ApiUtcShort),
    end_time_local: parseIntToTimestamp(endTimeLocal),
    friday,
    is_repeating: isRepeating,
    monday,
    saturday,
    start_date: formatLocalized(startDate, DateFormat.ApiUtcShort),
    start_time_local: parseIntToTimestamp(startTimeLocal),
    sunday,
    thursday,
    tuesday,
    tz: tz,
    wednesday,
  }

  const userFrom = (id: string) => ({ data: { id, type: 'user' } })

  const relationships = {
    user_covered: userFrom(userCoveredId),
    user_covering: userFrom(userCoveringId),
  }

  return {
    data: {
      attributes,
      relationships,
      type: 'cross_coverage',
    },
  }
}

type UpdateCrossCoverageThunkArgs = {
  callbacks?: ReduxActionCallbacks
  id: string
  patchData: CrossCoveragePatchData
}

export const updateCrossCoverage = createAsyncThunk(
  'crossCoverage/api/updateCrossCoverage',
  async ({ id, patchData, callbacks = {} }: UpdateCrossCoverageThunkArgs) => {
    const { onError = noop, onSuccess = noop } = callbacks

    try {
      const payload = buildPayloadFrom(patchData)
      const response = await crossCoverageService.updateCrossCoverage(
        id,
        payload,
      )

      onSuccess()

      return response
    } catch (error) {
      onError(error)

      throw error
    }
  },
)
