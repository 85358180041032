import styled from '@emotion/styled'
import { every, flatMap, pipe, prop } from 'lodash/fp'
import React from 'react'

import { Alert, Button, Loader } from 'packages/common'
import { centerWithFlex, colors } from 'packages/styles'

import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { useAppDispatch } from 'app/fieldapp/store/hooks'
import { InspectionListItem } from 'app/fieldapp/store/tasks'
import { completeVisitInspection } from 'app/fieldapp/store/visits/actions'

import {
  convertIsCompleteToBoolean,
  isViewingSharedClean,
} from '../../schedule.utils'
import { scrollToDrawerTop } from '../CleanDetail/CleanDetailDrawer/CleanDetailDrawer.utils'
import { CreateTicketDrawer } from '../common/CreateTicket/CreateTicketDrawer'
import { CompletedVisitInspection } from './components/CompletedVisitInspection'
import { InspectionNoteFormContainer } from './components/InspectionNoteForm/InspectionNoteForm.container'
import { VisitInspectionCategory } from './components/VisitInspectionCategory'
import { VisitInspectionDisabledWarning } from './components/VisitInspectionDisabledWarning'
import { VisitInspectionListDetail } from './components/VisitInspectionListDetail'
import { useInspectionContext } from './context/VisitInspectionChecklist.context'
import { useInspectionVisitWithRelationships } from './useInspectionVisitWithRelationships'

const convertItemsIsCompleteToBooleans = (items: InspectionListItem[]) => {
  return items.map(item => {
    return {
      ...item,
      isComplete: convertIsCompleteToBoolean(item.isComplete),
    }
  })
}

const allItemsComplete = pipe(
  flatMap(prop('items')),
  convertItemsIsCompleteToBooleans,
  every('isComplete'),
)

const St = {
  Alert: styled(Alert)`
    border: none;
    margin-bottom: 0;
  `,
  ChecklistItem: styled.div`
    padding: 20px;

    &:not(:last-child) {
      border-bottom: 1px ${colors.midnight20} solid;
    }
  `,

  CompleteButtonError: styled.div`
    ${centerWithFlex};
    color: ${colors.alert};
  `,

  InspectionChecklist: styled.div<{ selectedCategoryIdx: number | null }>`
    background-color: white;
    overflow-y: scroll;
    // This keeps the last checklist item from being covered by timers
    padding-bottom: 64px;
    padding-top: 16px;
    position: relative;

    // hide completely when a category is selected
    display: ${({ selectedCategoryIdx }) =>
      selectedCategoryIdx !== null ? 'none' : 'inherit'};
  `,

  InspectionControls: styled.div`
    padding: 10px 12px 0 12px;
  `,

  LoaderWrapper: styled.div`
    height: 32px;
    position: relative;
    margin-top: 32px;
  `,

  VisitInspectionCreateTicketDrawer: styled(CreateTicketDrawer)`
    padding: 16px 24px 16px 24px;
  `,

  VisitNotStarted: styled.div`
    margin: auto;
    padding-top: 40%;
    text-align: center;
    width: 80%;
  `,
}

const useTranslations = () => {
  const { t } = useI18n()

  return {
    completeInspection: t(Slugs.inspectionCompleteInspection),
    completeInspectionError: t(Slugs.inspectionErrorCompleteInspection),
    createTicket: t(Slugs.createTicket),
    inspectionNeedMoreHelp1: t(Slugs.inspectionNeedMoreHelp1),
    inspectionNeedMoreHelp2: t(Slugs.inspectionNeedMoreHelp2),
    inspectionVisitNotStarted: t(Slugs.inspectionVisitNotStarted),
  }
}

export const InspectionChecklistTestIds = {
  completeBtn: 'InspectionChecklist__completeBtn',
  container: 'InspectionChecklist__container',
}

export type VisitInspectionChecklistProps = {
  isLoadingVisit: boolean
}

export const VisitInspectionChecklist: React.FC<VisitInspectionChecklistProps> =
  React.memo(({ isLoadingVisit }) => {
    const dispatch = useAppDispatch()

    const { uiDisabledState, uiState, uiStateDispatch } = useInspectionContext()

    const { visit, unit } = useInspectionVisitWithRelationships()

    const strings = useTranslations()

    const inspectionChecklist = visit?.inspectionChecklist
    const checklists = inspectionChecklist?.checklists || []
    const isInspectionCompletable =
      !!checklists.length && allItemsComplete(checklists)
    const hideAdminLinks = isViewingSharedClean()

    const [selectedCategoryIdx, setSelectedCategoryIdx] = React.useState<
      number | null
    >(null)

    const handleCategoryClick = React.useCallback((idx: number) => {
      setSelectedCategoryIdx(idx)
      scrollToDrawerTop()
    }, [])

    const handleBackClick = React.useCallback(() => {
      setSelectedCategoryIdx(null)
    }, [])

    const handleCompleteVisitInspection = React.useCallback(async () => {
      uiStateDispatch({
        payload: { error: false, loading: true },
        type: 'setCompleteButtonState',
      })

      try {
        await dispatch(completeVisitInspection({ visitId: visit.id }))
      } catch (error) {
        uiStateDispatch({
          payload: { error: true },
          type: 'setCompleteButtonState',
        })
      } finally {
        uiStateDispatch({
          payload: { loading: false },
          type: 'setCompleteButtonState',
        })
      }
    }, [dispatch, uiStateDispatch, visit.id])

    if (!visit.startedAt && isLoadingVisit) {
      return (
        <St.LoaderWrapper>
          <Loader />
        </St.LoaderWrapper>
      )
    }

    return visit?.inspectionCompletedAt ? (
      <CompletedVisitInspection />
    ) : (
      <>
        <VisitInspectionDisabledWarning disabledState={uiDisabledState} />
        <St.InspectionChecklist
          data-testid={InspectionChecklistTestIds.container}
          selectedCategoryIdx={selectedCategoryIdx}
        >
          {checklists.map(
            (checklist, idx) =>
              !!checklist.items.length && (
                <VisitInspectionCategory
                  checklist={checklist}
                  key={checklist.category.id}
                  onClick={() => handleCategoryClick(idx)}
                />
              ),
          )}

          <InspectionNoteFormContainer
            inspectionNotes={visit.inspectionNotes}
            visitId={visit.id}
          />

          <St.InspectionControls>
            {uiState.completeBtn.error && (
              <St.CompleteButtonError>
                {strings.completeInspectionError}
              </St.CompleteButtonError>
            )}

            <Button
              block={true}
              dataTestId={InspectionChecklistTestIds.completeBtn}
              disabled={!isInspectionCompletable || !!uiDisabledState}
              isLoading={uiState.completeBtn.loading}
              onClick={handleCompleteVisitInspection}
              buttonType={'primary'}
            >
              {strings.completeInspection}
            </Button>
          </St.InspectionControls>

          {!hideAdminLinks && (
            <St.VisitInspectionCreateTicketDrawer
              taskId={visit.id}
              unit={unit}
            />
          )}
        </St.InspectionChecklist>

        {selectedCategoryIdx !== null ? (
          <VisitInspectionListDetail
            checklist={checklists[selectedCategoryIdx]}
            hideAdminLinks={hideAdminLinks}
            onBackClick={handleBackClick}
          />
        ) : null}
      </>
    )
  })
