import { produce } from 'immer'
import { merge } from 'lodash/fp'
import { ActionType, getType } from 'typesafe-actions'

import { fetchCleanByIdAction, fetchCleansAction } from '../cleans/actions'
import { fetchUnitByIdAction } from '../units/actions'
import { fetchCurrentUserAction } from '../users/actions'
import { fetchVisitsAction, fetchVisitByIdAction } from '../visits/actions'
import { fetchHousekeeperManagersByZoneAction } from './actions'
import { HousekeepersState } from './housekeepers.types'
import { emptyNormalizedHousekeepersData } from './housekeepers.utils'

export const initialState: HousekeepersState = {
  data: {},
}

const actions = {
  fetchCleanByIdAction,
  fetchCleansAction,
  fetchCurrentUserAction,
  fetchHousekeeperManagersByZoneAction,
  fetchUnitByIdAction,
  fetchVisitByIdAction,
  fetchVisitsAction,
}

type Actions = ActionType<typeof actions>

export const housekeepersReducer = (
  state = initialState,
  action: Actions,
): HousekeepersState =>
  produce(state, (draft: HousekeepersState) => {
    switch (action.type) {
      case getType(fetchCleanByIdAction.success):
      case getType(fetchCleansAction.success):
      case getType(fetchHousekeeperManagersByZoneAction.success):
      case getType(fetchVisitByIdAction.success):
      case getType(fetchVisitsAction.success):
      case getType(fetchUnitByIdAction.success): {
        const normalized =
          action?.payload?.normalized || emptyNormalizedHousekeepersData

        draft.data = { ...state.data, ...normalized.housekeeper }
        return
      }

      case getType(fetchCurrentUserAction.success): {
        const normalized =
          action.payload.normalized || emptyNormalizedHousekeepersData

        if (normalized.housekeeper) {
          Object.values(normalized.housekeeper).forEach(incomingHousekeeper => {
            const existingHousekeeper = state.data[incomingHousekeeper.id] || {}
            const mergedHousekeeper = merge(
              existingHousekeeper,
              incomingHousekeeper,
            )
            draft.data[incomingHousekeeper.id] = mergedHousekeeper
          })
        }

        return
      }
    }
  })
