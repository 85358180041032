import React from 'react'

import { Alert, Button } from 'packages/common'

import { useCrossCoverageDrawerTranslations } from '../../../useCrossCoverageDrawerTranslations'
import St from '../../../utils/CrossCoverageDrawer.styles'

export enum CrossCoverageFormControlsTestIds {
  cancelButton = 'cancelButton',
  submitButton = 'submitButton',
}

export type CrossCoverageFormControlsProps = {
  crossCoverageId?: string
  handleCancelButtonClick: () => void
  handleSubmitButtonClick: () => void
  isLoading: boolean
  submitIsDisabled: boolean
}

export const CrossCoverageFormControls: React.FC<
  CrossCoverageFormControlsProps
> = ({
  crossCoverageId,
  handleCancelButtonClick,
  handleSubmitButtonClick,
  isLoading,
  submitIsDisabled,
}) => {
  const strings = useCrossCoverageDrawerTranslations()
  return (
    <>
      <St.FormControls>
        <Button
          block={true}
          buttonType={crossCoverageId ? 'danger' : 'utility'}
          dataTestId={CrossCoverageFormControlsTestIds.cancelButton}
          onClick={handleCancelButtonClick}
        >
          {crossCoverageId ? strings.delete : strings.cancel}
        </Button>
        <Button
          block={true}
          buttonType="primary"
          dataTestId={CrossCoverageFormControlsTestIds.submitButton}
          disabled={submitIsDisabled || isLoading}
          isLoading={isLoading}
          onClick={handleSubmitButtonClick}
        >
          {crossCoverageId ? strings.update : strings.submit}
        </Button>
      </St.FormControls>
      <Alert alertType="info">{strings.systemAssigneeInfoAlert}</Alert>
    </>
  )
}
