import { createAsyncThunk } from '@reduxjs/toolkit'

import { clearBanner } from '../actions'
import { BannerConfig } from '../ui.types'

export const setBanner = createAsyncThunk(
  'ui/setBanner',
  async (args: BannerConfig, { dispatch }) => {
    if (args.timeout) {
      setTimeout(() => {
        dispatch(clearBanner())
      }, args.timeout)
    }

    return args
  },
)
