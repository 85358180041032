import * as React from 'react'

import { SubmitTimersForm } from '../SubmitTimersForm'
import { useSubmitOtherTimers } from './useSubmitOtherTimers'

export enum SubmitOtherTimersTestIds {
  container = 'submitOtherTimers__container',
}

export const SubmitOtherTimers: React.FC = () => {
  const hook = useSubmitOtherTimers()

  if (!hook) return null

  const {
    dispatchAbortSubmission,
    dispatchConfirmSubmission,
    dispatchCompleteStartedOffline,
    dispatchDeleteTimer,
    displayValue,
    otherTimerType,
    timerType,
    timers,
  } = hook

  return (
    <SubmitTimersForm
      dataTestId={SubmitOtherTimersTestIds.container}
      dispatchAbortSubmission={dispatchAbortSubmission}
      dispatchConfirmSubmission={dispatchConfirmSubmission}
      dispatchCompleteStartedOffline={dispatchCompleteStartedOffline}
      dispatchDeleteTimer={dispatchDeleteTimer}
      displayValue={displayValue}
      otherTimerType={otherTimerType}
      timerType={timerType}
      timers={timers}
    />
  )
}
