import styled from '@emotion/styled'
import React from 'react'

import { IconName, SvgIcon } from 'packages/iconic'
import { centerWithFlex, colors } from 'packages/styles'

import { convertIsCompleteToBoolean } from 'app/fieldapp/components/schedule/schedule.utils'
import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { categoryIconMap, InspectionList } from 'app/fieldapp/store/tasks'

import { useInspectionVisitWithRelationships } from '../../useInspectionVisitWithRelationships'

type CompletionStatus = 'not_complete' | 'complete_no_photos' | 'complete'

export const MIN_REQUIRED_PHOTOS = 2

/* eslint-disable @typescript-eslint/naming-convention */
const completionStyles = {
  background: {
    complete: colors.success40,
    complete_no_photos: colors.awareness40,
    not_complete: colors.white,
  },
  borderColor: {
    complete: colors.success40,
    complete_no_photos: colors.awareness40,
    not_complete: colors.midnight20,
  },
  color: {
    complete: colors.success,
    complete_no_photos: colors.awareness,
    not_complete: colors.midnight,
  },
}
/* eslint-enable @typescript-eslint/naming-convention */

const St = {
  Icon: styled(SvgIcon)<{ completedStatus: CompletionStatus }>`
    border: 3px solid
      ${({ completedStatus }) => completionStyles.borderColor[completedStatus]};
    border-radius: 50%;
    color: ${({ completedStatus }) => completionStyles.color[completedStatus]};
    padding: 8px;
    background: ${({ completedStatus }) =>
      completionStyles.background[completedStatus]};
  `,

  IconSection: styled.div`
    ${centerWithFlex};
    padding: 16px;
  `,

  InspectionCategory: styled.div`
    cursor: pointer;
    display: flex;

    &:not(:last-of-type) {
      border-bottom: 1px solid ${colors.midnight20};
    }
  `,

  TitleSection: styled.div`
    align-items: flex-start;
    display: flex;
    flex: 1 0;
    flex-direction: column;
    justify-content: center;
  `,

  TitleText: styled.span`
    color: ${colors.midnight};
    font-size: 15px;
    font-weight: bold;
  `,
}

const getCompletionStatus = (
  isCompleted: boolean,
  photosUploaded: boolean,
): CompletionStatus => {
  if (!isCompleted) return 'not_complete'
  return photosUploaded ? 'complete' : 'complete_no_photos'
}

const useTranslations = () => {
  const { t } = useI18n()

  return {
    items: t(Slugs.items),
  }
}

export const InspectionCategoryTestIds = {
  container: 'InspectionCategory__container',
}

export type VisitInspectionCategoryProps = {
  checklist: InspectionList
  onClick: (checklist: InspectionList) => void
}

export const VisitInspectionCategory: React.FC<VisitInspectionCategoryProps> =
  React.memo(({ checklist, onClick }) => {
    const strings = useTranslations()
    const { taskPhotos } = useInspectionVisitWithRelationships()

    const completedCount = checklist.items.filter(item =>
      convertIsCompleteToBoolean(item.isComplete),
    ).length

    const uploadedPhotosCount = taskPhotos.reduce((acc, photo) => {
      if (photo.categoryId === checklist.category.id) {
        return acc + 1
      }

      return acc
    }, 0)

    const totalItemCount = checklist.items.length
    const isCompleted = completedCount === totalItemCount
    const photosUploaded = uploadedPhotosCount >= MIN_REQUIRED_PHOTOS
    const completedStatus = getCompletionStatus(isCompleted, photosUploaded)
    const itemsString = `${completedCount}/${totalItemCount} ${strings.items}`

    const getIcon = () => {
      if (completedStatus === 'not_complete') {
        return categoryIconMap[checklist.category.categoryType]
      }

      return completedStatus === 'complete' ? IconName.check : IconName.camera
    }

    return (
      <St.InspectionCategory
        data-testid={InspectionCategoryTestIds.container}
        onClick={() => onClick(checklist)}
      >
        <St.IconSection>
          <St.Icon
            completedStatus={completedStatus}
            icon={getIcon()}
            size={15}
          />
        </St.IconSection>

        <St.TitleSection>
          <St.TitleText>{checklist.category.title}</St.TitleText>
          <span>{itemsString}</span>
        </St.TitleSection>

        <St.IconSection>
          <SvgIcon icon={IconName.chevronRight} size={18} />
        </St.IconSection>
      </St.InspectionCategory>
    )
  })
