import styled from '@emotion/styled'

import { Alert, Button } from 'packages/common'
import { colors, text } from 'packages/styles'

export const Asterisk = styled.span`
  color: ${colors.alert};
  padding-left: 4px;
`

export const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  grid-gap: 16px;
  justify-content: center;
  width: 100%;
`

export const DeleteAlert = styled(Alert)`
  margin-bottom: 0px;
`

export const FetchAlert = styled(Alert)`
  margin: 32px 16px 0 16px;
`

export const Label = styled.label`
  ${text.headingXsCaps};
  width: 80px;
`

export const LabelInputContainer = styled.div`
  align-items: center;
  display: flex;

  &:not(:last-child) {
    padding-bottom: 8px;
  }
`

export const Section = styled.section`
  padding: 32px 16px 0px 16px;
`

export const StartDate = styled.p`
  ${text.bodyRegularDefault};
  color: ${colors.dusk60};
  margin-bottom: 0px;
`

export const StartDateButton = styled(Button)`
  border-color: ${colors.midnight10};
  margin-top: 8px;
`

export const StartDateButtonContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const SubTitle = styled.h3`
  ${text.bodyTiny};
  color: ${colors.dusk60};
  font-style: italic;
  margin: 8px 0px 0px 0px;
`

export const SwitchContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`

export const Title = styled.h2`
  ${text.headingMediumCaps};
  color: ${colors.midnight};
  margin-bottom: 0px;
`
