import { isEmpty, merge } from 'lodash/fp'
import { createSelector } from 'reselect'

import { ApplicationState } from 'app/fieldapp/store/store'

import { CleanTime } from '../cleantimes.types'
import { hydrateRawCleanTime } from '../cleantimes.utils'

const getState = (state: ApplicationState): ApplicationState => state

const getCleanTimeId = (
  _state: ApplicationState,
  cleanTimeId: string | undefined,
): string | undefined => cleanTimeId

/**
 * Builds a CleanTime that is the result of combining a non-offline CleanTime with
 * the properties from an offline CleanTime (with the latter taking precedence).
 *
 * Use this in place of the regular "getCleanTimeById" selector when you need to ensure
 * that you have any potential offline data merged in. (If no offline data is present,
 * it will simply return the original CleanTime.)
 */
export const getMergedCleanTimeById = createSelector(
  getState,
  getCleanTimeId,
  (state, id): CleanTime | undefined => {
    if (!id) return undefined
    const cleanTimesState = state.cleanTimes

    const rawCleanTime = cleanTimesState.data[id] || {}

    const offlineCleanTime = cleanTimesState.offlineData[id] || {}

    const noData = isEmpty(rawCleanTime) && isEmpty(offlineCleanTime)
    const partialDataNotStartedOffline =
      isEmpty(rawCleanTime) && !offlineCleanTime?.attributes?.startedOffline

    if (noData || partialDataNotStartedOffline) return undefined
    return hydrateRawCleanTime(state)(merge(rawCleanTime, offlineCleanTime))
  },
)
