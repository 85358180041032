import styled from '@emotion/styled'
import React from 'react'

import { Alert, Button, FormProps } from 'packages/common'
import { colors } from 'packages/styles'
import { AsyncState } from 'packages/utils/hooks'

import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { Unit } from 'app/fieldapp/store/units'
import { SearchUnitsOptions } from 'app/fieldapp/store/units/actions/searchUnits'

import { UnitSearchSelector } from '../UnitSearchSelector'
import * as SharedSt from './CreateVisit.styles'
import { CreateVisitWarningsContainer } from './CreateVisitWarnings'
import { PastInspections } from './PastInspections'

const St = {
  Alert: styled(Alert)`
    margin-top: 24px;
  `,
  Buttons: styled(SharedSt.FormSection)`
    align-items: center;
    display: flex;
    justify-content: space-between;
  `,
  Divider: styled.hr`
    margin: 24px 0;
  `,
  SectionHeader: SharedSt.SectionHeader,
  UnitSelector: styled(UnitSearchSelector)`
    border: 1px solid ${colors.midnight20};
    border-radius: 4px;
  `,
}

const searchUnitsOptions: SearchUnitsOptions = {
  allUnits: false,
}

const useTranslations = () => {
  const { tp, ut } = useI18n()
  return {
    addVisit: ut(Slugs.addVisit),
    cancel: ut(Slugs.cancel),
    error: ut(Slugs.addVisitError),
    inspections: tp(Slugs.inspection, 2),
    unit: ut(Slugs.unit),
  }
}

export type CreateVisitFormValues = {
  unitId: string
}

export type CreateVisitFormProps = FormProps<CreateVisitFormValues> & {
  canCreateVisit: boolean
  className?: string
  fetchUnitState: AsyncState<unknown>
  handlers: { selectUnit: (unit: Unit) => void }
  unit: Unit | undefined
}

export const CreateVisitForm: React.FC<CreateVisitFormProps> = React.memo(
  ({
    canCreateVisit,
    fetchUnitState,
    className,
    formState,
    handlers,
    requestState,
    unit,
  }) => {
    const strings = useTranslations()

    return (
      <form className={className} onSubmit={handlers.submit}>
        <St.SectionHeader>{strings.unit}</St.SectionHeader>
        <St.UnitSelector
          onUnitChange={handlers.selectUnit}
          searchUnitsOptions={searchUnitsOptions}
          selectedUnit={unit}
        />
        <St.Divider />

        <CreateVisitWarningsContainer unitId={unit?.id || ''} />

        {canCreateVisit && (
          <>
            <PastInspections fetchUnitState={fetchUnitState} unit={unit} />
          </>
        )}

        {!!requestState.error && (
          <St.Alert alertType={'danger'}>{strings.error}</St.Alert>
        )}

        <St.Buttons>
          <Button
            block={true}
            buttonType="utility"
            isLoading={requestState.loading}
            onClick={handlers.cancel}
          >
            {strings.cancel}
          </Button>

          <Button
            block={true}
            disabled={!canCreateVisit || !formState.canSubmit}
            isFormSubmit={true}
            isLoading={requestState.loading}
          >
            {strings.addVisit}
          </Button>
        </St.Buttons>
      </form>
    )
  },
)
