import styled from '@emotion/styled'
import React from 'react'

import { useI18n } from 'packages/i18n'
import { IconName, SvgIcon } from 'packages/iconic'
import { text } from 'packages/styles'

import { Slugs } from 'app/fieldapp/i18n'

const St = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
  `,
  Content: styled.p`
    margin: 0;
  `,
  Heading: styled.h3`
    align-items: center;
    display: flex;
    gap: 12px;
    margin: 0;
    ${text.headingSCaps}
  `,
}

export enum CoverageDefaultInfoModalTestIds {
  container = 'CoverageDefaultInfoModal__container',
}

export const CoverageDefaultInfoModal: React.FC = React.memo(() => {
  const { t } = useI18n()

  return (
    <St.Container data-testid={CoverageDefaultInfoModalTestIds.container}>
      <St.Heading>
        <SvgIcon icon={IconName.infoCircle} size={24} />{' '}
        {t(Slugs.coverageDefaultModalTitle)}
      </St.Heading>
      <St.Content>{t(Slugs.coverageDefaultModalMessage)}</St.Content>
    </St.Container>
  )
})
