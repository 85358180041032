import { produce } from 'immer'
import { ActionType, getType } from 'typesafe-actions'

import { JSONApiObjectWithRelationships } from 'packages/utils/store/jsonapi.types'
import { getIdAndDataTypeFromAction } from 'packages/utils/store/store.utils'

import { RawClean } from 'app/fieldapp/store/cleans/cleans.types'
import { getLastFetchTimestamp } from 'app/fieldapp/utils/cacheHelpers'

import { rejectAssignmentAction } from '../assignments/actions'
import { NormalizedTaskData } from '../tasks'
import { emptyNormalizedTasksData } from '../tasks/tasks.utils'
import {
  fetchCleansAction,
  fetchCleanByIdAction,
  toggleInspectionItemAction,
  updateCleanAction,
} from './actions'
import {
  CleansState,
  CleanAttributes,
  CleanRelationships,
} from './cleans.types'

import { isRawClean } from '.'

const DEFAULT_ERROR = new Error('Unknown Error in cleansReducer')

export const initialState: CleansState = Object.freeze({
  data: {},
  error: undefined,
  lastFetch: 0,
  loading: true,
})

const actions = {
  fetchCleanByIdAction,
  fetchCleansAction,
  rejectAssignmentAction,
  toggleInspectionItemAction,
  updateCleanAction,
}

type CleansActionsTypes = ActionType<typeof actions>

export const cleansReducer = (
  state = initialState,
  action: CleansActionsTypes,
): CleansState =>
  produce(state, (draft: CleansState) => {
    switch (action.type) {
      case getType(actions.toggleInspectionItemAction.request):
      case getType(fetchCleansAction.request): {
        draft.error = undefined
        draft.loading = true
        return
      }

      case getType(fetchCleansAction.success): {
        const taskData: NormalizedTaskData['task'] =
          action?.payload?.normalized?.task || emptyNormalizedTasksData.task

        draft.data = {}

        Object.values(taskData).forEach(task => {
          if (isRawClean(task)) {
            draft.data[task.id] = task
          }
        })

        draft.error = undefined
        draft.loading = false
        draft.lastFetch = getLastFetchTimestamp('cleans')
        return
      }

      case getType(actions.fetchCleanByIdAction.success): {
        const [id, clean] = getIdAndDataTypeFromAction<RawClean>(action, 'task')

        draft.data[id] = clean as JSONApiObjectWithRelationships<
          CleanAttributes,
          CleanRelationships
        >

        return
      }

      case getType(actions.rejectAssignmentAction.success): {
        const {
          payload: { cleanId },
        } = action

        delete draft.data[cleanId]
        return
      }

      //------------------------------------------------
      // Misc. failure states
      //------------------------------------------------
      case getType(actions.fetchCleanByIdAction.failure):
      case getType(actions.fetchCleansAction.failure):
      case getType(actions.toggleInspectionItemAction.failure):
      case getType(actions.updateCleanAction.failure): {
        draft.error = action.payload || DEFAULT_ERROR
        draft.loading = false
        return
      }
    }
  })
