import React from 'react'

import { BooleanSelect } from 'packages/common'
import { IconName, SvgIcon } from 'packages/iconic'

import { useCrossCoverageDrawerTranslations } from '../../../useCrossCoverageDrawerTranslations'
import St from '../../../utils/CrossCoverageDrawer.styles'

export enum IsRepeatingFormTestIds {
  infoIconButton = 'IsRepeatingForm__infoIconButton',
  isRepeatingField = 'IsRepeatingForm__isRepeatingField',
}

export enum IsRepeatingFormAriaLabels {
  isRepeatingSelect = 'Is repeating select',
}

export type IsRepeatingFormProps = {
  handleInfoIconClick: () => void
  handleIsRepeatingChange: (opt: boolean) => void
  isDisabled: boolean
  isRepeating: boolean | null
}

export const IsRepeatingForm: React.FC<IsRepeatingFormProps> = ({
  handleInfoIconClick,
  handleIsRepeatingChange,
  isDisabled,
  isRepeating,
}) => {
  const strings = useCrossCoverageDrawerTranslations()

  const translateOption = (opt: boolean) => {
    if (opt === true) return strings.yes
    if (opt === false) return strings.no
    return strings.chooseAnOption
  }

  return (
    <St.FormField data-testid={IsRepeatingFormTestIds.isRepeatingField}>
      <St.FormFieldLabel>
        {strings.willThisEventRepeat}
        <St.FormFieldLabelRequiredAsterisk>*</St.FormFieldLabelRequiredAsterisk>
        <St.InfoIconButton
          data-testid={IsRepeatingFormTestIds.infoIconButton}
          onClick={handleInfoIconClick}
        >
          <SvgIcon icon={IconName.info} />
        </St.InfoIconButton>
      </St.FormFieldLabel>
      <BooleanSelect
        ariaLabel={IsRepeatingFormAriaLabels.isRepeatingSelect}
        disabled={isDisabled}
        onChange={handleIsRepeatingChange}
        options={[true, false]}
        translateValue={translateOption}
        value={isRepeating}
      />
    </St.FormField>
  )
}
