import { useSelector } from 'react-redux'

import { ApplicationState } from 'app/fieldapp/store/store'
import { TaskPhoto } from 'app/fieldapp/store/taskPhotos'
import { getTaskPhotosByIds } from 'app/fieldapp/store/taskPhotos/selectors'
import { Unit } from 'app/fieldapp/store/units'
import { getUnitById } from 'app/fieldapp/store/units/selectors'
import { Visit } from 'app/fieldapp/store/visits'
import { getVisitById } from 'app/fieldapp/store/visits/selectors'

import { useInspectionContext } from './context/VisitInspectionChecklist.context'

type UseInspectionVisitWithRelationships = {
  taskPhotos: TaskPhoto[]
  unit: Unit
  visit: Visit
}

export const useInspectionVisitWithRelationships =
  (): UseInspectionVisitWithRelationships => {
    const { visitId } = useInspectionContext()

    const visit = useSelector((state: ApplicationState) =>
      getVisitById(state, visitId),
    )

    const unit = useSelector((state: ApplicationState) =>
      getUnitById(state, visit?.unitId || ''),
    )

    const taskPhotos = useSelector((state: ApplicationState) => {
      if (!visit) return []
      return getTaskPhotosByIds(state.taskPhotos, visit?.taskPhotoIds)
    })

    if (!visit) {
      throw new Error(
        `Visit ${visitId} in the inspection context has no matching visit in the store`,
      )
    }

    if (!unit) {
      throw new Error('Visit without unit found')
    }

    return { taskPhotos, unit, visit }
  }
