import { createAsyncAction } from 'typesafe-actions'

import { RequireAllExceptOptional } from 'packages/grimoire/src/utils'
import { apiDateStringWithSeconds } from 'packages/utils/dateHelpers'
import {
  OfflineRequestConfig,
  RequestOptions,
  setRequestRelationship,
} from 'packages/utils/store'

import {
  NormalizedOtherTimersApiResponse,
  OtherTimersPostData,
  OtherTimerPostApiAttributes,
  OtherTimersActionTypes,
} from 'app/fieldapp/store/otherTimers'

import { offlineTimers } from '../../utils'
import { otherTimersService } from '../otherTimers.service'
import { fetchActiveOtherTimersForUser } from './fetchActiveOtherTimersForUser'

export const createFinalizedOtherTimerAction = createAsyncAction(
  OtherTimersActionTypes.CREATE_FINALIZED_OTHER_TIMER,
  OtherTimersActionTypes.CREATE_FINALIZED_OTHER_TIMER_SUCCESS,
  OtherTimersActionTypes.CREATE_FINALIZED_OTHER_TIMER_FAILURE,
)<
  OfflineRequestConfig<NormalizedOtherTimersApiResponse, OtherTimersPostData>,
  { localId: string; result: NormalizedOtherTimersApiResponse },
  Error
>()

export type FinalizedOtherTimePostData = Omit<
  RequireAllExceptOptional<OtherTimersPostData, 'notes'>,
  'isFinalized'
>

export const buildRequestData = (postData): RequestOptions => {
  const { startedAt, timerType, userId, stoppedAt, notes } = postData
  const attributes: OtherTimerPostApiAttributes = {
    is_finalized: true,
    notes,
    started_at: apiDateStringWithSeconds(startedAt),
    stopped_at: apiDateStringWithSeconds(stoppedAt),
    // TODO: rename to other timer when API ready
    task_type: timerType,
  }

  return {
    data: {
      attributes,
      relationships: {
        ...setRequestRelationship('user', userId),
      },
      // TODO: rename to other timer when API ready
      type: 'task_time',
    },
  }
}

/**
 * Posts a finalized timer. Used for working with timers started offline.
 * The localId is not posted to the API, but is used to delete any offline data.
 */
export const createFinalizedOtherTimer =
  (localId: string, postData: OtherTimersPostData) => async dispatch => {
    try {
      const requestData = buildRequestData(postData)
      const request = otherTimersService.createOtherTimer.bind(
        null,
        requestData,
      )
      const result = await dispatch(
        createFinalizedOtherTimerAction.request({ data: postData, request }),
      )

      await offlineTimers.removeOtherTime(localId)

      dispatch(createFinalizedOtherTimerAction.success({ localId, result }))

      await dispatch(fetchActiveOtherTimersForUser())
    } catch (error) {
      dispatch(createFinalizedOtherTimerAction.failure(error))
      throw error
    }
  }
