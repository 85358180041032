import { AxiosResponse } from 'axios'
import { snakeCase } from 'lodash'

import {
  AllInspectionFlagAttributeNames,
  AllInspectionFlagAttributes,
} from 'packages/grimoire/src/inspectionFlag/inspectionFlag.types'
import {
  JSONApiObjectWithRelationships,
  JSONApiObjectWithRelationshipsMap,
  JsonApiErrorResponse,
  NormalizedJSONApiResponse,
  ToOneRelationship,
} from 'packages/grimoire/src/utils'

/**********************************************************
 * REDUX TYPES
 *********************************************************/
export enum InspectionFlagActionTypes {
  UPDATE_INPSECTION_FLAG = 'crossCoverages/updateInspectionFlag/pending',
  UPDATE_INPSECTION_FLAG_FAILURE = 'crossCoverage/updateInspectionFlag/failure',
  UPDATE_INPSECTION_FLAG_SUCCESS = 'crossCoverage/updateInspectionFlag/success',
}

export type InspectionFlagState = {
  data: JSONApiInspectionFlagMap
  error?: JsonApiErrorResponse
}

/**********************************************************
 * SERVICE TYPES
 *********************************************************/
export type InspectionFlagResponse = {
  inspectionFlag: JSONApiInspectionFlagMap
}

export type NormalizedInspectionFlagApiResponse =
  NormalizedJSONApiResponse<InspectionFlagResponse>

export type InspectionFlagServiceResponse =
  Promise<NormalizedInspectionFlagApiResponse>

export type InspectionFlagApiResponse =
  AxiosResponse<NormalizedInspectionFlagApiResponse>

export type InspectionFlagPatchData = {
  notes: string
}

export type InspectionFlagPatchAPIAttributes = {
  notes: string
}

/**********************************************************
 * INSPECTION FLAG
 *********************************************************/
export type InspectionFlag = {
  id: string
  taskId: string
  ticketIds?: string[]
} & AllInspectionFlagAttributes

export const InspectionFlagAttributeNames = AllInspectionFlagAttributeNames

export const InspectionFlagApiFields: string[] =
  InspectionFlagAttributeNames.map(snakeCase)

export type InspectionFlagRelationships = {
  task: ToOneRelationship
}

export type RawInspectionFlag = JSONApiObjectWithRelationships<
  AllInspectionFlagAttributes,
  InspectionFlagRelationships
>

export type JSONApiInspectionFlagMap = JSONApiObjectWithRelationshipsMap<
  AllInspectionFlagAttributes,
  InspectionFlagRelationships
>
