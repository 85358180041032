import { AxiosResponse } from 'axios'
import snakeCase from 'lodash/snakeCase'

import {
  JSONApiObjectWithRelationships,
  JSONApiObjectWithRelationshipsMap,
  NormalizedJSONApiResponse,
  ToOneRelationship,
} from 'packages/utils/store/jsonapi.types'

import { JSONApiAssignmentsMap } from '../assignments'

/**********************************************************
 * REDUX TYPES
 *********************************************************/
export type SmartLocksState = {
  data: JSONApiSmartLocksMap
  error?: Error
}

/**********************************************************
 * SERVICE TYPES
 *********************************************************/
export type SmartLocksResponse = {
  assignment: JSONApiAssignmentsMap
  smartLock: JSONApiSmartLocksMap
}

export type NormalizedSmartLocksApiResponse =
  NormalizedJSONApiResponse<SmartLocksResponse>
export type SmartLocksServiceResponse = Promise<NormalizedSmartLocksApiResponse>
export type SmartLocksApiResponse =
  AxiosResponse<NormalizedSmartLocksApiResponse>

/**********************************************************
 * SMART LOCK TYPES
 *********************************************************/
export type SmartLockAttributes = {
  accessCode: string
}

export const SmartLockAttributeNames = ['accessCode']

export const SmartLockApiFields = SmartLockAttributeNames.map<string>(snakeCase)

export type SmartLockRelationships = {
  assignment: ToOneRelationship
}

export type RawSmartLock = JSONApiObjectWithRelationships<
  SmartLockAttributes,
  SmartLockRelationships
>

export type JSONApiSmartLocksMap = JSONApiObjectWithRelationshipsMap<
  SmartLockAttributes,
  SmartLockRelationships
>

export type SmartLock = {
  id: string
} & SmartLockAttributes
