import { filter, map, pipe, values } from 'lodash/fp'
import { createSelector } from 'reselect'

import { isValidPausedState } from 'app/fieldapp/components/timers/timers.helpers'
import { CleanTime } from 'app/fieldapp/store/cleantimes'
import { ApplicationState } from 'app/fieldapp/store/store'

import { hydrateRawCleanTime } from '../cleantimes.utils'

const getFullState = (state: ApplicationState): ApplicationState => state

export const getPausedCleanTimes = createSelector(
  getFullState,
  (state): CleanTime[] => {
    const hydrator = hydrateRawCleanTime(state)

    return pipe(
      values,
      map(hydrator),
      filter(isValidPausedState),
    )(state.cleanTimes.data)
  },
)
