import styled from '@emotion/styled'

import { SvgIcon } from 'packages/iconic'
import { colors } from 'packages/styles'

export {
  TaskCardContainer,
  TaskCardContent,
  UnitAddress,
  UnitCode,
  UnitName,
} from '../TaskSharedStyles/TaskCard.styles'

// TODO: TPD-5950 reconcile which updated styles can be shared from VisitCard (i.e. move to TaskCard.styles)

export const Section = styled.section`
  :not(:first-of-type) {
    margin-top: 8px;
  }
`

export const FlexSection = styled.div`
  display: flex;
  justify-content: left;
  margin-top: 2px;
  :first-of-type {
    margin-top: 8px;
  }
`

export const DueDate = styled.span`
  margin-left: 4px;
`

export const IconRow = styled(Section)`
  align-items: center;
  display: flex;

  div:first-of-type {
    margin-right: 8px; // add margin to the icon
  }

  strong {
    padding-top: 1px; // just a tiny bump for text v-alignment
  }
`

export const TeamCleanIcon = styled(SvgIcon)`
  color: ${colors.awareness}; // setting color here cascades to the "zap" SVG
`
