import { createAsyncAction } from 'typesafe-actions'

import {
  RequestConfig,
  RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import { taskPhotosService } from '../taskPhotos.service'
import {
  TaskPhotoApiFields,
  TaskPhotosActionTypes,
  NormalizedTaskPhotosApiResponse,
} from '../taskPhotos.types'

export const fetchTaskPhotoByIdAction = createAsyncAction(
  TaskPhotosActionTypes.FETCH_TASK_PHOTO,
  TaskPhotosActionTypes.FETCH_TASK_PHOTO_SUCCESS,
  TaskPhotosActionTypes.FETCH_TASK_PHOTO_FAILURE,
)<
  RequestConfig<NormalizedTaskPhotosApiResponse>,
  NormalizedTaskPhotosApiResponse,
  Error
>()

export const getParams = (): RequestOptions => ({
  fields: {
    task_photo: TaskPhotoApiFields,
  },
})

export const fetchTaskPhotoById = (taskPhotoId: string) => async dispatch => {
  try {
    const params = getParams()
    const request = taskPhotosService.fetchTaskPhotoById.bind(
      null,
      taskPhotoId,
      params,
    )
    const result = await dispatch(fetchTaskPhotoByIdAction.request({ request }))
    dispatch(fetchTaskPhotoByIdAction.success(result))
    return result.normalized
  } catch (error) {
    dispatch(fetchTaskPhotoByIdAction.failure(error))
    throw error
  }
}
