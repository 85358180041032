import { connect } from 'react-redux'

import { getActiveCleanTime } from 'app/fieldapp/store/cleantimes/selectors'
import { getHkByUserId } from 'app/fieldapp/store/housekeepers/selectors'
import { getActiveOtherTimer } from 'app/fieldapp/store/otherTimers/selectors'
import { getActiveUser } from 'app/fieldapp/store/users/selectors'

import { Timers, TimersProps } from './Timers'

type ReduxSelectors =
  | 'getActiveCleanTime'
  | 'getActiveOtherTimer'
  | 'getActiveUser'
  | 'getHkByUserId'

type StateProps = Pick<TimersProps, ReduxSelectors>
type DispatchProps = unknown
type PassThruProps = Omit<TimersProps, ReduxSelectors>

const mapStateToProps = state => ({
  getActiveCleanTime: () => getActiveCleanTime(state),
  getActiveOtherTimer: () => getActiveOtherTimer(state),
  getActiveUser: () => getActiveUser(state),
  getHkByUserId: (id: string) => getHkByUserId(state, id),
})

export const ConnectedTimers = connect<
  StateProps,
  DispatchProps,
  PassThruProps
>(
  mapStateToProps,
  {},
)(Timers)
