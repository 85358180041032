import * as React from 'react'
import { useWindowSize } from 'react-use'

import { OtherTimerType } from 'packages/grimoire'
import { useI18n } from 'packages/i18n'
import { SvgIcon, IconName } from 'packages/iconic'

import { CREATE_TICKET_URL } from 'app/fieldapp/components/profile'
import { Slugs } from 'app/fieldapp/i18n'

import { TimerRow } from './components'
import {
  ArrowWrapper,
  BackArrow,
  ChevronIcon,
  OtherTimersContainer,
  OtherTimersHeader,
  SubMenuTitle,
  SubMenuTitleContainer,
  CreateATicketContainer,
  CreateATicket,
  CreateATicketPaddingWrapper,
} from './OtherTimers.styles'
import {
  OtherTimerDrawerStatus,
  TimerMenuType,
  TimerMenuConfig,
} from './OtherTimers.types'

const clockIconTextStyles = { paddingLeft: '10px', paddingTop: '3px' }

export type OtherTimersProps = {
  displayedTimerConfig: TimerMenuConfig
  onOpenMenu: (menu: TimerMenuType) => void
  onStartOtherTimer: (otherTimerType: OtherTimerType) => Promise<void>
  otherTimerDrawerStatus: OtherTimerDrawerStatus
  toggleIsExpanded: () => void
}

export const OtherTimers: React.FC<OtherTimersProps> = ({
  displayedTimerConfig,
  onOpenMenu,
  onStartOtherTimer,
  otherTimerDrawerStatus,
  toggleIsExpanded,
}) => {
  const { t } = useI18n()

  const { isSubMenuOpen, isExpanded } = otherTimerDrawerStatus

  const windowHeight = useWindowSize().height

  const shouldDisplayBackBtn =
    isSubMenuOpen && isExpanded && displayedTimerConfig.title

  return (
    <OtherTimersContainer height={isExpanded ? windowHeight : 64}>
      <OtherTimersHeader onClick={toggleIsExpanded}>
        <SvgIcon
          centerItems={true}
          icon={IconName.clock}
          text={t(Slugs.selectTimer)}
          textFontSize={16}
          textStyleOverrides={clockIconTextStyles}
          size={20}
        />
        <ChevronIcon
          icon={IconName.chevronRight}
          isPointedUp={isExpanded}
          size={30}
        />
      </OtherTimersHeader>

      {shouldDisplayBackBtn && (
        <SubMenuTitleContainer>
          <ArrowWrapper>
            <BackArrow
              height={14}
              icon={IconName.leftArrow}
              onClick={() => onOpenMenu('main')}
              width={18}
            />
          </ArrowWrapper>
          <SubMenuTitle>{displayedTimerConfig.title}</SubMenuTitle>
        </SubMenuTitleContainer>
      )}

      {displayedTimerConfig.timers.map(config => (
        <TimerRow
          config={config}
          key={config.dataTestId}
          onOpenMenu={onOpenMenu}
          onStartOtherTimer={onStartOtherTimer}
          otherTimerDrawerStatus={otherTimerDrawerStatus}
        />
      ))}
      {displayedTimerConfig.showCreateTicketLink && (
        <CreateATicketContainer>
          <CreateATicketPaddingWrapper>
            {t(Slugs.noneOfThese)}
            <CreateATicket
              text={t(Slugs.createTicket)}
              url={CREATE_TICKET_URL}
            />
          </CreateATicketPaddingWrapper>
        </CreateATicketContainer>
      )}
    </OtherTimersContainer>
  )
}
