import { createSelector } from 'reselect'

import { ApplicationState } from 'app/fieldapp/store/store'
import { hydrateRawUser, JSONApiUsersMap, User } from 'app/fieldapp/store/users'

const getUsersData = (state: ApplicationState): JSONApiUsersMap =>
  state.users.data

const getActiveUserId = (state: ApplicationState): string =>
  state.users.activeUserId

export const getActiveUser = createSelector(
  getUsersData,
  getActiveUserId,
  (usersData, activeUserId): User | undefined => {
    const rawUser = usersData[activeUserId]
    return rawUser && hydrateRawUser(rawUser)
  },
)
