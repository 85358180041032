import { createAsyncAction } from 'typesafe-actions'

import {
  RequestConfig,
  RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import { HousekeeperApiFields } from '../../housekeepers'
import { cleanTimesService } from '../cleantimes.service'
import {
  CleanTimeApiFields,
  CleanTimesActionTypes,
  NormalizedCleanTimesApiResponse,
} from '../cleantimes.types'

export const fetchCleanTimeByIdAction = createAsyncAction(
  CleanTimesActionTypes.FETCH_CLEAN_TIME_BY_ID,
  CleanTimesActionTypes.FETCH_CLEAN_TIME_BY_ID_SUCCESS,
  CleanTimesActionTypes.FETCH_CLEAN_TIME_BY_ID_FAILURE,
)<
  RequestConfig<NormalizedCleanTimesApiResponse>,
  NormalizedCleanTimesApiResponse,
  Error
>()

export const getParams = (): RequestOptions => ({
  fields: {
    assignment: [],
    clean_time: CleanTimeApiFields,
    housekeeper: HousekeeperApiFields,
  },
  include: ['assignment', 'assignment.housekeeper'],
})

export const fetchCleanTimeById = (cleanTimeId: string) => async dispatch => {
  try {
    const params = getParams()
    const request = cleanTimesService.fetchCleanTimeById.bind(
      null,
      cleanTimeId,
      params,
    )
    const result = await dispatch(fetchCleanTimeByIdAction.request({ request }))
    dispatch(fetchCleanTimeByIdAction.success(result))
    return result.normalized
  } catch (error) {
    dispatch(fetchCleanTimeByIdAction.failure(error))
    throw error
  }
}
