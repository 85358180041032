import get from 'lodash/get' // eslint-disable-line

import { RequestOptions } from 'packages/utils/store/jsonapi.types'

import { api } from '../axiosInstance'
import { UsersApiResponse, UsersServiceResponse } from './users.types'
import { emptyUsersResponse } from './users.utils'

const URL = '/users'

export const usersService = {
  /**
   * Sends a GET request to the server to fetch the currently logged-in User.
   * @param params
   */
  async fetchCurrentUser(params: RequestOptions = {}): UsersServiceResponse {
    const response: UsersApiResponse = await api.get('/users/me', {
      params,
    })
    return get(response, 'data', emptyUsersResponse)
  },

  async fetchUserById(
    id: string,
    params: RequestOptions = {},
  ): UsersServiceResponse {
    const response: UsersApiResponse = await api.get(`${URL}/${id}`, {
      params,
    })
    return get(response, 'data', emptyUsersResponse)
  },

  /**
   * Sends a GET request to the server to fetch users by the specified params
   * @param params
   */
  async fetchUsers(params: RequestOptions = {}): UsersServiceResponse {
    const response: UsersApiResponse = await api.get(`/users`, {
      params,
    })
    return get(response, 'data', emptyUsersResponse)
  },
}
