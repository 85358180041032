import React from 'react'

import { CoverageEntry, CoverageEntryProps } from './CoverageEntry'

export type CoverageEntryContainerProps = Pick<CoverageEntryProps, 'entry'>

export const CoverageEntryContainer: React.FC<CoverageEntryContainerProps> = ({
  entry,
}) => {
  return <CoverageEntry entry={entry} />
}
