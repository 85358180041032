import * as React from 'react'

import { formatSecondsAsDigitalClock } from 'packages/utils/mathHelpers'

import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { useTaskUnit } from 'app/fieldapp/store/tasks/utils/useTaskUnit'

import { getTimerTypeForTask } from '../../../timers.helpers'
import { StopTimerButton } from '../../Buttons/StopTimerButton'
import {
  ActiveTimeContainer,
  DetailWrapper,
  TimeDisplay,
  TimerName,
} from '../ActiveTimers.styles'
import { useActiveCleanTimer } from './useActiveCleanTimer'

export enum ActiveCleanTimeTestIds {
  container = 'ActiveCleanTimer__container',
  pauseBtn = 'ActiveCleanTimer__pauseBtn',
}

export const ActiveCleanTimer: React.FC = React.memo(() => {
  const { activeTask, activeCleanTime, isLoading, stopActiveTimer, time } =
    useActiveCleanTimer()

  const { ut } = useI18n()

  const unit = useTaskUnit(activeTask)

  if (!activeTask || !activeCleanTime) return null

  const timeString = formatSecondsAsDigitalClock(time)

  const unitString = unit
    ? `${unit.unitCode} - ${unit.name}`
    : ut(Slugs.unknown)
  const cleanType = getTimerTypeForTask(activeTask)

  return (
    <ActiveTimeContainer
      data-testid={ActiveCleanTimeTestIds.container}
      timerColorType={cleanType}
    >
      <DetailWrapper>
        <TimerName>{unitString}</TimerName>
      </DetailWrapper>

      <TimeDisplay>{timeString}</TimeDisplay>

      <StopTimerButton
        dataTestId={ActiveCleanTimeTestIds.pauseBtn}
        isLoading={isLoading}
        onClick={stopActiveTimer}
      />
    </ActiveTimeContainer>
  )
})
