import { flatMap, prop } from 'lodash/fp'
import { createSelector } from 'reselect'

import { addDays, createDateObject } from 'packages/utils/dateHelpers'

import { Clean } from 'app/fieldapp/store/cleans/cleans.types'
import { getFrozenCleans } from 'app/fieldapp/store/cleans/selectors'
import { ApplicationState } from 'app/fieldapp/store/store'

import { getVisitsOnDate } from '../../visits/selectors'
import { getActiveUserAssignmentByClean } from './getActiveUserAssignmentByClean'

/**
 * Returns a sorted list of all assignment IDs for the current user that are
 * tied to cleans within the freeze window.
 *
 * Note that the returned list needs to be sorted to ensure a stable
 * cache key for the ServiceWorker cache.
 */
export const getFrozenAssignmentIds = createSelector(
  (state: ApplicationState): ApplicationState => state,
  (state): string[] => {
    const findAssignmentForUser = (acc: string[], clean: Clean) => {
      const assignment = getActiveUserAssignmentByClean(state, clean)
      if (assignment) {
        acc.push(assignment.id)
      }

      return acc
    }

    const frozenCleans = getFrozenCleans(state)
    const frozenCleanAssignmentIds = frozenCleans.reduce(
      findAssignmentForUser,
      [],
    )

    const visits = getVisitsOnDate(state, createDateObject())
    // Needed for users who start the forcasted inspection
    const visitsTomorrow = getVisitsOnDate(
      state,
      addDays(createDateObject(), 1),
    )
    // Visits currently are effectively a 1:1 relationship with assignments
    const visitAssignmentIds = flatMap(prop('assignmentIds'))([
      ...visits,
      ...visitsTomorrow,
    ])

    return [...frozenCleanAssignmentIds, ...visitAssignmentIds].sort()
  },
)
