import { createAsyncAction } from 'typesafe-actions'

import {
  RequestConfig,
  RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import { unitsService } from '../units.service'
import {
  NormalizedUnitSearchApiResponse,
  UnitsActionTypes,
} from '../units.types'

export const searchUnitsAction = createAsyncAction(
  UnitsActionTypes.SEARCH_UNITS,
  UnitsActionTypes.SEARCH_UNITS_SUCCESS,
  UnitsActionTypes.SEARCH_UNITS_FAILURE,
)<
  RequestConfig<NormalizedUnitSearchApiResponse>,
  NormalizedUnitSearchApiResponse,
  Error
>()

export type SearchUnitsOptions = {
  allUnits?: boolean
}

const defaultOptions: SearchUnitsOptions = {
  allUnits: false,
}

export const getParams = (
  search: string,
  options: SearchUnitsOptions = defaultOptions,
): RequestOptions => ({
  fields: {
    zone: [],
  },
  filter: {
    all_units: options.allUnits,
    search,
  },
  include: ['zone'],
  page: { size: 50 },
})

/**
 * Performs a unit search with optional configuration options.
 *
 * Current options include:
 * - allUnits: Whether the API should include units from all zones,
 *      or only the zone(s) the current user is in. (Default: false)
 *
 * @param search
 * @param options
 */
export const searchUnits =
  (search: string, options = defaultOptions) =>
  async dispatch => {
    try {
      const params = getParams(search, options)
      const request = unitsService.searchUnits.bind(null, params)
      const result = await dispatch(searchUnitsAction.request({ request }))
      dispatch(searchUnitsAction.success(result))
      return result.normalized
    } catch (error) {
      dispatch(searchUnitsAction.failure(error))
      throw error
    }
  }
