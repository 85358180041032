import React from 'react'

import { JsonApiErrorResponse } from 'packages/utils/store'

import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { useAppDispatch } from 'app/fieldapp/store/hooks'
import { clearBanner, setBanner } from 'app/fieldapp/store/ui/actions'
import { TimerType } from 'app/fieldapp/store/utils/timers/timers.types'

import {
  createTimersErrorBannerConfig,
  parseAndHandleError,
} from './useTimerErrors.helpers'

const reassignedTimerSlugs = {
  [TimerType.CLEAN]: Slugs.cleanReassigned,
  [TimerType.TICKET]: Slugs.ticketReassigned,
}

type UseTimerErrors = {
  clearExistingErrors: () => void
  handleError: (error: JsonApiErrorResponse) => void
  handleUnassignedEntity: (timerType: TimerType) => void
}

export function useTimerErrors(): UseTimerErrors {
  const dispatch = useAppDispatch()
  const { t } = useI18n()

  const handleError = React.useCallback(
    (errorRes: JsonApiErrorResponse) => {
      const code = parseAndHandleError(errorRes)

      dispatch(setBanner(createTimersErrorBannerConfig(t)(code)))
    },
    [dispatch, t],
  )

  const handleUnassignedEntity = React.useCallback(
    (timerType: TimerType) => {
      dispatch(
        setBanner({
          message: t(reassignedTimerSlugs[timerType]),
          timeout: 5000,
        }),
      )
    },
    [dispatch, t],
  )

  return {
    clearExistingErrors: clearBanner,
    handleError,
    handleUnassignedEntity,
  }
}
