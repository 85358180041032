import get from 'lodash/get' // eslint-disable-line
import memoizeOne from 'memoize-one'
import { createSelector } from 'reselect'

import { addDays, eachDay, format } from 'packages/utils/dateHelpers'

import { ApplicationState } from '../../store'
import { getCleansBucketedByDate } from './getCleansBucketedByDate'

const getFullState = (state: ApplicationState): ApplicationState => state

type DateRangeType = {
  [key: string]: string[]
}

const getDateRange = memoizeOne(
  (_state: ApplicationState, start: Date, range: number): DateRangeType =>
    eachDay(start, addDays(start, range - 1)).reduce((acc, date) => {
      const monthKey = format(date, 'yyyy-MM')
      const dateKey = format(date, 'yyyy-MM-dd')

      if (!(monthKey in acc)) {
        acc[monthKey] = []
      }

      acc[monthKey].push(dateKey)
      return acc
    }, {}),
)

export const getCleanBucketsForDateRange = createSelector(
  getFullState,
  getDateRange,
  (state, dateRange) => {
    // get the initial bucketed cleans, from which we will build our date-filtered buckets
    const cleanBuckets = getCleansBucketedByDate(state)
    const dateRangeBuckets = {}

    Object.entries(dateRange).forEach(([monthKey, monthBucket]) => {
      Object.values(monthBucket).forEach(dayKey => {
        // ensure we have a valid month-bucket (Object)
        if (!get(dateRangeBuckets, monthKey)) {
          dateRangeBuckets[monthKey] = {}
        }

        // for this date, use either the existing bucket from 'cleanBuckets' or an empty Array
        dateRangeBuckets[monthKey][dayKey] = get(
          cleanBuckets,
          [monthKey, dayKey],
          [],
        )
      })
    })

    return dateRangeBuckets
  },
)
