import { connect } from 'react-redux'

import { ApplicationState } from 'app/fieldapp/store/store'
import { getLastFetch } from 'app/fieldapp/store/tickets/selectors'
import { getActiveUser } from 'app/fieldapp/store/users/selectors'

import { TicketsPageContainer } from './TicketsPage.container'

const mapStateToProps = (state: ApplicationState) => ({
  activeUser: getActiveUser(state),
  lastFetch: getLastFetch(state),
})

export const ConnectedTicketsPage =
  connect(mapStateToProps)(TicketsPageContainer)
