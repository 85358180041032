import * as React from 'react'
import { useSelector } from 'react-redux'

import { TimerType } from 'packages/grimoire/src/utils/timers.types'
import { useOnlineStatus } from 'packages/utils/hooks'

import {
  getActiveCleanTime,
  getSubmittableCleanTime,
} from 'app/fieldapp/store/cleantimes/selectors'
import { getActiveOtherTimer } from 'app/fieldapp/store/otherTimers/selectors'
import { getSubmittableOtherTimer } from 'app/fieldapp/store/otherTimers/selectors/getSubmittableOtherTimer'
import { getSubmittableTicketTime } from 'app/fieldapp/store/ticket-times/selectors'
import { getActiveTicketTime } from 'app/fieldapp/store/ticket-times/selectors/getActiveTicketTime'

import { TimerComponentShowingState } from '../containers/Timers/Timers.helpers'
import {
  setSubmissionData,
  setUiState,
  TimersContext,
  TimerSubmissionData,
  UiState,
} from '../state'
import { useOfflineMergedTimers } from './useOfflineMergedTimers'

const useFindSubmittableTimer = (): TimerSubmissionData | null => {
  const cleanTime = useSelector(getSubmittableCleanTime)
  const ticketTime = useSelector(getSubmittableTicketTime)
  const otherTimer = useSelector(getSubmittableOtherTimer)

  if (cleanTime) {
    return {
      timer: cleanTime,
      type: TimerType.CLEAN,
    }
  }

  if (ticketTime) {
    return {
      timer: ticketTime,
      type: TimerType.TICKET,
    }
  }

  if (otherTimer) {
    return {
      timer: otherTimer,
      type: TimerType.OTHER,
    }
  }

  return null
}

/**
 * Custom hook used to abstract all of the logic around rendering components in the timers drawers
 *  This includes a `useEffect` that waits for changes in redux and timer state, then updates timer states `uiState` value
 *  The hook returns an object where each key represents a timer component, and each value is a boolean representing if that component should be rendered
 *  This hook is both connected to redux store via `useSelector` and `TimersContext`
 */
export const useTimersRenderState = (): TimerComponentShowingState => {
  const { dispatch, state } = React.useContext(TimersContext)
  const { submissionData, uiState } = state

  const activeCleanTime = useSelector(getActiveCleanTime)
  const activeOtherTime = useSelector(getActiveOtherTimer)
  const activeTicketTime = useSelector(getActiveTicketTime)

  // if we have any lingering un-submitted timers (excluding offline timers),
  // we need to go directly to the submit page for that timer
  const submittableTimerData = useFindSubmittableTimer()
  if (submittableTimerData && !submissionData) {
    dispatch(setSubmissionData(submittableTimerData))
  }

  const isOnline = useOnlineStatus().isOnline()
  const { offlineTimersCount } = useOfflineMergedTimers()

  /**
   * Memoized logic to determine the displayed Timer Components
   * This is returned from the hook
   */
  const componentRenderMap: TimerComponentShowingState = React.useMemo(() => {
    // NOTE: these values are all boolean flags; not references to actual timers

    const activeOtherTimer = uiState === UiState.Active && !!activeOtherTime
    const activeCleanTimer = uiState === UiState.Active && !!activeCleanTime
    const activeTicketTimer = uiState === UiState.Active && !!activeTicketTime

    const showOtherTimers =
      uiState === UiState.Paused || uiState === UiState.Idle

    const pausedTimers = uiState === UiState.Paused && !submissionData
    const hasSubmitTimers = uiState === UiState.Submitting && !!submissionData

    return {
      activeCleanTimer,
      activeOtherTimer,
      activeTicketTimer,
      hasSubmitTimers,
      pausedTimers,
      showOtherTimers,
    }
  }, [
    activeCleanTime,
    activeOtherTime,
    activeTicketTime,
    submissionData,
    uiState,
  ])

  /**
   * Updates the current UI state based on changes in timer states
   */
  React.useEffect(() => {
    const hasSubmittableTimer = !!submissionData

    const hasActiveTimer =
      !!activeCleanTime || !!activeOtherTime || !!activeTicketTime

    const hasPausedTimer = isOnline && offlineTimersCount > 0

    if (hasSubmittableTimer) {
      dispatch(setUiState(UiState.Submitting))
    } else if (hasActiveTimer) {
      dispatch(setUiState(UiState.Active))
    } else if (hasPausedTimer) {
      dispatch(setUiState(UiState.Paused))
    } else {
      dispatch(setUiState(UiState.Idle))
    }
  }, [
    activeCleanTime,
    activeOtherTime,
    activeTicketTime,
    dispatch,
    isOnline,
    offlineTimersCount,
    submissionData,
  ])

  return componentRenderMap
}
