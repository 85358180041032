import { Clean } from 'app/fieldapp/store/cleans'
import { getHkByUserId } from 'app/fieldapp/store/housekeepers/selectors'
import { ApplicationState } from 'app/fieldapp/store/store'
import { getActiveUser } from 'app/fieldapp/store/users/selectors'

import { Assignment } from '../assignments.types'

/**
 * Attempts to find and return the Assignment associated with the provided Clean
 * and the currently logged in user.
 *
 * Note that this selector is slightly special, in that the Clean it expects
 * is not a RawClean from Redux, but a normalized "UI Clean." This is because
 * this selector is generally intended to be used by components that already
 * have a "selected" Clean, but need to find the Assignment for the current user.
 *
 * Also note that it is intentionally NOT memoized because the clean passed in
 * is not a part of Redux state
 */
export const getActiveUserAssignmentByClean = (
  state: ApplicationState,
  clean: Clean,
): Assignment | undefined => {
  const user = getActiveUser(state)
  const hk = user && getHkByUserId(state, user.id)

  const assignmentHasHkAssigned = (assignment: Assignment) =>
    assignment.housekeeper?.id === hk?.id

  return hk && clean.assignments.find(assignmentHasHkAssigned)
}
