import { produce } from 'immer'
import { ActionType, getType } from 'typesafe-actions'

import { TimerType } from 'packages/grimoire/src/utils/timers.types'
import { getIdAndDataTypeFromAction } from 'packages/utils/store'

import { makeOfflineTimer } from 'app/fieldapp/components/timers/timers.helpers'
import {
  emptyNormalizedTicketTimeData,
  RawTicketTime,
  TicketTimesState,
} from 'app/fieldapp/store/ticket-times'

import {
  createTicketTimeOfflineAction,
  createFinalizedTicketTimeAction,
  createTicketTimeAction,
  deleteTicketTimeAction,
  fetchActiveTicketTimesForUserAction,
  fetchTicketTimeByIdAction,
  reloadOfflineTicketTimesAction,
  removeLocalTicketTimeAction,
  updateTicketTimeAction,
  updateTicketTimeOfflineAction,
} from './actions'

const initialState: TicketTimesState = {
  data: {},
  offlineData: {},
  requestPending: false,
}

const actions = {
  createFinalizedTicketTimeAction,
  createTicketTimeAction,
  createTicketTimeOfflineAction,
  deleteTicketTimeAction,
  fetchActiveTicketTimesForUserAction,
  fetchTicketTimeByIdAction,
  reloadOfflineTicketTimesAction,
  removeLocalTicketTimeAction,
  updateTicketTimeAction,
  updateTicketTimeOfflineAction,
}

type TicketTimeActionsTypes = ActionType<typeof actions>

export const ticketTimesReducer = (
  state = initialState,
  action: TicketTimeActionsTypes,
): TicketTimesState =>
  produce(state, (draft: TicketTimesState) => {
    switch (action.type) {
      case getType(updateTicketTimeAction.request):
      case getType(fetchActiveTicketTimesForUserAction.request):
      case getType(createTicketTimeAction.request):
      case getType(deleteTicketTimeAction.request):
      case getType(fetchTicketTimeByIdAction.request):
      case getType(createFinalizedTicketTimeAction.request): {
        draft.requestPending = true
        return
      }

      case getType(createFinalizedTicketTimeAction.success): {
        /* If localId exists, we're working with a timer started offline,
        if we're here, we've successfully posted a timer with a real id, so we 
        need to delete the local timer
        */
        const localId = action.payload.localId
        /** We only need to delete from offlineData because timers started offline won't touch the data section */
        delete draft.offlineData[localId]

        return
      }

      case getType(updateTicketTimeAction.success): {
        const id = Object.keys(action.payload.normalized.ticketTime)[0]
        delete draft.offlineData[id]
        return
      }

      case getType(fetchActiveTicketTimesForUserAction.success): {
        const normalized =
          action?.payload.normalized || emptyNormalizedTicketTimeData

        draft.data = normalized.ticketTime || {}
        draft.requestPending = false
        return
      }

      case getType(fetchTicketTimeByIdAction.success): {
        const [id, ticketTime] = getIdAndDataTypeFromAction<RawTicketTime>(
          action,
          'ticketTime',
        )

        draft.data[id] = ticketTime as RawTicketTime
        draft.requestPending = false
        return
      }

      case getType(removeLocalTicketTimeAction):
      case getType(deleteTicketTimeAction.success): {
        const { id } = action.payload

        delete draft.data[id]
        delete draft.offlineData[id]
        draft.requestPending = false
        return
      }

      case getType(deleteTicketTimeAction.failure):
      case getType(updateTicketTimeAction.failure):
      case getType(fetchTicketTimeByIdAction.failure):
      case getType(createTicketTimeAction.failure):
      case getType(createFinalizedTicketTimeAction.failure):
      case getType(fetchActiveTicketTimesForUserAction.failure): {
        draft.requestPending = false
        return
      }

      /**************************************************
       * Offline TicketTimes Handling
       **************************************************/
      case getType(createTicketTimeOfflineAction.success):
      case getType(updateTicketTimeOfflineAction.success): {
        const { data } = action.payload
        const { id } = data

        draft.offlineData[id] = makeOfflineTimer<TimerType.TICKET>(data)

        draft.requestPending = false
        return
      }

      case getType(reloadOfflineTicketTimesAction.success): {
        draft.offlineData = action.payload || {}
        return
      }
    }
  })
