import { createAsyncAction, deprecated } from 'typesafe-actions'

import { RequestConfig } from 'packages/utils/store'

import { offlineTimers } from 'app/fieldapp/store/utils'

import { cleanTimesService } from '../cleantimes.service'
import {
  NormalizedCleanTimesApiResponse,
  CleanTimesActionTypes,
} from '../cleantimes.types'

const { createStandardAction } = deprecated

export const removeLocalCleanTimeAction = createStandardAction(
  CleanTimesActionTypes.REMOVE_LOCAL_CLEAN_TIME,
)<{ id: string }>()

export const silentDeleteCleanTimeAction = createAsyncAction(
  CleanTimesActionTypes.SILENT_DELETE_CLEAN_TIME,
  CleanTimesActionTypes.SILENT_DELETE_CLEAN_TIME_SUCCESS,
  CleanTimesActionTypes.SILENT_DELETE_CLEAN_TIME_FAILURE,
)<RequestConfig<NormalizedCleanTimesApiResponse>, { id: string }, Error>()

/**
 * Silent/background clean time deletion action. This is intended for use when we
 * want to TRY to delete one or more clean times, but we don't really care if it succeeds.
 *
 * This is almost the same as the regular `deleteCleanTime` action, with a couple exceptions:
 * - CleanTimers are removed from the local store BEFORE we send the request,
 *     so that we don't have to wait for the API request to make UI updates
 * - No "success" action is dispatched
 *
 * Note that error/failure action IS dispatched so that we can e.g. log it to Sentry, but for
 * any component using this action, it should be considered a non-critical error.
 */
export const silentDeleteCleanTime =
  (cleanTimeId: string) => async dispatch => {
    try {
      await offlineTimers.removeCleanTime(cleanTimeId)
      dispatch(removeLocalCleanTimeAction({ id: cleanTimeId }))

      const request = cleanTimesService.deleteCleanTime.bind(null, cleanTimeId)
      const result = await dispatch(
        silentDeleteCleanTimeAction.request({ request }),
      )

      return result.normalized
    } catch (error) {
      dispatch(silentDeleteCleanTimeAction.failure(error))
      throw error
    }
  }
