import { createSelector } from 'reselect'

import { ApplicationState } from '../../store'
import { getVisitById } from '../../visits/selectors'
import { Housekeeper } from '../housekeepers.types'
import { getHkById } from './getHkById'

export const getLastCleanCompletedHksByVisitId = createSelector(
  (state: ApplicationState) => state,
  (_, visitId): string => visitId,
  (state, visitId): (Housekeeper | undefined)[] => {
    const visit = getVisitById(state, visitId)
    const hks = visit?.lastCleanCompletedHkIds.map(hkId =>
      getHkById(state, hkId),
    )

    return hks || []
  },
)
