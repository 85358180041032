import * as React from 'react'
import { usePrevious } from 'react-use'

import { useOnlineStatus } from 'packages/utils/hooks'

import { OfflineNotifier, OnlineStatus } from './OfflineNotifier'

const getOnlineStatus = (
  isOnline: boolean,
  prevIsOnline?: boolean,
): OnlineStatus => {
  if (!isOnline) return 'offline'
  return prevIsOnline === false ? 'backOnline' : 'online'
}

export const OfflineNotifierContainer: React.FC = () => {
  const isOnline = useOnlineStatus().isOnline()
  const prevIsOnline = usePrevious(isOnline)
  const onlineStatus: OnlineStatus = getOnlineStatus(isOnline, prevIsOnline)

  return <OfflineNotifier onlineStatus={onlineStatus} />
}
