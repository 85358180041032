/**********************************************************
 * CONTACT
 *********************************************************/

export type AllContactAttributes = {
  address1?: string
  address2?: string
  city?: string
  email?: string
  firstName?: string
  id: string
  isActive: boolean
  lastName?: string
  phone?: string
  phoneNotes?: string
  unitIds?: string[]
  zipCode?: string
}

export const ContactAttributeNames = [
  'id',
  'phone',
  'phoneNotes',
  'email',
  'firstName',
  'lastName',
  'address1',
  'address2',
  'city',
  'zipCode',
  'isActive',
  'unitIds',
]
