import { produce } from 'immer'
import { ActionType, getType } from 'typesafe-actions'

import { fetchTicketsAction } from '../tickets/actions'
import { CommentsState } from './comments.types'

const actions = {
  fetchTicketsAction,
}

type CommentsActionsTypes = ActionType<typeof actions>

export const initialState: CommentsState = {
  data: {},
}

export const commentsReducer = (
  state = initialState,
  action: CommentsActionsTypes,
): CommentsState =>
  produce(state, (draft: CommentsState) => {
    switch (action.type) {
      case getType(fetchTicketsAction.success): {
        draft.data = {
          ...draft.data,
          ...(action.payload.normalized?.comment || {}),
        }

        return
      }
    }
  })
