import styled from '@emotion/styled'
import { cond, negate, prop } from 'lodash/fp'
import upperCase from 'lodash/upperCase'
import * as React from 'react'

import { colors } from 'packages/styles'

import { useI18n, Slugs } from 'app/fieldapp/i18n'
import { Clean } from 'app/fieldapp/store/cleans'

type CleanStatus = 'notStarted' | 'inProgress' | 'complete'
type CleanStatusMap = {
  color: string
  slug: string
}

const cleanStatusMap: { [key in CleanStatus]: CleanStatusMap } = {
  complete: {
    color: colors.success,
    slug: Slugs.cleanComplete,
  },
  inProgress: {
    color: colors.dusk70,
    slug: Slugs.cleanInProgress,
  },
  notStarted: {
    color: colors.dusk70,
    slug: Slugs.cleanNotStarted,
  },
}

const St = {
  CleanStatusText: styled.div<{ color: string }>`
    color: ${({ color }) => color};
    font-weight: bold;
    font-size: 16px;
  `,
}

export type CleanStatusDisplayProps = {
  clean: Clean
}

export const CleanStatusDisplay: React.FC<CleanStatusDisplayProps> = React.memo(
  ({ clean }) => {
    const { t } = useI18n()

    const cleanStatus: CleanStatus = cond([
      [negate(prop('startedAt')), () => 'notStarted'],
      [negate(prop('completedAt')), () => 'inProgress'],
      [prop('completedAt'), () => 'complete'],
    ])(clean) as CleanStatus

    return (
      <St.CleanStatusText color={cleanStatusMap[cleanStatus].color}>
        {upperCase(t(cleanStatusMap[cleanStatus].slug))}
      </St.CleanStatusText>
    )
  },
)
