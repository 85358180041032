import { logInfo } from 'packages/wiretap/logging'

const DELETE_TOKEN_URL = `${process.env.REACT_APP_SERVICE_HOST}/delegation_token`
const SERVICE_URL = process.env.REACT_APP_SERVICE_HOST

type DeleteTokenArgs = {
  idToken: string
  resourcePath: string
}

export const fetchDelegateToken = async ({
  idToken,
  resourcePath,
}: DeleteTokenArgs): Promise<string> => {
  try {
    const result = await fetch(DELETE_TOKEN_URL, {
      body:
        'grant_type=urn:ietf:params:oauth:grant-type:token-exchange&' +
        `resource=${SERVICE_URL}/${resourcePath}&` +
        'scope=fieldops-jobs:read fieldops-jobs:start&' +
        `subject_token=${idToken}&` +
        'subject_token_type=urn:ietf:params:oauth:token-type:access_token',

      headers: {
        Authorization: `Bearer ${idToken}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },

      method: 'POST',
    }).then(res => {
      if (res.ok) {
        return res.json()
      }

      // errors here will not throw from fetch, so we need to manually parse/await and re-throw
      res.json().then(json => {
        logInfo('Error fetching delegate token', {
          error: json?.error || 'unknown',
        })
      })

      throw new Error('Error fetching delegate token')
    })

    const { access_token: delegateToken } = result
    return delegateToken
  } catch (err) {
    throw err
  }
}
