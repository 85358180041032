import { createSelector } from 'reselect'

import { ApplicationState } from 'app/fieldapp/store/store'

import { Ticket } from '../tickets.types'
import { hydrateRawTicket } from '../tickets.utils'

export const getTicketById = createSelector(
  (state: ApplicationState) => state.tickets,
  (_, id: string) => id,
  (ticketsState, id): Ticket | undefined => {
    const rawTicket = ticketsState?.data?.[id]
    const backupUnitId = ticketsState.ticketToUnitMap[id]
    return rawTicket && hydrateRawTicket(rawTicket, backupUnitId)
  },
)
