import { User } from 'packages/grimoire'
import { DayOfWeek } from 'packages/grimoire/src/utils'
import {
  startOfDay,
  createDateObject,
  format,
  isAfter,
  isBefore,
  toZonedTime,
  DateFormat,
} from 'packages/utils/dateHelpers'
import {
  parseTimestampTo24HourIndex,
  twentyFourHourMap,
} from 'packages/utils/timestamp'
import { getSystemTZ } from 'packages/utils/timezone.helpers'

import { CrossCoverage } from 'app/fieldapp/store/crossCoverage/crossCoverage.types'

import { TimeOption } from '../components/TimeSelect'
import { CrossCoverageFormState } from './CrossCoverageForm.reducer'

//TODO: TPD-6418: Add tests transformCrossCoverageToFormState

/**
 * Takes a standard `CrossCoverage` Record and returns `CrossCoverageFormState` to populate the CrossCoverageForm.
 * This is currently used to populate the `CrossCoverageForm` when editing an existing `CrossCoverage,
 * however it could also be used in an offline flow similar to timers
 */
export const transformCrossCoverageToFormState = ({
  assignee,
  crossCoverage,
  standardPartnerId,
  systemAssignedUserId,
}: {
  assignee: User
  crossCoverage?: CrossCoverage | null
  standardPartnerId?: string
  systemAssignedUserId?: string
}): CrossCoverageFormState | undefined => {
  if (!crossCoverage) return undefined

  const zonedStartDate = toZonedTime(
    crossCoverage.startDateTimeUTC,
    getSystemTZ(),
  )
  const zonedEndDate = toZonedTime(crossCoverage.endDateTimeUTC, getSystemTZ())

  const endTime = parseTimestampTo24HourIndex(
    format(zonedEndDate, DateFormat.ApiTimestamp),
  )
  const startTime = parseTimestampTo24HourIndex(
    format(zonedStartDate, DateFormat.ApiTimestamp),
  )

  const endDate = format(zonedEndDate, DateFormat.ApiUtcShort)
  const startDate = format(zonedStartDate, DateFormat.ApiUtcShort)

  const endTimeOption: TimeOption = {
    hourOfDay: endTime.hourIndex24 * 100 + endTime.minuteIndex,
    timeOfDay:
      twentyFourHourMap[endTime.hourIndex24 * 100 + endTime.minuteIndex],
  }

  const startTimeOption: TimeOption = {
    hourOfDay: startTime.hourIndex24 * 100 + startTime.minuteIndex,
    timeOfDay:
      twentyFourHourMap[startTime.hourIndex24 * 100 + startTime.minuteIndex],
  }

  const repeatDays = [
    crossCoverage.monday ? 'monday' : undefined,
    crossCoverage.tuesday ? 'tuesday' : undefined,
    crossCoverage.wednesday ? 'wednesday' : undefined,
    crossCoverage.thursday ? 'thursday' : undefined,
    crossCoverage.friday ? 'friday' : undefined,
    crossCoverage.saturday ? 'saturday' : undefined,
    crossCoverage.sunday ? 'sunday' : undefined,
  ].filter(Boolean) as unknown as DayOfWeek[]

  const getIsEditable = () => {
    const TODAY = createDateObject()

    if (isAfter(startOfDay(startDate), startOfDay(TODAY))) {
      return true
    }

    if (isBefore(startOfDay(startDate), startOfDay(TODAY))) {
      return false
    }

    const hourOfDay = TODAY.getHours() * 100
    const minuteOfDay = TODAY.getMinutes()

    const currentTimeStamp = hourOfDay + minuteOfDay
    const startTimeStamp = startTime.hourIndex24 * 100 + startTime.minuteIndex

    if (startTimeStamp < currentTimeStamp) return false
    return true
  }

  const state: CrossCoverageFormState = {
    crossCoverageId: crossCoverage.id,
    endDate,
    endTime: endTimeOption,
    formValidationError: undefined,
    isAssigneeBeingOverridden: assignee.id !== standardPartnerId,
    isEditable: getIsEditable(),
    isRepeating: crossCoverage.isRepeating,
    repeatDays,
    selectedAssignee: assignee,
    startDate,
    startTime: startTimeOption,
    systemAssignedUserId,
  }

  return state
}
