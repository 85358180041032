import styled from '@emotion/styled'
import * as React from 'react'

import { IconName, IconTextPosition, SvgIcon } from 'packages/iconic'
import { colors } from 'packages/styles/jss'

import { Slugs, useI18n } from 'app/fieldapp/i18n'

export const MAPS_URL_BASE = 'https://maps.apple.com/?daddr='

const Container = styled.div`
  color: ${colors.lake};
  display: flex;
  margin-top: 8px;
`

type MapsLinkProps = {
  address: string
  city: string
  state: string
}

export const MapsLink: React.FC<MapsLinkProps> = ({ address, city, state }) => {
  const { t } = useI18n()

  /**
   * Attempts to open a link to the native Maps app based on the current OS.
   * Note that, in order to prevent freezing on iOS, the opening behavior
   * will be slightly different depending on whether we are running PWA mode.
   */
  const onViewMapClick = React.useCallback(() => {
    // Available on Apple's iOS Safari only.
    const isPWA = navigator?.['standalone']
    const target = isPWA ? '_top' : '_blank'

    window.open(`${MAPS_URL_BASE}${address},${city},${state}`, target)
  }, [address, city, state])

  return (
    <Container>
      <SvgIcon
        centerItems={true}
        dataTestId="viewMapLink"
        icon={IconName.externalLink}
        onClick={onViewMapClick}
        size={16}
        text={t(Slugs.openMap)}
        textFontSize={16}
        textPosition={IconTextPosition.Left}
      />
    </Container>
  )
}
