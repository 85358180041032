import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Button } from 'packages/common'
import { SvgIcon } from 'packages/iconic'
import { colors } from 'packages/styles'

import {
  TaskIcon,
  VisitHeaderLeftIcons,
  ScheduledVisitIcon,
} from '../TaskSharedStyles'
import { TaskCardHeader } from '../TaskSharedStyles/TaskCard.styles'

export { TaskIcon, VisitHeaderLeftIcons, ScheduledVisitIcon }
export {
  TaskCardContainer,
  TaskCardContent,
  UnitAddress,
  UnitCode,
  UnitName,
} from '../TaskSharedStyles/TaskCard.styles'

export const VisitHeader = styled(TaskCardHeader)`
  background-color: ${colors.tetons30};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  justify-content: space-between;
  line-height: 24px;
  padding-right: 0;

  span {
    padding-top: 1px; // vertical alignment with icon
  }
`

//------------------------------------------------
// Grid Area
//------------------------------------------------
// We need this content to be in a grid to ensure all can text flow around the button/icon

export const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: repeat(2, auto);
`

export const UnitStatusIcon = styled(TaskIcon)`
  grid-column: 1/3;
  grid-row: 1;
  padding-bottom: 8px;

  svg {
    color: ${colors.alert};
  }
`

const statusIconOrButton = css`
  align-self: flex-end;
  grid-column: 2;
  grid-row: 2;
`

export const CompleteIcon = styled(SvgIcon)`
  ${statusIconOrButton};
  color: ${colors.success};
`

export const UpdateVisitButton = styled(Button)`
  ${statusIconOrButton};
`

export const StarIcon = styled(SvgIcon)`
  color: ${colors.dusk};
`

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
