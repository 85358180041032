import { AxiosResponse } from 'axios'

import { AllContactAttributes } from 'packages/grimoire/src/contact/contact.types'
import {
  JSONApiObjectWithRelationships,
  JSONApiObjectWithRelationshipsMap,
  NormalizedJSONApiResponse,
  ToManyRelationship,
} from 'packages/utils/store'

/**********************************************************
 * SERVICE TYPES
 *********************************************************/
export type ContactResponse = {
  contact: JSONApiContactsMap
}

export type NormalizedContactsApiResponse =
  NormalizedJSONApiResponse<ContactResponse>

export type ContactsApiResponse = AxiosResponse<NormalizedContactsApiResponse>
export type ContactsServiceResponse = Promise<NormalizedContactsApiResponse>

/**********************************************************
 * REDUX TYPES
 *********************************************************/
export enum ContactsActionTypes {
  FETCH_CONTACTS_BY_UNIT_ID = 'CONTACTS_BY_UNIT_ID/API/FETCHING_CONTACTS_BY_UNIT_ID',
  FETCH_CONTACTS_BY_UNIT_ID_FAILURE = 'CONTACTS_BY_UNIT_ID/FETCH_CONTACTS_BY_UNIT_ID_FAILURE',
  FETCH_CONTACTS_BY_UNIT_ID_SUCCESS = 'CONTACTS_BY_UNIT_ID/FETCH_CONTACTS_BY_UNIT_ID_SUCCESS',
}

export type ContactsState = {
  data: JSONApiContactsMap
}

/**********************************************************
 * Contact
 *********************************************************/

export type ContactRelationships = {
  ownerContracts: ToManyRelationship
  users: ToManyRelationship
}

export type JSONApiContactsMap = JSONApiObjectWithRelationshipsMap<
  AllContactAttributes,
  ContactRelationships
>

export type RawContact = JSONApiObjectWithRelationships<
  AllContactAttributes,
  ContactRelationships
>
export type Contact = AllContactAttributes
