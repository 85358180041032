import * as React from 'react'
import { useSelector } from 'react-redux'

import { AsyncSelector } from 'packages/common'
import { logError } from 'packages/wiretap/logging'

import { useAppDispatch } from 'app/fieldapp/store/hooks'
import { searchUsers } from 'app/fieldapp/store/users/actions'
import {
  getActiveUser,
  getUsersSearchResults,
} from 'app/fieldapp/store/users/selectors'
import { User, RawUser } from 'app/fieldapp/store/users/users.types'
import { hydrateRawUser } from 'app/fieldapp/store/users/users.utils'

type AsyncUserSelectorProps = {
  className?: string
  onUserSelectionChange: (value: User) => void
}

const AsyncUserSelector: React.FC<AsyncUserSelectorProps> = ({
  className,
  onUserSelectionChange,
}) => {
  const dispatch = useAppDispatch()
  const selectedUser = useSelector(getActiveUser)
  const getUsersResults = useSelector(getUsersSearchResults)

  // The following line is used to refer to the current version of the getResults selector within load options
  const getResultsRef = React.useRef(getUsersResults)
  getResultsRef.current = getUsersResults

  const loadOptions = async (
    input: string,
    callback: (arg: unknown) => void,
  ) => {
    if (input.length >= 3) {
      try {
        const normalizedResult = await dispatch(searchUsers(input))
        const usersArray = Object.values(normalizedResult.user) as RawUser[]
        const users = usersArray.map(hydrateRawUser)
        callback(users)
      } catch (error) {
        logError(error)
        callback([])
      }
    } else {
      callback([])
    }
  }

  const noOptionsMessage = React.useCallback(
    ({ inputValue }) => (inputValue === '' ? 'Type to search' : 'No results'),
    [],
  )

  return (
    <AsyncSelector
      className={className}
      defaultOptions={[]}
      isDisabled={false}
      getOptionLabel={(user: User) => `${user.firstName} ${user.lastName}`}
      getOptionValue={(user: User) => user.id}
      loadingMessage={() => 'Loading...'}
      loadOptions={loadOptions}
      noOptionsMessage={noOptionsMessage}
      onChange={onUserSelectionChange}
      placeholder={'Select...'}
      value={selectedUser}
    />
  )
}

export default AsyncUserSelector
