import React from 'react'
import { useSelector } from 'react-redux'

import { ModalConfig, useModalContext } from 'packages/common'
import { createDateObject } from 'packages/utils/dateHelpers'
import { useOnlineStatus } from 'packages/utils/hooks'

import { useUpdateVisit } from 'app/fieldapp/components/schedule/hooks'
import { getHasActiveCleanTimeForTask } from 'app/fieldapp/store/cleantimes/selectors/getHasActiveCleanTimeForTask'
import { useAppSelector } from 'app/fieldapp/store/hooks'
import { ApplicationState } from 'app/fieldapp/store/store'
import { getActiveTicketTimerByVisitId } from 'app/fieldapp/store/ticket-times/selectors'
import { getVisitDrawerState } from 'app/fieldapp/store/ui/selectors'
import {
  getVisitById,
  getVisitsLoadingState,
} from 'app/fieldapp/store/visits/selectors'
import { getAllTicketsCompleteForVisit } from 'app/fieldapp/store/visits/selectors/getAllTicketsCompleteForVisit'

import { IncompleteTasksModal } from '../../../IncompleteTasksModal'
import { VisitStatusBanner } from './VisitStatusBanner'

export const VisitStatusBannerContainer: React.FC = React.memo(() => {
  const { visitId } = useAppSelector(getVisitDrawerState)

  const visit = useAppSelector((state: ApplicationState) =>
    getVisitById(state, visitId),
  )
  const isLoadingAnyVisit = useAppSelector(getVisitsLoadingState)

  const [updateVisitState, updateVisitFn] = useUpdateVisit()

  const { showModal } = useModalContext()

  const visitTicketsComplete = useAppSelector((state: ApplicationState) =>
    getAllTicketsCompleteForVisit(state, visit?.id || ''),
  )

  const updateVisit = React.useCallback(() => {
    const completedAt = visit?.startedAt ? createDateObject() : undefined
    const startedAt = visit?.startedAt ? undefined : createDateObject()

    updateVisitFn({
      completedAt,
      id: visit?.id || '',
      startedAt,
    })
  }, [updateVisitFn, visit])

  const modalConfig: ModalConfig = {
    childRenderer: ({ beginClose }) => (
      <IncompleteTasksModal
        beginClose={beginClose}
        disabled={!visit?.timersSubmitted}
        loading={updateVisitState.loading}
        incompleteTickets={!visitTicketsComplete}
        incompleteInspection={!visit?.inspectionCompletedAt}
        updateVisit={updateVisit}
      />
    ),
  }

  const handleUpdateVisit = React.useCallback(
    modalConfig => {
      visit?.startedAt ? showModal(modalConfig) : updateVisit()
    },
    [showModal, updateVisit, visit],
  )

  const hasActiveCleanTimer = useSelector((state: ApplicationState) =>
    getHasActiveCleanTimeForTask(state, visit?.id || ''),
  )

  const activeTicketTimer = useSelector((state: ApplicationState) =>
    getActiveTicketTimerByVisitId(state, visit?.id),
  )

  const isOnline = useOnlineStatus().isOnline()

  const canUpdateVisit =
    isOnline && !isLoadingAnyVisit && !hasActiveCleanTimer && !activeTicketTimer

  return (
    <VisitStatusBanner
      canUpdateVisit={canUpdateVisit}
      onUpdateVisit={() => handleUpdateVisit(modalConfig)}
      requestState={updateVisitState}
      visit={visit}
    />
  )
})
