import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { SvgIcon } from 'packages/iconic'
import {
  centerWithFlex,
  colors,
  text,
  truncateTextWithEllipsis,
} from 'packages/styles'

import { MapsLink } from 'app/fieldapp/components/core'

export const InfoRowLabel = styled.span`
  ${text.bodyBoldSmall};
`

export const Details = styled.div`
  padding-left: 8px;
  width: 100%;
`

export const DetailIcon = styled(SvgIcon)`
  color: ${colors.midnight70};
`

export const UnitInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
  padding-left: 8px;
`

export const UnitInfoSection = styled.div`
  margin: 24px 16px 24px 24px;
`

export const UnitName = styled.div`
  ${text.bodyBold};
  color: ${colors.dusk};
`

export const BasicText = styled.div`
  ${text.bodyRegularSmall};
  color: ${colors.dusk};
`

export const TaskIcon = styled(SvgIcon)`
  &,
  span {
    ${truncateTextWithEllipsis};
  }

  svg {
    flex-shrink: 0;
  }
`

export const LinkToMaps = styled(MapsLink)`
  margin-top: 12px;
  margin-bottom: 24px;
`

export const TaskDetailContainer = styled.div`
  background: white;
  font-size: 14px;
  overflow-y: auto;
  padding-bottom: 64px;
  padding-top: 16px;
  position: relative;
`

export const TaskHeader = styled.div`
  align-items: center;
  display: flex;
  height: 36px;
`

export const VisitDetailHeader = styled(TaskHeader)`
  ${text.headingLabel};
  background-color: ${colors.tetons30};
  justify-content: space-between;
  line-height: 24px;
  padding: 0;
  padding-left: 24px;

  span {
    padding-top: 1px; // vertical alignment with icon
  }
`

export const TaskDetailWrapper = styled.div`
  padding: 16px;
`

const sectionBorder = css`
  border-top: 1px solid ${colors.midnight20};
  margin: 0 -16px;
  padding: 24px;
  padding-bottom: 0;
`

export const BottomDivider = styled.div`
  ${sectionBorder};
`

export const SectionSubTitle = styled.div`
  ${text.headingLabel};
  color: ${colors.midnight};
  margin-bottom: 8px;
`
export const VisitHeaderLeftIcons = styled.div`
  display: flex;
  justify-content: space-between;
`
export const ScheduledVisitIcon = styled(TaskIcon)`
  ${centerWithFlex};

  align-items: center;
  background-color: #ecf0f0;
  border-radius: 4px;
  border: 1px solid #ced9d9;
  height: 24px;
  margin-left: auto;
  margin-right: 8px;
  padding: 6px;
  width: 26px;
`
