import { isEqual } from 'lodash'
import React from 'react'

import {
  Alert,
  Button,
  FormHandlersOptional,
  FormProps,
  FormStyles,
  Loader,
} from 'packages/common'
import { getDeepCleanPredictedTime } from 'packages/grimoire'
import { IconName, SvgIcon } from 'packages/iconic'
import { colors } from 'packages/styles'
import { convertHoursToTimeString } from 'packages/utils/mathHelpers'

import { Slugs, useI18n } from 'app/fieldapp/i18n'
import {
  ComputedCleanJobType,
  computedCleanJobTypeValues,
} from 'app/fieldapp/store/cleans'

import { Divider } from '../CleanDetail.styles'
import {
  ButtonSection,
  CleanJobOptionStyles,
  CleanTypeHeading,
  CleanTypeRadioButtons,
  WarningSection,
  WarningSectionList,
} from './CleanJobTypeEditor.styles'

const St = {
  ButtonSection,
  CleanJobOptionStyles,
  CleanTypeHeading,
  CleanTypeRadioButtons,
  Form: FormStyles.Form,
  TextArea: FormStyles.TextArea,
  TextAreaLabel: FormStyles.TextAreaLabel,
  WarningSection,
  WarningSectionList,
}

const useTranslations = () => {
  const { ut } = useI18n()
  return {
    cancel: ut(Slugs.cancel),
    cleanType: ut(Slugs.cleanType),
    deep: ut(Slugs.deep),
    deepCleanDueBy: ut(Slugs.deepCleanDue),
    deepCleanRevertEffect1: ut(Slugs.deepCleanRevertEffect1),
    deepCleanRevertEffect2: ut(Slugs.deepCleanRevertEffect2),
    deepCleanRevertEffect3: ut(Slugs.deepCleanRevertEffect3),
    irreversibleChangeWarningDescription: ut(Slugs.savingAsPostStayDescription),
    irreversibleChangeWarningTitle: ut(Slugs.irreversibleChangeWarning),
    oopsChangesNotSaved: ut(Slugs.oopsChangesNotSaved),
    reasonLabel: ut(Slugs.reasonForEdit),
    save: ut(Slugs.save),
  }
}

type GroupedCleanTimes = {
  deep: number
  post: number
}

export type JobTypeUIConfig = {
  color: string
  icon: IconName
  slug: string
}

export const jobTypeConfigMap: {
  [key in ComputedCleanJobType]: JobTypeUIConfig
} = {
  deep: {
    color: colors.sand,
    icon: IconName.doorClose,
    slug: Slugs.deep,
  },
  post: {
    color: colors.gulf20,
    icon: IconName.bucketClean,
    slug: Slugs.postStay,
  },
}

const CleanJobOption: React.FC<{
  job: ComputedCleanJobType
  times: GroupedCleanTimes
}> = React.memo(({ job, times }) => {
  const { t } = useI18n()

  return (
    <>
      <St.CleanJobOptionStyles.Label job={job}>
        <SvgIcon icon={jobTypeConfigMap[job].icon} size={16} />
        {t(jobTypeConfigMap[job].slug)}
      </St.CleanJobOptionStyles.Label>

      {times[job] && (
        <St.CleanJobOptionStyles.PredictedTime>
          {convertHoursToTimeString(times[job])}
        </St.CleanJobOptionStyles.PredictedTime>
      )}
    </>
  )
})

type RadioOption = { name: 'cleanJobType'; value: ComputedCleanJobType }
const radioOptions: RadioOption[] = computedCleanJobTypeValues.map(value => ({
  name: 'cleanJobType',
  value,
}))

export type CleanJobTypeEditorFormValues = {
  cleanJobType: ComputedCleanJobType
  reason: string
}

type CleanJobTypeEditorFormProps = FormProps<CleanJobTypeEditorFormValues> & {
  handlers: Pick<FormHandlersOptional, 'changeRadio'>
}

export type CleanJobTypeEditorProps = {
  predictedCleanTime: number
} & CleanJobTypeEditorFormProps

export const CleanJobTypeEditor: React.FC<CleanJobTypeEditorProps> = React.memo(
  ({ formState, handlers, predictedCleanTime, requestState }) => {
    const strings = useTranslations()

    const getOptionComponent = React.useCallback(
      (option: RadioOption) => (
        <CleanJobOption
          job={option.value}
          times={{
            deep: getDeepCleanPredictedTime({ predictedCleanTime }),
            post: predictedCleanTime,
          }}
        />
      ),
      [predictedCleanTime],
    )

    const selectedValue = React.useMemo(
      () =>
        radioOptions.find(opt => opt.value === formState.values.cleanJobType),
      [formState.values.cleanJobType],
    )

    const showWarningAndReasonSectionForEditCleans =
      formState.values.cleanJobType === 'post'

    return (
      <div>
        <St.Form onSubmit={handlers.submit}>
          <St.CleanTypeHeading>{strings.cleanType}</St.CleanTypeHeading>
          <St.CleanTypeRadioButtons
            onChange={handlers.changeRadio}
            options={radioOptions}
            getOptionLabel={getOptionComponent}
            selectedValue={selectedValue}
            valueComparator={isEqual}
          />

          {showWarningAndReasonSectionForEditCleans && (
            <>
              <St.WarningSection>
                <strong>{strings.irreversibleChangeWarningTitle}</strong>
                <St.WarningSectionList>
                  <li>
                    <span>{strings.deepCleanRevertEffect1}</span>
                  </li>
                  <li>
                    <span>{strings.deepCleanRevertEffect2}</span>
                  </li>
                  <li>
                    <span>{strings.deepCleanRevertEffect3}</span>
                  </li>
                </St.WarningSectionList>
              </St.WarningSection>
              <St.TextAreaLabel htmlFor="reason">
                {strings.reasonLabel}
              </St.TextAreaLabel>
              <St.TextArea
                id="reason"
                maxLength={250}
                name="reason"
                onChange={handlers.change}
                rows={5}
                value={formState.values.reason}
              />
            </>
          )}

          {requestState.error && (
            <Alert alertType="danger">{strings.oopsChangesNotSaved}</Alert>
          )}

          <St.ButtonSection>
            <Button
              block={true}
              disabled={requestState.loading}
              onClick={handlers.cancel}
              buttonType={'secondary'}
            >
              {strings.cancel}
            </Button>
            <Button
              block={true}
              disabled={!formState.canSubmit}
              isFormSubmit={true}
              buttonType={'danger'}
            >
              {strings.save}
            </Button>
          </St.ButtonSection>
          {requestState.loading && <Loader />}
        </St.Form>
        <Divider />
      </div>
    )
  },
)
