import { map, pipe, uniq } from 'lodash/fp'

import {
  Coverage,
  CoverageDetailsRegion,
} from 'app/fieldapp/store/coverage/coverage.types'
import { User } from 'app/fieldapp/store/users'

export const commaSeparatedNamesFrom = (
  coverage: Coverage,
  coverageUser?: User,
): string => {
  if (coverage.coverageType !== 'standard_covering') {
    return coverageUser?.firstName || 'Unknown'
  }

  return pipe(
    // Get the first and last name of each covered region
    map(
      (coveredRegion: CoverageDetailsRegion) =>
        `${coveredRegion.firstName} ${coveredRegion.lastName}`,
    ),
    // Remove duplicates
    uniq,
    // Keep only the first name if it's unique
    (names: string[]) =>
      names.reduce<string[]>(
        (previousValue, firstAndLastName, _, firstAndLastNames) => {
          const [firstName, lastName] = firstAndLastName.split(' ')

          // If the array includes more than one entry with the same first
          // name, keep the last name
          if (
            firstAndLastNames.filter(name => name.split(' ')[0] === firstName)
              .length > 1
          ) {
            return [...previousValue, `${firstName} ${lastName}`]
          }

          // Otherwise, just keep the first name
          return [...previousValue, firstName]
        },
        [],
      ),
    // Add 'and' before the last name if there are more than 2 names
    (names: string[]) => {
      if (names.length > 1) {
        names[names.length - 1] = `and ${names[names.length - 1]}`
      }

      return names
    },
    // Join the names without a comma if there are less than 3 names
    // Otherwise, join the names with a comma
    (names: string[]) => {
      if (names.length < 3) {
        return names.join(' ')
      }

      return names.join(', ')
    },
  )(coverage?.details?.coveredRegions || [])
}
