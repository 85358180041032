import React from 'react'

import { TimerMenuConfig, TimerMenuType } from '../OtherTimers.types'
import { useOtherTimerMenuConfig } from './useOtherTimerMenuConfig'

type UseTimersSubMenu = {
  displayedTimerConfig: TimerMenuConfig
  isSubMenuOpen: boolean
  setDisplayedTimerConfigType: (menu: TimerMenuType) => void
}

export const useTimersSubMenu = (): UseTimersSubMenu => {
  const [displayedTimerConfigType, setDisplayedTimerConfigType] =
    React.useState<TimerMenuType>('main')

  const isSubMenuOpen = !(displayedTimerConfigType === 'main')

  const { getMenuById } = useOtherTimerMenuConfig()

  const displayedTimerConfig: TimerMenuConfig = React.useMemo(
    () => getMenuById(displayedTimerConfigType),
    [displayedTimerConfigType, getMenuById],
  )

  return { displayedTimerConfig, isSubMenuOpen, setDisplayedTimerConfigType }
}
