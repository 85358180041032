import styled from '@emotion/styled'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMount } from 'react-use'

import { Alert } from 'packages/common'
import { useI18n } from 'packages/i18n'
import { createDateString } from 'packages/utils/dateHelpers'
import { useAsyncFnWithReset } from 'packages/utils/hooks'

import { Slugs } from 'app/fieldapp/i18n'
import { fetchCoverageByDateRange } from 'app/fieldapp/store/coverage/actions'
import {
  isCoveredCoverageTypes,
  isCoveringCoverageTypes,
} from 'app/fieldapp/store/coverage/coverage.types'
import {
  getCurrentCoverage,
  getCurrentCoverageUser,
} from 'app/fieldapp/store/coverage/selectors'
import { AppDispatch } from 'app/fieldapp/store/store'

import { commaSeparatedNamesFrom } from './CoverageAlert.utils'

const useTranslations = () => {
  const { t } = useI18n()

  return {
    availabilityIncorrect: t(Slugs.availabilityIncorrect),
    coveredBy: t(Slugs.coveredBy),
    coveringFor: t(Slugs.coveringFor),
  }
}

const St = {
  Container: styled.div`
    margin: 1rem;
  `,
}

export enum CoverageAlertTestIds {
  container = 'CoverageAlert__container',
}

export const CoverageAlert: React.FC = React.memo(() => {
  const dispatch: AppDispatch = useDispatch()
  const currentCoverage = useSelector(getCurrentCoverage)
  const currentCoverageUser = useSelector(getCurrentCoverageUser)
  const strings = useTranslations()

  const [_, fetchCoverageByDateRangeFn] = useAsyncFnWithReset(
    async () =>
      dispatch(
        fetchCoverageByDateRange({
          dateRange: [createDateString(), createDateString()],
        }),
      ),
    [dispatch],
  )

  useMount(fetchCoverageByDateRangeFn)

  if (!currentCoverage) {
    return null
  }

  const currentCoverageUserNamesString =
    currentCoverage &&
    commaSeparatedNamesFrom(currentCoverage, currentCoverageUser)

  const { coverageType } = currentCoverage

  const isCovering = Object.values(isCoveringCoverageTypes).includes(
    coverageType,
  )

  const isCovered = Object.values(isCoveredCoverageTypes).includes(coverageType)

  if (
    !(isCovering || isCovered) ||
    currentCoverage.coverageType === 'standard'
  ) {
    return null
  }

  return (
    <St.Container data-testid={CoverageAlertTestIds.container}>
      <Alert alertType="info">
        {isCovering ? strings.coveringFor : strings.coveredBy}{' '}
        {currentCoverageUserNamesString}. {strings.availabilityIncorrect}
      </Alert>
    </St.Container>
  )
})
