import { transformNormalizedToTyped } from 'packages/utils/store'

import {
  TicketTime,
  TicketTimeAttributeNames,
  NormalizedTicketTimesApiResponse,
  RawTicketTime,
} from './ticket-times.types'

/**
 * Empty data in the shape of the TicketTimes service's `normalized` payload.
 * Use this as a fallback when there are issues with the API data being undefined or malformed.
 */
export const emptyNormalizedTicketTimeData = Object.freeze({
  ticketTime: {},
})

/**
 * Empty data to use as a "safety net" any time an API response is undefined for any reason
 */
export const emptyTicketTimesResponse: NormalizedTicketTimesApiResponse =
  Object.freeze({
    normalized: emptyNormalizedTicketTimeData,
    raw: { data: [] },
  })

export const hydrateRawTicketTime = (
  rawTicketTime: RawTicketTime,
): TicketTime => {
  return transformNormalizedToTyped<TicketTime>(
    rawTicketTime,
    TicketTimeAttributeNames,
  )
}
