import startCase from 'lodash/fp/startCase'
import React from 'react'

import { useI18n } from 'packages/i18n'

import { Slugs } from 'app/fieldapp/i18n'
import { CrossCoverage } from 'app/fieldapp/store/crossCoverage/crossCoverage.types'

import {
  getDaysFrom,
  getTimeFrom,
  getStartOrEndFrom,
} from './useUpcomingCoverageEvent.helpers'

const useTranslations = () => {
  const { t } = useI18n()

  return {
    days: t(Slugs.days),
    end: t(Slugs.end),
    everyDay: t(Slugs.everyDay),
    firstOccurrence: t(Slugs.firstOccurrence),
    fridayF: t(Slugs.fridayF),
    lastOccurrence: t(Slugs.lastOccurrence),
    mondayM: t(Slugs.mondayM),
    oneTime: t(Slugs.oneTime),
    repeating: t(Slugs.repeating),
    saturdaySA: t(Slugs.saturdaySA),
    start: t(Slugs.start),
    sundaySU: t(Slugs.sundaySU),
    thursdayTH: t(Slugs.thursdayTH),
    tuesdayTU: t(Slugs.tuesdayTU),
    wednesdayW: t(Slugs.wednesdayW),
  }
}

type UpcomingCoverageEventValues = {
  days: string
  end: string
  endLabel: string
  frequency: string
  start: string
  startLabel: string
  time: string | JSX.Element
}

export function useUpcomingCoverageEventValues(
  coverageEvent: CrossCoverage,
): UpcomingCoverageEventValues {
  const strings = useTranslations()

  const frequency = React.useMemo(
    () => (coverageEvent.isRepeating ? strings.repeating : strings.oneTime),
    [coverageEvent.isRepeating, strings.oneTime, strings.repeating],
  )

  const days = React.useMemo(
    () =>
      getDaysFrom(coverageEvent, {
        days: strings.days,
        everyDay: strings.everyDay,
        fridayF: strings.fridayF,
        mondayM: strings.mondayM,
        saturdaySA: strings.saturdaySA,
        sundaySU: strings.sundaySU,
        thursdayTH: strings.thursdayTH,
        tuesdayTU: strings.tuesdayTU,
        wednesdayW: strings.wednesdayW,
      }),
    [
      coverageEvent,
      strings.days,
      strings.everyDay,
      strings.fridayF,
      strings.mondayM,
      strings.saturdaySA,
      strings.sundaySU,
      strings.thursdayTH,
      strings.tuesdayTU,
      strings.wednesdayW,
    ],
  )

  const time = React.useMemo(() => getTimeFrom(coverageEvent), [coverageEvent])

  const startLabel = React.useMemo(() => {
    return startCase(
      coverageEvent.isRepeating ? strings.firstOccurrence : strings.start,
    )
  }, [coverageEvent.isRepeating, strings.firstOccurrence, strings.start])

  const start = React.useMemo(
    () => getStartOrEndFrom('start', coverageEvent),
    [coverageEvent],
  )

  const endLabel = React.useMemo(() => {
    return startCase(
      coverageEvent.isRepeating ? strings.lastOccurrence : strings.end,
    )
  }, [coverageEvent.isRepeating, strings.end, strings.lastOccurrence])

  const end = React.useMemo(
    () => getStartOrEndFrom('end', coverageEvent),
    [coverageEvent],
  )

  return {
    days,
    end,
    endLabel,
    frequency,
    start,
    startLabel,
    time,
  }
}
