import { createSelector } from 'reselect'

import { ApplicationState } from 'app/fieldapp/store/store'

import { TicketTime, TicketTimesState } from '../ticket-times.types'
import { hydrateRawTicketTime } from '../ticket-times.utils'

const getTicketTimesState = (state: ApplicationState): TicketTimesState =>
  state.ticketTimes

const getTicketTimeId = (
  _state: ApplicationState,
  ticketTimeId: string,
): string => ticketTimeId

export const getTicketTimeById = createSelector(
  getTicketTimesState,
  getTicketTimeId,
  (ticketTimesState, id): TicketTime | undefined => {
    const rawTicketTime = ticketTimesState.data[id]
    return rawTicketTime && hydrateRawTicketTime(rawTicketTime)
  },
)
