import { createSelector } from 'reselect'

import { hydrateRawUnit } from '..'
import { ApplicationState } from '../../store'
import { UnitsState } from '../units.types'

const getUnitsState = (state: ApplicationState, _id: string): UnitsState =>
  state.units

const getUnitId = (_state: ApplicationState, id: string): string => id

/**
 * @deprecated Do not use this selector; prefer `getUnitById` instead.
 *
 * This selector was built without the ability to possibly return undefined, which while it
 * is inherently incorrect, has a couple dependencies that rely on this behavior.
 *
 * The newer selector correctly defines a `Unit | undefined` return type,
 * so please use that one for any new code.
 */
export const legacyGetUnitById = createSelector(
  getUnitsState,
  getUnitId,
  (unitsState, unitId) => {
    const rawUnit = unitsState?.data?.[unitId]
    return hydrateRawUnit(rawUnit)
  },
)
