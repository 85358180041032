import styled from '@emotion/styled'
import React from 'react'
import { useSelector } from 'react-redux'

import { Alert } from 'packages/common'
import { createDateString } from 'packages/utils/dateHelpers'

import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { getWorkingStatusesForDateAndRange } from 'app/fieldapp/store/coverage/selectors/getWorkingStatusesForDateRange'
import { ApplicationState } from 'app/fieldapp/store/store'

import { getDateBoundsFromWorkWeek } from '../AvailabilitySchedule.utils'
import { DayCalendarCard } from '../DayCalendarCard'

const St = {
  Alert: styled(Alert)`
    width: 100%;
  `,
  Container: styled.div`
    display: flex;
    gap: 4px;
    justify-content: center;
    padding: 0 16px;
  `,
}

export enum WeekCalendarDisplayTestIds {
  container = 'WeekCalendarDisplay__container',
}

export type WeekCalendarDisplayProps = {
  dateRange: Date[]
  error?: string | Error | undefined
  loading: boolean
  onCardClick: (date: Date) => void
}

export const WeekCalendarDisplay: React.FC<WeekCalendarDisplayProps> =
  React.memo(({ dateRange, onCardClick, error, loading }) => {
    const { t } = useI18n()

    const dateBounds: [string, string] = getDateBoundsFromWorkWeek(dateRange)

    const computedWorkingStatuses = useSelector((state: ApplicationState) =>
      getWorkingStatusesForDateAndRange(state, dateBounds),
    )

    return (
      <St.Container data-testid={WeekCalendarDisplayTestIds.container}>
        {!error &&
          dateRange.map((date, index) => (
            <DayCalendarCard
              date={date}
              isLoading={loading}
              key={createDateString(date)}
              onClick={() => onCardClick(date)}
              workingStatus={computedWorkingStatuses[index]}
            />
          ))}
        {error && (
          <St.Alert alertType="danger">{t(Slugs.fetchCoverageError)}</St.Alert>
        )}
      </St.Container>
    )
  })
