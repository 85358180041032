import { map, prop } from 'lodash/fp'
import React from 'react'
import { useSelector } from 'react-redux'

import { fullName } from 'packages/grimoire'
import { useI18n } from 'packages/i18n'
import { IconName } from 'packages/iconic'

import {
  CleanDetailBody,
  CleanDetailSection,
  TeamCleanIcon,
} from 'app/fieldapp/components/schedule/components/CleanDetail/CleanDetail.styles'
import { Slugs } from 'app/fieldapp/i18n'
import { Clean } from 'app/fieldapp/store/cleans'
import { ApplicationState } from 'app/fieldapp/store/store'
import { getUserByHkId } from 'app/fieldapp/store/users/selectors'

const HkName: React.FC<{ hkId: string }> = React.memo(({ hkId }) => {
  const user = useSelector((state: ApplicationState) =>
    getUserByHkId(state, hkId),
  )
  const name = user ? fullName(user) : null

  return name ? <div>{name}</div> : null
})

const St = {
  Body: CleanDetailBody,
  Container: CleanDetailSection,
  Icon: TeamCleanIcon,
}

const useTranslations = () => {
  const { t } = useI18n()

  return {
    teamClean: t(Slugs.teamClean),
  }
}

export type TeamCleanInfoProps = {
  clean: Clean
}

export const TeamCleanInfo: React.FC<TeamCleanInfoProps> = ({ clean }) => {
  const strings = useTranslations()

  const isTeamClean = clean?.assignments?.length > 1
  const assignedHks = map(prop('housekeeper'))(clean?.assignments || [])
  const hkIds = map(prop('id'))(assignedHks)

  return isTeamClean ? (
    <St.Container>
      <St.Icon icon={IconName.zap} size={16} />
      <St.Body>
        <strong>{strings.teamClean}</strong>
        {hkIds.map(hkId => (
          <HkName hkId={hkId} key={hkId} />
        ))}
      </St.Body>
    </St.Container>
  ) : null
}
