import { createSlice } from '@reduxjs/toolkit'

import { fetchCrossCoverageById, fetchCrossCoverages } from './actions'
import { CrossCoverageState } from './crossCoverage.types'

const initialState: CrossCoverageState = {
  data: {},
}

export const crossCoverageSlice = createSlice({
  extraReducers: builder => {
    builder
      .addCase(fetchCrossCoverages.fulfilled, (state, action) => {
        state.data = action.payload.normalized.crossCoverage || {}
      })
      .addCase(fetchCrossCoverageById.fulfilled, (state, action) => {
        state.data = {
          ...state.data,
          ...action.payload.normalized.crossCoverage,
        }
      })
  },
  initialState,
  name: 'crossCoverage',
  reducers: {},
})

export default crossCoverageSlice.reducer
