import React from 'react'
import { useSelector } from 'react-redux'

import { useMemoById } from 'packages/utils/hooks'

import { useCleanTimerActions } from 'app/fieldapp/components/timers/hooks'
import { useTimers as useTimersContext } from 'app/fieldapp/components/timers/state'
import { StartedOfflineCleanTime } from 'app/fieldapp/components/timers/timers.types'
import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { CleanTime } from 'app/fieldapp/store/cleantimes'
import { getMergedCleanTimeById } from 'app/fieldapp/store/cleantimes/selectors'
import { ApplicationState } from 'app/fieldapp/store/store'
import { getTasksByAssignmentIds } from 'app/fieldapp/store/tasks/selectors'
import { useTaskUnit } from 'app/fieldapp/store/tasks/utils/useTaskUnit'
import { getOfflineTimerById } from 'app/fieldapp/store/utils/timers/selectors'

import { SubmissionTimer } from '../../types'
import { SubmitCleanTimers } from './SubmitCleanTimers'

export const SubmitCleanTimersContainer: React.FC = () => {
  const { ut } = useI18n()
  const { state } = useTimersContext()
  const { submissionData } = state

  const {
    dispatchCancelStopCleanTimer,
    dispatchCancelStopOfflineCleanTimer,
    dispatchCompleteStartedOfflineCleanTimer,
    dispatchConfirmCleanTimerSubmission,
    dispatchDeleteCleanTimerWithModalWarning,
  } = useCleanTimerActions()

  // find and memoize the clean associated with this timer (if any)
  const assignmentId = (submissionData?.timer as CleanTime)?.assignment.id || ''
  const tasksForAssignmentId = useSelector((storeState: ApplicationState) =>
    getTasksByAssignmentIds(storeState, [assignmentId]),
  )
  const taskForAssignment = tasksForAssignmentId[assignmentId]
  const memoizedTask = useMemoById(taskForAssignment)
  const unit = useTaskUnit(memoizedTask)
  const unitName = unit?.name || ut(Slugs.unknown)

  // find, memoize, and transform timer for the submit form
  const timerId = submissionData?.timer.id || ''
  const timer = useSelector((storeState: ApplicationState) =>
    getMergedCleanTimeById(storeState, timerId),
  )
  const memoizedTimer = useMemoById(timer)

  const offlineTimer = useSelector((storeState: ApplicationState) =>
    submissionData?.timer?.id
      ? getOfflineTimerById(storeState, submissionData.timer.id)
      : undefined,
  )

  // memoize a whole bunch of cleantime action callbacks
  const dispatchAbortSubmission = React.useCallback(async () => {
    if (offlineTimer?.id) {
      await dispatchCancelStopOfflineCleanTimer(offlineTimer.id)
    } else if (memoizedTimer?.id) {
      await dispatchCancelStopCleanTimer(memoizedTimer.id)
    }
  }, [
    dispatchCancelStopCleanTimer,
    dispatchCancelStopOfflineCleanTimer,
    memoizedTimer?.id,
    offlineTimer?.id,
  ])

  const dispatchConfirmSubmission = React.useCallback(
    async (timers: SubmissionTimer[]) => {
      if (memoizedTask) {
        await dispatchConfirmCleanTimerSubmission(memoizedTask, timers)
      }
    },
    [memoizedTask, dispatchConfirmCleanTimerSubmission],
  )

  const dispatchCompleteStartedOffline = React.useCallback(
    async (timer: StartedOfflineCleanTime) => {
      await dispatchCompleteStartedOfflineCleanTimer(timer, memoizedTask)
    },
    [dispatchCompleteStartedOfflineCleanTimer, memoizedTask],
  )

  const dispatchDeleteTimer = React.useCallback(async () => {
    if (memoizedTimer?.id) {
      await dispatchDeleteCleanTimerWithModalWarning(memoizedTimer.id)
    }
  }, [dispatchDeleteCleanTimerWithModalWarning, memoizedTimer?.id])

  return memoizedTimer ? (
    <SubmitCleanTimers
      dispatchAbortSubmission={dispatchAbortSubmission}
      dispatchCompleteStartedOffline={dispatchCompleteStartedOffline}
      dispatchConfirmSubmission={dispatchConfirmSubmission}
      dispatchDeleteTimer={dispatchDeleteTimer}
      displayValue={unitName}
      task={memoizedTask}
      timer={memoizedTimer}
    />
  ) : null
}
