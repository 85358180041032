import { createAsyncAction } from 'typesafe-actions'

import {
  RequestConfig,
  RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import {
  NormalizedTicketTimesApiResponse,
  TicketTimeApiFields,
  TicketTimesActionTypes,
} from 'app/fieldapp/store/ticket-times'

import { ticketTimesService } from '../ticket-times.service'

export const fetchTicketTimeByIdAction = createAsyncAction(
  TicketTimesActionTypes.FETCH_TICKET_TIME_BY_ID,
  TicketTimesActionTypes.FETCH_TICKET_TIME_BY_ID_SUCCESS,
  TicketTimesActionTypes.FETCH_TICKET_TIME_BY_ID_FAILURE,
)<
  RequestConfig<NormalizedTicketTimesApiResponse>,
  NormalizedTicketTimesApiResponse,
  Error
>()

export const getParams = (): RequestOptions => ({
  fields: {
    ticket: [],
    ticket_time: TicketTimeApiFields,
    user: [],
  },
  include: ['ticket'],
})

export const fetchTicketTimeById = (ticketTimeId: string) => async dispatch => {
  try {
    const params = getParams()
    const request = ticketTimesService.fetchTicketTimeById.bind(
      null,
      ticketTimeId,
      params,
    )
    const result = await dispatch(
      fetchTicketTimeByIdAction.request({ request }),
    )
    dispatch(fetchTicketTimeByIdAction.success(result))
    return result.normalized
  } catch (error) {
    dispatch(fetchTicketTimeByIdAction.failure(error))
    throw error
  }
}
