import styled from '@emotion/styled'
import { useDecision } from '@optimizely/react-sdk'
import * as React from 'react'

import { Alert, useModalContext } from 'packages/common'
import { IconName, SvgIcon } from 'packages/iconic'
import { Features } from 'packages/optimizely'
import { text } from 'packages/styles'
import { useOnlineStatus } from 'packages/utils/hooks'

import { LinksCard } from 'app/fieldapp/components/core'
import { CreateTicketDrawer } from 'app/fieldapp/components/schedule/components/common/CreateTicket/CreateTicketDrawer'
import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { useActiveUser } from 'app/fieldapp/utils/hooks/useActiveUser'

import { ProfileHeader } from '../../components'
import { WeekCalendarContainer } from '../../components/AvailabilitySchedule'
import { CoverageDefaultInfoModal } from '../../components/CoverageDefaultInfoModal'
import { OnCallTaskDrawerContainer } from '../../components/OnCallTaskDrawer'
import { StandardAvailabilityDrawerContainer } from '../../components/StandardAvailabilityDrawer'
import { StandardCoveragePartnerDrawerContainer } from '../../components/StandardCoveragePartnerDrawer'
import { UpcomingCoverageEventsContainer } from '../../components/UpcomingCoverageEvents/'

import styles from './ProfilePage.module.scss'

const St = {
  ProfileAdminLink: styled.a`
    text-decoration: underline;
  `,
  ProfileAdminLinkAlert: styled(Alert)`
    margin: 0 16px;
  `,
  ProfileContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 48px;
  `,
  ProfileSectionContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 0 16px;
  `,
  ProfileSectionContent: styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
  `,
  ProfileSectionHeader: styled.h4`
    align-items: center;
    display: flex;
    gap: 8px;
    ${text.headingSCaps}
    margin-bottom: 0;
  `,
}

const ADMIN_BASE_URL = process.env.REACT_APP_ADMIN_BASE_URL
const ADMIN_DASHBOARD_URL = `${ADMIN_BASE_URL}/admin/dashboard`

export const TIME_OFF_REQUEST_URL = `${ADMIN_DASHBOARD_URL}/schedules/timeoff`
export const UPDATE_AVAILABILITY_URL = `${ADMIN_DASHBOARD_URL}/schedules/availability`
export const MY_TICKETS_URL = `${ADMIN_DASHBOARD_URL}/tickets/index`
export const CREATE_TICKET_URL = `${ADMIN_DASHBOARD_URL}/tickets/editMaintenance`

const slugStringsKeys = [
  'changeYourAvailability',
  'myTickets',
  'requestTimeOff',
  'tickets',
  'updateAvailability',
] as const

type SlugStrings = Record<(typeof slugStringsKeys)[number], string>

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getStrings = (ut): SlugStrings =>
  slugStringsKeys.reduce(
    (prev, curr) => ({
      ...prev,
      [curr]: ut(Slugs[curr]),
    }),
    {},
  ) as SlugStrings

export const ProfilePage: React.FC = () => {
  // TODO: Clean up once cross-coverage FF is permanently enabled
  const [decision] = useDecision(Features.CROSS_COVERAGE)
  const isFFCrossCoverage = decision.enabled

  const isOnline = useOnlineStatus().isOnline()

  const { ut, t } = useI18n()

  const { user, isEmployee } = useActiveUser()

  const {
    changeYourAvailability,
    requestTimeOff,
    updateAvailability,
    tickets,
    myTickets,
  } = getStrings(ut)

  const scheduleLinks = [
    { text: requestTimeOff, url: TIME_OFF_REQUEST_URL },
    { text: updateAvailability, url: UPDATE_AVAILABILITY_URL },
  ]

  const ticketsLinks = [{ text: myTickets, url: MY_TICKETS_URL }]

  const { showModal } = useModalContext()

  const handleAvailabilityPresetsIconClick = () => {
    showModal({
      childRenderer: () => <CoverageDefaultInfoModal />,
    })
  }

  if (isFFCrossCoverage && isEmployee) {
    return (
      <St.ProfileContainer>
        <ProfileHeader user={user} />
        <St.ProfileAdminLinkAlert alertType={'info'}>
          {t(Slugs.adminLinkAlert)}{' '}
          <St.ProfileAdminLink
            rel="noopener noreferrer"
            href={UPDATE_AVAILABILITY_URL}
          >
            {t(Slugs.here)}
          </St.ProfileAdminLink>
        </St.ProfileAdminLinkAlert>
        <WeekCalendarContainer />
        {isOnline ? (
          <>
            <St.ProfileSectionContainer>
              <St.ProfileSectionHeader>
                {t(Slugs.availabilityPresets)}
                <SvgIcon
                  icon={IconName.infoFilled}
                  onClick={handleAvailabilityPresetsIconClick}
                  size={16}
                />
              </St.ProfileSectionHeader>
              <St.ProfileSectionContent>
                <StandardCoveragePartnerDrawerContainer />
                <StandardAvailabilityDrawerContainer />
                <OnCallTaskDrawerContainer />
              </St.ProfileSectionContent>
            </St.ProfileSectionContainer>
            <St.ProfileSectionContainer>
              <UpcomingCoverageEventsContainer isEditingEvent={false} />
            </St.ProfileSectionContainer>
          </>
        ) : (
          <St.ProfileSectionContainer>
            <St.ProfileSectionContent>
              <Alert alertType={'warning'}>
                {t(Slugs.cannotMakeChangesWhileOffline)}
              </Alert>
            </St.ProfileSectionContent>
          </St.ProfileSectionContainer>
        )}
      </St.ProfileContainer>
    )
  }

  return (
    <div>
      <ProfileHeader user={user} />

      <div className={styles.profilePageContent}>
        <div className={styles.profilePageSection}>
          <div className={styles.profilePageHeader}>
            {changeYourAvailability}
          </div>

          <LinksCard links={scheduleLinks} />

          <div className={styles.profilePageHeader}>{tickets}</div>

          <LinksCard links={ticketsLinks} />

          {isOnline ? (
            <>
              <CreateTicketDrawer isDrawerSection={false} />
            </>
          ) : (
            <div className={styles.profilePageAlert}>
              <Alert alertType={'warning'}>
                {t(Slugs.cannotMakeChangesWhileOffline)}
              </Alert>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
