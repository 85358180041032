import styled from '@emotion/styled'

import { TimerType } from 'packages/grimoire/src/utils/timers.types'
import { colors } from 'packages/styles'

import { TIMER_DETAIL_HEIGHT } from '../timers.constants'

type TimerColorType =
  | Exclude<TimerType, TimerType.CLEAN>
  | 'post-stay'
  | 'deep'
  | 'visit'

type TimerDetailWrapperProps = {
  timerColorType: TimerColorType
}

const timerTypeColorMap = {
  [TimerType.OTHER]: 'transparent',
  [TimerType.TICKET]: colors.success40,
  deep: colors.sand,
  'post-stay': colors.lake20,
  visit: colors.tetons30,
}

export const TimerDetailWrapper = styled.div<TimerDetailWrapperProps>`
  align-items: center;
  display: flex;
  height: ${TIMER_DETAIL_HEIGHT}px;
  justify-content: space-between;
  padding: 16px;
  padding-left: 0;

  ${({ timerColorType }) =>
    `border-left: 8px solid ${timerTypeColorMap[timerColorType]}`};
`
