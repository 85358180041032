import { AxiosResponse } from 'axios'

import { AllReviewAttributes } from 'packages/grimoire/src/review'
import {
  JSONApiObjectWithRelationships,
  JSONApiObjectWithRelationshipsMap,
  NormalizedJSONApiResponse,
  ToOneRelationship,
} from 'packages/utils/store'

/**********************************************************
 * SERVICE TYPES
 *********************************************************/
export type ReviewResponse = {
  guestReview: JSONApiReviewsMap
}

export type NormalizedReviewsApiResponse =
  NormalizedJSONApiResponse<ReviewResponse>

export type ReviewsApiResponse = AxiosResponse<NormalizedReviewsApiResponse>
export type ReviewsServiceResponse = Promise<NormalizedReviewsApiResponse>

/**********************************************************
 * REDUX TYPES
 *********************************************************/
export enum ReviewsActionTypes {
  FETCH_REVIEWS_BY_UNIT_ID = 'REVIEWS_BY_UNIT_ID/API/FETCHING_REVIEWS_BY_UNIT_ID',
  FETCH_REVIEWS_BY_UNIT_ID_FAILURE = 'REVIEWS_BY_UNIT_ID/FETCH_REVIEWS_BY_UNIT_ID_FAILURE',
  FETCH_REVIEWS_BY_UNIT_ID_SUCCESS = 'REVIEWS_BY_UNIT_ID/FETCH_REVIEWS_BY_UNIT_ID_SUCCESS',
}

export type ReviewsState = {
  data: JSONApiReviewsMap
}

/**********************************************************
 * Review
 *********************************************************/

export type RatingInfo = {
  label: string
  value: number
}

export type ReviewRelationships = {
  unit: ToOneRelationship
}

export type JSONApiReviewsMap = JSONApiObjectWithRelationshipsMap<
  AllReviewAttributes,
  ReviewRelationships
>

export type RawReview = JSONApiObjectWithRelationships<
  AllReviewAttributes,
  ReviewRelationships
>
export type Review = AllReviewAttributes
