import classNames from 'classnames'
import * as React from 'react'

import { Card } from 'packages/common'
import { IconName, SvgIcon } from 'packages/iconic'

import styles from './LinksCard.module.scss'

type LinksCardProps = {
  className?: string
  links: LinkProps[]
}

type LinkProps = {
  text: string
  url: string
}

const Link = ({ text, url }) => (
  <>
    <a
      aria-label={text}
      className={styles.link}
      href={url}
      rel="noopener noreferrer"
      target="_blank"
    >
      <span>{text}</span>
      <span>
        <SvgIcon centerItems={true} icon={IconName.externalLink} size={16} />
      </span>
    </a>

    <hr className={styles.divider} />
  </>
)

export const LinksCard: React.FC<LinksCardProps> = ({ className, links }) => (
  <Card className={classNames(styles.linkCard, className)}>
    {links.map(({ text, url }) => (
      <Link key={text} text={text} url={url} />
    ))}
  </Card>
)
