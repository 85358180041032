import { merge } from 'lodash/fp'
import { createSelector } from 'reselect'

import { ApplicationState } from 'app/fieldapp/store/store'

import { OtherTimer, RawOtherTime } from '../otherTimers.types'
import { getMergedOtherTimerById } from './getMergedOtherTimerById'

const otherTimerIsActive = (rtt: RawOtherTime): boolean =>
  !!rtt.attributes.startedAt && !rtt.attributes.stoppedAt

const getFullState = (state: ApplicationState): ApplicationState => state

export const getActiveOtherTimer = createSelector(
  getFullState,
  (fullState): OtherTimer | undefined => {
    const { data, offlineData } = fullState.otherTimers

    const mergedOtherTimers: RawOtherTime[] = Object.values(
      merge(data, offlineData),
    )
    const rawActiveOtherTime = mergedOtherTimers.find(otherTimerIsActive)

    if (!rawActiveOtherTime) {
      return undefined
    }

    /*
     * Check for an offline timer with the active id -
     * if it exists and is stopped offline, it belongs in paused timers
     */
    const offlineOtherTime =
      fullState.otherTimers.offlineData[rawActiveOtherTime.id]
    if (offlineOtherTime?.attributes.stoppedAt) {
      return undefined
    }

    return getMergedOtherTimerById(fullState, rawActiveOtherTime.id)
  },
)
