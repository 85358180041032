import styled from '@emotion/styled'
import React from 'react'
import { useSelector } from 'react-redux'

import { Button, Loader } from 'packages/common'
import { colors } from 'packages/styles'

import { isCheckedOrFlagged } from 'app/fieldapp/components/schedule/schedule.utils'
import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { useAppDispatch } from 'app/fieldapp/store/hooks'
import { getInspectionFlagByItemId } from 'app/fieldapp/store/inspectionFlags/selectors/getInspectionFlagByItemId'
import { ApplicationState } from 'app/fieldapp/store/store'
import {
  InspectionCategory,
  InspectionListItem,
} from 'app/fieldapp/store/tasks'
import { toggleVisitInspectionChecklistItem } from 'app/fieldapp/store/visits/actions'

import { useInspectionContext } from '../../context/VisitInspectionChecklist.context'
import { useInspectionVisitWithRelationships } from '../../useInspectionVisitWithRelationships'
import { InspectionFlagNoteFormContainer } from '../InspectionFlagNoteForm'
import { VisitInspectionCheckbox } from '../VisitInspectionCheckbox'

const St = {
  Error: styled.div`
    color: ${colors.alert};
  `,
  InspectionChecklistItem: styled.div`
    align-items: center;
    color: ${colors.dusk};
    font-size: 15px;
    padding: 20px;
    position: relative;

    &:not(:last-of-type) {
      border-bottom: 1px solid ${colors.midnight20};
    }
  `,
  Item: styled.div`
    display: flex;
  `,
  Label: styled.span`
    font-size: 16px;
  `,
  LabelContainer: styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 16px;
  `,
  Note: styled.textarea`
    border-radius: 4px;
    display: flex;
    margin: 24px 0 24px 0;
    padding: 8px 12px 8px 12px;
    width: 100%;
  `,
  SaveButton: styled(Button)`
    display: flex;
    line-height: 40px;
    margin-left: auto;
    max-width: 78px;
  `,
  Spacer: styled.span`
    width: 10px;
  `,
}

const useTranslations = () => {
  const { t } = useI18n()

  return {
    noNotesRequiredToSave: t(Slugs.noNotesRequiredToSave),
    notePlaceholder: t(Slugs.startTyping),
    save: t(Slugs.save),
    toggleItemError: t(Slugs.inspectionErrorToggleItem),
  }
}

export type CheckedOrFlagged = 'checked' | 'flagged'

export type VisitInspectionChecklistItemProps = {
  category: InspectionCategory
  checklistItem: InspectionListItem
}

export const VisitInspectionChecklistItem: React.FC<VisitInspectionChecklistItemProps> =
  React.memo(({ category, checklistItem }) => {
    const strings = useTranslations()

    const dispatch = useAppDispatch()

    const {
      uiDisabledState,
      uiState: { errorIds, loadingIds },
    } = useInspectionContext()

    const hasError = React.useMemo(
      () => errorIds.includes(checklistItem.id),
      [checklistItem, errorIds],
    )

    const isLoading = React.useMemo(
      () => loadingIds.includes(checklistItem.id),
      [checklistItem, loadingIds],
    )

    const { visit } = useInspectionVisitWithRelationships()
    const { uiStateDispatch } = useInspectionContext()

    const inspectionFlag = useSelector((state: ApplicationState) =>
      getInspectionFlagByItemId(state, {
        category: category,
        itemId: checklistItem.id,
      }),
    )

    const handleCheckboxToggle = React.useCallback(
      async (checkedOrFlagged: CheckedOrFlagged) => {
        if (!visit?.id) return

        uiStateDispatch({
          payload: { id: checklistItem.id },
          type: 'addLoadingItem',
        })

        try {
          await dispatch(
            toggleVisitInspectionChecklistItem({
              category,
              checkedOrFlagged,
              item: checklistItem,
              visitId: visit.id,
            }),
          ).unwrap()
        } catch (error) {
          uiStateDispatch({
            payload: { id: checklistItem.id },
            type: 'addErrorItem',
          })
        } finally {
          uiStateDispatch({
            payload: { id: checklistItem.id },
            type: 'removeLoadingItem',
          })
        }
      },
      [category, checklistItem, dispatch, uiStateDispatch, visit.id],
    )

    return (
      <St.InspectionChecklistItem>
        <>
          <St.Item>
            <VisitInspectionCheckbox
              checked={isCheckedOrFlagged(checklistItem.isComplete, 'checked')}
              disabled={!!uiDisabledState}
              onChange={() => handleCheckboxToggle('checked')}
              value={checklistItem.id}
            />
            <St.Spacer />
            <VisitInspectionCheckbox
              checked={isCheckedOrFlagged(checklistItem.isComplete, 'flagged')}
              disabled={!!uiDisabledState}
              onChange={() => handleCheckboxToggle('flagged')}
              value={checklistItem.id}
              isFlagged={true}
            />
            <St.LabelContainer>
              <St.Label>{checklistItem.title}</St.Label>
              {hasError ? <St.Error>{strings.toggleItemError}</St.Error> : ''}
            </St.LabelContainer>
          </St.Item>

          {inspectionFlag && !inspectionFlag?.deletedAt && (
            <InspectionFlagNoteFormContainer
              flagId={inspectionFlag?.id}
              inspectionFlagNotes={inspectionFlag.notes}
              visitId={visit.id}
            ></InspectionFlagNoteFormContainer>
          )}
        </>
        {isLoading && <Loader />}
      </St.InspectionChecklistItem>
    )
  })
