import { createAsyncAction } from 'typesafe-actions'

import {
  RequestOptions,
  RequestConfig,
} from 'packages/utils/store/jsonapi.types'

import { UserApiFields } from '../../users'
import { housekeepersService } from '../housekeepers.service'
import {
  HousekeepersActionTypes,
  NormalizedHousekeepersApiResponse,
} from '../housekeepers.types'

export const fetchHousekeeperManagersByZoneAction = createAsyncAction(
  HousekeepersActionTypes.FETCH_HOUSEKEEPER_MANAGERS_BY_ZONE,
  HousekeepersActionTypes.FETCH_HOUSEKEEPER_MANAGERS_BY_ZONE_SUCCESS,
  HousekeepersActionTypes.FETCH_HOUSEKEEPER_MANAGERS_BY_ZONE_FAILURE,
)<
  RequestConfig<NormalizedHousekeepersApiResponse>,
  NormalizedHousekeepersApiResponse,
  Error
>()

export const getParams = (zoneId: string): RequestOptions => {
  return {
    fields: {
      housekeeper: [],
      user: UserApiFields,
      zone: [],
    },
    filter: {
      is_housekeeping_manager: true,
      zone: zoneId,
    },
    include: ['user', 'zone'],
  }
}

export const fetchHousekeeperManagersByZone =
  (zoneId: string) => async dispatch => {
    try {
      const params = getParams(zoneId)
      const request = housekeepersService.fetchHousekeeperManagersByZone.bind(
        null,
        params,
      )
      const response = await dispatch(
        fetchHousekeeperManagersByZoneAction.request({ request }),
      )
      dispatch(fetchHousekeeperManagersByZoneAction.success(response))
      return response.normalized
    } catch (error) {
      dispatch(fetchHousekeeperManagersByZoneAction.failure(error))
      throw error
    }
  }
