import * as React from 'react'

import { TimerType } from 'app/fieldapp/store/utils/timers/timers.types'

import { useTimers as useTimersContext } from '../../state/timers.context'
import { SubmitCleanTimersContainer as SubmitCleanTimers } from './components/SubmitCleanTimers'
import { SubmitOtherTimers } from './components/SubmitOtherTimers'
import { SubmitTicketTimersContainer as SubmitTicketTimers } from './components/SubmitTicketTimers'

export const SubmitTimers: React.FC = () => {
  const { state } = useTimersContext()
  const { submissionData } = state

  switch (submissionData?.type) {
    case TimerType.CLEAN:
      return <SubmitCleanTimers />

    case TimerType.TICKET:
      return <SubmitTicketTimers />

    case TimerType.OTHER:
      return <SubmitOtherTimers />
  }

  return null
}
