import { createSelector } from 'reselect'

import { ReviewAttributeNames } from 'packages/grimoire/src/review'
import { transformNormalizedToTyped } from 'packages/utils/store'

import { ApplicationState } from '../../store'
import { Review } from '../reviews.types'

/**
 * Returns review for a specific review ID (cached via reselect) should recompute if state changes
 *
 * @param state
 */
export const getReviewById = createSelector(
  (state: ApplicationState) => state.reviews,
  (_, reviewId: string) => reviewId,
  (reviewsState, reviewId): Review => {
    const rawReview = reviewsState?.data?.[reviewId]

    return (
      rawReview &&
      transformNormalizedToTyped<Review>(rawReview, ReviewAttributeNames)
    )
  },
)
