import React from 'react'

import { AsyncSelector } from 'packages/common'
import { useI18n } from 'packages/i18n'

import { Slugs } from 'app/fieldapp/i18n'
import { CreateTicketDrawerUnit, Unit } from 'app/fieldapp/store/units'
import { SearchUnitsOptions } from 'app/fieldapp/store/units/actions/searchUnits'

import {
  getHighlightedUnitSearchResults,
  useUnitSearchLoader,
} from './UnitSearchSelector.helpers'

export type UnitSearchSelectorProps = {
  className?: string
  onUnitChange: (unit: Unit) => void
  searchUnitsOptions?: SearchUnitsOptions
  selectedUnit?: CreateTicketDrawerUnit
}

export const UnitSearchSelector: React.FC<UnitSearchSelectorProps> = React.memo(
  ({ className, onUnitChange, searchUnitsOptions, selectedUnit }) => {
    const { ut } = useI18n()
    const unitLoader = useUnitSearchLoader(searchUnitsOptions)

    const noOptionsMessage = React.useCallback(
      ({ inputValue }) =>
        inputValue === ''
          ? ut(Slugs.typeToSearch)
          : ut(Slugs.unitSearchNoResults),
      [ut],
    )

    const ref = React.createRef<HTMLInputElement>()

    return (
      <div data-testid="unit-selector">
        <AsyncSelector
          className={className}
          defaultOptions={[]}
          getOptionValue={unit => unit.id}
          formatOptionLabel={getHighlightedUnitSearchResults}
          loadingMessage={() => ut(Slugs.loading)}
          loadOptions={unitLoader}
          noOptionsMessage={noOptionsMessage}
          onChange={onUnitChange}
          placeholder={ut(Slugs.searchUnitNameOrCode)}
          ref={ref}
          value={selectedUnit}
        />
      </div>
    )
  },
)
