import { createAsyncAction } from 'typesafe-actions'

import {
  RequestConfig,
  RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import {
  InspectionCategory,
  InspectionListItem,
  NormalizedTasksApiResponse,
} from 'app/fieldapp/store/tasks'

import { tasksService } from '../../tasks/tasks.service'
import { toggleIsCompletedString } from '../../tasks/utils/tasks.utils'
import { CleansActionTypes } from '../cleans.types'
import { fetchCleanById } from './fetchCleanById'

export type ToggleInspectionItemArgs = {
  category: InspectionCategory
  cleanId: string
  item: InspectionListItem
}

export const toggleInspectionItemAction = createAsyncAction(
  CleansActionTypes.TOGGLE_INSPECTION_ITEM,
  CleansActionTypes.TOGGLE_INSPECTION_ITEM_SUCCESS,
  CleansActionTypes.TOGGLE_INSPECTION_ITEM_FAILURE,
)<
  RequestConfig<NormalizedTasksApiResponse>,
  NormalizedTasksApiResponse,
  Error
>()

export const buildRequestData = (
  args: ToggleInspectionItemArgs,
): RequestOptions => {
  const { category, cleanId, item } = args

  const attributes = {
    inspection_checklist: {
      checklists: [
        {
          category: {
            id: category.id,
          },
          items: [
            {
              id: item.id,
              is_complete: toggleIsCompletedString(item.isComplete),
            },
          ],
        },
      ],
    },
  }

  return {
    data: {
      attributes,
      id: cleanId,
      type: 'task',
    },
  }
}

export const toggleInspectionItem =
  (args: ToggleInspectionItemArgs) => async dispatch => {
    try {
      const cleanId = args.cleanId
      const requestData = buildRequestData(args)
      const request = tasksService.updateTask.bind(
        null,
        args.cleanId,
        requestData,
      )
      const result = await dispatch(
        toggleInspectionItemAction.request({ request }),
      )
      dispatch(toggleInspectionItemAction.success(result))

      // re-fetch the associated clean to ensure we have the latest version with all its relationships locally
      await dispatch(fetchCleanById(cleanId))

      return result.normalized
    } catch (error) {
      dispatch(toggleInspectionItemAction.failure(error))
      throw error
    }
  }
