import { createSelector } from 'reselect'

import { ApplicationState } from '../../store'
import { Contact, RawContact } from '../contacts.types'
import { hydrateRawContact } from '../contacts.utils'

/**
 * Returns all contacts for a specific unit ID (cached via reselect) should recompute if state changes
 *
 * @param state
 */
export const getContactsByUnitId = createSelector(
  (state: ApplicationState) => state.contacts.data, // Get all contacts from state
  (_, unitId: string) => unitId, // Get the unit ID as an argument
  (contactsState, unitId): Contact[] => {
    if (!unitId) {
      return []
    }

    if (!contactsState) {
      return []
    }

    // Filter contacts by the unit's ID
    return Object.values(contactsState)
      .filter((contact: RawContact) => {
        return (contact.attributes.unitIds ?? []).includes(unitId)
      })
      .map(hydrateRawContact)
  },
)
