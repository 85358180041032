import React from 'react'

import { Alert } from 'packages/common'

import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { Reservation } from 'app/fieldapp/store/reservations'
import { fullName, User } from 'app/fieldapp/store/users'
import { formatReservationDateTimeAttributes } from 'app/fieldapp/utils'

const useTranslations = (props: CreateVisitWarningsProps) => {
  const { t } = useI18n()

  const { checkoutDate: checkoutDateStr, checkoutTime: checkoutTimeStr } =
    formatReservationDateTimeAttributes(props.currentReservation)

  const userName = props.otherVisitUser ? fullName(props.otherVisitUser) : ''

  return {
    otherUserHasVisit: t(Slugs.createVisitErrorOtherUserVisit, {
      userName,
    }),
    unitIsOccupied: t(Slugs.createVisitErrorUnitOccupied, {
      date: checkoutDateStr,
      time: checkoutTimeStr,
    }),
    userHasVisit: t(Slugs.createVisitErrorUserHasVisit),
  }
}

export type CreateVisitWarningsProps = {
  currentReservation?: Reservation
  otherVisitUser?: User
  userHasExistingVisit: boolean
}

export const CreateVisitWarnings: React.FC<CreateVisitWarningsProps> =
  React.memo(props => {
    const { currentReservation, otherVisitUser, userHasExistingVisit } = props
    const strings = useTranslations(props)

    return (
      <>
        {!!userHasExistingVisit && (
          <Alert alertType={'danger'}>{strings.userHasVisit}</Alert>
        )}
        {!!currentReservation && (
          <Alert alertType={'warning'}>{strings.unitIsOccupied}</Alert>
        )}
        {!!otherVisitUser && (
          <Alert alertType={'info'}>{strings.otherUserHasVisit}</Alert>
        )}
      </>
    )
  })
