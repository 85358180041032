import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getType } from 'typesafe-actions'

import { NormalizedJSONApiResponse } from 'packages/utils/store'

import { CleansResponse } from '../cleans'
import { fetchCleanByIdAction, fetchCleansAction } from '../cleans/actions'
import { TasksResponse } from '../tasks'
import { TicketsResponse } from '../tickets'
import { fetchTicketByIdAction } from '../tickets/actions'
import { UnitResponse } from '../units'
import { fetchUnitByIdAction } from '../units/actions'
import { fetchVisitByIdAction, fetchVisitsAction } from '../visits/actions'
import { ReservationsResponse } from './reservations.types'
import { emptyNormalizedReservationsData } from './reservations.utils'

type RelatedActions = PayloadAction<
  NormalizedJSONApiResponse<
    | CleansResponse
    | ReservationsResponse
    | TasksResponse
    | TicketsResponse
    | UnitResponse
  >,
  string
>

const isRelatedAction = (action: RelatedActions): action is RelatedActions => {
  // Move these to the `isAnyOf` params after refactoring the action
  // creators to use createAsyncThunk
  switch (action.type) {
    case getType(fetchCleanByIdAction.success):
    case getType(fetchCleansAction.success):
    case getType(fetchUnitByIdAction.success):
    case getType(fetchVisitByIdAction.success):
    case getType(fetchVisitsAction.success):
    case getType(fetchTicketByIdAction.success):
      return true
    default:
      return false
  }
}

export const reservationsSlice = createSlice({
  extraReducers: builder => {
    builder.addMatcher(isRelatedAction, (state, action) => {
      const normalized =
        action.payload.normalized || emptyNormalizedReservationsData

      state.data = {
        ...state.data,
        ...normalized.reservation,
      }
    })
  },
  initialState: {
    data: {},
  },
  name: 'reservations',
  reducers: {},
})

export default reservationsSlice.reducer
