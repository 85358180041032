import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { isIOS } from 'mobile-device-detect'

import { Card } from 'packages/common'
import { centerWithFlex, colors, text } from 'packages/styles'

import { TaskHeader } from './TaskDetail.styles'

const cardSizingFix = isIOS
  ? css`
      word-break: break-word;
    `
  : css`
      overflow-wrap: anywhere;
    `

export const OwnerBadge = styled.span`
  ${centerWithFlex};
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 4px;
  color: ${colors.dusk};
  font-size: inherit;
  font-weight: 700;
  height: 20px;
  :not(div + span) {
    margin-left: 8px;
  }
  margin-right: 8px;
  padding: 0 8px;
`

export const TaskCardContainer = styled(Card)`
  ${cardSizingFix};
  cursor: pointer;
  font-size: 14px;
  position: relative;

  &:not(:first-of-type) {
    margin-top: 8px;
  }
`

export const TaskCardHeader = styled(TaskHeader)`
  ${text.bodyBoldTiny};
  justify-content: space-between;
  padding: 0 8px;
  text-transform: uppercase;
`

export const TaskCardContent = styled.div`
  padding: 16px 12px;
  position: relative;
`
export const UnitAddress = styled.div`
  ${text.bodyRegularSmall};
  align-self: flex-end;
  grid-column: 1;
  grid-row: 2;
  padding-right: 8px;
`

export const UnitCode = styled.div`
  ${text.headingLabel};
  color: ${colors.dusk60};
`

export const UnitName = styled.div`
  ${text.headingDefault};
  margin-bottom: 8px;
  margin-top: 2px;
`
