import styled from '@emotion/styled'
import React from 'react'

import { IconName, SvgIcon } from 'packages/iconic'
import { centerWithFlex, colors, text } from 'packages/styles'

import { Slugs, useI18n } from 'app/fieldapp/i18n'

const St = {
  Badge: styled.span`
    ${centerWithFlex};
    background: ${colors.midnight70};
    border-radius: 20px;
    color: ${colors.white};
    display: inline-flex;
    height: 26px;
    padding: 10px 15px;
    position: relative;
  `,
  BellEllipse: styled.span`
    ${centerWithFlex};
    background: ${colors.alert};
    border-radius: 50%;
    height: 14px;
    position: absolute;
    right: -2px;
    top: -3px;
    width: 14px;
  `,
  Content: styled.div`
    align-items: center;
    display: flex;
    gap: 6px;
  `,
  Text: styled.span`
    ${text.headingTinyCaps};
  `,
}

export enum OwnerCommentBadgeTestIds {
  bell = 'OwnerCommentBadge__bell',
  container = 'OwnerCommentBadge__container',
}

export type OwnerCommentBadgeProps = {
  className?: string
  hasBellAlert?: boolean
}

export const OwnerCommentBadge: React.FC<OwnerCommentBadgeProps> = React.memo(
  ({ className, hasBellAlert }) => {
    const { ut } = useI18n()

    return (
      <St.Badge
        className={className}
        data-testid={OwnerCommentBadgeTestIds.container}
      >
        <St.Content>
          <SvgIcon icon={IconName.messageSquare} size={16} />
          <St.Text>{ut(Slugs.owner)}</St.Text>
        </St.Content>
        {hasBellAlert && (
          <St.BellEllipse data-testid={OwnerCommentBadgeTestIds.bell}>
            <SvgIcon icon={IconName.bell} size={8} />
          </St.BellEllipse>
        )}
      </St.Badge>
    )
  },
)
