import styled from '@emotion/styled'
import * as React from 'react'

import { Button, ButtonProps } from 'packages/common'

const StyledButton = styled(Button)`
  align-items: center;
  display: flex;
  justify-content: center;
`

const PauseSquare = styled.div`
  background: white;
  border-radius: 3px;
  height: 16px;
  width: 16px;
`

type OmittedButtonProps = Omit<
  ButtonProps,
  'children' | 'loaderType' | 'buttonShape' | 'buttonType'
>

export type StopTimerButtonProps = OmittedButtonProps

export const StopTimerButton: React.FC<StopTimerButtonProps> = props => {
  return (
    <StyledButton buttonShape={'circle'} buttonType={'danger'} {...props}>
      <PauseSquare />
    </StyledButton>
  )
}
