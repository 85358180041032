import { pipe } from 'lodash/fp'

import { cleanIsDeepClean } from 'packages/grimoire'
import { makeOfflinePatchData } from 'packages/offline'
import { evolve, isDefined, removeUndefined } from 'packages/utils/misc'

import {} from 'app/fieldapp/store/otherTimers'
import { Clean } from 'app/fieldapp/store/cleans'
import { taskIsClean } from 'app/fieldapp/store/tasks/utils/tasks.utils'
import {
  GenericTimer,
  TimerType,
} from 'app/fieldapp/store/utils/timers/timers.types'
import { Visit } from 'app/fieldapp/store/visits'

import { timerPatchStoreTransforms } from '../../store/utils/timers/timers.utils'
import { TimerAPIPatch, TimerOffline } from './timers.types'

export const isValidPausedState = (timer: GenericTimer): boolean =>
  isDefined(timer.startedAt) &&
  isDefined(timer.stoppedAt) &&
  timer.isFinalized === false

export function makeOfflineTimer<T extends TimerType>(
  data: TimerAPIPatch<T>,
): TimerOffline<T> {
  // convert timestamps to strings for offline storage (and manually remove undefined)
  const evolvedOtherTime = pipe(
    removeUndefined,
    evolve(timerPatchStoreTransforms),
  )(data) as TimerAPIPatch<T>

  return makeOfflinePatchData<T>(evolvedOtherTime)
}

export const getTimerTypeForTask = (
  task: Clean | Visit,
): 'visit' | 'deep' | 'post-stay' => {
  if (taskIsClean(task)) return cleanIsDeepClean(task) ? 'deep' : 'post-stay'
  return 'visit'
}
