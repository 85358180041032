import { User } from 'packages/grimoire'

import { VisitTicketDetailsProps } from './VisitTicketDetails'

export type TicketVisitStatus =
  | 'neitherAssignedNorAttached'
  | 'assignedAndAttached'
  | 'onlyAssigned'
  | 'onlyAttached'

export const getTicketVisitStatus = (
  props: Pick<VisitTicketDetailsProps, 'assignee' | 'ticket' | 'visit'>,
  user: User | undefined,
): TicketVisitStatus => {
  const { assignee, ticket, visit } = props
  const isAssignedToUser = !!assignee?.id && assignee.id === user?.id
  const isAttachedToVisit = (visit?.ticketIds || []).includes(ticket.id)

  if (isAssignedToUser && isAttachedToVisit) return 'assignedAndAttached'
  if (isAssignedToUser && !isAttachedToVisit) return 'onlyAssigned'
  if (!isAssignedToUser && isAttachedToVisit) return 'onlyAttached'
  return 'neitherAssignedNorAttached'
}
