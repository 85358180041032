import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { Button, ModalConfig, useModalContext } from 'packages/common'
import { useI18n } from 'packages/i18n'
import { logInfo } from 'packages/wiretap/logging'

import { Slugs } from 'app/fieldapp/i18n'
import { getActiveUserAssignmentByClean } from 'app/fieldapp/store/assignments/selectors'
import { Clean } from 'app/fieldapp/store/cleans'
import { ApplicationState } from 'app/fieldapp/store/store'

import { ContractorRejectionModal } from './ContractorRejectionModal'

const useTranslations = () => {
  const { t } = useI18n()
  return {
    error: t(Slugs.cleanNotFoundError),
    reject: t(Slugs.reject),
  }
}

export type ContractorRejectionControlsProps = { clean: Clean }

export const ContractorRejectionControls: React.FC<ContractorRejectionControlsProps> =
  React.memo(({ clean }) => {
    const { showModal } = useModalContext()

    const strings = useTranslations()

    const assignment = useSelector((state: ApplicationState) =>
      getActiveUserAssignmentByClean(state, clean),
    )

    const isRejectingClean = useSelector(
      (state: ApplicationState) => state.ui.modals.cleanRejection.isRejecting,
    )

    const modalConfig: ModalConfig = useMemo(
      () => ({
        childRenderer: ({ beginClose }) =>
          assignment ? (
            <ContractorRejectionModal
              assignmentId={assignment.id}
              beginClose={beginClose}
              cleanId={clean.id}
            />
          ) : (
            // This should NEVER happen, but if it does, all the user can do is reload the page and hope for the best
            <div>{strings.error}</div>
          ),
      }),
      [assignment, clean.id, strings.error],
    )

    const openModal = useCallback(() => {
      try {
        showModal(modalConfig)
      } catch (e) {
        logInfo('Could not open modal', {
          error: JSON.stringify(e) || 'unknown',
        })
      }
    }, [modalConfig, showModal])

    return (
      <Button
        buttonType="danger"
        onClick={openModal}
        isLoading={isRejectingClean}
      >
        {strings.reject}
      </Button>
    )
  })
