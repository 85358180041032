import {
  JSONApiCleanTimesMap,
  OfflineCleanTime,
} from 'packages/grimoire/src/cleantime'

export * from 'packages/grimoire/src/cleantime'

/**********************************************************
 * REDUX TYPES
 *********************************************************/

export enum CleanTimesActionTypes {
  CREATE_CLEAN_TIME = 'CLEAN_TIMES/API/CREATE_CLEAN_TIME',
  CREATE_CLEAN_TIME_FAILURE = 'CLEAN_TIMES/CREATE_CLEAN_TIME_FAILURE',
  CREATE_CLEAN_TIME_SUCCESS = 'CLEAN_TIMES/CREATE_CLEAN_TIME_SUCCESS',

  CREATE_FINALIZED_CLEAN_TIME = 'CLEAN_TIMES/API/CREATE_FINALIZED_CLEAN_TIME',
  CREATE_FINALIZED_CLEAN_TIME_FAILURE = 'CLEAN_TIMES/CREATE_FINALIZED_CLEAN_TIME_FAILURE',
  CREATE_FINALIZED_CLEAN_TIME_SUCCESS = 'CLEAN_TIMES/CREATE_FINALIZED_CLEAN_TIME_SUCCESS',

  DELETE_CLEAN_TIME = 'CLEAN_TIMES/API/DELETE_CLEAN_TIME',
  DELETE_CLEAN_TIME_FAILURE = 'CLEAN_TIMES/DELETE_CLEAN_TIME_FAILURE',
  DELETE_CLEAN_TIME_SUCCESS = 'CLEAN_TIMES/DELETE_CLEAN_TIME_SUCCESS',

  FETCH_CLEAN_TIMES = 'CLEAN_TIMES/API/FETCH_CLEAN_TIMES',

  FETCH_CLEAN_TIMES_FAILURE = 'CLEAN_TIMES/FETCH_CLEAN_TIMES_FAILURE',
  FETCH_CLEAN_TIMES_SUCCESS = 'CLEAN_TIMES/FETCH_CLEAN_TIMES_SUCCESS',
  FETCH_CLEAN_TIME_BY_ID = 'CLEAN_TIMES/API/FETCH_CLEAN_TIME_BY_ID',

  FETCH_CLEAN_TIME_BY_ID_FAILURE = 'CLEAN_TIMES/FETCH_CLEAN_TIME_BY_ID_FAILURE',
  FETCH_CLEAN_TIME_BY_ID_SUCCESS = 'CLEAN_TIMES/FETCH_CLEAN_TIME_BY_ID_SUCCESS',
  OFFLINE_CREATE_CLEAN_TIME = 'CLEAN_TIMES/OFFLINE/CREATE_CLEAN_TIME',

  OFFLINE_CREATE_CLEAN_TIME_FAILURE = 'CLEAN_TIMES/OFFLINE/CREATE_CLEAN_TIME_FAILURE',
  OFFLINE_CREATE_CLEAN_TIME_SUCCESS = 'CLEAN_TIMES/OFFLINE/CREATE_CLEAN_TIME_SUCCESS',
  OFFLINE_UPDATE_CLEAN_TIME = 'CLEAN_TIMES/OFFLINE/UPDATE_CLEAN_TIME',

  OFFLINE_UPDATE_CLEAN_TIME_FAILURE = 'CLEAN_TIMES/OFFLINE/UPDATE_CLEAN_TIME_FAILURE',
  OFFLINE_UPDATE_CLEAN_TIME_SUCCESS = 'CLEAN_TIMES/OFFLINE/UPDATE_CLEAN_TIME_SUCCESS',
  RELOAD_OFFLINE_CLEAN_TIMES = 'CLEAN_TIMES/RELOAD_OFFLINE_CLEAN_TIMES',

  RELOAD_OFFLINE_CLEAN_TIMES_FAILURE = 'CLEAN_TIMES/RELOAD_OFFLINE_CLEAN_TIMES_FAILURE',
  RELOAD_OFFLINE_CLEAN_TIMES_SUCCESS = 'CLEAN_TIMES/RELOAD_OFFLINE_CLEAN_TIMES_SUCCESS',
  REMOVE_LOCAL_CLEAN_TIME = 'CLEAN_TIMES/REMOVE_LOCAL_CLEAN_TIME',

  SILENT_DELETE_CLEAN_TIME = 'CLEAN_TIMES/API/SILENT_DELETE_CLEAN_TIME',
  SILENT_DELETE_CLEAN_TIME_FAILURE = 'CLEAN_TIMES/SILENT_DELETE_CLEAN_TIME_FAILURE',
  SILENT_DELETE_CLEAN_TIME_SUCCESS = 'CLEAN_TIMES/SILENT_DELETE_CLEAN_TIME_SUCCESS',

  UPDATE_CLEAN_TIME = 'CLEAN_TIMES/API/UPDATE_CLEAN_TIME',
  UPDATE_CLEAN_TIME_FAILURE = 'CLEAN_TIMES/UPDATE_CLEAN_TIME_FAILURE',
  UPDATE_CLEAN_TIME_SUCCESS = 'CLEAN_TIMES/UPDATE_CLEAN_TIME_SUCCESS',
}

export type CleanTimesState = {
  data: JSONApiCleanTimesMap
  error?: Error
  offlineData: Record<string, OfflineCleanTime>
  requestPending: boolean
}
