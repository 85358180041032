import { createSelector } from 'reselect'

import { ApplicationState } from '../../store'
import {
  Housekeeper,
  HousekeepersState,
  RawHousekeeper,
} from '../housekeepers.types'
import { hydrateRawHousekeeper } from '../housekeepers.utils'

const getHousekeepersState = (state: ApplicationState): HousekeepersState =>
  state.housekeepers

const getUserId = (
  _state: ApplicationState,
  id: string | undefined,
): string | undefined => id

export const getHkByUserId = createSelector(
  getHousekeepersState,
  getUserId,
  (hksState, userId): Housekeeper | undefined => {
    if (!userId) return undefined
    const rawHousekeeper = Object.values(hksState.data).find(
      (rawHk: RawHousekeeper) => rawHk.relationships.user.data?.id === userId,
    )
    return rawHousekeeper && hydrateRawHousekeeper(rawHousekeeper)
  },
)
