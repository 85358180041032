import styled from '@emotion/styled'
import React from 'react'

import { Button, FormProps, Loader } from 'packages/common'
import { colors } from 'packages/styles'

import { Slugs, useI18n } from 'app/fieldapp/i18n'

const St = {
  Container: styled.div`
    display: flex;
    justify-content: space-between;
    margin-left: auto;
  `,
  Form: styled.form`
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    margin-top: 8px;
    position: relative;
  `,
  Submit: styled(Button)`
    &:focus {
      outline: none; // this outline overflows the loader and just looks bad
    }
  `,
  TextArea: styled.textarea`
    border-radius: 4px;
    border: 1px solid ${colors.midnight20};
    display: flex;
    flex-grow: 1;
    font-size: 16px;
    margin-bottom: 16px;
    margin-top: 16px;
    max-height: 62px;
    max-width: 100%;
    padding: 8px 12px 8px 12px;
    width: 100%;
  `,
}

const useTranslations = () => {
  const { ut } = useI18n()
  return {
    // todo update this translation
    notePlaceholder: ut(Slugs.notePlaceholder),
    save: ut(Slugs.save),
  }
}

export type InspectionNoteFormValues = {
  inspectionFlagNotes: string
}

export type InspectionNoteFormProps = FormProps<InspectionNoteFormValues> & {
  flagId: string
}

export const InspectionNoteForm: React.FC<InspectionNoteFormProps> = React.memo(
  ({ flagId, formState, handlers, requestState }) => {
    const strings = useTranslations()
    return (
      <St.Form onSubmit={handlers.submit}>
        <St.TextArea
          id={`inspectionFlagNotes-${flagId}`}
          maxLength={500}
          name="inspectionFlagNotes"
          onChange={handlers.change}
          placeholder={strings.notePlaceholder}
          rows={5}
          value={formState.values.inspectionFlagNotes}
        />

        <St.Container>
          <St.Submit disabled={!formState.canSubmit} isFormSubmit={true}>
            {strings.save}
          </St.Submit>
        </St.Container>
        {requestState.loading && <Loader />}
      </St.Form>
    )
  },
)
