import styled from '@emotion/styled'
import * as React from 'react'

import { Button, ButtonProps } from 'packages/common'
import { SvgIcon, IconName } from 'packages/iconic'

const StyledSvgIcon = styled(SvgIcon)`
  padding-left: 2px;
`

type OmittedButtonProps = Omit<
  ButtonProps,
  'children' | 'loaderType' | 'buttonShape' | 'buttonType'
>

export type StartTimerButtonProps = OmittedButtonProps

export const StartTimerButton: React.FC<StartTimerButtonProps> = props => {
  return (
    <Button buttonShape={'circle'} buttonType={'primary'} {...props}>
      <StyledSvgIcon icon={IconName.playIcon} size={18} />
    </Button>
  )
}
