import { RequestOptions } from 'packages/utils/store/jsonapi.types'

import { api } from '../axiosInstance'
import { emptyInspectionFlagResponse } from './inspectionFlag.utils'
import {
  InspectionFlagServiceResponse,
  InspectionFlagApiResponse,
} from './inspectionFlags.types'

const URL = '/inspection_flags'

export const inspectionFlagService = {
  async updateInspectionFlag(
    id: string,
    patchData: RequestOptions,
  ): InspectionFlagServiceResponse {
    const response: InspectionFlagApiResponse = await api.patch(
      `${URL}/${id}`,
      patchData,
    )

    return response?.data || emptyInspectionFlagResponse
  },
}
