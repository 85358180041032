import { noop } from 'lodash/fp'
import { createAsyncAction } from 'typesafe-actions'

import { ReduxActionCallbacks } from 'packages/grimoire/src/utils'
import { RequestConfig, RequestOptions } from 'packages/utils/store'

import { assignmentsService } from '../assignments.service'
import {
  AssignmentRejectApiAttributes,
  AssignmentRejectData,
  AssignmentsActionTypes,
  NormalizedAssignmentsApiResponse,
} from '../assignments.types'

export const rejectAssignmentAction = createAsyncAction(
  AssignmentsActionTypes.REJECT_ASSIGNMENT,
  AssignmentsActionTypes.REJECT_ASSIGNMENT_SUCCESS,
  AssignmentsActionTypes.REJECT_ASSIGNMENT_FAILURE,
)<RequestConfig<NormalizedAssignmentsApiResponse>, { cleanId: string }, Error>()

export const buildRequestData = (
  patchData: AssignmentRejectData,
): RequestOptions => {
  const { rejectionReason } = patchData

  const attributes: AssignmentRejectApiAttributes = {
    rejection_reason: rejectionReason,
  }

  return {
    data: {
      attributes,
      id: patchData.id,
      type: 'assignment',
    },
  }
}

/**
 * Action that is specific to assignment rejection, this patches the assignment with the rejection reason
 * We need a seperate action for this because it needs to trigger a removal of the clean from the store, while a normal patch does not
 * On success, we return the cleanId this assignment is associated with, so we that can handle removing it from the store in the cleans reducer
 */
export const rejectAssignment =
  (
    rejectData: AssignmentRejectData & { cleanId: string },
    callbacks: ReduxActionCallbacks = {},
  ) =>
  async dispatch => {
    const { onSuccess = noop, onError = noop } = callbacks
    try {
      const assignmentId = rejectData.id
      const requestData = buildRequestData(rejectData)
      const request = assignmentsService.updateAssignment.bind(
        null,
        assignmentId,
        requestData,
      )

      const result = await dispatch(rejectAssignmentAction.request({ request }))
      dispatch(rejectAssignmentAction.success({ cleanId: rejectData.cleanId }))

      onSuccess()
      return result.normalized
    } catch (error) {
      dispatch(rejectAssignmentAction.failure(error))
      onError(error)
      throw error
    }
  }
