import { createAsyncAction } from 'typesafe-actions'

import { OfflineError } from 'packages/offline'
import { apiDateStringWithSeconds } from 'packages/utils/dateHelpers'
import {
  OfflineRequestConfig,
  RequestOptions,
  setRequestRelationship,
} from 'packages/utils/store'

import {
  NormalizedTicketTimesApiResponse,
  TicketTimePostData,
  TicketTimePostApiAttributes,
  TicketTimesActionTypes,
} from 'app/fieldapp/store/ticket-times'

import { ticketTimesService } from '../ticket-times.service'
import { fetchTicketTimeById } from './fetchTicketTimeById'

export const createTicketTimeAction = createAsyncAction(
  TicketTimesActionTypes.CREATE_TICKET_TIME,
  TicketTimesActionTypes.CREATE_TICKET_TIME_SUCCESS,
  TicketTimesActionTypes.CREATE_TICKET_TIME_FAILURE,
)<
  OfflineRequestConfig<NormalizedTicketTimesApiResponse, TicketTimePostData>,
  NormalizedTicketTimesApiResponse,
  Error
>()

export const buildRequestData = (
  postData: TicketTimePostData,
): RequestOptions => {
  const { startedAt, ticketId, userId } = postData
  const attributes: TicketTimePostApiAttributes = {
    started_at: apiDateStringWithSeconds(startedAt),
    ticket_id: ticketId,
  }

  return {
    data: {
      attributes,
      relationships: {
        ...setRequestRelationship('user', userId),
      },
      type: 'ticket_time',
    },
  }
}

export const createTicketTime =
  (postData: TicketTimePostData) => async dispatch => {
    try {
      const requestData = buildRequestData(postData)
      const request = ticketTimesService.createTicketTime.bind(
        null,
        requestData,
      )
      const result = await dispatch(
        createTicketTimeAction.request({ data: postData, request }),
      )
      dispatch(createTicketTimeAction.success(result))

      // // now re-fetch the newly-created TicketTime, so we can store the full data locally
      const newTicketTimeId = Object.keys(result.normalized.ticketTime)[0]
      await dispatch(fetchTicketTimeById(newTicketTimeId))
    } catch (error) {
      if (error === OfflineError) return
      dispatch(createTicketTimeAction.failure(error))
      throw error
    }
  }
