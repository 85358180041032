import { AxiosResponse } from 'axios'
import { snakeCase } from 'lodash/fp'

import {
  AllTaskPhotoAttributes,
  AllTaskPhotoAttributeNames,
} from 'packages/grimoire/src/taskPhoto'
import {
  JSONApiObjectWithRelationships,
  JSONApiObjectWithRelationshipsMap,
  NormalizedJSONApiResponse,
  ToOneRelationship,
} from 'packages/grimoire/src/utils'

/**********************************************************
 * SERVICE TYPES
 *********************************************************/
export type TaskPhotosResponse = {
  taskPhoto: JsonApiTaskPhotosMap
}

export type TaskPhotoPostAttributes = {
  categoryId: string
  taskId: string
  userId: string
}

export type TaskPhotoPostApiAttributes = {
  category_id: string
}

type TaskPhotoRelKeys = 'task' | 'uploaded_by'
export type TaskPhotoPostApiRelationships = {
  [key in TaskPhotoRelKeys]: ToOneRelationship
}

export type NormalizedTaskPhotosApiResponse =
  NormalizedJSONApiResponse<TaskPhotosResponse>

export type TaskPhotosServiceResponse = Promise<NormalizedTaskPhotosApiResponse>

export type TaskPhotosApiResponse =
  AxiosResponse<NormalizedTaskPhotosApiResponse>

/**********************************************************
 * REDUX TYPES
 *********************************************************/
export enum TaskPhotosActionTypes {
  CREATE_TASK_PHOTO = 'TASK_PHOTOS/API/CREATE_TASK_PHOTO',
  CREATE_TASK_PHOTO_FAILURE = 'TASK_PHOTOS/CREATE_TASK_PHOTO_FAILURE',
  CREATE_TASK_PHOTO_SUCCESS = 'TASK_PHOTOS/CREATE_TASK_PHOTO_SUCCESS',

  DELETE_TASK_PHOTO = 'TASK_PHOTOS/API/DELETE_TASK_PHOTO',
  DELETE_TASK_PHOTO_FAILURE = 'TASK_PHOTOS/DELETE_TASK_PHOTO_FAILURE',
  DELETE_TASK_PHOTO_SUCCESS = 'TASK_PHOTOS/DELETE_TASK_PHOTO_SUCCESS',

  FETCH_TASK_PHOTO = 'TASK_PHOTOS/API/FETCH_v_PHOTO',
  FETCH_TASK_PHOTO_FAILURE = 'TASK_PHOTOS/FETCH_TASK_PHOTO_FAILURE',
  FETCH_TASK_PHOTO_SUCCESS = 'TASK_PHOTOS/FETCH_TASK_PHOTO_SUCCESS',
}

export type TaskPhotosState = {
  data: JsonApiTaskPhotosMap
}

/**********************************************************
 * TASK PHOTO
 *********************************************************/
export type TaskPhoto = {
  id: string
} & TaskPhotoAttributes

export type TaskPhotoAttributes = AllTaskPhotoAttributes

export const TaskPhotoAttributeNames = AllTaskPhotoAttributeNames

export const TaskPhotoApiFields = TaskPhotoAttributeNames.map(snakeCase)

export type TaskPhotoRelationships = {
  clean: ToOneRelationship
  uploadedBy: ToOneRelationship
}

export type RawTaskPhoto = JSONApiObjectWithRelationships<
  TaskPhotoAttributes,
  TaskPhotoRelationships
>

export type JsonApiTaskPhotosMap = JSONApiObjectWithRelationshipsMap<
  TaskPhotoAttributes,
  TaskPhotoRelationships
>
