import styled from '@emotion/styled'
import * as React from 'react'

import { colors, truncateTextWithEllipsis } from 'packages/styles'
import { formatSecondsAsDigitalClock } from 'packages/utils/mathHelpers'

import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { OtherTimerType } from 'app/fieldapp/store/otherTimers'
import { Unit } from 'app/fieldapp/store/units'

const St = {
  TimerDetail: styled.div`
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-weight: bold;
  `,

  TimerTitle: styled.div`
    ${truncateTextWithEllipsis};
    color: ${colors.dusk};
  `,
}

const otherTimerTypeSlugMap = {
  [OtherTimerType.DRIVE]: Slugs.drive,
  [OtherTimerType.LAUNDRY]: Slugs.laundry,
  [OtherTimerType.OTHER]: Slugs.other,
  [OtherTimerType.TRAINING]: Slugs.training,
}

const useTranslations = (props: Pick<TimerDetailProps, 'otherTimerType'>) => {
  const { ut } = useI18n()
  const { otherTimerType } = props

  return {
    otherTimerTitle: otherTimerType
      ? ut(otherTimerTypeSlugMap[otherTimerType])
      : '',
    unknown: ut(Slugs.unknown),
  }
}

export enum TimerDetailTestIds {
  otherTimerType = 'TimerDetail__otherTimerType',
  unit = 'TimerDetail__unit',
}

export type TimerDetailProps = {
  otherTimerType?: OtherTimerType
  time: number
  unit?: Unit
}

export const TimerDetail: React.FC<TimerDetailProps> = React.memo(
  ({ otherTimerType, time, unit }) => {
    const { otherTimerTitle, unknown } = useTranslations({ otherTimerType })

    const unitString = unit ? `${unit.unitCode} - ${unit.name}` : unknown
    const timeString = formatSecondsAsDigitalClock(time)

    const testId = otherTimerType
      ? TimerDetailTestIds.otherTimerType
      : TimerDetailTestIds.unit

    return (
      <St.TimerDetail>
        <St.TimerTitle data-testid={testId}>
          {otherTimerType ? otherTimerTitle : unitString}
        </St.TimerTitle>

        <div>{timeString}</div>
      </St.TimerDetail>
    )
  },
)
