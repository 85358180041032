import styled from '@emotion/styled'
import React from 'react'

import { Drawer, DrawerState } from 'packages/common'

import { CreateVisitContainer } from './CreateVisit.container'

const St = {
  Drawer: styled(Drawer)`
    min-width: 375px;
  `,
}

type CreateVisitWithTicketDrawerProps = {
  closeDrawer: () => void
  completeDrawerClose: () => void
  drawerState: DrawerState
  visitUnitId?: string
}

export const CreateVisitWithTicketDrawer: React.FC<CreateVisitWithTicketDrawerProps> =
  React.memo(
    ({ closeDrawer, completeDrawerClose, drawerState, visitUnitId }) => {
      return (
        <>
          {drawerState.isOpen && (
            <St.Drawer
              afterExit={completeDrawerClose}
              forceClose={drawerState.forceClose}
              isOpen={drawerState.isOpen}
            >
              <CreateVisitContainer
                initialUnitId={visitUnitId}
                onCancel={closeDrawer}
                onSuccess={closeDrawer}
              />
            </St.Drawer>
          )}
        </>
      )
    },
  )
