import styled from '@emotion/styled'
import React from 'react'

import { Alert } from 'packages/common'
import { useI18n } from 'packages/i18n'
import { text } from 'packages/styles'

import { Slugs } from 'app/fieldapp/i18n'
import { CrossCoverage } from 'app/fieldapp/store/crossCoverage/crossCoverage.types'

import { UpcomingCoverageEventContainer } from './UpcomingCoverageEvent/UpcomingCoverageEvent.container'

const useTranslations = () => {
  const { t } = useI18n()

  return {
    loading: t(Slugs.loading),
    upcomingCoverage: t(Slugs.upcomingCoverage),
    upcomingCrossCoveragesEmpty: t(Slugs.upcomingCrossCoveragesEmpty),
    upcomingCrossCoveragesError: t(Slugs.upcomingCrossCoveragesError),
  }
}

export enum UpcomingCoverageEventsTestIds {
  container = 'UpcomingCoverageEvents__container',
  errorFetchingCoverages = 'UpcomingCoverageEvents__errorFetchingCoverages',
  loadingCoverages = 'UpcomingCoverageEvents__loading',
  noCoverages = 'UpcomingCoverageEvents__noCoverages',
}

const St = {
  Heading: styled.h4`
    ${text.headingSCaps}
    margin-bottom: 24px;
  `,
  Main: styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;
  `,
}

const Container: React.FC<React.PropsWithChildren> = ({ children }) => {
  const strings = useTranslations()
  return (
    <div data-testid={UpcomingCoverageEventsTestIds.container}>
      <St.Heading>{strings.upcomingCoverage}</St.Heading>
      <St.Main>{children}</St.Main>
    </div>
  )
}

export type UpcomingCoverageEventsProps = {
  coverageEvents?: CrossCoverage[]
  isError: boolean
  isLoading: boolean
}

export const UpcomingCoverageEvents: React.FC<UpcomingCoverageEventsProps> =
  React.memo(({ isLoading, isError, coverageEvents }) => {
    const strings = useTranslations()

    if (isLoading === true) {
      return (
        <Container>
          <p data-testid={UpcomingCoverageEventsTestIds.loadingCoverages}>
            {strings.loading}
          </p>
        </Container>
      )
    }

    if (isError) {
      return (
        <Container>
          <Alert
            dataTestId={UpcomingCoverageEventsTestIds.errorFetchingCoverages}
            alertType="warning"
          >
            {strings.upcomingCrossCoveragesError}
          </Alert>
        </Container>
      )
    }

    if (coverageEvents?.length === 0) {
      return (
        <Container>
          <p data-testid={UpcomingCoverageEventsTestIds.noCoverages}>
            {strings.upcomingCrossCoveragesEmpty}
          </p>
        </Container>
      )
    }

    return (
      <Container>
        {coverageEvents?.map(coverageEvent => (
          <UpcomingCoverageEventContainer
            key={coverageEvent.id}
            coverageEvent={coverageEvent}
          />
        ))}
      </Container>
    )
  })
