import { noop } from 'lodash/fp'
import { createAsyncAction } from 'typesafe-actions'

import { ReduxActionCallbacks } from 'packages/grimoire/src/utils'
import { apiDateStringWithSeconds } from 'packages/utils/dateHelpers'
import {
  RequestConfig,
  RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import { NormalizedTasksApiResponse } from '../../tasks'
import { tasksService } from '../../tasks/tasks.service'
import {
  VisitPatchApiAttributes,
  VisitPatchData,
  VisitsActionTypes,
} from '../visits.types'
import { fetchVisitById } from './fetchVisitById'

export const updateVisitAction = createAsyncAction(
  VisitsActionTypes.UPDATE_VISIT,
  VisitsActionTypes.UPDATE_VISIT_SUCCESS,
  VisitsActionTypes.UPDATE_VISIT_FAILURE,
)<
  RequestConfig<NormalizedTasksApiResponse>,
  NormalizedTasksApiResponse,
  Error
>()

export const buildRequestData = (patchData: VisitPatchData): RequestOptions => {
  const {
    completedAt,
    inspectionCompletedAt,
    isActive,
    inspectionNotes,
    inspectionUpdateConfig,
    startedAt,
  } = patchData

  const attributes: Partial<VisitPatchApiAttributes> = {
    completed_at: completedAt && apiDateStringWithSeconds(completedAt),
    inspection_checklist: !!inspectionUpdateConfig
      ? {
          checklists: [
            {
              category: {
                id: inspectionUpdateConfig?.category?.id,
              },
              items: [
                {
                  id: inspectionUpdateConfig?.item?.id,
                  is_complete: inspectionUpdateConfig?.item?.isComplete,
                },
              ],
            },
          ],
        }
      : undefined,
    inspection_completed_at:
      inspectionCompletedAt && apiDateStringWithSeconds(inspectionCompletedAt),
    inspection_notes: inspectionNotes,
    is_active: isActive,
    started_at: startedAt && apiDateStringWithSeconds(startedAt),
  }

  const tickets = Array.isArray(patchData.ticketIds)
    ? patchData.ticketIds.map(id => ({
        id,
        type: 'ticket',
      }))
    : undefined

  const relationships = {
    ...(tickets && {
      tickets: { data: [...tickets] },
    }),
  }

  return {
    data: {
      attributes,
      id: patchData.id,
      relationships,
      type: 'task',
    },
  }
}

export const updateVisit =
  (patchData: VisitPatchData, callbacks: ReduxActionCallbacks = {}) =>
  async dispatch => {
    const { onError = noop, onSuccess = noop } = callbacks
    try {
      const visitId = patchData.id
      const requestData = buildRequestData(patchData)
      const request = tasksService.updateTask.bind(null, visitId, requestData)
      const result = await dispatch(updateVisitAction.request({ request }))
      dispatch(updateVisitAction.success(result))

      // re-fetch the associated visit to ensure we have the latest version locally
      await dispatch(fetchVisitById(visitId))

      onSuccess()
      return result.normalized
    } catch (error) {
      dispatch(updateVisitAction.failure(error))
      onError()
      throw error
    }
  }
