import styled from '@emotion/styled'
import React from 'react'
import { useSelector } from 'react-redux'

import { Button, ModalConfig, useModalContext } from 'packages/common'
import { useManageConnectedDrawerState } from 'packages/common/src/modals/Drawer/hooks/useManageConnectedDrawerState'
import { IconName, SvgIcon } from 'packages/iconic'
import { colors, text } from 'packages/styles'

import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { ComputedWorkingStatus } from 'app/fieldapp/store/coverage/coverage.types'
import { getWorkingStatusFromCoverageTypeAndUsers } from 'app/fieldapp/store/coverage/selectors'
import { ApplicationState } from 'app/fieldapp/store/store'
import {
  setCrossCoverageDrawer,
  setOnCallTaskDrawer,
  setStandardAvailabilityDrawer,
  setStandardCoveragePartnerDrawer,
} from 'app/fieldapp/store/ui/actions'
import {
  getStandardAvailabilityDrawerState,
  getOnCallTaskDrawerState,
  getStandardCoveragePartnerDrawerState,
} from 'app/fieldapp/store/ui/selectors'
import { getCrossCoverageDrawerState } from 'app/fieldapp/store/ui/selectors/getCrossCoverageDrawerState'
import { getUserById } from 'app/fieldapp/store/users/selectors'

import {
  TimelineEntry,
  getWorkingStatusText,
  isCoveredEntry,
  isCrossCoverageEntry,
} from './DayScheduleTimeline.utils'

const St = {
  Button: styled(Button)`
    margin-left: 0px !important;
  `,
  ButtonsContainer: styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
  `,
  EntryContent: styled.div<{ workingStatus: ComputedWorkingStatus }>`
    ${text.bodyBoldTiny};
    align-items: center;

    background: ${({ workingStatus }) =>
      ({
        covered: colors.midnight20,
        oncall: colors.midnight70,
        requested: colors.keyWest,
      }[workingStatus])};
    border-radius: 4px;
    border: ${({ workingStatus }) =>
      ({
        covered: `1px solid ${colors.midnight70}`,
        oncall: `1px solid ${colors.midnight70}`,
        requested: `1px solid ${colors.keyWest}`,
      }[workingStatus])};
    color: ${({ workingStatus }) =>
      ({
        covered: colors.dusk,
        oncall: colors.white,
        requested: colors.dusk,
      }[workingStatus])};
    cursor: pointer;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: center;
    padding: 12px 16px;
  `,
  EntryContentCoveredIcon: styled(SvgIcon)`
    color: ${colors.success};
    margin-right: 0;
    position: absolute;
    right: 8px;
    top: calc(50% - 12px);
  `,
  EntryContentRequestedIcon: styled(SvgIcon)`
    margin-right: 0;
    position: absolute;
    right: 8px;
    top: calc(50% - 12px);
  `,
  InfoIcon: styled(SvgIcon)`
    background-color: ${colors.midnight20};
    border-radius: 50%;
    padding: 4px;
  `,
  ModalContainer: styled.div`
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
  `,
  ModalText: styled.div`
    ${text.bodyRegularSmall};
    margin-bottom: 16px;
  `,
  ModalTitle: styled.div`
    ${text.headingDefault};
    align-items: center;
    display: flex;
    gap: 2px;
  `,
}

const NonCrossCoverageTimeOffModal: React.FC<{
  beginClose: () => void
}> = props => {
  const { t } = useI18n()

  const standardAvailabilityDrawerManager = useManageConnectedDrawerState({
    dispatchableToggleDrawerAction: setStandardAvailabilityDrawer,
    selector: getStandardAvailabilityDrawerState,
  })

  const coveragePartnerDrawerManager = useManageConnectedDrawerState({
    dispatchableToggleDrawerAction: setStandardCoveragePartnerDrawer,
    selector: getStandardCoveragePartnerDrawerState,
  })

  const openStandardAvailabilityDrawer = () => {
    props.beginClose()
    standardAvailabilityDrawerManager.openDrawer()
  }

  const openCoveragePartnerDrawer = () => {
    props.beginClose()
    coveragePartnerDrawerManager.openDrawer()
  }

  return (
    <St.ModalContainer>
      <St.ModalTitle>
        <St.InfoIcon icon={IconName.info} />
        {t(Slugs.timeOffBlock)}
      </St.ModalTitle>
      <St.ModalText>{t(Slugs.timeOfTextBlock)}</St.ModalText>
      <St.ButtonsContainer>
        <St.Button buttonType="text" onClick={openStandardAvailabilityDrawer}>
          {t(Slugs.editStandardAvailability)}
        </St.Button>
        <St.Button buttonType="text" onClick={openCoveragePartnerDrawer}>
          {t(Slugs.editCoveragePartner)}
        </St.Button>
      </St.ButtonsContainer>
    </St.ModalContainer>
  )
}

const useCoverageEntryTranslations = () => {
  const { t } = useI18n()

  return {
    isCoveringStatus: t(Slugs.isCoveringStatus),
    isRequested: t(Slugs.isRequested),
    youAreOncall: t(Slugs.youAreOncall),
  }
}

export type CoverageEntryProps = {
  entry: TimelineEntry
}

export const CoverageEntry: React.FC<CoverageEntryProps> = React.memo(
  ({ entry }) => {
    const crossCoverageDrawerManager = useManageConnectedDrawerState({
      dispatchableToggleDrawerAction: setCrossCoverageDrawer,
      selector: getCrossCoverageDrawerState,
    })

    const onCallTaskDrawerManager = useManageConnectedDrawerState({
      dispatchableToggleDrawerAction: setOnCallTaskDrawer,
      selector: getOnCallTaskDrawerState,
    })

    const user = useSelector((state: ApplicationState) =>
      isCoveredEntry(entry) ? getUserById(state, entry.userId) : undefined,
    )

    const requestedUser = useSelector((state: ApplicationState) =>
      isCoveredEntry(entry)
        ? getUserById(state, entry.requestedUserId)
        : undefined,
    )

    const nonCrossCoverageTimeOffModalConfig: ModalConfig = {
      childRenderer: ({ beginClose }) => (
        <NonCrossCoverageTimeOffModal beginClose={beginClose} />
      ),
    }

    const { showModal } = useModalContext()

    const workingStatus = React.useMemo(
      () =>
        getWorkingStatusFromCoverageTypeAndUsers({
          coverageType: entry.coverageType,
          requestedUserId: requestedUser?.id,
          userId: user?.id,
        }),
      [entry.coverageType, user?.id, requestedUser?.id],
    )

    const handleOnCallDrawerClick = () => {
      if (workingStatus === 'oncall') {
        onCallTaskDrawerManager.openDrawer({
          coverageId: entry.id,
        })
      }

      if (isCrossCoverageEntry(entry)) {
        crossCoverageDrawerManager.openDrawer({
          crossCoverageId: entry.crossCoverageId,
          systemAssignedUserId: user?.id,
        })
      }

      if (!isCrossCoverageEntry(entry) && workingStatus !== 'oncall') {
        showModal(nonCrossCoverageTimeOffModalConfig)
      }
    }

    const strings = useCoverageEntryTranslations()

    return (
      <St.EntryContent
        workingStatus={workingStatus}
        onClick={handleOnCallDrawerClick}
      >
        {getWorkingStatusText(workingStatus, strings, user, requestedUser)}
        {workingStatus === 'covered' && (
          <St.EntryContentCoveredIcon icon={IconName.checkCircle2} size={24} />
        )}
        {workingStatus === 'requested' && (
          <St.EntryContentRequestedIcon
            icon={IconName.alertTriangle3}
            size={24}
          />
        )}
      </St.EntryContent>
    )
  },
)
