import React from 'react'

import { IconName, SvgIcon } from 'packages/iconic'

import { useCrossCoverageDrawerTranslations } from '../../../useCrossCoverageDrawerTranslations'
import St from '../../../utils/CrossCoverageDrawer.styles'

export const IsRepeatingFormModal: React.FC = () => {
  const strings = useCrossCoverageDrawerTranslations()

  return (
    <>
      <St.CoverageRepeatInfoHeading>
        <SvgIcon icon={IconName.info} />
        {strings.repeatingEvents}
      </St.CoverageRepeatInfoHeading>
      <St.CoverageRepeatInfoContent>
        {strings.coverageRepeatTooltipText}
      </St.CoverageRepeatInfoContent>
    </>
  )
}
