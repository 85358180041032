import styled from '@emotion/styled'
import React from 'react'

import { useI18n } from 'packages/i18n'
import { IconName, SvgIcon } from 'packages/iconic'
import { colors, text } from 'packages/styles'
import {
  createDateString,
  DateFormat,
  formatLocalized,
} from 'packages/utils/dateHelpers'

import { StartTimerButton } from 'app/fieldapp/components/timers/components/Buttons/StartTimerButton'
import { Slugs } from 'app/fieldapp/i18n'
import { Unit } from 'app/fieldapp/store/units'

const St = {
  ControlButton: styled(StartTimerButton)``,
  InspectionInfo: styled.div`
    align-items: center;
    display: flex;
    grid-gap: 16px;
  `,
  InspectionType: styled.div`
    ${text.bodyBoldSmall};
    padding-top: 1px; // for better visual centering with icon
    text-transform: uppercase;
  `,
  TextWrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  `,
  TimerControls: styled.div`
    align-items: center;
    background-color: ${colors.midnight4};
    border: 1px solid ${colors.midnight20};
    border-radius: 3px;
    display: flex;
    justify-content: space-between;
    padding: 16px;
  `,
  Timestamp: styled.div`
    ${text.bodyRegularTiny};
  `,
}

const useTranslations = () => {
  const { ut } = useI18n()
  return {
    lastCompleted: ut(Slugs.lastCompleted),
    noActivity: ut(Slugs.noActivity),
    type: ut(Slugs.routine),
  }
}

// minor helper hook to deal with the multiple possible states of "last completed" text
const useParseDateText = (unit: Unit | undefined) => {
  const strings = useTranslations()

  const { lastInspectionCompletedAt } = unit?.statistics || {}
  const date =
    lastInspectionCompletedAt && createDateString(lastInspectionCompletedAt)

  return date
    ? formatLocalized(date, DateFormat.MonthShortDateAndYear)
    : strings.noActivity
}

export enum VisitInspectionTimerControlsTestIds {
  button = 'VisitInspectionTimerControls__button',
  container = 'VisitInspectionTimerControls__container',
}

export type VisitInspectionTimerControlsProps = {
  disabled: boolean
  isLoading: boolean
  onStart: () => void
  unit?: Unit
}

export const VisitInspectionTimerControls: React.FC<VisitInspectionTimerControlsProps> =
  React.memo(({ onStart, disabled, isLoading, unit }) => {
    const strings = useTranslations()

    const lastCompletedText = useParseDateText(unit)

    return (
      <St.TimerControls
        data-testid={VisitInspectionTimerControlsTestIds.container}
      >
        <St.InspectionInfo>
          <SvgIcon icon={IconName.homePast} size={20} />
          <St.TextWrapper>
            <St.InspectionType>{strings.type}</St.InspectionType>
            <St.Timestamp>
              {strings.lastCompleted}: {lastCompletedText}
            </St.Timestamp>
          </St.TextWrapper>
        </St.InspectionInfo>
        <StartTimerButton
          dataTestId={VisitInspectionTimerControlsTestIds.button}
          isLoading={isLoading}
          disabled={disabled}
          onClick={onStart}
        />
      </St.TimerControls>
    )
  })
