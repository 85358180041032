import React from 'react'

import { ModalConfig, useModalContext } from '../../contexts'
import { ModalChildrenProps } from '../../ModalPortal'
import { ToggleModal, ToggleModalProps } from './ToggleModal'

export type ToggleModalHookProps = Omit<
  ToggleModalProps,
  'beginClose' | 'onConfirm'
> &
  Pick<ModalConfig, 'modalProps'>

type ShowModalArgs = {
  config: ToggleModalHookProps['config']
  onConfirm: () => Promise<unknown>
}

export type UseToggleModal = {
  showModal: (args: ShowModalArgs) => void
}

export const useToggleModal = (): UseToggleModal => {
  const { showModal } = useModalContext()

  const showToggleModal = React.useCallback(
    ({ config, onConfirm }: ShowModalArgs) =>
      showModal({
        childRenderer: (props: ModalChildrenProps) => (
          <ToggleModal {...props} config={config} onConfirm={onConfirm} />
        ),
        modalProps: {
          disableCloseMethods: ['backdrop'],
        },
      }),
    [showModal],
  )

  return {
    showModal: showToggleModal,
  }
}
