import {
  GenericTimer,
  TimerType,
} from 'app/fieldapp/store/utils/timers/timers.types'

import { UiState } from './timers.types'

export enum ActionType {
  CLEAR_SUBMISSION_TIMER = 'CLEAR_SUBMISSION_TIMER',
  SET_LOADING_TIMER = 'SET_LOADING_TIMER',
  SET_ONLINE_STATUS = 'SET_ONLINE_STATUS',
  SET_SUBMISSION_DATA = 'SET_SUBMISSION_DATA',
  SET_UI_STATE = 'SET_UI_STATE',
}

type SetLoadingTimer = {
  data: {
    timerId: string | null
  }
  type: ActionType.SET_LOADING_TIMER
}

type SetUiState = {
  data: {
    uiState: UiState
  }
  type: ActionType.SET_UI_STATE
}

export type TimerSubmissionData = {
  timer: GenericTimer
  type: TimerType
}

type SetSubmissionData = {
  data: TimerSubmissionData
  type: ActionType.SET_SUBMISSION_DATA
}

type ClearSubmissionTimer = {
  type: ActionType.CLEAR_SUBMISSION_TIMER
}

type SetOnlineStatus = {
  data: {
    newHasBeenOffline: boolean
  }
  type: ActionType.SET_ONLINE_STATUS
}

export type TimerAction =
  | SetLoadingTimer
  | SetUiState
  | SetSubmissionData
  | ClearSubmissionTimer
  | SetOnlineStatus

/**************************************************
 * Action Creators
 **************************************************/
export const setOnlineStatus = (
  newHasBeenOffline: boolean,
): SetOnlineStatus => ({
  data: {
    newHasBeenOffline,
  },
  type: ActionType.SET_ONLINE_STATUS,
})

export const setUiState = (uiState: UiState): SetUiState => ({
  data: {
    uiState,
  },
  type: ActionType.SET_UI_STATE,
})

export const setLoadingTimer = (timerId: string): SetLoadingTimer => ({
  data: {
    timerId,
  },
  type: ActionType.SET_LOADING_TIMER,
})

export const clearLoadingTimer = (): SetLoadingTimer => ({
  data: {
    timerId: null,
  },
  type: ActionType.SET_LOADING_TIMER,
})

export const setSubmissionData = (
  data: TimerSubmissionData,
): SetSubmissionData => ({
  data,
  type: ActionType.SET_SUBMISSION_DATA,
})

export const clearTimerSubmission = (): ClearSubmissionTimer => ({
  type: ActionType.CLEAR_SUBMISSION_TIMER,
})
