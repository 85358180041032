import { createAsyncThunk } from '@reduxjs/toolkit'
import { noop } from 'lodash/fp'

import { ReduxActionCallbacks } from 'packages/grimoire/src/utils'

import { setIsCoverageStale } from '../../coverage/actions'
import { standardAvailabilityService } from '../standardAvailability.service'

type DeleteStandardAvailabilityArgs = {
  callbacks: ReduxActionCallbacks
  standardAvailabilityId: string
}
export const deleteStandardAvailabilityById = createAsyncThunk(
  'standardAvailability/api/deleteStandardAvailabilityById',
  async (args: DeleteStandardAvailabilityArgs, { dispatch }) => {
    const { standardAvailabilityId, callbacks } = args
    const { onError = noop, onSuccess = noop } = callbacks

    try {
      await standardAvailabilityService.deleteStandardAvailabilityById(
        standardAvailabilityId,
      )

      dispatch(setIsCoverageStale(true))

      onSuccess()

      // Here result is empty, so we need to create a payload with the id
      return { standardAvailabilityId }
    } catch (error) {
      onError()
    }
  },
)
