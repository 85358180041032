import React from 'react'
import { useDispatch } from 'react-redux'

import { useFormFns, useToast } from 'packages/common'
import { cleanIsDeepClean } from 'packages/grimoire'
import { ReduxActionCallbacks } from 'packages/grimoire/src/utils'
import { useAsyncFnWithReset, useOnlineStatus } from 'packages/utils/hooks'
import { track } from 'packages/wiretap'
import { SegmentEvents } from 'packages/wiretap/src/tracking/events'

import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { Clean, CleanPatchData } from 'app/fieldapp/store/cleans'
import { updateClean } from 'app/fieldapp/store/cleans/actions'
import { useActiveUser } from 'app/fieldapp/utils/hooks/useActiveUser'

import {
  CleanJobTypeEditor,
  CleanJobTypeEditorFormValues,
  CleanJobTypeEditorProps,
} from './CleanJobTypeEditor'
import { validateDeepCleanSkipForm } from './CleanJobTypeEditor.helpers'

const useTranslations = () => {
  const { ut } = useI18n()
  return {
    message: ut(Slugs.changedToPostStayClean),
  }
}

const initialFormValues: CleanJobTypeEditorFormValues = {
  cleanJobType: 'deep',
  reason: '',
}

export type CleanJobTypeEditorContainerProps = {
  clean: Clean
  isEditing: boolean
  onCancelClick: () => void
}

export const CleanJobTypeEditorContainer: React.FC<CleanJobTypeEditorContainerProps> =
  ({ clean, isEditing, onCancelClick }) => {
    const reduxDispatch = useDispatch()
    const strings = useTranslations()
    const { user } = useActiveUser()
    const { showToast } = useToast()
    const isOnline = useOnlineStatus().isOnline()

    const { formValues, handleChange, handleChangeRadio, resetFormValues } =
      useFormFns<CleanJobTypeEditorFormValues>(initialFormValues)

    const [updateState, updateCleanFn, resetUpdateClean] = useAsyncFnWithReset(
      async (patchData: CleanPatchData, callbacks: ReduxActionCallbacks = {}) =>
        reduxDispatch(updateClean(patchData, callbacks)),
      [reduxDispatch],
    )

    const handleSubmit = React.useCallback(
      async (event: React.FormEvent) => {
        event.preventDefault()

        const newJobType = clean.jobType.filter(job => job !== 'deep_clean')
        const patchData: CleanPatchData = { id: clean.id, jobType: newJobType }

        updateCleanFn(patchData, {
          onSuccess: () => {
            showToast({ message: strings.message })

            /* eslint-disable @typescript-eslint/naming-convention */
            track(SegmentEvents.fieldAppDeepCleanConvertToPost, {
              clean_id: clean.id,
              is_started: !!clean.startedAt,
              reason: formValues.reason.trim(),
              user_id: user?.id || 'unknown',
            })
            /* eslint-enable @typescript-eslint/naming-convention */
          },
        })
      },
      [
        clean.id,
        clean.jobType,
        clean.startedAt,
        formValues.reason,
        showToast,
        strings.message,
        updateCleanFn,
        user?.id,
      ],
    )

    const shouldRenderEditor = React.useMemo(
      () => cleanIsDeepClean(clean) && isEditing,
      [clean, isEditing],
    )

    // any time we leave 'editing' state, reset the form
    React.useEffect(() => {
      if (!isEditing) {
        resetFormValues()
        resetUpdateClean()
      }
    }, [isEditing, resetFormValues, resetUpdateClean])

    const formState =
      React.useMemo((): CleanJobTypeEditorProps['formState'] => {
        const canSubmit = validateDeepCleanSkipForm(formValues) && isOnline
        return {
          canSubmit,
          values: formValues,
        }
      }, [formValues, isOnline])

    const handlers = React.useMemo(
      (): CleanJobTypeEditorProps['handlers'] => ({
        cancel: onCancelClick,
        change: handleChange,
        changeRadio: handleChangeRadio,
        submit: handleSubmit,
      }),
      [handleChange, handleChangeRadio, handleSubmit, onCancelClick],
    )

    return shouldRenderEditor ? (
      <CleanJobTypeEditor
        formState={formState}
        handlers={handlers}
        predictedCleanTime={clean.predictedCleanTime}
        requestState={updateState}
      />
    ) : null
  }
