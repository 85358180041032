import lf from 'localforage'

import { OfflineCleanTime } from 'packages/grimoire'

import { OfflineOtherTimer } from 'app/fieldapp/store/otherTimers'
import { OfflineTicketTime } from 'app/fieldapp/store/ticket-times'

const DB_NAME = 'offlineTimers'

const otherTimersDb = lf.createInstance({
  driver: lf.LOCALSTORAGE,
  name: DB_NAME,
  storeName: 'otherTimers',
})

const cleanTimesDb = lf.createInstance({
  driver: lf.LOCALSTORAGE,
  name: DB_NAME,
  storeName: 'cleanTimes',
})

const ticketTimesDB = lf.createInstance({
  driver: lf.LOCALSTORAGE,
  name: DB_NAME,
  storeName: 'ticketTimes',
})

export type OfflineTimer =
  | OfflineOtherTimer
  | OfflineCleanTime
  | OfflineTicketTime

function getTimers<T extends OfflineTimer>(db: LocalForage) {
  return async (): Promise<Record<string, T>> => {
    const data = {}
    await db.iterate((value, key) => {
      data[key] = value
    })

    return data
  }
}

const offlineOtherTimers = {
  getOtherTimeByID: async (id: string) => {
    const timers = await getTimers<OfflineOtherTimer>(otherTimersDb)()
    return timers[id]
  },
  getOtherTimers: getTimers<OfflineOtherTimer>(otherTimersDb),
  removeOtherTime: async (id: string): Promise<void> =>
    await otherTimersDb.removeItem(id),
  saveOtherTime: async (data: OfflineOtherTimer): Promise<OfflineOtherTimer> =>
    await otherTimersDb.setItem(data.id, data),
}

const offlineCleanTimes = {
  getCleanTimeByID: async (id: string) => {
    const timers = await getTimers<OfflineCleanTime>(cleanTimesDb)()
    return timers[id]
  },
  getCleanTimes: getTimers<OfflineCleanTime>(cleanTimesDb),
  removeCleanTime: async (id: string): Promise<void> =>
    await cleanTimesDb.removeItem(id),
  saveCleanTime: async (data: OfflineCleanTime): Promise<OfflineCleanTime> =>
    await cleanTimesDb.setItem(data.id, data),
}

const offlineTicketTimes = {
  getTicketTimeByID: async (id: string) => {
    const timers = await getTimers<OfflineTicketTime>(ticketTimesDB)()
    return timers[id]
  },
  getTicketTimes: getTimers<OfflineTicketTime>(ticketTimesDB),
  removeTicketTime: async (id: string): Promise<void> =>
    await ticketTimesDB.removeItem(id),
  saveTicketTime: async (data: OfflineTicketTime): Promise<OfflineTicketTime> =>
    await ticketTimesDB.setItem(data.id, data),
}

export const offlineTimers = {
  ...offlineOtherTimers,
  ...offlineCleanTimes,
  ...offlineTicketTimes,
}
