/**********************************************************
 * REVIEW
 *********************************************************/
export enum ReviewSource {
  AIRBNB = 'airbnb',
  BOOKING = 'booking.com',
  VACASA = 'vacasa',
  VRBO = 'vrbo',
}

export type AllReviewAttributes = {
  accuracyRating?: number
  checkinRating?: number
  cleanlinessRating?: number
  comfortRating?: number
  communicationRating?: number
  facilitiesRating?: number
  locationRating?: number
  onlineListingRating?: number
  overallRating: number
  privateReviewText?: string
  propertyConditionRating?: number
  publicReviewText?: string
  reservationId?: string
  reviewDate: string
  reviewHeadline?: string
  reviewId: string
  reviewReply?: string
  reviewSource: ReviewSource
  serviceRating?: number
  staffRating?: number
  unitId: string
  valueRating?: number
  wifiRating?: number
}

export const ReviewAttributeNames = [
  'reviewId',

  'reviewDate',
  'reviewHeadline',
  'reviewSource',

  'publicReviewText',
  'privateReviewText',
  'reviewReply',

  'overallRating',
  'cleanlinessRating',
  'locationRating',
  'wifiRating',
  'propertyConditionRating',
  'serviceRating',
  'communicationRating',
  'accuracyRating',
  'checkinRating',
  'valueRating',
  'onlineListingRating',
  'staffRating',
  'comfortRating',
  'facilitiesRating',

  'unitId',
  'reservationId',
]
