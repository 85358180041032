import { FREEZE_WINDOW_END, isCompleteType } from 'packages/grimoire'
import {
  isUnitCleanInProgress,
  isUnitOccupied,
} from 'packages/grimoire/src/unit/unit.helpers'
import {
  createDateString,
  differenceInHours,
  isBefore,
} from 'packages/utils/dateHelpers'
import { useOnlineStatus } from 'packages/utils/hooks'

import { Clean } from 'app/fieldapp/store/cleans'
import { Unit } from 'app/fieldapp/store/units'
import { useActiveUser } from 'app/fieldapp/utils/hooks/useActiveUser'

import { CheckedOrFlagged } from './components/VisitInspectionChecklist/components/VisitInspectionChecklistItem'

export function shouldRenderCleanTimerControls(clean: Clean): boolean {
  const { effectiveDate } = clean

  const isBeforeEndOfFreezeWindow = isBefore(effectiveDate, FREEZE_WINDOW_END)

  return isBeforeEndOfFreezeWindow && clean.completedAt === null
}

export const SHARED_CLEAN_ROOT_URL = '/shared/cleans'
export const SHARED_CLEAN_INVALID_URL = `${SHARED_CLEAN_ROOT_URL}/invalid`
export const isViewingSharedClean = (): boolean =>
  window.location.href.includes(SHARED_CLEAN_ROOT_URL)

export const showUnitStatus = (unit: Unit): boolean => {
  return isUnitCleanInProgress(unit) || isUnitOccupied(unit)
}

export function useShouldRenderAssignmentRejectionControls(
  clean: Clean,
): boolean {
  const { isOnline } = useOnlineStatus()

  // We should not be rendering the rejection controls if the user is an employee, but just in case, we can make sure
  const { isEmployee } = useActiveUser()

  const { effectiveDate } = clean

  const isWithinRejectionWindow =
    differenceInHours(effectiveDate, createDateString()) >= 48

  const cleanIsStarted = clean.startedAt

  return (
    isWithinRejectionWindow &&
    isOnline() &&
    !isEmployee &&
    !isViewingSharedClean() &&
    !cleanIsStarted
  )
}

export const convertIsCompleteToBoolean = (
  isComplete: isCompleteType,
): boolean => {
  if (isComplete === 'checked' || isComplete === 'flagged') return true

  return false
}

// TODO TPD-6559: update this functions isComplete arg type to be only the isCompleteType
// once we fully support the string value over the boolean
export const isCheckedOrFlagged = (
  isComplete: boolean | isCompleteType,
  string: 'checked' | 'flagged',
): boolean => {
  if (typeof isComplete === 'string') {
    if (isComplete === string) return true

    return false
  }

  return isComplete
}

export const toggleIsCompleteValue = (
  checkedOrFlagged: CheckedOrFlagged,
  isComplete: isCompleteType,
): isCompleteType => {
  if (isComplete === 'unchecked') return checkedOrFlagged
  if (isComplete === checkedOrFlagged) return 'unchecked'
  return checkedOrFlagged
}
