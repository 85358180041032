import { createAsyncThunk } from '@reduxjs/toolkit'
import { noop } from 'lodash/fp'

import { ReduxActionCallbacks } from 'packages/grimoire/src/utils'
import { RequestOptions } from 'packages/utils/store'
import { getSystemTZ } from 'packages/utils/timezone.helpers'

import { setIsCoverageStale } from '../../coverage/actions'
import { standardAvailabilityService } from '../standardAvailability.service'
import { StandardAvailabilityPatchData } from '../standardAvailability.types'

export const getParams = (
  patchData: StandardAvailabilityPatchData,
): RequestOptions => ({
  data: {
    attributes: {
      friday: patchData.friday,
      monday: patchData.monday,
      saturday: patchData.saturday,
      start_date: patchData.startDate,
      sunday: patchData.sunday,
      thursday: patchData.thursday,
      tuesday: patchData.tuesday,
      tz: getSystemTZ(),
      wednesday: patchData.wednesday,
    },
    id: patchData.standardAvailabiltiyId,
    type: 'standard_availability',
  },
})

type UpdateStandardAvailabilityByIdThunkArgs = {
  callbacks: ReduxActionCallbacks
  patchData: StandardAvailabilityPatchData
}

/**
In an effort to avoid race conditions we are NOT fetching standard_availability inside this action. This makes this action
an exception to our usual pattern, so just be aware that you will need to fetch standard_availability manually.
 */
export const updateStandardAvailabilityById = createAsyncThunk(
  'standardAvailability/api/updateStandardAvailabilityById',
  async (args: UpdateStandardAvailabilityByIdThunkArgs, { dispatch }) => {
    const { patchData, callbacks } = args
    const { onError = noop, onSuccess = noop } = callbacks

    try {
      const params = getParams(patchData)
      const result =
        await standardAvailabilityService.updateStandardAvailabilityById(
          patchData.standardAvailabiltiyId,
          params,
        )
      dispatch(setIsCoverageStale(true))
      onSuccess()
      return result
    } catch (error) {
      onError()
    }
  },
)
