/**
 * Converts the provided file data to a DataURL; very useful e.g. for using
 * local binary image data to feed the 'src' attr for an <img> tag.
 * @param file
 *
 * Taken from MDN:
 * https://developer.mozilla.org/en-US/docs/Web/API/FileReader/readAsDataURL
 */
export const convertFileToDataUrl = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.addEventListener(
      'load',
      () => {
        const base64Data = reader.result
        if (typeof base64Data === 'string') {
          resolve(base64Data)
        } else {
          reject(new Error('Could not encode image'))
        }
      },
      false,
    )

    if (file) {
      reader.readAsDataURL(file)
    }
  })
}

/**
 * Generates a new <img> Element from a DataUrl string. This can be used to insert
 * directly into the DOM, but also for getting stats on an image we otherwise would
 * not be able to, like height/width, etc.
 * @param dataUrl
 */
export const generateImgElement = (
  dataUrl: string,
): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const img = new Image()

    try {
      img.onload = () => {
        resolve(img)
      }

      img.src = dataUrl
    } catch (err) {
      reject('Error creating img element')
    }
  })
}
