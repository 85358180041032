import * as React from 'react'

import { TimerType } from 'packages/grimoire/src/utils/timers.types'

import { transformTimers } from 'app/fieldapp/components/timers/components/SubmitTimers/helpers'
import {
  SubmissionTimer,
  SubmitTimersFormProps,
} from 'app/fieldapp/components/timers/components/SubmitTimers/types'
import { TicketTime } from 'app/fieldapp/store/ticket-times'
import { Ticket } from 'app/fieldapp/store/tickets'

import { SubmitTimersForm } from '../SubmitTimersForm'
import { useHandleMissingTicket } from './useHandleMissingTicket'

export enum SubmitTicketTimersTestIds {
  container = 'SubmitTicketTimers__container',
}

type SubmitTimerActions = Pick<
  SubmitTimersFormProps,
  | 'dispatchAbortSubmission'
  | 'dispatchDeleteTimer'
  | 'dispatchCompleteStartedOffline'
> & { dispatchConfirmSubmission: (timer: SubmissionTimer) => void }

export type SubmitTicketTimersProps = SubmitTimerActions & {
  displayValue: string
  isExternalComment: boolean | undefined
  ticket: Ticket | undefined
  timer: TicketTime
}

export const SubmitTicketTimers: React.FC<SubmitTicketTimersProps> = ({
  dispatchAbortSubmission,
  dispatchConfirmSubmission,
  dispatchCompleteStartedOffline,
  dispatchDeleteTimer,
  displayValue,
  isExternalComment,
  ticket,
  timer,
}) => {
  const submissionTimers = transformTimers([timer])

  const { handleMissingTicket } = useHandleMissingTicket()
  if (!ticket) {
    handleMissingTicket(timer.id, timer.ticketId)
    return null
  }

  return (
    <SubmitTimersForm
      dataTestId={SubmitTicketTimersTestIds.container}
      dispatchAbortSubmission={dispatchAbortSubmission}
      dispatchConfirmSubmission={timers => dispatchConfirmSubmission(timers[0])}
      dispatchCompleteStartedOffline={dispatchCompleteStartedOffline}
      dispatchDeleteTimer={dispatchDeleteTimer}
      displayValue={displayValue}
      isExternalComment={isExternalComment}
      timerType={TimerType.TICKET}
      timers={submissionTimers}
    />
  )
}
