import React from 'react'

import { Alert } from 'packages/common'
import {
  unitCodeAndNameString,
  unitFullAddressString,
} from 'packages/utils/hkStringHelpers'
import { logInfo } from 'packages/wiretap/logging'

import {
  BasicText,
  LinkToMaps,
  UnitInfoSection,
  UnitName,
} from 'app/fieldapp/components/schedule/components/TaskSharedStyles'
import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { useAppSelector } from 'app/fieldapp/store/hooks'
import { ApplicationState } from 'app/fieldapp/store/store'
import { getVisitDrawerState } from 'app/fieldapp/store/ui/selectors'
import { getUnitById } from 'app/fieldapp/store/units/selectors'
import { getVisitById } from 'app/fieldapp/store/visits/selectors'

const St = {
  LinkToMaps,
  UnitAddress: BasicText,
  UnitInfoSection,
  UnitName,
}

export const VisitAddressInfo: React.FC = React.memo(() => {
  const { t } = useI18n()
  const { visitId } = useAppSelector(getVisitDrawerState)

  const visit = useAppSelector((state: ApplicationState) =>
    getVisitById(state, visitId),
  )
  const unit = useAppSelector((state: ApplicationState) =>
    getUnitById(state, visit?.unitId),
  )

  if (!unit) {
    logInfo(
      'Missing visit or unit info when trying to render VisitAddressInfo',
      { visitId: visitId },
    )

    return (
      <St.UnitInfoSection>
        <Alert alertType="warning">{t(Slugs.errorTryAgainLater)}</Alert>
      </St.UnitInfoSection>
    )
  }

  const unitNameString = unitCodeAndNameString(unit)
  const addressString = unitFullAddressString(unit)

  return (
    <St.UnitInfoSection>
      <St.UnitName>{unitNameString}</St.UnitName>
      <St.UnitAddress>{addressString}</St.UnitAddress>
      <St.LinkToMaps {...unit} />
    </St.UnitInfoSection>
  )
})
