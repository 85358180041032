import styled from '@emotion/styled'

import { RadioButtons } from 'packages/common'
import { ComputedCleanJobType } from 'packages/grimoire'
import { colors } from 'packages/styles'

import { jobTypeConfigMap } from './CleanJobTypeEditor'

export const CleanTypeHeading = styled.div`
  font-size: 14px;
  font-weight: 700;
  margin-top: 16px;
  text-transform: uppercase;
`

export const CleanJobOptionStyles = {
  Label: styled.span<{ job: ComputedCleanJobType }>`
    background-color: ${({ job }) => jobTypeConfigMap[job].color};

    align-items: center;
    border-radius: 12px;
    cursor: pointer;
    display: inline-flex;
    flex-direction: row;
    font-size: 12px;
    font-weight: 700;
    grid-gap: 8px;
    justify-content: center;
    padding: 6px 10px;
    text-transform: uppercase;
  `,

  PredictedTime: styled.span`
    color: ${colors.dusk};
    font-size: 14px;
    margin-left: 12px;
  `,
}

export const ButtonSection = styled.div`
  display: flex;
  padding-top: 24px;
`

export const WarningSection = styled.div`
  color: ${colors.dusk60};
  margin-top: 24px;
  margin-bottom: 24px;
`
export const WarningSectionList = styled.ul`
  margin-top: 0px;
  padding-left: 24px;
`

export const CleanTypeRadioButtons = styled(RadioButtons)`
  margin-top: 16px;

  & > div:not(:first-of-type) {
    margin-top: 12px;
  }
`
