import * as React from 'react'

import { Button } from 'packages/common'
import { TimerType } from 'packages/grimoire/src/utils/timers.types'
import { IconName, SvgIcon } from 'packages/iconic'
import { differenceInSeconds } from 'packages/utils/dateHelpers'

import { TimerDetail } from 'app/fieldapp/components/timers/components'
import { TimerDetailWrapper } from 'app/fieldapp/components/timers/components/Timers.styles'
import { useOtherTimerActions } from 'app/fieldapp/components/timers/hooks'
import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { OtherTimer } from 'app/fieldapp/store/otherTimers'

import styles from '../PausedTimerDisplay.module.scss'

export enum PausedOtherTimerDisplayTestIds {
  completeBtn = 'PausedOtherTimer__completeBtn',
  container = 'PausedOtherTimer__container',
  deleteBtn = 'PausedOtherTimer__deleteBtn',
}

export type PausedOtherTimerDisplayProps = {
  loading: boolean
  otherTimer: OtherTimer
  requestPending: boolean
}

export const PausedOtherTimerDisplay: React.FC<PausedOtherTimerDisplayProps> =
  React.memo(({ loading, requestPending, otherTimer }) => {
    const { t } = useI18n()
    const { dispatchBeginOtherTimerSubmission, dispatchDeleteOtherTime } =
      useOtherTimerActions()

    const time = differenceInSeconds(
      otherTimer.stoppedAt ?? otherTimer.startedAt,
      otherTimer.startedAt,
    )

    const handleSubmitClick = React.useCallback(() => {
      dispatchBeginOtherTimerSubmission(otherTimer)
    }, [dispatchBeginOtherTimerSubmission, otherTimer])

    const handleDeleteClick = React.useCallback(() => {
      dispatchDeleteOtherTime(otherTimer.id)
    }, [dispatchDeleteOtherTime, otherTimer])

    return (
      <TimerDetailWrapper
        data-testid={PausedOtherTimerDisplayTestIds.container}
        timerColorType={TimerType.OTHER}
      >
        <div
          data-testid={PausedOtherTimerDisplayTestIds.deleteBtn}
          className={styles.closeButton}
          onClick={handleDeleteClick}
        >
          <SvgIcon className={styles.closeX} icon={IconName.x} />
        </div>

        <div className={styles.timerDetailWrapper}>
          <TimerDetail time={time} otherTimerType={otherTimer.timerType} />
        </div>

        <div className={styles.timerControls}>
          <Button
            ariaLabel="Submit this timer"
            dataTestId={PausedOtherTimerDisplayTestIds.completeBtn}
            disabled={requestPending && !loading}
            isLoading={loading}
            onClick={handleSubmitClick}
            buttonType={'primary'}
          >
            {t(Slugs.submit)}
          </Button>
        </div>
      </TimerDetailWrapper>
    )
  })
