import React from 'react'

import styles from './Rating.module.scss'

export const RatingTestIds = {
  container: 'rating-container',
}

export type RatingProps = {
  max?: number
  value: number
}

export const Rating: React.FC<RatingProps> = React.memo(
  ({ value, max = 5 }) => {
    const percentage = (value / max) * 100
    const width = Math.max(0, Math.min(100, percentage))
    const stars = '★'.repeat(max)

    return (
      <i
        className={styles.rating}
        style={
          {
            '--rating-width': `${width}%`,
            '--stars': `"${stars}"`,
          } as React.CSSProperties
        }
        data-rating={value}
        data-testid={RatingTestIds.container}
      ></i>
    )
  },
)
