export type AllTicketTimeAttributes = {
  isExternalComment?: boolean
  isFinalized: boolean
  notes?: string
  startedAt: string
  startedOffline?: boolean
  stoppedAt: string | null
  ticketId: string
}

export const AllTicketTimeAttributeNames = [
  'isFinalized',
  'notes',
  'startedAt',
  'startedOffline',
  'stoppedAt',
  'ticketId',
]
