import styled from '@emotion/styled'
import React from 'react'

import { Drawer, DrawerContainerProps } from 'packages/common'
import { Events, track } from 'packages/wiretap'

import { NativeShare } from 'app/fieldapp/components/core'
import { CREATE_TICKET_DRAWER_ID } from 'app/fieldapp/components/schedule/components/common/CreateTicket/CreateTicketDrawer'
import { TEST_ID_TIMERS } from 'app/fieldapp/components/timers'
import { useI18n } from 'app/fieldapp/i18n'

import { UseTicket } from '../../hooks'
import { TicketDetail } from '../TicketDetail'
import { createShareContent } from '../useTicketDetails/useTicketDetails.helpers'

const St = {
  Drawer: styled(Drawer)`
    max-width: 480px;
  `,

  Header: styled.div`
    align-items: center;
    display: flex;
    height: 48px;
    padding-left: 24px;
  `,
}

type PickedDrawerProps = Pick<DrawerContainerProps, 'afterExit' | 'isOpen'>
export type TicketDetailDrawerProps = PickedDrawerProps & {
  ticketDetails: UseTicket
  userId: string
}

export const TicketDetailDrawer: React.FC<TicketDetailDrawerProps> = ({
  afterExit,
  isOpen,
  ticketDetails,
  userId,
}) => {
  const { ticket, unit, isCommentsCriteriaFulfilled } = ticketDetails
  const { ut } = useI18n()

  const handleClickAway = React.useCallback(event => {
    // prevent closing the drawer if the outside click was on the Timers UI
    const query = `div[data-testid="${TEST_ID_TIMERS}"]`
    const timersDrawer = document.querySelector(query)

    // prevent closing the drawer if the outside click was on the Create Ticket Drawer
    const createTicketDrawer = document.querySelector(
      `#${CREATE_TICKET_DRAWER_ID}`,
    )

    const createTicketDrawerBackdrop = document.querySelector(
      `#${CREATE_TICKET_DRAWER_ID}__backdrop`,
    )

    return (
      timersDrawer?.contains(event.target) ||
      createTicketDrawer?.contains(event.target) ||
      createTicketDrawerBackdrop?.contains(event.target) ||
      false
    )
  }, [])

  const handleShareClick = React.useCallback(() => {
    if (!ticket) return

    /* eslint-disable @typescript-eslint/naming-convention */
    track(Events.fieldAppShareTicket, {
      ticket_id: ticket.id,
      user_id: userId,
    })
    /* eslint-enable @typescript-eslint/naming-convention */
  }, [ticket, userId])

  if (!ticket || !unit) return null

  return (
    <St.Drawer
      afterExit={afterExit}
      isOpen={isOpen}
      onClickAway={handleClickAway}
    >
      <St.Header>
        <NativeShare
          {...createShareContent(ticket, unit, ut)}
          onClick={handleShareClick}
        />
      </St.Header>

      <TicketDetail
        commentIds={ticketDetails.ticketCommentIds}
        ticket={ticket}
        unit={unit}
        canBeCompleted={isCommentsCriteriaFulfilled}
      />
    </St.Drawer>
  )
}
