import { RequestOptions } from 'packages/utils/store'

import { api } from '../axiosInstance'
import {
  CoveragePartnerApiResponse,
  CoveragePartnerServiceResponse,
} from './coveragePartner.types'

const URL = '/coverage_partner'

export const emptyCoveragePartnersResponse = {
  data: {
    coveragePartner: {},
  },
}

export const coveragePartnerService = {
  async createCoveragePartner(
    data: RequestOptions,
  ): CoveragePartnerServiceResponse {
    const response: CoveragePartnerApiResponse = await api.post(URL, data)

    return response?.data || emptyCoveragePartnersResponse
  },
}
