import { AxiosResponse } from 'axios'

import {
  AssignmentRejectionReason,
  JSONApiAssignmentsMap,
} from 'packages/grimoire'
import { NormalizedJSONApiResponse } from 'packages/utils/store'

export * from 'packages/grimoire/src/assignment'

/**********************************************************
 * REDUX TYPES
 *********************************************************/
export type AssignmentsState = {
  data: JSONApiAssignmentsMap
}

export enum AssignmentsActionTypes {
  REJECT_ASSIGNMENT = 'ASSIGNMENTS/API/REJECT_ASSIGNMENT',
  REJECT_ASSIGNMENT_FAILURE = 'ASSIGNMENTS/REJECT_ASSIGNMENT_FAILURE',
  REJECT_ASSIGNMENT_SUCCESS = 'ASSIGNMENTS/REJECT_ASSIGNMENT_SUCCESS',
}

export type AssignmentRejectData = {
  id: string
} & AssignmentRejectAttributes

export type AssignmentRejectApiAttributes = {
  rejection_reason: AssignmentRejectionReason
}

export type AssignmentRejectAttributes = {
  rejectionReason: AssignmentRejectionReason
}

export type AssignmentsResponse = {
  assignment: JSONApiAssignmentsMap
}

export type NormalizedAssignmentsApiResponse =
  NormalizedJSONApiResponse<AssignmentsResponse>

export type AssignmentsServiceResponse =
  Promise<NormalizedAssignmentsApiResponse>

export type AssignmentsApiResponse =
  AxiosResponse<NormalizedAssignmentsApiResponse>
