import {
  findRelationshipId,
  transformNormalizedToTyped,
} from 'packages/utils/store'

import {
  Housekeeper,
  HousekeeperAttributeNames,
  NormalizedHousekeepersApiResponse,
  RawHousekeeper,
} from './housekeepers.types'

export const emptyNormalizedHousekeepersData = Object.freeze({
  housekeeper: {},
  user: {},
  zoneId: '',
})

/**
 * Empty data to use as a "safety net" any time an API response is undefined for any reason
 */
export const emptyHousekeepersResponse: NormalizedHousekeepersApiResponse =
  Object.freeze({
    normalized: emptyNormalizedHousekeepersData,
    raw: { data: [] },
  })

export const hydrateRawHousekeeper = (
  rawHousekeeper: RawHousekeeper,
): Housekeeper => {
  const housekeeper = transformNormalizedToTyped<Housekeeper>(
    rawHousekeeper,
    HousekeeperAttributeNames,
  )

  const zoneId = findRelationshipId(rawHousekeeper, 'zone')

  const userId = findRelationshipId(rawHousekeeper, 'userNoPii')

  return {
    ...housekeeper,
    userId,
    zoneId,
  }
}
