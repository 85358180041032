import { createAsyncAction } from 'typesafe-actions'

import {
  RequestConfig,
  RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import { ReservationApiFields } from '../../reservations'
import { TicketCommentApiFields } from '../../ticketComments'
import { UnitApiFields } from '../../units'
import { UserApiFieldsWithoutPII } from '../../users'
import { ticketsService } from '../tickets.service'
import {
  NormalizedTicketsApiResponse,
  TicketApiFields,
  TicketsActionTypes,
} from '../tickets.types'

export const fetchTicketByIdAction = createAsyncAction(
  TicketsActionTypes.FETCH_TICKET,
  TicketsActionTypes.FETCH_TICKET_SUCCESS,
  TicketsActionTypes.FETCH_TICKET_FAILURE,
)<
  RequestConfig<NormalizedTicketsApiResponse>,
  NormalizedTicketsApiResponse,
  Error
>()

export const getParams = (): RequestOptions => ({
  fields: {
    reservation: ReservationApiFields,
    ticket: TicketApiFields,
    ticket_comment: TicketCommentApiFields,
    unit: UnitApiFields,
    user: UserApiFieldsWithoutPII,
  },
  include: [
    'assignee',
    'comments',
    'comments.user',
    'open_visit',
    'unit',
    'unit.current_reservation',
    'unit.next_reservation',
    'unit.unit_family',
  ],
})

export const fetchTicketById = (ticketId: string) => async dispatch => {
  try {
    const params = getParams()
    const request = ticketsService.fetchTicketById.bind(null, ticketId, params)
    const result = await dispatch(fetchTicketByIdAction.request({ request }))
    dispatch(fetchTicketByIdAction.success(result))
    return result.normalized
  } catch (error) {
    dispatch(fetchTicketByIdAction.failure(error))
    throw error
  }
}
