import { transformNormalizedToTyped } from 'packages/utils/store'

import { ApplicationState } from '../../store'
import { User, UserAttributeNames } from '../users.types'

export const getUsers = (state: ApplicationState): User[] => {
  return Object.values(state.users.data).map(user =>
    transformNormalizedToTyped(user, UserAttributeNames),
  )
}
