import { createAsyncAction } from 'typesafe-actions'

import { OfflineError } from 'packages/offline'
import { apiDateStringWithSeconds } from 'packages/utils/dateHelpers'
import {
  OfflineRequestConfig,
  RequestOptions,
  setRequestRelationship,
} from 'packages/utils/store'

import { cleanTimesService } from '../cleantimes.service'
import {
  CleanTimePostApiAttributes,
  CleanTimePostData,
  CleanTimesActionTypes,
  NormalizedCleanTimesApiResponse,
} from '../cleantimes.types'
import { fetchCleanTimeById } from './fetchCleanTimeById'

export const createCleanTimeAction = createAsyncAction(
  CleanTimesActionTypes.CREATE_CLEAN_TIME,
  CleanTimesActionTypes.CREATE_CLEAN_TIME_SUCCESS,
  CleanTimesActionTypes.CREATE_CLEAN_TIME_FAILURE,
)<
  OfflineRequestConfig<NormalizedCleanTimesApiResponse, CleanTimePostData>,
  NormalizedCleanTimesApiResponse,
  Error
>()

export const buildRequestData = ({
  assignmentId,
  startedAt,
}: CleanTimePostData): RequestOptions => {
  const attributes: CleanTimePostApiAttributes = {
    started_at: apiDateStringWithSeconds(startedAt),
  }

  return {
    data: {
      attributes,
      relationships: {
        ...setRequestRelationship('assignment', assignmentId),
      },
      type: 'clean_time',
    },
  }
}

export const createCleanTime =
  (postData: CleanTimePostData) => async dispatch => {
    try {
      const requestData = buildRequestData(postData)
      const request = cleanTimesService.createCleanTime.bind(null, requestData)
      const result = await dispatch(
        createCleanTimeAction.request({ data: postData, request }),
      )
      dispatch(createCleanTimeAction.success(result))

      // now re-fetch the newly-created CleanTime, so we can store the full data locally
      const newCleanTimeId = Object.keys(result.normalized.cleanTime)[0]
      await dispatch(fetchCleanTimeById(newCleanTimeId))

      return result.normalized
    } catch (error) {
      if (error === OfflineError) return
      dispatch(createCleanTimeAction.failure(error))
      throw error
    }
  }
