import { createSelector } from 'reselect'

import { ApplicationState } from '../../store'
import { hydrateRawVisit, Visit } from '../../visits'

/**
 * Returns an array of visits based on all ID provided.
 * Note that this pulls ALL matching visits from both 'data' and 'unitAssociatedVisits' sections.
 */
export const getVisitsByIds = createSelector(
  (state: ApplicationState) => state.visits,
  (_, visitIds: string[]) => visitIds,
  (visitsState, visitIds): Visit[] =>
    visitIds.reduce((acc, id) => {
      const rawVisit =
        visitsState.data[id] || visitsState.unitAssociatedVisits[id]

      if (rawVisit) {
        acc.push(hydrateRawVisit(rawVisit))
      }

      return acc
    }, [] as Visit[]),
)
