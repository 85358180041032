import * as React from 'react'

import { OtherTimerType } from 'packages/grimoire'

import { ExternalCommentToggle } from 'app/fieldapp/components/tickets/ExternalCommentToggle'
import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { TimerType } from 'app/fieldapp/store/utils'

import { SubmissionTimer } from '../../types'
import { NotesTimerType, useNoteSectionText } from './NoteSection.helpers'
import { Container, Label, TextArea } from './NoteSection.styles'

const requireNoteTimerTypes: (OtherTimerType | TimerType.TICKET)[] = [
  OtherTimerType.OTHER,
  OtherTimerType.DRIVE,
]

const notesRequired = (
  otherTimerType: OtherTimerType | TimerType.TICKET | undefined,
) => {
  return !!otherTimerType && requireNoteTimerTypes.includes(otherTimerType)
}

export enum NotesSectionTestIds {
  textArea = 'notesSection__textArea',
}

export type NotesSectionProps = {
  isExternalComment: boolean
  // "register" function from react-hook-form
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: any
  setIsExternalComment: (b: boolean) => void
  timer: SubmissionTimer
  timerType: NotesTimerType
}

export const NotesSection: React.FC<NotesSectionProps> = ({
  register,
  timerType,
  timer,
  isExternalComment,
  setIsExternalComment,
}) => {
  const { t } = useI18n()

  const { label, placeholder } = useNoteSectionText(timerType)

  const sectionHeader = React.useMemo(() => {
    if (!(timerType === TimerType.TICKET)) return t(Slugs.notes)
    return t(Slugs.comment)
  }, [timerType, t])

  return timer ? (
    <Container>
      <div>{sectionHeader}</div>

      <Label>
        {label}
        {timerType === TimerType.TICKET && (
          <ExternalCommentToggle
            checked={isExternalComment}
            onToggle={setIsExternalComment}
          />
        )}
      </Label>
      <TextArea
        cols={1}
        data-testid={NotesSectionTestIds.textArea}
        maxLength={255}
        name={`${timer.id}.notes`}
        placeholder={placeholder}
        ref={register({ required: notesRequired(timerType) })}
        rows={1}
      />
    </Container>
  ) : null
}
