import React from 'react'
import { useDispatch } from 'react-redux'
import { useMount } from 'react-use'

import { useManageConnectedDrawerState } from 'packages/common/src/modals/Drawer/hooks/useManageConnectedDrawerState'
import { useAsyncFnWithReset } from 'packages/utils/hooks'

import { fetchStandardAvailability } from 'app/fieldapp/store/standardAvailability/actions'
import { AppDispatch } from 'app/fieldapp/store/store'
import { setStandardAvailabilityDrawer } from 'app/fieldapp/store/ui/actions'
import { getStandardAvailabilityDrawerState } from 'app/fieldapp/store/ui/selectors'
import { useActiveUser } from 'app/fieldapp/utils/hooks/useActiveUser'

import { StandardAvailabilityDrawer } from './StandardAvailabilityDrawer'

export const StandardAvailabilityDrawerContainer: React.FC = () => {
  const dispatch: AppDispatch = useDispatch()
  const { user } = useActiveUser()

  const drawerManager = useManageConnectedDrawerState({
    dispatchableToggleDrawerAction: setStandardAvailabilityDrawer,
    selector: getStandardAvailabilityDrawerState,
  })

  const [fetchStandardAvailabilityState, fetchStandardAvailabilityFn] =
    useAsyncFnWithReset(async () => {
      return dispatch(fetchStandardAvailability(user?.id))
    }, [dispatch, user])

  useMount(fetchStandardAvailabilityFn)

  return (
    <StandardAvailabilityDrawer
      drawerManager={drawerManager}
      initialFetchRequestState={fetchStandardAvailabilityState}
    />
  )
}
