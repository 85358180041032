import { produce } from 'immer'
import { merge } from 'lodash/fp'
import { ActionType, getType } from 'typesafe-actions'

import { getLastFetchTimestamp } from 'app/fieldapp/utils/cacheHelpers'

import { fetchUnitByIdAction } from '../units/actions'
import { fetchVisitByIdAction } from '../visits/actions'
import {
  fetchTicketByIdAction,
  fetchTicketingMaintenanceDispositionsAction,
  fetchTicketsAction,
  updateTicketAction,
} from './actions'
import { TicketsState } from './tickets.types'

const actions = {
  fetchTicketByIdAction,
  fetchTicketingMaintenanceDispositionsAction,
  fetchTicketsAction,
  fetchUnitByIdAction,
  fetchVisitByIdAction,
  updateTicketAction,
}

type TicketsActionsTypes = ActionType<typeof actions>

export const initialState: TicketsState = {
  data: {},
  dispositions: {},
  lastFetch: 0,
  loading: false,
  ticketToUnitMap: {},
}

export const ticketsReducer = (
  state = initialState,
  action: TicketsActionsTypes,
): TicketsState =>
  produce(state, (draft: TicketsState) => {
    switch (action.type) {
      case getType(fetchTicketsAction.request): {
        draft.loading = true
        return
      }

      case getType(fetchTicketsAction.success): {
        draft.data = action.payload.normalized?.ticket || {}
        draft.lastFetch = getLastFetchTimestamp('tickets')
        draft.loading = false
        return
      }

      case getType(fetchTicketByIdAction.success):
      case getType(fetchUnitByIdAction.success): // tickets included with units (e.g. in "create visit" flow)
      case getType(fetchVisitByIdAction.success): {
        const ticketsData = action.payload.normalized?.ticket || {}
        const unit = Object.values(action.payload.normalized?.unit || {})[0]

        if (ticketsData) {
          Object.values(ticketsData).forEach(incomingTicket => {
            const existingTicket = state.data[incomingTicket.id] || {}
            const mergedTicket = merge(existingTicket, incomingTicket)
            draft.data[incomingTicket.id] = mergedTicket
          })
        }

        Object.values(ticketsData).forEach(rawTicket => {
          // for cases where the ticket does not have knowledge of the unit relationship (sibling includes in JSON:API),
          // we should still be able to find the unit relationship "the hard way" by inspecting the other payload data
          const unitId =
            rawTicket?.relationships?.unit?.data?.id || unit?.id || ''

          draft.data[rawTicket.id] = rawTicket
          draft.ticketToUnitMap[rawTicket.id] = unitId
        })

        return
      }

      case getType(fetchTicketingMaintenanceDispositionsAction.success): {
        draft.dispositions = action.payload.normalized?.disposition || {}
        draft.loading = false
        return
      }

      case getType(updateTicketAction.success): {
        const data = action.payload.normalized?.ticket || {}
        const { attributes, id } = Object.values(data)[0] || {}

        if (!id || !draft.data[id]) return

        draft.data[id].attributes = {
          ...draft.data[id].attributes,
          ...attributes,
        }

        return
      }

      case getType(fetchTicketsAction.failure): {
        draft.data = initialState.data
        draft.loading = false
        return
      }
    }
  })
