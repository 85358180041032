import { createSelector } from 'reselect'

import { ApplicationState } from '../../store'
import { RawCrossCoverage } from '../crossCoverage.types'
import { hydrateRawCrossCoverage } from '../crossCoverage.utils'

const getCrossCoverageState = (state: ApplicationState) => state.crossCoverage

export const getCrossCoverages = createSelector(
  getCrossCoverageState,
  crossCoverageState =>
    Object.values(crossCoverageState.data || {}).map(crossCoverage =>
      hydrateRawCrossCoverage(crossCoverage as RawCrossCoverage),
    ),
)
