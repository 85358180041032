import { JSONApiUsersMap, RawUser } from 'packages/grimoire'

export * from 'packages/grimoire/src/user'

/**********************************************************
 * REDUX TYPES
 *********************************************************/
export enum UsersActionTypes {
  FETCH_COVERAGE_OPTIONS = 'USERS/API/FETCH_COVERAGE_OPTIONS',
  FETCH_COVERAGE_OPTIONS_FAILURE = 'USERS/FETCH_COVERAGE_OPTIONS_FAILURE',
  FETCH_COVERAGE_OPTIONS_SUCCESS = 'USERS/FETCH_COVERAGE_OPTIONS_SUCCESS',

  FETCH_CURRENT_COVERAGE_PARTNER = 'USERS/API/FETCH_CURRENT_COVERAGE_PARTNER',
  FETCH_CURRENT_COVERAGE_PARTNER_FAILURE = 'USERS/FETCH_CURRENT_COVERAGE_PARTNER_FAILURE',
  FETCH_CURRENT_COVERAGE_PARTNER_SUCCESS = 'USERS/FETCH_CURRENT_COVERAGE_PARTNER_SUCCESS',

  FETCH_CURRENT_USER = 'USERS/API/FETCH_CURRENT_USER',
  FETCH_CURRENT_USER_FAILURE = 'USERS/FETCH_CURRENT_USER_FAILURE',
  FETCH_CURRENT_USER_SUCCESS = 'USERS/FETCH_CURRENT_USER_SUCCESS',

  SEARCH_USERS = 'USERS/API/SEARCH_USERS',
  SEARCH_USERS_FAILURE = 'USERS/SEARCH_USERS_FAILURE',
  SEARCH_USERS_SUCCESS = 'USERS/SEARCH_USERS_SUCCESS',

  SET_ACTIVE_USER = 'USERS/SET_ACTIVE_USER',
}

export type UsersState = {
  activeUserHkId: string
  activeUserId: string
  authUserId: string
  coveragePartnerOptions: JSONApiUsersMap
  currentCoveragePartner: RawUser | undefined
  data: JSONApiUsersMap
  searchResults: JSONApiUsersMap
}
