import { createSelector } from 'reselect'

import { isSameDay } from 'packages/utils/dateHelpers'

import { ApplicationState } from '../../store'
import { Coverage } from '../coverage.types'
import { hydrateRawCoverage } from '../coverage.utils'

const getCoverageData = (state: ApplicationState) => state.coverage.data
const getDate = (_, date: string) => date

export const getCoverageByDate = createSelector(
  getCoverageData,
  getDate,
  (coverageData, date): Coverage[] => {
    return Object.values(coverageData)
      .filter(coverage => isSameDay(coverage.attributes.start, date))
      .map(hydrateRawCoverage)
  },
)
