import { useReducer } from 'react'
import React from 'react'

import { Alert } from 'packages/common'
import { getSystemTZ } from 'packages/utils/timezone.helpers'

import { useCrossCoverageDrawerTranslations } from '../useCrossCoverageDrawerTranslations'
import St from '../utils/CrossCoverageDrawer.styles'
import { IsRepeatingFormContainer as IsRepeatingField } from './components'
import { AssigneeSelectionFormContainer as AssigneeField } from './components/AssigneeSelectionForm/AssigneeSelectionForm.container'
import { CrossCoverageFormControlsContainer as FormButtons } from './components/CrossCoverageFormControls'
import { RepeatingCoverageFormContainer as RepeatingFields } from './components/RepeatingCoverageForm/RepeatingCoverageForm.container'
import { TimeAndDateFormContainer as TimeAndDateFields } from './components/TimeAndDateForm/TimeAndDateForm.container'
import {
  CrossCoverageFormState,
  crossCoverageFormReducer,
} from './state/CrossCoverageForm.reducer'

export enum CrossCoverageFormTestIds {
  cancelButton = 'cancelButton',
  formValidationErrorAlert = 'formValidationErrorAlert',
  submitButton = 'submitButton',
}

type CrossCoverageFormProps = {
  initialState: CrossCoverageFormState
}

export const CrossCoverageForm: React.FC<CrossCoverageFormProps> = ({
  initialState,
}) => {
  const [formState, formDispatch] = useReducer(
    crossCoverageFormReducer,
    initialState,
  )

  const formStateManager = {
    formDispatch,
    formState,
  }

  const strings = useCrossCoverageDrawerTranslations()

  return (
    <St.Form>
      {!formState.isEditable && (
        <Alert alertType={'info'}>{strings.eventAlreadyStarted}</Alert>
      )}
      <IsRepeatingField formDispatch={formDispatch} formState={formState} />
      {formState.isRepeating !== null && (
        <>
          <TimeAndDateFields {...formStateManager} />
          <St.Timezone>Event is in {getSystemTZ()}</St.Timezone>
          <AssigneeField {...formStateManager} />
          {formState.isRepeating && <RepeatingFields {...formStateManager} />}
        </>
      )}
      <FormButtons {...formStateManager} />

      <St.FormErrors>
        {formState.formValidationError?.message && (
          <Alert
            dataTestId={CrossCoverageFormTestIds.formValidationErrorAlert}
            alertType={'danger'}
          >
            {formState.formValidationError.message}
          </Alert>
        )}
      </St.FormErrors>
    </St.Form>
  )
}
