import { RequestOptions } from 'packages/utils/store/jsonapi.types'

import { api } from '../axiosInstance'
import { ContactsApiResponse, ContactsServiceResponse } from './contacts.types'
import { emptyContactResponse } from './contacts.utils'

const URL = '/contacts'

export const contactsService = {
  async fetchContacts(params: RequestOptions = {}): ContactsServiceResponse {
    const response: ContactsApiResponse = await api.get(URL, {
      params,
    })

    return response?.data || emptyContactResponse
  },
}
