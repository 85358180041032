import styled from '@emotion/styled'
import React from 'react'

import { Alert } from 'packages/common'

import { Slugs, useI18n } from 'app/fieldapp/i18n'

import { DisabledVisitsInspectionType } from '../../context/VisitInspectionChecklist.context.helpers'

const St = {
  Alert: styled(Alert)`
    border: none;
    margin-bottom: 0;
  `,
}

const useTranslations = (disabledState: DisabledVisitsInspectionType) => {
  const { t } = useI18n()

  let message = ''

  if (disabledState === 'taskNotStarted') {
    message = t(Slugs.inspectionNotStarted)
  }

  if (disabledState === 'taskIsComplete') {
    message = t(Slugs.inspectionHasCompleteVisit)
  }

  if (disabledState === 'noActiveTimer') {
    message = t(Slugs.visitInspectionHasNoActiveTimer)
  }

  if (disabledState === 'otherTimerRunning') {
    message = t(Slugs.otherTimerRunning)
  }

  return {
    message,
  }
}

export type VisitInspectionDisabledWarningProps = {
  disabledState: DisabledVisitsInspectionType
}

export const VisitInspectionDisabledWarning: React.FC<VisitInspectionDisabledWarningProps> =
  React.memo(({ disabledState }) => {
    const { message } = useTranslations(disabledState)
    const warningStates: DisabledVisitsInspectionType[] = [
      'taskIsComplete',
      'noActiveTimer',
      'taskNotStarted',
      'otherTimerRunning',
    ]

    return warningStates.includes(disabledState) ? (
      <St.Alert alertType={'warning'}>{message}</St.Alert>
    ) : null
  })
