import styled from '@emotion/styled'
import { any, upperCase } from 'lodash/fp'
import React from 'react'

import { IconName, SvgIcon } from 'packages/iconic'
import { centerWithFlex, colors } from 'packages/styles'

import { Slugs, useI18n } from 'app/fieldapp/i18n'

import { OwnerBadge } from '../../../TaskSharedStyles/TaskCard.styles'

const SimpleBadge = styled.div`
  ${centerWithFlex};
  border-radius: 4px;
  display: inline-flex;
  font-size: 12px;
  height: 20px;
  line-height: 12px;
  :first-of-type {
    margin-left: 8px;
  }
  margin-right: 8px;
  padding: 2px 4px;
`

const St = {
  BadgeCheckIn: styled(SimpleBadge)`
    background-color: ${colors.midnight70};
    color: white;
  `,
  BadgeLate: styled(SimpleBadge)`
    background-color: ${colors.midnight20};
    border: 1px solid ${colors.midnight30};
    color: ${colors.midnight80};
  `,
  OwnerBadge,
  VisitStatusIcons: styled.div`
    ${centerWithFlex};
    background-color: rgba(255, 255, 255, 0.3);
    border-top-right-radius: 4px;
    color: ${colors.midnight70};
    height: 100%;
    text-transform: none;
  `,
}

const useTranslations = () => {
  const { t } = useI18n()
  return {
    checkIn: t(Slugs.checkIn),
    late: t(Slugs.badgeLate),
  }
}

export type VisitStatusIconsProps = {
  isCheckIn: boolean
  isLateCheckout: boolean
  isOwnerVisit: boolean
}

export const VisitStatusIcons: React.FC<VisitStatusIconsProps> = React.memo(
  ({ isCheckIn, isLateCheckout, isOwnerVisit }) => {
    const strings = useTranslations()
    const hasAnythingToRender = any(Boolean, [
      isCheckIn,
      isLateCheckout,
      isOwnerVisit,
    ])

    return hasAnythingToRender ? (
      <St.VisitStatusIcons>
        {isCheckIn && <St.BadgeCheckIn>{strings.checkIn}</St.BadgeCheckIn>}
        {isLateCheckout && <St.BadgeLate>{strings.late}</St.BadgeLate>}
        {isOwnerVisit && (
          <St.OwnerBadge>
            <SvgIcon
              centerItems={true}
              icon={IconName.crown}
              size={22}
              text={upperCase(Slugs.owner)}
            />
          </St.OwnerBadge>
        )}
      </St.VisitStatusIcons>
    ) : null
  },
)
