import React from 'react'
import { Route, Routes } from 'react-router-dom'

import {
  Loader,
  SentryErrorBoundary,
  ServiceWorkerWrapper,
} from 'packages/common'
import { FeatureBranchLoginPage } from 'packages/featureBranchLogin/FeatureBranchLoginPage'

import { useIsFeatureBranchDeployment } from '../../packages/featureBranchLogin/useIsFeatureBranchDeployment'
import { useIsFeatureBranchLogin } from '../../packages/featureBranchLogin/useIsFeatureBranchLogin'
import { register as registerSW } from '../../serviceWorker'
import { RefreshOnFocus, UpdateNotifier } from './components'
import FieldAppRoot from './components/FieldAppRoot'
import { FIELD_APP_CONTAINER_ID } from './FieldApp.constants'
import { useAppAuth } from './utils/hooks/useAppAuth'

import styles from './FieldApp.module.scss'

const FieldApp: React.FC = () => {
  const { authState } = useAppAuth()
  const { isFeatureBranchLogin } = useIsFeatureBranchLogin()
  const { isFeatureBranchDeployment } = useIsFeatureBranchDeployment()

  return (
    <SentryErrorBoundary boundary={'fieldapp-root'}>
      <RefreshOnFocus />

      {/* Feature Branch deployment will use different login screen which needs to be accessible without any token, but only if this is feature branch deployment */}
      {isFeatureBranchDeployment && (
        <Routes>
          <Route
            path="/feature-branch-login"
            element={<FeatureBranchLoginPage />}
          />
        </Routes>
      )}

      {/* If this is not a feature branch deployment, show always the regular FieldApp */}
      {!isFeatureBranchLogin && (
        <>
          {!authState.loaded ? (
            <Loader />
          ) : (
            <div
              className={styles.fieldAppViewWrapper}
              id={FIELD_APP_CONTAINER_ID}
              data-testid={FIELD_APP_CONTAINER_ID}
            >
              <ServiceWorkerWrapper
                registerSW={registerSW}
                updateNotifier={<UpdateNotifier />}
              />

              <FieldAppRoot initialUrl={authState.initialUrl} />
            </div>
          )}
        </>
      )}
    </SentryErrorBoundary>
  )
}

export default FieldApp
