import React from 'react'

import { IconName, SvgIcon } from 'packages/iconic'
import { format, DateFormat } from 'packages/utils/dateHelpers'

import { useCrossCoverageDrawerTranslations } from '../../../useCrossCoverageDrawerTranslations'
import St from '../../../utils/CrossCoverageDrawer.styles'
import { FormValidationError } from '../../../utils/CrossCoverageState.helpers'
import { TimeOption, TimeSelect } from '../TimeSelect'

export enum TimeAndDateFormTestIds {
  eventEndDatePickerButton = 'eventEndDatePickerButton',
  eventEndField = 'eventEndField',
  eventStartDatePickerButton = 'eventStartDatePickerButton',
  eventStartField = 'eventStartField',
  eventStartTimeSelect = 'eventStartTimeSelect',
}

export enum TimeAndDateFormAriaLabels {
  eventEndTimeSelect = 'Event end time select',
  eventStartTimeSelect = 'Event start time select',
}

export type TimeAndDateFormProps = {
  endDate: Date
  endTime: TimeOption
  endTimeOptions: TimeOption[]
  formValidationError?: FormValidationError | undefined
  handleTimeChange: (type: 'start' | 'end') => (value: TimeOption) => void
  isDisabled: boolean
  isRepeating: boolean
  showEndDatePickerModal: () => void
  showStartDatePickerModal: () => void
  startDate: Date
  startTime: TimeOption
  startTimeOptions: TimeOption[]
}

export const TimeAndDateForm: React.FC<TimeAndDateFormProps> = React.memo(
  ({
    endDate,
    endTime,
    endTimeOptions,
    formValidationError,
    handleTimeChange,
    isDisabled,
    isRepeating,
    showEndDatePickerModal,
    showStartDatePickerModal,
    startDate,
    startTime,
    startTimeOptions,
  }) => {
    const strings = useCrossCoverageDrawerTranslations()
    return (
      <>
        <St.FormField data-testid={TimeAndDateFormTestIds.eventStartField}>
          <St.FormFieldLabel>
            {strings.eventStart}
            <St.FormFieldLabelRequiredAsterisk>
              *
            </St.FormFieldLabelRequiredAsterisk>
          </St.FormFieldLabel>
          <St.FormFieldPickerButtonGroup>
            <St.FormFieldPickerButton
              data-testid={TimeAndDateFormTestIds.eventStartDatePickerButton}
              disabled={isDisabled}
              onClick={showStartDatePickerModal}
            >
              {format(startDate, DateFormat.SlashesWithShortYear)}
              <SvgIcon size={18} icon={IconName.calendar} />
            </St.FormFieldPickerButton>
            <TimeSelect
              ariaLabel={TimeAndDateFormTestIds.eventStartTimeSelect}
              disabled={isDisabled}
              handleChange={handleTimeChange('start')}
              isValid={!formValidationError?.cause?.startTime}
              options={startTimeOptions}
              value={startTime}
            />
          </St.FormFieldPickerButtonGroup>
        </St.FormField>
        <St.FormField data-testid={TimeAndDateFormTestIds.eventEndField}>
          <St.FormFieldLabel>
            {strings.eventEnd}
            <St.FormFieldLabelRequiredAsterisk>
              *
            </St.FormFieldLabelRequiredAsterisk>
          </St.FormFieldLabel>
          <St.FormFieldPickerButtonGroup>
            {!isRepeating && (
              <St.FormFieldPickerButton
                data-testid={TimeAndDateFormTestIds.eventEndDatePickerButton}
                disabled={isDisabled}
                onClick={showEndDatePickerModal}
              >
                {format(endDate, DateFormat.SlashesWithShortYear)}
                <SvgIcon size={18} icon={IconName.calendar} />
              </St.FormFieldPickerButton>
            )}
            <TimeSelect
              ariaLabel={TimeAndDateFormAriaLabels.eventEndTimeSelect}
              disabled={isDisabled}
              handleChange={handleTimeChange('end')}
              isValid={!formValidationError?.cause?.endTime}
              options={endTimeOptions}
              value={endTime}
            />
            {isRepeating && (
              <St.FormFieldPickerButtonGroupBottom>
                Event will end the{' '}
                {endTime.isNextDay ? strings.nextDay : strings.sameDay} at{' '}
                {endTime.timeOfDay}
              </St.FormFieldPickerButtonGroupBottom>
            )}
          </St.FormFieldPickerButtonGroup>
        </St.FormField>
      </>
    )
  },
)
