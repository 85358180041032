import { RequestOptions } from 'packages/utils/store/jsonapi.types'

import { api } from '../axiosInstance'
import {
  CrossCoverageServiceResponse,
  CrossCoverageApiResponse,
} from './crossCoverage.types'
import { emptyCrossCoverageResponse } from './crossCoverage.utils'

const URL = '/cross_coverage'

export const crossCoverageService = {
  async createCrossCoverage(
    data: RequestOptions,
  ): CrossCoverageServiceResponse {
    const response: CrossCoverageApiResponse = await api.post(URL, data)

    return response?.data || emptyCrossCoverageResponse
  },

  async updateCrossCoverage(
    id: string,
    patchData: RequestOptions,
  ): CrossCoverageServiceResponse {
    const response: CrossCoverageApiResponse = await api.patch(
      `${URL}/${id}`,
      patchData,
    )

    return response?.data || emptyCrossCoverageResponse
  },

  async fetchCrossCoverages(
    params: RequestOptions,
  ): CrossCoverageServiceResponse {
    const response: CrossCoverageApiResponse = await api.get(URL, { params })

    return response?.data || emptyCrossCoverageResponse
  },

  async fetchCrossCoverageById(
    crossCoverageId: string,
    params: RequestOptions,
  ): CrossCoverageServiceResponse {
    const response: CrossCoverageApiResponse = await api.get(
      `${URL}/${crossCoverageId}`,
      { params },
    )

    return response?.data || emptyCrossCoverageResponse
  },

  async deleteCrossCoverageById(
    crossCoverageId: string,
  ): CrossCoverageServiceResponse {
    const response: CrossCoverageApiResponse = await api.delete(
      `${URL}/${crossCoverageId}`,
    )

    return response?.data || emptyCrossCoverageResponse
  },
}
