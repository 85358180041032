import styled from '@emotion/styled'
import React from 'react'

import {
  BannerTestIds,
  Drawer,
  DrawerContainerProps,
  LegacyModal2TestIds,
} from 'packages/common'

import { CREATE_TICKET_DRAWER_ID } from 'app/fieldapp/components/schedule/components/common'
import { TEST_ID_TIMERS } from 'app/fieldapp/components/timers'

import { VISIT_TICKET_DRAWER_ID } from '../../VisitTicketDrawer/VisitTicketDrawer'
import { ReviewDetail } from '../ReviewDetail'

export const REVIEWS_DRAWER_ID = 'ReviewsDrawer'

const St = {
  Drawer: styled(Drawer)`
    width: min(420px, 100vw);
  `,

  Header: styled.div`
    align-items: center;
    display: flex;
    height: 48px;
    padding-left: 24px;
  `,
}

const ignoreClickEventsOn: DrawerContainerProps['ignoreClickEventsOn'] = [
  `#${TEST_ID_TIMERS}`,
  `#${BannerTestIds.container}`,
  `#${CREATE_TICKET_DRAWER_ID}`,
  `#${CREATE_TICKET_DRAWER_ID}__backdrop`,
  `#${VISIT_TICKET_DRAWER_ID}`,
  `#${REVIEWS_DRAWER_ID}`,
  `#${LegacyModal2TestIds.container}`,
]

type PickedDrawerProps = Pick<DrawerContainerProps, 'afterExit' | 'isOpen'>
export type ReviewsDrawerProps = PickedDrawerProps

export const ReviewDrawer: React.FC<ReviewsDrawerProps> = React.memo(
  ({ afterExit, isOpen }) => {
    const [isDrawerForceClosed, setIsDrawerForceClosed] = React.useState(false)
    const forceCloseDrawer = () => setIsDrawerForceClosed(true)

    return (
      <St.Drawer
        afterExit={afterExit}
        id={REVIEWS_DRAWER_ID}
        forceClose={isDrawerForceClosed}
        ignoreClickEventsOn={ignoreClickEventsOn}
        isOpen={isOpen}
        data-testid={REVIEWS_DRAWER_ID}
      >
        <ReviewDetail onCloseDrawerClick={forceCloseDrawer} />
      </St.Drawer>
    )
  },
)
