import React from 'react'

import { IconName } from 'packages/iconic'

import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { useAppSelector } from 'app/fieldapp/store/hooks'
import { ApplicationState } from 'app/fieldapp/store/store'
import { getVisitDrawerState } from 'app/fieldapp/store/ui/selectors'
import { getVisitById } from 'app/fieldapp/store/visits/selectors'

import { TaskDetailUnitLinks } from '../TaskDetailUnitLinks'
import {
  BottomDivider,
  ScheduledVisitIcon,
  TaskDetailContainer,
  TaskDetailWrapper,
  TaskIcon,
  VisitDetailHeader,
  VisitHeaderLeftIcons,
} from '../TaskSharedStyles'
import {
  PreviousCleanInfo,
  RemoveVisitContainer,
  VisitAccessCodes,
  VisitAddressInfo,
  VisitHomeInfo,
  VisitResDetailsContainer,
  VisitStatusBannerContainer,
  VisitStatusIconsContainer,
  VisitWifiInfo,
} from './components'
import { OwnerInfo } from './components/OwnerInfo/OwnerInfo'

const useTranslations = () => {
  const { ut } = useI18n()

  return {
    visit: ut(Slugs.visit),
  }
}

const St = {
  BottomDivider,
  ScheduledVisitIcon,
  TaskDetailContainer,
  TaskDetailWrapper,
  TaskIcon,
  VisitDetailHeader,
  VisitHeaderLeftIcons,
}

const textStyles: React.CSSProperties = {
  marginLeft: '8px',
}

export type VisitDetailProps = {
  onCloseDrawerClick: () => void
}

export const VisitDetail: React.FC<VisitDetailProps> = React.memo(
  ({ onCloseDrawerClick }) => {
    const { visitId } = useAppSelector(getVisitDrawerState)
    const visit = useAppSelector((state: ApplicationState) =>
      getVisitById(state, visitId),
    )

    const strings = useTranslations()

    return (
      <St.TaskDetailContainer>
        <St.VisitDetailHeader>
          <St.VisitHeaderLeftIcons>
            <St.TaskIcon
              centerItems={true}
              icon={IconName.maintenance}
              size={18}
              text={strings.visit}
              textFontSize={'inherit'}
              textStyleOverrides={textStyles}
            />
            {visit?.serviceType === 'scheduled' && (
              <St.ScheduledVisitIcon
                centerItems={true}
                icon={IconName.refreshCw}
                size={16}
              />
            )}
          </St.VisitHeaderLeftIcons>
          <VisitStatusIconsContainer />
        </St.VisitDetailHeader>

        <VisitAddressInfo />
        <VisitStatusBannerContainer />

        <St.TaskDetailWrapper>
          {/* Note that if a visit has both a smartLock and a regular lockbox, we should always prefer the smartLock */}
          <VisitAccessCodes />
          <VisitResDetailsContainer />
          <PreviousCleanInfo />
          <OwnerInfo unitId={visit?.unitId} />
          <VisitHomeInfo />
          <VisitWifiInfo />
          <St.BottomDivider />

          <TaskDetailUnitLinks taskId={visit?.id} unitId={visit?.unitId} />

          <St.BottomDivider />
          <RemoveVisitContainer onCloseDrawerClick={onCloseDrawerClick} />
        </St.TaskDetailWrapper>
      </St.TaskDetailContainer>
    )
  },
)
