import { transformNormalizedToTyped } from 'packages/utils/store'

import {
  NormalizedSmartLocksApiResponse,
  RawSmartLock,
  SmartLock,
  SmartLockAttributeNames,
} from './smartlocks.types'

/**
 * Empty data in the shape of the  service's `normalized` payload.
 * Use this as a fallback when there are issues with the API data being undefined or malformed.
 */
export const emptyNormalizedSmartLocksData = Object.freeze({
  assignment: {},
  smartLock: {},
})

/**
 * Empty data to use as a "safety net" any time an API response is undefined for any reason
 */
export const emptySmartLocksResponse: NormalizedSmartLocksApiResponse =
  Object.freeze({
    normalized: emptyNormalizedSmartLocksData,
    raw: { data: [] },
  })

export const transformRawSmartLock = (rawSmartLock: RawSmartLock): SmartLock =>
  transformNormalizedToTyped<SmartLock>(rawSmartLock, SmartLockAttributeNames)
