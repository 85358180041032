import { difference } from 'lodash/fp'

import { logInfo } from 'packages/wiretap/logging'

const Keys = {
  pendingUploads: 'pendingImageUploads',
}

export const pendingImageUploads = {
  add(id: string): void {
    if (!id) return

    try {
      const jsonIn = sessionStorage.getItem(Keys.pendingUploads)
      const list = jsonIn ? JSON.parse(jsonIn) : []
      list.push(id)

      const jsonOut = JSON.stringify(list)
      sessionStorage.setItem(Keys.pendingUploads, jsonOut)
    } catch (err) {
      // not a huge deal if there's an error here, but worth logging
      logInfo('Error setting "pending upload" to session storage.', {
        error: err.message,
        taskPhotoId: id,
      })
    }
  },

  has(id: string): boolean {
    const jsonIn = sessionStorage.getItem(Keys.pendingUploads)
    const list = jsonIn ? JSON.parse(jsonIn) : []
    return list.includes(id)
  },

  remove(id: string): void {
    if (!id) return

    try {
      const jsonIn = sessionStorage.getItem(Keys.pendingUploads)
      const list = jsonIn ? JSON.parse(jsonIn) : []

      if (list.length) {
        const jsonOut = JSON.stringify(difference(list, [id]))
        sessionStorage.setItem(Keys.pendingUploads, jsonOut)
      }
    } catch (err) {
      // not a huge deal if there's an error here, but worth logging
      logInfo('Error removing "pending upload" from session storage.', {
        data: { error: err.message, taskPhotoId: id },
      })
    }
  },
}
