import { createSelector } from 'reselect'

import { ApplicationState } from 'app/fieldapp/store/store'

import { getCleanTimeById } from '../../cleantimes/selectors/getCleanTimeById'
import { CleanTime, RawCleanTime } from '../cleantimes.types'

const getFullState = (state: ApplicationState): ApplicationState => state

/**
 * Returns an object, keyed by assignmentId, where each value is an array of
 * CleanTimes where the attached assignment has a id equal to the key
 */
export const getCleanTimesBucketedByAssignmentId = createSelector(
  getFullState,
  state => {
    const rawCleanTimes: RawCleanTime[] = Object.values(state.cleanTimes.data)

    return rawCleanTimes.reduce((acc, rawCt) => {
      const cleanTime = getCleanTimeById(state, rawCt.id)

      if (cleanTime) {
        const assignmentId = cleanTime.assignment.id

        const bucket = acc[assignmentId] || []
        bucket.push(cleanTime)
        acc[assignmentId] = bucket

        return acc
      }

      return acc
    }, {} as { [assignmentId: string]: CleanTime[] })
  },
)
