import { createAsyncAction } from 'typesafe-actions'

import { Optional } from 'packages/grimoire/src/utils'
import { OfflineRequestConfig } from 'packages/utils/store'

import { makeOfflineTimer } from 'app/fieldapp/components/timers/timers.helpers'
import {
  NormalizedOtherTimersApiResponse,
  OtherTimePatchData,
  OtherTimersActionTypes,
} from 'app/fieldapp/store/otherTimers'
import { offlineTimers } from 'app/fieldapp/store/utils'

export const updateOtherTimerOfflineAction = createAsyncAction(
  OtherTimersActionTypes.OFFLINE_UPDATE_OTHER_TIMER,
  OtherTimersActionTypes.OFFLINE_UPDATE_OTHER_TIMER_SUCCESS,
  OtherTimersActionTypes.OFFLINE_UPDATE_OTHER_TIMER_FAILURE,
)<
  OfflineRequestConfig<NormalizedOtherTimersApiResponse, OtherTimePatchData>,
  Optional<
    OfflineRequestConfig<NormalizedOtherTimersApiResponse, OtherTimePatchData>,
    'request'
  >,
  Error
>()

export const updateOtherTimerOffline =
  (
    payload: Optional<
      OfflineRequestConfig<
        NormalizedOtherTimersApiResponse,
        OtherTimePatchData
      >,
      'request'
    >,
  ) =>
  async dispatch => {
    const existingTimer = await offlineTimers.getOtherTimeByID(payload.data.id)

    const timerData: OtherTimePatchData = existingTimer
      ? ({
          ...existingTimer.attributes,
          ...payload.data,
        } as unknown as OtherTimePatchData)
      : payload.data

    await offlineTimers.saveOtherTime(makeOfflineTimer(timerData))

    await dispatch(updateOtherTimerOfflineAction.success({ data: timerData }))
  }
