import * as React from 'react'

import { useOnlineStatus } from 'packages/utils/hooks'

import {
  useOfflineMergedTimers,
  useTimersRenderState,
} from 'app/fieldapp/components/timers/hooks'

import { OfflineTimersBanner } from './OfflineTimersBanner'

export const OfflineTimersBannerContainer: React.FC = () => {
  const isOnline = useOnlineStatus().isOnline()
  const { hasSubmitTimers } = useTimersRenderState()
  const { stoppedOfflineTimersCount } = useOfflineMergedTimers()

  return (
    <OfflineTimersBanner
      isOnline={isOnline}
      isSubmitting={hasSubmitTimers}
      timersCount={stoppedOfflineTimersCount}
    />
  )
}
