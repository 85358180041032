import React from 'react'
import { useSelector } from 'react-redux'

import { useMemoById } from 'packages/utils/hooks'

import { useTicket } from 'app/fieldapp/components/tickets/hooks'
import { useTicketTimerActions } from 'app/fieldapp/components/timers/hooks'
import { useTimers as useTimersContext } from 'app/fieldapp/components/timers/state'
import { StartedOfflineTicketTime } from 'app/fieldapp/components/timers/timers.types'
import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { ApplicationState } from 'app/fieldapp/store/store'
import { OfflineTicketTime, TicketTime } from 'app/fieldapp/store/ticket-times'
import { getMergedTicketTimeById } from 'app/fieldapp/store/ticket-times/selectors'
import { getOfflineTimerById } from 'app/fieldapp/store/utils/timers/selectors'

import { SubmissionTimer } from '../../types'
import { SubmitTicketTimers } from './SubmitTicketTimers'

export const SubmitTicketTimersContainer: React.FC = () => {
  const { ut } = useI18n()
  const { state } = useTimersContext()
  const { submissionData } = state

  const {
    dispatchCancelStopOfflineTicketTimer,
    dispatchCancelStopTicketTimer,
    dispatchCompleteStartedOfflineTicketTimer,
    dispatchConfirmTicketTimeSubmission,
    dispatchDeleteTicketTimeWithModalWarning,
  } = useTicketTimerActions()

  // find and memoize the ticket associated with this timer (if any)
  const ticketId = (submissionData?.timer as TicketTime)?.ticketId || ''
  const { ticket, unit } = useTicket(ticketId)
  const ticketUnitName = unit?.name || ut(Slugs.unknown)

  // find, memoize, and transform timer for the submit form
  const timerId = submissionData?.timer.id || ''
  const timer = useSelector((storeState: ApplicationState) =>
    getMergedTicketTimeById(storeState, timerId),
  )
  const memoizedTimer = useMemoById(timer)

  const offlineTimer: OfflineTicketTime | undefined = useSelector(
    (storeState: ApplicationState) =>
      submissionData?.timer.id
        ? getOfflineTimerById(storeState, submissionData?.timer.id)
        : undefined,
  )

  // memoize a whole bunch of TicketTime action callbacks
  const dispatchAbortSubmission = React.useCallback(async () => {
    if (offlineTimer?.id) {
      await dispatchCancelStopOfflineTicketTimer(offlineTimer.id)
    } else if (memoizedTimer?.id) {
      await dispatchCancelStopTicketTimer(memoizedTimer.id)
    }
  }, [
    dispatchCancelStopTicketTimer,
    dispatchCancelStopOfflineTicketTimer,
    memoizedTimer?.id,
    offlineTimer?.id,
  ])

  const dispatchConfirmSubmission = React.useCallback(
    async (timer: SubmissionTimer) => {
      if (ticket) {
        await dispatchConfirmTicketTimeSubmission(timer, ticket.openVisitId)
      }
    },
    [ticket, dispatchConfirmTicketTimeSubmission],
  )

  const dispatchCompleteStartedOffline = React.useCallback(
    async (timer: StartedOfflineTicketTime) => {
      await dispatchCompleteStartedOfflineTicketTimer(timer, ticket)
    },
    [dispatchCompleteStartedOfflineTicketTimer, ticket],
  )

  const dispatchDeleteTimer = React.useCallback(async () => {
    if (memoizedTimer?.id) {
      await dispatchDeleteTicketTimeWithModalWarning(memoizedTimer.id)
    }
  }, [dispatchDeleteTicketTimeWithModalWarning, memoizedTimer?.id])

  return memoizedTimer ? (
    <SubmitTicketTimers
      dispatchAbortSubmission={dispatchAbortSubmission}
      dispatchCompleteStartedOffline={dispatchCompleteStartedOffline}
      dispatchConfirmSubmission={dispatchConfirmSubmission}
      dispatchDeleteTimer={dispatchDeleteTimer}
      displayValue={ticketUnitName}
      isExternalComment={offlineTimer?.attributes.isExternalComment}
      ticket={ticket}
      timer={memoizedTimer}
    />
  ) : null
}
