import { OtherTimerType } from 'packages/grimoire'

import { Slugs } from 'app/fieldapp/i18n'

import { TimerMenuConfig } from './OtherTimers.types'

export enum OtherTimersMainMenuDataTestIds {
  drive = 'otherTimers__drive',
  laundry = 'otherTimers__laundry',
  other = 'otherTimers__other',
  training = 'otherTimers__training',
}

export const mainMenu: TimerMenuConfig = {
  id: 'main',
  timers: [
    {
      dataTestId: OtherTimersMainMenuDataTestIds.drive,
      slug: Slugs.drive,
      subMenu: 'drive',
      type: 'MENU',
    },
    {
      dataTestId: OtherTimersMainMenuDataTestIds.laundry,
      slug: Slugs.laundry,
      type: OtherTimerType.LAUNDRY,
    },
    {
      dataTestId: OtherTimersMainMenuDataTestIds.training,
      slug: Slugs.training,
      type: OtherTimerType.TRAINING,
    },
    {
      dataTestId: OtherTimersMainMenuDataTestIds.other,
      slug: Slugs.other,
      subMenu: 'other',
      type: 'MENU',
    },
  ],
}

export enum OtherTimersOtherMenuDataTestIds {
  admin = 'otherTimers__other_admin',
  employeeMeeting = 'otherTimers__other_employee_meeting',
  office = 'otherTimers__other_office',
  scheduling = 'otherTimers__other_scheduling',
}

export const otherMenu: TimerMenuConfig = {
  id: 'other',
  showCreateTicketLink: true,
  timers: [
    {
      dataTestId: OtherTimersOtherMenuDataTestIds.scheduling,
      slug: Slugs.otherTimerScheduling,
      subType: 'scheduling',
      type: OtherTimerType.OTHER,
    },
    {
      dataTestId: OtherTimersOtherMenuDataTestIds.admin,
      slug: Slugs.otherTimerAdmin,
      subType: 'admin',
      type: OtherTimerType.OTHER,
    },
    {
      dataTestId: OtherTimersOtherMenuDataTestIds.office,
      slug: Slugs.otherTimerOfficeCleaning,
      subType: 'office_clean',
      type: OtherTimerType.OTHER,
    },
    {
      dataTestId: OtherTimersOtherMenuDataTestIds.employeeMeeting,
      slug: Slugs.otherTimerEmployeeMeeting,
      subType: 'employee_meeting',
      type: OtherTimerType.OTHER,
    },
  ],
  title: Slugs.otherTaskToStart,
}

export enum OtherTimersDriveMenuDataTestIds {
  clean = 'otherTimers__drive_clean',
  home = 'otherTimers__drive_home',
  inspection = 'otherTimers__drive_inspection',
  laundry = 'otherTimers__drive_laundry',
  maintenance = 'otherTimers__drive_maintenance',
  supplies = 'otherTimers__drive_supplies',
}

export const driveMenu: TimerMenuConfig = {
  id: 'drive',
  showCreateTicketLink: false,
  timers: [
    {
      dataTestId: OtherTimersDriveMenuDataTestIds.clean,
      slug: Slugs.driveTimerCleanAProperty,
      subType: 'clean',
      type: OtherTimerType.DRIVE,
    },
    {
      dataTestId: OtherTimersDriveMenuDataTestIds.maintenance,
      slug: Slugs.driveTimerMaintenance,
      subType: 'maintenance',
      type: OtherTimerType.DRIVE,
    },
    {
      dataTestId: OtherTimersDriveMenuDataTestIds.supplies,
      slug: Slugs.driveTimerSupplies,
      subType: 'supplies',
      type: OtherTimerType.DRIVE,
    },
    {
      dataTestId: OtherTimersDriveMenuDataTestIds.laundry,
      slug: Slugs.driveTimerOffSiteLaundry,
      subType: 'laundry',
      type: OtherTimerType.DRIVE,
    },
    {
      dataTestId: OtherTimersDriveMenuDataTestIds.inspection,
      slug: Slugs.driveTimerInspection,
      subType: 'inspection',
      type: OtherTimerType.DRIVE,
    },
  ],
  title: Slugs.reasonForDriving,
}
