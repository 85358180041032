import { createAsyncThunk } from '@reduxjs/toolkit'

import { RequestOptions } from 'packages/utils/store/jsonapi.types'

import { unitsService } from '../units.service'

export const getParams = (): RequestOptions => ({
  fields: {
    unit: [],
  },
  include: ['open_inspection_flags.tickets'],
  page: { size: 1500 },
})

export const fetchUnitAndInspectionFlagsById = createAsyncThunk(
  'units/api/fetchUnitAndInspectionFlagsById',
  async (unitId: string) => {
    const params = getParams()
    return await unitsService.fetchUnitById(unitId, params)
  },
)
