import { Events, track } from 'packages/wiretap'

import { getDelegateUserId } from 'app/fieldapp/store/auth/selectors'
import { ApplicationState } from 'app/fieldapp/store/store'
import { getActiveUser } from 'app/fieldapp/store/users/selectors'

import { CleanPatchData } from './cleans.types'

export const trackCleanStartedAndCompleted = (
  patchData: CleanPatchData,
  getState: () => ApplicationState,
): void => {
  const { completedAt, id, startedAt } = patchData
  if (!completedAt && !startedAt) return

  const state = getState()
  const activeUser = getActiveUser(state)
  const delegateUserId = getDelegateUserId(state)

  const payload = {
    clean_id: id,
    is_delegate: !!delegateUserId,
    user_id: activeUser?.id || delegateUserId || 'unknown',
  }

  if (startedAt) {
    track(Events.fieldAppStartClean, payload)
  }

  if (completedAt) {
    track(Events.fieldAppCompleteClean, payload)
  }
}
