import { connect } from 'react-redux'

import { ApplicationState } from 'app/fieldapp/store/store'
import { getActiveUser } from 'app/fieldapp/store/users/selectors'

import { ProfilePage } from './ProfilePage'

const mapStateToProps = (state: ApplicationState) => ({
  activeUser: getActiveUser(state),
})

export const ConnectedProfilePage = connect(mapStateToProps)(ProfilePage)
