import * as React from 'react'

import { useAppSelector } from 'app/fieldapp/store/hooks'
import { ApplicationState } from 'app/fieldapp/store/store'
import { getVisitDrawerState } from 'app/fieldapp/store/ui/selectors'
import { getVisitById } from 'app/fieldapp/store/visits/selectors'

import { VisitInspectionTimerControlsContainer } from '../VisitDetail/components/VisitInspectionTimerControls/VisitInspectionTimerControls.container'
import { InspectionChecklistContextWrapper } from './context/VisitInspectionChecklist.context'
import { VisitInspectionChecklist } from './VisitInspectionChecklist'

export type VisitInspectionChecklistContainerProps = {
  isLoadingVisit: boolean
}

export const VisitInspectionChecklistContainer: React.FC<VisitInspectionChecklistContainerProps> =
  React.memo(({ isLoadingVisit }) => {
    const { visitId } = useAppSelector(getVisitDrawerState)

    const visit = useAppSelector((state: ApplicationState) =>
      getVisitById(state, visitId),
    )

    if (!visit) return null

    return (
      <InspectionChecklistContextWrapper visit={visit}>
        {!!visit?.startedAt && (
          <VisitInspectionTimerControlsContainer visit={visit} />
        )}
        <VisitInspectionChecklist isLoadingVisit={isLoadingVisit} />
      </InspectionChecklistContextWrapper>
    )
  })
