import { createAsyncAction } from 'typesafe-actions'

import { Optional } from 'packages/grimoire/src/utils'
import { OfflineRequestConfig } from 'packages/utils/store'

import { makeOfflineTimer } from 'app/fieldapp/components/timers/timers.helpers'
import {
  NormalizedCleanTimesApiResponse,
  CleanTimePatchData,
  CleanTimesActionTypes,
} from 'app/fieldapp/store/cleantimes'

import { offlineTimers } from '../../utils'

export const updateCleanTimeOfflineAction = createAsyncAction(
  CleanTimesActionTypes.OFFLINE_UPDATE_CLEAN_TIME,
  CleanTimesActionTypes.OFFLINE_UPDATE_CLEAN_TIME_SUCCESS,
  CleanTimesActionTypes.OFFLINE_UPDATE_CLEAN_TIME_FAILURE,
)<
  OfflineRequestConfig<NormalizedCleanTimesApiResponse, CleanTimePatchData>,
  Optional<
    OfflineRequestConfig<NormalizedCleanTimesApiResponse, CleanTimePatchData>,
    'request'
  >,
  Error
>()

export const updateCleanTimeOffline =
  (
    payload: Optional<
      OfflineRequestConfig<NormalizedCleanTimesApiResponse, CleanTimePatchData>,
      'request'
    >,
  ) =>
  async dispatch => {
    const existingTimer = await offlineTimers.getCleanTimeByID(payload.data.id)

    const timerData: CleanTimePatchData = existingTimer
      ? ({
          ...existingTimer.attributes,
          ...payload.data,
        } as unknown as CleanTimePatchData)
      : payload.data

    await offlineTimers.saveCleanTime(makeOfflineTimer(timerData))

    dispatch(updateCleanTimeOfflineAction.success({ data: timerData }))
  }
