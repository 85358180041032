import { useSelector } from 'react-redux'

import { getCleanTimesRequestPending } from 'app/fieldapp/store/cleantimes/selectors'
import { getOtherTimersRequestPending } from 'app/fieldapp/store/otherTimers/selectors'
import { getTicketTimesRequestPending } from 'app/fieldapp/store/ticket-times/selectors'

export const useTimersRequestPending = (): boolean => {
  const cleanTimesPending = useSelector(getCleanTimesRequestPending)
  const otherTimersPending = useSelector(getOtherTimersRequestPending)
  const ticketTimesPending = useSelector(getTicketTimesRequestPending)

  return cleanTimesPending || otherTimersPending || ticketTimesPending
}
