import styled from '@emotion/styled'
import { noop } from 'lodash/fp'
import React from 'react'
import { useMount } from 'react-use'

import {
  Button,
  ExternalLink,
  ModalChildrenProps,
  ModalConfig,
  useModalContext,
} from 'packages/common'
import {
  ModalButtons,
  ModalContent,
  ModalTitle,
} from 'packages/common/src/modals/Modal/Modal.styles'
import { storage } from 'packages/utils/misc'

import { Slugs, useI18n } from 'app/fieldapp/i18n'

const URL =
  'https://www.vacasa.com/policies/privacy-notice-employees-and-contractors'

const St = {
  ExternalLink: styled(ExternalLink)`
    font-size: 16px;
  `,
  LinkSection: styled(ModalContent)`
    margin-top: 16px;
  `,
  ModalButtons: styled(ModalButtons)`
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 30px;
  `,
  ModalContent,
  ModalTitle: styled(ModalTitle)`
    display: flex;
    font-size: 18px;
    font-weight: 700;
    justify-content: center;
    margin-top: 20px;
  `,
}

const useTranslations = () => {
  const { ut } = useI18n()

  return {
    agree: ut(Slugs.iAgree),
    body: ut(Slugs.privacyNoticeBody),
    link: ut(Slugs.privacyNotice),
    title: ut(Slugs.privacyNoticeTitle),
  }
}

export const PrivacyDisclosureModalBody: React.FC<ModalChildrenProps> =
  React.memo(({ beginClose = noop }) => {
    const handleConfirmClick = () => {
      storage.setItem('PrivacyDisclosureConfirmed', 'true')
      beginClose()
    }

    const strings = useTranslations()

    return (
      <>
        <St.ModalTitle>{strings.title}</St.ModalTitle>
        <St.ModalContent>{strings.body}</St.ModalContent>
        <St.LinkSection>
          <St.ExternalLink text={strings.link} url={URL} />
        </St.LinkSection>
        <St.ModalButtons>
          <Button buttonType={'primary'} onClick={handleConfirmClick}>
            {strings.agree}
          </Button>
        </St.ModalButtons>
      </>
    )
  })

export const usePrivacyDisclosureModal = (): void => {
  const modalConfig = React.useMemo((): ModalConfig => {
    return {
      childRenderer: ({ beginClose }) => {
        return <PrivacyDisclosureModalBody beginClose={beginClose} />
      },
      modalProps: {
        disableCloseMethods: ['esc', 'backdrop'],
        hideCloseButton: true,
      },
    }
  }, [])
  const { showModal } = useModalContext()
  useMount(() => {
    if (!localStorage.getItem('PrivacyDisclosureConfirmed')) {
      showModal(modalConfig)
    }
  })
}
