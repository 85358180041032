import styled from '@emotion/styled'
import React from 'react'

import { createDateObject } from 'packages/utils/dateHelpers'
import { useOnlineStatus } from 'packages/utils/hooks'

import { useCleanCompletionModal } from 'app/fieldapp/components/timers/hooks/useCleanCompletionModal'
import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { Clean, CleanPatchData } from 'app/fieldapp/store/cleans'
import { updateClean } from 'app/fieldapp/store/cleans/actions'
import { useAppDispatch, useAppSelector } from 'app/fieldapp/store/hooks'
import { ApplicationState } from 'app/fieldapp/store/store'
import { setBanner } from 'app/fieldapp/store/ui/actions'

import {
  shouldRenderCleanTimerControls,
  useShouldRenderAssignmentRejectionControls,
} from '../../../../schedule.utils'
import { ContractorCleanControls } from './ContractorCleanControls'
import { ContractorRejectionControls } from './ContractorRejectionControls'

const St = {
  Container: styled.div`
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    padding: 8px;
    grid-gap: 16px;
    position: absolute;
    right: 0;
  `,
}

export type ContractorCleanControlsContainerProps = { clean: Clean }

export const ContractorCleanControlsContainer: React.FC<ContractorCleanControlsContainerProps> =
  React.memo(({ clean }) => {
    const dispatch = useAppDispatch()
    const { t } = useI18n()

    const isRejectingClean = useAppSelector(
      (state: ApplicationState) => state.ui.modals.cleanRejection.isRejecting,
    )

    const { isOnline } = useOnlineStatus()
    const [state, setState] = React.useState({
      disabled: false,
      loading: false,
    })

    const shouldRenderAssignmentRejectionControls =
      useShouldRenderAssignmentRejectionControls(clean)

    const handleError = React.useCallback(() => {
      dispatch(setBanner({ message: t(Slugs.anErrorOccurred), timeout: 5000 }))
    }, [dispatch, t])

    const updateCleanProp = (prop: 'startedAt') => async () => {
      const now = createDateObject()

      const cleanUpdateData: CleanPatchData | undefined = !clean[prop]
        ? {
            id: clean.id,
            [prop]: now,
          }
        : undefined

      if (cleanUpdateData) {
        setState(prev => ({ ...prev, loading: true }))
        try {
          await dispatch(updateClean(cleanUpdateData))
        } catch (e) {
          handleError()
        } finally {
          setState(prev => ({ ...prev, loading: false }))
        }
      }
    }

    const { showCleanCompletionModal } = useCleanCompletionModal()
    const boundShowCleanCompletionModal = React.useCallback(
      () => showCleanCompletionModal(clean),
      [clean, showCleanCompletionModal],
    )

    const isRunning = !!clean.startedAt && !clean.completedAt
    const handleClick = isRunning
      ? boundShowCleanCompletionModal
      : updateCleanProp('startedAt')

    const shouldRenderControls =
      shouldRenderAssignmentRejectionControls ||
      shouldRenderCleanTimerControls(clean)

    const shouldDisableCleanControls =
      state.disabled || !isOnline() || isRejectingClean

    return shouldRenderControls ? (
      <St.Container>
        {shouldRenderAssignmentRejectionControls && (
          <ContractorRejectionControls clean={clean} />
        )}
        {shouldRenderCleanTimerControls(clean) && (
          <ContractorCleanControls
            disabled={shouldDisableCleanControls}
            loading={state.loading}
            isRunning={isRunning}
            onClick={handleClick}
          />
        )}
      </St.Container>
    ) : null
  })
