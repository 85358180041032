import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Button } from 'packages/common'
import { SvgIcon } from 'packages/iconic'
import { centerWithFlex } from 'packages/styles'

const rowHeight = '60px'

const expandedTimerRowStyles = css`
  height: ${rowHeight};
  padding: 16px;

  margin-top: 24px;

  /* This is the first timer row since the header is always the first child */
  &:nth-of-type(2) {
    margin-top: 36px;
  }
`

const collapsedTimerRowStyles = css`
  height: 0px;
  overflow: hidden;
  padding: 0px;
`

export const ArrowWrapper = styled.div`
  ${centerWithFlex};
  height: 44px;
  width: 44px;
`

export const StyledTimerRow = styled.div<{ isExpanded: boolean }>`
  align-items: center;
  display: flex;
  font-size: 20px;
  justify-content: space-between;

  ${({ isExpanded }) =>
    isExpanded ? expandedTimerRowStyles : collapsedTimerRowStyles}
`

export const StartTimerIcon = styled(SvgIcon)`
  padding-left: 2px;
`
export const StartButton = styled(Button)`
  height: 44px;
  width: 44px;
`
export const Label = styled.div<{ isMenu: boolean }>`
  font-weight: ${({ isMenu }) => (isMenu ? 600 : 300)};
`
