import { produce } from 'immer'
import { createAsyncAction } from 'typesafe-actions'
import { v4 as uuid } from 'uuid'

import { OfflineRequestConfig } from 'packages/utils/store'

import { makeOfflineTimer } from 'app/fieldapp/components/timers/timers.helpers'
import {
  NormalizedOtherTimersApiResponse,
  OtherTimePatchData,
  OtherTimersActionTypes,
} from 'app/fieldapp/store/otherTimers'
import { offlineTimers } from 'app/fieldapp/store/utils'

type CreateOtherTimeOfflinePayload = OfflineRequestConfig<
  NormalizedOtherTimersApiResponse,
  Omit<OtherTimePatchData, 'id'>
>

type CreateOtherTimeOfflinePayloadWithId = OfflineRequestConfig<
  NormalizedOtherTimersApiResponse,
  OtherTimePatchData
>

export const createOtherTimerOfflineAction = createAsyncAction(
  OtherTimersActionTypes.OFFLINE_CREATE_OTHER_TIMER,
  OtherTimersActionTypes.OFFLINE_CREATE_OTHER_TIMER_SUCCESS,
  OtherTimersActionTypes.OFFLINE_CREATE_OTHER_TIMER_FAILURE,
)<
  CreateOtherTimeOfflinePayloadWithId,
  CreateOtherTimeOfflinePayloadWithId,
  Error
>()

export const createOtherTimerOffline =
  (payload: CreateOtherTimeOfflinePayload) => async dispatch => {
    const id = uuid()

    const updatedPayload: CreateOtherTimeOfflinePayloadWithId = produce(
      payload,
      (draft: CreateOtherTimeOfflinePayloadWithId) => {
        draft.data.id = id
        draft.data.startedOffline = true
      },
    ) as CreateOtherTimeOfflinePayloadWithId

    await offlineTimers.saveOtherTime(makeOfflineTimer(updatedPayload.data))

    await dispatch(createOtherTimerOfflineAction.success(updatedPayload))
  }
