import styled from '@emotion/styled'
import classNames from 'classnames'
import React from 'react'

import { ExternalLink } from 'packages/common'
import { IconName, SvgIcon } from 'packages/iconic'

import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { Lockbox } from 'app/fieldapp/store/lockboxes/lockboxes.types'
import { SmartLock } from 'app/fieldapp/store/smartlocks/smartlocks.types'
import { Unit } from 'app/fieldapp/store/units/units.types'

import styles from '../../CleanDetail.module.scss'

const St = {
  SectionLabel: styled.span`
    font-weight: 600;
  `,

  SmartLock: styled.div`
    overflow-wrap: anywhere;
  `,
}

export enum AccessCodesTestIds {
  container = 'AccessCodes__container',
}

export type AccessCodesProps = {
  lockbox?: Lockbox
  /**
   * (Optional) SmartLock data for this Clean
   * Note that if a clean has both a smartLock and a regular lockbox, we should always prefer the smartLock
   */
  smartLock?: SmartLock
  smartLockAccessGranted: boolean
  unit: Unit
}

export const AccessCodes: React.FC<AccessCodesProps> = React.memo(
  ({ lockbox, smartLock, smartLockAccessGranted, unit }) => {
    const { t } = useI18n()

    const hasValidLockbox =
      lockbox && (lockbox.primaryLock || lockbox.backupLock)

    const isExternalCode = (smartLock?.accessCode || '').match(/http/)

    if (!smartLock && !lockbox) {
      return null
    }

    return (
      <>
        <div
          className={classNames(
            styles.cleanUnitAccess,
            styles.cleanUnitInfoContainer,
          )}
          data-testid={AccessCodesTestIds.container}
        >
          <SvgIcon
            className={styles.cleanDetailIcon}
            icon={IconName.lock}
            size={16}
          />

          <div className={styles.details}>
            <strong>{t(Slugs.access)}</strong>
            {!!unit.gateCode && (
              <div>
                <span className={styles.accessCodeLabel}>
                  {t(Slugs.gateCode)}
                </span>
                : {unit.gateCode}
              </div>
            )}

            {!!unit.alarmCode && (
              <div>
                <span className={styles.accessCodeLabel}>
                  {t(Slugs.alarmCode)}
                </span>
                : {unit.alarmCode}
              </div>
            )}

            {smartLock && (
              <St.SmartLock data-testid="smartLock-section">
                <St.SectionLabel>{t(Slugs.smartLock)}</St.SectionLabel>:{' '}
                {!smartLockAccessGranted && (
                  <div>{t(Slugs.accessNotGrantedYet)}</div>
                )}
                {smartLockAccessGranted && isExternalCode && (
                  <ExternalLink
                    text={smartLock.accessCode}
                    url={smartLock.accessCode}
                  />
                )}
                {smartLockAccessGranted &&
                  !isExternalCode &&
                  (smartLock.accessCode || '-')}
              </St.SmartLock>
            )}

            {!smartLock && lockbox && (
              <div data-testid="lockbox-section">
                {hasValidLockbox ? (
                  <>
                    <div>
                      <span className={styles.accessCodeLabel}>
                        {t(Slugs.lockbox)}
                      </span>
                      : {lockbox.primaryLock || '-'}
                    </div>
                    <div>
                      <span className={styles.accessCodeLabel}>
                        {t(Slugs.backup)}
                      </span>
                      : {lockbox.backupLock || '-'}
                    </div>
                    <div>
                      <span className={styles.accessCodeLabel}>
                        {t(Slugs.notes)}
                      </span>
                      : {lockbox.notes || '-'}
                    </div>
                  </>
                ) : (
                  <> - </>
                )}
              </div>
            )}
          </div>
        </div>
      </>
    )
  },
)
