import { NormalizedTasksApiResponse, TasksResponse } from './tasks.types'

/**********************************************************
 * FALLBACK DATA
 *********************************************************/

/**
 * Empty data in the shape of the Tasks service's `normalized` payload.
 * Use this as a fallback when there are issues with the API data being undefined or malformed.
 */
export const emptyNormalizedTasksData: TasksResponse = Object.freeze({
  assignment: {},
  housekeeper: {},
  lockBox: {},
  reservation: {},
  smartLock: {},
  task: {},
  taskPhoto: {},
  ticket: {},
  unit: {},
  user: {},
})

/**
 * Empty data to use as a "safety net" any time an API response is undefined for any reason
 */
export const emptyTasksResponse: NormalizedTasksApiResponse = Object.freeze({
  normalized: emptyNormalizedTasksData,
  raw: { data: [] },
})
