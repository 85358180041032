import { createAsyncAction } from 'typesafe-actions'

import {
  RequestConfig,
  RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import { ReservationApiFields } from 'app/fieldapp/store/reservations'

import { TicketApiFields } from '../../tickets'
import { unitsService } from '../units.service'
import {
  NormalizedUnitsApiResponse,
  UnitApiFields,
  UnitsActionTypes,
} from '../units.types'

export const fetchUnitByIdAction = createAsyncAction(
  UnitsActionTypes.FETCH_UNIT_BY_ID,
  UnitsActionTypes.FETCH_UNIT_BY_ID_SUCCESS,
  UnitsActionTypes.FETCH_UNIT_BY_ID_FAILURE,
)<
  RequestConfig<NormalizedUnitsApiResponse>,
  NormalizedUnitsApiResponse,
  Error
>()

export const getParams = (): RequestOptions => ({
  fields: {
    reservation: ReservationApiFields,
    ticket: TicketApiFields,
    unit: UnitApiFields,
    zone: [],
  },
  include: [
    'current_reservation',
    'next_reservation',
    'open_tickets',
    'open_tickets.assignee',
    'open_visits.assigned_housekeepers.user',
    'previous_reservation',
    'zone',
  ],
})

export const fetchUnitById = (unitId: string) => async dispatch => {
  try {
    const params = getParams()
    const request = unitsService.fetchUnitById.bind(null, unitId, params)
    const result = await dispatch(fetchUnitByIdAction.request({ request }))
    dispatch(fetchUnitByIdAction.success(result))
    return result.normalized
  } catch (error) {
    dispatch(fetchUnitByIdAction.failure(error))
    throw error
  }
}
