import { createAsyncAction } from 'typesafe-actions'

import {
  RequestConfig,
  RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import {
  NormalizedOtherTimersApiResponse,
  OtherTimeApiFields,
  OtherTimersActionTypes,
} from 'app/fieldapp/store/otherTimers'

import { otherTimersService } from '../otherTimers.service'

export const fetchOtherTimerByIdAction = createAsyncAction(
  OtherTimersActionTypes.FETCH_OTHER_TIMER_BY_ID,
  OtherTimersActionTypes.FETCH_OTHER_TIMER_BY_ID_SUCCESS,
  OtherTimersActionTypes.FETCH_OTHER_TIMER_BY_ID_FAILURE,
)<
  RequestConfig<NormalizedOtherTimersApiResponse>,
  NormalizedOtherTimersApiResponse,
  Error
>()

export const getParams = (): RequestOptions => ({
  fields: {
    task_time: OtherTimeApiFields,
    user: [],
  },
})

export const fetchOtherTimerById = (otherTimerId: string) => async dispatch => {
  try {
    const params = getParams()
    const request = otherTimersService.fetchOtherTimerById.bind(
      null,
      otherTimerId,
      params,
    )
    const result = await dispatch(
      fetchOtherTimerByIdAction.request({ request }),
    )
    dispatch(fetchOtherTimerByIdAction.success(result))
    return result.normalized
  } catch (error) {
    dispatch(fetchOtherTimerByIdAction.failure(error))
    throw error
  }
}
