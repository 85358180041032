import * as React from 'react'

import { NotificationBar } from 'packages/common'

import { Slugs, useI18n } from 'app/fieldapp/i18n'

import styles from './UpdateNotifier.module.scss'

export const UpdateNotifier = React.memo(() => {
  const { t } = useI18n()

  const onClick = () => {
    localStorage.clear()

    // trigger a 'skipWaiting' event to force the new SW to take over
    // Note that the ServiceWorker can sometimes a report a pending update that has already been
    // applied, which will result in "skipWaiting" not working, and thus a "stuck" notification.
    // In this case, we will simply manually refresh instead, just to make banner go away.
    navigator.serviceWorker?.getRegistration().then(registration => {
      const waitingSW = registration?.waiting

      waitingSW
        ? waitingSW.postMessage('skipWaiting')
        : window.location.reload()
    })
  }

  return (
    <NotificationBar
      className={styles.updateNotifier}
      onClick={onClick}
      type={'lake'}
    >
      {t(Slugs.updatesAvailable)}
    </NotificationBar>
  )
})
