import { createAsyncAction } from 'typesafe-actions'

import { OfflineError } from 'packages/offline'
import { apiDateStringWithSeconds } from 'packages/utils/dateHelpers'
import {
  ErrorWithParams,
  OfflineRequestConfig,
  RequestOptions,
} from 'packages/utils/store'

import {
  OtherTimePatchApiAttributes,
  OtherTimePatchData,
  OtherTimersActionTypes,
  NormalizedOtherTimersApiResponse,
} from 'app/fieldapp/store/otherTimers'
import { offlineTimers } from 'app/fieldapp/store/utils'

import { otherTimersService } from '../otherTimers.service'
import { fetchActiveOtherTimersForUser } from './fetchActiveOtherTimersForUser'

export const updateOtherTimerAction = createAsyncAction(
  OtherTimersActionTypes.UPDATE_OTHER_TIMER,
  OtherTimersActionTypes.UPDATE_OTHER_TIMER_SUCCESS,
  OtherTimersActionTypes.UPDATE_OTHER_TIMER_FAILURE,
)<
  OfflineRequestConfig<NormalizedOtherTimersApiResponse, OtherTimePatchData>,
  NormalizedOtherTimersApiResponse,
  ErrorWithParams<OtherTimePatchData>
>()

export const buildRequestData = (
  patchData: OtherTimePatchData,
): RequestOptions => {
  const { id, isFinalized, notes, startedAt, stoppedAt } = patchData

  const attributes: OtherTimePatchApiAttributes = {
    is_finalized: isFinalized,
    notes,
    started_at: startedAt ? apiDateStringWithSeconds(startedAt) : startedAt,
    stopped_at: stoppedAt ? apiDateStringWithSeconds(stoppedAt) : stoppedAt,
  }

  return {
    data: {
      attributes,
      id,
      type: 'task_time',
    },
  }
}

export const updateOtherTimer =
  (patchData: OtherTimePatchData) => async dispatch => {
    try {
      const otherTimerId = patchData.id
      const requestData = buildRequestData(patchData)

      const request = otherTimersService.updateOtherTimer.bind(
        null,
        otherTimerId,
        requestData,
      )

      const result = await dispatch(
        updateOtherTimerAction.request({
          data: patchData,
          request,
        }),
      )

      // remove any existing copies of this timer from local cache
      await offlineTimers.removeOtherTime(otherTimerId)

      dispatch(updateOtherTimerAction.success(result))

      // re-fetch all timers to ensure we update the PWA cache
      await dispatch(fetchActiveOtherTimersForUser())

      return result.normalized
    } catch (error) {
      /* No need to do anything if there is a middleware-handled offline error */
      if (error === OfflineError) return

      dispatch(updateOtherTimerAction.failure({ error, params: patchData }))
      throw error
    }
  }
