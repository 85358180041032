import { AxiosResponse } from 'axios'
import { snakeCase } from 'lodash/fp'

import {
  DayOfWeekBoolMap,
  daysOfWeek,
  JSONApiObject,
  JSONApiObjectMap,
  NormalizedJSONApiResponse,
} from 'packages/grimoire/src/utils'

/**********************************************************
 * REDUX TYPES
 *********************************************************/

export type StandardAvailabilityState = {
  data: JSONApiStandardAvailabilityMap
}

export enum StandardAvailabilityActionTypes {
  CREATE_STANDARD_AVAILABILITY = 'STANDARD_AVAILABILITY/API/CREATE_STANDARD_AVAILABILITY',
  CREATE_STANDARD_AVAILABILITY_FAILURE = 'STANDARD_AVAILABILITY/CREATE_STANDARD_AVAILABILITY_FAILURE',
  CREATE_STANDARD_AVAILABILITY_SUCCESS = 'STANDARD_AVAILABILITY/CREATE_STANDARD_AVAILABILITY_SUCCESS',

  DELETE_STANDARD_AVAILABILITY = 'STANDARD_AVAILABILITY/API/DELETE_STANDARD_AVAILABILITY',
  DELETE_STANDARD_AVAILABILITY_FAILURE = 'STANDARD_AVAILABILITY/DELETE_STANDARD_AVAILABILITY_FAILURE',
  DELETE_STANDARD_AVAILABILITY_SUCCESS = 'STANDARD_AVAILABILITY/DELETE_STANDARD_AVAILABILITY_SUCCESS',

  FETCH_STANDARD_AVAILABILITY = 'STANDARD_AVAILABILITY/API/FETCH_STANDARD_AVAILABILITY',
  FETCH_STANDARD_AVAILABILITY_FAILURE = 'STANDARD_AVAILABILITY/FETCH_STANDARD_AVAILABILITY_FAILURE',
  FETCH_STANDARD_AVAILABILITY_SUCCESS = 'STANDARD_AVAILABILITY/FETCH_STANDARD_AVAILABILITY_SUCCESS',

  UPDATE_STANDARD_AVAILABILITY = 'STANDARD_AVAILABILITY/API/UPDATE_STANDARD_AVAILABILITY',
  UPDATE_STANDARD_AVAILABILITY_FAILURE = 'STANDARD_AVAILABILITY/UPDATE_STANDARD_AVAILABILITY_FAILURE',
  UPDATE_STANDARD_AVAILABILITY_SUCCESS = 'STANDARD_AVAILABILITY/UPDATE_STANDARD_AVAILABILITY_SUCCESS',
}

/**********************************************************
 * SERVICE TYPES
 *********************************************************/

export type StandardAvailabilityResponse = {
  standardAvailability: JSONApiStandardAvailabilityMap
}

export type NormalizedStandardAvailabiltyApiResponse =
  NormalizedJSONApiResponse<StandardAvailabilityResponse>

export type StandardAvailabilityServiceResponse =
  Promise<NormalizedStandardAvailabiltyApiResponse>

export type StandardAvailabilityApiResponse =
  AxiosResponse<NormalizedStandardAvailabiltyApiResponse>

export type StandardAvailabilityPatchData = {
  standardAvailabiltiyId: string
  startDate?: string
} & DayOfWeekBoolMap

export type StandardAvailabilityPostData = {
  startDate?: string
} & DayOfWeekBoolMap

/**********************************************************
 * STANDARD AVAILABILITY
 *********************************************************/

export type StandardAvailability = {
  id: string
} & StandardAvailabiltyAttributes

export type StandardAvailabiltyAttributes = {
  startDate: string
  userId: string
} & DayOfWeekBoolMap

export type JSONApiStandardAvailabilityMap =
  JSONApiObjectMap<StandardAvailabiltyAttributes>

export const StandardAvailabilityAttributeNames = ['startDate', ...daysOfWeek]

export const StandardAvailabilityApiFields: string[] =
  StandardAvailabilityAttributeNames.map(snakeCase)

export type RawStandardAvailability =
  JSONApiObject<StandardAvailabiltyAttributes>
