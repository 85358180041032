import { useDatePickerModal } from 'packages/common/src/DatePicker/useDatePickerModal'
import { createDateObject, isAfter, isBefore } from 'packages/utils/dateHelpers'

import {
  defaultEndTime,
  defaultStartTime,
} from '../../utils/CrossCoverageDrawer.helpers'
import {
  CrossCoverageFormDispatch,
  CrossCoverageFormState,
} from '../state/CrossCoverageForm.reducer'

type UseCrossCoverageFormDatePickerModalsProps = {
  formDispatch: CrossCoverageFormDispatch
  state: CrossCoverageFormState
}

export type UseCrossCoverageFormDatePickerModals = {
  showEndDatePickerModal: () => void
  showStartDatePickerModal: () => void
}

export const useCrossCoverageFormDatePickerModals = ({
  formDispatch,
  state,
}: UseCrossCoverageFormDatePickerModalsProps): UseCrossCoverageFormDatePickerModals => {
  const { showDatePickerModal: showStartDatePickerModal } = useDatePickerModal({
    disableBeforeDate: createDateObject(),
    onDateChange: date => {
      if (!date) return

      const dateChangePayload = {
        startDate: date,
      }

      /** If changing the start date will impact the other date fields, these changes should be made */
      const validationChanges = {
        endDate: date,
        endTime: defaultEndTime(),
        startTime: defaultStartTime(date),
      }

      formDispatch({
        payload: {
          ...dateChangePayload,
          ...(isAfter(date, state.endDate) ? validationChanges : {}),
        },
        type: 'setDatesAndTimes',
      })
    },
    selectedDate: state.startDate,
  })

  const { showDatePickerModal: showEndDatePickerModal } = useDatePickerModal({
    disableBeforeDate: state.startDate,
    onDateChange: (date: Date | undefined) => {
      if (!date) return
      const dateChangePayload = {
        endDate: date,
      }

      /** If changing the end date will impact the other date fields, these changes should be made */
      const validationChanges = {
        endTime: defaultEndTime(),
        startDate: date,
        startTime: defaultStartTime(state.startDate),
      }

      formDispatch({
        payload: {
          ...dateChangePayload,
          ...(isBefore(date, state.startDate) ? validationChanges : {}),
        },
        type: 'setDatesAndTimes',
      })
    },
    selectedDate: state.endDate,
  })

  return {
    showEndDatePickerModal,
    showStartDatePickerModal,
  }
}
