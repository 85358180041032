import React from 'react'

type EventWrapper = (
  callback: () => unknown,
) => (event: React.MouseEvent<unknown>) => unknown

type UseWrappedEvent = {
  preventEvent: EventWrapper
  stopAndPreventEvent: EventWrapper
  stopEvent: EventWrapper
}

export const useWrappedEvent = (): UseWrappedEvent => {
  const preventEvent = React.useCallback(
    (callback: () => unknown) => (event: React.MouseEvent<unknown>) => {
      event.preventDefault()
      callback()
    },
    [],
  )

  const stopEvent = React.useCallback(
    (callback: () => unknown) => (event: React.MouseEvent<unknown>) => {
      event.stopPropagation()
      callback()
    },
    [],
  )

  const stopAndPreventEvent = React.useCallback(
    (callback: () => unknown) => (event: React.MouseEvent<unknown>) => {
      event.stopPropagation()
      event.preventDefault()
      callback()
    },
    [],
  )

  return { preventEvent, stopAndPreventEvent, stopEvent }
}
