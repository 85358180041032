import { createSelector } from 'reselect'

import { ApplicationState } from '../../store'
import { hydrateRawUser, User } from '../../users'

export const getUserById = createSelector(
  (state: ApplicationState) => state.users,
  (_, userId: string) => userId,
  (usersState, userId): User | undefined => {
    const rawUser = usersState.data[userId]
    return rawUser ? hydrateRawUser(rawUser) : undefined
  },
)
