import { taskIsClean, TaskJobType } from 'packages/grimoire'
import {
  findAllRelationshipIds,
  findRelationshipId,
  transformNormalizedToTyped,
} from 'packages/utils/store'

import { getAssignmentById } from '../assignments/selectors'
import { getReservationById } from '../reservations/selectors'
import { ApplicationState } from '../store'
import { getTaskPhotosByIds } from '../taskPhotos/selectors'
import { legacyGetUnitById } from '../units/selectors'
import { Clean, CleanAttributeNames, RawClean } from './cleans.types'

export const hydrateRawClean =
  (state: ApplicationState) =>
  (rawClean: RawClean): Clean => {
    const assignments = rawClean.relationships.activeAssignments.data.map(ass =>
      getAssignmentById(state, ass.id),
    )

    // populate reservation (required)
    const reservationId = findRelationshipId(rawClean, 'reservation')
    if (!reservationId) {
      throw Error(
        `A reservation relationship could not be found with ID: ${reservationId}`,
      )
    }

    const reservation = getReservationById(state, reservationId)
    if (!reservation) {
      throw Error(`A reservation could not be found with ID: ${reservationId}`)
    }

    // populate unit (required)
    const unitId = findRelationshipId(rawClean, 'unit')
    if (!unitId) {
      throw Error(`A unit could not be found with ID: ${unitId}`)
    }

    const unit = legacyGetUnitById(state, unitId)

    // populate taskPhotos (optional)
    const photoIds = findAllRelationshipIds(rawClean, 'taskPhotos')
    const taskPhotos = getTaskPhotosByIds(state.taskPhotos, photoIds)

    const clean = transformNormalizedToTyped<Clean>(
      rawClean,
      CleanAttributeNames,
    )

    return {
      ...clean,
      assignments,
      reservation,
      taskPhotos,
      unit,
    }
  }

export const isRawClean = (task: {
  attributes: { jobType: TaskJobType[] }
}): task is RawClean => {
  return taskIsClean(task.attributes)
}
