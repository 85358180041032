import { createSlice } from '@reduxjs/toolkit'

import { ApplicationState } from '../store'
import { fanOutDataBatchRequests } from './actions/fanOutDataBatchRequests'

const appSlice = createSlice({
  extraReducers: builder => {
    builder
      .addCase(fanOutDataBatchRequests.pending, state => {
        state.fanOutDataBatchRequests = 'pending'
      })
      .addCase(fanOutDataBatchRequests.fulfilled, state => {
        state.fanOutDataBatchRequests = 'fulfilled'
      })
      .addCase(fanOutDataBatchRequests.rejected, state => {
        state.fanOutDataBatchRequests = 'rejected'
      })
  },
  initialState: {
    fanOutDataBatchRequests: 'pending',
  },
  name: 'app',
  reducers: {},
})

export const getFanOutBatchRequests = (state: ApplicationState): string =>
  state.app.fanOutDataBatchRequests

export default appSlice.reducer
