import styled from '@emotion/styled'
import React from 'react'
import { useAsyncFn } from 'react-use'

import { Button } from 'packages/common'
import { IconName, SvgIcon } from 'packages/iconic'
import { useOnlineStatus, useShareApi } from 'packages/utils/hooks'
import { Events, track } from 'packages/wiretap'

import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { Clean } from 'app/fieldapp/store/cleans'
import { useAppDispatch } from 'app/fieldapp/store/hooks'
import { setBanner } from 'app/fieldapp/store/ui/actions'
import { useActiveUser } from 'app/fieldapp/utils/hooks/useActiveUser'

import { fetchDelegateToken } from './ShareCleanButton.helpers'

export const CLIPBOARD_RESET_TIMEOUT = 1500

enum CopyState {
  Idle,
  Copied,
  Failed,
}

const St = {
  Icon: styled(SvgIcon)`
    margin-right: 4px;
    padding-bottom: 2px;
  `,
}

const slugsByCopyState: Record<CopyState, string> = {
  [CopyState.Idle]: Slugs.copyLink,
  [CopyState.Copied]: Slugs.copied,
  [CopyState.Failed]: Slugs.copyFailed,
}

const useTranslations = (copyState: CopyState) => {
  const { ut } = useI18n()

  return {
    buttonText: ut(Slugs.shareCleanButton),
    copyLinkText: ut(slugsByCopyState[copyState]),
    shareText: ut(Slugs.shareCleanBody),
    tokenError: ut(Slugs.errorTryAgainLater),
  }
}

export type ShareCleanButtonProps = {
  clean: Clean
  idToken: string
}

export const ShareCleanButton: React.FC<ShareCleanButtonProps> = React.memo(
  ({ clean, idToken }) => {
    const { share, canShare } = useShareApi()
    const { isOnline } = useOnlineStatus()
    const dispatch = useAppDispatch()
    const { user } = useActiveUser()

    const [copyState, setCopyState] = React.useState<CopyState>(CopyState.Idle)
    const strings = useTranslations(copyState)

    const handleCopyLink = React.useCallback(async text => {
      try {
        await navigator.clipboard.writeText(text)
        setCopyState(CopyState.Copied)
        setTimeout(() => setCopyState(CopyState.Idle), CLIPBOARD_RESET_TIMEOUT)
      } catch (err) {
        setCopyState(CopyState.Failed)
      }
    }, [])

    const trackShareClean = React.useCallback(() => {
      /* eslint-disable @typescript-eslint/naming-convention */
      track(Events.fieldAppShareClean, {
        clean_id: clean.id,
        user_id: user?.id || 'unknown',
      })
      /* eslint-enable @typescript-eslint/naming-convention */
    }, [clean.id, user?.id])

    const [fetchTokenState, fetchToken] = useAsyncFn(async () => {
      trackShareClean()
      try {
        const resourcePath = `cleans/${clean.id}`
        const delegateToken = await fetchDelegateToken({
          idToken,
          resourcePath,
        })

        // due to URL parsing issue, we need to split the token "pieces" into query params
        // this is because attempting to include the full token (with periods) will cause apps/SMS not to read the URL correctly
        const [header, body, sig] = delegateToken.split('.')
        const tokenParams = `h=${header}&b=${body}&s=${sig}`
        const delegateUrl = `shared/cleans?selectedClean=${clean.id}&${tokenParams}`

        const fullUrl = `${window.location.origin}/${delegateUrl}`

        // NOTE: be very careful if tweaking the formatting/layout here, as it will affect the final "share" text
        const text = `
${strings.shareText}

${clean.unit.address}

${fullUrl}`

        if (canShare) share({ text })
        else handleCopyLink(text)
      } catch (err) {
        dispatch(setBanner({ message: strings.tokenError }))
      }
    }, [
      clean.id,
      dispatch,
      idToken,
      setBanner,
      share,
      strings.shareText,
      strings.tokenError,
      trackShareClean,
    ])

    return (
      <Button
        disabled={!isOnline()}
        isLoading={fetchTokenState.loading}
        onClick={fetchToken}
        buttonType={'secondary'}
      >
        {canShare ? (
          <>
            <St.Icon icon={IconName.link2} size={24} />
            {strings.buttonText}
          </>
        ) : (
          <>
            <St.Icon icon={IconName.copy} size={22} />
            {strings.copyLinkText}
          </>
        )}
      </Button>
    )
  },
)
