import styled from '@emotion/styled'
import React from 'react'

import { Button } from 'packages/common'
import { useI18n } from 'packages/i18n'
import { colors } from 'packages/styles'
import { useOnlineStatus } from 'packages/utils/hooks'

import { UnitInfoContainer } from 'app/fieldapp/components/schedule/components/TaskSharedStyles'
import { Slugs } from 'app/fieldapp/i18n'

import { VisitDeletionStatus } from './RemoveVisit.container'

const St = {
  CanNotRemoveMessage: styled.span`
    color: ${colors.dusk60};
  `,
  UnitInfoContainer: styled(UnitInfoContainer)`
    justify-content: center;
  `,
}

const useTranslations = (visitStatus: VisitDeletionStatus) => {
  const { t } = useI18n()

  const getCannotDeleteMessage = () => {
    if (visitStatus === 'completed') return t(Slugs.deleteCompletedVisitMessage)
    if (visitStatus === 'inProgress') return t(Slugs.deleteStartedVisitMessage)
    if (visitStatus === 'system') return t(Slugs.deleteSystemVisitMessage)
    return '' // just to make TS "correct"
  }

  return {
    cannotDeleteMessage: getCannotDeleteMessage(),
    removeVisit: t(Slugs.removeVisit),
  }
}

const showButtonStatuses: VisitDeletionStatus[] = [
  'notStarted',
  'isLoadingAnyVisit',
]

const hasButtonStatus = (status: VisitDeletionStatus) =>
  showButtonStatuses.includes(status)

export type RemoveVisitProps = {
  onDelete: () => void
  visitStatus: VisitDeletionStatus
}

export const RemoveVisit: React.FC<RemoveVisitProps> = React.memo(
  ({ onDelete, visitStatus }) => {
    const { cannotDeleteMessage, removeVisit } = useTranslations(visitStatus)

    const isOnline = useOnlineStatus().isOnline()

    return (
      <St.UnitInfoContainer>
        {hasButtonStatus(visitStatus) ? (
          <Button
            block={true}
            buttonType="danger"
            disabled={!isOnline}
            isLoading={visitStatus === 'isLoadingAnyVisit'}
            onClick={onDelete}
          >
            {removeVisit}
          </Button>
        ) : (
          <St.CanNotRemoveMessage>{cannotDeleteMessage}</St.CanNotRemoveMessage>
        )}
      </St.UnitInfoContainer>
    )
  },
)
