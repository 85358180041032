import styled from '@emotion/styled'
import { noop } from 'lodash/fp'
import React from 'react'

import {
  BannerTestIds,
  DrawerContainerProps,
  LegacyModal2TestIds,
  LIGHTBOX_CLASS_SELECTOR,
} from 'packages/common'
import { Drawer } from 'packages/common'

import { CREATE_TICKET_DRAWER_ID } from 'app/fieldapp/components/schedule/components/common'
import { TEST_ID_TIMERS } from 'app/fieldapp/components/timers'

import { REVIEWS_DRAWER_ID } from '../../Reviews/ReviewDrawer/ReviewDrawer'
import { VISIT_TICKET_DRAWER_ID } from '../../VisitTicketDrawer/VisitTicketDrawer'
import { VisitDetailProps } from '../VisitDetail'
import { VisitDetailWrapper } from '../VisitDetailWrapper'
import { VisitDetailDrawerId } from './VisitDetailDrawer.utils'

const St = {
  Drawer: styled(Drawer)`
    width: min(420px, 100vw);

    & > button:first-of-type {
      // ensure the absolute-position button stays on top of relative-positioned tab content
      z-index: 2;
    }
  `,
}

const ignoreClickEventsOn: DrawerContainerProps['ignoreClickEventsOn'] = [
  `#${TEST_ID_TIMERS}`,
  LIGHTBOX_CLASS_SELECTOR,
  `#${BannerTestIds.container}`,
  `#${CREATE_TICKET_DRAWER_ID}`,
  `#${CREATE_TICKET_DRAWER_ID}__backdrop`,
  `#${VISIT_TICKET_DRAWER_ID}`,
  `#${REVIEWS_DRAWER_ID}`,
  `#${LegacyModal2TestIds.container}`,
]

type PickedDrawerProps = Pick<
  DrawerContainerProps,
  'afterExit' | 'afterOpen' | 'isOpen'
>

type PickedDetailProps = Omit<VisitDetailProps, 'onCloseDrawerClick'>

export type VisitDetailDrawerProps = PickedDrawerProps &
  PickedDetailProps & {
    isLoadingVisit: boolean
  }

export const VisitDetailDrawer: React.FC<VisitDetailDrawerProps> = React.memo(
  ({ afterExit = noop, afterOpen = noop, isLoadingVisit, isOpen }) => {
    const handleAfterExit = React.useCallback(() => {
      setIsDrawerForceClosed(false)
      afterExit()
    }, [afterExit])

    const [isDrawerForceClosed, setIsDrawerForceClosed] = React.useState(false)
    const forceCloseDrawer = () => setIsDrawerForceClosed(true)

    return (
      <St.Drawer
        afterExit={handleAfterExit}
        afterOpen={afterOpen}
        forceClose={isDrawerForceClosed}
        hideCloseButton={true}
        id={VisitDetailDrawerId}
        ignoreClickEventsOn={ignoreClickEventsOn}
        isOpen={isOpen}
        data-testid="visit-detail-drawer"
      >
        <VisitDetailWrapper
          isLoadingVisit={isLoadingVisit}
          onCloseDrawerClick={forceCloseDrawer}
        />
      </St.Drawer>
    )
  },
)
