import { ContactAttributeNames } from 'packages/grimoire/src/contact/contact.types'
import { transformNormalizedToTyped } from 'packages/utils/store'

import {
  Contact,
  ContactResponse,
  NormalizedContactsApiResponse,
  RawContact,
} from './contacts.types'

/**
 * Empty data in the shape of the Contact service's `normalized` payload.
 * Use this as a fallback when there are issues with the API data being undefined or malformed.
 */
export const emptyNormalizedContactData = Object.freeze<ContactResponse>({
  contact: {},
})

export const emptyContactResponse: NormalizedContactsApiResponse =
  Object.freeze({
    normalized: emptyNormalizedContactData,
    raw: { data: [] },
  })

export const hydrateRawContact = (rawContact: RawContact): Contact =>
  transformNormalizedToTyped<Contact>(rawContact, ContactAttributeNames)
