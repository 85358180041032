import { useSelector } from 'react-redux'

import { getReservationById } from 'app/fieldapp/store/reservations/selectors'
import { ApplicationState } from 'app/fieldapp/store/store'
import { getUnitById } from 'app/fieldapp/store/units/selectors'
import { getUserByHkId } from 'app/fieldapp/store/users/selectors'
import { getVisitsByIds } from 'app/fieldapp/store/visits/selectors'
import { useActiveUser } from 'app/fieldapp/utils/hooks/useActiveUser'

import { CreateVisitWarningsProps } from '../CreateVisitWarnings/CreateVisitWarnings'

type UseUnitVisits = CreateVisitWarningsProps

const EMPTY_ARRAY = []

export const useUnitVisits = (unitId: string): UseUnitVisits => {
  const { housekeeperId = '' } = useActiveUser()

  const unit = useSelector((state: ApplicationState) =>
    getUnitById(state, unitId),
  )

  const openVisitsForUnit = useSelector((state: ApplicationState) =>
    getVisitsByIds(state, unit?.openVisitIds || EMPTY_ARRAY),
  )

  const currentReservation = useSelector((state: ApplicationState) =>
    getReservationById(state, unit?.currentResId || ''),
  )

  //-----------------------------------------------------------
  // Find open visits for this unit assigned to other HK/Users
  //-----------------------------------------------------------
  const otherVisit = openVisitsForUnit.find(
    visit => !visit.assignedHkIds.includes(housekeeperId),
  )

  const otherVisitHkId =
    otherVisit && otherVisit.assignedHkIds.find(id => id !== housekeeperId)

  const otherVisitUser = useSelector((state: ApplicationState) =>
    getUserByHkId(state, otherVisitHkId || ''),
  )

  //----------------------------------------------------------------
  // Find open visits for this unit assigned to the current HK/User
  //----------------------------------------------------------------
  const userHasExistingVisit = !!openVisitsForUnit.find(visit =>
    visit.assignedHkIds.includes(housekeeperId),
  )

  return {
    currentReservation,
    otherVisitUser,
    userHasExistingVisit,
  }
}
