import React from 'react'
import { useSelector } from 'react-redux'

import { createDateObject } from 'packages/utils/dateHelpers'
import { useOnlineStatus } from 'packages/utils/hooks'

import {
  useOfflineMergedTimers,
  useTicketTimerActions,
} from 'app/fieldapp/components/timers/hooks'
import { useHasActiveTimer } from 'app/fieldapp/components/timers/hooks/useHasActiveTimer'
import { useTimers as useTimersContext } from 'app/fieldapp/components/timers/state/timers.context'
import { Ticket, TicketStatus } from 'app/fieldapp/store/tickets'
import { getActiveUser } from 'app/fieldapp/store/users/selectors'

export type UseTicketDetails = {
  handleStartTimer: () => Promise<void>
  isTimerDisabled: boolean
  isTimerLoading: boolean
}

export function useTicketDetails(ticket: Ticket | undefined): UseTicketDetails {
  const activeUser = useSelector(getActiveUser)

  const isOnline = useOnlineStatus().isOnline()
  const { state: timersState } = useTimersContext()
  const { loadingTimerId, submissionData } = timersState
  const { dispatchStartTicketTimer } = useTicketTimerActions()
  const { offlineTimersCount } = useOfflineMergedTimers()

  const handleStartTimer = React.useCallback(async () => {
    if (!ticket?.id) return

    await dispatchStartTicketTimer({
      startedAt: createDateObject(),
      ticketId: ticket.id,
      userId: activeUser?.id || '',
    })
  }, [activeUser?.id, dispatchStartTicketTimer, ticket?.id])

  const hasActiveTimer = useHasActiveTimer()
  const ticketIsAccepted = ticket?.status === TicketStatus.ACCEPTED
  const isTimerDisabled =
    hasActiveTimer ||
    !ticketIsAccepted ||
    !!submissionData ||
    (offlineTimersCount > 0 && isOnline)

  return {
    handleStartTimer,
    isTimerDisabled,
    isTimerLoading: !!loadingTimerId,
  }
}
