import { createSelector } from 'reselect'

import { RawUser } from 'packages/grimoire'

import { ApplicationState } from 'app/fieldapp/store/store'
import { hydrateRawUser, User } from 'app/fieldapp/store/users'

const getCurrentUser = (state: ApplicationState): RawUser | undefined =>
  state.users.currentCoveragePartner

export const getCurrentCoveragePartner = createSelector(
  getCurrentUser,
  (user): User | undefined => {
    if (!user) return undefined
    return hydrateRawUser(user)
  },
)
