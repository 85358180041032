import styled from '@emotion/styled'
import React, { useState } from 'react'

import { Pill } from 'packages/common'
import { ReviewSource } from 'packages/grimoire/src/review'
import { IconName, SvgIcon } from 'packages/iconic'
import { colors } from 'packages/styles'

import { ReviewFilters } from 'app/fieldapp/components/schedule/components/Reviews/ReviewFilters'
import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { Review } from 'app/fieldapp/store/reviews/reviews.types'

const St = {
  ChosenFiltersAndPill: styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    gap: 8px;
  `,
  FilterButtonIcon: styled(SvgIcon)`
    transform: rotate(90deg);
  `,
  FilterButtonText: styled.span`
    font-weight: 600;
    margin-left: 8px;
  `,
  FiltersPill: styled(Pill)`
    background: white;
    border: ${colors.midnight} 1px solid;
    color: ${colors.midnight};
    padding: 8px 16px;
    margin-left: auto;
  `,
  ReviewFiltersContainer: styled.div`
    align-items: stretch;
    display: flex;
    text-align: right;
    flex-direction: column;
    margin-bottom: 30px;
    gap: 20px;
  `,
}

const useTranslations = () => {
  const { t } = useI18n()

  return {
    channels: t(Slugs.channels),
    ratings: t(Slugs.ratings),
    reviewFiltersButton: t(Slugs.reviewFiltersButton),
    reviewFiltersByChannelTitle: t(Slugs.reviewFiltersByChannelTitle),
    reviewFiltersByRatingTitle: t(Slugs.reviewFiltersByRatingTitle),
    reviewNoAttached: t(Slugs.reviewNoAttached),
    reviewTitle: t(Slugs.reviewTitle),
    visit: t(Slugs.visit),
  }
}

export type ReviewFiltersContainerProps = {
  reviews: Review[]
  setFilteredReviews: (reviews: Review[]) => void
}

export const ReviewFiltersContainer: React.FC<ReviewFiltersContainerProps> =
  React.memo(({ reviews, setFilteredReviews }) => {
    const strings = useTranslations()

    const [isExpanded, setIsExpanded] = useState(false)
    const [isRatingsFilterChosen, setIsRatingsFilterChosen] = useState(false)
    const [isChannelsFilterChosen, setIsChannelsFilterChosen] = useState(false)
    const [checkedRatings, setCheckedRatings] = useState<number[]>([])
    const [checkedChannels, setCheckedChannels] = useState<string[]>([])

    const handleApplyFilters = (
      selectedRatings: number[],
      selectedChannels: string[],
    ) => {
      const channelMapping: { [key: string]: ReviewSource } = {
        Airbnb: ReviewSource.AIRBNB,
        Booking: ReviewSource.BOOKING,
        Vacasa: ReviewSource.VACASA,
        Vrbo: ReviewSource.VRBO,
      }

      const ratingRanges: { [key: number]: [number, number] } = {
        1: [0, 1.9],
        2: [2, 2.9],
        3: [3, 3.9],
        4: [4, 4.9],
        5: [5, 5.1],
      }

      const newFilteredReviews = reviews.filter(review => {
        const ratingMatches =
          selectedRatings.length === 0 ||
          selectedRatings.some(rating => {
            const [min, max] = ratingRanges[rating]
            return review.overallRating >= min && review.overallRating <= max
          })

        const channelMatches =
          selectedChannels.length === 0 ||
          selectedChannels.includes(
            Object.keys(channelMapping).find(
              key => channelMapping[key] === review.reviewSource,
            ) ?? '',
          )

        return ratingMatches && channelMatches
      })

      setIsRatingsFilterChosen(selectedRatings.length > 0)
      setIsChannelsFilterChosen(selectedChannels.length > 0)
      setCheckedRatings(selectedRatings)
      setCheckedChannels(selectedChannels)
      setFilteredReviews(newFilteredReviews)
      setIsExpanded(false)
    }

    return (
      <St.ReviewFiltersContainer>
        <St.ChosenFiltersAndPill>
          {isRatingsFilterChosen && (
            <Pill
              size="small"
              badgeCount={checkedRatings.length}
              variant="secondary"
              onClose={() => handleApplyFilters([], checkedChannels)}
              onClick={() => setIsExpanded(!isExpanded)}
            >
              {strings.ratings}
            </Pill>
          )}
          {isChannelsFilterChosen && (
            <Pill
              size="small"
              badgeCount={checkedChannels.length}
              variant="secondary"
              onClose={() => handleApplyFilters(checkedRatings, [])}
              onClick={() => setIsExpanded(!isExpanded)}
            >
              {strings.channels}
            </Pill>
          )}
          <St.FiltersPill onClick={() => setIsExpanded(!isExpanded)}>
            <St.FilterButtonIcon icon={IconName.sliders} size={14} />
            <St.FilterButtonText>
              {strings.reviewFiltersButton}
            </St.FilterButtonText>
          </St.FiltersPill>
        </St.ChosenFiltersAndPill>

        {isExpanded && (
          <ReviewFilters
            onApplyFilters={handleApplyFilters}
            checkedRatings={checkedRatings}
            checkedChannels={checkedChannels}
          />
        )}
      </St.ReviewFiltersContainer>
    )
  })
