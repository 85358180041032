import styled from '@emotion/styled'
import React from 'react'

import { ExternalLink } from 'packages/common'
import { OrderedBathAmenities, OrderedBedAmenities } from 'packages/grimoire'
import { useI18n } from 'packages/i18n'
import { IconName } from 'packages/iconic'
import { getVacasaViewUnitURL } from 'packages/utils/links'

import { composeOrderedAmenityString } from 'app/fieldapp/components/schedule/components/CleanDetail/CleanDetail.helpers'
import {
  InfoRowLabel,
  Details,
  DetailIcon,
  SectionSubTitle,
  UnitInfoContainer,
} from 'app/fieldapp/components/schedule/components/TaskSharedStyles'
import { Slugs } from 'app/fieldapp/i18n'
import { useAppSelector } from 'app/fieldapp/store/hooks'
import { ApplicationState } from 'app/fieldapp/store/store'
import { getVisitDrawerState } from 'app/fieldapp/store/ui/selectors'
import { getUnitById } from 'app/fieldapp/store/units/selectors'
import { getVisitById } from 'app/fieldapp/store/visits/selectors'

import { VisitPriorityScoreDetail } from './VisitPriorityScoreDetail/VisitPriorityScoreDetail'

const St = {
  DetailIcon,
  Details,
  InfoRowLabel,
  SectionSubTitle,
  UnitInfoContainer,
  VPSContainer: styled.div`
    align-items: center;
    background: #f6f6f6;
    padding: 10px 16px;
  `,
}

export enum VisitHomeInfoTestIds {
  vpsContainer = 'VisitHomeInfoProps__vpsContainer',
}

export const VisitHomeInfo: React.FC = React.memo(() => {
  const { t, tp } = useI18n()
  const { visitId } = useAppSelector(getVisitDrawerState)
  const visit = useAppSelector((state: ApplicationState) =>
    getVisitById(state, visitId),
  )
  const unit = useAppSelector((state: ApplicationState) =>
    getUnitById(state, visit?.unitId),
  )

  if (!visit || !unit) return null

  const { amenities, bathrooms, beds, id } = unit

  const bedTypesString = composeOrderedAmenityString(
    OrderedBedAmenities,
    amenities,
    tp,
  )
  const bathTypesString = composeOrderedAmenityString(
    OrderedBathAmenities,
    amenities,
    tp,
  )

  return (
    <St.UnitInfoContainer>
      <St.DetailIcon icon={IconName.home} size={16} />
      <St.Details>
        <St.SectionSubTitle>{t(Slugs.homeInfo)}</St.SectionSubTitle>

        <div>
          <St.InfoRowLabel>{tp(Slugs.bed, beds)}</St.InfoRowLabel>
          {bedTypesString && ` (${bedTypesString})`}
        </div>

        <div>
          <St.InfoRowLabel>{tp(Slugs.bath, bathrooms)}</St.InfoRowLabel>
          {bathTypesString && ` (${bathTypesString})`}
        </div>

        <div>
          <St.InfoRowLabel>{t(Slugs.maxOccupancy)}</St.InfoRowLabel>
          {` ${unit?.maxOccupancy}`}
        </div>

        <ExternalLink text={t(Slugs.viewHome)} url={getVacasaViewUnitURL(id)} />

        {!!visit?.metadata?.priorityScore && (
          <St.VPSContainer data-testid={VisitHomeInfoTestIds.vpsContainer}>
            <VisitPriorityScoreDetail visitId={visit.id} />
          </St.VPSContainer>
        )}
      </St.Details>
    </St.UnitInfoContainer>
  )
})
