import { createSelector } from 'reselect'

import { findRelationshipId } from 'packages/utils/store'

import { ApplicationState } from 'app/fieldapp/store/store'
import { hydrateRawUser, User } from 'app/fieldapp/store/users'

export const getUsersByHkIds = createSelector(
  (state: ApplicationState) => state,
  (_, hkIds: string[]) => hkIds,
  (state, hkIds): User[] =>
    hkIds.reduce((acc, hkId) => {
      const hk = state.housekeepers.data[hkId]
      const userId = findRelationshipId(hk, 'user') || ''
      const rawUser = state.users.data[userId]

      if (rawUser) {
        acc.push(hydrateRawUser(rawUser))
      }

      return acc
    }, [] as User[]),
)
