import styled from '@emotion/styled'
import { noop } from 'lodash/fp'
import React from 'react'

import { SvgIcon, IconName } from 'packages/iconic'
import { colors } from 'packages/styles/jss'

import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { useAppDispatch } from 'app/fieldapp/store/hooks'
import { setBanner } from 'app/fieldapp/store/ui/actions'

const StyledSvgIcon = styled(SvgIcon)`
  color: ${colors.lake};

  & > span {
    padding-top: 5px; // just a minor vertical alignment fix for the text
  }
`

export type NativeShareProps = {
  onClick?: () => void
  text: string
  title: string
}

export const NativeShare: React.FC<NativeShareProps> = ({
  onClick = noop,
  text,
  title,
}) => {
  const dispatch = useAppDispatch()
  const { t } = useI18n()

  const handleShareError = React.useCallback(() => {
    dispatch(setBanner({ message: t(Slugs.anErrorOccurred), timeout: 5000 }))
  }, [dispatch, t])

  const handleOpenShareMenu = React.useCallback(async () => {
    try {
      onClick()
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      await (navigator as any).share({ text, title })
    } catch (e) {
      // "AbortErrors" occur when the "share" menu is open, but the user clicks away which is not an error we'd care about
      if (e.name !== 'AbortError') {
        handleShareError()
      }
    }
  }, [handleShareError, onClick, text, title])

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  return (navigator as any).share ? (
    <StyledSvgIcon
      centerItems={true}
      icon={IconName.share}
      onClick={handleOpenShareMenu}
      size={20}
      text={'Share'}
      textFontSize={14}
    />
  ) : null
}
