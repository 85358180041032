import * as React from 'react'
import AsyncSelect from 'react-select/async'

import { User } from 'app/fieldapp/store/users/users.types'

import styles from './CoveragePartnerSelector.module.scss'

type CoveragePartnerSelectorProps = {
  disabled?: boolean
  label?: string
  loadOptions: (inputValue, callback) => void
  onUserSelectionChange: (value: User) => void
  placeholder: string
  selectedUser?: User
}

const CoveragePartnerSelector: React.FC<CoveragePartnerSelectorProps> = ({
  onUserSelectionChange,
  selectedUser,
  placeholder,
  disabled,
  label,
  loadOptions,
}) => {
  const handleChange = (selectedOption: User | null) => {
    if (selectedOption) {
      onUserSelectionChange(selectedOption)
    }
  }

  return (
    <AsyncSelect
      aria-label={label}
      classNamePrefix="coveragePartnerSelector"
      className={styles.coveragePartnerSelector}
      defaultOptions={selectedUser ? [selectedUser] : []}
      loadOptions={loadOptions}
      onChange={handleChange}
      getOptionLabel={(user: User) => `${user.firstName} ${user.lastName}`}
      getOptionValue={(user: User) => user.id}
      value={selectedUser}
      isDisabled={!!disabled}
      placeholder={placeholder}
      noOptionsMessage={({ inputValue }) =>
        inputValue === '' ? 'Type to search' : 'No results'
      }
    />
  )
}

export default CoveragePartnerSelector
