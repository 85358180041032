import { produce } from 'immer'

import { transformNormalizedToTyped } from 'packages/utils/store'

import {
  OtherTimer,
  OtherTimerAttributeNames,
  NormalizedOtherTimersApiResponse,
  RawOtherTime,
} from './otherTimers.types'

/**
 * Empty data in the shape of the OtherTimers service's `normalized` payload.
 * Use this as a fallback when there are issues with the API data being undefined or malformed.
 */
export const emptyNormalizedOtherTimerData = Object.freeze({
  // TODO: rename to other timer when API ready
  taskTime: {},
})

/**
 * Empty data to use as a "safety net" any time an API response is undefined for any reason
 */
export const emptyOtherTimerResponse: NormalizedOtherTimersApiResponse =
  Object.freeze({
    normalized: emptyNormalizedOtherTimerData,
    raw: { data: [] },
  })

type OtherTimerWithAPIAttrs = Omit<OtherTimer, 'subTimerType' | 'timerType'> & {
  subTaskType: OtherTimer['subTimerType']
  taskType: OtherTimer['timerType']
}

export const hydrateRawOtherTime = (rawOtherTime: RawOtherTime): OtherTimer => {
  const withAPIAttrs = transformNormalizedToTyped<OtherTimerWithAPIAttrs>(
    rawOtherTime,
    OtherTimerAttributeNames,
  )

  /* eslint-disable @typescript-eslint/no-explicit-any */
  const timer: OtherTimer = produce(withAPIAttrs, (draft: any) => {
    delete draft.subTaskType
    delete draft.taskType

    draft.timerType = withAPIAttrs.taskType
    draft.subTimerType = withAPIAttrs.subTaskType
    return
  }) as any
  /* eslint-enable @typescript-eslint/no-explicit-any */

  return timer
}
