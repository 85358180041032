import React from 'react'

import { CrossCoverageFormContainerProps } from '../../../../utils/prop.types'
import { EditCrossCoverageFormContainer } from './EditCrossCoverageControls.container'
import { NewCrossCoverageFormControlsContainer } from './NewCrossCoverageControls.container'

export const CrossCoverageFormControlsContainer: React.FC<
  CrossCoverageFormContainerProps
> = props => {
  if (props.formState.crossCoverageId) {
    return <EditCrossCoverageFormContainer {...props} />
  }

  return <NewCrossCoverageFormControlsContainer {...props} />
}
