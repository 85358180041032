import {
  findRelationshipId,
  transformNormalizedToTyped,
} from 'packages/utils/store'

import { getHkById } from '../housekeepers/selectors'
import { ApplicationState } from '../store'
import {
  Assignment,
  AssignmentAttributeNames,
  NormalizedAssignmentsApiResponse,
  RawAssignment,
} from './assignments.types'

export const emptyNormalizedAssignmentsData = Object.freeze({
  assignment: {},
})

export const emptyAssignmentsResponse: NormalizedAssignmentsApiResponse =
  Object.freeze({
    normalized: emptyNormalizedAssignmentsData,
    raw: { data: [] },
  })

export const hydrateRawAssignment =
  (state: ApplicationState) =>
  (rawAssignment: RawAssignment): Assignment => {
    const assignment = transformNormalizedToTyped<Assignment>(
      rawAssignment,
      AssignmentAttributeNames,
    )

    const hkId = findRelationshipId(rawAssignment, 'housekeeper')
    if (!hkId) {
      throw new Error(`No housekeeper id could be found on assignment ${hkId}`)
    }

    const housekeeper = getHkById(state, hkId)
    if (!housekeeper) {
      throw new Error(`No housekeeper could be found for id: ${hkId}`)
    }

    return {
      ...assignment,
      housekeeper,
    }
  }
