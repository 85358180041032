/**********************************************************
 * CROSS COVERAGE
 *********************************************************/
export type AllCrossCoverageAttributes = {
  createdAt: string
  deletedAt?: string
  endDate: string
  endTimeLocal: string
  friday: boolean
  isRepeating: boolean
  monday: boolean
  saturday: boolean
  startDate: string
  startTimeLocal: string
  sunday: boolean
  thursday: boolean
  tuesday: boolean
  tz: string
  wednesday: boolean
}

export const AllCrossCoverageAttributeNames = [
  'createdAt',
  'deletedAt',
  'endDate',
  'endDateTimeUTC',
  'endTimeLocal',
  'friday',
  'isRepeating',
  'monday',
  'saturday',
  'startDate',
  'startDateTimeUTC',
  'startTimeLocal',
  'sunday',
  'thursday',
  'tuesday',
  'tz',
  'wednesday',
]
