import styled from '@emotion/styled'

import { TicketSeverity } from 'packages/grimoire/src/ticket'
import { centerWithFlex, colors, text } from 'packages/styles'

export const severityColorMap = {
  [TicketSeverity.LOW]: colors.midnight10,
  [TicketSeverity.MEDIUM]: colors.midnight10,
  [TicketSeverity.PLANNED]: colors.midnight10,
  [TicketSeverity.PRIOR_TO_NEXT_GUEST]: colors.awareness40,
  [TicketSeverity.TODAY]: colors.alert40,
  [TicketSeverity.URGENT]: colors.alert40,
}

export const TicketStatusBar = styled.div<{ severity: TicketSeverity }>`
  background: ${props => severityColorMap[props.severity]};
  display: flex;
  font-size: 12px;
  justify-content: space-between;
  padding: 6px 8px;
`

export const TicketSeverityText = styled.span`
  ${text.bodyBoldTiny};
  text-transform: uppercase;
`

export const VisitTicketSection = styled.div`
  margin: 24px 16px;
`

export const VisitTicketSectionTitle = styled.div`
  ${text.bodyBold};
  margin-bottom: 4px;
`

export const YouBadge = styled.div`
  ${centerWithFlex};
  ${text.headingTinyCaps};
  background: ${colors.aspen50};
  border-radius: 16px;
  color: ${colors.dusk};
  display: inline-flex;
  height: 18px;
  margin-left: 4px;
  padding: 0 6px;
  padding-left: 8px;
`
