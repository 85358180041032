import React from 'react'

import { IconName, SvgIcon } from 'packages/iconic'
import { DateFormat, format } from 'packages/utils/dateHelpers'

import { useCrossCoverageDrawerTranslations } from '../../../useCrossCoverageDrawerTranslations'
import St from '../../../utils/CrossCoverageDrawer.styles'
import { DaySelect, DaySelectOption } from '../DaySelect'

export enum RepeatingCoverageFormTestIds {
  eventEndDatePickerButton = 'eventEndDatePickerButton',
  repeatDaysField = 'repeatDaysField',
  repeatUntilField = 'repeatUntilField',
}

export type RepeatingCoverageFormProps = {
  currentRepeatDaysValue: DaySelectOption[]
  endDate: Date
  handleRepeatDaysSelectChange: (
    options: DaySelectOption[],
    day: { option: DaySelectOption },
  ) => void
  isDisabled: boolean
  showEndDatePickerModal: () => void
}

export const RepeatingCoverageForm: React.FC<RepeatingCoverageFormProps> =
  React.memo(
    ({
      currentRepeatDaysValue,
      endDate,
      isDisabled,
      handleRepeatDaysSelectChange,
      showEndDatePickerModal,
    }) => {
      const strings = useCrossCoverageDrawerTranslations()
      return (
        <>
          <St.FormField
            data-testid={RepeatingCoverageFormTestIds.repeatDaysField}
          >
            <St.FormFieldLabel>
              {strings.eventRepeats}
              <St.FormFieldLabelRequiredAsterisk>
                *
              </St.FormFieldLabelRequiredAsterisk>
            </St.FormFieldLabel>
            <DaySelect
              disabled={isDisabled}
              handleChange={handleRepeatDaysSelectChange}
              value={currentRepeatDaysValue}
            />
          </St.FormField>
          <St.FormField
            data-testid={RepeatingCoverageFormTestIds.repeatUntilField}
          >
            <St.FormFieldLabel>
              {strings.repeatUntilInclusive}
              <St.FormFieldLabelRequiredAsterisk>
                *
              </St.FormFieldLabelRequiredAsterisk>
            </St.FormFieldLabel>
            <St.FormFieldPickerButton
              disabled={isDisabled}
              data-testid={
                RepeatingCoverageFormTestIds.eventEndDatePickerButton
              }
              onClick={showEndDatePickerModal}
            >
              {format(endDate, DateFormat.SlashesWithShortYear)}
              <SvgIcon size={18} icon={IconName.calendar} />
            </St.FormFieldPickerButton>
          </St.FormField>
        </>
      )
    },
  )
