import { AxiosResponse } from 'axios'
import { snakeCase } from 'lodash/fp'

import {
  JSONApiObjectWithRelationships,
  JSONApiObjectWithRelationshipsMap,
  NormalizedJSONApiResponse,
  ToOneRelationship,
  ToOneRelationshipOrNull,
} from 'packages/utils/store'

import { JSONApiUsersMap } from '../users'

export type ComputedWorkingStatus = 'oncall' | 'covered' | 'requested'

/** Represents working status for a given day across all coverage records  */
export type ManyComputedWorkingStatuses = ComputedWorkingStatus | 'multiple'

/**********************************************************
 * REDUX TYPES
 *********************************************************/
export enum CoverageActionTypes {
  FETCH_COVERAGE = 'COVERAGE/API/FETCH_COVERAGE',
  FETCH_COVERAGE_FAILURE = 'COVERAGE/FETCH_COVERAGE_FAILURE',
  FETCH_COVERAGE_SUCCESS = 'COVERAGE/FETCH_COVERAGE_SUCCESS',

  SET_MAY_REQUIRE_COVERAGE_FETCH = 'coverage/setIsCoverageStale',
}

export type CoverageState = {
  data: JSONApiCoverageMap
  isStale: boolean
  user?: JSONApiUsersMap
}

/**********************************************************
 * SERVICE TYPES
 *********************************************************/
export type CoverageResponse = {
  coverage: JSONApiCoverageMap
  user: JSONApiUsersMap
}

export type NormalizedCoverageApiResponse =
  NormalizedJSONApiResponse<CoverageResponse>

export type CoverageServiceResponse = Promise<NormalizedCoverageApiResponse>

export type CoverageApiResponse = AxiosResponse<NormalizedCoverageApiResponse>

/**********************************************************
 * COVERAGE
 *********************************************************/

export type Coverage = {
  crossCoverageId?: string
  id: string
  requestedUserId?: string
  userId: string
  userReceivingVisits: boolean
} & CoverageAttributes

export type RawCoverage = JSONApiObjectWithRelationships<
  CoverageAttributes,
  CoverageRelationships
>

export type CoverageType =
  | 'standard'
  | 'standard_covered'
  | 'standard_covering'
  | 'cross_coverage_covered'
  | 'cross_coverage_covering'

export const isCoveringCoverageTypes: CoverageType[] = [
  'standard_covering',
  'cross_coverage_covering',
]

export const isWorkingCoverageTypes: CoverageType[] = [
  'standard',
  'standard_covering',
  'cross_coverage_covering',
]

export const isNotWorkingCoverageTypes: CoverageType[] = [
  'cross_coverage_covered',
  'standard_covered',
]

export const isCoveredCoverageTypes = isNotWorkingCoverageTypes

export type CoverageDetailsRegion = {
  end: string
  firstName: string
  lastName: string
  start: string
  unitCount: number
}

export type CoverageDetails = {
  coveredRegions: CoverageDetailsRegion[]
  myRegionUnitCount: number
}

export type CoverageAttributes = {
  coverageType: CoverageType
  details: CoverageDetails
  end: string
  start: string
}

export const CoverageAttributeNames = [
  'coverageType',
  'details',
  'end',
  'start',
  'userReceivingVisits',
]

export const CoverageApiFields: string[] = CoverageAttributeNames.map(snakeCase)

export type CoverageRelationships = {
  crossCoverage?: ToOneRelationship
  requestedUser: ToOneRelationshipOrNull
  user: ToOneRelationship
}

export type JSONApiCoverageMap = JSONApiObjectWithRelationshipsMap<
  CoverageAttributes,
  CoverageRelationships
>
