import styled from '@emotion/styled'
import React from 'react'

import { Button } from 'packages/common'
import { useI18n } from 'packages/i18n'
import { IconName, SvgIcon } from 'packages/iconic'
import { colors, text } from 'packages/styles'

import { Slugs } from 'app/fieldapp/i18n'

const useTranslations = incompleteTasks => {
  const { t } = useI18n()

  return {
    confirm: t(Slugs.markVisitDone),
    inspectionIncomplete: t(Slugs.inspectionIncompleteMessage),
    message: incompleteTasks
      ? t(Slugs.incompleteTasksWarningText)
      : t(Slugs.allTasksCompletedText),
    ticketsIncomplete: t(Slugs.ticketsIncompleteMessage),
    title: incompleteTasks
      ? t(Slugs.incompleteTasksWarningHeader)
      : t(Slugs.allTasksCompletedHeader),
  }
}

const St = {
  IconWrapper: styled.span`
    background: ${colors.alert10};
    border-radius: 40px;
    height: 34px;
    width: 34px;
  `,

  IncompleteTaskIcon: styled(SvgIcon)`
    color: ${colors.alert70};
    padding-left: 10px;
    padding-top: 10px;
  `,

  IncompleteTaskRow: styled.div`
    align-items: center;
    display: flex;
    margin-bottom: 8px;
  `,

  IncompleteTaskText: styled.span`
    ${text.bodyRegularSmall};
    margin-left: 10px;
  `,

  ModalContainer: styled.div`
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
  `,

  ModalText: styled.div`
    ${text.bodyRegularSmall};
    margin-bottom: 16px;
  `,

  ModalTitle: styled.div`
    ${text.headingDefault};
  `,

  TasksContainer: styled.div`
    margin-bottom: 8px;
  `,
}

export type IncompleteTasksModalProps = {
  beginClose: () => void
  disabled?: boolean
  incompleteInspection: boolean
  incompleteTickets: boolean
  loading: boolean
  updateVisit: () => void
}

export const IncompleteTasksModal: React.FC<IncompleteTasksModalProps> =
  React.memo(
    ({
      beginClose,
      disabled = false,
      loading,
      incompleteInspection,
      incompleteTickets,
      updateVisit,
    }) => {
      const strings = useTranslations(incompleteTickets || incompleteInspection)

      const handleClick = async () => {
        await updateVisit()
        beginClose()
      }

      return (
        <St.ModalContainer>
          <St.ModalTitle>{strings.title}</St.ModalTitle>
          <St.ModalText>{strings.message}</St.ModalText>

          {(incompleteTickets || incompleteInspection) && (
            <St.TasksContainer>
              {incompleteTickets && (
                <St.IncompleteTaskRow>
                  <St.IconWrapper>
                    <St.IncompleteTaskIcon
                      icon={IconName.tag}
                      size={14}
                    ></St.IncompleteTaskIcon>
                  </St.IconWrapper>
                  <St.IncompleteTaskText>
                    {strings.ticketsIncomplete}
                  </St.IncompleteTaskText>
                </St.IncompleteTaskRow>
              )}

              {incompleteInspection && (
                <St.IncompleteTaskRow>
                  <St.IconWrapper>
                    <St.IncompleteTaskIcon
                      icon={IconName.edit}
                      size={14}
                    ></St.IncompleteTaskIcon>
                  </St.IconWrapper>
                  <St.IncompleteTaskText>
                    {strings.inspectionIncomplete}
                  </St.IncompleteTaskText>
                </St.IncompleteTaskRow>
              )}
            </St.TasksContainer>
          )}

          <Button
            block={true}
            disabled={disabled}
            onClick={handleClick}
            isLoading={loading}
          >
            {strings.confirm}
          </Button>
        </St.ModalContainer>
      )
    },
  )
