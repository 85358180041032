import { createSelector } from 'reselect'

import { ApplicationState } from '../../store'
import { CrossCoverage, JSONApiCrossCoverageMap } from '../crossCoverage.types'
import { hydrateRawCrossCoverage } from '../crossCoverage.utils'

const getCrossCoverageData = (
  state: ApplicationState,
): JSONApiCrossCoverageMap => state.crossCoverage.data

const getId = (_, id) => id

export const getCrossCoverageById = createSelector(
  getCrossCoverageData,
  getId,
  (crossCoverageData, crossCoverageId): CrossCoverage | undefined => {
    const raw = crossCoverageData?.[crossCoverageId]
    if (!raw) return undefined

    return hydrateRawCrossCoverage(raw)
  },
)
