import * as React from 'react'
import { useSelector } from 'react-redux'

import { useOnlineStatus } from 'packages/utils/hooks'

import { getActiveCleanTime } from 'app/fieldapp/store/cleantimes/selectors/getActiveCleanTime'
import { getHasActiveCleanTimeForTask } from 'app/fieldapp/store/cleantimes/selectors/getHasActiveCleanTimeForTask'
import { ApplicationState } from 'app/fieldapp/store/store'
import { Visit } from 'app/fieldapp/store/visits'
import { useActiveUser } from 'app/fieldapp/utils/hooks/useActiveUser'

import {
  DisabledVisitsInspectionType,
  initialInspectionChecklistUIState,
  InspectionChecklistUiState,
  InspectionChecklistUiStateAction,
  inspectionChecklistUiStateReducer,
} from './VisitInspectionChecklist.context.helpers'

export type VisitInspectionChecklistContextType = {
  uiDisabledState: DisabledVisitsInspectionType
  uiState: InspectionChecklistUiState
  uiStateDispatch: React.Dispatch<InspectionChecklistUiStateAction>
  visitId: string
}

export const VisitInspectionChecklistContext =
  React.createContext<VisitInspectionChecklistContextType>(
    {} as VisitInspectionChecklistContextType,
  )

VisitInspectionChecklistContext.displayName = 'VisitInspectionChecklistContext'
export const useInspectionContext = (): VisitInspectionChecklistContextType =>
  React.useContext(VisitInspectionChecklistContext)

export const InspectionChecklistContextWrapper: React.FC<{ visit: Visit }> =
  React.memo(({ visit, children }) => {
    const [uiState, uiStateDispatch] = React.useReducer(
      inspectionChecklistUiStateReducer,
      initialInspectionChecklistUIState,
    )

    const isOnline = useOnlineStatus().isOnline()

    const { isEmployee } = useActiveUser()

    const hasActiveTimerForThisTask = useSelector((state: ApplicationState) =>
      getHasActiveCleanTimeForTask(state, visit.id),
    )

    const hasAnyActiveTimer = useSelector((state: ApplicationState) =>
      getActiveCleanTime(state),
    )

    const uiDisabledState: DisabledVisitsInspectionType = React.useMemo(() => {
      if (!visit.startedAt) {
        return 'taskNotStarted'
      }

      if (!!visit.completedAt) {
        return 'taskIsComplete'
      }

      if (!hasActiveTimerForThisTask && hasAnyActiveTimer) {
        return 'otherTimerRunning'
      }

      if (!hasActiveTimerForThisTask && isEmployee) {
        return 'noActiveTimer'
      }

      if (!isOnline) {
        return 'offline'
      }

      return null
    }, [
      visit,
      isOnline,
      hasActiveTimerForThisTask,
      hasAnyActiveTimer,
      isEmployee,
    ])

    const value = React.useMemo(
      () => ({
        uiDisabledState,
        uiState,
        uiStateDispatch,
        visitId: visit.id,
      }),
      [visit.id, uiDisabledState, uiState, uiStateDispatch],
    )

    return (
      <VisitInspectionChecklistContext.Provider value={value}>
        {children}
      </VisitInspectionChecklistContext.Provider>
    )
  })
