import { noop } from 'lodash/fp'
import React from 'react'

import { IconName, SvgIcon } from 'packages/iconic'

import { CrossCoverage } from 'app/fieldapp/store/crossCoverage/crossCoverage.types'

import { St } from './UpcomingCoverageEvent.styles'
import { useUpcomingCoverageEventValues } from './useUpcomingCoverageEventValues'

export enum UpcomingCoverageEventTestIds {
  container = 'UpcomingCoverageEvent__container',
}

export type UpcomingCoverageEventProps = {
  coverageEvent: CrossCoverage
  handleCoverageEventClick: () => void
  isCrossCoverageDrawerOpen: boolean
}

export const UpcomingCoverageEvent: React.FC<UpcomingCoverageEventProps> =
  React.memo(
    ({
      coverageEvent,
      handleCoverageEventClick,
      isCrossCoverageDrawerOpen,
    }) => {
      const { days, end, endLabel, frequency, start, startLabel, time } =
        useUpcomingCoverageEventValues(coverageEvent)

      return (
        <St.Container
          data-testid={UpcomingCoverageEventTestIds.container}
          isDisabled={isCrossCoverageDrawerOpen}
          onClick={!isCrossCoverageDrawerOpen ? handleCoverageEventClick : noop}
        >
          <St.Frequency>{frequency}</St.Frequency>
          {days && (
            <St.Days isRepeating={coverageEvent.isRepeating}>{days}</St.Days>
          )}
          {time && (
            <St.Time>
              <SvgIcon icon={IconName.clock} size={18} /> {time}
            </St.Time>
          )}
          <St.Dates>
            <St.Date>
              <strong>{startLabel}</strong>
              {start}
            </St.Date>
            <St.Date>
              <strong>{endLabel}</strong>
              {end}
            </St.Date>
          </St.Dates>

          <St.RightChevron
            icon={IconName.chevronRight}
            isDisabled={isCrossCoverageDrawerOpen}
            size={22}
          />
        </St.Container>
      )
    },
  )
