import React from 'react'

import { IconName } from 'packages/iconic'
import { DateFormat, formatLocalized } from 'packages/utils/dateHelpers'

import {
  BasicText,
  Details,
  DetailIcon,
  SectionSubTitle,
  UnitInfoContainer,
} from 'app/fieldapp/components/schedule/components/TaskSharedStyles'
import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { Reservation } from 'app/fieldapp/store/reservations'

const St = {
  BasicText,
  DetailIcon,
  Details,
  SectionSubTitle,
  UnitInfoContainer,
}

const useTranslations = (props: VisitResDetailsProps) => {
  const { currentRes, nextRes, previousRes } = props
  const { t, tp } = useI18n()

  const guestsAndDogs = (res?: Reservation): string =>
    res
      ? `${tp(Slugs.guestsCount, res.guests, false)}, ${tp(
          Slugs.dogsCount,
          res.dogs,
          false,
        )}`
      : ''

  return {
    // Current Reservation
    currentRes: t(Slugs.currentRes),
    currentResDate: currentRes
      ? t(Slugs.checksOutDate, {
          date: formatLocalized(
            currentRes.checkOut,
            DateFormat.MonthAndDayLong,
          ),
        })
      : '',
    currentResGuests: guestsAndDogs(currentRes),

    // Next Reservation
    nextRes: t(Slugs.nextRes),
    nextResDate: nextRes
      ? t(Slugs.checksInDate, {
          date: formatLocalized(nextRes.checkIn, DateFormat.MonthAndDayLong),
        })
      : t(Slugs.noneScheduled),
    nextResGuests: guestsAndDogs(nextRes),

    previousInspectionDate: t(Slugs.previousInspectionDate),

    // Previous Reservation
    previousRes: t(Slugs.previousRes),
    previousResDate: previousRes
      ? t(Slugs.checkedOutDate, {
          date: formatLocalized(
            previousRes.checkOut,
            DateFormat.MonthAndDayLong,
          ),
        })
      : t(Slugs.noneScheduled),
    previousResGuests: guestsAndDogs(previousRes),
  }
}

export type VisitResDetailsProps = {
  currentRes?: Reservation
  nextRes?: Reservation
  previousInspectionDate?: String
  previousRes?: Reservation
}

export const VisitResDetails: React.FC<VisitResDetailsProps> = React.memo(
  props => {
    const { currentRes, previousRes, previousInspectionDate } = props
    const strings = useTranslations(props)

    return (
      <>
        {previousInspectionDate && (
          <St.UnitInfoContainer>
            <St.DetailIcon icon={IconName.calendarClean} size={16} />
            <St.Details>
              <St.SectionSubTitle>
                {strings.previousInspectionDate}
              </St.SectionSubTitle>
              <St.BasicText>{previousInspectionDate}</St.BasicText>
            </St.Details>
          </St.UnitInfoContainer>
        )}

        {!currentRes && !!previousRes && (
          <St.UnitInfoContainer>
            <St.DetailIcon icon={IconName.users} size={16} />
            <St.Details>
              <St.SectionSubTitle>{strings.previousRes}</St.SectionSubTitle>
              <St.BasicText>{strings.previousResDate}</St.BasicText>
              <St.BasicText>{strings.previousResGuests}</St.BasicText>
            </St.Details>
          </St.UnitInfoContainer>
        )}

        {!!currentRes && (
          <St.UnitInfoContainer>
            <St.DetailIcon icon={IconName.users} size={16} />
            <St.Details>
              <St.SectionSubTitle>{strings.currentRes}</St.SectionSubTitle>
              <St.BasicText>{strings.currentResDate}</St.BasicText>
              <St.BasicText>{strings.currentResGuests}</St.BasicText>
            </St.Details>
          </St.UnitInfoContainer>
        )}

        <St.UnitInfoContainer>
          <St.DetailIcon icon={IconName.users} size={16} />
          <St.Details>
            <St.SectionSubTitle>{strings.nextRes}</St.SectionSubTitle>
            <St.BasicText>{strings.nextResDate}</St.BasicText>
            <St.BasicText>{strings.nextResGuests}</St.BasicText>
          </St.Details>
        </St.UnitInfoContainer>
      </>
    )
  },
)
