import styled from '@emotion/styled'
import React, { useState } from 'react'

import { Alert } from 'packages/common'
import { IconName } from 'packages/iconic'
import { colors, text } from 'packages/styles'
import { createDateObject, differenceInDays } from 'packages/utils/dateHelpers'
import {
  unitCodeAndNameString,
  unitFullAddressString,
} from 'packages/utils/hkStringHelpers'

import { ReviewCard } from 'app/fieldapp/components/core/components/ReviewCard'
import { ReviewFiltersContainer } from 'app/fieldapp/components/schedule/components/Reviews/ReviewFiltersContainer'
import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { Review } from 'app/fieldapp/store/reviews/reviews.types'
import { Unit } from 'app/fieldapp/store/units'
import { Visit } from 'app/fieldapp/store/visits'

import {
  BasicText,
  ScheduledVisitIcon,
  TaskDetailContainer,
  TaskIcon,
  UnitName,
  VisitDetailHeader,
  VisitHeaderLeftIcons,
} from '../../../TaskSharedStyles'

const St = {
  ReviewsList: styled.div`
    padding: 15px;
    margin-top: 16px;
  `,

  ScheduledVisitIcon,

  SectionContent: styled.div`
    padding: 24px;
    :not(:last-of-type) {
      border-bottom: solid ${colors.midnight30};
      border-width: 1px 0;
    }
  `,

  SectionTitle: styled.div`
    ${text.fieldLabelMobile}
    margin-bottom:10px;
  `,

  TaskDetailContainer,
  TaskIcon,

  UnitAddress: BasicText,
  UnitName,

  VisitDetailHeader,
  VisitHeaderLeftIcons,

  VisitReviewAlert: styled(Alert)`
    margin: 16px;
  `,
}

const textStyles: React.CSSProperties = {
  marginLeft: '8px',
}

const useTranslations = () => {
  const { ut } = useI18n()

  return {
    reviewNoAttached: ut(Slugs.reviewNoAttached),
    reviewTitle: ut(Slugs.reviewTitle),
    visit: ut(Slugs.visit),
  }
}

export type VisitReviewsTabProps = {
  onReviewCardClick: (review: Review) => void
  reviews: Review[]
  unit: Unit
  visit: Visit
}

function sortByReviewDate(a: Review, b: Review): number {
  const dateA = createDateObject(a.reviewDate)
  const dateB = createDateObject(b.reviewDate)

  if (differenceInDays(dateA, dateB) === 0) return 0
  return differenceInDays(dateA, dateB) < 0 ? 1 : -1
}

export const VisitReviewsTab: React.FC<VisitReviewsTabProps> = React.memo(
  ({ unit, reviews, visit, onReviewCardClick }) => {
    const strings = useTranslations()

    const unitNameString = unitCodeAndNameString(unit)
    const addressString = unitFullAddressString(unit)

    const [filteredReviews, setFilteredReviews] = useState(reviews)

    return (
      <St.TaskDetailContainer>
        <St.VisitDetailHeader>
          <St.VisitHeaderLeftIcons>
            <St.TaskIcon
              centerItems={true}
              icon={IconName.maintenance}
              size={18}
              text={strings.visit}
              textFontSize={'inherit'}
              textStyleOverrides={textStyles}
            />
            {visit.serviceType === 'scheduled' && (
              <St.ScheduledVisitIcon
                centerItems={true}
                icon={IconName.refreshCw}
                size={16}
              />
            )}
          </St.VisitHeaderLeftIcons>
        </St.VisitDetailHeader>

        <St.SectionContent>
          <St.SectionTitle>{strings.reviewTitle}</St.SectionTitle>
          <St.UnitName>{unitNameString}</St.UnitName>
          <St.UnitAddress>{addressString}</St.UnitAddress>
        </St.SectionContent>

        {reviews.length ? (
          <St.ReviewsList data-testid="reviews-list">
            <ReviewFiltersContainer
              reviews={reviews}
              setFilteredReviews={setFilteredReviews}
            />
            {filteredReviews.sort(sortByReviewDate).map(review => (
              <ReviewCard
                key={review.reviewId}
                review={review}
                onClick={() => onReviewCardClick(review)}
              />
            ))}
          </St.ReviewsList>
        ) : (
          <St.VisitReviewAlert alertType={'info'}>
            <div>{strings.reviewNoAttached}</div>
          </St.VisitReviewAlert>
        )}
      </St.TaskDetailContainer>
    )
  },
)
