import styled from '@emotion/styled'
import React from 'react'

import { Clean } from 'app/fieldapp/store/cleans'

import { CleanStatusControls } from './CleanStatusControls'
import { CleanStatusDisplay } from './CleanStatusDisplay'

const St = {
  CleanStatus: styled.div`
    align-items: center;
    border-bottom: 1px solid #dfe5e8;
    border-top: 1px solid #dfe5e8;
    display: flex;
    height: 68px;
    justify-content: space-between;
    margin: 0 -16px;
    padding: 0px 24px 0px 16px;
    position: relative;
  `,
}

export enum CleanStatusTestIds {
  completedIcon = 'CleanStatus__completedIcon',
  container = 'CleanStatus__container',
}

export type CleanStatusProps = {
  clean: Clean
}

export const CleanStatus: React.FC<CleanStatusProps> = React.memo(
  ({ clean }) => {
    return (
      <St.CleanStatus>
        <CleanStatusDisplay clean={clean} />
        <CleanStatusControls clean={clean} />
      </St.CleanStatus>
    )
  },
)
