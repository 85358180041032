import { createSelector } from 'reselect'

import { ApplicationState } from 'app/fieldapp/store/store'

import { getTicketsByIds } from '../../tickets/selectors'
import { getVisitById } from './getVisitById'

const getFullState = (state: ApplicationState): ApplicationState => state
const getVisitId = (_, visitId: string) => visitId

export const getAllTicketsCompleteForVisit = createSelector(
  getFullState,
  getVisitId,
  (state, visitId): boolean => {
    const visit = getVisitById(state, visitId)

    if (visit?.ticketIds) {
      const tickets = getTicketsByIds(state, visit.ticketIds)

      return tickets.every(ticket => !!ticket.completedAt)
    }

    return true
  },
)
