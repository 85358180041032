import * as React from 'react'

import { TimerType } from 'packages/grimoire/src/utils/timers.types'

import { StopTimerButton } from '../../Buttons/StopTimerButton'
import {
  ActiveTimeContainer,
  DetailWrapper,
  TimeDisplay,
  TimerName,
} from '../ActiveTimers.styles'
import { ActiveOtherTimerStrings } from './ActiveOtherTimer.container'

export enum ActiveOtherTimerTestIds {
  container = 'ActiveOtherTimer__container',
  pauseBtn = 'ActiveOtherTimer__pauseBtn',
}

export type ActiveOtherTimerProps = {
  isLoading: boolean
  stopActiveTimer: () => void
  strings: ActiveOtherTimerStrings
  timeString: string
}

export const ActiveOtherTimer: React.FC<ActiveOtherTimerProps> = React.memo(
  ({ isLoading, stopActiveTimer, strings, timeString }) => {
    return (
      <ActiveTimeContainer
        data-testid={ActiveOtherTimerTestIds.container}
        timerColorType={TimerType.OTHER}
      >
        <DetailWrapper>
          <TimerName>{strings.timerName}</TimerName>
        </DetailWrapper>

        <TimeDisplay>{timeString}</TimeDisplay>

        <StopTimerButton
          dataTestId={ActiveOtherTimerTestIds.pauseBtn}
          isLoading={isLoading}
          onClick={stopActiveTimer}
        />
      </ActiveTimeContainer>
    )
  },
)
