import { createAsyncThunk } from '@reduxjs/toolkit'
import { noop } from 'lodash/fp'

import { ReduxActionCallbacks } from 'packages/grimoire/src/utils'

import { updateInspectionFlag } from '../../inspectionFlags/actions/updateInspectionFlag'
import { fetchVisitById } from './fetchVisitById'

type SaveInspectionFlagNoteData = {
  inspectionFlagId: string
  note: string
  visitId: string
}

type SaveInspectionNoteThunkArgs = {
  callbacks: ReduxActionCallbacks
  saveData: SaveInspectionFlagNoteData
}

export const saveInspectionFlagNote = createAsyncThunk(
  'inspectionFlags/saveInspectionFlagNote',
  async (args: SaveInspectionNoteThunkArgs, { dispatch }) => {
    const { callbacks, saveData } = args
    const { inspectionFlagId, note, visitId } = saveData
    const { onError = noop, onSuccess = noop } = callbacks

    try {
      const result = await dispatch(
        updateInspectionFlag({
          id: inspectionFlagId,
          patchData: {
            notes: note,
          },
        }),
      )

      await dispatch(fetchVisitById(visitId))

      onSuccess()
      return result
    } catch (err) {
      onError(err)
    }
  },
)
