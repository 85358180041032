import { noop } from 'lodash/fp'
import React from 'react'

import { IconName } from 'packages/iconic'

import * as St from './VisitInspectionCheckbox.styles'

export enum VisitInspectionCheckboxTestIds {
  container = 'VisitInspectionCheckbox__container',
}

export type VisitInspectionCheckboxProps = {
  checked?: boolean
  dataTestId?: string
  disabled?: boolean
  isFlagged?: boolean
  name?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  value: string
}

export const VisitInspectionCheckbox: React.FC<VisitInspectionCheckboxProps> =
  React.memo(
    ({
      checked,
      dataTestId = VisitInspectionCheckboxTestIds.container,
      disabled = false,
      isFlagged = false,
      name,
      onChange = noop,
      value,
    }) => {
      const cbName = name ?? `Checkbox__${value}`

      return (
        <St.CheckboxContainer data-testid={dataTestId}>
          <St.CheckboxLabel disabled={disabled}>
            <St.CheckboxInput
              checked={checked}
              disabled={disabled}
              id={cbName}
              name={cbName}
              onChange={onChange}
              type="checkbox"
              value={value}
            />
            <St.CustomCheckbox isFlagged={isFlagged}>
              <St.Icon
                icon={isFlagged ? IconName.flag : IconName.thinCheck}
                size={20}
                disabled={disabled}
              />
            </St.CustomCheckbox>
          </St.CheckboxLabel>
        </St.CheckboxContainer>
      )
    },
  )
