import { createAsyncAction } from 'typesafe-actions'

import {
  RequestConfig,
  RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import { HousekeeperApiFields } from 'app/fieldapp/store/housekeepers/housekeepers.types'
import { ApplicationState } from 'app/fieldapp/store/store'

import { getFrozenAssignmentIds } from '../../assignments/selectors'
import { cleanTimesService } from '../cleantimes.service'
import {
  CleanTimeApiFields,
  CleanTimesActionTypes,
  NormalizedCleanTimesApiResponse,
} from '../cleantimes.types'

export const fetchActiveCleanTimesForUserAction = createAsyncAction(
  CleanTimesActionTypes.FETCH_CLEAN_TIMES,
  CleanTimesActionTypes.FETCH_CLEAN_TIMES_SUCCESS,
  CleanTimesActionTypes.FETCH_CLEAN_TIMES_FAILURE,
)<
  RequestConfig<NormalizedCleanTimesApiResponse>,
  NormalizedCleanTimesApiResponse,
  Error
>()

export const getParams = (assignmentIds: string[]): RequestOptions => ({
  fields: {
    assignment: [],
    clean_time: CleanTimeApiFields,
    housekeeper: HousekeeperApiFields,
  },
  filter: {
    assignment: { in: assignmentIds },
    is_finalized: false,
  },
  include: ['assignment', 'assignment.housekeeper'],
  page: { size: 1500 },
})

/**
 * Sends a request to fetch all un-finalized CleanTimes for the current user.
 *
 * This is a bit of a speciality action, in that CleanTime fetching requires
 * additional logic beyond the other timer types, and it is all self-contained here:
 * - We cannot simply fetch all for a given user ID
 * - We must use assignment IDs to fetch them, but we must also ensure that:
 *     - We only use assignments for cleans within the freeze window
 *     - We only include assignments for this user (i.e. in the case of multi-user cleans)
 */
export const fetchActiveCleanTimesForUser = () => {
  return async (dispatch, getState: () => ApplicationState) => {
    try {
      const state = getState()
      const frozenAssignmentIds = getFrozenAssignmentIds(state)
      const params = getParams(frozenAssignmentIds)

      const request = cleanTimesService.fetchCleanTimes.bind(null, params)
      const result = await dispatch(
        fetchActiveCleanTimesForUserAction.request({ request }),
      )

      dispatch(fetchActiveCleanTimesForUserAction.success(result))
      return result
    } catch (error) {
      dispatch(fetchActiveCleanTimesForUserAction.failure(error))
      throw error
    }
  }
}
