import styled from '@emotion/styled'
import React from 'react'
import { useSelector } from 'react-redux'

import { useI18n } from 'packages/i18n'
import { text } from 'packages/styles'

import { ReviewCardHeader } from 'app/fieldapp/components/core/components/ReviewCard/ReviewCardHeader'
import { useAppSelector } from 'app/fieldapp/store/hooks'
import { RatingInfo } from 'app/fieldapp/store/reviews/reviews.types'
import { getReviewById } from 'app/fieldapp/store/reviews/selectors/getReviewById'
import { ApplicationState } from 'app/fieldapp/store/store'
import { getReviewDrawerState } from 'app/fieldapp/store/ui/selectors'
import { getUnitById } from 'app/fieldapp/store/units/selectors'

import { RatingCollapsible } from '../RatingCollapsible'
import { ReviewLinks } from '../ReviewLinks'
import { TextReview } from '../TextReview'
import { generateRatings } from './ReviewDetail.utils'

const St = {
  ReviewDetails: styled.div`
    padding-bottom: 64px;
    padding-top: 48px;
  `,
  ReviewSection: styled.div`
    padding: 24px;
  `,
  ReviewTitle: styled.div`
    ${text.bodyBoldRegular};
    margin-bottom: 5px;
  `,
}

export const ReviewDetail: React.FC = React.memo(() => {
  const { t } = useI18n()
  const { reviewId } = useSelector(getReviewDrawerState)

  const review = useAppSelector((state: ApplicationState) =>
    getReviewById(state, reviewId),
  )

  const unit = useAppSelector((state: ApplicationState) =>
    getUnitById(state, review?.unitId),
  )

  return (
    <St.ReviewDetails>
      <ReviewCardHeader
        id={review?.reviewId}
        reviewSource={review.reviewSource}
        overallRating={review.overallRating}
        isReplied={!!review.reviewReply}
      />
      <St.ReviewSection>
        {review.reviewHeadline && (
          <St.ReviewTitle>{review.reviewHeadline}</St.ReviewTitle>
        )}

        <RatingCollapsible
          collapsibleLimit={0}
          overallRating={review.overallRating}
          ratings={generateRatings(review).map(
            (x): RatingInfo => ({
              label: t(x.slug),
              value: x.value,
            }),
          )}
        />

        <TextReview label="Review Content" content={review.publicReviewText} />
        <TextReview label="Private Review" content={review.privateReviewText} />
        <TextReview label="Review Reply" content={review.reviewReply} />

        <ReviewLinks
          reservationId={review.reservationId}
          unit={unit}
          taskId=""
        />
      </St.ReviewSection>
    </St.ReviewDetails>
  )
})
