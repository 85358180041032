import { createAsyncAction } from 'typesafe-actions'

import {
  OfflineCleanTime,
  CleanTimesActionTypes,
} from 'app/fieldapp/store/cleantimes'
import { offlineTimers } from 'app/fieldapp/store/utils'

export const reloadOfflineCleanTimesAction = createAsyncAction(
  CleanTimesActionTypes.RELOAD_OFFLINE_CLEAN_TIMES,
  CleanTimesActionTypes.RELOAD_OFFLINE_CLEAN_TIMES_SUCCESS,
  CleanTimesActionTypes.RELOAD_OFFLINE_CLEAN_TIMES_FAILURE,
)<null, Record<string, OfflineCleanTime>, Error>()

export const reloadOfflineCleanTimes = () => async dispatch => {
  const offlineCleanTimes = await offlineTimers.getCleanTimes()
  dispatch(reloadOfflineCleanTimesAction.success(offlineCleanTimes))
}
