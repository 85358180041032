import { produce } from 'immer'
import { merge } from 'lodash'
import { ActionType, getType } from 'typesafe-actions'

import { fetchReviewsByUnitIdAction } from './actions/fetchReviewsByUnitId'
import { ReviewsState } from './reviews.types'
import { emptyNormalizedReviewData } from './reviews.utils'

export const initialState: ReviewsState = {
  data: {},
}

const actions = {
  fetchReviewsByUnitIdAction,
}

type Actions = ActionType<typeof actions>

export const reviewsReducer = (
  state = initialState,
  action: Actions,
): ReviewsState =>
  produce(state, (draft: ReviewsState) => {
    switch (action.type) {
      case getType(fetchReviewsByUnitIdAction.success): {
        const normalized =
          action.payload.normalized || emptyNormalizedReviewData

        if (normalized.guestReview) {
          Object.values(normalized.guestReview).forEach(guestReview => {
            const existingReview = state.data[guestReview.id] || {}
            const mergedReview = merge(existingReview, guestReview)
            draft.data[guestReview.id] = mergedReview
          })
        }

        return
      }
    }
  })
