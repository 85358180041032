import { createAsyncThunk } from '@reduxjs/toolkit'

import { InspectionCategory, InspectionListItem } from 'packages/grimoire'
import { Events, track } from 'packages/wiretap'

import { isViewingSharedClean } from 'app/fieldapp/components/schedule/schedule.utils'

import { getDelegateUserId } from '../../auth/selectors'
import { ApplicationState } from '../../store'
import { toggleIsCompletedString } from '../../tasks/utils/tasks.utils'
import { getActiveUser } from '../../users/selectors'
import { updateVisit } from './updateVisit'

type toggleInspectionChecklistItemParams = {
  category: InspectionCategory
  item: InspectionListItem
  visitId: string
}

export const toggleInspectionChecklistItem = createAsyncThunk(
  'visits/toggleInspectionChecklistItem',
  async (
    { category, item, visitId }: toggleInspectionChecklistItemParams,
    { dispatch, getState },
  ) => {
    const { id: itemId, isComplete } = item

    const userId = getActiveUser(getState() as ApplicationState)?.id
    const delegateUserId = getDelegateUserId(getState() as ApplicationState)

    const toggledItemIsComplete = {
      ...item,
      isComplete: toggleIsCompletedString(isComplete),
    }

    /* eslint-disable @typescript-eslint/naming-convention */
    const trackingSuccessPayload = {
      category_id: category.id,
      is_complete: toggleIsCompletedString(isComplete),
      is_delegate: isViewingSharedClean(),
      item_id: itemId,
      user_id: userId || delegateUserId || 'unknown',
      visit_id: visitId,
    }
    /* eslint-enable @typescript-eslint/naming-convention */

    try {
      await dispatch(
        updateVisit({
          id: visitId,
          inspectionUpdateConfig: {
            category,
            item: toggledItemIsComplete,
          },
        }),
      )

      track(Events.fieldAppInspectionToggleItem, trackingSuccessPayload)
    } catch (err) {
      track(Events.fieldAppInspectionToggleItemError, {
        ...trackingSuccessPayload,
        error: err?.message || 'Unknown Error',
      })
    }
  },
)
