import { useDispatch } from 'react-redux'

import { useToast } from 'packages/common'
import { AsyncState, useAsyncFnWithReset } from 'packages/utils/hooks'

import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { AppDispatch } from 'app/fieldapp/store/store'
import { VisitPatchData } from 'app/fieldapp/store/visits'
import { updateVisit } from 'app/fieldapp/store/visits/actions'

const useTranslations = () => {
  const { t } = useI18n()

  const updateMessages = {
    error: t(Slugs.visitUpdateError),
    success: t(Slugs.visitUpdateSuccess),
  }

  return updateMessages
}

type UseUpdateVisit = [
  AsyncState<unknown>,
  (
    patchData: VisitPatchData,
  ) => Promise<(dispatch: unknown) => Promise<unknown>>,
  () => void,
]

export const useUpdateVisit = (): UseUpdateVisit => {
  const strings = useTranslations()
  const dispatch: AppDispatch = useDispatch()
  const { showToast } = useToast()

  return useAsyncFnWithReset(
    async (patchData: VisitPatchData) =>
      dispatch(
        updateVisit(patchData, {
          onError: () => {
            showToast({
              message: strings.error,
              toastType: 'danger',
            })
          },
          onSuccess: () => {
            showToast({
              message: strings.success,
            })
          },
        }),
      ),
    [dispatch, showToast, strings.error, strings.success],
  )
}
