import { noop } from 'lodash/fp'
import { createAsyncAction } from 'typesafe-actions'

import { ReduxActionCallbacks } from 'packages/grimoire/src/utils'
import {
  makeRelationship,
  RequestConfig,
  RequestOptions,
} from 'packages/utils/store'

import { fetchTicketById } from '../../tickets/actions'
import { ticketCommentsService } from '../ticketComments.service'
import {
  NormalizedTicketCommentsApiResponse,
  TicketCommentPostData,
  TicketCommentsActionTypes,
} from '../ticketComments.types'

export const createTicketCommentAction = createAsyncAction(
  TicketCommentsActionTypes.CREATE_TICKET_COMMENT,
  TicketCommentsActionTypes.CREATE_TICKET_COMMENT_SUCCESS,
  TicketCommentsActionTypes.CREATE_TICKET_COMMENT_FAILURE,
)<
  RequestConfig<NormalizedTicketCommentsApiResponse>,
  NormalizedTicketCommentsApiResponse,
  Error
>()

export const getParams = (postData: TicketCommentPostData): RequestOptions => ({
  data: {
    attributes: {
      external: postData.external,
      note: postData.note,
    },
    relationships: {
      ticket: makeRelationship('ticket', postData.ticketId),
      user: makeRelationship('user', postData.userId),
    },
    type: 'ticket_comment',
  },
})

export const createTicketComment =
  (postData: TicketCommentPostData, callbacks: ReduxActionCallbacks = {}) =>
  async dispatch => {
    const { onError = noop, onSuccess = noop } = callbacks

    try {
      const params = getParams(postData)
      const request = ticketCommentsService.createTicketComment.bind(
        null,
        params,
      )
      const result = await dispatch(
        createTicketCommentAction.request({ request }),
      )
      dispatch(createTicketCommentAction.success(result))

      const newTicketCommentId = Object.keys(result.normalized.ticketComment)[0]
      // re-fetch the TICKET, so we can get both the full new comment and the up-to-date relationships
      await dispatch(fetchTicketById(postData.ticketId))

      onSuccess(newTicketCommentId)
      return result.normalized
    } catch (error) {
      dispatch(createTicketCommentAction.failure(error))
      onError()
      throw error
    }
  }
