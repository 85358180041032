import styled from '@emotion/styled'
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Review } from 'app/fieldapp/store/reviews/reviews.types'
import { getReviewsByUnitId } from 'app/fieldapp/store/reviews/selectors/getReviewsByUnitId'
import { AppDispatch, ApplicationState } from 'app/fieldapp/store/store'
import { setReviewDrawer } from 'app/fieldapp/store/ui/ui.slice'
import { getUnitById } from 'app/fieldapp/store/units/selectors'
import { getVisitById } from 'app/fieldapp/store/visits/selectors'

import { VisitReviewsTab } from './VisitReviewsTab'

const St = {
  Invalid: styled.div`
    padding: 12px;
  `,
}

export type VisitReviewsTabContainerProps = {
  visitId: string
}

export const VisitReviewsTabContainer: React.FC<VisitReviewsTabContainerProps> =
  React.memo(({ visitId }) => {
    const dispatch: AppDispatch = useDispatch()

    const handleReviewCardClick = React.useCallback(
      (review: Review) => {
        dispatch(
          setReviewDrawer({
            isOpen: true,
            reviewId: review.reviewId,
          }),
        )
      },
      [dispatch],
    )

    const visit = useSelector((state: ApplicationState) =>
      getVisitById(state, visitId || ''),
    )

    const unit = useSelector((state: ApplicationState) =>
      getUnitById(state, visit?.unitId || ''),
    )

    const reviews = useSelector((state: ApplicationState) =>
      getReviewsByUnitId(state, visit?.unitId || ''),
    )

    // this should never happen, but we need to make TS happy and handle it in a safe way
    if (!visit || !unit || !reviews) {
      return <St.Invalid>Invalid Review Data</St.Invalid>
    }

    return (
      <>
        <VisitReviewsTab
          unit={unit}
          reviews={reviews}
          visit={visit}
          onReviewCardClick={handleReviewCardClick}
        />
      </>
    )
  })
