import { createAsyncThunk } from '@reduxjs/toolkit'

import { RequestOptions } from 'packages/utils/store'

import { standardAvailabilityService } from '../standardAvailability.service'
import { StandardAvailabilityApiFields } from '../standardAvailability.types'

/**
  since it is possible to have more than one "current_availabiliy" stored in the DB, the
  is_current filter on getParams informs the API to give us only the most current availabilities
*/
export const getParams = (userId?: string): RequestOptions => {
  return {
    fields: { standard_availability: StandardAvailabilityApiFields },
    filter: {
      is_current: true,
      user: userId,
    },
  }
}

export const fetchStandardAvailability = createAsyncThunk(
  'standardAvailability/api/fetchStandardAvailability',
  async (userId?: string) => {
    const params = getParams(userId)
    const response =
      await standardAvailabilityService.fetchStandardAvailability(params)
    return response
  },
)
