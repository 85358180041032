import styled from '@emotion/styled'
import React from 'react'

import { colors, text } from 'packages/styles'
import { DateFormat, format } from 'packages/utils/dateHelpers'

import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { useAppSelector } from 'app/fieldapp/store/hooks'
import { ApplicationState } from 'app/fieldapp/store/store'
import { getVisitById } from 'app/fieldapp/store/visits/selectors'

const St = {
  ExpandCaret: styled.span<{ isExpanded?: boolean }>`
    border-bottom: 5px solid transparent;
    border-left: 6px solid ${colors.midnight};
    border-radius: 1px;
    border-top: 5px solid transparent;
    height: 0;
    margin-left: 0px;
    margin-top: 4px;
    transform: ${({ isExpanded }) => (isExpanded ? 'rotate(90deg)' : '')}
      translateX(1px);
    width: 0;
  `,
  LastUpdated: styled.div`
    ${text.bodyTiny};
    color: ${colors.dusk40};
    display: flex;
    justify-content: right;
    margin-top: 12px;
  `,
  ScoreDetail: styled.div`
    ${text.bodyTiny}
  `,
  ScoreDetailContainer: styled.div`
    display: flex;
    justify-content: space-between;
  `,
  Title: styled.div`
    ${text.bodyBoldTiny};
    margin-bottom: 12px;
    margin-left: 8px;
  `,
  VPSHeader: styled.div`
    cursor: pointer;
    display: flex;
    padding-top: 12px;
  `,
}

export enum VisitPriorityScoreDetailTestIds {
  caret = 'VisitPriorityScoreDetail__caret',
}

export type VisitPriorityScoreDetailProps = {
  visitId: string
}

export const VisitPriorityScoreDetail: React.FC<VisitPriorityScoreDetailProps> =
  React.memo(({ visitId }) => {
    const { t } = useI18n()
    const visit = useAppSelector((state: ApplicationState) =>
      getVisitById(state, visitId),
    )
    const priorityScore = visit?.metadata.priorityScore
    const createdAt = visit?.createdAt

    const [isExpanded, setIsExpanded] = React.useState(false)

    if (!priorityScore || !createdAt) return null
    return (
      <>
        <St.VPSHeader onClick={() => setIsExpanded(!isExpanded)}>
          <St.ExpandCaret
            data-testid={VisitPriorityScoreDetailTestIds.caret}
            isExpanded={isExpanded}
          />
          <St.Title>{`${t(Slugs.visitPriorityScore)} = ${
            priorityScore.totalScore
          }*`}</St.Title>
        </St.VPSHeader>
        {isExpanded && (
          <>
            <St.ScoreDetailContainer>
              <St.ScoreDetail>
                {t(Slugs.oldestInspectionRanking)}
              </St.ScoreDetail>
              <St.ScoreDetail>
                {priorityScore.lastCompletedInspectionScore}
              </St.ScoreDetail>
            </St.ScoreDetailContainer>
            <St.ScoreDetailContainer>
              <St.ScoreDetail>
                {t(Slugs.openTicketUrgencyPoints)}
              </St.ScoreDetail>
              <St.ScoreDetail>
                {priorityScore.ticketSeverityScore}
              </St.ScoreDetail>
            </St.ScoreDetailContainer>
            <St.ScoreDetailContainer>
              <St.ScoreDetail>{t(Slugs.bedroomQuantity)}</St.ScoreDetail>
              <St.ScoreDetail>{priorityScore.bedroomCountScore}</St.ScoreDetail>
            </St.ScoreDetailContainer>
            <St.ScoreDetailContainer>
              <St.ScoreDetail>{t(Slugs.b2bCheckIn)}</St.ScoreDetail>
              <St.ScoreDetail>{priorityScore.b2BScore}</St.ScoreDetail>
            </St.ScoreDetailContainer>
            <St.ScoreDetailContainer>
              <St.ScoreDetail>{t(Slugs.resWithin48Hours)}</St.ScoreDetail>
              <St.ScoreDetail>
                {priorityScore.upcomingReservationScore}
              </St.ScoreDetail>
            </St.ScoreDetailContainer>
            <St.ScoreDetailContainer>
              <St.ScoreDetail>{t(Slugs.ownerArrival)}</St.ScoreDetail>
              <St.ScoreDetail>{priorityScore.ownerArrivalScore}</St.ScoreDetail>
            </St.ScoreDetailContainer>
            <St.LastUpdated>{`*${t(Slugs.lastUpdated)} ${format(
              createdAt,
              DateFormat.MonthAndDayShortWithYear,
            )}`}</St.LastUpdated>
          </>
        )}
      </>
    )
  })
