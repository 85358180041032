import { createSelector } from 'reselect'

import { ApplicationState } from 'app/fieldapp/store/store'
import { hydrateRawUser, JSONApiUsersMap, User } from 'app/fieldapp/store/users'

const getUsersData = (state: ApplicationState): JSONApiUsersMap =>
  state.users.data

const getAuthUserId = (state: ApplicationState): string =>
  state.users.authUserId

export const getAuthUser = createSelector(
  getUsersData,
  getAuthUserId,
  (usersData, authUserId): User | undefined => {
    const rawUser = usersData[authUserId]
    return rawUser && hydrateRawUser(rawUser)
  },
)
