import classNames from 'classnames'
import React from 'react'
import { Controller, FieldError, Control } from 'react-hook-form'

import { useI18n } from 'packages/i18n'
import { IconName, SvgIcon } from 'packages/iconic'
import {
  isAfter,
  isSameMinute,
  isFuture as dateIsFuture,
} from 'packages/utils/dateHelpers'
import { useOnlineStatus } from 'packages/utils/hooks/useOnlineStatus'
import { Events, track } from 'packages/wiretap'

import { Slugs } from 'app/fieldapp/i18n'

import styles from '../../SubmitTimers.module.scss'
import { DateTimeInput } from '../DateTimeInput'

const trackStartedAtChange = () => track(Events.submitTimerStartedAtChanged)
const trackStoppedAtChange = () => track(Events.submitTimerStoppedAtChanged)

export enum TimerInputTestIds {
  container = 'TimerInput__container',
  deleteBtn = 'TimerInput__deleteBtn',
}

export type TimerInputProps = {
  control: Control
  errors: {
    startedAt?: FieldError
    stoppedAt?: FieldError
  }
  id: string
  onDelete: () => void
  onTimerChange: (e) => void
  trigger: (field: string) => void
  watch: (field: string) => string
}

export const TimerInput: React.FC<TimerInputProps> = ({
  control,
  errors,
  id,
  onDelete,
  trigger,
  watch,
  onTimerChange,
}) => {
  const { ut } = useI18n()
  const isOnline = useOnlineStatus().isOnline()

  const startedAtField = `${id}.startedAt`
  const stoppedAtField = `${id}.stoppedAt`

  const isFuture = value =>
    dateIsFuture(value) ? ut(Slugs.timerChangeErrorFuture) : true

  const isStartAfter = value => {
    const other = watch(stoppedAtField)

    return isAfter(value, other) || isSameMinute(value, other)
      ? ut(Slugs.timerChangeErrorStartAfter)
      : true
  }

  const isEndBefore = value => {
    const other = watch(startedAtField)

    return isAfter(other, value) || isSameMinute(other, value)
      ? ut(Slugs.timerChangeErrorEndBefore)
      : true
  }

  return (
    <div data-testid={TimerInputTestIds.container} className={styles.timerRow}>
      {isOnline && (
        <div className={styles.closeButtonContainer}>
          <div
            className={styles.closeButton}
            data-testid={TimerInputTestIds.deleteBtn}
            onClick={onDelete}
          >
            <SvgIcon className={styles.closeX} icon={IconName.x} size-={10} />
          </div>
        </div>
      )}

      <div className={classNames(styles.timerLabel, styles.startLabel)}>
        <div className={styles.triangle} />
        <span className={styles.labelText}>{ut(Slugs.start)}</span>
      </div>

      <Controller
        control={control}
        name={startedAtField}
        rules={{
          validate: {
            isFuture,
            isStartAfter,
          },
        }}
        onTimerChange={onTimerChange}
        as={DateTimeInput}
        className={styles.startDisplay}
        error={errors?.startedAt}
        trackChange={trackStartedAtChange}
        validateOther={() => trigger(stoppedAtField)}
      />

      <div className={classNames(styles.stopLabel, styles.timerLabel)}>
        <div className={styles.square} />
        <span className={styles.labelText}>{ut(Slugs.stop)}</span>
      </div>

      <Controller
        control={control}
        name={stoppedAtField}
        rules={{
          validate: {
            isEndBefore,
            isFuture,
          },
        }}
        onTimerChange={onTimerChange}
        as={DateTimeInput}
        className={styles.stopDisplay}
        error={errors?.stoppedAt}
        trackChange={trackStoppedAtChange}
        validateOther={() => trigger(startedAtField)}
      />
    </div>
  )
}
