import {
  findRelationshipId,
  transformNormalizedToTyped,
} from 'packages/utils/store'

import { Assignment } from '../assignments'
import { getAssignmentById } from '../assignments/selectors'
import { ApplicationState } from '../store'
import {
  CleanTime,
  CleanTimeAttributeNames,
  CleanTimeAttributes,
  NormalizedCleanTimesApiResponse,
  OfflineCleanTime,
  RawCleanTime,
} from './cleantimes.types'

type RawCleanTimeWithAssignmentId = RawCleanTime & {
  attributes: CleanTimeAttributes & { assignmentId?: string }
}

/**
 * Empty data in the shape of the CleanTimes service's `normalized` payload.
 * Use this as a fallback when there are issues with the API data being undefined or malformed.
 */
export const emptyNormalizedCleanTimesData = Object.freeze({
  assignment: {},
  cleanTime: {},
})

/**
 * Empty data to use as a "safety net" any time an API response is undefined for any reason
 */
export const emptyCleanTimesResponse: NormalizedCleanTimesApiResponse =
  Object.freeze({
    normalized: emptyNormalizedCleanTimesData,
    raw: { data: [] },
  })

export const transformRawCleanTime = (
  rawCleanTime: RawCleanTime | OfflineCleanTime,
): CleanTime =>
  transformNormalizedToTyped<CleanTime>(
    rawCleanTime as RawCleanTime,
    CleanTimeAttributeNames,
  )

/** Get the assignmentId from the RawClean, if it does not exist as a relationship,
 * check if exists as an attribute (from offline timers) and return that.
 * If neither exist, return undefined
 */
const getAssignmentId = (
  rawCleanTime: RawCleanTime | RawCleanTimeWithAssignmentId,
) => {
  return (
    findRelationshipId(rawCleanTime, 'assignment') ||
    (rawCleanTime as RawCleanTimeWithAssignmentId)?.attributes?.assignmentId
  )
}

export const hydrateRawCleanTime =
  (state: ApplicationState) =>
  (rawCleanTime: RawCleanTime | RawCleanTimeWithAssignmentId): CleanTime => {
    const cleanTime = transformRawCleanTime(rawCleanTime)
    const assignmentId = getAssignmentId(rawCleanTime)

    if (!assignmentId) {
      throw new Error(
        `cleanTime ${cleanTime.id} has invalid assignment ID: ${assignmentId}`,
      )
    }

    try {
      cleanTime.assignment = getAssignmentById(state, assignmentId)
    } catch (err) {
      // HACK: in cases where a clean has been re-assigned, but the user still has
      // lingering cleantimes referencing it, we will append the ID alone as a "fake"
      // assignment so the app doesn't crash. The ID is all we actually need here anyway,
      // and this will let us safely handle the issue, in the absence of a better solution.
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      cleanTime.assignment = { id: assignmentId } as Assignment
    }

    return cleanTime
  }
