import { produce } from 'immer'

import { TimerAction, ActionType, TimerSubmissionData } from './timers.actions'
import { UiState } from './timers.types'

export type TimerState = {
  hasBeenOffline: boolean
  loadingTimerId: string | null
  submissionData: TimerSubmissionData | null
  uiState: UiState
}

export const initialState: TimerState = {
  hasBeenOffline: false,
  loadingTimerId: null,
  submissionData: null,
  uiState: UiState.Idle,
}

export const reducer = (state: TimerState, action: TimerAction): TimerState =>
  produce(state, draft => {
    switch (action.type) {
      case ActionType.SET_UI_STATE: {
        const newUiState = action.data.uiState
        if (state.uiState !== newUiState) {
          draft.uiState = newUiState
        }

        return draft
      }

      case ActionType.SET_ONLINE_STATUS: {
        draft.hasBeenOffline = action.data.newHasBeenOffline
        return draft
      }

      case ActionType.SET_LOADING_TIMER: {
        draft.loadingTimerId = action.data.timerId
        return draft
      }

      case ActionType.SET_SUBMISSION_DATA: {
        draft.submissionData = action.data
        return draft
      }

      case ActionType.CLEAR_SUBMISSION_TIMER: {
        draft.submissionData = null
        return draft
      }

      default:
        return draft
    }
  })
