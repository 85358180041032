import styled from '@emotion/styled'
import React from 'react'

import { Loader, LoaderAlignment } from 'packages/common'
import { IconName, SvgIcon } from 'packages/iconic'
import { centerWithFlex, colors, text } from 'packages/styles'
import {
  createDateString,
  DateFormat,
  formatLocalized,
} from 'packages/utils/dateHelpers'
import { AsyncState } from 'packages/utils/hooks'

import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { Unit } from 'app/fieldapp/store/units'

import * as SharedSt from '../CreateVisit.styles'

const St = {
  IconWrapper: styled.div`
    ${centerWithFlex};
    grid-gap: 8px;
  `,
  InspectionDetail: styled.div`
    align-items: center;
    background-color: ${colors.midnight4};
    border: 1px solid ${colors.midnight20};
    border-radius: 3px;
    display: flex;
    height: 48px;
    justify-content: space-between;
    padding: 8px;
  `,
  InspectionType: styled.div`
    ${text.bodyBoldTiny};
    padding-top: 1px; // for better visual centering with icon
    text-transform: uppercase;
  `,
  LoaderWrapper: styled.div`
    height: 20px;
    position: relative;
  `,
  LoadingText: styled.div`
    ${text.bodyRegularSmall};
  `,
  PastInspections: SharedSt.FormSection,
  SectionHeader: SharedSt.SectionHeader,
  Subtitle: styled(SharedSt.Text)`
    margin: 16px 0;
  `,
  Timestamp: styled.div`
    ${text.bodyRegularTiny};
  `,
}

const useTranslations = () => {
  const { tp, ut } = useI18n()
  return {
    error: ut(Slugs.cannotLoadDate),
    lastCompleted: ut(Slugs.lastCompleted),
    loading: ut(Slugs.loading),
    noActivity: ut(Slugs.noActivity),
    subtitle: ut(Slugs.addVisitInspectionSubtitle),
    title: tp(Slugs.inspection, 2, false),
    type: ut(Slugs.routine),
  }
}

// minor helper hook to deal with the multiple possible states of "last completed" text
const useParseDateText = (
  unit: PastInspectionsProps['unit'],
  hasError: boolean,
) => {
  const strings = useTranslations()

  if (hasError) return strings.error

  const { lastInspectionCompletedAt } = unit?.statistics || {}
  const date =
    lastInspectionCompletedAt && createDateString(lastInspectionCompletedAt)

  return date
    ? formatLocalized(date, DateFormat.MonthShortDateAndYear)
    : strings.noActivity
}

export type PastInspectionsProps = {
  fetchUnitState: AsyncState<unknown>
  unit: Unit | undefined
}

export const PastInspections: React.FC<PastInspectionsProps> = React.memo(
  ({ fetchUnitState, unit }) => {
    const strings = useTranslations()
    const { error, loading } = fetchUnitState

    const lastCompletedText = useParseDateText(unit, !!error)

    const canRender = !!error || loading || unit?.id
    if (!canRender) return null

    return (
      <St.PastInspections>
        <St.SectionHeader>{strings.title}</St.SectionHeader>

        {loading ? (
          <>
            <St.LoadingText>{strings.loading}</St.LoadingText>
            <St.LoaderWrapper>
              <Loader
                alignment={LoaderAlignment.left}
                color={colors.dusk}
                size={8}
              />
            </St.LoaderWrapper>
          </>
        ) : (
          <>
            <St.Subtitle>{strings.subtitle}</St.Subtitle>
            <St.InspectionDetail>
              <St.IconWrapper>
                <SvgIcon icon={IconName.homePast} size={15} />
                <St.InspectionType>{strings.type}</St.InspectionType>
              </St.IconWrapper>
              <St.Timestamp>
                {strings.lastCompleted}: {lastCompletedText}
              </St.Timestamp>
            </St.InspectionDetail>
          </>
        )}
      </St.PastInspections>
    )
  },
)
