import styled from '@emotion/styled'

import { Card } from 'packages/common'
import { colors, text, truncateTextWithEllipsis } from 'packages/styles'

export const ReviewCard = styled(Card)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto; // Responsive height based on content
  max-height: 60vh; // Slightly more space if content is large
  border-radius: 4px;
  margin: 10px 0; // More vertical space between cards
  padding: 0;
  border: 0px;
  background-color: ${colors.white};
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15); // Softer shadow for a more subtle effect
`
export const TinyCard = styled(Card)`
  width: 100%;
  height: auto; // Responsive height based on content
  max-height: 60vh; // Slightly more space if content is large
  border-radius: 4px;
  margin: 10px 0; // More vertical space between cards
  padding: 0;
  border: 0px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15); // Softer shadow for a more subtle effect
`

export const CardContent = styled.div`
  padding: 15px; // Increased padding for more internal space
  display: flex;
  flex-direction: column;
`

export const ReviewTitle = styled.div`
  ${text.bodyBoldRegular};
  ${truncateTextWithEllipsis};
  margin-bottom: 5px; // More space between the title and content
`

export const ReviewContent = styled.div`
  ${text.bodyRegularTiny};
  ${truncateTextWithEllipsis};
  flex-grow: 1; // Takes up remaining space, giving more room to content
  margin-bottom: 5px; // More space above the rating
`

export const Rating = styled.div`
  ${text.bodyRegularSmall};
  color: ${colors.dusk};
  margin-bottom: 5px; // Space above the date or below the rating
  display: flex;
  align-items: center;
`

export const ReviewDate = styled.div`
  ${text.bodyRegularSmall};
  color: ${colors.midnight};
  align-self: flex-start;
`
