//eslint-disable-next-line
import { isSameDay, parseISO } from 'date-fns/fp'
import { filter, map, pipe, prop, values } from 'lodash/fp'
import { createSelector } from 'reselect'

import { ApplicationState } from '../../store'
import { hydrateRawVisit } from '../visits.utils'

const filterVisitsByDay = (date: Date) =>
  pipe(prop('attributes.effectiveDate'), parseISO, isSameDay(date))

export const getVisitsOnDate = createSelector(
  (state: ApplicationState): ApplicationState['visits'] => state.visits,
  (_, date: Date) => date,
  (state, date) =>
    pipe(
      prop('data'),
      values,
      filter(filterVisitsByDay(date)),
      map(hydrateRawVisit),
    )(state),
)
