import { produce } from 'immer'
import { merge } from 'lodash/fp'
import { ActionType, getType } from 'typesafe-actions'

import { fetchCleanByIdAction, fetchCleansAction } from '../cleans/actions'
import { fetchTicketByIdAction, fetchTicketsAction } from '../tickets/actions'
import { fetchUnitByIdAction, searchUnitsAction } from '../units/actions'
import { fetchVisitByIdAction, fetchVisitsAction } from '../visits/actions'
import { UnitsState } from './units.types'
import { emptyNormalizedUnitsData } from './units.utils'

export const initialState: UnitsState = {
  data: {},
  searchResults: {
    unit: {},
    zone: {},
  },
}

const actions = {
  fetchCleanByIdAction,
  fetchCleansAction,
  fetchTicketByIdAction,
  fetchTicketsAction,
  fetchUnitByIdAction,
  fetchVisitByIdAction,
  fetchVisitsAction,
  searchUnitsAction,
}

type Actions = ActionType<typeof actions>

export const unitsReducer = (
  state = initialState,
  action: Actions,
): UnitsState =>
  produce(state, (draft: UnitsState) => {
    switch (action.type) {
      case getType(fetchVisitByIdAction.success):
      case getType(fetchVisitsAction.success):
      case getType(fetchCleanByIdAction.success):
      case getType(fetchCleansAction.success):
      case getType(fetchTicketsAction.success):
      case getType(fetchUnitByIdAction.success):
      case getType(fetchTicketByIdAction.success): {
        const normalized = action.payload.normalized || emptyNormalizedUnitsData

        if (normalized.unit) {
          Object.values(normalized.unit).forEach(incomingUnit => {
            const existingUnit = state.data[incomingUnit.id] || {}
            const mergedUnit = merge(existingUnit, incomingUnit)
            draft.data[incomingUnit.id] = mergedUnit
          })
        }

        return
      }

      case getType(searchUnitsAction.success): {
        const normalized =
          action.payload?.normalized || emptyNormalizedUnitsData

        draft.searchResults.unit = normalized.unit
        draft.searchResults.zone = normalized.zone

        return
      }
    }
  })
