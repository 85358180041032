import styled from '@emotion/styled'
import React from 'react'

import {
  BannerTestIds,
  Drawer,
  DrawerContainerProps,
  LegacyModal2TestIds,
  LIGHTBOX_CLASS_SELECTOR,
} from 'packages/common'

import { UseTicket } from 'app/fieldapp/components/tickets/hooks'
import { TEST_ID_TIMERS } from 'app/fieldapp/components/timers'

import { CREATE_TICKET_DRAWER_ID } from '../common'
import {
  VisitTicketDetails,
  VisitTicketDetailsProps,
} from './VisitTicketDetails'

export const VISIT_TICKET_DRAWER_ID = 'VisitTicketDrawer'

const St = {
  Drawer: styled(Drawer)`
    width: min(420px, 100vw);
  `,

  Header: styled.div`
    align-items: center;
    display: flex;
    height: 48px;
    padding-left: 24px;
  `,
}

const ignoreClickEventsOn: DrawerContainerProps['ignoreClickEventsOn'] = [
  `#${TEST_ID_TIMERS}`,
  LIGHTBOX_CLASS_SELECTOR,
  `#${BannerTestIds.container}`,
  `#${CREATE_TICKET_DRAWER_ID}`,
  `#${CREATE_TICKET_DRAWER_ID}__backdrop`,
  `#${VISIT_TICKET_DRAWER_ID}`,
  `#${LegacyModal2TestIds.container}`,
]

type PickedDrawerProps = Pick<DrawerContainerProps, 'afterExit' | 'isOpen'>
type PickedDetailsProps = Pick<
  VisitTicketDetailsProps,
  | 'completeTicketRequestState'
  | 'onCompleteTicketClick'
  | 'ticketTimerProps'
  | 'user'
  | 'visit'
>
export type VisitTicketDrawerProps = PickedDrawerProps &
  PickedDetailsProps & {
    ticketDetails: UseTicket
  }

export const VisitTicketDrawer: React.FC<VisitTicketDrawerProps> = React.memo(
  ({
    afterExit,
    completeTicketRequestState,
    isOpen,
    onCompleteTicketClick,
    ticketDetails,
    ticketTimerProps,
    user,
    visit,
  }) => {
    const { assignee, ticket, ticketCommentIds, unit } = ticketDetails

    const isAssignedToCurrentUser = !!user?.id && assignee?.id === user?.id

    if (!ticket || !unit) return null

    return (
      <St.Drawer
        afterExit={afterExit}
        id={VISIT_TICKET_DRAWER_ID}
        ignoreClickEventsOn={ignoreClickEventsOn}
        isOpen={isOpen}
      >
        <VisitTicketDetails
          assignee={assignee}
          completeTicketRequestState={completeTicketRequestState}
          isAssignedToCurrentUser={isAssignedToCurrentUser}
          onCompleteTicketClick={onCompleteTicketClick}
          ticket={ticket}
          ticketCommentIds={ticketCommentIds}
          ticketTimerProps={ticketTimerProps}
          unit={unit}
          user={user}
          visit={visit}
        />
      </St.Drawer>
    )
  },
)
