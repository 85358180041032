import classNames from 'classnames'
import * as React from 'react'

import { Slugs, useI18n } from 'app/fieldapp/i18n'

import { PausedTimersList } from './PausedTimersList'
import { usePausedTimers } from './usePausedTimers'

import styles from './PausedTimers.module.scss'

export enum PausedTimersTestIds {
  header = 'PausedTimers__header',
}

export const PausedTimers: React.FC = React.memo(() => {
  const { t } = useI18n()

  const {
    expanded,
    isLoading,
    offlineTimers,
    requestPending,
    toggleExpanded,
    totalTimersCount,
    wrapperStyles,
  } = usePausedTimers()

  return (
    <div
      className={classNames(styles.pausedTimersContent, {
        [styles.expanded]: expanded,
      })}
    >
      <div
        className={styles.pausedTimersHeader}
        data-testid={PausedTimersTestIds.header}
      >
        <div className={classNames(styles.pausedText)}>
          {`${t(Slugs.savedOffline)} (${totalTimersCount})`}
        </div>

        <div
          className={classNames(styles.showMoreText, {
            [styles.visible]: totalTimersCount > 0,
          })}
          onClick={toggleExpanded}
        >
          {expanded ? `- ${t(Slugs.showLess)}` : `+ ${t(Slugs.showMore)}`}
        </div>
      </div>

      {totalTimersCount ? (
        <PausedTimersList
          isLoading={isLoading}
          pausedCleanTimes={offlineTimers.cleanTimes}
          pausedOtherTimers={offlineTimers.otherTimers}
          pausedTicketTimes={offlineTimers.ticketTimes}
          requestPending={requestPending}
          wrapperStyles={wrapperStyles}
        />
      ) : null}
    </div>
  )
})
