/*
 * Maps each hour of the day to a string representation.
 */
export const twentyFourHourMap = {
  0: '12:00 AM',
  100: '01:00 AM',
  1000: '10:00 AM',
  1030: '10:30 AM',
  1100: '11:00 AM',
  1130: '11:30 AM',
  1200: '12:00 PM',
  1230: '12:30 PM',
  130: '01:30 AM',
  1300: '01:00 PM',
  1330: '01:30 PM',
  1400: '02:00 PM',
  1430: '02:30 PM',
  1500: '03:00 PM',
  1530: '03:30 PM',
  1600: '04:00 PM',
  1630: '04:30 PM',
  1700: '05:00 PM',
  1730: '05:30 PM',
  1800: '06:00 PM',
  1830: '06:30 PM',
  1900: '07:00 PM',
  1930: '07:30 PM',
  200: '02:00 AM',
  2000: '08:00 PM',
  2030: '08:30 PM',
  2100: '09:00 PM',
  2130: '09:30 PM',
  2200: '10:00 PM',
  2230: '10:30 PM',
  230: '02:30 AM',
  2300: '11:00 PM',
  2330: '11:30 PM',
  30: '12:30 AM',
  300: '03:00 AM',
  330: '03:30 AM',
  400: '04:00 AM',
  430: '04:30 AM',
  500: '05:00 AM',
  530: '05:30 AM',
  600: '06:00 AM',
  630: '06:30 AM',
  700: '07:00 AM',
  730: '07:30 AM',
  800: '08:00 AM',
  830: '08:30 AM',
  900: '09:00 AM',
  930: '09:30 AM',
} as const

/** Takes an iso timestamp such as "13:53:00" and returns a time string with the shape: `01:53 PM` */
export function parseTimestamp(timestamp: string): string {
  const [hour, minute, _] = timestamp.split(':')
  const hourNumber = Number(hour)
  const hourString = (() => {
    if (hourNumber === 0) return '12'

    let theHourString = `${hourNumber <= 12 ? hourNumber : hourNumber - 12}`

    if (theHourString.length < 2) {
      theHourString = `0${theHourString}`
    }

    return theHourString
  })()
  const ampm = hourNumber < 12 ? 'AM' : 'PM'

  return `${hourString}:${minute} ${ampm}`
}

type Time24HourBase = {
  hourIndex24: number
  minuteIndex: number
}

export function parseIntToTimestamp(int: number): string {
  const str = String(int)

  // 12:00 AM and 12:30 AM are the only times that are not 3 or 4 characters long
  const isMidnightTime = str === '0' || str === '30'

  if ((str.length < 3 || str.length > 4) && !isMidnightTime) {
    throw new Error('Invalid integer format time')
  }

  if (str === '0') {
    return '00:00'
  }

  if (str === '30') {
    return '00:30'
  }

  if (str.length === 3) {
    return `0${str.slice(0, 1)}:${str.slice(1)}`
  }

  return `${str.slice(0, 2)}:${str.slice(2)}`
}

/** Takes an iso timestamp such as "13:53:00" a `Time24HourBase` */
export function parseTimestampTo24HourIndex(timestamp: string): Time24HourBase {
  const [hour, minute, _] = timestamp.split(':')
  const hourNumber = Number(hour)

  return {
    hourIndex24: hourNumber,
    minuteIndex: Number(minute),
  }
}

export function isBefore(time1: string, time2: string): boolean {
  const [hour1, minute1, _] = time1.split(':')
  const [hour2, minute2, _2] = time2.split(':')

  if (Number(hour1) < Number(hour2)) return true
  if (Number(hour1) > Number(hour2)) return false

  return Number(minute1) < Number(minute2)
}
