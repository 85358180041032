import { createAsyncThunk } from '@reduxjs/toolkit'
import { produce } from 'immer'

import { format, DateFormat } from 'packages/utils/dateHelpers'
import { RequestOptions } from 'packages/utils/store/jsonapi.types'

import { UserApiFieldsWithoutPII } from '../../users'
import { coverageService } from '../coverage.service'
import { CoverageApiFields } from '../coverage.types'

export const getParams = (
  dateRange: [string, string],
  userId?: string,
): RequestOptions => ({
  fields: {
    coverage: CoverageApiFields,
    requestedUser: UserApiFieldsWithoutPII,
    user: UserApiFieldsWithoutPII,
  },
  filter: produce({}, (draft: Record<string, unknown>) => {
    draft.date_range = dateRange.map(date =>
      format(date, DateFormat.ApiUtcShort),
    )

    if (userId) {
      draft.user = userId
    }

    return draft
  }),
  include: ['cross_coverage', 'requested_user', 'user'],
  page: { size: 500 },
})

type FetchCoverageByDateRangeParams = {
  dateRange: [string, string]
  userId?: string
}

export const fetchCoverageByDateRange = createAsyncThunk(
  'coverage/api/fetchCoverageByDateRange',
  async ({ dateRange, userId }: FetchCoverageByDateRangeParams) => {
    const params = getParams(dateRange, userId)
    const response = await coverageService.fetchCoverage(params)
    return response
  },
)
