import { Global as GlobalStyles, css } from '@emotion/react'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import {
  initializeSentry,
  SentryContextProvider,
  SentryErrorBoundary,
} from 'packages/common/src/Sentry'
import { Iconic } from 'packages/iconic'
import { CssReset } from 'packages/styles'
import { colors } from 'packages/styles'

import 'react-toastify/dist/ReactToastify.css'

// import reportWebVitals from '../../reportWebVitals'
import FieldApp from './FieldApp'
import { initializeDatadogRum } from './initializeDatadogRum'
import fieldappStore from './store/store'

const globalStyles = css`
  body {
    background-color: ${colors.midnight4};
  }
`

const sentryIsActive = initializeSentry()
initializeDatadogRum()

const container = document.getElementById('root')

if (container) {
  const root = createRoot(container) // createRoot(container) if you use TypeScript

  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <Provider store={fieldappStore}>
          <SentryContextProvider value={{ sentryIsActive }}>
            <SentryErrorBoundary boundary={'global'}>
              <Iconic />
              <CssReset />
              <GlobalStyles styles={globalStyles} />
              <FieldApp />
            </SentryErrorBoundary>
          </SentryContextProvider>
        </Provider>
      </BrowserRouter>
    </React.StrictMode>,
  )
} else {
  throw new Error('Root container not found')
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
