import { produce } from 'immer'

import { User } from 'packages/grimoire'
import { DayOfWeek, daysOfWeek } from 'packages/grimoire/src/utils'
import {
  createDateObject,
  isBefore,
  isSameDay,
  addDays,
  isAfter,
} from 'packages/utils/dateHelpers'

import {
  defaultEndTime,
  defaultStartTime,
} from '../../utils/CrossCoverageDrawer.helpers'
import { FormValidationError } from '../../utils/CrossCoverageState.helpers'
import { TimeOption } from '../components/TimeSelect'

export const initialState = (
  coveragePartner: User | undefined,
): CrossCoverageFormState => {
  return {
    endDate: createDateObject(),
    endTime: defaultEndTime(),
    formValidationError: undefined,
    isAssigneeBeingOverridden: false,
    isEditable: true,
    isRepeating: null,
    repeatDays: [],
    selectedAssignee: coveragePartner,
    startDate: createDateObject(),
    startTime: defaultStartTime(createDateObject()),
    systemAssignedUserId: undefined,
  }
}

export type CrossCoverageFormDispatch = React.Dispatch<CrossCoverageFormAction>

export type CrossCoverageFormState = {
  crossCoverageId?: string
  endDate: Date
  endTime: TimeOption
  formValidationError: FormValidationError | undefined
  isAssigneeBeingOverridden: boolean
  isEditable?: boolean
  isRepeating: null | boolean
  repeatDays: DayOfWeek[]
  selectedAssignee: User | undefined
  startDate: Date
  startTime: TimeOption
  systemAssignedUserId: string | undefined
}

export type CrossCoverageFormAction = SingleTaskAction | MultiTaskAction

export type MultiTaskAction =
  | {
      payload: CrossCoverageFormState['isRepeating']
      type: 'changeIsRepeatingAndReset'
    }
  | {
      payload: {
        endDate?: CrossCoverageFormState['endDate']
        endTime?: CrossCoverageFormState['endTime']
        startDate?: CrossCoverageFormState['startDate']
        startTime?: CrossCoverageFormState['startTime']
      }
      type: 'setDatesAndTimes'
    }

export type SingleTaskAction =
  | {
      payload: CrossCoverageFormState['isRepeating']
      type: 'setIsRepeating'
    }
  | {
      payload: CrossCoverageFormState['formValidationError']
      type: 'setFormValidationError'
    }
  | {
      payload: CrossCoverageFormState['selectedAssignee']
      type: 'setSelectedAssignee'
    }
  | {
      payload: boolean
      type: 'setIsAssigneeBeingOverridden'
    }
  | {
      payload: {
        changedDayOfWeek: DayOfWeek
        repeatDays: DayOfWeek[]
      }
      type: 'setRepeatDays'
    }
  | { type: 'reset' | 'clearFormValidationError' }

/**
 * Reducer for the cross coverage form
 */
export const crossCoverageFormReducer = (
  state: CrossCoverageFormState,
  action: CrossCoverageFormAction,
): CrossCoverageFormState => {
  return produce(state, draft => {
    const standardReset = () => {
      const today = createDateObject()
      draft.startDate = today
      draft.startTime = defaultStartTime(today)
      draft.endDate = today
      draft.endTime = defaultEndTime()
      draft.selectedAssignee = undefined
      draft.formValidationError = undefined
      draft.repeatDays = []
      draft.isAssigneeBeingOverridden = false
      return
    }

    switch (action.type) {
      case 'setIsRepeating':
        draft.isRepeating = action.payload
        if (action.payload) {
          // if we have repeating coverage, default to ending tomorrow
          draft.endDate = addDays(createDateObject(), 1)
        }

        draft.formValidationError = undefined
        return
      case 'setFormValidationError':
        draft.formValidationError = action.payload
        return
      case 'clearFormValidationError':
        draft.formValidationError = undefined
        return
      case 'setSelectedAssignee':
        draft.selectedAssignee = action.payload
        draft.formValidationError = undefined
        return
      case 'setIsAssigneeBeingOverridden':
        draft.isAssigneeBeingOverridden = action.payload
        return
      case 'changeIsRepeatingAndReset': {
        standardReset()
        draft.isRepeating = action.payload
        return
      }

      case 'setRepeatDays': {
        const repeatDays = action.payload.repeatDays
        const prevRepeatDays = state.repeatDays

        const isRemovingSelection = prevRepeatDays.some(
          dayOfWeek => dayOfWeek === action.payload.changedDayOfWeek,
        )

        // // If the user is unselecting a day, we want to remove the day
        const selectedDays = isRemovingSelection
          ? prevRepeatDays.filter(
              dayOfWeek => dayOfWeek !== action.payload.changedDayOfWeek,
            )
          : repeatDays

        // Sort the days by their ordinal value so they're always in week-order
        draft.repeatDays = [...selectedDays].sort(
          (optionA: DayOfWeek, optionB: DayOfWeek) =>
            daysOfWeek.indexOf(optionA) - daysOfWeek.indexOf(optionB),
        )

        draft.formValidationError = undefined

        return
      }

      case 'reset': {
        standardReset()
        return
      }

      case 'setDatesAndTimes': {
        // SET DATES AND TIMES
        if (action.payload.endDate) {
          draft.endDate = action.payload.endDate
        }

        if (action.payload.endTime) {
          draft.endTime = action.payload.endTime
        }

        if (action.payload.startDate) {
          draft.startDate = action.payload.startDate
        }

        if (action.payload.startTime) {
          draft.startTime = action.payload.startTime
        }

        const today = createDateObject()

        if (
          isSameDay(draft.startDate, today) &&
          draft.startTime.hourOfDay < defaultStartTime(today).hourOfDay
        ) {
          draft.startTime = defaultStartTime(today)
        }

        if (
          isSameDay(draft.endDate, today) &&
          draft.endTime.hourOfDay < defaultStartTime(today).hourOfDay
        ) {
          draft.endTime = defaultEndTime()
        }

        // Handle changes if end date is before start date
        if (isBefore(draft.endDate, draft.startDate)) {
          draft.startDate = draft.endDate
          draft.startTime = defaultStartTime(draft.endDate)
          draft.endTime = defaultEndTime()
        }

        // VALIDATE NEW DATES AND TIMES
        const getEndTimeIsAfterStartTime = () => {
          if (draft.isRepeating) {
            const endTime = draft.endTime.isNextDay
              ? draft.endTime.hourOfDay + 2400
              : draft.endTime.hourOfDay

            return draft.startTime.hourOfDay < endTime
          }

          if (isAfter(draft.endDate, draft.startDate)) {
            return true
          }

          return draft.startTime.hourOfDay < draft.endTime.hourOfDay
        }

        if (!getEndTimeIsAfterStartTime()) {
          draft.formValidationError = new FormValidationError(
            'Start time must be before end time',
            {
              endTime: true,
              startTime: true,
            },
          )

          return
        }

        draft.formValidationError = undefined

        return
      }
    }
  })
}
