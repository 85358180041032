import styled from '@emotion/styled'
import React from 'react'
import { useSelector } from 'react-redux'

import { Loader } from 'packages/common'
import { useI18n } from 'packages/i18n'
import { colors, text } from 'packages/styles'
import {
  DateFormat,
  createDateString,
  format,
} from 'packages/utils/dateHelpers'

import { Slugs } from 'app/fieldapp/i18n'
import { getCoverageByDate } from 'app/fieldapp/store/coverage/selectors'
import { ApplicationState } from 'app/fieldapp/store/store'

import { CrossCoverageDrawerContainer } from '../../CrossCoverageDrawer'
import { DayScheduleTimeline } from './DayScheduleTimeline'
import {
  TimelineEntry,
  createTimelineEntry,
} from './DayScheduleTimeline/DayScheduleTimeline.utils'

const St = {
  Container: styled.div`
    position: relative;
    min-height: 150px;
  `,
  Date: styled.div`
    ${text.headingCardAlt};
    align-items: center;
    display: flex;
    gap: 8px;
    justify-content: flex-start;
  `,
  Header: styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
    gap: 6px;
  `,
  VisitsBadge: styled.span<{ hasVisits: boolean }>`
    ${text.bodyBoldSmall}
    display: inline-block;
    background: ${({ hasVisits }) =>
      hasVisits
        ? 'transparent'
        : `linear-gradient(to top left, ${colors.midnight50} 48%, white 48%, white 52%, ${colors.midnight50} 52%)`};
    border: ${({ hasVisits }) =>
      hasVisits ? `1px solid ${colors.midnight50}` : 'none'};
    color: ${({ hasVisits }) => (hasVisits ? colors.midnight50 : colors.white)};
    border-radius: 4px;
    padding: 2px 8px;
  `,
}

export enum ScheduleDateDetailTestIds {
  container = 'ScheduleDateDetail__container',
}

export type ScheduleDateDetailProps = {
  date: Date
  loading: boolean
}

export const ScheduleDateDetail: React.FC<ScheduleDateDetailProps> = React.memo(
  ({ date, loading }) => {
    const { ut } = useI18n()

    const coverageEntries = useSelector((state: ApplicationState) =>
      getCoverageByDate(state, createDateString(date)),
    )

    const timelineEntries: TimelineEntry[] = React.useMemo(() => {
      return coverageEntries.map(createTimelineEntry)
    }, [coverageEntries])

    return (
      <St.Container data-testid={ScheduleDateDetailTestIds.container}>
        {loading && <Loader />}
        <St.Header>
          <St.Date>
            {format(date, DateFormat.DayNameAndDate)}{' '}
            <St.VisitsBadge
              hasVisits={coverageEntries?.[0]?.userReceivingVisits}
            >
              {ut(Slugs.visits)}
            </St.VisitsBadge>
          </St.Date>
          <CrossCoverageDrawerContainer />
        </St.Header>
        {!loading && (
          <>
            {!!timelineEntries.length && (
              <DayScheduleTimeline entries={timelineEntries} />
            )}
          </>
        )}
      </St.Container>
    )
  },
)
