import styled from '@emotion/styled'

import { Drawer } from 'packages/common'
import { centerWithFlex, colors, text } from 'packages/styles'

const St = {
  CoverageDrawerContents: styled.div`
    // the bottom padding here is to prevent drawer content from being hidden behind timers
    padding: 0 18px 96px 18px;
  `,
  CoverageRepeatInfoContent: styled.p`
    ${text.bodyRegularSmall}
  `,
  CoverageRepeatInfoHeading: styled.h3`
    ${text.fieldLabelMobile}
    align-items: center;
    display: flex;
    gap: 8px;
  `,
  Drawer: styled(Drawer)`
    width: min(420px, 100vw);
  `,
  Form: styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px 0 24px 0;
  `,
  FormControls: styled.div`
    display: flex;
    gap: 23px;
    justify-content: space-between;
    width: 100%;
  `,
  FormErrors: styled.div``,
  FormField: styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
  `,
  FormFieldLabel: styled.label`
    ${text.fieldLabelMobile}
    align-items: center;
    display: flex;
    gap: 8px;
  `,
  FormFieldLabelRequiredAsterisk: styled.span`
    color: ${colors.alert};
  `,
  FormFieldOverrideSelectButton: styled.button`
    align-items: center;
    background-color: ${colors.white};
    border-radius: 2px;
    border: 1px solid ${colors.midnight30};
    color: ${colors.dusk};
    display: flex;
    justify-content: space-between;
    min-height: 40px;
    padding: 2px 16px;

    &:after {
      color: #0d99ff;
      content: 'Override';
      font-family: 'Inter', sans-serif;
      font-size: 12px;
      font-weight: 700;
    }
  `,
  FormFieldPickerButton: styled.button`
    align-items: center;
    background-color: ${colors.white};
    border-radius: 2px;
    border: 1px solid ${colors.midnight30};
    color: ${colors.dusk};
    display: flex;
    justify-content: space-between;
    min-height: 40px;
    padding: 2px 16px;
    width: 100%;

    // Match the look of React Select's disabled state
    &:disabled {
      background-color: hsl(0, 0%, 95%);
    }

    svg {
      color: ${colors.dusk};
    }
  `,
  FormFieldPickerButtonGroup: styled.div`
    display: grid;
    grid-column-gap: 24px;
    grid-row-gap: 4px;
    grid-template-columns: calc(50% - 12px) calc(50% - 12px);
    grid-template-rows: auto auto;
    min-height: 40px;
    width: 100%;
  `,
  FormFieldPickerButtonGroupBottom: styled.span`
    font-size: 14px;
    font-style: italic;
    grid-column: 1 / span 2;
    grid-row: 2;
  `,
  Header: styled.div`
    ${centerWithFlex};
    ${text.bodyBoldSmall};
    background: ${colors.midnight10};
    color: ${colors.midnight};
    height: 92px;
  `,
  InfoIconButton: styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
  `,
  LoaderContainer: styled.div`
    min-height: 300px;
    position: relative;
    width: 100%;
  `,
  Section: styled.section`
    padding: 32px 16px 0px 16px;
  `,
  Timezone: styled.span`
    align-self: flex-end;
    color: ${colors.dusk60};
    font-style: italic;
    margin-bottom: -8px;
    margin-top: -16px;
    ${text.bodyRegularDefault}
  `,
}

export default St
