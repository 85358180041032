import styled from '@emotion/styled'
import React from 'react'

import { Card, Loader } from 'packages/common'
import { IconName, SvgIcon } from 'packages/iconic'
import { text } from 'packages/styles'
import { format } from 'packages/utils/dateHelpers'

import { ComputedWorkingStatus } from 'app/fieldapp/store/coverage/coverage.types'
import { useDayOfWeekTranslationShort } from 'app/fieldapp/utils/hooks/useDateTranslations'

const St = {
  Card: styled(Card)`
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-width: 100px;
    padding: 8px;
    position: relative;
  `,
  Date: styled.h3`
    ${text.headingDefault};
    display: inline-block;
    margin: 0;
  `,
  DayName: styled.h4`
    ${text.headingLabel};
  `,
}

export enum DayCalendarCardTestIds {
  container = 'DayCalendarCard__container',
}

const WorkingStatusIconMap: Record<ComputedWorkingStatus, { icon: IconName }> =
  {
    covered: {
      icon: IconName.dayStatusCoveredIndicator,
    },
    multiple: {
      icon: IconName.dayStatusMultipleIndicator,
    },
    oncall: {
      icon: IconName.dayStatusOncallIndicator,
    },
    requested: {
      icon: IconName.dayStatusRequestedIndicator,
    },
  }

export type DayCalendarCardProps = {
  date: Date
  isLoading: boolean
  onClick?: () => void
  workingStatus: ComputedWorkingStatus
}

export const DayCalendarCard: React.FC<DayCalendarCardProps> = React.memo(
  ({ date, isLoading, onClick, workingStatus }) => {
    const translateDate = useDayOfWeekTranslationShort()
    const iconConfig = WorkingStatusIconMap[workingStatus]
    return (
      <St.Card dataTestId={DayCalendarCardTestIds.container} onClick={onClick}>
        {isLoading && <Loader />}
        <St.Date>{format(date, 'dd')}</St.Date>
        <St.DayName>{translateDate(date)}</St.DayName>
        <SvgIcon height={8} icon={iconConfig.icon} width={25} />
      </St.Card>
    )
  },
)
