import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { getType } from 'typesafe-actions'

import { NormalizedJSONApiResponse } from 'packages/utils/store'

import { TasksResponse } from '../tasks'
import { fetchUnitAndInspectionFlagsById } from '../units/actions/fetchUnitAndInspectionFlagsById'
import { fetchVisitByIdAction } from '../visits/actions'
import { InspectionFlagState } from './inspectionFlags.types'

const initialState: InspectionFlagState = {
  data: {},
}

type RelatedActions = PayloadAction<
  NormalizedJSONApiResponse<TasksResponse>,
  string
>

const isRelatedAction = (action: RelatedActions): action is RelatedActions => {
  // Move these to the `isAnyOf` params after refactoring the action
  // creators to use createAsyncThunk
  switch (action.type) {
    case getType(fetchVisitByIdAction.success):
      return true
    default:
      return false
  }
}

export const inspectionFlagSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(
      fetchUnitAndInspectionFlagsById.fulfilled,
      (state, action) => {
        state.data = action.payload.normalized.inspectionFlag || {}
      },
    )

    builder.addMatcher(isRelatedAction, (state, action) => {
      state.data = action.payload.normalized.inspectionFlag || {}
    })
  },
  initialState,
  name: 'inspectionFlag',
  reducers: {},
})

export default inspectionFlagSlice.reducer
