import styled from '@emotion/styled'
import React from 'react'

import { centerWithFlex, colors, layers } from 'packages/styles'

import { Slugs, useI18n } from 'app/fieldapp/i18n'

const OfflineTimersBannerContainer = styled.div`
  ${centerWithFlex};

  background-color: ${colors.yellow};
  color: white;
  font-size: 12px;
  font-weight: bold;
  padding: 8px 24px;
  z-index: ${layers.modal};
`

const useTranslations = ({ timersCount }: OfflineTimersBannerProps) => {
  const { t } = useI18n()

  return {
    text: t(Slugs.offlineTimersBanner, { timerCount: timersCount }),
  }
}

export enum OfflineTimersBannerTestIds {
  container = 'OfflineTimersBanner__container',
  text = 'OfflineTimersBanner__text',
}

export type OfflineTimersBannerProps = {
  isOnline: boolean
  isSubmitting: boolean
  timersCount: number
}

export const OfflineTimersBanner: React.FC<OfflineTimersBannerProps> =
  props => {
    const strings = useTranslations(props)
    const { isOnline, timersCount, isSubmitting } = props

    const showBanner = isOnline && timersCount && !isSubmitting

    return showBanner ? (
      <OfflineTimersBannerContainer
        data-testid={OfflineTimersBannerTestIds.container}
      >
        <span data-testid={OfflineTimersBannerTestIds.text}>
          {strings.text}
        </span>
      </OfflineTimersBannerContainer>
    ) : null
  }
