import { createSelector } from 'reselect'

import { RawClean } from '../../cleans'
import { ApplicationState } from '../../store'
import { RawVisit } from '../../visits'
import { SmartLock, RawSmartLock } from '../smartlocks.types'
import { transformRawSmartLock } from '../smartlocks.utils'

/**
 * Returns a smartLock based on the provided Task ID relationship.
 *
 * This selector starts at the task and finds the SmartLock ID in that relationship,
 * vs the other which starts at the smartLock and looks up the Clean ID there.
 *
 * A couple reasons this is necessary:
 * - When a lock is included in a /tasks request, rather than fetched directly, it does not include
 *      the task ID in its own data, so we literally cannot look it up from that direction.
 * - This path is slightly more efficient, since we can look up the Task directly,
 *      rather than iterating over all smartLocks until we find a match.
 */
const getSmartLockByTaskId = (
  smartLocksState,
  tasksState,
  taskId,
): SmartLock | undefined => {
  const rawTask: RawClean | RawVisit = tasksState.data[taskId]
  if (!rawTask) return undefined

  const smartLockData = rawTask.relationships?.smartLocks?.data
  if (!smartLockData) return undefined

  const smartLockId = smartLockData[0]?.id || ''
  const rawSmartLock: RawSmartLock = smartLocksState.data[smartLockId]

  return rawSmartLock ? transformRawSmartLock(rawSmartLock) : undefined
}

export const getIncludedSmartLockByCleanId = createSelector(
  (state: ApplicationState) => state.smartLocks,
  (state: ApplicationState) => state.cleans, // Use Cleans state for this selector
  (_, visitId: string) => visitId,
  getSmartLockByTaskId,
)

export const getIncludedSmartLockByVisitId = createSelector(
  (state: ApplicationState) => state.smartLocks,
  (state: ApplicationState) => state.visits, // Use Visits state for this selector
  (_, visitId: string) => visitId,
  getSmartLockByTaskId,
)
