import { createAsyncThunk } from '@reduxjs/toolkit'
import { noop } from 'lodash/fp'

import {
  ReduxActionCallbacks,
  RequestOptions,
} from 'packages/grimoire/src/utils'
import { makeRelationship } from 'packages/utils/store'

import { setIsCoverageStale } from '../../coverage/actions'
import { fetchCurrentCoveragePartner } from '../../users/actions/fetchCurrentCoveragePartner'
import { coveragePartnerService } from '../coveragePartner.service'
import { CoveragePartnerPostData } from '../coveragePartner.types'

export const getParams = (
  postData: CoveragePartnerPostData,
): RequestOptions => ({
  data: {
    attributes: {},
    relationships: {
      user_covered: makeRelationship('user', postData.coveredUserId),
      user_covering: makeRelationship('user', postData.coveringUserId),
    },
    type: 'coverage_partner',
  },
})

type CreateCoveragePartnerThunkArgs = {
  callbacks: ReduxActionCallbacks
  postData: CoveragePartnerPostData
}

export const createCoveragePartner = createAsyncThunk(
  'coveragePartner/api/createCoveragePartner',
  async (args: CreateCoveragePartnerThunkArgs, { dispatch }) => {
    const { postData, callbacks } = args
    const { onError = noop, onSuccess = noop } = callbacks

    try {
      const params = getParams(postData)
      const response = await coveragePartnerService.createCoveragePartner(
        params,
      )
      await dispatch(fetchCurrentCoveragePartner())
      dispatch(setIsCoverageStale(true))

      onSuccess(response)
      return response
    } catch (error) {
      onError(error)
    }
  },
)
