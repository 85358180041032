import { allPass, filter, map, pipe, prop, propEq, values } from 'lodash/fp'

import { ApplicationState } from '../../store'
import { Disposition } from '../tickets.types'
import { hydrateRawDisposition } from '../tickets.utils'
export const getTierTwoMaintenanceRequestDispositions: (
  state: ApplicationState,
) => Disposition[] = pipe(
  prop('tickets.dispositions'),
  values,
  filter(
    allPass([
      propEq('attributes.tierOne', 'maintenance'),
      propEq('attributes.tierTwo', 'maintenance_request'),
    ]),
  ),
  map(hydrateRawDisposition),
)
