import { RequestOptions } from 'packages/utils/store/jsonapi.types'

import { api } from '../axiosInstance'
import { HksApiResponse, HksServiceResponse } from './housekeepers.types'
import { emptyHousekeepersResponse } from './housekeepers.utils'

const URL = '/housekeepers'

export const housekeepersService = {
  async fetchHousekeeperManagersByZone(
    params: RequestOptions = {},
  ): HksServiceResponse {
    const response: HksApiResponse = await api.get(URL, {
      params,
    })
    return response?.data ?? emptyHousekeepersResponse
  },
}
