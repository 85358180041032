import { RequestOptions } from 'packages/utils/store/jsonapi.types'

import { api } from '../axiosInstance'
import {
  TaskPhotosServiceResponse,
  TaskPhotosApiResponse,
} from './taskPhotos.types'
import { emptyTaskPhotosResponse } from './taskPhotos.utils'

const URL = '/task_photos'

export const taskPhotosService = {
  async createTaskPhoto(data: RequestOptions = {}): TaskPhotosServiceResponse {
    const response: TaskPhotosApiResponse = await api.post(URL, data)
    return response?.data || emptyTaskPhotosResponse
  },

  async fetchTaskPhotoById(
    id: string,
    params: RequestOptions = {},
  ): TaskPhotosServiceResponse {
    const response: TaskPhotosApiResponse = await api.get(`${URL}/${id}`, {
      params,
    })
    return response?.data || emptyTaskPhotosResponse
  },

  async deleteTaskPhoto(id: string): TaskPhotosServiceResponse {
    const response: TaskPhotosApiResponse = await api.delete(`${URL}/${id}`)
    return response?.data || emptyTaskPhotosResponse
  },
}
