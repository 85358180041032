import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { usePrevious } from 'react-use'

import {
  createDateObject,
  createDateObjectFromTimestamp,
  differenceInHours,
} from 'packages/utils/dateHelpers'
import { useOnlineStatus } from 'packages/utils/hooks'

import { fetchCleans } from 'app/fieldapp/store/cleans/actions'
import { getLastFetch } from 'app/fieldapp/store/cleans/selectors'
import { AppDispatch } from 'app/fieldapp/store/store'
import { fetchTickets } from 'app/fieldapp/store/tickets/actions'
import { getActiveUser } from 'app/fieldapp/store/users/selectors'
import { getCoreDataIsLoading } from 'app/fieldapp/store/utils/selectors/getCoreDataIsLoading'
import { useTimelineDateRange } from 'app/fieldapp/utils/timelineHelpers'

export type RefreshOnFocusProps = {}

export const RefreshOnFocus: React.FC<RefreshOnFocusProps> = () => {
  const dispatch: AppDispatch = useDispatch()
  const user = useSelector(getActiveUser)
  const isOnline = useOnlineStatus().isOnline()
  const isLoading = useSelector(getCoreDataIsLoading)

  // Dividing this value by 1000 gets the correct unix timestamp
  const lastFetch = useSelector(getLastFetch) / 1000
  const { startDate, endDate } = useTimelineDateRange()

  const getShouldRefresh = React.useCallback(() => {
    const lastFetchIntervalPassed =
      differenceInHours(
        createDateObject(),
        createDateObjectFromTimestamp(lastFetch),
      ) >= 1

    return !isLoading && isOnline && lastFetchIntervalPassed
  }, [isLoading, isOnline, lastFetch])

  const refetch = React.useCallback(async () => {
    try {
      if (user) {
        await Promise.all([
          dispatch(fetchTickets(user.id)),
          dispatch(fetchCleans(user.id, startDate, endDate)),
        ])
      }
    } catch (e) {
      // we don't need to do anything if an error occurs
    }
  }, [user, startDate, endDate, dispatch])

  const [focusCount, setFocusCount] = React.useState(0)

  const prevCount = usePrevious(focusCount)

  const incrementFocusCount = React.useCallback(
    () => setFocusCount(p => p + 1),
    [],
  )

  React.useEffect(() => {
    window.addEventListener('focus', incrementFocusCount)
    return () => window.removeEventListener('focus', incrementFocusCount)
  }, [incrementFocusCount])

  React.useEffect(() => {
    if (focusCount !== prevCount && getShouldRefresh()) refetch()
  }, [focusCount, prevCount, getShouldRefresh, refetch])

  return null
}
