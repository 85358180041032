import { noop } from 'lodash/fp'
import { createAsyncAction } from 'typesafe-actions'

import { ReduxActionCallbacks } from 'packages/grimoire/src/utils'
import { apiDateStringWithSeconds } from 'packages/utils/dateHelpers'
import {
  RequestConfig,
  RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import { NormalizedTasksApiResponse } from '../../tasks'
import { tasksService } from '../../tasks/tasks.service'
import { trackCleanStartedAndCompleted } from '../cleans.trackers'
import {
  CleanPatchData,
  CleanPatchApiAttributes,
  CleansActionTypes,
} from '../cleans.types'
import { fetchCleanById } from './fetchCleanById'

export const updateCleanAction = createAsyncAction(
  CleansActionTypes.UPDATE_CLEAN,
  CleansActionTypes.UPDATE_CLEAN_SUCCESS,
  CleansActionTypes.UPDATE_CLEAN_FAILURE,
)<
  RequestConfig<NormalizedTasksApiResponse>,
  NormalizedTasksApiResponse,
  Error
>()

export const buildRequestData = (patchData: CleanPatchData): RequestOptions => {
  const { completedAt, inspectionCompletedAt, jobType, startedAt } = patchData
  const attributes: Partial<CleanPatchApiAttributes> = {
    completed_at: completedAt && apiDateStringWithSeconds(completedAt),
    inspection_completed_at:
      inspectionCompletedAt && apiDateStringWithSeconds(inspectionCompletedAt),
    job_type: jobType,
    started_at: startedAt && apiDateStringWithSeconds(startedAt),
  }

  return {
    data: {
      attributes,
      id: patchData.id,
      type: 'task',
    },
  }
}

export const updateClean =
  (patchData: CleanPatchData, callbacks: ReduxActionCallbacks = {}) =>
  async (dispatch, getState) => {
    const { onSuccess = noop } = callbacks
    try {
      const cleanId = patchData.id
      const requestData = buildRequestData(patchData)
      const request = tasksService.updateTask.bind(null, cleanId, requestData)
      const result = await dispatch(updateCleanAction.request({ request }))
      dispatch(updateCleanAction.success(result))
      trackCleanStartedAndCompleted(patchData, getState)

      // re-fetch the associated clean to ensure we have the latest version locally
      await dispatch(fetchCleanById(cleanId))

      onSuccess()
      return result.normalized
    } catch (error) {
      dispatch(updateCleanAction.failure(error))
      throw error
    }
  }
