import styled from '@emotion/styled'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  DEFAULT_WILDCARD,
  splitAndInsertWildcards,
} from 'packages/utils/searchHelpers'

import { AppDispatch, ApplicationState } from 'app/fieldapp/store/store'
import { SearchUnit, Unit } from 'app/fieldapp/store/units'
import { searchUnits } from 'app/fieldapp/store/units/actions'
import { SearchUnitsOptions } from 'app/fieldapp/store/units/actions/searchUnits'
import { getUnitSearchResults } from 'app/fieldapp/store/units/selectors/getUnitSearchResults'

const UnitOption = styled.span<{ isSearchMatch: boolean }>`
  font-weight: ${({ isSearchMatch }) => (isSearchMatch ? 'bold' : 'normal')};
`
export const buildUnitString = ({
  name,
  unitCode,
  city,
  state,
}: Unit): string => {
  return `${name} (${unitCode}, ${city}, ${state})`
}

export const useUnitSearchLoader = (
  options?: SearchUnitsOptions,
): ((input: string) => Promise<SearchUnit[]>) => {
  const dispatch: AppDispatch = useDispatch()
  const getSearchResults = useSelector((state: ApplicationState) =>
    getUnitSearchResults(state),
  )

  const getSearchResultsRef = React.useRef(getSearchResults)
  React.useEffect(() => {
    getSearchResultsRef.current = getSearchResults
  }, [getSearchResults])

  return (input: string): Promise<SearchUnit[]> =>
    new Promise(async (resolve, reject) => {
      try {
        await dispatch(searchUnits(input, options))
        const val = getSearchResultsRef.current
        resolve(val)
      } catch (e) {
        reject([])
      }
    })
}

export enum UnitSelectorHelperTestIds {
  match = 'UnitSelectorHelperTestIds__match',
  noMatch = 'UnitSelectorHelperTestIds__no_match',
}

export const getHighlightedUnitSearchResults = (
  unit: Unit,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  { inputValue }, // react-select params
): React.ReactNode => {
  const searchString = inputValue.trim()

  const fullUnitString = buildUnitString(unit)

  if (!searchString) {
    return fullUnitString
  }

  const splitByMatch = splitAndInsertWildcards(fullUnitString, searchString)

  return splitByMatch.reduce((accum, val, i) => {
    if (val === DEFAULT_WILDCARD) {
      return accum // do not make a node for strings matching the wildcard
    }

    // bold it if the previous string was '*'
    const isSearchMatch = i > 0 && splitByMatch[i - 1] === DEFAULT_WILDCARD

    return accum.concat(
      <UnitOption
        data-testid={
          isSearchMatch
            ? UnitSelectorHelperTestIds.match
            : UnitSelectorHelperTestIds.noMatch
        }
        isSearchMatch={isSearchMatch}
        key={i}
      >
        {val}
      </UnitOption>,
    )
  }, [] as React.ReactNode[])
}
