import React from 'react'
import { useSelector } from 'react-redux'

import {
  createDateObject,
  createDateString,
  isBefore,
  subDays,
} from 'packages/utils/dateHelpers'
import { useOnlineStatus } from 'packages/utils/hooks'

import {
  useCleanTimerActions,
  useOfflineMergedTimers,
} from 'app/fieldapp/components/timers/hooks'
import { useTimers as useTimersContext } from 'app/fieldapp/components/timers/state'
import { getActiveUserAssignmentByClean } from 'app/fieldapp/store/assignments/selectors'
import { Clean } from 'app/fieldapp/store/cleans'
import {
  getActiveCleanTime,
  getCleanTimesRequestPending,
} from 'app/fieldapp/store/cleantimes/selectors'
import {
  getActiveOtherTimer,
  getOtherTimersRequestPending,
} from 'app/fieldapp/store/otherTimers/selectors'
import { ApplicationState } from 'app/fieldapp/store/store'
import { getActiveTicketTime } from 'app/fieldapp/store/ticket-times/selectors'

import { shouldRenderCleanTimerControls } from '../../../../schedule.utils'
import { CleanTimerControls } from './CleanTimerControls'

export type CleanTimerControlsContainerProps = {
  clean: Clean
}

export const CleanTimerControlsContainer: React.FC<CleanTimerControlsContainerProps> =
  React.memo(({ clean }) => {
    const isOnline = useOnlineStatus().isOnline()
    const cleanTimesRequestPending = useSelector(getCleanTimesRequestPending)
    const otherTimersRequestPending = useSelector(getOtherTimersRequestPending)
    const activeCleanTime = useSelector(getActiveCleanTime)
    const activeOtherTime = useSelector(getActiveOtherTimer)
    const activeTicketTime = useSelector(getActiveTicketTime)
    const assignment = useSelector((state: ApplicationState) =>
      getActiveUserAssignmentByClean(state, clean),
    )

    const { offlineTimersCount } = useOfflineMergedTimers()
    const { dispatchStartCleanTimer } = useCleanTimerActions()
    const { state: timersState } = useTimersContext()
    const { loadingTimerId, submissionData } = timersState

    const hasActiveTimer =
      !!activeCleanTime || !!activeOtherTime || !!activeTicketTime

    const hasRequestPending =
      cleanTimesRequestPending || otherTimersRequestPending

    const isMoreThan24HoursBeforeEarliestCleanStartTime = isBefore(
      createDateString(),
      subDays(clean.earliestCleanStartTime, 1),
    )

    const enableStartingTimer = React.useMemo(
      () =>
        !!assignment &&
        !isMoreThan24HoursBeforeEarliestCleanStartTime &&
        !hasActiveTimer &&
        !hasRequestPending &&
        !submissionData &&
        (!offlineTimersCount || !isOnline),
      [
        assignment,
        hasActiveTimer,
        hasRequestPending,
        isMoreThan24HoursBeforeEarliestCleanStartTime,
        isOnline,
        offlineTimersCount,
        submissionData,
      ],
    )

    const shouldRenderTimer = React.useMemo(
      () => shouldRenderCleanTimerControls(clean),
      [clean],
    )

    /**
     * Handler for clicking the "Start" button on a clean in "Not Started" state
     * This should:
     * - trigger a PATCH request on the Clean instance
     * - trigger a POST request for a new CleanTime instance
     */
    const handleStartClick = async () => {
      if (!clean || !assignment) return

      const startedAt = createDateObject()

      const newCleanTimeData = {
        assignmentId: assignment.id,
        startedAt,
      }

      const cleanUpdateData = !clean.startedAt
        ? {
            id: clean.id,
            startedAt,
          }
        : undefined

      await dispatchStartCleanTimer(newCleanTimeData, cleanUpdateData)
    }

    const isLoading = !!loadingTimerId
    const isDisabled = !enableStartingTimer && !isLoading

    if (!shouldRenderTimer) return null

    return (
      <CleanTimerControls
        isDisabled={isDisabled}
        isLoading={isLoading}
        onStartClick={handleStartClick}
      />
    )
  })
