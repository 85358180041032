import { produce } from 'immer'
import { ActionType, getType } from 'typesafe-actions'

import { getIdAndDataTypeFromAction } from 'packages/utils/store'

import { NormalizedTaskData } from '../tasks'
import { emptyNormalizedTasksData } from '../tasks/tasks.utils'
import { fetchUnitByIdAction } from '../units/actions'
import {
  createVisitAction,
  deleteVisitAction,
  fetchVisitByIdAction,
  fetchVisitsAction,
  updateVisitAction,
} from './actions'
import { RawVisit, VisitsState } from './visits.types'
import { isRawVisit } from './visits.utils'

export const initialState: VisitsState = Object.freeze({
  data: {},
  loading: true,
  unitAssociatedVisits: {},
})

const actions = {
  createVisitAction,
  deleteVisitAction,
  fetchUnitByIdAction,
  fetchVisitByIdAction,
  fetchVisitsAction,
  updateVisitAction,
}

type VisitsActionsTypes = ActionType<typeof actions>

export const visitsReducer = (
  state = initialState,
  action: VisitsActionsTypes,
): VisitsState =>
  produce(state, (draft: VisitsState) => {
    switch (action.type) {
      case getType(deleteVisitAction.request):
      case getType(updateVisitAction.request):
      case getType(fetchVisitsAction.request): {
        draft.loading = true
        return
      }

      case getType(fetchVisitsAction.success): {
        const normalizedTaskData: NormalizedTaskData['task'] =
          action?.payload?.normalized?.task || emptyNormalizedTasksData.task

        draft.data = {}

        Object.values(normalizedTaskData).forEach(task => {
          if (isRawVisit(task)) {
            draft.data[task.id] = task
          }
        })

        draft.loading = false
        return
      }

      case getType(fetchVisitByIdAction.success): {
        const [id, visit] = getIdAndDataTypeFromAction<RawVisit>(action, 'task')
        draft.data[id] = visit
        draft.loading = false
        return
      }

      case getType(fetchUnitByIdAction.success): {
        const normalizedTaskData =
          action?.payload?.normalized?.task || emptyNormalizedTasksData.task

        draft.unitAssociatedVisits = {
          ...state.unitAssociatedVisits,
          ...normalizedTaskData,
        }

        draft.loading = false
        return
      }

      case getType(deleteVisitAction.success): {
        const { id } = action?.payload || ''
        if (id) {
          delete draft.data[id]
        }

        return
      }

      case getType(updateVisitAction.failure):
      case getType(fetchVisitsAction.failure):
      case getType(fetchVisitByIdAction.failure): {
        draft.loading = false
        return
      }
    }
  })
