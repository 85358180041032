import { createAsyncAction } from 'typesafe-actions'

import { RequestConfig } from 'packages/utils/store'

import { taskPhotosService } from '../taskPhotos.service'
import {
  TaskPhotosActionTypes,
  NormalizedTaskPhotosApiResponse,
} from '../taskPhotos.types'

export const deleteTaskPhotoAction = createAsyncAction(
  TaskPhotosActionTypes.DELETE_TASK_PHOTO,
  TaskPhotosActionTypes.DELETE_TASK_PHOTO_SUCCESS,
  TaskPhotosActionTypes.DELETE_TASK_PHOTO_FAILURE,
)<RequestConfig<NormalizedTaskPhotosApiResponse>, { id: string }, Error>()

export const deleteTaskPhoto = (id: string) => async dispatch => {
  try {
    const request = taskPhotosService.deleteTaskPhoto.bind(null, id)
    const result = await dispatch(deleteTaskPhotoAction.request({ request }))

    // Here result is empty, so we need to create a payload with the id
    dispatch(deleteTaskPhotoAction.success({ id }))

    return result.normalized
  } catch (error) {
    dispatch(deleteTaskPhotoAction.failure(error))
    throw error
  }
}
