import styled from '@emotion/styled'
import React from 'react'

import { Alert, Button, Drawer, useManageDrawerState } from 'packages/common'
import { useI18n } from 'packages/i18n'
import { IconName } from 'packages/iconic'
import { colors } from 'packages/styles'
import { useOnlineStatus } from 'packages/utils/hooks'

import {
  DetailIcon,
  SectionSubTitle,
} from 'app/fieldapp/components/schedule/components/TaskSharedStyles'
import { Slugs } from 'app/fieldapp/i18n'
import { CreateTicketDrawerUnit } from 'app/fieldapp/store/units'

import { DetailTickets } from '../../DetailsTickets'
import { CreateTicketFormContainer } from '../CreateTicketForm/CreateTicketForm.container'

export const CREATE_TICKET_DRAWER_ID = 'CreateTicketDrawer'

type DrawerStyleProps = Pick<CreateTicketDrawerProps, 'isDrawerSection'>

const St = {
  Alert: styled(Alert)`
    margin-top: 12px;
  `,
  CreateTicketDrawer: styled.div<DrawerStyleProps>`
    display: flex;
    flex-direction: row;
    margin-bottom: 24px;
    margin-top: ${({ isDrawerSection }) => (isDrawerSection ? 0 : 16)}px;
    padding-left: ${({ isDrawerSection }) => (isDrawerSection ? 8 : 0)}px;
  `,
  DetailIcon,
  Details: styled.div<DrawerStyleProps>`
    padding-left: ${({ isDrawerSection }) => (isDrawerSection ? 8 : 0)}px;
    width: 100%;
  `,
  Divider: styled.hr`
    color: ${colors.midnight20};
    margin: 8px 0 24px 0;
  `,
  Drawer: styled(Drawer)`
    max-width: 480px;
    min-width: min(360px, 100%);
  `,
  DrawerContentsContainer: styled.div`
    min-height: 100vh;
    padding-bottom: 168px;
  `,
  SectionSubTitle,
}

export enum CreateTicketDrawerTestIds {
  alert = 'CreateTicketDrawer__alert',
  button = 'CreateTicketDrawer__button',
  header = 'CreateTicketDrawer__header',
}

export type CreateTicketDrawerProps = {
  className?: string
  isDrawerSection?: boolean
  taskId?: string
  unit?: CreateTicketDrawerUnit
}

export const CreateTicketDrawer: React.FC<CreateTicketDrawerProps> = ({
  className,
  isDrawerSection = true,
  taskId,
  unit,
}) => {
  const { completeDrawerClose, closeDrawer, drawerState, openDrawer } =
    useManageDrawerState()

  const { ut } = useI18n()
  const isOnline = useOnlineStatus().isOnline()

  const [selectedUnit, setSelectedUnit] = React.useState(unit)

  const selectedUnitId = selectedUnit?.id || ''

  return (
    <>
      <St.CreateTicketDrawer
        className={className}
        isDrawerSection={isDrawerSection}
      >
        {isDrawerSection && <St.DetailIcon icon={IconName.eye} size={16} />}

        <St.Details isDrawerSection={isDrawerSection}>
          {isDrawerSection && (
            <St.SectionSubTitle data-testid={CreateTicketDrawerTestIds.header}>
              {ut(Slugs.seeAnIssue)}
            </St.SectionSubTitle>
          )}

          {isOnline ? (
            <Button
              block={!isDrawerSection}
              buttonType={'utility'}
              dataTestId={CreateTicketDrawerTestIds.button}
              onClick={openDrawer}
            >
              {ut(Slugs.createTicket)}
            </Button>
          ) : (
            <St.Alert
              alertType="warning"
              dataTestId={CreateTicketDrawerTestIds.alert}
            >
              {ut(Slugs.cannotCreateTicket)}
            </St.Alert>
          )}
        </St.Details>
      </St.CreateTicketDrawer>

      <St.Drawer
        afterExit={completeDrawerClose}
        forceClose={drawerState.forceClose}
        id={CREATE_TICKET_DRAWER_ID}
        isOpen={drawerState.isOpen}
      >
        <St.DrawerContentsContainer>
          <CreateTicketFormContainer
            closeForm={closeDrawer}
            onSetSelectedUnit={setSelectedUnit}
            selectedUnit={selectedUnit}
            taskId={taskId}
            unit={unit}
          />

          <St.Divider />

          <DetailTickets unitId={selectedUnitId} />
        </St.DrawerContentsContainer>
      </St.Drawer>
    </>
  )
}
