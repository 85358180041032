import { createAsyncThunk } from '@reduxjs/toolkit'

import { InspectionCategory, InspectionListItem } from 'packages/grimoire'
import { Events, track } from 'packages/wiretap'

import { CheckedOrFlagged } from 'app/fieldapp/components/schedule/components/VisitInspectionChecklist/components/VisitInspectionChecklistItem'
import {
  isViewingSharedClean,
  toggleIsCompleteValue,
} from 'app/fieldapp/components/schedule/schedule.utils'

import { getDelegateUserId } from '../../auth/selectors'
import { ApplicationState } from '../../store'
import { getActiveUser } from '../../users/selectors'
import { updateVisit } from './updateVisit'

type toggleVisitInspectionChecklistItemParams = {
  category: InspectionCategory
  checkedOrFlagged: CheckedOrFlagged
  item: InspectionListItem
  visitId: string
}

export const toggleVisitInspectionChecklistItem = createAsyncThunk(
  'visits/toggleVisitInspectionChecklistItem',
  async (
    {
      category,
      checkedOrFlagged,
      item,
      visitId,
    }: toggleVisitInspectionChecklistItemParams,
    { dispatch, getState, rejectWithValue },
  ) => {
    const { id: itemId, isComplete } = item

    const userId = getActiveUser(getState() as ApplicationState)?.id
    const delegateUserId = getDelegateUserId(getState() as ApplicationState)

    const convertItemIsComplete = {
      ...item,
      isComplete: toggleIsCompleteValue(checkedOrFlagged, isComplete),
    }

    /* eslint-disable @typescript-eslint/naming-convention */
    const trackingSuccessPayload = {
      category_id: category.id,
      is_complete: convertItemIsComplete.isComplete,
      is_delegate: isViewingSharedClean(),
      item_id: itemId,
      user_id: userId || delegateUserId || 'unknown',
      visit_id: visitId,
    }
    /* eslint-enable @typescript-eslint/naming-convention */

    try {
      if (!visitId) return

      await dispatch(
        updateVisit({
          id: visitId,
          inspectionUpdateConfig: {
            category,
            item: convertItemIsComplete,
          },
        }),
      )

      track(Events.fieldAppInspectionToggleItem, trackingSuccessPayload)
    } catch (err) {
      track(Events.fieldAppInspectionToggleItemError, {
        ...trackingSuccessPayload,
        error: err?.message || 'Unknown Error',
      })

      return rejectWithValue(err)
    }
  },
)
