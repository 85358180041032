import React from 'react'

import { AsyncState } from 'packages/utils/hooks'

export type VisitTicketDrawerContextType = {
  fetchTicketState: AsyncState<unknown>
}

export const VisitTicketDrawerContext =
  React.createContext<VisitTicketDrawerContextType>({
    fetchTicketState: {
      loading: false,
    },
  })

export const useVisitTicketDrawerContext = (): VisitTicketDrawerContextType =>
  React.useContext(VisitTicketDrawerContext)
