import * as React from 'react'

import { TimerAction } from './timers.actions'
import { TimerState } from './timers.reducer'

export type TimersContextType = {
  dispatch: React.Dispatch<TimerAction>
  state: TimerState
}

export const TimersContext = React.createContext<TimersContextType>(
  {} as TimersContextType,
)

TimersContext.displayName = 'TimersContext'

export const useTimers = (): TimersContextType =>
  React.useContext(TimersContext)
