import styled from '@emotion/styled'
import React, { useState } from 'react'

import { Button, Checkbox } from 'packages/common'
import { Rating } from 'packages/common'
import { colors } from 'packages/styles'

import { Slugs, useI18n } from 'app/fieldapp/i18n'

const RATINGS = [5, 4, 3, 2, 1]
const CHANNELS = ['Airbnb', 'Booking', 'Vacasa', 'Vrbo']

const St = {
  ChannelsFilterContainer: styled.div`
    align-items: start;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  `,
  FiltersAndButtonContainer: styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 20px;
  `,
  FiltersContainer: styled.div`
    align-items: stretch;
    display: flex;
    justify-content: space-between;
    gap: 40px;
  `,
  FilterTitle: styled.span`
    color: ${colors.midnight};
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px;
  `,
  RatingsFilterContainer: styled.div`
    align-items: start;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  `,
}

const useTranslations = () => {
  const { t } = useI18n()

  return {
    applyButton: t(Slugs.applyButton),
    reviewFiltersByChannelTitle: t(Slugs.reviewFiltersByChannelTitle),
    reviewFiltersByRatingTitle: t(Slugs.reviewFiltersByRatingTitle),
  }
}

export type ReviewFiltersProps = {
  checkedChannels: string[]
  checkedRatings: number[]
  onApplyFilters: (
    selectedRatings: number[],
    selectedChannels: string[],
  ) => void
}

export const ReviewFilters: React.FC<ReviewFiltersProps> = React.memo(
  ({ onApplyFilters, checkedRatings, checkedChannels }) => {
    const strings = useTranslations()

    const [selectedRatings, setSelectedRatings] =
      useState<number[]>(checkedRatings)
    const [selectedChannels, setSelectedChannels] =
      useState<string[]>(checkedChannels)

    const handleRatingFilterChange = (rating: number) => {
      setSelectedRatings(prevRatings =>
        prevRatings.includes(rating)
          ? prevRatings.filter(r => r !== rating)
          : [...prevRatings, rating],
      )
    }

    const handleChannelFilterChange = (channel: string) => {
      setSelectedChannels(prevChannels =>
        prevChannels.includes(channel)
          ? prevChannels.filter(c => c !== channel)
          : [...prevChannels, channel],
      )
    }

    return (
      <St.FiltersAndButtonContainer>
        <St.FiltersContainer>
          <St.RatingsFilterContainer>
            <St.FilterTitle>
              {strings.reviewFiltersByRatingTitle}
            </St.FilterTitle>
            {RATINGS.map(rating => (
              <Checkbox
                key={rating}
                label={<Rating value={rating} />}
                onChange={() => handleRatingFilterChange(rating)}
                checked={selectedRatings.includes(rating)}
                value={rating}
              />
            ))}
          </St.RatingsFilterContainer>
          <St.ChannelsFilterContainer>
            <St.FilterTitle>
              {strings.reviewFiltersByChannelTitle}
            </St.FilterTitle>
            {CHANNELS.map(channel => (
              <Checkbox
                key={channel}
                label={channel}
                onChange={() => handleChannelFilterChange(channel)}
                checked={selectedChannels.includes(channel)}
                value={channel}
              />
            ))}
          </St.ChannelsFilterContainer>
        </St.FiltersContainer>
        <Button
          onClick={() => onApplyFilters(selectedRatings, selectedChannels)}
        >
          {strings.applyButton}
        </Button>
      </St.FiltersAndButtonContainer>
    )
  },
)
