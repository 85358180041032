import { produce } from 'immer'
import { ActionType, getType } from 'typesafe-actions'

import { fetchCleanByIdAction } from '../cleans/actions'
import { fetchVisitByIdAction } from '../visits/actions'
import { deleteTaskPhotoAction } from './actions'
import { createTaskPhotoAction, fetchTaskPhotoByIdAction } from './actions'
import { TaskPhotosState } from './taskPhotos.types'
import { emptyNormalizedTaskPhotosData } from './taskPhotos.utils'

export const initialState: TaskPhotosState = Object.freeze({
  data: {},
})

const actions = {
  createTaskPhotoAction,
  deleteTaskPhotoAction,
  fetchCleanByIdAction,
  fetchTaskPhotoByIdAction,
  fetchVisitByIdAction,
}

export const taskPhotosReducer = (
  state = initialState,
  action: ActionType<typeof actions>,
): TaskPhotosState =>
  produce(state, (draft: TaskPhotosState) => {
    switch (action.type) {
      case getType(createTaskPhotoAction.success):
      case getType(fetchCleanByIdAction.success):
      case getType(fetchVisitByIdAction.success):
      case getType(fetchTaskPhotoByIdAction.success): {
        const normalized =
          action?.payload?.normalized || emptyNormalizedTaskPhotosData

        draft.data = {
          ...state.data,
          ...normalized.taskPhoto,
        }

        return
      }

      case getType(deleteTaskPhotoAction.success): {
        const { id } = action.payload
        delete draft.data[id]
        return
      }

      default:
        return
    }
  })
