import React from 'react'

import { useModalContext } from 'packages/common'

import {
  CrossCoverageFormAction,
  CrossCoverageFormState,
} from '../../state/CrossCoverageForm.reducer'
import { IsRepeatingForm } from './IsRepeatingForm'
import { IsRepeatingFormModal } from './IsRepeatingForm.modal'

type IsRepeatingFormContainerProps = {
  formDispatch: React.Dispatch<CrossCoverageFormAction>
  formState: CrossCoverageFormState
}

export const IsRepeatingFormContainer: React.FC<IsRepeatingFormContainerProps> =
  React.memo(({ formDispatch, formState }) => {
    const { showModal } = useModalContext()

    const { isRepeating } = formState

    const handleInfoIconClick = () => {
      showModal({
        childRenderer: () => <IsRepeatingFormModal />,
      })
    }

    const handleIsRepeatingChange = (opt: boolean) => {
      formDispatch({ payload: opt, type: 'setIsRepeating' })
    }

    return (
      <IsRepeatingForm
        handleInfoIconClick={handleInfoIconClick}
        handleIsRepeatingChange={handleIsRepeatingChange}
        isDisabled={!formState.isEditable}
        isRepeating={isRepeating}
      />
    )
  })
