import {
  createDateRange,
  createDateString,
  startOfWeek,
} from 'packages/utils/dateHelpers'

/** Generates a date range that starts from Monday and runs through Sunday */
export const generateWorkWeekDateRange = (date: Date): Date[] => {
  const firstSundayOfWeek = startOfWeek(date, { weekStartsOn: 0 })
  return createDateRange({ startDate: firstSundayOfWeek, totalDays: 7 })
}

export const getDateBoundsFromWorkWeek = (
  dateRange: Date[],
): [string, string] => {
  return [
    createDateString(dateRange[0]), // start of week
    createDateString(dateRange[6]), // end of week
  ]
}
