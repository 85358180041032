import { createAsyncThunk } from '@reduxjs/toolkit'
import { noop } from 'lodash'

import { ReduxActionCallbacks } from 'packages/grimoire/src/utils'

import { setIsCoverageStale } from '../../coverage/actions'
import { crossCoverageService } from '../crossCoverage.service'

type DeleteCrossCoverageThunkArgs = {
  callbacks?: ReduxActionCallbacks
  id: string
}

export const deleteCrossCoverage = createAsyncThunk(
  'crossCoverage/api/deleteCrossCoverage',
  async (
    { id, callbacks = {} }: DeleteCrossCoverageThunkArgs,
    { dispatch },
  ) => {
    const { onError = noop, onSuccess = noop } = callbacks

    try {
      const response = await crossCoverageService.deleteCrossCoverageById(id)
      dispatch(setIsCoverageStale(true))
      onSuccess()
      return response
    } catch (error) {
      onError(error)
      throw error
    }
  },
)
