import { createAsyncAction } from 'typesafe-actions'

import { OfflineError } from 'packages/offline'
import {
  apiDateStringWithSeconds,
  createDateObject,
} from 'packages/utils/dateHelpers'
import { OfflineRequestConfig, RequestOptions } from 'packages/utils/store'

import { getTaskPhotosByIds } from '../../taskPhotos/selectors'
import { offlineTimers } from '../../utils'
import { updateVisit } from '../../visits/actions'
import { getVisitByCleanTimerId } from '../../visits/selectors'
import { cleanTimesService } from '../cleantimes.service'
import {
  CleanTimePatchData,
  CleanTimesActionTypes,
  NormalizedCleanTimesApiResponse,
  CleanTimePatchApiAttributes,
} from '../cleantimes.types'
import { fetchActiveCleanTimesForUser } from './fetchActiveCleanTimesForUser'
import { getHasAllInspectionPhotos } from './utils'

export const updateCleanTimeAction = createAsyncAction(
  CleanTimesActionTypes.UPDATE_CLEAN_TIME,
  CleanTimesActionTypes.UPDATE_CLEAN_TIME_SUCCESS,
  CleanTimesActionTypes.UPDATE_CLEAN_TIME_FAILURE,
)<
  OfflineRequestConfig<NormalizedCleanTimesApiResponse, CleanTimePatchData>,
  NormalizedCleanTimesApiResponse,
  Error
>()

export const buildRequestData = (
  patchData: CleanTimePatchData,
): RequestOptions => {
  const { id, isFinalized, notes, startedAt, stoppedAt } = patchData

  const attributes: Partial<CleanTimePatchApiAttributes> = {
    is_finalized: isFinalized,
    notes,
    started_at: startedAt && apiDateStringWithSeconds(startedAt),
    stopped_at: stoppedAt && apiDateStringWithSeconds(stoppedAt),
  }

  return {
    data: {
      attributes,
      id,
      type: 'clean_time',
    },
  }
}

export const updateCleanTime =
  (patchData: CleanTimePatchData) => async (dispatch, getState) => {
    try {
      const cleanTimeId = patchData.id
      const requestData = buildRequestData(patchData)
      const request = cleanTimesService.updateCleanTime.bind(
        null,
        cleanTimeId,
        requestData,
      )
      if (patchData.isVisitInpsectionTimer) {
        const state = getState()
        const visit = getVisitByCleanTimerId(state, cleanTimeId)

        const inspectionChecklistCompleted =
          !visit?.inspectionChecklist?.checklists.filter(
            checklist =>
              !!checklist.items.filter(item => item.isComplete === 'unchecked')
                .length,
          ).length

        const taskPhotos = getTaskPhotosByIds(
          state.taskPhotos,
          visit?.taskPhotoIds || [],
        )

        const hasRequiredPhotos = visit?.inspectionChecklist
          ? getHasAllInspectionPhotos(taskPhotos, visit?.inspectionChecklist)
          : false

        if (
          visit &&
          inspectionChecklistCompleted &&
          !visit.inspectionCompletedAt &&
          hasRequiredPhotos
        ) {
          dispatch(
            updateVisit({
              id: visit.id,
              inspectionCompletedAt: createDateObject(),
            }),
          )
        }
      }

      const result = await dispatch(
        updateCleanTimeAction.request({ data: patchData, request }),
      )

      // remove any existing copies of this timer from local cache
      await offlineTimers.removeCleanTime(cleanTimeId)

      dispatch(updateCleanTimeAction.success(result))

      // re-fetch all timers to ensure we update the PWA cache
      await dispatch(fetchActiveCleanTimesForUser())

      return result.normalized
    } catch (error) {
      /* No need to do anything if there is a middleware-handled offline error */
      if (error === OfflineError) return
      dispatch(updateCleanTimeAction.failure(error))
      throw error
    }
  }
