import { useDecision } from '@optimizely/react-sdk'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { Features } from 'packages/optimizely'

import { getLockboxById } from 'app/fieldapp/store/lockboxes/selectors'
import { ApplicationState } from 'app/fieldapp/store/store'
import {
  getSortedTicketCommentIds,
  getTicketCommentById,
} from 'app/fieldapp/store/ticketComments/selectors'
import { getTicketCommentsByIds } from 'app/fieldapp/store/ticketComments/selectors/getTicketCommentsByIds'
import { getTicketById } from 'app/fieldapp/store/tickets/selectors'
import { getUnitById } from 'app/fieldapp/store/units/selectors'
import { getUserById } from 'app/fieldapp/store/users/selectors'

export type UseTicket = {
  assignee: ReturnType<typeof getUserById>
  comments: ReturnType<typeof getTicketCommentsByIds>
  isCommentsCriteriaFulfilled: boolean
  lockbox: ReturnType<typeof getLockboxById>
  ticket: ReturnType<typeof getTicketById>
  ticketCommentIds: ReturnType<typeof getSortedTicketCommentIds>
  unit: ReturnType<typeof getUnitById>
}

const EMPTY_ARRAY = []

export const useTicket = (ticketId: string): UseTicket => {
  const [decision] = useDecision(Features.FORCED_OWNER_COMMENT)

  const ticket = useSelector((state: ApplicationState) =>
    getTicketById(state, ticketId),
  )

  const assignee = useSelector((state: ApplicationState) =>
    getUserById(state, ticket?.assigneeId || ''),
  )

  const unit = useSelector((state: ApplicationState) =>
    getUnitById(state, ticket?.unitId || ''),
  )

  const lockbox = useSelector((state: ApplicationState) =>
    getLockboxById(state, ticket?.lockboxId || ''),
  )

  const comments = useSelector((state: ApplicationState) =>
    getTicketCommentsByIds(state, ticket?.commentIds || EMPTY_ARRAY),
  )

  const ticketCommentIds = useSelector((state: ApplicationState) =>
    getSortedTicketCommentIds(state, ticket?.commentIds || EMPTY_ARRAY),
  )

  // needed for the logic around comments
  const lastComment = useSelector((state: ApplicationState) =>
    getTicketCommentById(state, ticketCommentIds[0]),
  )
  const lastCommentUser = useSelector((state: ApplicationState) =>
    lastComment ? getUserById(state, lastComment.userId) : null,
  )

  const isCommentsCriteriaFulfilled = useMemo(() => {
    // if the flag is not enabled, the criteria is always fulfilled so the feature is not active
    if (!decision.enabled) return true
    // ticket can be only completed if it is internal ticket
    if (ticket?.visibility === 'internal') return true
    // or if the last comment exits and is not from a homeowner and it must be external
    if (
      lastComment &&
      lastCommentUser &&
      !lastCommentUser.isHomeowner &&
      lastComment.external
    ) {
      return true
    }

    //every other scenario is not fulfilled
    return false
  }, [decision.enabled, ticket?.visibility, lastComment, lastCommentUser])

  return {
    assignee,
    comments,
    isCommentsCriteriaFulfilled,
    lockbox,
    ticket,
    ticketCommentIds,
    unit,
  }
}
