import styled from '@emotion/styled'
import React from 'react'

import { User, fullName } from 'packages/grimoire'
import { useI18n } from 'packages/i18n'
import { IconName } from 'packages/iconic'
import { DateFormat, format } from 'packages/utils/dateHelpers'

import * as SharedStyles from 'app/fieldapp/components/schedule/components/TaskSharedStyles'
import { Slugs } from 'app/fieldapp/i18n'
import { useAppSelector } from 'app/fieldapp/store/hooks'
import { getLastCleanCompletedHksByVisitId } from 'app/fieldapp/store/housekeepers/selectors/getLastCleanCompletedHksByVisitId'
import { ApplicationState } from 'app/fieldapp/store/store'
import { getVisitDrawerState } from 'app/fieldapp/store/ui/selectors'
import { getFilteredUsersByHkIds } from 'app/fieldapp/store/users/selectors/getFilteredUsersByHkIds'
import { getVisitById } from 'app/fieldapp/store/visits/selectors'

const St = {
  ...SharedStyles,
  Container: SharedStyles.UnitInfoContainer,
  Details: styled(SharedStyles.Details)`
    display: flex;
    flex-direction: column;
  `,
}

export enum PreviousCleanInfoTestIds {
  container = 'PreviousCleanInfo__container',
}

export const PreviousCleanInfo: React.FC = React.memo(() => {
  const { visitId } = useAppSelector(getVisitDrawerState)
  const visit = useAppSelector((state: ApplicationState) =>
    getVisitById(state, visitId),
  )
  const hks = useAppSelector((state: ApplicationState) =>
    getLastCleanCompletedHksByVisitId(state, visitId),
  )

  const users: User[] = useAppSelector((state: ApplicationState) =>
    getFilteredUsersByHkIds(state, hks),
  )

  const { ut } = useI18n()

  return (
    <St.Container data-testid={PreviousCleanInfoTestIds.container}>
      <St.DetailIcon icon={IconName.calendarClean} size={16} />
      <St.Details>
        <St.SectionSubTitle>{ut(Slugs.priorClean)}</St.SectionSubTitle>
        <St.BasicText>
          {ut(Slugs.completed)}{' '}
          {visit?.lastCleanCompletedAt
            ? format(
                visit.lastCleanCompletedAt,
                DateFormat.SlashesWithShortYear,
              )
            : '-'}
        </St.BasicText>
        {users.map((user, index) => {
          return <St.BasicText key={index}>{fullName(user)}</St.BasicText>
        })}
      </St.Details>
    </St.Container>
  )
})
