import {
  JSONApiOtherTimerMap,
  OfflineOtherTimer,
} from 'packages/grimoire/src/otherTimer'

export * from 'packages/grimoire/src/otherTimer'

/**********************************************************
 * REDUX TYPES
 *********************************************************/
export enum OtherTimersActionTypes {
  CREATE_FINALIZED_OTHER_TIMER = 'OTHER_TIMERS/API/CREATE_FINALIZED_OTHER_TIMER',
  CREATE_FINALIZED_OTHER_TIMER_FAILURE = 'OTHER_TIMERS/CREATE_FINALIZED_OTHER_TIMER_FAILURE',
  CREATE_FINALIZED_OTHER_TIMER_SUCCESS = 'OTHER_TIMERS/CREATE_FINALIZED_OTHER_TIMER_SUCCESS',

  CREATE_OTHER_TIMER = 'OTHER_TIMERS/API/CREATE_OTHER_TIMER',
  CREATE_OTHER_TIMER_FAILURE = 'OTHER_TIMERS/CREATE_OTHER_TIMER_FAILURE',
  CREATE_OTHER_TIMER_SUCCESS = 'OTHER_TIMERS/CREATE_OTHER_TIMER_SUCCESS',

  DELETE_OTHER_TIMER = 'OTHER_TIMERS/API/DELETE_OTHER_TIMER',
  DELETE_OTHER_TIMER_FAILURE = 'OTHER_TIMERS/DELETE_OTHER_TIMER_FAILURE',
  DELETE_OTHER_TIMER_SUCCESS = 'OTHER_TIMERS/DELETE_OTHER_TIMER_SUCCESS',

  FETCH_OTHER_TIMERS = 'OTHER_TIMERS/API/FETCH_OTHER_TIMERS',
  FETCH_OTHER_TIMERS_FAILURE = 'OTHER_TIMERS/FETCH_OTHER_TIMERS_FAILURE',
  FETCH_OTHER_TIMERS_SUCCESS = 'OTHER_TIMERS/FETCH_OTHER_TIMERS_SUCCESS',

  FETCH_OTHER_TIMER_BY_ID = 'OTHER_TIMERS/API/FETCH_OTHER_TIMER_BY_ID',
  FETCH_OTHER_TIMER_BY_ID_FAILURE = 'OTHER_TIMERS/FETCH_OTHER_TIMER_BY_ID_FAILURE',
  FETCH_OTHER_TIMER_BY_ID_SUCCESS = 'OTHER_TIMERS/FETCH_OTHER_TIMER_BY_ID_SUCCESS',

  OFFLINE_CREATE_OTHER_TIMER = 'OTHER_TIMERS/OFFLINE/CREATE_OTHER_TIMER',
  OFFLINE_CREATE_OTHER_TIMER_FAILURE = 'OTHER_TIMERS/OFFLINE/CREATE_OTHER_TIMER_FAILURE',
  OFFLINE_CREATE_OTHER_TIMER_SUCCESS = 'OTHER_TIMERS/OFFLINE/CREATE_OTHER_TIMER_SUCCESS',

  OFFLINE_UPDATE_OTHER_TIMER = 'OTHER_TIMERS/OFFLINE/UPDATE_OTHER_TIMER',
  OFFLINE_UPDATE_OTHER_TIMER_FAILURE = 'OTHER_TIMERS/OFFLINE/UPDATE_OTHER_TIMER_FAILURE',
  OFFLINE_UPDATE_OTHER_TIMER_SUCCESS = 'OTHER_TIMERS/OFFLINE/UPDATE_OTHER_TIMER_SUCCESS',

  RELOAD_OFFLINE_OTHER_TIMERS = 'OTHER_TIMERS/RELOAD_OFFLINE_OTHER_TIMERS',
  RELOAD_OFFLINE_OTHER_TIMERS_FAILURE = 'OTHER_TIMERS/RELOAD_OFFLINE_OTHER_TIMERS_FAILURE',
  RELOAD_OFFLINE_OTHER_TIMERS_SUCCESS = 'OTHER_TIMERS/RELOAD_OFFLINE_OTHER_TIMERS_SUCCESS',

  UPDATE_OTHER_TIMER = 'OTHER_TIMERS/API/UPDATE_OTHER_TIMER',
  UPDATE_OTHER_TIMER_FAILURE = 'OTHER_TIMERS/UPDATE_OTHER_TIMER_FAILURE',
  UPDATE_OTHER_TIMER_SUCCESS = 'OTHER_TIMERS/UPDATE_OTHER_TIMER_SUCCESS',
}

export type OtherTimersState = {
  data: JSONApiOtherTimerMap
  offlineData: Record<string, OfflineOtherTimer>
  requestPending: boolean
}
