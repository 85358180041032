import { createSelector } from 'reselect'

import { ApplicationState } from 'app/fieldapp/store/store'

import { CleanTime, RawCleanTime } from '../cleantimes.types'
import { hydrateRawCleanTime } from '../cleantimes.utils'

const getFullState = (state: ApplicationState): ApplicationState => state
const getAssignmentId = (_state, assignmentId: string): string => assignmentId

/**
 * Returns an Array of all CleanTimes associated with a given Assignment ID.
 * If no CleanTimes are found for the assignment, it will return an empty Array.
 */
export const getCleanTimesByAssignmentId = createSelector(
  getFullState,
  getAssignmentId,
  (state, assignmentId): CleanTime[] => {
    const isSameAssignment = (rct: RawCleanTime) =>
      rct.relationships?.assignment?.data?.id === assignmentId

    const hydrate = hydrateRawCleanTime(state)

    return Object.values(state.cleanTimes.data)
      .filter(isSameAssignment)
      .map(hydrate)
  },
)
