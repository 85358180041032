import { configureStore } from '@reduxjs/toolkit'

import { getAuthMiddleware } from 'packages/auth'
import { getOfflineMiddleware } from 'packages/offline'
import { getWireTapMiddle } from 'packages/wiretap'

import {
  createOtherTimerOffline,
  updateOtherTimerOffline,
} from 'app/fieldapp/store/otherTimers/actions'

import { faTrackers } from '../tracking/trackers'
import { setNeedsFullAuthRedirect, setNeedsSilentRefresh } from './auth/actions'
import { getAuthToken, getNeedsSilentRefresh } from './auth/selectors'
import { setRequestHeader } from './axiosInstance'
import { CleanTimesActionTypes } from './cleantimes'
import { createCleanTimeOffline } from './cleantimes/actions'
import { updateCleanTimeOffline } from './cleantimes/actions/updateCleanTime.offline'
import { segmentMiddleware } from './middlewares'
import { OtherTimersActionTypes } from './otherTimers'
import { rootReducer } from './reducers'
import { TicketTimesActionTypes } from './ticket-times'
import { createTicketTimeOffline } from './ticket-times/actions'
import { updateTicketTimeOffline } from './ticket-times/actions/updateTicketTime.offline'

export type ApplicationState = ReturnType<typeof rootReducer>

const authMiddleware = getAuthMiddleware({
  getAuthToken,
  getNeedsSilentRefresh,
  setNeedsFullAuthRedirect,
  setNeedsSilentRefresh,
  setRequestHeader,
})

const wireTapMiddleware = getWireTapMiddle({ trackers: faTrackers })

const offlineMiddleware = getOfflineMiddleware({
  offlineActions: {
    [OtherTimersActionTypes.UPDATE_OTHER_TIMER]: updateOtherTimerOffline,
    [CleanTimesActionTypes.UPDATE_CLEAN_TIME]: updateCleanTimeOffline,
    [TicketTimesActionTypes.UPDATE_TICKET_TIME]: updateTicketTimeOffline,
    [OtherTimersActionTypes.CREATE_OTHER_TIMER]: createOtherTimerOffline,
    [CleanTimesActionTypes.CREATE_CLEAN_TIME]: createCleanTimeOffline,
    [TicketTimesActionTypes.CREATE_TICKET_TIME]: createTicketTimeOffline,
  },
})

const store = configureStore({
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActionPaths: [
          'payload.request',
          'meta.arg.callbacks.onError',
          'meta.arg.callbacks.onSuccess',
          'payload.nativeEvent',
          'payload.target',
        ],
      },
    })
      .concat(authMiddleware)
      .concat(offlineMiddleware)
      .concat(segmentMiddleware)
      .concat(wireTapMiddleware),
  reducer: rootReducer,
})

export type AppDispatch = typeof store.dispatch
export default store
