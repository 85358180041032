import styled from '@emotion/styled'

import { SvgIcon } from 'packages/iconic'
import { colors } from 'packages/styles'

export const CheckboxContainer = styled.div`
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  position: relative;
`

export const CheckboxInput = styled.input`
  // hide native <input> so we can show our styled one instead
  cursor: pointer;
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0;
`

export const CheckboxLabel = styled.label<{ disabled: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`

export const Icon = styled(SvgIcon)<{ disabled: boolean }>`
  color: ${({ disabled }) => (disabled ? colors.midnight40 : 'inherit')};
`

export const CustomCheckbox = styled.span<{ isFlagged: boolean }>`
  background-color: ${colors.midnight20};
  border-radius: 50%;
  box-sizing: border-box;
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 125ms ease;
  will-change: background-color;

  input:checked ~ & {
    background-color: ${({ isFlagged }) =>
      isFlagged ? colors.awareness70 : colors.success40};
  }

  input:disabled ~ & {
    opacity: 0.5;
  }
`
