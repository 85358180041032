import styled from '@emotion/styled'

import { colors, truncateTextWithEllipsis } from 'packages/styles'

import { TimerDetailWrapper } from '../Timers.styles'

export const ActiveTimeContainer = styled(TimerDetailWrapper)`
  border-top: 1px solid ${colors.midnight20};
  flex-direction: row;
  justify-content: space-between;
`

export const DetailWrapper = styled.div`
  // 8px = spacing, 44px = button width, 105px = time text
  max-width: calc(100% - 8px - 105px - 44px);
  flex-grow: 2;
  padding: 0 8px;
`

export const OtherTimerDetails = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: bold;
`

export const TimerName = styled.div`
  ${truncateTextWithEllipsis};
  color: ${colors.dusk};
  font-size: 16px;
  font-weight: 400;
`

export const TimeDisplay = styled.div`
  color: #a10024;
  font-size: 24px;
  font-weight: 700;
`
