import { User } from 'packages/grimoire'
import { createDateObject, format } from 'packages/utils/dateHelpers'

import { Coverage } from 'app/fieldapp/store/coverage/coverage.types'

import { ComputedWorkingStatus } from '../../../../../../store/coverage/coverage.types'

export type BaseTimelineEntry = { endTime: Date; id: string; startTime: Date }

type TimelineCrossCoveringEntry = {
  coverageType: 'cross_coverage_covering'
  crossCoverageId: string
} & BaseTimelineEntry

type TimelineStandardCoveringEntry = {
  coverageType: 'standard_covering'
} & BaseTimelineEntry

type TimelineStandardEntry = {
  coverageType: 'standard'
} & BaseTimelineEntry

export type TimelineStandardCoveredEntry = {
  coverageType: 'standard_covered'
  requestedUserId: string
  userId: string
} & BaseTimelineEntry

type TimelineCrossCoveredEntry = {
  coverageType: 'cross_coverage_covered'
  crossCoverageId: string
  requestedUserId: string
  userId: string
} & BaseTimelineEntry

export type TimelineEntry =
  | TimelineCrossCoveringEntry
  | TimelineStandardCoveringEntry
  | TimelineStandardEntry
  | TimelineStandardCoveredEntry
  | TimelineCrossCoveredEntry

/** Typeguard that effectively lets us know if we should have an associated user with the coverage record */
export const isCoveredEntry = (
  entry: TimelineEntry,
): entry is TimelineStandardCoveredEntry | TimelineCrossCoveredEntry =>
  ['cross_coverage_covered', 'standard_covered'].includes(entry.coverageType)

/** Typeguard that effectively lets us know if we should have an associated cross coverage with the coverage record */
export const isCrossCoverageEntry = (
  entry: TimelineEntry,
): entry is TimelineCrossCoveredEntry | TimelineCrossCoveredEntry => {
  const crossCoverageEntryTypes: TimelineEntry['coverageType'][] = [
    'cross_coverage_covered',
    'cross_coverage_covering',
  ]
  return crossCoverageEntryTypes.includes(entry.coverageType)
}

export const timelineTimeFormatter = (date: string | Date): string =>
  format(date, 'hh:mm a')

export const getWorkingStatusText = (
  workingStatus: ComputedWorkingStatus,
  translations: Record<
    'youAreOncall' | 'isCoveringStatus' | 'isRequested',
    string
  >,
  user?: User,
  requestedUser?: User,
): string => {
  if (workingStatus === 'requested') {
    return `${requestedUser?.firstName || 'Unknown'} ${
      translations.isRequested
    }`
  }

  if (workingStatus === 'covered') {
    return `${user?.firstName || 'Unknown'} ${translations.isCoveringStatus}`
  }

  return translations.youAreOncall
}

export const createTimelineEntry = (coverage: Coverage): TimelineEntry => {
  const { start, end, id, coverageType } = coverage

  const entry = {
    coverageType,
    endTime: createDateObject(end),
    id,
    startTime: createDateObject(start),
  } as unknown as TimelineEntry

  if (isCoveredEntry(entry)) {
    entry.userId = coverage.userId

    if (!coverage.requestedUserId) {
      throw new Error('Cross coverage entry must have a requested user id')
    }

    entry.requestedUserId = coverage.requestedUserId
  }

  if (isCrossCoverageEntry(entry)) {
    if (!coverage.crossCoverageId) {
      throw new Error('Cross coverage entry must have a cross coverage id')
    }

    if (!coverage.requestedUserId) {
      throw new Error('Cross coverage entry must have a requested user id')
    }

    entry.crossCoverageId = coverage.crossCoverageId
    entry.requestedUserId = coverage.requestedUserId
  }

  return entry
}
