import { RequestOptions } from 'packages/utils/store/jsonapi.types'

import { api } from '../axiosInstance'
import { ReviewsApiResponse, ReviewsServiceResponse } from './reviews.types'
import { emptyReviewResponse } from './reviews.utils'

const URL = '/guest_reviews'

export const reviewsService = {
  async fetchReviews(params: RequestOptions = {}): ReviewsServiceResponse {
    const response: ReviewsApiResponse = await api.get(URL, {
      params,
    })

    return response?.data || emptyReviewResponse
  },
}
