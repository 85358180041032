import { startCase, upperFirst } from 'lodash'
import React from 'react'

import { IconName } from 'packages/iconic'

import { isViewingSharedClean } from 'app/fieldapp/components/schedule/schedule.utils'
import { Slugs, useI18n } from 'app/fieldapp/i18n'

import { NavTab, NavTabConfig } from './NavTab'

import styles from './NavTabWrapper.module.scss'

export const NavTabWrapper: React.FC = () => {
  const { t } = useI18n()
  const cleansTab = {
    icon: IconName.calendar,
    route: '/cleans',
    text: startCase(t(Slugs.tasks)),
  }

  const profileTab = {
    icon: IconName.user,
    route: '/profile',
    text: upperFirst(t(Slugs.profile)),
  }

  const ticketsTab = {
    icon: IconName.tag,
    route: '/tickets',
    text: upperFirst(t(Slugs.tickets)),
  }

  const configs: NavTabConfig[] = [cleansTab, ticketsTab, profileTab]

  const shouldRender = !isViewingSharedClean()

  if (!shouldRender) {
    return null
  }

  return (
    <nav className={styles.navTabWrapper}>
      {configs.map(config => (
        <NavTab config={config} key={config.route} />
      ))}
    </nav>
  )
}
