import React from 'react'
import { useSelector } from 'react-redux'

import { Button } from 'packages/common'
import { useManageConnectedDrawerState } from 'packages/common/src/modals/Drawer/hooks/useManageConnectedDrawerState'

import { setCrossCoverageDrawer } from 'app/fieldapp/store/ui/actions'
import { getCrossCoverageDrawerState } from 'app/fieldapp/store/ui/selectors/getCrossCoverageDrawerState'

import { CrossCoverageDrawer } from './CrossCoverageDrawer'
import { useCrossCoverageDrawerTranslations } from './useCrossCoverageDrawerTranslations'

export const CrossCoverageDrawerContainer: React.FC = () => {
  const strings = useCrossCoverageDrawerTranslations()

  const drawerManager = useManageConnectedDrawerState({
    dispatchableToggleDrawerAction: setCrossCoverageDrawer,
    selector: getCrossCoverageDrawerState,
  })

  const openDrawer = () => {
    drawerManager.openDrawer()
  }

  const { crossCoverageId } = useSelector(getCrossCoverageDrawerState)

  return (
    <>
      <Button buttonType={'text'} onClick={openDrawer}>
        {strings.newCoverage}
      </Button>
      <CrossCoverageDrawer
        drawerManager={drawerManager}
        isEditCrossCoverage={!!crossCoverageId}
      />
    </>
  )
}
