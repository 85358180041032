import upperFirst from 'lodash/upperFirst'
import * as React from 'react'

import { CleanCheckOutDisplay } from 'packages/common'
import { IconName } from 'packages/iconic'
import { unitFullAddressString } from 'packages/utils/hkStringHelpers'

import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { Clean } from 'app/fieldapp/store/cleans'
import { cleanDueDateString } from 'app/fieldapp/utils'

import { CleanHeader } from '../CleanHeader'
import { CleanStatusControls } from '../CleanStatus/CleanStatusControls'
import * as St from './CleanCard.styles'

const useTranslations = () => {
  const { t } = useI18n()

  return {
    due: upperFirst(t(Slugs.due)),
    startTime: upperFirst(t(Slugs.startTime)),
    teamClean: t(Slugs.teamClean),
  }
}

export enum CleanCardTestIds {
  cleanCheckOutDisplay = 'cleanCard__cleanCheckOutDisplay',
  container = 'cleanCard__container',
}

export type CleanCardProps = {
  clean: Clean
  onClick: (cleanId: string) => void
}

export const CleanCard: React.FC<CleanCardProps> = React.memo(
  ({ clean, onClick }) => {
    const strings = useTranslations()
    const { unit } = clean

    const handleClick = React.useCallback(
      () => onClick(clean.id),
      [clean, onClick],
    )

    const isTeamClean = clean?.assignments?.length > 1
    const dueDateString = cleanDueDateString(clean)
    const addressString = unitFullAddressString(unit)

    return (
      <St.TaskCardContainer dataTestId={CleanCardTestIds.container}>
        <CleanHeader clean={clean} />

        <St.TaskCardContent onClick={handleClick}>
          <St.Section>
            <St.UnitCode>{unit.unitCode}</St.UnitCode>
            <St.UnitName>{unit.name}</St.UnitName>
          </St.Section>

          <St.Section>
            <St.UnitAddress>{addressString}</St.UnitAddress>
          </St.Section>

          <St.FlexSection>
            <strong>{strings.startTime}</strong>
            <CleanCheckOutDisplay
              clean={clean}
              dataTestId={CleanCardTestIds.cleanCheckOutDisplay}
            />
          </St.FlexSection>

          <St.FlexSection>
            <strong>{strings.due}</strong>
            <St.DueDate>{dueDateString}</St.DueDate>
          </St.FlexSection>
          {isTeamClean && (
            <St.IconRow>
              <St.TeamCleanIcon icon={IconName.zap} size={16} />
              <strong>{strings.teamClean}</strong>
            </St.IconRow>
          )}
        </St.TaskCardContent>

        <CleanStatusControls clean={clean} />
      </St.TaskCardContainer>
    )
  },
)
