import { createAsyncAction } from 'typesafe-actions'

import {
  RequestConfig,
  RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import {
  NormalizedUsersApiResponse,
  UsersActionTypes,
  UserApiFieldsWithoutPII,
  usersService,
} from 'app/fieldapp/store/users'

import { getActiveUser } from '../selectors'
export const fetchCurrentCoveragePartnerAction = createAsyncAction(
  UsersActionTypes.FETCH_CURRENT_COVERAGE_PARTNER,
  UsersActionTypes.FETCH_CURRENT_COVERAGE_PARTNER_SUCCESS,
  UsersActionTypes.FETCH_CURRENT_COVERAGE_PARTNER_FAILURE,
)<
  RequestConfig<NormalizedUsersApiResponse>,
  [NormalizedUsersApiResponse, string],
  Error
>()

export const getParams = (): RequestOptions => ({
  fields: { user: UserApiFieldsWithoutPII },
  include: ['current_coverage_partner'],
})

export const fetchCurrentCoveragePartner = () => async (dispatch, getState) => {
  try {
    const state = getState()
    const currentUserId = getActiveUser(state)?.id
    if (!currentUserId) {
      throw new Error('No Existing Active User')
    }

    const request = usersService.fetchUserById.bind(
      null,
      currentUserId,
      getParams(),
    )
    const result = await dispatch(
      fetchCurrentCoveragePartnerAction.request({ request }),
    )
    dispatch(fetchCurrentCoveragePartnerAction.success([result, currentUserId]))
  } catch (error) {
    dispatch(fetchCurrentCoveragePartnerAction.failure(error))
    throw error
  }
}
