import * as React from 'react'

import { Clean } from 'app/fieldapp/store/cleans'

import { InspectionChecklistContextWrapper } from './context/InspectionChecklist.context'
import {
  InspectionChecklist,
  InspectionChecklistProps,
} from './InspectionChecklist'

type PickedInspectionChecklistProps = Pick<
  InspectionChecklistProps,
  'isLoadingClean'
>

export type InspectionChecklistContainerProps =
  PickedInspectionChecklistProps & {
    clean: Clean
  }

export const InspectionChecklistContainer: React.FC<InspectionChecklistContainerProps> =
  React.memo(({ clean, isLoadingClean }) => {
    return (
      <InspectionChecklistContextWrapper clean={clean}>
        <InspectionChecklist isLoadingClean={isLoadingClean} />
      </InspectionChecklistContextWrapper>
    )
  })
