import styled from '@emotion/styled'
import React from 'react'

import { Alert } from 'packages/common'
import { IconName } from 'packages/iconic'

import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { Reservation } from 'app/fieldapp/store/reservations'
import { formatReservationDateTimeAttributes } from 'app/fieldapp/utils'

const St = {
  Alert: styled(Alert)`
    margin: 16px 0 0 0;
  `,
}

const useTranslations = (reservation: Reservation | undefined) => {
  const { t } = useI18n()

  const {
    checkoutDate: checkoutDateStr,
    checkoutTime: checkoutTimeStr,
    checkinDate: checkInDateStr,
    checkinTime: checkInTimeStr,
  } = formatReservationDateTimeAttributes(reservation)

  return {
    unitIsOccupied: t(Slugs.createVisitErrorUnitOccupied, {
      date: checkoutDateStr,
      time: checkoutTimeStr,
    }),
    unitIsVacant: t(Slugs.unitIsVacant, {
      date: checkInDateStr,
      time: checkInTimeStr,
    }),
    vacancyUnknown: t(Slugs.vacancyUnknown),
  }
}

export enum UnitOccupancyDetailsTestIds {
  container = 'UnitOccupancyDetails__container',
}

export type UnitOccupancyDetailsProps = {
  hasUnitFamily: boolean
  isUnitOccupied: boolean
  reservation?: Reservation
}

export const UnitOccupancyDetails: React.FC<UnitOccupancyDetailsProps> =
  React.memo(({ isUnitOccupied, hasUnitFamily, reservation }) => {
    const strings = useTranslations(reservation)

    return (
      <>
        {hasUnitFamily && (
          <St.Alert iconName={IconName.doorClose} alertType={'info'}>
            {strings.vacancyUnknown}
          </St.Alert>
        )}
        {!hasUnitFamily &&
          (isUnitOccupied
            ? !!reservation && (
                <St.Alert iconName={IconName.alertCircle} alertType={'warning'}>
                  {strings.unitIsOccupied}
                </St.Alert>
              )
            : !!reservation && (
                <St.Alert iconName={IconName.doorClose} alertType={'info'}>
                  {strings.unitIsVacant}
                </St.Alert>
              ))}
      </>
    )
  })
