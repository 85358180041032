import styled from '@emotion/styled'

import { ExternalLink as CoreExternalLink } from 'packages/common'
import { colors } from 'packages/styles'

import { TicketSeverity } from 'app/fieldapp/store/tickets'

import { severityColorMap } from '../../schedule/components/TaskSharedStyles/VisitTicket.styles'

export const StatusBar = styled.div<{ severity: TicketSeverity }>`
  background: ${props => severityColorMap[props.severity]};
  padding: 6px 16px;
`

export const Section = styled.section`
  font-size: 14px;
  padding: 16px 24px;

  &:not(:last-child) {
    border-bottom: 1px solid ${colors.midnight20};
  }
`

export const Container = styled.div`
  background: ${colors.white};
  min-height: calc(100vh + 50px);

  pre {
    white-space: pre-line;
  }
`

export const Title = styled.div`
  color: black;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
`

export const SubTitle = styled.div`
  color: ${colors.dusk70};
  font-size: 14px;
  line-height: 20px;
  margin-top: 2px;
`

export const TicketTemplate = styled.div`
  margin-bottom: 16px;
`

export const Description = styled.div`
  margin-bottom: 16px;
`

export const Visibility = styled.div`
  margin-top: 16px;
`

export const ExternalLink = styled(CoreExternalLink)`
  margin-top: 16px;
`
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
