import styled from '@emotion/styled'
import { partition } from 'lodash/fp'
import React from 'react'

import { Alert, Switch } from 'packages/common'
import { IconName } from 'packages/iconic'
import { colors, text } from 'packages/styles'

import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { Ticket } from 'app/fieldapp/store/tickets'
import { compareBySeverityThenDueDate } from 'app/fieldapp/store/tickets/tickets.utils'
import { Unit } from 'app/fieldapp/store/units'
import { Visit } from 'app/fieldapp/store/visits'

import {
  ScheduledVisitIcon,
  TaskDetailContainer,
  TaskIcon,
  VisitDetailHeader,
  VisitHeaderLeftIcons,
} from '../../../TaskSharedStyles'
import { VisitStatusIconsContainer } from '../VisitStatusIcons'
import { VisitTicketCardContainer } from '../VisitTicketCard'

const St = {
  NoOpenTickets: styled.div`
    ${text.bodyRegularSmall}
    font-style: italic;
  `,

  ScheduledVisitIcon,

  SectionContent: styled.div`
    padding: 24px;
    :not(:last-of-type) {
      border-bottom: solid ${colors.midnight30};
      border-width: 1px 0;
    }
  `,

  SectionSubTitle: styled.div`
    ${text.bodyRegularSmall}
    color: ${colors.dusk80};
    display: inline-block;
    margin-top: 8px;
  `,

  SectionTitle: styled.div`
    ${text.fieldLabelMobile}
  `,

  TaskDetailContainer,
  TaskIcon,

  TicketsFilter: styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
  `,

  TicketsFilterSwitch: styled(Switch)`
    width: 56px;
  `,

  TicketsFilterText: styled.div`
    ${text.bodyRegularSmall}
    margin-left: 10px;
    overflow-wrap: break-word;
  `,

  TicketsList: styled.div`
    margin-top: 16px;
  `,

  VisitDetailHeader,
  VisitHeaderLeftIcons,

  VisitTicketAlert: styled(Alert)`
    margin-bottom: 0px;
    margin-top: 16px;
  `,
}

const textStyles: React.CSSProperties = {
  marginLeft: '8px',
}

const useTranslations = () => {
  const { ut } = useI18n()

  return {
    noAttachedTickets: ut(Slugs.noAttachedTickets),
    noOpenTickets: ut(Slugs.noOpenTickets),
    possibleTicketsBody: ut(Slugs.possibleTicketsBody),
    possibleTicketsTitle: ut(Slugs.possibleTicketsTitle),
    visit: ut(Slugs.visit),
    visitTicketFilterAlertText: ut(Slugs.visitTicketFilterAlertText),
    visitTicketFilterToggleText: ut(Slugs.visitTicketFilterToggleText),
    visitTicketSubtitle: ut(Slugs.visitTicketSubtitle),
    visitTicketTitle: ut(Slugs.visitTicketTitle),
  }
}

export enum VisitTicketsTabTestIds {
  noOpenTickets = 'VisitTicketsTabTestIds__noOpenTickets',
}

export type VisitTicketsTabProps = {
  dispositionIds: string[]
  tickets: Ticket[]
  unit: Unit
  visit: Visit
}

export const VisitTicketsTab: React.FC<VisitTicketsTabProps> = React.memo(
  ({ dispositionIds = [], tickets = [], unit, visit }) => {
    const strings = useTranslations()

    const [switchState, setSwitchState] = React.useState(false)

    const [attachedTickets, unattachedTickets] = partition(
      ticket => visit.ticketIds.includes(ticket.id),
      tickets,
    )

    const sortedAttachedTickets = attachedTickets.sort(
      compareBySeverityThenDueDate,
    )

    const sortedUnattachedTickets = unattachedTickets
      .sort(compareBySeverityThenDueDate)
      .filter(ticket =>
        switchState ? dispositionIds.includes(ticket.dispositionId) : true,
      )

    return (
      <St.TaskDetailContainer>
        <St.VisitDetailHeader>
          <St.VisitHeaderLeftIcons>
            <St.TaskIcon
              centerItems={true}
              icon={IconName.maintenance}
              size={18}
              text={strings.visit}
              textFontSize={'inherit'}
              textStyleOverrides={textStyles}
            />
            {visit.serviceType === 'scheduled' && (
              <St.ScheduledVisitIcon
                centerItems={true}
                icon={IconName.refreshCw}
                size={16}
              />
            )}
          </St.VisitHeaderLeftIcons>

          {!!unit && <VisitStatusIconsContainer unitId={unit.id} />}
        </St.VisitDetailHeader>

        <St.SectionContent>
          <St.SectionTitle>{strings.visitTicketTitle}</St.SectionTitle>
          <St.SectionSubTitle>{strings.visitTicketSubtitle}</St.SectionSubTitle>
          {sortedAttachedTickets.length ? (
            <St.TicketsList>
              {sortedAttachedTickets.map(ticket => (
                <VisitTicketCardContainer
                  key={ticket.id}
                  ticketId={ticket.id}
                  visit={visit}
                />
              ))}
            </St.TicketsList>
          ) : (
            <St.VisitTicketAlert alertType={'info'}>
              <div>{strings.noAttachedTickets}</div>
            </St.VisitTicketAlert>
          )}
        </St.SectionContent>

        <St.SectionContent>
          <St.SectionTitle>{strings.possibleTicketsTitle}</St.SectionTitle>
          <St.SectionSubTitle>{strings.possibleTicketsBody}</St.SectionSubTitle>

          <St.TicketsFilter>
            <St.TicketsFilterSwitch
              checked={switchState}
              onToggle={setSwitchState}
            />
            <St.TicketsFilterText>
              {strings.visitTicketFilterToggleText}
            </St.TicketsFilterText>
          </St.TicketsFilter>

          <St.TicketsList>
            {sortedUnattachedTickets.length ? (
              sortedUnattachedTickets.map(ticket => (
                <VisitTicketCardContainer
                  key={ticket.id}
                  ticketId={ticket.id}
                  visit={visit}
                />
              ))
            ) : switchState ? (
              <St.VisitTicketAlert alertType={'info'}>
                <div>{strings.visitTicketFilterAlertText}</div>
              </St.VisitTicketAlert>
            ) : (
              <St.NoOpenTickets
                data-testid={VisitTicketsTabTestIds.noOpenTickets}
              >
                {strings.noOpenTickets}
              </St.NoOpenTickets>
            )}
          </St.TicketsList>
        </St.SectionContent>
      </St.TaskDetailContainer>
    )
  },
)
