import { upperFirst } from 'lodash/fp'
import React from 'react'

import { ClipboardText } from 'packages/common'
import { useI18n } from 'packages/i18n'
import { IconName } from 'packages/iconic'

import {
  InfoRowLabel,
  Details,
  DetailIcon,
  SectionSubTitle,
  UnitInfoContainer,
} from 'app/fieldapp/components/schedule/components/TaskSharedStyles'
import { Slugs } from 'app/fieldapp/i18n'
import { useAppSelector } from 'app/fieldapp/store/hooks'
import { ApplicationState } from 'app/fieldapp/store/store'
import { getVisitDrawerState } from 'app/fieldapp/store/ui/selectors'
import { getUnitById } from 'app/fieldapp/store/units/selectors'
import { getVisitById } from 'app/fieldapp/store/visits/selectors'

const St = {
  DetailIcon,
  Details,
  InfoRowLabel,
  SectionSubTitle,
  UnitInfoContainer,
}

export const VisitWifiInfo: React.FC = React.memo(() => {
  const { t } = useI18n()
  const { visitId } = useAppSelector(getVisitDrawerState)
  const visit = useAppSelector((state: ApplicationState) =>
    getVisitById(state, visitId),
  )
  const unit = useAppSelector((state: ApplicationState) =>
    getUnitById(state, visit?.unitId),
  )

  if (!unit || !visit) return null

  const { amenities } = unit

  return amenities.wifi ? (
    <St.UnitInfoContainer>
      <St.DetailIcon icon={IconName.wifi} size={16} />
      <St.Details>
        <St.SectionSubTitle>{t(Slugs.wifi)}</St.SectionSubTitle>

        <div>
          <St.InfoRowLabel>
            {`${upperFirst(t(Slugs.network))}: `}
          </St.InfoRowLabel>

          {amenities.wifiNetwork}
        </div>

        <div>
          <St.InfoRowLabel>
            {`${upperFirst(t(Slugs.password))}:`}
          </St.InfoRowLabel>

          {amenities.wifiPassword && (
            <ClipboardText>{amenities.wifiPassword}</ClipboardText>
          )}
        </div>
      </St.Details>
    </St.UnitInfoContainer>
  ) : null
})
