import styled from '@emotion/styled'

import { SvgIcon } from 'packages/iconic'
import { colors, text } from 'packages/styles'

export const St = {
  Container: styled.div<{ isDisabled: boolean }>`
    align-items: center;
    background-color: ${colors.white};
    border-radius: 8px;
    border: 1px solid ${colors.gulf};
    cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 12px;
    padding: 8px 16px;
    position: relative;
  `,
  Date: styled.div`
    ${text.bodyRegularDefault}
    color: #000;
    display: flex;
    justify-content: space-between;
  `,
  Dates: styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: space-between;
    max-width: 270px;
    width: 100%;
  `,
  Days: styled.div<{ isRepeating?: boolean }>`
    ${text.headingTinyCaps}
    border-radius: 50px;
    border: 1px solid ${colors.midnight70};
    color: ${colors.midnight70};
    margin-left: auto;
    padding: 1px 6px;

    ${({ isRepeating }) =>
      isRepeating &&
      `
      background-color: ${colors.midnight70};
      color: #fff;
    `}
  `,
  Frequency: styled.div`
    ${text.headingCardSmall}
    color: ${colors.dusk};
    width: calc(45% - 8px);
  `,
  RightChevron: styled(SvgIcon)<{ isDisabled: boolean }>`
    display: ${({ isDisabled }) => (isDisabled ? 'none' : 'block')};
    position: absolute;
    right: 16px;
    transform: translateY(-50%);
    top: 50%;
  `,
  Sup: styled.sup`
    ${text.bodyBoldTiny}
    align-self: flex-start;
    margin-left: -8px;
    margin-top: -4px;
  `,
  Time: styled.div`
    ${text.bodyRegularDefault}
    align-items: center;
    color: #000;
    display: flex;
    gap: 8px;
    width: 100%;
  `,
}
