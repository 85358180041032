import { AxiosResponse } from 'axios'
import { snakeCase } from 'lodash/fp'

import {
  AllTicketAttributes,
  AllTicketAttributeNames,
} from 'packages/grimoire/src/ticket/ticket.types'
import { TicketStatus } from 'packages/grimoire/src/ticket/ticket.types'
import {
  JSONApiObject,
  JSONApiObjectMap,
  JSONApiObjectWithRelationships,
  JSONApiObjectWithRelationshipsMap,
  NormalizedJSONApiResponse,
  ToManyRelationship,
  ToOneRelationship,
} from 'packages/grimoire/src/utils'

import { JSONApiCommentsMap } from 'app/fieldapp/store/comments'
import { JSONApiLockboxesMap } from 'app/fieldapp/store/lockboxes'
import { JSONApiTicketCommentsMap } from 'app/fieldapp/store/ticketComments'
import { JSONApiUnitsMap } from 'app/fieldapp/store/units'
import { JSONApiUsersMap } from 'app/fieldapp/store/users'

import { JSONApiReservationsMap } from '../reservations'

export { TicketSeverity } from 'packages/grimoire/src/ticket/ticket.types'
export { TicketStatus }

export class TicketStatusError extends Error {}

export const severityList = ['Urgent', 'Prior to Next Guest', 'Medium'] as const
export type SeverityType = (typeof severityList)[number]

/**********************************************************
 * REDUX TYPES
 *********************************************************/
export enum TicketsActionTypes {
  CREATE_TICKET = 'TICKETS/API/CREATE_TICKET',
  CREATE_TICKET_FAILURE = 'TICKETS/CREATE_TICKET_FAILURE',
  CREATE_TICKET_SUCCESS = 'TICKETS/CREATE_TICKET_SUCCESS',

  FETCH_ALL_DISPOSITIONS = 'TICKETS/API/FETCH_ALL_DISPOSITIONS',
  FETCH_ALL_DISPOSITIONS_FAILURE = 'TICKETS/FETCH_ALL_DISPOSITIONS_FAILURE',
  FETCH_ALL_DISPOSITIONS_SUCCESS = 'TICKETS/FETCH_ALL_DISPOSITIONS_SUCCESS',

  FETCH_TICKET = 'TICKETS/API/FETCH_TICKET',
  FETCH_TICKETS = 'TICKETS/API/FETCH_TICKETS',
  FETCH_TICKETS_FAILURE = 'TICKETS/FETCH_TICKETS_FAILURE',

  FETCH_TICKETS_SUCCESS = 'TICKETS/FETCH_TICKETS_SUCCESS',
  FETCH_TICKET_FAILURE = 'TICKETS/FETCH_TICKET_FAILURE',
  FETCH_TICKET_SUCCESS = 'TICKETS/FETCH_TICKET_SUCCESS',

  UPDATE_TICKET = 'TICKETS/API/UPDATE_TICKET',
  UPDATE_TICKET_FAILURE = 'TICKETS/UPDATE_TICKET_FAILURE',
  UPDATE_TICKET_SUCCESS = 'TICKETS/UPDATE_TICKET_SUCCESS',
}

export type TicketsState = {
  data: JSONApiTicketsMap
  dispositions: JSONApiDispositionMap
  lastFetch: number
  loading: boolean
  ticketToUnitMap: Record<string, string>
}

/**********************************************************
 * SERVICE TYPES
 *********************************************************/
export type TicketsResponse = {
  comment: JSONApiCommentsMap
  lockBox: JSONApiLockboxesMap
  reservation: JSONApiReservationsMap
  ticket: JSONApiTicketsMap
  ticketComment: JSONApiTicketCommentsMap
  unit: JSONApiUnitsMap
  user: JSONApiUsersMap
}

export type NormalizedTicketsApiResponse =
  NormalizedJSONApiResponse<TicketsResponse>

export type TicketsServiceResponse = Promise<NormalizedTicketsApiResponse>
export type TicketsApiResponse = AxiosResponse<NormalizedTicketsApiResponse>

// PATCH REQUEST DATA
export type TicketPatchAttributes = {
  assigneeId: String
  status: TicketStatus
}

export type TicketPatchData = {
  id: string
} & Partial<TicketPatchAttributes>

// POST REQUEST DATA
export type TicketPostData = {
  assignToSelf: boolean
  assigneeId?: string
  description?: string
  dispositionId: string
  external?: boolean
  inspectionFlagIds: string[]
  severity: SeverityType
  title: string
  unitId: string
  userId: string
  visibility?: string
}

export type TicketPostAPIAttributes = Pick<
  TicketPostData,
  'description' | 'title' | 'severity'
>

export type TicketPostAPIRelationships = {
  assignee?: ToOneRelationship
  creator: ToOneRelationship
  disposition: ToOneRelationship
  inspection_flags?: ToManyRelationship
  unit: ToOneRelationship
}

/**********************************************************
 * DISPOSITIONS
 *********************************************************/
export type DispositionAttributes = {
  tier3Display: string
  tierOne: string
  tierThree: string
  tierTwo: string
}

export const DispositionAttributeNames = [
  'tier3Display',
  'tierOne',
  'tierThree',
  'tierTwo',
]

export const DispositionApiFields = DispositionAttributeNames.map(snakeCase)

export type DispositionResponse = {
  disposition: JSONApiObjectMap<DispositionAttributes>
}

export type NormalizedDispositionResponse =
  NormalizedJSONApiResponse<DispositionResponse>

export type DispositionServiceResponse = Promise<NormalizedDispositionResponse>
export type DispositionApiResponse =
  AxiosResponse<NormalizedDispositionResponse>

export type NormalizedDispositionApiResponse =
  NormalizedJSONApiResponse<DispositionResponse>

export type JSONApiDispositionMap = JSONApiObjectMap<DispositionAttributes>

export type RawDisposition = JSONApiObject<DispositionAttributes>

export type Disposition = {
  id: string
} & DispositionAttributes

/**********************************************************
 * TICKET
 *********************************************************/
export type Ticket = {
  assigneeId: string
  commentIds: string[]
  dispositionId: string
  dueDateNoTimestamp: string
  id: string
  lockboxId: string
  openVisitId: string
  ownerCommentStatus: 'none' | 'newest' | 'exists'
  templateBody: string
  unitId: string
  visibility: string
} & AllTicketAttributes

export const TicketAttributeNames = [
  ...AllTicketAttributeNames,
  'ownerCommentStatus',
  'templateBody',
  'visibility',
]

export const TicketApiFields: string[] = TicketAttributeNames.map(snakeCase)

export type TicketRelationships = {
  assignee: ToOneRelationship
  comments: ToManyRelationship
  disposition: ToOneRelationship
  lockBox: ToOneRelationship
  openVisit: ToOneRelationship
  unit: ToOneRelationship
}

export type JSONApiTicketsMap = JSONApiObjectWithRelationshipsMap<
  AllTicketAttributes,
  TicketRelationships
>

export type RawTicket = JSONApiObjectWithRelationships<
  AllTicketAttributes,
  TicketRelationships
>
