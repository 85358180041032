export type GenericTimer = {
  id: string
} & GenericTimerAttributes

export type GenericTimerAttributes = {
  isFinalized: boolean
  startedAt: string
  startedOffline?: boolean
  stoppedAt: string | null
}

export enum TimerType {
  CLEAN = 'clean',
  OTHER = 'other',
  TICKET = 'ticket',
}
