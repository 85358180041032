import { createSelector } from 'reselect'

import { findRelationshipId } from 'packages/utils/store'

import { ApplicationState } from 'app/fieldapp/store/store'
import { hydrateRawUser, User } from 'app/fieldapp/store/users'

export const getUserByHkId = createSelector(
  (state: ApplicationState) => state,
  (_, hkId: string) => hkId,
  (state, hkId): User | undefined => {
    const hk = state.housekeepers.data[hkId]
    const userId = findRelationshipId(hk, 'user') || ''
    const rawUser = state.users.data[userId]

    return rawUser ? hydrateRawUser(rawUser) : undefined
  },
)
