import styled from '@emotion/styled'
import React from 'react'

import { Button } from 'packages/common'
import { getTaskProgressStatus, TaskProgressStatus } from 'packages/grimoire'
import { IconName, SvgIcon } from 'packages/iconic'
import { colors, text } from 'packages/styles'
import { AsyncState } from 'packages/utils/hooks'

import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { Visit } from 'app/fieldapp/store/visits'

type Styles = {
  bg: string
  border: string
  borderWidth: string
  color: string
}

const stylesByStatus: Record<TaskProgressStatus, Styles> = {
  completed: {
    bg: 'white',
    border: `solid ${colors.midnight20}`,
    borderWidth: '1px 0',
    color: colors.dusk80,
  },
  inProgress: {
    bg: colors.midnight70,
    border: 'none',
    borderWidth: '0',
    color: 'white',
  },
  notStarted: {
    bg: 'white',
    border: `solid ${colors.midnight20}`,
    borderWidth: '1px 0',
    color: colors.dusk80,
  },
}

const St = {
  CompleteIcon: styled(SvgIcon)`
    color: ${colors.success};
  `,
  StatusText: styled.span`
    ${text.bodyBold};
    text-transform: uppercase;
  `,
  VisitStatusBanner: styled.div<{ status: TaskProgressStatus }>`
    align-items: center;
    background-color: ${({ status }) => stylesByStatus[status].bg};
    border: ${({ status }) => stylesByStatus[status].border};
    border-width: ${({ status }) => stylesByStatus[status].borderWidth};
    color: ${({ status }) => stylesByStatus[status].color};
    display: flex;
    height: 68px;
    justify-content: space-between;
    padding: 24px;
  `,
}

const useTranslations = (status: TaskProgressStatus) => {
  const { ut } = useI18n()

  const buttonTextByStatus: Record<TaskProgressStatus, string> = {
    completed: '',
    inProgress: ut(Slugs.markDone),
    notStarted: ut(Slugs.start),
  }

  const textByStatus: Record<TaskProgressStatus, string> = {
    completed: ut(Slugs.complete),
    inProgress: ut(Slugs.inProgress),
    notStarted: ut(Slugs.notStarted),
  }

  return {
    buttonText: buttonTextByStatus[status],
    progressText: textByStatus[status],
  }
}

export type VisitStatusBannerProps = {
  canUpdateVisit: boolean
  onUpdateVisit: () => void
  requestState: AsyncState<unknown>
  visit: Visit
}

export const VisitStatusBanner: React.FC<VisitStatusBannerProps> = React.memo(
  ({ canUpdateVisit, onUpdateVisit, requestState, visit }) => {
    const status = getTaskProgressStatus(visit)
    const strings = useTranslations(status)

    return (
      <St.VisitStatusBanner status={status}>
        <St.StatusText>{strings.progressText}</St.StatusText>

        {status === 'completed' ? (
          <St.CompleteIcon icon={IconName.checkCircle} size={30} />
        ) : (
          <Button
            buttonType={status === 'inProgress' ? 'danger' : 'primary'}
            disabled={!canUpdateVisit}
            isLoading={requestState.loading}
            onClick={onUpdateVisit}
          >
            {strings.buttonText}
          </Button>
        )}
      </St.VisitStatusBanner>
    )
  },
)
