import React from 'react'
import { useSelector } from 'react-redux'

import { useManageConnectedDrawerState } from 'packages/common/src/modals/Drawer/hooks/useManageConnectedDrawerState'

import { setCrossCoverageDrawer } from 'app/fieldapp/store/ui/actions'
import { getCrossCoverageDrawerState } from 'app/fieldapp/store/ui/selectors'

import {
  UpcomingCoverageEvent,
  UpcomingCoverageEventProps,
} from './UpcomingCoverageEvent'

export type UpcomingCoverageEventContainerProps = Pick<
  UpcomingCoverageEventProps,
  'coverageEvent'
>

export const UpcomingCoverageEventContainer: React.FC<
  UpcomingCoverageEventContainerProps
> = ({ coverageEvent }) => {
  const crossCoverageDrawerManager = useManageConnectedDrawerState({
    dispatchableToggleDrawerAction: setCrossCoverageDrawer,
    selector: getCrossCoverageDrawerState,
  })

  const handleCoverageEventClick = () => {
    crossCoverageDrawerManager.openDrawer({
      crossCoverageId: coverageEvent.id,
    })
  }

  const isCrossCoverageDrawerOpen = useSelector(
    getCrossCoverageDrawerState,
  )?.isOpen

  return (
    <UpcomingCoverageEvent
      coverageEvent={coverageEvent}
      handleCoverageEventClick={handleCoverageEventClick}
      isCrossCoverageDrawerOpen={isCrossCoverageDrawerOpen}
    />
  )
}
