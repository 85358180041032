import { createAsyncAction } from 'typesafe-actions'

import { Optional } from 'packages/grimoire/src/utils'
import { OfflineRequestConfig } from 'packages/utils/store'

import { makeOfflineTimer } from 'app/fieldapp/components/timers/timers.helpers'
import {
  NormalizedTicketTimesApiResponse,
  TicketTimePatchData,
  TicketTimesActionTypes,
} from 'app/fieldapp/store/ticket-times'

import { offlineTimers } from '../../utils'

export const updateTicketTimeOfflineAction = createAsyncAction(
  TicketTimesActionTypes.OFFLINE_UPDATE_TICKET_TIME,
  TicketTimesActionTypes.OFFLINE_UPDATE_TICKET_TIME_SUCCESS,
  TicketTimesActionTypes.OFFLINE_UPDATE_TICKET_TIME_FAILURE,
)<
  OfflineRequestConfig<NormalizedTicketTimesApiResponse, TicketTimePatchData>,
  Optional<
    OfflineRequestConfig<NormalizedTicketTimesApiResponse, TicketTimePatchData>,
    'request'
  >,
  Error
>()

export const updateTicketTimeOffline =
  (
    payload: Optional<
      OfflineRequestConfig<
        NormalizedTicketTimesApiResponse,
        TicketTimePatchData
      >,
      'request'
    >,
  ) =>
  async dispatch => {
    const existingTimer = await offlineTimers.getTicketTimeByID(payload.data.id)

    const timerData: TicketTimePatchData = existingTimer
      ? ({
          ...existingTimer.attributes,
          ...payload.data,
        } as unknown as TicketTimePatchData)
      : payload.data

    await offlineTimers.saveTicketTime(makeOfflineTimer(timerData))

    dispatch(updateTicketTimeOfflineAction.success({ data: timerData }))
  }
