/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { trackCreateTimer, createTimerActions } from './trackers'

export const segmentMiddleware =
  ({ getState }) =>
  next =>
  action => {
    if (createTimerActions.has(action.type)) {
      trackCreateTimer(action, getState())
    }

    next(action)
  }
