import { useSelector } from 'react-redux'

import { HkEmployeeType, User } from 'packages/grimoire'

import { getDelegateUserId } from 'app/fieldapp/store/auth/selectors'
import {
  getActiveUser,
  getActiveUserHkId,
} from 'app/fieldapp/store/users/selectors'

type UseActiveUser = {
  delegateUserId: string | undefined
  employeeType: string | undefined
  housekeeperId: string | undefined
  isAgency: boolean
  isContractor: boolean
  isEmployee: boolean
  user: User | undefined
}

// TPD-6357 updated to pull the employeeType off the user not the hk
export const useActiveUser = (): UseActiveUser => {
  const user = useSelector(getActiveUser)
  const delegateUserId = useSelector(getDelegateUserId)
  const housekeeperId = useSelector(getActiveUserHkId)

  const isEmployee: boolean = user?.employeeType === HkEmployeeType.employee
  const isContractor: boolean = user?.employeeType === HkEmployeeType.contractor
  const isAgency: boolean = user?.employeeType === HkEmployeeType.agency
  const employeeType: string | undefined = user?.employeeType || undefined

  return {
    delegateUserId,
    employeeType,
    housekeeperId,
    isAgency,
    isContractor,
    isEmployee,
    user,
  }
}
