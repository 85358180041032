import { datadogRum } from '@datadog/browser-rum'
import { getUserLogsContext } from 'packages/utils/misc'

const envShorthand = {
  development: 'dev',
  production: 'prod',
  staging: 'stage',
}

let initialized = false

export const initializeDatadogRum = () => {
  if (initialized) return

  const clientToken = process.env.REACT_APP_DATADOG_RUM_FA_CLIENT_TOKEN ?? ''

  if (!clientToken) {
    // eslint-disable-next-line no-console
    console.warn(
      'Datadog RUM could not be initialized because no client token was provided.',
    )
    return
  }

  const environment = process.env.REACT_APP_ENV || 'development'
  const env = envShorthand[environment]
  const isProd = environment === 'production'

  if (isProd) {
    initialized = true
    datadogRum.init({
      applicationId: process.env.REACT_APP_DATADOG_RUM_FA_APPLICATION_ID,
      clientToken,
      site: 'datadoghq.com',
      service: 'field-app',
      env,
      version: process.env.REACT_APP_RELEASE,
      sessionSampleRate: 20,
      sessionReplaySampleRate: 10,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'allow',
    })

    const userLogsContext = getUserLogsContext()

    datadogRum.setUser(userLogsContext)
  }
}
