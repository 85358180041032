import { produce } from 'immer'

import { formatInTimeZone, DateFormat } from 'packages/utils/dateHelpers'
import { transformNormalizedToTyped } from 'packages/utils/store'

import {
  CrossCoverage,
  CrossCoverageAttributeNames,
  CrossCoverageResponse,
  NormalizedCrossCoverageApiResponse,
  RawCrossCoverage,
} from './crossCoverage.types'

export const emptyNormalizedCrossCoverageData: CrossCoverageResponse =
  Object.freeze({
    crossCoverage: {},
    user: {},
  })

export const emptyCrossCoverageResponse: NormalizedCrossCoverageApiResponse =
  Object.freeze({
    normalized: emptyNormalizedCrossCoverageData,
    raw: { data: [] },
  })

export const hydrateRawCrossCoverage = (
  raw: RawCrossCoverage,
): CrossCoverage => {
  return produce(
    transformNormalizedToTyped<CrossCoverage>(raw, CrossCoverageAttributeNames),
    draft => {
      draft.userCoveringId = raw?.relationships?.userCovering?.data?.id || ''
      draft.startDateTimeUTC = formatInTimeZone(
        //before US team send as fist input attribute bad iso sting (2024-07-04T14:00:04.815ZT00:00) and because of the bug in the old version of date-fns-tz it worked somehow
        `${raw.attributes.startDate.split('T')[0]}T${
          raw.attributes.startTimeLocal
        } ${raw.attributes.tz}`,
        'UTC',
        DateFormat.IsoDateAndTimeWithOffset,
      )

      draft.endDateTimeUTC = formatInTimeZone(
        //before US team send as fist input attribute bad iso sting (2024-07-04T14:00:04.815ZT00:00) and because of the bug in the old version of date-fns-tz it worked somehow
        `${raw.attributes.endDate.split('T')[0]}T${
          raw.attributes.endTimeLocal
        } ${raw.attributes.tz}`,
        'UTC',
        DateFormat.IsoDateAndTimeWithOffset,
      )
    },
  )
}
