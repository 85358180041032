import { noop } from 'lodash/fp'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { useAsync } from 'react-use'

import { Loader } from 'packages/common'
import { splitSearchQuery } from 'packages/utils/misc'

import { fetchSharedCleanById } from 'app/fieldapp/store/cleans/actions'
import { getCleanById } from 'app/fieldapp/store/cleans/selectors'
import { getIncludedLockboxByUnitId } from 'app/fieldapp/store/lockboxes/selectors'
import { getIncludedSmartLockByCleanId } from 'app/fieldapp/store/smartlocks/selectors'
import { AppDispatch, ApplicationState } from 'app/fieldapp/store/store'

import { CleanDetailWrapper } from '../components/CleanDetail/CleanDetailWrapper'
import { SHARED_CLEAN_INVALID_URL } from '../schedule.utils'

export type SharedCleanPageContainerProps = {}

export const SharedCleanPageContainer: React.FC<SharedCleanPageContainerProps> =
  React.memo(() => {
    const dispatch: AppDispatch = useDispatch()

    const { selectedClean = '' } = splitSearchQuery(window.location.search)

    const fetchCleanState = useAsync(async () => {
      if (!selectedClean) return
      await dispatch(fetchSharedCleanById(selectedClean))
    }, [selectedClean])

    const clean = useSelector((state: ApplicationState) =>
      getCleanById(state, selectedClean),
    )

    const lockbox = useSelector((state: ApplicationState) =>
      getIncludedLockboxByUnitId(state, clean?.unit.id || ''),
    )

    const smartLock = useSelector((state: ApplicationState) =>
      getIncludedSmartLockByCleanId(state, clean?.id || ''),
    )

    if (fetchCleanState.loading) {
      return <Loader />
    }

    if (!clean) {
      return <Navigate to={SHARED_CLEAN_INVALID_URL} />
    }

    return (
      <CleanDetailWrapper
        clean={clean}
        isLoadingClean={false}
        lockbox={lockbox}
        onCloseDrawerClick={noop}
        smartLock={smartLock}
      />
    )
  })
