import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ConfirmModalSlugsType, useCompleteModal } from 'packages/common'
import { ReduxDispatch } from 'packages/grimoire/src/utils'
import { useOnlineStatus } from 'packages/utils/hooks/useOnlineStatus'
import { logError } from 'packages/wiretap/logging'

import { useTimers as useTimersContext } from 'app/fieldapp/components/timers/state/timers.context'
import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { ApplicationState } from 'app/fieldapp/store/store'
import { getActiveTicketTime } from 'app/fieldapp/store/ticket-times/selectors'
import { TicketStatus, TicketStatusError } from 'app/fieldapp/store/tickets'
import { updateTicket } from 'app/fieldapp/store/tickets/actions'

import { useTicketsError } from '../../hooks'

const completeModalSlugs: ConfirmModalSlugsType = {
  message: Slugs.completeTicketModalText,
  title: Slugs.completeTicketModalTitle,
}

function useHasActiveTimer(ticketId: string): boolean {
  // eslint-disable-next-line eqeqeq
  return useSelector(getActiveTicketTime)?.ticketId == ticketId
}

type TicketStatusHook = {
  disabled?: boolean
  loading?: boolean
  onClick?: () => void
  text: string
}

export function useTicketStatus(
  id: string,
  status: TicketStatus,
): TicketStatusHook {
  const { ut } = useI18n()
  const { isOnline } = useOnlineStatus()
  const { handleError } = useTicketsError()
  const { state: timersState } = useTimersContext()
  const { submissionData } = timersState

  const hasActiveTimer = useHasActiveTimer(id)

  const dispatch = useDispatch<ReduxDispatch<ApplicationState>>()
  const [loading, setIsLoading] = React.useState(false)

  const updateStatus = async (newStatus: TicketStatus) => {
    setIsLoading(true)
    try {
      await dispatch(updateTicket({ id, status: newStatus }))
    } catch (err) {
      handleError(
        new TicketStatusError(
          `failed to change status for ticket: ${id}, status: ${status}`,
        ),
      )
    } finally {
      setIsLoading(false)
    }
  }

  const { showModal: showCompleteModal } = useCompleteModal({
    onConfirm: () => updateStatus(TicketStatus.RESOLVED_COMPLETED),
    slugs: completeModalSlugs,
  })

  const disabled = !isOnline() || hasActiveTimer || !!submissionData

  switch (status) {
    case TicketStatus.ASSIGNED:
      return {
        disabled,
        loading,
        onClick: () => updateStatus(TicketStatus.ACCEPTED),
        text: ut(Slugs.ticketStatusActionAccept),
      }

    case TicketStatus.ACCEPTED:
      return {
        disabled,
        loading,
        onClick: showCompleteModal,
        text: ut(Slugs.ticketStatusActionComplete),
      }

    case TicketStatus.RESOLVED_COMPLETED:
      return {
        disabled: true,
        text: ut(Slugs.completed),
      }

    default:
      logError(new Error(`Unhandled ticket status: ${status}, id: ${id}`))

      return {
        disabled: true,
        text: '',
      }
  }
}
