import { createSelector } from 'reselect'

import { ApplicationState } from 'app/fieldapp/store/store'

import { OtherTimer, OtherTimersState } from '../otherTimers.types'
import { hydrateRawOtherTime } from '../otherTimers.utils'

const getOtherTimersState = (state: ApplicationState): OtherTimersState =>
  state.otherTimers

const getOtherTimerId = (
  _state: ApplicationState,
  otherTimerId: string | undefined,
): string | undefined => otherTimerId

export const getOtherTimeById = createSelector(
  getOtherTimersState,
  getOtherTimerId,
  (otherTimersState, id): OtherTimer | undefined => {
    if (!id) return undefined
    const rawOtherTime = otherTimersState.data[id]
    return rawOtherTime && hydrateRawOtherTime(rawOtherTime)
  },
)
