import { createSelector } from 'reselect'

import { isBefore, isSameSecond } from 'packages/utils/dateHelpers'

import { ApplicationState } from 'app/fieldapp/store/store'

import type { RawTicketComment } from '../ticketComments.types'

const getTicketCommentsData = (state: ApplicationState) =>
  state.ticketComments.data
const getCommentIds = (_, commentIds: string[]) => commentIds

function sortByCreatedAt(a: RawTicketComment, b: RawTicketComment): number {
  const createdA = a.attributes.monolithTimestamp
  const createdB = b.attributes.monolithTimestamp

  if (isSameSecond(createdA, createdB)) return 0
  return isBefore(createdA, createdB) ? 1 : -1
}

/**
 * Returns an array of all ticketComment IDs sorted by their 'createdAt,' with newest first.
 */
export const getSortedTicketCommentIds = createSelector(
  getTicketCommentsData,
  getCommentIds,
  (commentsData, commentIds): string[] => {
    const relevantComments = commentIds
      .map(id => commentsData[id])
      .filter(Boolean) as RawTicketComment[]

    return relevantComments.sort(sortByCreatedAt).map(({ id }) => id)
  },
)
