import { createAsyncAction } from 'typesafe-actions'

import {
  RequestConfig,
  RequestOptions,
  JsonApiErrorCodes,
} from 'packages/utils/store/jsonapi.types'
import { hasErrorCode } from 'packages/utils/store/jsonapi.utils'

import { ticketsService } from '../tickets.service'
import {
  TicketStatus,
  TicketsActionTypes,
  NormalizedTicketsApiResponse,
} from '../tickets.types'

export const fetchTicketsAction = createAsyncAction(
  TicketsActionTypes.FETCH_TICKETS,
  TicketsActionTypes.FETCH_TICKETS_SUCCESS,
  TicketsActionTypes.FETCH_TICKETS_FAILURE,
)<
  RequestConfig<NormalizedTicketsApiResponse>,
  NormalizedTicketsApiResponse,
  Error
>()

export const fetchTickets = (userId: string) => async dispatch => {
  const params: RequestOptions = {
    filter: {
      status: {
        in: [TicketStatus.ASSIGNED, TicketStatus.ACCEPTED],
      },
      user_id: userId,
    },
    include: [
      'assignee',
      'comments',
      'comments.user',
      'open_visit',
      'unit',
      'unit.open_visits',
      'lock_box',
    ],
    // legacy API max results limited to 100
    page: { size: 100 },
  }

  const request = ticketsService.fetchTickets.bind(null, params)

  try {
    const result = await dispatch(fetchTicketsAction.request({ request }))

    dispatch(fetchTicketsAction.success(result))
    return result
  } catch (error) {
    dispatch(fetchTicketsAction.failure(error))

    if (hasErrorCode(JsonApiErrorCodes.MISSING_MONOLITH_USER_ID, error)) {
      return
    }

    throw error
  }
}
