import { format } from 'packages/utils/dateHelpers'

import { Slugs, useI18n } from 'app/fieldapp/i18n'

type useDayTranslations = {
  friday: string
  monday: string
  saturday: string
  sunday: string
  thursday: string
  tuesday: string
  wednesday: string
}

export const useDayNameTranslationsShort = (): useDayTranslations => {
  const { t } = useI18n()
  return {
    friday: t(Slugs.fri),
    monday: t(Slugs.mon),
    saturday: t(Slugs.sat),
    sunday: t(Slugs.sun),
    thursday: t(Slugs.thur),
    tuesday: t(Slugs.tue),
    wednesday: t(Slugs.wed),
  }
}

const slugsMap = {
  0: {
    long: Slugs.sunday,
    short: Slugs.sun,
  },
  1: {
    long: Slugs.monday,
    short: Slugs.mon,
  },
  2: {
    long: Slugs.tuesday,
    short: Slugs.tue,
  },
  3: {
    long: Slugs.wednesday,
    short: Slugs.wed,
  },
  4: {
    long: Slugs.thursday,
    short: Slugs.thur,
  },
  5: {
    long: Slugs.friday,
    short: Slugs.fri,
  },
  6: {
    long: Slugs.saturday,
    short: Slugs.sat,
  },
}

type DateTranslationFn = (date: Date) => string

const useDayOfWeekTranslation = (
  dateFormat: 'short' | 'long',
): DateTranslationFn => {
  const { t } = useI18n()

  const translate = (date: Date): string => {
    const ISODateIndex = Number(format(date, 'i'))
    // The above is a "1" based index starting on Monday, but we want a "0" based index starting on Sunday
    const dateIndex = ISODateIndex === 7 ? 0 : ISODateIndex

    if (dateFormat === 'short') {
      return t(slugsMap[dateIndex].short)
    }

    return t(slugsMap[dateIndex].long)
  }

  return translate
}

export const useDayOfWeekTranslationShort = (): DateTranslationFn =>
  useDayOfWeekTranslation('short')

export const useDayOfWeekTranslationLong = (): DateTranslationFn =>
  useDayOfWeekTranslation('long')
