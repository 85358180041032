import { createSelector } from 'reselect'

import { isToday } from 'packages/utils/dateHelpers'

import { ApplicationState } from 'app/fieldapp/store/store'

import { getTasksByAssignmentIds } from '../../tasks/selectors'
import { CleanTime, RawCleanTime } from '../cleantimes.types'
import { getCleanTimeById } from './getCleanTimeById'

const isSubmittable = (rtt: RawCleanTime): boolean =>
  !!rtt.attributes.startedAt &&
  !!rtt.attributes.stoppedAt &&
  !rtt.attributes.isFinalized &&
  isToday(rtt.attributes.stoppedAt)

const getFullState = (state: ApplicationState): ApplicationState => state

/**
 * Finds and returns the first CleanTime in a "submittable" state. This means that the timer
 * has been stopped but not finalized, and has no associated offline data currently saved.
 * These are effectively "rogue" timers that need to immediately be either submitted
 * or deleted, as we no longer have any UI concept for "paused" timers.
 */
export const getSubmittableCleanTime = createSelector(
  getFullState,
  (fullState): CleanTime | undefined => {
    const rawCleanTimes: RawCleanTime[] = Object.values(
      fullState.cleanTimes.data,
    )
    const rawSubmittableCleanTime = rawCleanTimes.find(isSubmittable)
    if (!rawSubmittableCleanTime) return undefined

    // ignore any timers with associated offline data (these will show up in offline timers UI)
    const offlineTimer =
      fullState.cleanTimes.offlineData[rawSubmittableCleanTime.id]
    if (offlineTimer) return undefined

    // ignore any timers associated with cleans that are no longer assigned to current user
    const assignmentId =
      rawSubmittableCleanTime.relationships.assignment.data.id
    const tasks = getTasksByAssignmentIds(fullState, [assignmentId])
    if (!Object.values(tasks).length) return undefined

    return getCleanTimeById(fullState, rawSubmittableCleanTime.id)
  },
)
