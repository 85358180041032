import { createSelector } from 'reselect'

import { hydrateRawClean, RawClean } from '../../cleans'
import { ApplicationState } from '../../store'
import { hydrateRawVisit, RawVisit } from '../../visits'
import { AssignmentToTaskMap } from '../tasks.types'

const getFullState = (state: ApplicationState): ApplicationState => state
const getAssignmentIds = (_state: ApplicationState, ids: string[]): string[] =>
  ids

/**
 * Returns a map of tasks (cleans or visits) keyed by the assignment IDs passed in, where a given
 * clean is associated with a given assignment.
 *
 * If for any reason no task is found for a given assignment ID, it will simply
 * be excluded from the map (rather than inserting a null placeholder).
 *
 * We currently do NOT handle the case where a task is in both cleans and visits. This might be worth future
 * consideration since they come from the same endpoint, but this should NOT happen.
 */
export const getTasksByAssignmentIds = createSelector(
  getFullState,
  getAssignmentIds,
  (state, assignmentIds): AssignmentToTaskMap => {
    const cleanHydrator = hydrateRawClean(state)

    return assignmentIds.reduce((acc, assignmentId): AssignmentToTaskMap => {
      const rawClean = Object.values(state.cleans.data).find(
        (rawClean: RawClean) => {
          const assignments =
            rawClean.relationships?.activeAssignments?.data || []
          for (let i = 0; i < assignments.length; i += 1) {
            if (assignments[i].id === assignmentId) {
              return true
            }
          }

          return false
        },
      )

      const rawVisit = Object.values(state.visits.data).find(
        (rawVisit: RawVisit) => {
          const assignments =
            rawVisit.relationships?.activeAssignments?.data || []
          for (let i = 0; i < assignments.length; i += 1) {
            if (assignments[i].id === assignmentId) {
              return true
            }
          }

          return false
        },
      )

      if (rawClean) {
        acc[assignmentId] = cleanHydrator(rawClean)
      }

      if (rawVisit) {
        acc[assignmentId] = hydrateRawVisit(rawVisit)
      }

      return acc
    }, {} as AssignmentToTaskMap)
  },
)
