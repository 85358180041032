import { union } from 'lodash'
import { createAsyncAction } from 'typesafe-actions'

import { apiFilterExcludeVisits } from 'packages/grimoire'
import {
  RequestConfig,
  RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import { AssignmentApiFields } from '../../assignments'
import { HousekeeperApiFields } from '../../housekeepers'
import { LockboxApiFields } from '../../lockboxes'
import { ReservationApiFields } from '../../reservations'
import { SmartLockApiFields } from '../../smartlocks'
import { NormalizedTasksApiResponse } from '../../tasks'
import { tasksService } from '../../tasks/tasks.service'
import { UnitApiFields } from '../../units'
import { UserApiFieldsWithoutPII } from '../../users'
import { CleanApiFields, CleansActionTypes } from '../cleans.types'

// add in manager_info to unit fields - this is only used in FA
const unitFields = union(['manager_info'], UnitApiFields)

export const fetchCleanByIdAction = createAsyncAction(
  CleansActionTypes.FETCH_CLEAN,
  CleansActionTypes.FETCH_CLEAN_SUCCESS,
  CleansActionTypes.FETCH_CLEAN_FAILURE,
)<
  RequestConfig<NormalizedTasksApiResponse>,
  NormalizedTasksApiResponse,
  Error
>()

export const getParams = (): RequestOptions => ({
  fields: {
    assignment: AssignmentApiFields,
    housekeeper: HousekeeperApiFields,
    lock_box: LockboxApiFields,
    reservation: ReservationApiFields,
    smart_lock: SmartLockApiFields,
    task: CleanApiFields,
    unit: unitFields,
    user: UserApiFieldsWithoutPII,
  },
  filter: {
    ...apiFilterExcludeVisits,
  },
  include: [
    'active_assignments',
    'active_assignments.housekeeper',
    'active_assignments.housekeeper.user',
    'task_photos',
    'reservation',
    'smart_locks',
    'unit',
    'unit.current_reservation',
    'unit.lock_box',
    'unit.next_reservation',
    'unit.previous_reservation',
  ],
  page: { size: 100 },
})

export const fetchCleanById = (cleanId: string) => async dispatch => {
  try {
    const params = getParams()
    const request = tasksService.fetchTaskById.bind(null, cleanId, params)
    const result = await dispatch(fetchCleanByIdAction.request({ request }))
    dispatch(fetchCleanByIdAction.success(result))
    return result.normalized
  } catch (error) {
    dispatch(fetchCleanByIdAction.failure(error))
    throw error
  }
}
