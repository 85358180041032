import {
  addDays,
  DateFormat,
  format,
  startOfToday,
  subDays,
} from 'packages/utils/dateHelpers'

import {
  CLEANS_TIMELINE_DATE_RANGE_SHORT,
  CLEANS_TIMELINE_PREV_DAYS,
} from '../FieldApp.constants'

export const useTimelineDateRange = (): {
  endDate: string
  range: number
  startDate: string
} => {
  const range = CLEANS_TIMELINE_DATE_RANGE_SHORT

  const today = startOfToday()

  const startDate = format(
    subDays(today, CLEANS_TIMELINE_PREV_DAYS),
    DateFormat.ApiUtcShort,
  )

  const endDate = format(addDays(today, range), DateFormat.ApiUtcShort)

  return {
    endDate,
    range,
    startDate,
  }
}
