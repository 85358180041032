import * as React from 'react'

import { ClipboardText, ExternalLink } from 'packages/common'
import {
  cleanIsDeepClean,
  getDeepCleanPredictedTime,
  isWithinFreezeWindow,
} from 'packages/grimoire'
import { IconName, SvgIcon } from 'packages/iconic'
import { differenceInDays, startOfToday } from 'packages/utils/dateHelpers'
import { useOnlineStatus } from 'packages/utils/hooks'
import { getVacasaViewUnitURL } from 'packages/utils/links'
import { convertHoursToTimeString } from 'packages/utils/mathHelpers'

import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { Clean } from 'app/fieldapp/store/cleans'
import { Lockbox } from 'app/fieldapp/store/lockboxes/lockboxes.types'
import { SmartLock } from 'app/fieldapp/store/smartlocks/smartlocks.types'
import {
  OrderedBathAmenities,
  OrderedBedAmenities,
} from 'app/fieldapp/store/units'
import { useActiveUser } from 'app/fieldapp/utils/hooks/useActiveUser'

import { CleanStatus } from '../CleanStatus'
import { TaskDetailUnitLinks } from '../TaskDetailUnitLinks'
import {
  composeOrderedAmenityString,
  shouldRenderContractorRate,
} from './CleanDetail.helpers'
import {
  CleanDetailHeader,
  CleanDetailsUnitLinks,
  EditTypeButton,
  PrivacyNoticeSection,
} from './CleanDetail.styles'
import { CleanJobTypeEditorContainer } from './CleanJobTypeEditor'
import {
  AccessCodes,
  AddressInfo,
  DateInfo,
  TeamCleanInfo,
  WifiInfo,
} from './components'
import { CleanNotesDisplay } from './components/CleanNotesDisplay'

import styles from './CleanDetail.module.scss'

const URL =
  'https://www.vacasa.com/policies/privacy-notice-employees-and-contractors'

const St = {
  CleanDetailHeader,
  CleanDetailsUnitLinks,
  EditTypeButton,
  PrivacyNoticeSection,
}

export enum CleanDetailTestIds {
  container = 'CleanDetail__container',
  hotTubInfo = 'CleanDetail__hotTubInfo',
}

export type CleanDetailProps = {
  clean: Clean
  lockbox?: Lockbox
  /**
   * (Optional) SmartLock data for this Clean
   * Note that if a clean has both a smartLock and a regular lockbox, we should always prefer the smartLock
   */
  smartLock?: SmartLock
}

export const CleanDetail: React.FC<CleanDetailProps> = React.memo(
  ({ clean, lockbox, smartLock }) => {
    const { t, tp } = useI18n()
    const { isEmployee } = useActiveUser()
    const shouldRenderRate = shouldRenderContractorRate(
      clean.assignments,
      isEmployee,
    )

    const [isEditingCleanJobType, setIsEditingCleanJobType] =
      React.useState(false)

    const { predictedCleanTime, reservation, unit } = clean
    const effectivePredictedCleanTime = cleanIsDeepClean(clean)
      ? getDeepCleanPredictedTime(clean)
      : predictedCleanTime

    const { dogs, guests } = reservation
    const { amenities, bathrooms, beds, housekeeperNotes, id: unitId } = unit

    const frozen = isWithinFreezeWindow(clean.effectiveDate)

    const lastResString = `${tp(Slugs.guest, guests)}, ${tp(Slugs.dog, dogs)}`
    const bedTypesString = composeOrderedAmenityString(
      OrderedBedAmenities,
      amenities,
      tp,
    )
    const bathTypesString = composeOrderedAmenityString(
      OrderedBathAmenities,
      amenities,
      tp,
    )

    const hottubAvailable = !!amenities.hotTub && amenities.hotTub > 0

    const toggleEditJobType = () => {
      setIsEditingCleanJobType(prev => !prev)
    }

    const isOnline = useOnlineStatus().isOnline()

    const shouldRenderEditTypeButton =
      !isEditingCleanJobType &&
      !clean.completedAt &&
      cleanIsDeepClean(clean) &&
      isOnline

    // TPD-6175 We only request smartlock access codes for cleans three days away
    const smartLockAccessGranted =
      differenceInDays(clean.effectiveDate, startOfToday()) <= 3

    return (
      <div
        className={styles.cleanDetail}
        data-testid={CleanDetailTestIds.container}
      >
        <St.CleanDetailHeader clean={clean}>
          {shouldRenderEditTypeButton && (
            <St.EditTypeButton buttonType={'text'} onClick={toggleEditJobType}>
              {t(Slugs.editType)}
            </St.EditTypeButton>
          )}
        </St.CleanDetailHeader>

        <div className={styles.cleanDetailWrapper}>
          <CleanJobTypeEditorContainer
            clean={clean}
            isEditing={isEditingCleanJobType}
            onCancelClick={toggleEditJobType}
          />

          <AddressInfo clean={clean} />

          <CleanStatus clean={clean} />

          <DateInfo clean={clean} />

          <section className={styles.section}>
            {shouldRenderRate && (
              <div className={styles.cleanUnitInfoContainer}>
                <SvgIcon
                  className={styles.cleanDetailIcon}
                  icon={IconName.bucketClean}
                  size={16}
                />
                <div className={styles.details}>
                  <div>
                    <strong>{t(Slugs.rate)}</strong>
                  </div>
                  <div>${clean.assignments?.[0].payRate}</div>
                </div>
              </div>
            )}

            {frozen && (
              <AccessCodes
                lockbox={lockbox}
                smartLock={smartLock}
                smartLockAccessGranted={smartLockAccessGranted}
                unit={unit}
              />
            )}

            <CleanNotesDisplay notes={clean.notes || ''} />
            <TeamCleanInfo clean={clean} />

            <div className={styles.cleanUnitInfoContainer}>
              <SvgIcon
                className={styles.cleanDetailIcon}
                icon={IconName.users}
                size={16}
              />
              <div className={styles.details}>
                <div>
                  <strong>{t(Slugs.priorReservation)}</strong>
                </div>
                <div>{lastResString}</div>
              </div>
            </div>

            <div className={styles.cleanUnitInfoContainer}>
              <SvgIcon
                className={styles.cleanDetailIcon}
                icon={IconName.clock}
                size={16}
              />
              <div className={styles.details}>
                <div>
                  <strong>{t(Slugs.predictedCleanTime)}</strong>
                </div>
                <div>
                  {convertHoursToTimeString(effectivePredictedCleanTime)}
                </div>
              </div>
            </div>

            <div className={styles.cleanUnitInfoContainer}>
              <SvgIcon
                className={styles.cleanDetailIcon}
                icon={IconName.home}
                size={16}
              />
              <div className={styles.details}>
                <div>
                  <strong>{t(Slugs.homeInfo)}</strong>
                </div>

                <div>
                  <span className={styles.amenityInfoSection}>
                    {tp(Slugs.bed, beds)}
                  </span>
                  {bedTypesString && ` (${bedTypesString})`}
                </div>

                <div>
                  <span className={styles.amenityInfoSection}>
                    {tp(Slugs.bath, bathrooms)}
                  </span>
                  {bathTypesString && ` (${bathTypesString})`}
                </div>
                <div>
                  <span className={styles.amenityInfoSection}>
                    {t(Slugs.maxOccupancy)}
                  </span>
                  {` ${unit.maxOccupancy}`}
                </div>

                <div className={styles.unitManagerInfoSection}>
                  <span className={styles.amenityInfoSection}>
                    {t(Slugs.unitManager)}
                  </span>
                  <div>
                    {`${unit.managerInfo?.firstName} ${unit.managerInfo?.lastName}`}
                  </div>
                  <a
                    className={styles.unitManagerPhone}
                    href={`tel:${unit.managerInfo?.mobilePhone}`}
                  >
                    {unit.managerInfo?.mobilePhone}
                  </a>
                </div>

                {hottubAvailable && (
                  <div>
                    <span
                      className={styles.amenityInfoSection}
                      data-testid={CleanDetailTestIds.hotTubInfo}
                    >
                      {t(Slugs.hotTub)}
                    </span>
                  </div>
                )}

                <ExternalLink
                  text={t(Slugs.viewHome)}
                  url={getVacasaViewUnitURL(unitId)}
                />
              </div>
            </div>

            <WifiInfo amenities={amenities} />

            {!!housekeeperNotes && (
              <div className={styles.cleanUnitInfoContainer}>
                <SvgIcon
                  className={styles.cleanDetailIcon}
                  icon={IconName.info}
                  size={16}
                />
                <div className={styles.details}>
                  <div>
                    <strong>{t(Slugs.additionalInstructions)}</strong>
                  </div>

                  <div>
                    {housekeeperNotes.split('\n').map((paragraph, i) => (
                      <p className={styles.hkNote} key={i}>
                        {paragraph}
                      </p>
                    ))}
                  </div>
                </div>
              </div>
            )}

            {isEmployee && (
              <div className={styles.cleanUnitInfoContainer}>
                <SvgIcon
                  className={styles.cleanDetailIcon}
                  icon={IconName.watch}
                  size={16}
                />
                <div className={styles.details}>
                  <div>
                    <strong>{t(Slugs.cleanId)}</strong>
                  </div>
                  <div>
                    {t(Slugs.cleanIdForUkg)}
                    <em> ClockIt! with UKG</em>
                  </div>
                  <ClipboardText>{clean.id}</ClipboardText>
                </div>
              </div>
            )}

            <St.CleanDetailsUnitLinks>
              <TaskDetailUnitLinks taskId={clean.id} unitId={clean.unit.id} />
            </St.CleanDetailsUnitLinks>

            <St.PrivacyNoticeSection>
              <ExternalLink text={t(Slugs.privacyNotice)} url={URL} />
            </St.PrivacyNoticeSection>
          </section>
        </div>
      </div>
    )
  },
)
