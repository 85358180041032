import * as React from 'react'
import { useSelector } from 'react-redux'
import { useInterval } from 'react-use'

import {
  createDateObject,
  differenceInSeconds,
} from 'packages/utils/dateHelpers'

import { useTicket } from 'app/fieldapp/components/tickets/hooks'
import { useTicketTimerActions } from 'app/fieldapp/components/timers/hooks'
import { useTimers as useTimersContext } from 'app/fieldapp/components/timers/state'
import { getActiveTicketTime } from 'app/fieldapp/store/ticket-times/selectors/getActiveTicketTime'
import { Unit } from 'app/fieldapp/store/units/units.types'

export type UseActiveTicketTimer = {
  isLoading: boolean
  stopActiveTimer: () => void
  time: number
  unit: Unit | undefined
}

export function useActiveTicketTimer(): UseActiveTicketTimer {
  const activeTicketTime = useSelector(getActiveTicketTime)

  const {
    dispatchBeginTicketTimeSubmission,
    dispatchBeginTicketTimeStartedOfflineSubmission,
  } = useTicketTimerActions()
  const { state } = useTimersContext()
  const { loadingTimerId } = state
  const isLoading = !!loadingTimerId

  const { unit } = useTicket(activeTicketTime?.ticketId || '')

  const getRunningTime = React.useCallback(
    () =>
      activeTicketTime
        ? differenceInSeconds(createDateObject(), activeTicketTime.startedAt)
        : 0,
    [activeTicketTime],
  )

  const stopActiveTimer = React.useCallback(() => {
    if (activeTicketTime) {
      activeTicketTime.startedOffline
        ? dispatchBeginTicketTimeStartedOfflineSubmission(activeTicketTime)
        : dispatchBeginTicketTimeSubmission(activeTicketTime)
    }
  }, [
    activeTicketTime,
    dispatchBeginTicketTimeStartedOfflineSubmission,
    dispatchBeginTicketTimeSubmission,
  ])

  const [time, setTime] = React.useState(getRunningTime)

  /** Set up an interval to update the "running" timer display once per second. */
  useInterval(
    () => {
      setTime(getRunningTime())
    },
    // If the timer is loading (submitting), disable the interval
    isLoading ? null : 1000,
  )

  return {
    isLoading,
    stopActiveTimer,
    time,
    unit,
  }
}
