import capitalize from 'lodash/fp/capitalize'
import React from 'react'

import {
  format,
  DateFormat,
  createDateObject,
  differenceInDays,
  differenceInHours,
} from 'packages/utils/dateHelpers'
import { isBefore, parseTimestamp } from 'packages/utils/timestamp'

import { CrossCoverage } from 'app/fieldapp/store/crossCoverage/crossCoverage.types'

import { St } from './UpcomingCoverageEvent.styles'

type Translations = {
  days: string
  everyDay: string
  fridayF: string
  mondayM: string
  saturdaySA: string
  sundaySU: string
  thursdayTH: string
  tuesdayTU: string
  wednesdayW: string
}

export const getDaysFrom = (
  coverageEvent: CrossCoverage,
  translations: Translations,
): string => {
  if (!coverageEvent.isRepeating) {
    const startDateTime = createDateObject(
      `${coverageEvent.startDate}T${coverageEvent.startTimeLocal}`,
    )
    const endDateTime = createDateObject(
      `${coverageEvent.endDate}T${coverageEvent.endTimeLocal}`,
    )
    const theDifferenceInHours = differenceInHours(endDateTime, startDateTime)
    const theDifferenceInDays = differenceInDays(endDateTime, startDateTime)

    if (theDifferenceInHours < 24) {
      return ''
    }

    if (theDifferenceInHours < 48) {
      return `2 ${translations.days}`
    }

    if (theDifferenceInHours < 72) {
      return `3 ${translations.days}`
    }

    return `${theDifferenceInDays} ${translations.days}`
  }

  /* eslint-disable sort-keys */
  const _days = {
    sunday: translations.sundaySU,
    monday: translations.mondayM,
    tuesday: translations.tuesdayTU,
    wednesday: translations.wednesdayW,
    thursday: translations.thursdayTH,
    friday: translations.fridayF,
    saturday: translations.saturdaySA,
  }
  /* eslint-enable sort-keys */

  const coveredDays = Object.keys(_days).reduce((acc, day) => {
    if (coverageEvent[day]) {
      acc[day] = _days[day]
    }

    return acc
  }, {})

  const numberOfDaysCovered = Object.keys(coveredDays).length

  if (numberOfDaysCovered === 7) {
    return translations.everyDay
  }

  if (numberOfDaysCovered > 1) {
    return Object.values(coveredDays).join(', ')
  }

  if (numberOfDaysCovered > 0) {
    return capitalize(`${Object.keys(coveredDays)[0]}s`)
  }

  return ''
}

export const getStartOrEndFrom = (
  startOrEnd: 'start' | 'end',
  coverageEvent: CrossCoverage,
): string => {
  const formattedDate = format(
    coverageEvent[`${startOrEnd}Date`],
    DateFormat.MonthAndDayShortWithYear,
  )

  if (!coverageEvent.isRepeating) {
    return `${formattedDate} ${parseTimestamp(
      coverageEvent[`${startOrEnd}TimeLocal`],
    )}`
  }

  return formattedDate
}

export const getTimeFrom = (
  coverageEvent: CrossCoverage,
): string | JSX.Element => {
  if (!coverageEvent.isRepeating) {
    return ''
  }

  const startTime = parseTimestamp(coverageEvent.startTimeLocal)
  const endTime = parseTimestamp(coverageEvent.endTimeLocal)
  const endTimeIsNextDay = isBefore(
    coverageEvent.endTimeLocal,
    coverageEvent.startTimeLocal,
  )

  return (
    <>
      {startTime} - {endTime} {endTimeIsNextDay ? <St.Sup>+1</St.Sup> : ''}
    </>
  )
}
