import get from 'lodash/get' // eslint-disable-line

import { RequestOptions } from 'packages/utils/store/jsonapi.types'

import {
  emptyOtherTimerResponse,
  OtherTimersApiResponse,
  OtherTimersServiceResponse,
} from 'app/fieldapp/store/otherTimers'

import { api } from '../axiosInstance'

// TODO: rename to other timer when API ready
const URL = '/task_times'

export const otherTimersService = {
  /**
   * Sends a GET request to the server to fetch OtherTimers by the specified params
   * @param params
   */
  async fetchOtherTimers(
    params: RequestOptions = {},
  ): OtherTimersServiceResponse {
    const response: OtherTimersApiResponse = await api.get(URL, {
      params,
    })

    return get(response, 'data', emptyOtherTimerResponse)
  },

  /**
   * Sends a GET request to the server for a OtherTimer with the specified ID
   * @param id
   * @param params
   */
  async fetchOtherTimerById(
    id: string,
    params: RequestOptions = {},
  ): OtherTimersServiceResponse {
    const response: OtherTimersApiResponse = await api.get(`${URL}/${id}`, {
      params,
    })
    return get(response, 'data', emptyOtherTimerResponse)
  },

  /**
   * Sends a POST request to the server to create a new OtherTimer
   * @param data
   */
  async createOtherTimer(data: RequestOptions): OtherTimersServiceResponse {
    const response: OtherTimersApiResponse = await api.post(URL, data)

    return get(response, 'data', emptyOtherTimerResponse)
  },

  /**
   * Sends a PATCH request to update the associated OtherTimer
   * @param otherTimerId
   * @param data
   */
  async updateOtherTimer(
    otherTimerId: string,
    data: RequestOptions,
  ): OtherTimersServiceResponse {
    const response: OtherTimersApiResponse = await api.patch(
      `${URL}/${otherTimerId}`,
      data,
    )

    return get(response, 'data', emptyOtherTimerResponse)
  },

  async deleteOtherTimer(otherTimerId: string): OtherTimersServiceResponse {
    const response: OtherTimersApiResponse = await api.delete(
      `${URL}/${otherTimerId}`,
    )

    return get(response, 'data', emptyOtherTimerResponse)
  },
}
