import { createAsyncAction } from 'typesafe-actions'

import { RequestConfig } from 'packages/utils/store'

import { offlineTimers } from '../../utils'
import { getMergedTicketTimeById } from '../selectors'
import { ticketTimesService } from '../ticket-times.service'
import {
  TicketTimesActionTypes,
  NormalizedTicketTimesApiResponse,
} from '../ticket-times.types'

export const deleteTicketTimeAction = createAsyncAction(
  TicketTimesActionTypes.DELETE_TICKET_TIME,
  TicketTimesActionTypes.DELETE_TICKET_TIME_SUCCESS,
  TicketTimesActionTypes.DELETE_TICKET_TIME_FAILURE,
)<RequestConfig<NormalizedTicketTimesApiResponse>, { id: string }, Error>()

export const deleteTicketTime =
  (ticketTimeId: string) => async (dispatch, getState) => {
    const timer = getMergedTicketTimeById(getState(), ticketTimeId)

    // If the timer has a started offline flag, it hasn't been synced to the API, so we can just delete it locally
    if (timer?.startedOffline) {
      await offlineTimers.removeTicketTime(ticketTimeId)
      dispatch(deleteTicketTimeAction.success({ id: ticketTimeId }))
      return
    }

    try {
      const request = ticketTimesService.deleteTicketTime.bind(
        null,
        ticketTimeId,
      )
      const result = await dispatch(deleteTicketTimeAction.request({ request }))

      await offlineTimers.removeTicketTime(ticketTimeId)

      // Here result is empty, so we need to create a payload with the id
      dispatch(deleteTicketTimeAction.success({ id: ticketTimeId }))

      return result.normalized
    } catch (error) {
      dispatch(deleteTicketTimeAction.failure(error))
      throw error
    }
  }
