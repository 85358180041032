import { useSelector } from 'react-redux'

import { ApplicationState } from 'app/fieldapp/store/store'
import { Unit } from 'app/fieldapp/store/units'
import { Visit } from 'app/fieldapp/store/visits'
import { getVisitsByIds } from 'app/fieldapp/store/visits/selectors'
import { useActiveUser } from 'app/fieldapp/utils/hooks/useActiveUser'

export const useOpenVisitForActiveUserAndUnit = ({
  unit,
}: {
  unit?: Unit
}): Visit | undefined => {
  const { housekeeperId } = useActiveUser()
  const openVisitsForUnit = useSelector((state: ApplicationState) =>
    getVisitsByIds(state, unit?.openVisitIds || []),
  )

  const openVisitForUser = openVisitsForUnit.find(visit =>
    visit.assignedHkIds.includes(housekeeperId || ''),
  )

  return openVisitForUser
}
