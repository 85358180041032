import React from 'react'
import { useDispatch } from 'react-redux'

import { useConfirmModal, useToast } from 'packages/common'
import { getTaskProgressStatus, TaskServiceType } from 'packages/grimoire'
import { useI18n } from 'packages/i18n'
import { useAsyncFnWithReset } from 'packages/utils/hooks'

import { Slugs } from 'app/fieldapp/i18n'
import { useAppSelector } from 'app/fieldapp/store/hooks'
import { AppDispatch, ApplicationState } from 'app/fieldapp/store/store'
import { getVisitDrawerState } from 'app/fieldapp/store/ui/selectors'
import { Visit } from 'app/fieldapp/store/visits'
import { deleteVisit } from 'app/fieldapp/store/visits/actions'
import { getVisitById } from 'app/fieldapp/store/visits/selectors'

import { RemoveVisit } from './RemoveVisit'

const useTranslations = () => {
  const { t } = useI18n()

  return {
    error: t(Slugs.removeVisitError),
    modalMessage: t(Slugs.deleteVisitConfirmationMessage),
    modalTitle: t(Slugs.areYouSure),
    success: t(Slugs.removeVisitSuccess),
  }
}

export type VisitDeletionStatus =
  | 'isLoadingAnyVisit'
  | 'notStarted'
  | 'inProgress'
  | 'system'
  | 'completed'

export const getVisitDeletionStatus = (
  isLoadingAnyVisit: boolean,
  visit: Visit,
): VisitDeletionStatus => {
  // System generated visits cannot be removed
  if (visit.serviceType === TaskServiceType.scheduled) return 'system'
  const status = getTaskProgressStatus(visit)

  // If the visit is started/completed, we don't show a button, so no need to render a loading state
  if (status === 'notStarted' && isLoadingAnyVisit) return 'isLoadingAnyVisit'

  return status
}

type RemoveVisitContainerProps = {
  onCloseDrawerClick: () => void
}

export const RemoveVisitContainer: React.FC<RemoveVisitContainerProps> = ({
  onCloseDrawerClick,
}) => {
  const dispatch: AppDispatch = useDispatch()
  const strings = useTranslations()
  const { showToast } = useToast()

  const { visitId } = useAppSelector(getVisitDrawerState)

  const visit = useAppSelector((state: ApplicationState) =>
    getVisitById(state, visitId),
  )

  const isLoadingAnyVisit = useAppSelector(
    (state: ApplicationState) => state.visits.loading,
  )

  const [_, deleteVisitFn] = useAsyncFnWithReset(
    async () =>
      dispatch(
        deleteVisit(visit?.id || '', {
          onError: () => {
            showToast({
              message: strings.error,
              toastType: 'danger',
            })
          },
          onSuccess: () => {
            showToast({
              message: strings.success,
              toastType: 'success',
            })
          },
        }),
      ),
    [dispatch, showToast, strings.error, strings.success, visit?.id],
  )

  const { showModal } = useConfirmModal({
    onConfirm: () => {
      deleteVisitFn()
      onCloseDrawerClick()
    },
    slugs: {
      message: strings.modalMessage,
      title: strings.modalTitle,
    },
  })

  const onDelete = React.useCallback(() => {
    showModal()
  }, [showModal])

  // The visit should always exist but we need to handle things gracefully so TS is happy
  const visitStatus = visit
    ? getVisitDeletionStatus(isLoadingAnyVisit, visit)
    : 'notStarted'

  return <RemoveVisit onDelete={onDelete} visitStatus={visitStatus} />
}
