import { pipe, values, filter, map, find, prop, merge } from 'lodash/fp'
import { createSelector } from 'reselect'

import { ApplicationState } from 'app/fieldapp/store/store'

import { getTicketById } from '../../tickets/selectors'
import { TicketTime, RawTicketTime } from '../ticket-times.types'
import { getMergedTicketTimeById } from './getMergedTicketTimeById'

type Ids = { ticketId: string | undefined; ticketTimeId: string | undefined }

const ticketTimeIsActive = (rtt: RawTicketTime): boolean =>
  !!rtt.attributes.startedAt && !rtt.attributes.stoppedAt

const getFullState = (state: ApplicationState): ApplicationState => state

export const getActiveTicketTime = createSelector(
  getFullState,
  (fullState): TicketTime | undefined => {
    const getTicketTimeAndTicketIds = (tt: RawTicketTime): Ids => {
      const ticketTimeId = tt?.id
      const ticket = getTicketById(fullState, tt?.attributes.ticketId || '')
      const ticketId = ticket?.id
      return { ticketId, ticketTimeId }
    }

    const getTicketAndTicketTimeExist = (ids: Ids): boolean =>
      !!(ids.ticketId && ids.ticketTimeId)

    const { data, offlineData } = fullState.ticketTimes

    const fullTicketTimesData = Object.values(merge(data, offlineData))

    const activeTimerId = pipe(
      values,
      filter(ticketTimeIsActive),
      map(getTicketTimeAndTicketIds),
      find(getTicketAndTicketTimeExist),
      prop('ticketTimeId'),
    )(fullTicketTimesData)

    if (!activeTimerId) return undefined

    /*
     * Check for an offline timer with the active id -
     * if it exists and is stopped offline, it belongs in paused timers
     */
    const offlineTicketTime = fullState.ticketTimes.offlineData[activeTimerId]
    if (offlineTicketTime?.attributes.stoppedAt) {
      return undefined
    }

    return getMergedTicketTimeById(fullState, activeTimerId)
  },
)
