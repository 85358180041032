import { createAsyncAction, deprecated } from 'typesafe-actions'

import { RequestConfig } from 'packages/utils/store'

import { offlineTimers } from 'app/fieldapp/store/utils'

import { ticketTimesService } from '../ticket-times.service'
import {
  NormalizedTicketTimesApiResponse,
  TicketTimesActionTypes,
} from '../ticket-times.types'

const { createStandardAction } = deprecated

export const removeLocalTicketTimeAction = createStandardAction(
  TicketTimesActionTypes.REMOVE_LOCAL_TICKET_TIME,
)<{ id: string }>()

export const silentDeleteTicketTimeAction = createAsyncAction(
  TicketTimesActionTypes.SILENT_DELETE_TICKET_TIME,
  TicketTimesActionTypes.SILENT_DELETE_TICKET_TIME_SUCCESS,
  TicketTimesActionTypes.SILENT_DELETE_TICKET_TIME_FAILURE,
)<RequestConfig<NormalizedTicketTimesApiResponse>, { id: string }, Error>()

/**
 * Silent/background ticket time deletion action. This is intended for use when we
 * want to TRY to delete one or more ticket times, but we don't really care if it succeeds.
 *
 * This is almost the same as the regular `deleteTicketTime` action, with a couple exceptions:
 * - TicketTimers are removed from the local store BEFORE we send the request,
 *     so that we don't have to wait for the API request to make UI updates
 * - No "success" action is dispatched
 *
 * Note that error/failure action IS dispatched so that we can e.g. log it to Sentry, but for
 * any component using this action, it should be considered a non-critical error.
 */
export const silentDeleteTicketTime =
  (ticketTimeId: string) => async dispatch => {
    try {
      await offlineTimers.removeTicketTime(ticketTimeId)
      dispatch(removeLocalTicketTimeAction({ id: ticketTimeId }))

      const request = ticketTimesService.deleteTicketTime.bind(
        null,
        ticketTimeId,
      )
      const result = await dispatch(
        silentDeleteTicketTimeAction.request({ request }),
      )

      return result.normalized
    } catch (error) {
      dispatch(silentDeleteTicketTimeAction.failure(error))
      throw error
    }
  }
