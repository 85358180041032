import React from 'react'

import { isSameDay, isToday } from 'packages/utils/dateHelpers'
import { twentyFourHourMap } from 'packages/utils/timestamp'

import { defaultStartTime } from '../../../utils/CrossCoverageDrawer.helpers'
import {
  CrossCoverageFormAction,
  CrossCoverageFormState,
} from '../../state/CrossCoverageForm.reducer'
import { useCrossCoverageFormDatePickerModals } from '../DatePickers'
import { TimeOption, timeOptionsArray } from '../TimeSelect'
import { TimeAndDateForm } from './TimeAndDateForm'

export const getStartTimeOptions = (
  state: CrossCoverageFormState,
): TimeOption[] => {
  const { startDate } = state
  if (!isToday(startDate)) return timeOptionsArray

  return timeOptionsArray.filter(({ hourOfDay }) => {
    return hourOfDay >= defaultStartTime(startDate).hourOfDay
  })
}

export const getEndTimeOptions = (
  state: CrossCoverageFormState,
): typeof timeOptionsArray => {
  const { isRepeating, startDate, startTime, endDate } = state

  if (isRepeating) {
    const indexOfStartTime = timeOptionsArray.findIndex(
      timeOption => timeOption.hourOfDay === startTime.hourOfDay,
    )

    const beforeStartTime = timeOptionsArray
      .slice(0, indexOfStartTime + 1)
      .map(option => ({
        isNextDay: true,
        ...option,
      }))
    const afterStartTime = timeOptionsArray.slice(indexOfStartTime + 1)

    return [...afterStartTime, ...beforeStartTime]
  }

  if (!isSameDay(startDate, endDate)) {
    return timeOptionsArray
  }

  if (startTime.hourOfDay === 2300) {
    return [{ hourOfDay: 2300, timeOfDay: twentyFourHourMap[2300] }]
  }

  return timeOptionsArray.filter(hour => {
    return hour.hourOfDay > startTime.hourOfDay
  })
}

type TimeAndDateFormContainerProps = {
  formDispatch: React.Dispatch<CrossCoverageFormAction>
  formState: CrossCoverageFormState
}

export const TimeAndDateFormContainer: React.FC<
  TimeAndDateFormContainerProps
> = ({ formDispatch, formState }) => {
  const {
    endDate,
    endTime,
    isEditable,
    isRepeating,
    startDate,
    startTime,
    formValidationError,
  } = formState

  const startTimeOptions = getStartTimeOptions(formState)
  const endTimeOptions = getEndTimeOptions(formState)

  const isDisabled = !isEditable

  const handleTimeChange = (type: 'start' | 'end') => (value: TimeOption) => {
    formDispatch({
      payload: { [`${type}Time`]: value },
      type: 'setDatesAndTimes',
    })
  }

  const datepickers = useCrossCoverageFormDatePickerModals({
    formDispatch,
    state: formState,
  })

  return isRepeating === null ? null : (
    <TimeAndDateForm
      endDate={endDate}
      endTime={endTime}
      endTimeOptions={endTimeOptions}
      formValidationError={formValidationError}
      handleTimeChange={handleTimeChange}
      isDisabled={isDisabled}
      isRepeating={isRepeating}
      showEndDatePickerModal={datepickers.showEndDatePickerModal}
      showStartDatePickerModal={datepickers.showStartDatePickerModal}
      startDate={startDate}
      startTime={startTime}
      startTimeOptions={startTimeOptions}
    />
  )
}
