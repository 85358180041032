import styled from '@emotion/styled'
import React from 'react'

import { Button, Drawer, useManageDrawerState } from 'packages/common'
import { useOnlineStatus } from 'packages/utils/hooks'

import { Slugs, useI18n } from 'app/fieldapp/i18n'

import { CreateVisitContainer } from './CreateVisit.container'

const St = {
  Drawer: styled(Drawer)`
    min-width: 375px;
  `,
}

const useTranslations = () => {
  const { ut } = useI18n()
  return {
    addVisit: ut(Slugs.addVisit),
  }
}

export const CreateVisitDrawer: React.FC = React.memo(() => {
  const strings = useTranslations()

  const drawerStateResult = useManageDrawerState()
  const { closeDrawer, completeDrawerClose, drawerState, openDrawer } =
    drawerStateResult

  const isOnline = useOnlineStatus().isOnline()

  return (
    <>
      <Button buttonType="utility" disabled={!isOnline} onClick={openDrawer}>
        {strings.addVisit}
      </Button>

      {drawerState.isOpen && (
        <St.Drawer
          afterExit={completeDrawerClose}
          forceClose={drawerState.forceClose}
          isOpen={drawerState.isOpen}
          data-testid="create-visit-drawer"
        >
          <CreateVisitContainer
            onCancel={closeDrawer}
            onSuccess={closeDrawer}
          />
        </St.Drawer>
      )}
    </>
  )
})
