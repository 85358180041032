import { upperFirst } from 'lodash/fp'

import { TranslatorFn } from 'packages/i18n'
import {
  DateFormat,
  differenceInSeconds,
  format,
  isToday,
} from 'packages/utils/dateHelpers'
import { formatSecondsAsDigitalClock } from 'packages/utils/mathHelpers'

import { Slugs } from 'app/fieldapp/i18n'

import { SubmissionTimer } from './types'

type NonSubmittedTimer = {
  id: string
  isExternalComment?: boolean
  notes?: string
  startedAt: string
  startedOffline?: boolean
  stoppedAt: string | null
}

/**
 * Takes a translation function and returns a function that accepts a date formatted as a string,
 * and returns that date formatted as expected for the Timer Submission UI
 */
export const formatDateForSubmission =
  (t: TranslatorFn) =>
  (date: string): string => {
    if (isToday(date)) {
      return `${upperFirst(t(Slugs.today))}, ${format(
        date,
        DateFormat.TimeWithAmPm,
      )}`
    }

    return format(
      date,
      `${DateFormat.MonthAndDayShort}, ${DateFormat.TimeWithAmPm}`,
    )
  }

const formatDateForInput = (date: string): string =>
  format(date, DateFormat.IsoDateAndTime)

export const transformTimer = ({
  id,
  isExternalComment,
  notes = '',
  startedAt,
  startedOffline,
  stoppedAt,
}: NonSubmittedTimer): SubmissionTimer => ({
  id,
  isExternalComment,
  notes,
  startedAt: formatDateForInput(startedAt),
  startedOffline,
  stoppedAt: formatDateForInput(stoppedAt as string),
})

const stoppedAtNotNull = ({ stoppedAt }) => !!stoppedAt

export const transformTimers = (
  timers: NonSubmittedTimer[],
): SubmissionTimer[] => timers.filter(stoppedAtNotNull).map(transformTimer)

export const sumTimers = (timers: NonSubmittedTimer[]): string => {
  const totalSeconds = timers.filter(stoppedAtNotNull).reduce((acc, ct) => {
    // We can safely type `stoppedAt` because of the above filter
    return acc + differenceInSeconds(ct.stoppedAt as string, ct.startedAt)
  }, 0)

  return formatSecondsAsDigitalClock(totalSeconds)
}
