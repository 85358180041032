import React from 'react'
import { useSelector } from 'react-redux'

import { createDateObject } from 'packages/utils/dateHelpers'
import { useOnlineStatus } from 'packages/utils/hooks'

import {
  useCleanTimerActions,
  useOfflineMergedTimers,
} from 'app/fieldapp/components/timers/hooks'
import { useTimers as useTimersContext } from 'app/fieldapp/components/timers/state'
import {
  getActiveCleanTime,
  getCleanTimesRequestPending,
} from 'app/fieldapp/store/cleantimes/selectors'
import {
  getActiveOtherTimer,
  getOtherTimersRequestPending,
} from 'app/fieldapp/store/otherTimers/selectors'
import { ApplicationState } from 'app/fieldapp/store/store'
import { getActiveTicketTime } from 'app/fieldapp/store/ticket-times/selectors'
import { getUnitById } from 'app/fieldapp/store/units/selectors'
import { Visit } from 'app/fieldapp/store/visits'

import { VisitInspectionTimerControls } from './VisitInspectionTimerControls'

export const VisitInspectionTimerControlsContainer: React.FC<{ visit: Visit }> =
  ({ visit }) => {
    const cleanTimesRequestPending = useSelector(getCleanTimesRequestPending)
    const otherTimersRequestPending = useSelector(getOtherTimersRequestPending)

    const { offlineTimersCount } = useOfflineMergedTimers()
    const isOnline = useOnlineStatus().isOnline()
    const activeCleanTime = useSelector(getActiveCleanTime)
    const activeOtherTime = useSelector(getActiveOtherTimer)
    const activeTicketTime = useSelector(getActiveTicketTime)

    const { state: timersState } = useTimersContext()
    const { loadingTimerId, submissionData } = timersState

    const { dispatchStartCleanTimer } = useCleanTimerActions()

    const unit = useSelector((state: ApplicationState) =>
      getUnitById(state, visit.unitId),
    )

    const handleStartClick = async () => {
      const assignmentId = visit.assignmentIds[0]
      if (!visit || !assignmentId) return

      const startedAt = createDateObject()

      const newCleanTimeData = {
        assignmentId,
        startedAt,
      }

      await dispatchStartCleanTimer(newCleanTimeData)
    }

    const hasActiveTimer =
      !!activeCleanTime || !!activeOtherTime || !!activeTicketTime

    const hasRequestPending =
      cleanTimesRequestPending || otherTimersRequestPending

    const isLoading = !!loadingTimerId

    const enableStartingTimer = React.useMemo(
      () =>
        !hasActiveTimer &&
        !hasRequestPending &&
        !submissionData &&
        !visit.completedAt &&
        (!offlineTimersCount || !isOnline),
      [
        hasActiveTimer,
        hasRequestPending,
        isOnline,
        offlineTimersCount,
        visit.completedAt,
        submissionData,
      ],
    )

    return (
      <VisitInspectionTimerControls
        disabled={!enableStartingTimer}
        isLoading={isLoading}
        onStart={handleStartClick}
        unit={unit}
      />
    )
  }
