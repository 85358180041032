import styled from '@emotion/styled'

export const Container = styled.div`
  padding-top: 16px;
  width: 100%;
`

export const Label = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 12px;
  justify-content: space-between;
  padding-bottom: 4px;
  padding-top: 4px;
`
export const TextArea = styled.textarea`
  height: 80px;
  padding: 8px 16px;
  resize: none;
  width: 100%;
`
