import { createSelector } from 'reselect'

import { ApplicationState } from '../../store'
import { Unit } from '../units.types'
import { hydrateRawUnit } from '../units.utils'

export const getUnitById = createSelector(
  (state: ApplicationState) => state.units,
  (_, id: string) => id,
  (unitsState, unitId): Unit | undefined => {
    const rawUnit = unitsState?.data?.[unitId]
    return rawUnit && hydrateRawUnit(rawUnit)
  },
)
