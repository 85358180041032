import styled from '@emotion/styled'
import React from 'react'
import { components, DropdownIndicatorProps, ActionMeta } from 'react-select'

import { Select } from 'packages/common'
import { IconName, SvgIcon } from 'packages/iconic'
import { colors, text } from 'packages/styles'
import { twentyFourHourMap } from 'packages/utils/timestamp'

const St = {
  Select: styled(Select)``,
  Superscript: styled.sup`
    ${text.bodyBoldTiny}
    color: ${colors.midnight};
  `,
}

export const timeOptionsArray = Object.entries(twentyFourHourMap).map(
  ([hourOfDay, timeOfDay]) => ({
    hourOfDay: Number(hourOfDay),
    timeOfDay,
  }),
)

const TimeSelectDropdownIndicator = (props: DropdownIndicatorProps) => {
  return (
    <components.DropdownIndicator {...props}>
      <SvgIcon style={{ color: colors.dusk }} icon={IconName.clock} size={18} />
    </components.DropdownIndicator>
  )
}

export type TimeOption = {
  hourOfDay: number // The hour of day * 100 - Ex. 8:00 AM would be 800
  isNextDay?: boolean
  timeOfDay: string
}

export enum TimeSelectTestIds {
  container = 'TimeSelect__container',
}

export type TimeSelectProps = {
  ariaLabel?: string
  disabled?: boolean
  handleChange: (value: TimeOption, action: ActionMeta) => void
  isValid?: boolean
  options: TimeOption[]
  value: TimeOption
}

export const TimeSelect: React.FC<TimeSelectProps> = React.memo(
  ({
    ariaLabel,
    disabled = false,
    handleChange,
    options,
    value,
    isValid = true,
  }) => {
    return (
      <Select
        ariaLabel={ariaLabel}
        components={{
          DropdownIndicator: TimeSelectDropdownIndicator,
        }}
        disabled={disabled}
        fullWidth={true}
        getOptionLabel={({ isNextDay, timeOfDay }) => (
          <>
            {timeOfDay} {isNextDay && <St.Superscript>+1</St.Superscript>}
          </>
        )}
        onChange={handleChange}
        options={options}
        selectedValue={value}
        style={{
          control: (baseStyles: Record<string, string>) => ({
            ...baseStyles,
            borderColor: !isValid ? colors.alert : baseStyles.borderColor,
          }),
        }}
      />
    )
  },
)
