import styled from '@emotion/styled'
import React from 'react'

import { Button, FormProps, Loader } from 'packages/common'
import { colors, text } from 'packages/styles'

import { Slugs, useI18n } from 'app/fieldapp/i18n'

const St = {
  Container: styled.div`
    display: flex;
    justify-content: space-between;
    margin-left: auto;
  `,
  Form: styled.form`
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    margin-top: 8px;
    padding: 12px 16px;
    position: relative;
  `,
  Header: styled.div`
    ${text.fieldLabelMobile}
    margin-bottom: 8px;
  `,
  Submit: styled(Button)`
    &:focus {
      outline: none; // this outline overflows the loader and just looks bad
    }
  `,
  TextArea: styled.textarea`
    border: 1px solid ${colors.midnight20};
    border-radius: 4px;
    flex-grow: 1;
    font-size: 16px;
    padding: 8px 16px;
    max-width: 100%;
  `,
}

const useTranslations = () => {
  const { ut } = useI18n()
  return {
    addNote: ut(Slugs.addNote),
    notes: ut(Slugs.notes),
    placeholder: ut(Slugs.notePlaceholder),
  }
}

export type InspectionNoteFormValues = {
  inspectionNotes: string
}

export type InspectionNoteFormProps = FormProps<InspectionNoteFormValues>

export const InspectionNoteForm: React.FC<InspectionNoteFormProps> = React.memo(
  ({ formState, handlers, requestState }) => {
    const strings = useTranslations()
    return (
      <St.Form onSubmit={handlers.submit}>
        <St.Header>{strings.notes}</St.Header>
        <St.TextArea
          id="inspectionNotes"
          maxLength={1000}
          name="inspectionNotes"
          onChange={handlers.change}
          placeholder={strings.placeholder}
          rows={5}
          value={formState.values.inspectionNotes}
        />

        <St.Container>
          <St.Submit disabled={!formState.canSubmit} isFormSubmit={true}>
            {strings.addNote}
          </St.Submit>
        </St.Container>
        {requestState.loading && <Loader />}
      </St.Form>
    )
  },
)
