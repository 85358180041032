/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { isOnline } from 'packages/utils/pwa'
import { OfflineRequestConfig } from 'packages/utils/store'

export const OfflineError = new Error('Action Dispatched Offline')

export type OfflineMiddlewareConfig = {
  offlineActions: {
    [actionType: string]: (
      payload: OfflineRequestConfig<never, never>,
    ) => (dispatch) => void
  }
}

/**
 * Create Middleware for intercepting requests made offline.
 * Will only intercept actions that are defined in the config.
 * This config takes keys that are action types, and values of
 * functions to be ran when the associated action is dispatched while offline.
 */
export function getOfflineMiddleware({
  offlineActions,
}: OfflineMiddlewareConfig) {
  return ({ dispatch }) =>
    next =>
    async action => {
      const online = isOnline()

      if (!online && Object.keys(offlineActions).includes(action.type)) {
        await dispatch(offlineActions[action.type](action.payload))
        throw OfflineError
      }

      return next(action)
    }
}
