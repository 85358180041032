import { Slugs, useI18n } from 'app/fieldapp/i18n'

const crossCoverageSlugs = [
  Slugs.assignedCoverage,
  Slugs.cancel,
  Slugs.chooseAnOption,
  Slugs.coverageRepeatTooltipText,
  Slugs.createCrossCoverageFailure,
  Slugs.createCrossCoverageSuccess,
  Slugs.deleteXCovFailure,
  Slugs.deleteXCovSuccess,
  Slugs.editCoverageRequest,
  Slugs.eventAlreadyStarted,
  Slugs.eventEnd,
  Slugs.eventRepeats,
  Slugs.eventStart,
  Slugs.eventStartEndTimeErrorMessage,
  Slugs.fri,
  Slugs.friday,
  Slugs.mon,
  Slugs.monday,
  Slugs.newCoverage,
  Slugs.newCrossCoverageRequest,
  Slugs.nextDay,
  Slugs.no,
  Slugs.noCoveragePartnerSelected,
  Slugs.repeatingEvents,
  Slugs.repeatUntilInclusive,
  Slugs.requestedCoverage,
  Slugs.sameDay,
  Slugs.sat,
  Slugs.saturday,
  Slugs.seeDetailViewForSystemAssignee,
  Slugs.submit,
  Slugs.sun,
  Slugs.sunday,
  Slugs.systemAssignee,
  Slugs.systemAssigneeInfoAlert,
  Slugs.thur,
  Slugs.thursday,
  Slugs.tue,
  Slugs.tuesday,
  Slugs.updateXCovFailure,
  Slugs.updateXCovSuccess,
  Slugs.wed,
  Slugs.wednesday,
  Slugs.willThisEventRepeat,
  Slugs.yes,
] as const

type DirectCrossCoverageDrawerTranslations = Record<
  (typeof crossCoverageSlugs)[number],
  string
>

export type CrossCoverageDrawerTranslations =
  DirectCrossCoverageDrawerTranslations & {
    delete: string
    update: string
  }

export const useCrossCoverageDrawerTranslations =
  (): CrossCoverageDrawerTranslations => {
    const { t, ut } = useI18n()

    /* For every slug, get the translation */
    const directTranslations = crossCoverageSlugs.reduce(
      (translations, slug) => {
        translations[slug] = t(slug)
        return translations
      },
      {} as CrossCoverageDrawerTranslations,
    )

    return {
      ...directTranslations,
      delete: ut(Slugs.delete),
      update: ut(Slugs.update),
    }
  }
