import styled from '@emotion/styled'
import React from 'react'
import { useDispatch } from 'react-redux'

import { Alert, ToggleModalProps, useToggleModal } from 'packages/common'
import { cleanIsDeepClean } from 'packages/grimoire'
import { createDateObject } from 'packages/utils/dateHelpers'
import { useOnlineStatus } from 'packages/utils/hooks/useOnlineStatus'

import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { updateClean } from 'app/fieldapp/store/cleans/actions'
import { Clean } from 'app/fieldapp/store/cleans/cleans.types'
import { AppDispatch } from 'app/fieldapp/store/store'
import { useActiveUser } from 'app/fieldapp/utils/hooks/useActiveUser'

const St = {
  Alert: styled(Alert)`
    margin: 0;
    margin-top: 32px;
  `,
  Ul: styled.ul`
    margin: 0;
    margin-bottom: 8px;
    padding-left: 24px;
  `,
}

const getPostStayCleanModalConfig = (
  clean: Clean,
  isEmployee: boolean,
  t,
): ToggleModalProps['config'] => {
  const showInspectionWarning = !clean.inspectionCompletedAt && !isEmployee
  const staticConfig = {
    bodyContent: (
      <>
        <St.Ul>
          <li>{t(Slugs.homeIsCleaned)}</li>
        </St.Ul>

        {showInspectionWarning && (
          <St.Alert alertType={'warning'}>
            {t(Slugs.selfInspectionNotCompleted)}
          </St.Alert>
        )}
      </>
    ),
    footerContent: <div>{t(Slugs.completeCleanModalFooter)}</div>,
    title: `${t(Slugs.thisHomeIs)}:`,
    toggleLabel: t(Slugs.postStayCleanComplete),
  }

  return {
    disabled: {
      ...staticConfig,
      buttonText: t(Slugs.notCompleted),
    },
    enabled: {
      ...staticConfig,
      buttonText: t(Slugs.completed),
    },
  }
}

const getDeepCleanModalConfig = (
  clean: Clean,
  isEmployee: boolean,
  t,
): ToggleModalProps['config'] => {
  const showInspectionWarning = !clean.inspectionCompletedAt && !isEmployee
  const staticConfig = {
    bodyContent: (
      <>
        <St.Ul>
          <li>{t(Slugs.allDeepCleanTasksDone)}</li>
          <li>{t(Slugs.homeIsCleaned)}</li>
        </St.Ul>

        {showInspectionWarning && (
          <St.Alert alertType={'warning'}>
            {t(Slugs.selfInspectionNotCompleted)}
          </St.Alert>
        )}
      </>
    ),
    title: `${t(Slugs.thisHomeIs)}:`,
    toggleLabel: t(Slugs.deepCleanComplete),
  }

  return {
    disabled: {
      ...staticConfig,
      buttonText: t(Slugs.notCompleted),
      footerContent: <div>{t(Slugs.completeCleanModalFooterAlt)}</div>,
    },
    enabled: {
      ...staticConfig,
      buttonText: t(Slugs.completed),
      footerContent: <div>{t(Slugs.completeCleanModalFooter)}</div>,
    },
  }
}

export type UseCleanCompletionModal = {
  showCleanCompletionModal: (clean?: Clean) => void
}

export const useCleanCompletionModal = (): UseCleanCompletionModal => {
  const { ut } = useI18n()
  const dispatch: AppDispatch = useDispatch()
  const isOnline = useOnlineStatus().isOnline()
  const { isEmployee } = useActiveUser()

  const { showModal } = useToggleModal()

  const showCleanCompletionModal = React.useCallback(
    (clean?: Clean) => {
      if (!clean || !!clean.completedAt || !isOnline) return

      const config = cleanIsDeepClean(clean)
        ? getDeepCleanModalConfig(clean, isEmployee, ut)
        : getPostStayCleanModalConfig(clean, isEmployee, ut)

      showModal({
        config,
        onConfirm: async () =>
          await dispatch(
            updateClean({
              completedAt: createDateObject(),
              id: clean.id,
            }),
          ),
      })
    },
    [dispatch, isEmployee, isOnline, showModal, ut],
  )

  return { showCleanCompletionModal }
}
