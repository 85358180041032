import { createAsyncAction } from 'typesafe-actions'

import {
  RequestConfig,
  RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import { ApplicationState } from 'app/fieldapp/store/store'

import { ticketTimesService } from '../ticket-times.service'
import {
  TicketTimeApiFields,
  TicketTimesActionTypes,
  NormalizedTicketTimesApiResponse,
} from '../ticket-times.types'

export const fetchActiveTicketTimesForUserAction = createAsyncAction(
  TicketTimesActionTypes.FETCH_TICKET_TIMES,
  TicketTimesActionTypes.FETCH_TICKET_TIMES_SUCCESS,
  TicketTimesActionTypes.FETCH_TICKET_TIMES_FAILURE,
)<
  RequestConfig<NormalizedTicketTimesApiResponse>,
  NormalizedTicketTimesApiResponse,
  Error
>()

export const getParams = (userId: string): RequestOptions => ({
  fields: {
    ticket: [],
    ticket_time: TicketTimeApiFields,
    user: [],
  },
  filter: {
    is_finalized: false,
    user: userId,
  },
  include: ['ticket'],
  page: { size: 1500 },
})

export const fetchActiveTicketTimesForUser = () => {
  return async (dispatch, getState: () => ApplicationState) => {
    try {
      const userId = getState().users.activeUserId
      const params = getParams(userId)
      const request = ticketTimesService.fetchTicketTimes.bind(null, params)
      const result = await dispatch(
        fetchActiveTicketTimesForUserAction.request({ request }),
      )
      dispatch(fetchActiveTicketTimesForUserAction.success(result))
      return result
    } catch (error) {
      dispatch(fetchActiveTicketTimesForUserAction.failure(error))
      throw error
    }
  }
}
