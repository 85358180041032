import upperFirst from 'lodash/upperFirst'
import * as React from 'react'

import {
  CleanBadgeTypes,
  TooltipPosition,
  UnitDetailBadges,
} from 'packages/common'
import { cleanIsDeepClean, cleanIsMidstay } from 'packages/grimoire'
import { TranslatorFn, useI18n, Slugs } from 'packages/i18n'
import { IconName, SvgIcon } from 'packages/iconic'

import { Clean } from 'app/fieldapp/store/cleans'

import {
  BaseCleanHeader,
  BadgeContainer,
  OwnerBadge,
  TaskIcon,
} from './CleanHeader.styles'

type CleanHeaderConfig = {
  icon: IconName
  text: string
}

const getConfig = (clean: Clean, t: TranslatorFn): CleanHeaderConfig => {
  if (cleanIsMidstay(clean)) {
    return {
      icon: IconName.midstayClean,
      text: t(Slugs.midStay),
    }
  }

  if (cleanIsDeepClean(clean)) {
    return {
      icon: IconName.bucketClean,
      text: t(Slugs.deep),
    }
  }

  return {
    icon: IconName.doorClose,
    text: t(Slugs.postStay),
  }
}

export type CleanHeaderProps = {
  clean: Clean
}

export enum CleanHeaderTestIds {
  ownerBadge = 'CleanHeader__ownerBadge',
}

export const CleanHeader: React.FC<CleanHeaderProps> = React.memo(props => {
  const { t } = useI18n()

  const { clean, children } = props
  const { icon, text } = getConfig(clean, t)

  return (
    <BaseCleanHeader {...props}>
      <TaskIcon
        centerItems={true}
        icon={icon}
        text={text}
        textFontSize={'inherit'}
        size={16}
      />
      {children}
      <BadgeContainer>
        <UnitDetailBadges
          clean={clean}
          omitBadgeTypes={[CleanBadgeTypes.Late]}
          tooltipPosition={TooltipPosition.Left}
        />
        {clean.isNextStayOwner && (
          <OwnerBadge data-testid={CleanHeaderTestIds.ownerBadge}>
            <SvgIcon
              centerItems={true}
              icon={IconName.crown}
              text={upperFirst(Slugs.owner)}
              size={22}
            />
          </OwnerBadge>
        )}
      </BadgeContainer>
    </BaseCleanHeader>
  )
})
