import { produce } from 'immer'
import get from 'lodash/get' // eslint-disable-line
import { ActionType, getType } from 'typesafe-actions'

import { TimerType } from 'packages/grimoire/src/utils/timers.types'
import { getIdAndDataTypeFromAction } from 'packages/utils/store'

import {
  emptyNormalizedOtherTimerData,
  RawOtherTime,
  OtherTimersState,
} from 'app/fieldapp/store/otherTimers'

import { makeOfflineTimer } from '../../components/timers/timers.helpers'
import {
  createOtherTimerAction,
  createOtherTimerOfflineAction,
  createFinalizedOtherTimerAction,
  deleteOtherTimerAction,
  fetchActiveOtherTimersForUserAction,
  fetchOtherTimerByIdAction,
  reloadOfflineOtherTimersAction,
  updateOtherTimerAction,
  updateOtherTimerOfflineAction,
} from './actions'

const initialState: OtherTimersState = {
  data: {},
  offlineData: {},
  requestPending: false,
}

const actions = {
  createFinalizedOtherTimerAction,
  createOtherTimerAction,
  createOtherTimerOfflineAction,
  deleteOtherTimerAction,
  fetchActiveOtherTimersForUserAction,
  fetchOtherTimerByIdAction,
  reloadOfflineOtherTimersAction,
  updateOtherTimerAction,
  updateOtherTimerOfflineAction,
}

type OtherTimeActionsTypes = ActionType<typeof actions>

export const otherTimersReducer = (
  state = initialState,
  action: OtherTimeActionsTypes,
): OtherTimersState =>
  produce(state, (draft: OtherTimersState) => {
    switch (action.type) {
      case getType(updateOtherTimerAction.request):
      case getType(fetchActiveOtherTimersForUserAction.request):
      case getType(fetchOtherTimerByIdAction.request):
      case getType(deleteOtherTimerAction.request):
      case getType(createOtherTimerAction.request):
      case getType(createFinalizedOtherTimerAction.request): {
        draft.requestPending = true
        return
      }

      case getType(createFinalizedOtherTimerAction.success): {
        /* If localId exists, we're working with a timer started offline,
        if we're here, we've successfully posted a timer with a real id, so we 
        need to delete the local timer
        */
        const localId = action.payload.localId
        /** We only need to delete from offlineData because timers started offline won't touch the data section */
        delete draft.offlineData[localId]

        return
      }

      case getType(fetchActiveOtherTimersForUserAction.success): {
        const normalized = get(
          action,
          'payload.normalized',
          emptyNormalizedOtherTimerData,
        )

        // TODO: rename to other timer when API ready
        draft.data = normalized.taskTime || {}
        draft.requestPending = false
        return
      }

      case getType(fetchOtherTimerByIdAction.success): {
        // TODO: rename to other timer when API ready
        const [id, otherTimer] = getIdAndDataTypeFromAction<RawOtherTime>(
          action,
          'taskTime',
        )

        draft.data[id] = otherTimer as RawOtherTime
        draft.requestPending = false
        return
      }

      case getType(updateOtherTimerAction.success): {
        // TODO: rename to other timer when API ready
        const id = Object.keys(action.payload.normalized.taskTime)[0]
        delete draft.offlineData[id]
        return
      }

      case getType(deleteOtherTimerAction.success): {
        const id = action.payload
        delete draft.data[id]
        delete draft.offlineData[id]
        draft.requestPending = false
        return
      }

      case getType(createOtherTimerAction.failure):
      case getType(createFinalizedOtherTimerAction.failure):
      case getType(deleteOtherTimerAction.failure):
      case getType(fetchActiveOtherTimersForUserAction.failure):
      case getType(fetchOtherTimerByIdAction.failure):
      case getType(updateOtherTimerAction.failure): {
        draft.requestPending = false
        return
      }

      /**************************************************
       * Offline OtherTimers Handling
       **************************************************/
      case getType(updateOtherTimerOfflineAction.success):
      case getType(createOtherTimerOfflineAction.success): {
        const { data } = action.payload

        draft.offlineData[data.id] = makeOfflineTimer<TimerType.OTHER>(data)

        draft.requestPending = false
        return
      }

      case getType(reloadOfflineOtherTimersAction.success): {
        draft.offlineData = action.payload || {}
        return
      }
    }
  })
