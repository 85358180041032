import { createSelector } from 'reselect'

import { ApplicationState } from 'app/fieldapp/store/store'

import { OfflineTimer } from '../offlineTimers.db'

const getFullState = (state: ApplicationState): ApplicationState => state

export const getOfflineTimerById = createSelector(
  getFullState,
  (_state, id: string) => id,
  (fullState, id): OfflineTimer | undefined => {
    const { otherTimers, cleanTimes, ticketTimes } = fullState

    return (
      cleanTimes.offlineData[id] ||
      otherTimers.offlineData[id] ||
      ticketTimes.offlineData[id]
    )
  },
)
