import { noop } from 'lodash/fp'
import { createAsyncAction } from 'typesafe-actions'

import { ReduxActionCallbacks } from 'packages/grimoire/src/utils'
import { RequestConfig } from 'packages/utils/store/jsonapi.types'

import { NormalizedTasksApiResponse } from 'app/fieldapp/store/tasks'
import { tasksService } from 'app/fieldapp/store/tasks/tasks.service'

import { VisitsActionTypes } from '../visits.types'

export const deleteVisitAction = createAsyncAction(
  VisitsActionTypes.DELETE_VISIT,
  VisitsActionTypes.DELETE_VISIT_SUCCESS,
  VisitsActionTypes.DELETE_VISIT_FAILURE,
)<RequestConfig<NormalizedTasksApiResponse>, { id: string }, Error>()

export const deleteVisit =
  (id: string, callbacks: ReduxActionCallbacks = {}) =>
  async dispatch => {
    const { onError = noop, onSuccess = noop } = callbacks
    try {
      const request = tasksService.deleteTask.bind(null, id)
      const result = await dispatch(deleteVisitAction.request({ request }))

      // Here result is empty, so we need to create a payload with the id
      dispatch(deleteVisitAction.success({ id }))

      onSuccess()
      return result.normalized
    } catch (error) {
      dispatch(deleteVisitAction.failure(error))
      onError()
      throw error
    }
  }
