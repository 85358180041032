import { createSelector } from 'reselect'

import { ApplicationState } from '../../store'
import { hydrateRawVisit, Visit } from '../../visits'

export const getVisitById = createSelector(
  (state: ApplicationState) => state.visits,
  (_, visitId: string) => visitId,
  (visitsState, visitId): Visit | undefined => {
    const rawVisit = visitsState.data[visitId]
    return rawVisit ? hydrateRawVisit(rawVisit) : undefined
  },
)
