import styled from '@emotion/styled'
import * as React from 'react'

import { Alert, ClipboardText } from 'packages/common'
import { IconName, SvgIcon } from 'packages/iconic'

import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { Amenities, NetworkAmenities } from 'app/fieldapp/store/units'

import { Section } from '../TicketDetail.styles'

export const TEST_ID_WIFI_INFO = 'TicketDetail__wifi'

const Row = styled.div`
  display: flex;

  :not(:last-child) {
    margin-bottom: 20px;
  }
`

const Content = styled.div`
  flex: 1;
`

const StyledSvgIcon = styled(SvgIcon)`
  flex: 0;
  margin-top: 1px;
  padding-right: 16px;
`

const Header = styled.h3`
  font-size: 14px;
  font-weight: bold;
  margin: 0;
`

const InlineHeader = styled.span`
  font-weight: 600;
`

const VisitNeededForAccessAlert = styled(Alert)`
  font-size: 16px;
  padding: 16px 14px 16px 20px;
`

type AccessSectionProps = Pick<Amenities, NetworkAmenities> & {
  visitNeededForAccess: boolean
}

export const AccessSection: React.FC<AccessSectionProps> = ({
  visitNeededForAccess,
  wifi,
  wifiNetwork,
  wifiPassword,
}) => {
  const { ut, t } = useI18n()

  return (
    <Section>
      {visitNeededForAccess && (
        <VisitNeededForAccessAlert alertType={'info'}>
          {t(Slugs.visitNeededForAccess)}
        </VisitNeededForAccessAlert>
      )}
      {wifi && (
        <Row data-testid={TEST_ID_WIFI_INFO}>
          <StyledSvgIcon icon={IconName.wifi} size={16} />
          <Content>
            <Header>{ut(Slugs.wifi)}</Header>
            <div>
              <InlineHeader>{ut(Slugs.network)}:</InlineHeader> {wifiNetwork}
            </div>
            <div>
              <InlineHeader>{ut(Slugs.password)}:</InlineHeader>
              {wifiPassword && <ClipboardText>{wifiPassword}</ClipboardText>}
            </div>
          </Content>
        </Row>
      )}
    </Section>
  )
}
