import { RequestOptions } from 'packages/utils/store/jsonapi.types'

import { api } from '../axiosInstance'
import {
  TicketTimesApiResponse,
  TicketTimesServiceResponse,
} from './ticket-times.types'
import { emptyTicketTimesResponse } from './ticket-times.utils'

const URL = '/ticket_times'

export const ticketTimesService = {
  /**
   * Sends a GET request to the server to fetch TicketTimes by the specified params
   * @param params
   */
  async fetchTicketTimes(
    params: RequestOptions = {},
  ): TicketTimesServiceResponse {
    const response = await api.get(URL, {
      params,
    })

    return response?.data || emptyTicketTimesResponse
  },

  /**
   * Sends a GET request to the server for a TicketTime with the specified ID
   * @param id
   * @param params
   */
  async fetchTicketTimeById(
    id: string,
    params: RequestOptions = {},
  ): TicketTimesServiceResponse {
    const response = await api.get(`${URL}/${id}`, {
      params,
    })
    return response?.data || emptyTicketTimesResponse
  },

  /**
   * Sends a POST request to the server to create a new TicketTime
   * @param data
   */
  async createTicketTime(data: RequestOptions): TicketTimesServiceResponse {
    const response: TicketTimesApiResponse = await api.post(URL, data)

    return response?.data || emptyTicketTimesResponse
  },

  /**
   * Sends a PATCH request to update the associated TicketTime
   * @param ticketTimerId
   * @param data
   */
  async updateTicketTimer(
    ticketTimerId: string,
    data: RequestOptions,
  ): TicketTimesServiceResponse {
    const response: TicketTimesApiResponse = await api.patch(
      `${URL}/${ticketTimerId}`,
      data,
    )

    return response?.data || emptyTicketTimesResponse
  },

  async deleteTicketTime(ticketTimerId: string): TicketTimesServiceResponse {
    const response: TicketTimesApiResponse = await api.delete(
      `${URL}/${ticketTimerId}`,
    )

    return response?.data || emptyTicketTimesResponse
  },
}
