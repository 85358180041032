import { Clean } from '../../cleans'
import { TaskJobType, isCompleteType } from '../tasks.types'

/** Typeguard specific to the FA - prefer this over the function with the same name in grimoire when in the FA */
export const taskIsClean = (task?: {
  jobType: TaskJobType[]
}): task is Clean => {
  return Boolean(task?.jobType.includes('standard'))
}

/** Toggles inspection items from checked to unchecked and vice versa.
 * Used primarily for dealing with the checkbox UI and the ternary isCompleteType */
export const toggleIsCompletedString = (
  isComplete: isCompleteType,
): isCompleteType => {
  return isComplete === 'checked' ? 'unchecked' : 'checked'
}
