import { useSelector } from 'react-redux'

import { Clean } from '../../cleans'
import { ApplicationState } from '../../store'
import { Unit } from '../../units'
import { getUnitById } from '../../units/selectors'
import { Visit } from '../../visits'
import { taskIsClean } from './tasks.utils'

/** Select a unit from the store for a task, regardless of task type */
export const useTaskUnit = (task?: Visit | Clean): Unit | undefined => {
  const unit = useSelector((state: ApplicationState) => {
    if (!task) return undefined

    return taskIsClean(task) ? task.unit : getUnitById(state, task.unitId)
  })
  return unit
}
