import { AxiosResponse } from 'axios'

import { JSONApiUsersMap } from 'packages/grimoire'
import {
  JSONApiObjectWithRelationships,
  JSONApiObjectWithRelationshipsMap,
  NormalizedJSONApiResponse,
  ToOneRelationship,
} from 'packages/utils/store'

export * from 'packages/grimoire/src/housekeeper'

/**********************************************************
 * REDUX TYPES
 *********************************************************/
export type HousekeepersState = {
  data: JSONApiHousekeepersMap
}

export enum HousekeepersActionTypes {
  FETCH_HOUSEKEEPER_MANAGERS_BY_ZONE = 'HOUSEKEEPERS/API/FETCH_HOUSEKEEPER_MANAGERS_BY_ZONE',
  FETCH_HOUSEKEEPER_MANAGERS_BY_ZONE_FAILURE = 'HOUSEKEEPERS/FETCH_HOUSEKEEPER_MANAGERS_BY_ZONE_FAILURE',
  FETCH_HOUSEKEEPER_MANAGERS_BY_ZONE_SUCCESS = 'HOUSEKEEPERS/FETCH_HOUSEKEEPER_MANAGERS_BY_ZONE_SUCCESS',
}

/**********************************************************
 * SERVICE TYPES
 *********************************************************/
type HkResponse = {
  housekeeper: JSONApiHousekeepersMap
  user: JSONApiUsersMap
}

export type NormalizedHousekeepersApiResponse =
  NormalizedJSONApiResponse<HkResponse>

export type HksApiResponse = AxiosResponse<NormalizedHousekeepersApiResponse>
export type HksServiceResponse = Promise<NormalizedHousekeepersApiResponse>

/**********************************************************
 * HOUSEKEEPER
 *********************************************************/
export type Housekeeper = {
  id: string
  userId?: string
  zoneId?: string
} & HousekeeperAttributes

export type HousekeeperAttributes = {
  employeeType: string
}

export type HousekeeperRelationships = {
  user: ToOneRelationship
  zone: ToOneRelationship
}

export type RawHousekeeper = JSONApiObjectWithRelationships<
  HousekeeperAttributes,
  HousekeeperRelationships
>

export type JSONApiHousekeepersMap = JSONApiObjectWithRelationshipsMap<
  HousekeeperAttributes,
  HousekeeperRelationships
>
