import { createAsyncThunk } from '@reduxjs/toolkit'
import { noop } from 'lodash'

import { ReduxActionCallbacks } from 'packages/grimoire/src/utils'
import { DateFormat, formatLocalized } from 'packages/utils/dateHelpers'
import { RequestOptions } from 'packages/utils/store'
import { parseIntToTimestamp } from 'packages/utils/timestamp'

import { setIsCoverageStale } from '../../coverage/actions'
import { crossCoverageService } from '../crossCoverage.service'
import {
  CrossCoveragePostData,
  CrossCoveragePostAPIAttributes,
} from '../crossCoverage.types'

export const buildPayloadFrom = (
  postData: CrossCoveragePostData,
): RequestOptions => {
  const {
    endDate,
    endTimeLocal,
    friday,
    isRepeating,
    monday,
    saturday,
    startDate,
    startTimeLocal,
    sunday,
    thursday,
    tuesday,
    tz,
    userCoveredId,
    userCoveringId,
    wednesday,
  } = postData

  const attributes: Partial<CrossCoveragePostAPIAttributes> = {
    end_date: formatLocalized(endDate, DateFormat.ApiUtcShort),
    end_time_local: parseIntToTimestamp(endTimeLocal),
    friday,
    is_repeating: isRepeating,
    monday,
    saturday,
    start_date: formatLocalized(startDate, DateFormat.ApiUtcShort),
    start_time_local: parseIntToTimestamp(startTimeLocal),
    sunday,
    thursday,
    tuesday,
    tz: tz,
    wednesday,
  }

  const userFrom = (id: string) => ({ data: { id, type: 'user' } })

  const relationships = {
    user_covered: userFrom(userCoveredId),
    user_covering: userFrom(userCoveringId),
  }

  return {
    data: {
      attributes,
      relationships,
      type: 'cross_coverage',
    },
  }
}

type CreateCrossCoverageThunkArgs = {
  callbacks: ReduxActionCallbacks
  postData: CrossCoveragePostData
}

export const createCrossCoverage = createAsyncThunk(
  'crossCoverages/api/createCrossCoverage',
  async (
    { postData, callbacks }: CreateCrossCoverageThunkArgs,
    { dispatch },
  ) => {
    const { onSuccess = noop, onError = noop } = callbacks

    try {
      const payload = buildPayloadFrom(postData)
      const response = await crossCoverageService.createCrossCoverage(payload)
      dispatch(setIsCoverageStale(true))
      onSuccess(response)

      return response
    } catch (error) {
      onError(error)
    }
  },
)
