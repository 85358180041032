import { createSelector } from 'reselect'

import { ApplicationState } from 'app/fieldapp/store/store'

import { CleanTime } from '../cleantimes.types'
import { hydrateRawCleanTime } from '../cleantimes.utils'

const getFullState = (state: ApplicationState): ApplicationState => state

const getCleanTimeId = (_state, cleanTimeId: string): string => cleanTimeId

export const getCleanTimeById = createSelector(
  getFullState,
  getCleanTimeId,
  (state, id): CleanTime | undefined => {
    const hydrator = hydrateRawCleanTime(state)
    const rawCleanTime = state.cleanTimes.data[id]
    return rawCleanTime && hydrator(rawCleanTime)
  },
)
