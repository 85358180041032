import { User } from '@sentry/react'
import React from 'react'

import { logError } from 'packages/wiretap/logging'

import { useCrossCoverageDrawerTranslations } from '../../../../useCrossCoverageDrawerTranslations'
import { FormValidationError } from '../../../../utils/CrossCoverageState.helpers'
import {
  CrossCoverageFormAction,
  CrossCoverageFormState,
} from '../../../state/CrossCoverageForm.reducer'

type CrossCoverageFormStateWithAssignee = CrossCoverageFormState & {
  selectedAssignee: User
}

/**
 * Hook returns a Typeguard to confirm form state matches expected
 * validation and handles any dispatching error handling
 */
export const useValidation = ({
  activeUser,
  changeType,
  formDispatch,
}: {
  activeUser: User | undefined
  changeType: 'update' | 'create'
  formDispatch: React.Dispatch<CrossCoverageFormAction>
}): ((
  state: CrossCoverageFormState,
) => state is CrossCoverageFormStateWithAssignee) => {
  const strings = useCrossCoverageDrawerTranslations()
  const validateFormState = React.useCallback(
    (
      formState: CrossCoverageFormState,
    ): formState is CrossCoverageFormStateWithAssignee => {
      const stateHasSelectedAssignee = (
        state: CrossCoverageFormState,
      ): state is CrossCoverageFormState & {
        crossCoverageId: string
        selectedAssignee: User
      } => !!state.selectedAssignee

      if (changeType === 'update' && !formState.crossCoverageId) {
        // If this happens something is VERY wrong and it's likely better to crash the app than continue
        const error = new Error('Editing Cross Coverage without an ID')
        logError(error)
        throw error
      }

      if (!stateHasSelectedAssignee(formState) || !activeUser?.id) {
        const errorMessage =
          changeType === 'create'
            ? strings.createCrossCoverageFailure
            : strings.updateXCovFailure
        formDispatch({
          payload: new FormValidationError(errorMessage, {
            selectedAssignee: true,
          }),
          type: 'setFormValidationError',
        })

        return false
      }

      return true
    },
    [activeUser?.id, changeType, formDispatch, strings],
  )

  return validateFormState
}
