import classNames from 'classnames'
import React from 'react'

import {
  unitCodeAndNameString,
  unitFullAddressString,
} from 'packages/utils/hkStringHelpers'

import { MapsLink } from 'app/fieldapp/components/core'
import { Clean } from 'app/fieldapp/store/cleans'

import styles from '../../CleanDetail.module.scss'

export enum AddressInfoTestIds {
  container = 'AddressInfo__container',
}

export type AddressInfoProps = {
  clean: Clean
}

export const AddressInfo: React.FC<AddressInfoProps> = React.memo(
  ({ clean }) => {
    const { unit } = clean
    const unitNameString = unitCodeAndNameString(unit)
    const addressString = unitFullAddressString(unit)

    return (
      <section className={styles.addressSection}>
        <div className={styles.badge}></div>

        <div
          className={classNames(styles.addressInfoContainer, styles.unitName)}
        >
          {unitNameString}
        </div>

        <div className={styles.addressInfoContainer}>{addressString}</div>
        <MapsLink {...unit} />
      </section>
    )
  },
)
