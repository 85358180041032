import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useAsyncFn } from 'react-use'

import { useOnlineStatus } from 'packages/utils/hooks'
import { logInfo } from 'packages/wiretap/logging'

import { fetchCleanById } from 'app/fieldapp/store/cleans/actions'
import { getCleanById } from 'app/fieldapp/store/cleans/selectors'
import { getIncludedLockboxByUnitId } from 'app/fieldapp/store/lockboxes/selectors'
import { getIncludedSmartLockByCleanId } from 'app/fieldapp/store/smartlocks/selectors'
import { AppDispatch, ApplicationState } from 'app/fieldapp/store/store'

import { CleanDetailDrawer } from './CleanDetailDrawer'

export type CleanDetailDrawerContainerProps = { cleanId: string | undefined }

export const CleanDetailDrawerContainer: React.FC<
  CleanDetailDrawerContainerProps
> = ({ cleanId = '' }) => {
  const dispatch: AppDispatch = useDispatch()
  const navigate = useNavigate()
  const isOnline = useOnlineStatus().isOnline()

  // re-fetch the full clean details whenever the drawer is opened
  const [fetchCleanState, fetchClean] = useAsyncFn(async () => {
    if (!cleanId) return

    try {
      await dispatch(fetchCleanById(cleanId))
    } catch (err) {
      if (isOnline) {
        logInfo('Error fetching clean (drawer open)', {
          error: err?.message || 'unknown',
        })
      }
    }
  }, [cleanId, dispatch, isOnline])

  const clean = useSelector((state: ApplicationState) =>
    getCleanById(state, cleanId),
  )

  const lockbox = useSelector((state: ApplicationState) =>
    getIncludedLockboxByUnitId(state, clean?.unit.id || ''),
  )

  const smartLock = useSelector((state: ApplicationState) =>
    getIncludedSmartLockByCleanId(state, clean?.id || ''),
  )

  const afterExit = React.useCallback(() => {
    navigate('/cleans')
  }, [navigate])

  // if we have a clean ID but no clean is found, we are here by mistake; go back to main cleans page
  React.useEffect(() => {
    if (cleanId && !clean) {
      navigate('/cleans')
    }
  }, [clean, cleanId, navigate])

  return (
    <CleanDetailDrawer
      afterExit={afterExit}
      afterOpen={fetchClean}
      clean={clean}
      isLoadingClean={fetchCleanState.loading}
      isOpen={!!clean}
      lockbox={lockbox}
      smartLock={smartLock}
    />
  )
}
