import { TranslatorFn } from 'packages/i18n'
import {
  unitCodeAndNameString,
  unitFullAddressString,
} from 'packages/utils/hkStringHelpers'
import { getAdminViewTicketURL } from 'packages/utils/links'

import { NativeShareProps } from 'app/fieldapp/components/core'
import { Slugs } from 'app/fieldapp/i18n'
import { Ticket } from 'app/fieldapp/store/tickets'
import { Unit } from 'app/fieldapp/store/units'

import { formatTicketDate } from '../../tickets.helpers'

export function createShareContent(
  ticket: Ticket,
  unit: Unit,
  ut: TranslatorFn,
): NativeShareProps {
  const { id, severity, title, dueDate } = ticket

  const lines = [
    `Title: ${title}`,
    `Severity: ${severity}`,
    `${ut(Slugs.due)}: ${
      dueDate ? formatTicketDate(dueDate) : ut(Slugs.unknown)
    }`,
    '',
    `${ut(Slugs.unit)}: ${unitCodeAndNameString(unit)}`,
    `${ut(Slugs.address)}: ${unitFullAddressString(unit)}`,
    '',
    `${getAdminViewTicketURL(id)}`,
  ]

  return {
    text: lines.join('\n'),
    title: `Ticket for ${unit?.name || ut(Slugs.unknown)}`,
  }
}
