import styled from '@emotion/styled'
import React from 'react'

import {
  Button,
  FormHandlersOptional,
  FormProps,
  Loader,
} from 'packages/common'
import { IconName, SvgIcon } from 'packages/iconic'
import { colors, text } from 'packages/styles'

import { Slugs, useI18n } from 'app/fieldapp/i18n'

import { ExternalCommentToggle } from '../ExternalCommentToggle/ExternalCommentToggle'

const St = {
  Container: styled.div`
    display: flex;
    justify-content: space-between;
  `,
  Form: styled.form`
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    position: relative;
  `,
  StaffOnlyContainer: styled.div`
    align-items: center;
    background: #f9f9f9;
    border-bottom: 1px solid #ccd6db;
    border-left: 1px solid #ccd6db;
    border-radius: 0 0 4px 4px;
    border-right: 1px solid #ccd6db;
    display: flex;
    flex-grow: 1;
    justify-content: center;
  `,
  StaffOnlyText: styled.p`
    ${text.headingMicroCaps};
    margin: 0 0 0 8px;
  `,
  Submit: styled(Button)`
    align-self: flex-end;
    &:focus {
      outline: none; // this outline overflows the loader and just looks bad
    }
    margin: 8px 0 4px 8px;
  `,
  TextArea: styled.textarea`
    border: 1px solid ${colors.midnight20};
    border-radius: 4px 4px 4px 0;
    flex-grow: 1;
    font-size: 16px;
    padding: 8px 16px;
    max-width: 100%;
  `,
}

const useTranslations = () => {
  const { ut } = useI18n()
  return {
    comment: ut(Slugs.comment),
    placeholder: ut(Slugs.addCommentPlaceholder),
    staffOnly: ut(Slugs.staffOnly),
  }
}

export type TicketCommentFormValues = {
  note: string
}

export type TicketCommentFormProps = FormProps<TicketCommentFormValues> & {
  displayVisibilityToggle: boolean
  formState: {
    isExternal: boolean
  }
  handlers: Pick<FormHandlersOptional, 'changeToggle'>
}

export const TicketCommentForm: React.FC<TicketCommentFormProps> = React.memo(
  ({ displayVisibilityToggle, formState, handlers, requestState }) => {
    const strings = useTranslations()

    return (
      <St.Form onSubmit={handlers.submit}>
        <St.TextArea
          id="note"
          maxLength={1000}
          name="note"
          onChange={handlers.change}
          placeholder={strings.placeholder}
          rows={5}
          value={formState.values.note}
        />

        <St.Container>
          {displayVisibilityToggle ? (
            <ExternalCommentToggle
              checked={formState.isExternal}
              onToggle={handlers.changeToggle}
            />
          ) : (
            <St.StaffOnlyContainer>
              <SvgIcon icon={IconName.lock} size={14} />
              <St.StaffOnlyText>{strings.staffOnly}</St.StaffOnlyText>
            </St.StaffOnlyContainer>
          )}

          <St.Submit disabled={!formState.canSubmit} isFormSubmit={true}>
            {strings.comment}
          </St.Submit>
        </St.Container>
        {requestState.loading && <Loader />}
      </St.Form>
    )
  },
)
