import styled from '@emotion/styled'
import React from 'react'

import { colors, text } from 'packages/styles'
import { getSystemTZ } from 'packages/utils/timezone.helpers'

import { Slugs, useI18n } from 'app/fieldapp/i18n'

import { CoverageEntryContainer } from './CoverageEntry.container'
import {
  TimelineEntry,
  timelineTimeFormatter,
} from './DayScheduleTimeline.utils'

const STATIC_MEASUREMENTS = {
  dotWidth: 10,
  endEntryHeight: 20,
  entryHeight: 55,
  entryTimeWidth: 60,
  gap: 16,
  timelineWidth: 2,
}

const DOT_POSITION =
  STATIC_MEASUREMENTS.entryTimeWidth +
  STATIC_MEASUREMENTS.gap +
  STATIC_MEASUREMENTS.timelineWidth / 2

const St = {
  EntryDot: styled.div`
    background-color: ${colors.dusk60};
    border-radius: 50%;
    height: ${STATIC_MEASUREMENTS.dotWidth}px;
    left: ${DOT_POSITION}px;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    width: ${STATIC_MEASUREMENTS.dotWidth}px;
  `,

  EntryTime: styled.div<{ isEnd?: boolean }>`
    align-items: flex-start;
    display: flex;
    height: ${({ isEnd }) =>
      STATIC_MEASUREMENTS[isEnd ? 'endEntryHeight' : 'entryHeight']}px;
    // We want to make sure the time is not hanging lower than the dot at the bottom of the timeline, this corrects for that
    transform: ${({ isEnd }) => (isEnd ? 'translateY(-3px)' : 'none')};
    width: ${STATIC_MEASUREMENTS.entryTimeWidth}px;
  `,

  EntryTimeText: styled.span`
    ${text.bodyBoldTiny};
    color: ${colors.dusk60};
  `,

  Timeline: styled.div`
    background-color: ${colors.dusk60};
    height: ${STATIC_MEASUREMENTS.entryHeight}px;
    width: ${STATIC_MEASUREMENTS.timelineWidth}px;
  `,

  TimelineContainer: styled.div`
    padding: 16px;
    background-color: ${colors.white};
  `,

  TimelineEntryContainer: styled.div<{ isEnd?: boolean }>`
    align-items: center;
    display: flex;
    gap: ${STATIC_MEASUREMENTS.gap}px;
    height: ${({ isEnd }) =>
      STATIC_MEASUREMENTS[isEnd ? 'endEntryHeight' : 'entryHeight']}px;
    margin: 0;
    position: relative;
    width: 100%;
  `,

  TimelineTimeZoneText: styled.span`
    ${text.bodyRegularDefault}
    display: flex;
    font-style: italic;
    justify-content: right;
  `,
}

const useTranslations = () => {
  const { t } = useI18n()

  return {
    timelineIsIn: t(Slugs.timelineIsIn),
  }
}

export enum DayScheduleTimelineTestIds {
  container = 'DayScheduleTimeline__container',
  entry = 'DayScheduleTimeline__entry',
}

export type DayScheduleTimelineProps = {
  entries: TimelineEntry[]
}

export const DayScheduleTimeline: React.FC<DayScheduleTimelineProps> =
  React.memo(({ entries }) => {
    const strings = useTranslations()

    return (
      <St.TimelineContainer>
        {entries.map(entry => {
          return (
            <St.TimelineEntryContainer
              data-testid={DayScheduleTimelineTestIds.entry}
              key={entry.id}
            >
              <St.EntryTime>
                <St.EntryTimeText>
                  {timelineTimeFormatter(entry.startTime)}
                </St.EntryTimeText>
              </St.EntryTime>
              <St.EntryDot />

              <St.Timeline />
              <CoverageEntryContainer entry={entry} />
            </St.TimelineEntryContainer>
          )
        })}
        <St.TimelineEntryContainer
          data-testid={DayScheduleTimelineTestIds.entry}
          isEnd={true}
        >
          <St.EntryTime isEnd={true}>
            <St.EntryTimeText>
              {timelineTimeFormatter(entries[entries.length - 1].endTime)}
            </St.EntryTimeText>
          </St.EntryTime>
          <St.EntryDot />
        </St.TimelineEntryContainer>
        <St.TimelineTimeZoneText>
          {strings.timelineIsIn + ' ' + getSystemTZ()}
        </St.TimelineTimeZoneText>
      </St.TimelineContainer>
    )
  })
