import styled from '@emotion/styled'
import React, { useState } from 'react'

import { text } from 'packages/styles'

import { Slugs, useI18n } from 'app/fieldapp/i18n'

export enum TextReviewTestIds {
  container = 'TextReview__container',
  content = 'TextReview__content',
  headline = 'TextReview__headline',
  showMoreButton = 'TextReview__showMoreButton',
}

const DEFAULT_SHOW_MORE_LIMIT = 300
const TRUNCATION_BUFFER = 50

// Styled components with 'st' prefix
const St = {
  Container: styled.div`
    margin: 1rem 0;
  `,
  Content: styled.p`
    ${text.bodyRegularDefault};
  `,
  SectionHeader: styled.div`
    ${text.bodyBoldRegular};
    margin: 0.5rem 0;
  `,
  ShowMoreButton: styled.a`
    background-color: transparent;
    border: none;
    color: #007bff;
    cursor: pointer;
    font-size: 0.875rem;

    &:hover {
      text-decoration: underline;
    }
  `,
}

export type TextReviewProps = {
  content?: string
  label: string
  showMoreLimit?: number
}

export const TextReview: React.FC<TextReviewProps> = React.memo(
  ({ label, content = '', showMoreLimit = DEFAULT_SHOW_MORE_LIMIT }) => {
    const { t } = useI18n()
    const [isExpanded, setIsExpanded] = useState(false)

    if (!content) return null

    const shouldTruncate = content.length > showMoreLimit
    const displayedContent =
      isExpanded || !shouldTruncate
        ? content
        : `${content.slice(0, showMoreLimit - TRUNCATION_BUFFER)}...`

    return (
      <St.Container data-testid={TextReviewTestIds.container}>
        <St.SectionHeader data-testid={TextReviewTestIds.headline}>
          {label}
        </St.SectionHeader>
        <St.Content data-testid={TextReviewTestIds.content}>
          {displayedContent}
        </St.Content>
        {shouldTruncate && (
          <St.ShowMoreButton
            onClick={() => setIsExpanded(!isExpanded)}
            data-testid={TextReviewTestIds.showMoreButton}
          >
            {isExpanded ? t(Slugs.showLess) : t(Slugs.showMore)}
          </St.ShowMoreButton>
        )}
        <hr />
      </St.Container>
    )
  },
)
