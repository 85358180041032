import styled from '@emotion/styled'
import React from 'react'

import { Checkbox, Loader } from 'packages/common'
import { colors } from 'packages/styles'

import { convertIsCompleteToBoolean } from 'app/fieldapp/components/schedule/schedule.utils'
import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { InspectionListItem } from 'app/fieldapp/store/tasks'

import { useInspectionContext } from '../../context/InspectionChecklist.context'

const St = {
  InspectionChecklistItem: styled.div`
    color: ${colors.dusk};
    font-size: 15px;
    padding: 20px;
    position: relative;

    &:not(:last-of-type) {
      border-bottom: 1px solid ${colors.midnight20};
    }
  `,
}

const useTranslations = () => {
  const { t } = useI18n()

  return {
    toggleItemError: t(Slugs.inspectionErrorToggleItem),
  }
}

export type InspectionChecklistItemProps = {
  checklistItem: InspectionListItem
  onToggleItem: () => void
}

export const InspectionChecklistItem: React.FC<InspectionChecklistItemProps> =
  React.memo(({ checklistItem, onToggleItem }) => {
    const strings = useTranslations()

    const {
      uiDisabledState,
      uiState: { errorIds, loadingIds },
    } = useInspectionContext()

    const hasError = React.useMemo(
      () => errorIds.includes(checklistItem.id),
      [checklistItem, errorIds],
    )

    const isLoading = React.useMemo(
      () => loadingIds.includes(checklistItem.id),
      [checklistItem, loadingIds],
    )

    return (
      <St.InspectionChecklistItem>
        <Checkbox
          checked={convertIsCompleteToBoolean(checklistItem.isComplete)}
          disabled={!!uiDisabledState}
          error={hasError ? strings.toggleItemError : ''}
          label={checklistItem.title}
          onChange={onToggleItem}
          value={checklistItem.id}
        />
        {isLoading && <Loader />}
      </St.InspectionChecklistItem>
    )
  })
