import * as React from 'react'

import { Alert, Button } from 'packages/common'

import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { TicketStatus } from 'app/fieldapp/store/tickets'

import { Section } from '../TicketDetail.styles'
import { useTicketStatus } from './useTicketStatus'

type StatusSectionProps = {
  disabledBecauseCommentsCriteria: boolean
  status: TicketStatus
  ticketId: string
}

export const StatusSection: React.FC<StatusSectionProps> = ({
  ticketId,
  status,
  disabledBecauseCommentsCriteria,
}) => {
  const { text, onClick, disabled, loading } = useTicketStatus(ticketId, status)
  const { t } = useI18n()
  const isStatusAccepted = status === TicketStatus.ACCEPTED
  const isDisabledDueToCommentsCriteria =
    isStatusAccepted && disabledBecauseCommentsCriteria

  return (
    <Section>
      {/* show this only when user is completing status */}
      {isDisabledDueToCommentsCriteria && (
        <Alert alertType={'warning'}>
          {t(Slugs.ticketCantBeCompletedWarning)}
        </Alert>
      )}
      <Button
        block={true}
        disabled={disabled || isDisabledDueToCommentsCriteria}
        isLoading={loading}
        onClick={onClick}
        buttonType={'utility'}
      >
        {text}
      </Button>
    </Section>
  )
}
