import * as React from 'react'
import { useSelector } from 'react-redux'

import { useOnlineStatus } from 'packages/utils/hooks'

import { Clean } from 'app/fieldapp/store/cleans'
import { getHasActiveCleanTimeForTask } from 'app/fieldapp/store/cleantimes/selectors/getHasActiveCleanTimeForTask'
import { ApplicationState } from 'app/fieldapp/store/store'
import { useActiveUser } from 'app/fieldapp/utils/hooks/useActiveUser'

import {
  DisabledUiType,
  initialInspectionChecklistUIState,
  InspectionChecklistUiState,
  InspectionChecklistUiStateAction,
  inspectionChecklistUiStateReducer,
} from './InspectionChecklist.context.helpers'

export type InspectionChecklistContextType = {
  clean: Clean
  uiDisabledState: DisabledUiType
  uiState: InspectionChecklistUiState
  uiStateDispatch: React.Dispatch<InspectionChecklistUiStateAction>
}

export const InspectionChecklistContext =
  React.createContext<InspectionChecklistContextType>(
    {} as InspectionChecklistContextType,
  )

InspectionChecklistContext.displayName = 'InspectionChecklistContext'
export const useInspectionContext = (): InspectionChecklistContextType =>
  React.useContext(InspectionChecklistContext)

export const InspectionChecklistContextWrapper: React.FC<{ clean: Clean }> =
  React.memo(({ clean, children }) => {
    const [uiState, uiStateDispatch] = React.useReducer(
      inspectionChecklistUiStateReducer,
      initialInspectionChecklistUIState,
    )

    const hasActiveTimer = useSelector((s: ApplicationState) =>
      getHasActiveCleanTimeForTask(s, clean.id),
    )

    const isOnline = useOnlineStatus().isOnline()

    const { isEmployee } = useActiveUser()

    const uiDisabledState: DisabledUiType = React.useMemo(() => {
      if (!!clean.completedAt) {
        return 'cleanIsComplete'
      }

      if (!hasActiveTimer && isEmployee) {
        return 'noActiveTimer'
      }

      if (!isOnline) {
        return 'offline'
      }

      return null
    }, [clean, hasActiveTimer, isEmployee, isOnline])

    const value = React.useMemo(
      () => ({
        clean,
        uiDisabledState,
        uiState,
        uiStateDispatch,
      }),
      [clean, uiDisabledState, uiState, uiStateDispatch],
    )

    return (
      <InspectionChecklistContext.Provider value={value}>
        {children}
      </InspectionChecklistContext.Provider>
    )
  })
