import get from 'lodash/get' // eslint-disable-line

import { RequestOptions } from 'packages/utils/store/jsonapi.types'

import { api } from '../axiosInstance'
import {
  CleanTimesApiResponse,
  CleanTimesServiceResponse,
} from './cleantimes.types'
import { emptyCleanTimesResponse } from './cleantimes.utils'

const URL = '/clean_times'

export const cleanTimesService = {
  /**
   * Sends a GET request to the server to fetch CleanTimes by the specified params
   * @param params
   */
  async fetchCleanTimes(
    params: RequestOptions = {},
  ): CleanTimesServiceResponse {
    const response: CleanTimesApiResponse = await api.get(URL, {
      params,
    })

    return get(response, 'data', emptyCleanTimesResponse)
  },

  /**
   * Sends a GET request to the server for a CleanTime with the specified ID
   * @param id
   * @param params
   */
  async fetchCleanTimeById(
    id: string,
    params: RequestOptions = {},
  ): CleanTimesServiceResponse {
    const response: CleanTimesApiResponse = await api.get(`${URL}/${id}`, {
      params,
    })
    return get(response, 'data', emptyCleanTimesResponse)
  },

  /**
   * Sends a POST request to the server to create a new CleanTime
   * @param data
   */
  async createCleanTime(data: RequestOptions): CleanTimesServiceResponse {
    const response: CleanTimesApiResponse = await api.post(URL, data)

    return get(response, 'data', emptyCleanTimesResponse)
  },

  /**
   * Sends a PATCH request to update the associated CleanTime
   * @param cleanTimeId
   * @param data
   */
  async updateCleanTime(
    cleanTimeId: string,
    data: RequestOptions,
  ): CleanTimesServiceResponse {
    const response: CleanTimesApiResponse = await api.patch(
      `${URL}/${cleanTimeId}`,
      data,
    )

    return get(response, 'data', emptyCleanTimesResponse)
  },

  async deleteCleanTime(cleanTimeId: string): CleanTimesServiceResponse {
    const response: CleanTimesApiResponse = await api.delete(
      `${URL}/${cleanTimeId}`,
    )

    return get(response, 'data', emptyCleanTimesResponse)
  },
}
