import React from 'react'

import { Alert } from 'packages/common'
import { useI18n } from 'packages/i18n'

import { Slugs } from 'app/fieldapp/i18n'
import { Ticket } from 'app/fieldapp/store/tickets'

import { DetailsTicketCardContainer } from '../DetailsTicketCardContainer'

export type DetailsTicketListProps = {
  tickets: Ticket[]
}

export const DetailsTicketList: React.FC<DetailsTicketListProps> = React.memo(
  ({ tickets = [] }) => {
    const { t } = useI18n()

    return tickets.length === 0 ? (
      <Alert alertType={'info'}>{t(Slugs.noOpenTickets)}</Alert>
    ) : (
      <>
        {tickets.map(ticket => (
          <DetailsTicketCardContainer ticketId={ticket.id} key={ticket.id} />
        ))}
      </>
    )
  },
)
