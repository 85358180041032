import styled from '@emotion/styled'
import React, { useState } from 'react'

import { Rating } from 'packages/common'
import { IconName, SvgIcon } from 'packages/iconic'
import { colors, text } from 'packages/styles'

import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { RatingInfo } from 'app/fieldapp/store/reviews/reviews.types'

export enum RatingCollapsibleTestIds {
  container = 'RatingCollapsible__container',
  header = 'RatingCollapsible__header',
  item = 'RatingCollapsible__item',
  list = 'RatingCollapsible__list',
  toggleButton = 'RatingCollapsible__toggleButton',
}

const DEFAULT_COLLAPSIBLE_LIMIT = 3

const St = {
  Container: styled.div`
    margin: 1rem 0;
    border-bottom: ;
  `,
  Header: styled.div`
    ${text.bodyBoldRegular};
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;

    & span {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  `,
  Item: styled.li`
    ${text.bodyRegularDefault};
    border: solid ${colors.midnight10};
    border-width: 0 0 1px 0;
    display: flex;
    justify-content: space-between;
    padding: 0.25rem;
  `,
  List: styled.ul`
    list-style: none;
    margin: 0;
    padding: 0 2rem;
  `,
  ToggleButton: styled.button`
    background-color: transparent;
    border: none;
    color: #007bff;
    cursor: pointer;
    padding-left: 1rem;
    font-size: 0.875rem;

    &:hover {
      text-decoration: underline;
    }
  `,
}

export type RatingCollapsibleProps = {
  collapsibleLimit?: number
  overallRating: number
  ratings: RatingInfo[]
}

export const RatingCollapsible: React.FC<RatingCollapsibleProps> = React.memo(
  ({
    overallRating,
    ratings,
    collapsibleLimit = DEFAULT_COLLAPSIBLE_LIMIT,
  }) => {
    const { t } = useI18n()
    const [isExpanded, setIsExpanded] = useState(false)

    const shouldCollapse = ratings.length > collapsibleLimit
    const displayedRatings =
      isExpanded || !shouldCollapse
        ? ratings
        : ratings.slice(0, collapsibleLimit)

    return (
      <St.Container data-testid={RatingCollapsibleTestIds.container}>
        <St.Header data-testid={RatingCollapsibleTestIds.header}>
          {t(Slugs.reviewOverallRating)}
          <span>
            <Rating value={overallRating} />
            {shouldCollapse && (
              <St.ToggleButton
                onClick={() => setIsExpanded(!isExpanded)}
                data-testid={RatingCollapsibleTestIds.toggleButton}
              >
                {isExpanded ? (
                  <SvgIcon size={24} icon={IconName.chevronTop} />
                ) : (
                  <SvgIcon size={24} icon={IconName.chevronDown} />
                )}
              </St.ToggleButton>
            )}
          </span>
        </St.Header>
        <St.List data-testid={RatingCollapsibleTestIds.list}>
          {displayedRatings.map((rating, index) => (
            <St.Item key={index} data-testid={RatingCollapsibleTestIds.item}>
              {rating.label}: <Rating value={rating.value} />
            </St.Item>
          ))}
        </St.List>
        <hr />
      </St.Container>
    )
  },
)
