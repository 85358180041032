import { lowerCase } from 'lodash/fp'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { useInterval } from 'react-use'

import { useI18n } from 'packages/i18n'
import {
  createDateObject,
  differenceInSeconds,
} from 'packages/utils/dateHelpers'
import { formatSecondsAsDigitalClock } from 'packages/utils/mathHelpers'

import { useOtherTimerActions } from 'app/fieldapp/components/timers/hooks'
import { useTimers as useTimersContext } from 'app/fieldapp/components/timers/state'
import { Slugs } from 'app/fieldapp/i18n'
import { OtherTimer, OtherTimerType } from 'app/fieldapp/store/otherTimers'
import { getActiveOtherTimer } from 'app/fieldapp/store/otherTimers/selectors'

import { useOtherTimerMenuConfig } from '../../OtherTimers/hooks/useOtherTimerMenuConfig'
import { isSubMenuType } from '../../OtherTimers/OtherTimers.helpers'
import { ActiveOtherTimer } from './ActiveOtherTimer'

export type ActiveOtherTimerStrings = {
  timerName: string
  trackMileage: string
}

export const useTranslations = (
  otherTimer?: OtherTimer,
): ActiveOtherTimerStrings => {
  const { t, ut } = useI18n()

  const { getMenuByTaskType } = useOtherTimerMenuConfig()

  const getTimerName = () => {
    const timerType = otherTimer?.timerType
    const subType = otherTimer?.subTimerType

    if (isSubMenuType(timerType) && subType) {
      /* Use the timer menu config for the other timer type to determine the correct slug to display */
      const menu = getMenuByTaskType(timerType)
      const slug = menu.timers.find(timer => timer.subType === subType)?.slug

      if (slug && timerType === OtherTimerType.OTHER) return ut(slug)
      if (slug && timerType === OtherTimerType.DRIVE) {
        return `${ut(Slugs.driveTo)} ${lowerCase(t(slug))}`
      }
    }

    return ut(Slugs[timerType || OtherTimerType.LAUNDRY])
  }

  return {
    timerName: getTimerName(),
    trackMileage: ut(Slugs.trackMileage),
  }
}

export const ActiveOtherTimerContainer: React.FC = () => {
  const activeOtherTime = useSelector(getActiveOtherTimer)
  const strings = useTranslations(activeOtherTime)

  const {
    dispatchBeginOtherTimerSubmission,
    dispatchBeginOtherTimerStartedOfflineSubmission,
  } = useOtherTimerActions()

  const { state: timersState } = useTimersContext()
  const { loadingTimerId } = timersState
  const isLoading = !!loadingTimerId

  const getRunningTime = React.useCallback(
    () =>
      activeOtherTime
        ? differenceInSeconds(createDateObject(), activeOtherTime.startedAt)
        : 0,
    [activeOtherTime],
  )

  const [runningTime, setRunningTime] = React.useState(getRunningTime)

  const stopActiveTimer = React.useCallback(() => {
    if (activeOtherTime) {
      activeOtherTime.startedOffline
        ? dispatchBeginOtherTimerStartedOfflineSubmission(activeOtherTime)
        : dispatchBeginOtherTimerSubmission(activeOtherTime)
    }
  }, [
    activeOtherTime,
    dispatchBeginOtherTimerStartedOfflineSubmission,
    dispatchBeginOtherTimerSubmission,
  ])

  /** Set up an interval to update the "running" timer display once per second. */
  useInterval(
    () => {
      setRunningTime(getRunningTime())
    },
    // If the timer is loading (submitting), disable the interval
    isLoading ? null : 1000,
  )

  return (
    <ActiveOtherTimer
      isLoading={isLoading}
      stopActiveTimer={stopActiveTimer}
      strings={strings}
      timeString={formatSecondsAsDigitalClock(runningTime)}
    />
  )
}
