import { produce } from 'immer'
import { merge, reduce, times, xor } from 'lodash/fp'
import { createAsyncAction } from 'typesafe-actions'

import { apiFilterExcludeVisits } from 'packages/grimoire'
import { DateFormat, format } from 'packages/utils/dateHelpers'
import {
  RequestConfig,
  RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import { ApplicationState } from 'app/fieldapp/store/store'

import { AssignmentApiFields } from '../../assignments'
import { HkEmployeeType, HousekeeperApiFields } from '../../housekeepers'
import { LockboxApiFields } from '../../lockboxes'
import { ReservationApiFields } from '../../reservations'
import { SmartLockApiFields } from '../../smartlocks'
import { NormalizedTasksApiResponse } from '../../tasks'
import { tasksService } from '../../tasks/tasks.service'
import { UnitApiFields } from '../../units'
import { UserApiFieldsWithoutPII } from '../../users'
import { getActiveUser } from '../../users/selectors'
import { CleanApiFields, CleansActionTypes } from '../cleans.types'

export const CLEANS_PAGE_SIZE = 100

export const fetchCleansAction = createAsyncAction(
  CleansActionTypes.FETCH_CLEANS,
  CleansActionTypes.FETCH_CLEANS_SUCCESS,
  CleansActionTypes.FETCH_CLEANS_FAILURE,
)<
  RequestConfig<NormalizedTasksApiResponse>,
  NormalizedTasksApiResponse,
  Error
>()

export const getDateRange = (startDate: string, endDate?: string) =>
  endDate
    ? {
        range: [
          format(startDate, DateFormat.ApiUtcShort),
          format(endDate, DateFormat.ApiUtcShort),
        ],
      }
    : format(startDate, DateFormat.ApiUtcShort)

// omit inspectionChecklist from the "all cleans" request for performance reasons
const cleanFields = xor(['inspection_checklist'], CleanApiFields)

export const getParams = (
  userId: string,
  dateRange: string | { range: string[] },
  isEmployee: boolean,
): RequestOptions => {
  // HACK: To prevent disappearing timers on team cleans, don't filter completed cleans for employees
  const completedFilter = isEmployee ? {} : { completed_at: { isnull: true } }

  return {
    fields: {
      assignment: AssignmentApiFields,
      housekeeper: HousekeeperApiFields,
      lock_box: LockboxApiFields,
      reservation: ReservationApiFields,
      smart_lock: SmartLockApiFields,
      task: cleanFields,
      unit: UnitApiFields,
      user: UserApiFieldsWithoutPII,
    },
    filter: {
      'active_assignments.housekeeper.user': userId,
      effective_date: dateRange,
      is_active: true,
      ...completedFilter,
      ...apiFilterExcludeVisits,
    },
    include: [
      'active_assignments',
      'active_assignments.housekeeper',
      'active_assignments.housekeeper.user',
      'reservation',
      'smart_locks',
      'unit',
      'unit.lock_box',
    ],
    page: { size: CLEANS_PAGE_SIZE },
    sort: ['effective_date'],
  }
}

const getIsUserEmployee = (state: ApplicationState) => {
  const activeUser = getActiveUser(state)

  return activeUser?.employeeType === HkEmployeeType.employee
}

export const fetchCleans =
  (userId: string, startDate: string, endDate?: string) =>
  async (dispatch, getState: () => ApplicationState) => {
    try {
      const isEmployee = getIsUserEmployee(getState())
      const dateRange = getDateRange(startDate, endDate)
      const params = getParams(userId, dateRange, isEmployee)
      const request = tasksService.fetchTasks.bind(null, params)

      const initialResult: NormalizedTasksApiResponse = await dispatch(
        fetchCleansAction.request({ request }),
      )

      const totalPages = initialResult?.raw?.meta?.num_pages || 1

      // If there's only one page, we're done
      if (totalPages <= 1) {
        dispatch(fetchCleansAction.success(initialResult))
        return initialResult.normalized
      }

      // Fetch the additional pages

      /* Create an array of params - one for each additional page we need to fetch */
      const paramsWithPage = times(
        index =>
          produce(params, draft => {
            // Offset by 2: 1 because we already fetched the first page, and 1 because page numbers are 1-indexed
            draft.page = { number: index + 2, size: CLEANS_PAGE_SIZE }
          }),
        // Offset by 1 because we already fetched the first page
        totalPages - 1,
      )

      /* Fetch the remaining pages in parallel */
      const additionalPages = await Promise.all(
        paramsWithPage.map(async params => {
          const request = tasksService.fetchTasks.bind(null, params)

          const result = await dispatch(fetchCleansAction.request({ request }))

          return result as NormalizedTasksApiResponse
        }),
      )

      /* Merge the additional pages into the initial result */
      const mergedResponse = reduce(
        merge,
        initialResult,
        additionalPages,
      ) as NormalizedTasksApiResponse

      dispatch(fetchCleansAction.success(mergedResponse))
      return mergedResponse.normalized
    } catch (error) {
      dispatch(fetchCleansAction.failure(error))
      throw error
    }
  }
