import { createSelector } from 'reselect'

import { ApplicationState } from 'app/fieldapp/store/store'
import { hydrateRawUser, JSONApiUsersMap, User } from 'app/fieldapp/store/users'

const getCoverageOptionsState = (state: ApplicationState): JSONApiUsersMap =>
  state.users.coveragePartnerOptions

export const getCoveragePartnerOptions = createSelector(
  getCoverageOptionsState,
  (users): User[] => {
    return Object.values(users).map(hydrateRawUser)
  },
)
