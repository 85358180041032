import upperFirst from 'lodash/upperFirst'
import * as React from 'react'

import { TranslatorFn } from 'packages/i18n'

import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { fullName, User } from 'app/fieldapp/store/users'

import styles from './ProfileHeader.module.scss'

export const TEST_ID_PROFILE_PAGE_HEADER = 'profilePage__header'

export const getProfileHeaderStrings = (
  t: TranslatorFn,
  userFullName: string,
): Record<string, string> => {
  const up = (val: string, vars = {}) => upperFirst(t(val, vars))

  return {
    hi: userFullName ? up(Slugs.hi, { userName: userFullName }) : '',
  }
}

export type ProfileHeaderProps = {
  user: User | undefined
}

export const ProfileHeader: React.FC<ProfileHeaderProps> = React.memo(
  ({ user }) => {
    const { t } = useI18n()

    const userFullName = (user && fullName(user)) || ''

    /** Memoize translated strings */
    const strings = React.useMemo(
      () => getProfileHeaderStrings(t, userFullName),
      [t, userFullName],
    )

    return (
      <div
        className={styles.profileHeader}
        data-testid={TEST_ID_PROFILE_PAGE_HEADER}
      >
        <span className={styles.userName}>{strings.hi}</span>
      </div>
    )
  },
)
