import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { fetchCoverageByDateRange } from './actions'
import { CoverageState } from './coverage.types'
import { emptyNormalizedCoverageData } from './coverage.utils'

const initialState: CoverageState = {
  data: {},
  isStale: false,
}

export const coverageSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(fetchCoverageByDateRange.fulfilled, (state, action) => {
      const normalized =
        action.payload.normalized.coverage ||
        emptyNormalizedCoverageData.coverage
      state.data = normalized
      state.isStale = false
    })
  },
  initialState,
  name: 'coverage',
  reducers: {
    setIsCoverageStale: (state, action: PayloadAction<boolean>) => {
      state.isStale = action.payload
    },
  },
})

export const { setIsCoverageStale } = coverageSlice.actions
export default coverageSlice.reducer
