import { OtherTimerType } from 'packages/grimoire'

import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { TimerType } from 'app/fieldapp/store/utils'

type UseNoteSectionText = {
  label: string
  placeholder: string
}

/** Type that provides information about the timer type required to render "NotesSection" correctly  */
export type NotesTimerType = OtherTimerType | TimerType.TICKET | undefined

export function useNoteSectionText(
  timerType: NotesTimerType,
): UseNoteSectionText {
  const { t } = useI18n()

  const getNotesLabel = (tt: NotesTimerType | undefined): string => {
    switch (tt) {
      case OtherTimerType.DRIVE:
        return t(Slugs.driveTimeNotesLabel)
      case OtherTimerType.OTHER:
        return t(Slugs.otherTimeNotesLabel)
      case TimerType.TICKET:
        return t(Slugs.commentsWillBePosted)
      default:
        return t(Slugs.genericNotesLabel)
    }
  }

  const getPlaceholderText = (tt: NotesTimerType | undefined): string => {
    switch (tt) {
      case OtherTimerType.DRIVE:
        return 'Start: 123 A st city, state \nEnd: 321 ln B, city, state'
      case OtherTimerType.OTHER:
        return t(Slugs.otherNotesPlaceholderText)
      default:
        return ''
    }
  }

  return {
    label: getNotesLabel(timerType),
    placeholder: getPlaceholderText(timerType),
  }
}
