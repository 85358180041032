import styled from '@emotion/styled'
import { useDecision } from '@optimizely/react-sdk'
import React from 'react'

import { SanitizedHTML } from 'packages/common/src/SanitizedHTML'
import { UnitLinks } from 'packages/common/src/UnitLinks'
import { IconName, SvgIcon } from 'packages/iconic'
import { Features } from 'packages/optimizely'
import { colors } from 'packages/styles'
import { useOnlineStatus } from 'packages/utils/hooks'
import { getAdminViewTicketURL } from 'packages/utils/links'

import { MapsLink } from 'app/fieldapp/components/core'
import { CreateTicketDrawer } from 'app/fieldapp/components/schedule/components/common'
import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { Ticket } from 'app/fieldapp/store/tickets'
import { Unit } from 'app/fieldapp/store/units'

import { UnitOccupancyDetailsContainer } from '../../core/components/UnitOccupancyDetails'
import { StartTimerButton } from '../../timers/components/Buttons/StartTimerButton'
import { useTranslateTickets } from '../hooks'
import { TicketAttachments } from '../TicketAttachments/TicketAttachments'
import { TicketComments } from '../TicketComments'
import { AccessSection } from './AccessSection'
import { DatesSection } from './DatesSection'
import { StatusSection } from './StatusSection'
import {
  Container,
  Description,
  ExternalLink,
  Row,
  Section,
  StatusBar,
  SubTitle,
  TicketTemplate,
  Title,
  Visibility,
} from './TicketDetail.styles'
import { useTicketDetails } from './useTicketDetails'

const St = {
  Separator: styled.hr`
    border: 0;
    border-bottom: 1px solid ${colors.midnight20};
    margin: 0;
    padding: 0;
  `,

  TicketAttachmentsSection: styled.div`
    margin: 16px 24px 16px 24px !important;
  `,

  TicketCommentsSection: styled.div`
    margin: 16px 24px 16px 24px !important;
  `,

  TicketDetailCreateTicketDrawer: styled(CreateTicketDrawer)`
    padding: 0 24px 16px 24px;
  `,

  TicketDetailsUnitLinks: styled(UnitLinks)`
    padding: 16px 24px 0 24px;
  `,
}

const iconTextStyles: React.CSSProperties = {
  fontWeight: 'bold',
  lineHeight: '16px',
  textTransform: 'uppercase',
}

const useTranslations = (ticket: Ticket) => {
  const { ut } = useI18n()
  const { translateSeverity } = useTranslateTickets()

  return {
    description: ut(Slugs.description),
    requestedBy: ut(Slugs.requestedBy),
    severity: translateSeverity(ticket.severity),
    templateDetails: ut(Slugs.templateDetails),
    viewTicket: ut(Slugs.viewTicket),
    visibility: ut(Slugs.visibility),
    visibilityValues: {
      internal: ut(Slugs.staffOnly),
      owner: ut(Slugs.staffAndOwner),
    },
  }
}

export type TicketDetailProps = {
  canBeCompleted: boolean
  commentIds: string[]
  ticket: Ticket
  unit: Unit
}

export const TicketDetail: React.FC<TicketDetailProps> = ({
  commentIds,
  ticket,
  unit,
  canBeCompleted,
}) => {
  const [decision] = useDecision(Features.TICKET_ATTACHMENTS)
  const isTicketAttachmentsEnabled = decision.enabled
  const strings = useTranslations(ticket)
  const isOnline = useOnlineStatus().isOnline()

  const { handleStartTimer, isTimerDisabled, isTimerLoading } =
    useTicketDetails(ticket)

  const ticketInAdminURL = getAdminViewTicketURL(ticket.id)

  return (
    <Container>
      <StatusBar severity={ticket.severity}>
        <SvgIcon
          centerItems={true}
          icon={IconName.tag}
          text={strings.severity}
          textFontSize={12}
          textStyleOverrides={iconTextStyles}
        />
      </StatusBar>
      <Section>
        <Title>
          <div>{ticket.title}</div>
          <div>
            {unit.unitCode} - {unit.name}
          </div>
        </Title>

        <SubTitle>
          {unit.address},<br />
          {unit.city}, {unit.state}
        </SubTitle>

        <Row>
          <MapsLink
            address={unit.address}
            city={unit.city}
            state={unit.state}
          />
          <StartTimerButton
            disabled={isTimerDisabled}
            isLoading={isTimerLoading}
            onClick={handleStartTimer}
          />
        </Row>

        <Row>
          <UnitOccupancyDetailsContainer unit={unit} ticketId={ticket.id} />
        </Row>
      </Section>

      <Section>
        <Description>
          <b>{strings.description}:</b>
          {!!ticket.description && <SanitizedHTML html={ticket.description} />}
        </Description>
        {!!ticket.templateBody && (
          <TicketTemplate>
            <b>{strings.templateDetails}:</b> {ticket.templateBody}
          </TicketTemplate>
        )}
        <div>
          <b>{strings.requestedBy}:</b> {ticket.requestedBy}
        </div>
        <Visibility>
          <b>{strings.visibility}:</b>{' '}
          {strings.visibilityValues[ticket.visibility]}
        </Visibility>

        <div>
          <ExternalLink text={strings.viewTicket} url={ticketInAdminURL} />
        </div>
      </Section>

      <StatusSection
        ticketId={ticket.id}
        status={ticket.status}
        disabledBecauseCommentsCriteria={!canBeCompleted}
      />
      <DatesSection
        createdAt={ticket.createdAt}
        dueDate={ticket.dueDateNoTimestamp}
      />
      <AccessSection
        visitNeededForAccess={!ticket.openVisitId}
        {...unit.amenities}
      />

      {/* COMMENTS SECTION */}
      <St.TicketCommentsSection>
        <TicketComments commentIds={commentIds} ticketId={ticket.id} />
      </St.TicketCommentsSection>

      {isTicketAttachmentsEnabled && isOnline && (
        <>
          <St.Separator />
          <St.TicketAttachmentsSection>
            <TicketAttachments ticketId={ticket.id} />
          </St.TicketAttachmentsSection>
        </>
      )}

      <St.Separator />

      <St.TicketDetailsUnitLinks
        unitId={unit.id}
        omitSections={['createATicket']}
      />
      <St.TicketDetailCreateTicketDrawer unit={unit} />
    </Container>
  )
}
