import { produce } from 'immer'

import {
  findAllRelationshipIds,
  transformNormalizedToTyped,
} from 'packages/utils/store'

import {
  InspectionFlag,
  InspectionFlagAttributeNames,
  InspectionFlagResponse,
  NormalizedInspectionFlagApiResponse,
  RawInspectionFlag,
} from './inspectionFlags.types'

export const emptyNormalizedInspectionFlagData: InspectionFlagResponse =
  Object.freeze({
    inspectionFlag: {},
    task: {},
  })

export const emptyInspectionFlagResponse: NormalizedInspectionFlagApiResponse =
  Object.freeze({
    normalized: emptyNormalizedInspectionFlagData,
    raw: { data: [] },
  })

export const hydrateRawInspectionFlag = (
  raw: RawInspectionFlag,
): InspectionFlag => {
  return produce(
    transformNormalizedToTyped<InspectionFlag>(
      raw,
      InspectionFlagAttributeNames,
    ),
    draft => {
      draft.taskId = raw.relationships?.task?.data?.id
      draft.ticketIds = findAllRelationshipIds(raw, 'tickets')
    },
  )
}
