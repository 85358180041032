import get from 'lodash/get' // eslint-disable-line

import { storage } from 'packages/utils/misc'

import { User } from 'app/fieldapp/store/users'

/**
 * Attempts to read user data stored in localStorage.
 * In the case of regular users, this will always be undefined.
 * In the case super-users, this will be populated when/if said super-user
 *    searches/selects another user.
 */
export const getCachedUser = (): User | undefined => {
  const cachedUser = localStorage.getItem('user')
  if (cachedUser) {
    const parsedUser = JSON.parse(cachedUser)
    if (get(parsedUser, 'id')) {
      return parsedUser
    }
  }

  return undefined
}

export const getLastFetchTimestamp = (entity: string): number => {
  const key = `lastFetch-${entity}`

  // assuming the user is currently online, update the value of `lastFetch` to reflect the current time
  // i.e. we DO NOT want to update this value if the user is offline, because that
  // would indicate that our "API response" was actually served by the ServiceWorker/cache
  if (navigator.onLine) {
    // eslint-disable-next-line no-restricted-syntax
    const now = Date.now()

    storage.setItem(key, `${now}`)
    return now
  }

  const lastFetch = localStorage.getItem(key)
  return lastFetch ? Number.parseInt(lastFetch) : 0
}
