import React from 'react'
import { useSelector } from 'react-redux'

import { useManageConnectedDrawerState } from 'packages/common/src/modals/Drawer/hooks/useManageConnectedDrawerState'

import { getCoverageById } from 'app/fieldapp/store/coverage/selectors/getCoverageById'
import { ApplicationState } from 'app/fieldapp/store/store'
import { setOnCallTaskDrawer } from 'app/fieldapp/store/ui/actions'
import { getOnCallTaskDrawerState } from 'app/fieldapp/store/ui/selectors'

import { OnCallTaskDrawer } from './OnCallTaskDrawer'

export const OnCallTaskDrawerContainer: React.FC = () => {
  const coverageId = useSelector(getOnCallTaskDrawerState).coverageId

  const coverage = useSelector((state: ApplicationState) =>
    getCoverageById(state, coverageId),
  )

  const onCallTaskDrawerManager = useManageConnectedDrawerState({
    dispatchableToggleDrawerAction: setOnCallTaskDrawer,
    selector: getOnCallTaskDrawerState,
  })

  return (
    <OnCallTaskDrawer
      coverage={coverage}
      drawerManager={onCallTaskDrawerManager}
    />
  )
}
