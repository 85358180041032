import styled from '@emotion/styled'

import { cleanIsDeepClean } from 'packages/grimoire'
import { centerWithFlex, colors } from 'packages/styles/jss'

import { Clean } from 'app/fieldapp/store/cleans'

import { TaskCardHeader } from '../TaskSharedStyles/TaskCard.styles'

export { OwnerBadge } from '../TaskSharedStyles/TaskCard.styles'
export { TaskIcon } from '../TaskSharedStyles'

export const BadgeContainer = styled.div`
  ${centerWithFlex};
  background-color: rgba(255, 255, 255, 0.3);
  border-top-right-radius: 4px;
  color: ${colors.midnight70};
  height: 100%;
  justify-content: space-between;
  div:has(div) {
    padding: 0 8px;
  }
  text-transform: none;
`

export const BaseCleanHeader = styled(TaskCardHeader)<{ clean: Clean }>`
  background-color: ${({ clean }) =>
    cleanIsDeepClean(clean) ? colors.sand : colors.gulf20};
  padding-right: 0;
`
