import styled from '@emotion/styled'
import React from 'react'

import { Alert } from 'packages/common'
import { useI18n } from 'packages/i18n'

import { Slugs } from 'app/fieldapp/i18n'

import { DisabledUiType } from '../../context/InspectionChecklist.context.helpers'

const St = {
  Alert: styled(Alert)`
    border: none;
    margin-bottom: 0;
  `,
}

const useTranslations = (disabledState: DisabledUiType) => {
  const { t } = useI18n()

  let message = ''

  if (disabledState === 'cleanIsComplete') {
    message = t(Slugs.inspectionHasCompleteClean)
  }

  if (disabledState === 'noActiveTimer') {
    message = t(Slugs.inspectionHasNoActiveTimer)
  }

  return {
    message,
  }
}

export type InspectionDisabledWarningProps = {
  disabledState: DisabledUiType
}

export const InspectionDisabledWarning: React.FC<InspectionDisabledWarningProps> =
  React.memo(({ disabledState }) => {
    const { message } = useTranslations(disabledState)

    const warningStates: DisabledUiType[] = ['noActiveTimer', 'cleanIsComplete']

    return warningStates.includes(disabledState) ? (
      <St.Alert alertType={'warning'}>{message}</St.Alert>
    ) : null
  })
