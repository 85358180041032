import styled from '@emotion/styled'
import React from 'react'

import { Alert, ExternalLink } from 'packages/common'
import { useI18n } from 'packages/i18n'
import { IconName } from 'packages/iconic'
import { logInfo } from 'packages/wiretap/logging'

import {
  InfoRowLabel,
  Details,
  DetailIcon,
  SectionSubTitle,
  UnitInfoContainer,
} from 'app/fieldapp/components/schedule/components/TaskSharedStyles'
import { Slugs } from 'app/fieldapp/i18n'
import { useAppSelector } from 'app/fieldapp/store/hooks'
import { getIncludedLockboxByUnitId } from 'app/fieldapp/store/lockboxes/selectors'
import { getIncludedSmartLockByVisitId } from 'app/fieldapp/store/smartlocks/selectors'
import { ApplicationState } from 'app/fieldapp/store/store'
import { getVisitDrawerState } from 'app/fieldapp/store/ui/selectors'
import { getUnitById } from 'app/fieldapp/store/units/selectors'
import { getVisitById } from 'app/fieldapp/store/visits/selectors'

const St = {
  DetailIcon,
  Details,
  InfoRowLabel,
  SectionSubTitle,
  SmartLock: styled.div`
    overflow-wrap: anywhere;
  `,
  UnitInfoContainer,
}

export enum VisitAccessCodesTestIds {
  container = 'VisitAccessCodes__container',
  lockbox = 'VisitAccessCodes__lockbox',
  smartLock = 'VisitAccessCodes__smartLock',
}

export const VisitAccessCodes: React.FC = React.memo(() => {
  const { t } = useI18n()
  const { visitId } = useAppSelector(getVisitDrawerState)
  const visit = useAppSelector((state: ApplicationState) =>
    getVisitById(state, visitId),
  )
  const unit = useAppSelector((state: ApplicationState) =>
    getUnitById(state, visit?.unitId),
  )
  const lockbox = useAppSelector((state: ApplicationState) =>
    getIncludedLockboxByUnitId(state, visit?.unitId || ''),
  )
  const smartLock = useAppSelector((state: ApplicationState) =>
    getIncludedSmartLockByVisitId(state, visitId || ''),
  )

  if (!visit || !unit) {
    logInfo('Missing visit or unit info when trying to get access codes', {
      unitId: unit?.id,
      visitId: visitId,
    })
  }

  const hasValidLockbox = lockbox && (lockbox.primaryLock || lockbox.backupLock)

  const isExternalCode = (smartLock?.accessCode || '').match(/http/)

  if (!smartLock && !lockbox) {
    return null
  }

  return (
    <St.UnitInfoContainer data-testid={VisitAccessCodesTestIds.container}>
      <St.DetailIcon icon={IconName.lock} size={16} />

      <St.Details>
        <St.SectionSubTitle>{t(Slugs.access)}</St.SectionSubTitle>

        {!unit && (
          <Alert alertType="warning">{t(Slugs.errorTryAgainLater)}</Alert>
        )}

        {!!unit?.gateCode && (
          <div>
            <St.InfoRowLabel>{t(Slugs.gateCode)}</St.InfoRowLabel>:{' '}
            {unit.gateCode}
          </div>
        )}

        {!!unit?.alarmCode && (
          <div>
            <St.InfoRowLabel>{t(Slugs.alarmCode)}</St.InfoRowLabel>:{' '}
            {unit.alarmCode}
          </div>
        )}

        {unit?.hasSmartLock && (
          <St.SmartLock data-testid={VisitAccessCodesTestIds.smartLock}>
            <St.InfoRowLabel>{`${t(Slugs.smartLock)}: `}</St.InfoRowLabel>
            {smartLock ? (
              <>
                {isExternalCode ? (
                  <ExternalLink
                    text={smartLock.accessCode}
                    url={smartLock.accessCode}
                  />
                ) : (
                  smartLock.accessCode || '-'
                )}
              </>
            ) : (
              <Alert alertType="warning">
                {t(Slugs.failedToRetrieveSmartLock)}
              </Alert>
            )}
          </St.SmartLock>
        )}

        {!!lockbox && (
          <div data-testid={VisitAccessCodesTestIds.lockbox}>
            {hasValidLockbox ? (
              <>
                <div>
                  <St.InfoRowLabel>{t(Slugs.lockbox)}</St.InfoRowLabel>:{' '}
                  {lockbox?.primaryLock || '-'}
                </div>
                <div>
                  <St.InfoRowLabel>{t(Slugs.backup)}</St.InfoRowLabel>:{' '}
                  {lockbox?.backupLock || '-'}
                </div>
                <div>
                  <St.InfoRowLabel>{t(Slugs.notes)}</St.InfoRowLabel>:{' '}
                  {lockbox?.notes || '-'}
                </div>
              </>
            ) : (
              <> - </>
            )}
          </div>
        )}
      </St.Details>
    </St.UnitInfoContainer>
  )
})
