import { RequestOptions } from 'packages/utils/store'

import {
  UnitsApiResponse,
  UnitsServiceResponse,
} from 'app/fieldapp/store/units'

import { api } from '../axiosInstance'
import { UnitSearchApiResponse, UnitSearchServiceResponse } from './units.types'
import { emptyUnitsResponse } from './units.utils'

const URL = '/units'

export const unitsService = {
  async fetchUnitById(
    id: string,
    params: RequestOptions = {},
  ): UnitsServiceResponse {
    const response: UnitsApiResponse = await api.get(`${URL}/${id}`, {
      params,
    })
    return response?.data || emptyUnitsResponse
  },

  async searchUnits(params: RequestOptions = {}): UnitSearchServiceResponse {
    const response: UnitSearchApiResponse = await api.get(`${URL}`, { params })
    return response?.data || emptyUnitsResponse
  },
}
