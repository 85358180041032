import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ModalConfig, useModalContext } from 'packages/common'
import { createDateObject } from 'packages/utils/dateHelpers'
import { useOnlineStatus } from 'packages/utils/hooks'

import { useUpdateVisit } from 'app/fieldapp/components/schedule/hooks'
import { getHasActiveCleanTimeForTask } from 'app/fieldapp/store/cleantimes/selectors'
import { AppDispatch, ApplicationState } from 'app/fieldapp/store/store'
import { getActiveTicketTimerByVisitId } from 'app/fieldapp/store/ticket-times/selectors'
import { setVisitDrawer } from 'app/fieldapp/store/ui/actions'
import { UnknownUnit } from 'app/fieldapp/store/units'
import { getUnitById } from 'app/fieldapp/store/units/selectors'
import { Visit } from 'app/fieldapp/store/visits'
import { getAllTicketsCompleteForVisit } from 'app/fieldapp/store/visits/selectors/getAllTicketsCompleteForVisit'

import { IncompleteTasksModal } from '../IncompleteTasksModal'
import { VisitCard } from './VisitCard'

export type VisitCardContainerProps = {
  visit: Visit
}

export const VisitCardContainer: React.FC<VisitCardContainerProps> = React.memo(
  ({ visit }) => {
    const dispatch: AppDispatch = useDispatch()
    const isLoadingAnyVisit = useSelector(
      (state: ApplicationState) => state.visits.loading,
    )

    const [updateVisitState, updateVisitFn] = useUpdateVisit()
    const updateVisit = React.useCallback(() => {
      const completedAt = visit.startedAt ? createDateObject() : undefined
      const startedAt = visit.startedAt ? undefined : createDateObject()

      updateVisitFn({
        completedAt,
        id: visit.id,
        startedAt,
      })
    }, [updateVisitFn, visit])

    const handleCardClick = React.useCallback(() => {
      dispatch(
        setVisitDrawer({
          isOpen: true,
          tab: 'unitInfo',
          visitId: visit.id,
        }),
      )
    }, [dispatch, visit.id])

    const unit = useSelector((state: ApplicationState) =>
      getUnitById(state, visit.unitId),
    )

    const hasActiveCleanTimer = useSelector((state: ApplicationState) =>
      getHasActiveCleanTimeForTask(state, visit.id),
    )

    const activeTicketTimer = useSelector((state: ApplicationState) =>
      getActiveTicketTimerByVisitId(state, visit.id),
    )

    const isOnline = useOnlineStatus().isOnline()

    const { showModal } = useModalContext()

    const visitTicketsComplete = useSelector((state: ApplicationState) =>
      getAllTicketsCompleteForVisit(state, visit.id),
    )
    const modalConfig: ModalConfig = {
      childRenderer: ({ beginClose }) => (
        <IncompleteTasksModal
          beginClose={beginClose}
          disabled={!visit.timersSubmitted}
          loading={updateVisitState.loading}
          incompleteTickets={!visitTicketsComplete}
          incompleteInspection={!visit.inspectionCompletedAt}
          updateVisit={updateVisit}
        />
      ),
    }

    const handleUpdateVisit = React.useCallback(
      modalConfig => {
        visit.startedAt ? showModal(modalConfig) : updateVisit()
      },
      [showModal, updateVisit, visit],
    )

    const canUpdateVisit =
      isOnline &&
      !updateVisitState.loading &&
      !isLoadingAnyVisit &&
      !hasActiveCleanTimer &&
      !activeTicketTimer

    return (
      <VisitCard
        canUpdateVisit={canUpdateVisit}
        onClick={handleCardClick}
        onUpdateVisit={() => handleUpdateVisit(modalConfig)}
        requestState={updateVisitState}
        unit={unit || UnknownUnit}
        visit={visit}
      />
    )
  },
)
