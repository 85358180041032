import styled from '@emotion/styled'
import React from 'react'

import { useI18n } from 'packages/i18n'
import { IconName, SvgIcon } from 'packages/iconic'
import { colors, text } from 'packages/styles'

import { Slugs } from 'app/fieldapp/i18n'

import { ScheduleDateDetail } from '../ScheduleDateDetail'
import { WeekCalendarDisplay } from '../WeekCalendarDisplay'

const St = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
  `,
  Controls: styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: space-between;
  `,
  HeaderRow: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px 0;
  `,
  HeadingText: styled.h2`
    ${text.headingMediumCaps}
    margin-bottom: 0;
  `,
  IconControls: styled(SvgIcon)<{ disabled: boolean }>`
    color: ${({ disabled }) => (disabled ? colors.dusk : colors.dusk40)};
    cursor: ${({ disabled }) => (disabled ? 'pointer' : 'not-allowed')};
  `,
}

export enum WeekCalendarTestIds {
  arrowLeft = 'WeekCalendar__arrowLeft',
  arrowRight = 'WeekCalendar__arrowRight',
  container = 'WeekCalendar__container',
}

export type WeekCalendarProps = {
  changeSelectedWeek: (direction: 'inc' | 'dec') => () => void
  dateRange: Date[]
  error: string | Error | undefined
  handleCardClick: (date: Date) => void
  isOnline: boolean
  loading: boolean
  selectedDate: Date
}

export const WeekCalendar: React.FC<WeekCalendarProps> = React.memo(
  ({
    changeSelectedWeek,
    dateRange,
    loading,
    error,
    handleCardClick,
    isOnline,
    selectedDate,
  }) => {
    const { t } = useI18n()

    return (
      <St.Container data-testid={WeekCalendarTestIds.container}>
        <St.HeaderRow>
          <St.HeadingText>{t(Slugs.myAvailability)}</St.HeadingText>
          <St.Controls>
            <St.IconControls
              dataTestId={WeekCalendarTestIds.arrowLeft}
              disabled={isOnline}
              icon={IconName.chevronLeft}
              onClick={changeSelectedWeek('dec')}
              size={24}
            />
            <St.IconControls
              dataTestId={WeekCalendarTestIds.arrowRight}
              disabled={isOnline}
              icon={IconName.chevronRight}
              onClick={changeSelectedWeek('inc')}
              size={24}
            />
          </St.Controls>
        </St.HeaderRow>
        <WeekCalendarDisplay
          dateRange={dateRange}
          error={error}
          loading={loading}
          onCardClick={handleCardClick}
        />
        <ScheduleDateDetail date={selectedDate} loading={loading} />
      </St.Container>
    )
  },
)
