import {
  TIMER_DETAIL_HEIGHT,
  OTHER_TIMERS_HEADER_HEIGHT,
  PAUSED_TIMER_HEADER_HEIGHT,
} from '../../timers.constants'

export type TimerComponentShowingState = {
  activeCleanTimer: boolean
  activeOtherTimer: boolean
  activeTicketTimer: boolean
  hasSubmitTimers: boolean
  pausedTimers: boolean
  showOtherTimers: boolean
}

export const getTotalPaddingHeight = (
  componentsShowing: TimerComponentShowingState,
): string => {
  const {
    activeCleanTimer,
    activeOtherTimer,
    activeTicketTimer,
    pausedTimers,
    showOtherTimers,
  } = componentsShowing

  const hasActiveTimer =
    activeCleanTimer || activeOtherTimer || activeTicketTimer

  let total = 0

  if (hasActiveTimer) {
    total += TIMER_DETAIL_HEIGHT
  }

  if (pausedTimers) {
    total += PAUSED_TIMER_HEADER_HEIGHT + TIMER_DETAIL_HEIGHT
  }

  if (showOtherTimers) {
    total += OTHER_TIMERS_HEADER_HEIGHT
  }

  return `${total}px`
}
