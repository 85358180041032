import { last, capitalize, lowerCase } from 'lodash/fp'
import React from 'react'
import {
  components,
  MultiValueProps,
  ValueContainerProps,
  OptionProps,
  ActionMeta,
} from 'react-select'

import { Select, Checkbox } from 'packages/common'
import { useI18n } from 'packages/i18n'
import { colors } from 'packages/styles'

import { Slugs } from 'app/fieldapp/i18n'

import { useCrossCoverageDrawerTranslations } from '../../../useCrossCoverageDrawerTranslations'

export type DaySelectOption = {
  longDay: string
  ordinal: number
  shortDay: string
  startTimeOfDay: string
}

const RepeatDaysValueContainer = (props: ValueContainerProps) => {
  const values = props.getValue()
  return (
    <components.ValueContainer {...props}>
      {values.length ? <span style={{ marginRight: 5 }}>Every</span> : ''}
      {props.children}
    </components.ValueContainer>
  )
}

const RepeatDaysMultiValueLabel = (props: MultiValueProps) => {
  const hasNextValue =
    props.selectProps.value.length > 1 &&
    (last(props.selectProps.value) as DaySelectOption)?.shortDay !==
      props.data.shortDay

  return (
    <components.MultiValueLabel {...props}>
      {capitalize(props.data.shortDay)}
      {hasNextValue ? ',' : ` at ${props.data.startTimeOfDay}`}
    </components.MultiValueLabel>
  )
}

const RepeatDaysOption = (props: OptionProps) => {
  const isChecked = props
    .getValue()
    .some((option: DaySelectOption) => option.shortDay === props.value.shortDay)

  return (
    <components.Option {...props}>
      <Checkbox
        label={capitalize(props.data.longDay)}
        value={props.data.shortDay}
        checked={isChecked}
      />
    </components.Option>
  )
}

const customComponents = {
  MultiValueLabel: RepeatDaysMultiValueLabel,
  Option: RepeatDaysOption,
  ValueContainer: RepeatDaysValueContainer,
}

const styles = {
  multiValue: () => ({
    backgroundColor: 'transparent',
  }),
  multiValueLabel: () => ({
    backgroundColor: 'transparent',
    color: colors.dusk,
    marginRight: '5px',
  }),
  multiValueRemove: () => ({
    display: 'none',
  }),
  option: () => ({
    fontWeight: '700',
    padding: '6px 11px',
  }),
}

const useOptions = () => {
  const strings = useCrossCoverageDrawerTranslations()

  return [
    {
      longDay: strings.sunday,
      ordinal: 0,
      shortDay: strings.sun,
    },
    {
      longDay: strings.monday,
      ordinal: 1,
      shortDay: strings.mon,
    },
    {
      longDay: strings.tuesday,
      ordinal: 2,
      shortDay: strings.tue,
    },
    {
      longDay: strings.wednesday,
      ordinal: 3,
      shortDay: strings.wed,
    },
    {
      longDay: strings.thursday,
      ordinal: 4,
      shortDay: strings.thur,
    },
    {
      longDay: strings.friday,
      ordinal: 5,
      shortDay: strings.fri,
    },
    {
      longDay: strings.saturday,
      ordinal: 6,
      shortDay: strings.sat,
    },
  ]
}

export enum DaySelectTestIds {
  container = 'DaySelect__container',
}

export type DaySelectProps = {
  disabled?: boolean
  handleChange: (value: DaySelectOption[], action: ActionMeta) => void
  value: DaySelectOption[]
}

export const DaySelect: React.FC<DaySelectProps> = React.memo(
  ({ disabled, handleChange, value }) => {
    const options = useOptions()
    const { t } = useI18n()

    return (
      <div data-testid={DaySelectTestIds.container}>
        <Select
          clearable={false}
          closeMenuOnSelect={false}
          components={customComponents}
          disabled={disabled}
          getOptionLabel={daySelectOption =>
            capitalize(daySelectOption.longDay)
          }
          isMulti={true}
          onChange={handleChange}
          options={options.map(option => ({
            ...option,
            shortDay: lowerCase(option.shortDay),
          }))}
          placeholder={t(Slugs.chooseDays)}
          searchable={false}
          selectedValue={value}
          style={styles}
        />
      </div>
    )
  },
)
