import React from 'react'

import { SubMenuType, OtherTimerType } from 'packages/grimoire'
import { useI18n } from 'packages/i18n'

import { driveMenu, mainMenu, otherMenu } from '../OtherTimers.configs'
import { translateMenu } from '../OtherTimers.helpers'
import {
  OtherTimerTypeMenuWithSubMenu,
  TimerMenuConfig,
  TimerMenuType,
} from '../OtherTimers.types'

type UseOtherTimerMenuConfig = {
  getMenuById: (id: TimerMenuType) => TimerMenuConfig
  getMenuByTaskType: (type: SubMenuType) => OtherTimerTypeMenuWithSubMenu
  menus: TimerMenuConfig[]
}

export const useOtherTimerMenuConfig = (): UseOtherTimerMenuConfig => {
  const { ut } = useI18n()

  const translateLabels = translateMenu(ut)

  const menus = React.useMemo(
    () => [driveMenu, otherMenu, mainMenu].map(translateLabels),
    [translateLabels],
  )

  const getMenuById = React.useCallback(
    (targetId: TimerMenuType) =>
      menus.find(({ id }) => id === targetId) as TimerMenuConfig,
    [menus],
  )

  const getMenuByTaskType = React.useCallback(
    (otherTimerType: SubMenuType): OtherTimerTypeMenuWithSubMenu => {
      const id: TimerMenuType =
        otherTimerType === OtherTimerType.DRIVE ? 'drive' : 'other'
      return getMenuById(id) as OtherTimerTypeMenuWithSubMenu
    },
    [getMenuById],
  )

  return { getMenuById, getMenuByTaskType, menus }
}
