import { DateFormat, format } from 'packages/utils/dateHelpers'

import { TicketSeverity } from 'app/fieldapp/store/tickets'

export const formatTicketDate = (date: string): string =>
  format(date, DateFormat.MonthDateAndYear)

export const getTicketColorClassBySeverity = (
  severity: TicketSeverity,
): string => {
  switch (severity) {
    case TicketSeverity.PLANNED:
      return 'midnight'
    case TicketSeverity.URGENT:
    case TicketSeverity.TODAY:
      return 'urgent'
    case TicketSeverity.PRIOR_TO_NEXT_GUEST:
      return 'aware'
    default:
      return ''
  }
}
