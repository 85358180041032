import { createSelector } from 'reselect'

import { ApplicationState } from 'app/fieldapp/store/store'

import { getTasksByAssignmentIds } from '../../tasks/selectors'
import { getActiveCleanTime } from './getActiveCleanTime'

const getFullState = (state: ApplicationState): ApplicationState => state
const getTaskId = (_, taskId: string) => taskId

export const getHasActiveCleanTimeForTask = createSelector(
  getFullState,
  getTaskId,
  (fullState, taskId): boolean => {
    const activeCleanTime = getActiveCleanTime(fullState)
    if (!activeCleanTime) return false

    // CleanTimes should always technically have assignments attached to them, but this is one
    // of those weird edge cases that can happen with re-assigned cleans that already have timers running
    const { assignment } = activeCleanTime
    if (!assignment?.id) return false

    const cleanForActiveTimer = getTasksByAssignmentIds(fullState, [
      assignment.id,
    ])[assignment.id]

    return !!cleanForActiveTimer && cleanForActiveTimer.id === taskId
  },
)
