import { createSelector } from 'reselect'

import { ApplicationState } from '../../store'
import { Coverage } from '../coverage.types'
import { hydrateRawCoverage } from '../coverage.utils'

const getCoverageData = (state: ApplicationState) => state.coverage.data
const getCoverageId = (_, id: string) => id

export const getCoverageById = createSelector(
  getCoverageData,
  getCoverageId,
  (coverageData, coverageId): Coverage => {
    const rawCoverage = coverageData?.[coverageId]

    return rawCoverage && hydrateRawCoverage(rawCoverage)
  },
)
