import { transformNormalizedToTyped } from 'packages/utils/store'

import {
  NormalizedUsersApiResponse,
  RawUser,
  User,
  UserAttributeNames,
} from './users.types'

/**
 * Empty data in the shape of the Users service's `normalized` payload.
 * Use this as a fallback when there are issues with the API data being undefined or malformed.
 */
export const emptyNormalizedUsersData = Object.freeze({
  housekeeper: {},
  user: {},
})

/**
 * Empty data to use as a "safety net" any time an API response is undefined for any reason
 */
export const emptyUsersResponse: NormalizedUsersApiResponse = Object.freeze({
  normalized: emptyNormalizedUsersData,
  raw: { data: [] },
})

export const transformRawUser = (user: RawUser): User =>
  transformNormalizedToTyped<User>(user, UserAttributeNames)

export const hydrateRawUser = (rawUser: RawUser): User => {
  const housekeeperId = rawUser?.relationships?.housekeeper?.data?.id || ''
  return {
    ...transformRawUser(rawUser),
    housekeeperId,
  }
}
