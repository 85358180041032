import { createAsyncAction } from 'typesafe-actions'

import { OfflineError } from 'packages/offline'
import { apiDateStringWithSeconds } from 'packages/utils/dateHelpers'
import {
  OfflineRequestConfig,
  RequestOptions,
  setRequestRelationship,
} from 'packages/utils/store'

import {
  NormalizedOtherTimersApiResponse,
  OtherTimersPostData,
  OtherTimerPostApiAttributes,
  OtherTimersActionTypes,
} from 'app/fieldapp/store/otherTimers'

import { otherTimersService } from '../otherTimers.service'
import { fetchOtherTimerById } from './fetchOtherTimerById'

export const createOtherTimerAction = createAsyncAction(
  OtherTimersActionTypes.CREATE_OTHER_TIMER,
  OtherTimersActionTypes.CREATE_OTHER_TIMER_SUCCESS,
  OtherTimersActionTypes.CREATE_OTHER_TIMER_FAILURE,
)<
  OfflineRequestConfig<NormalizedOtherTimersApiResponse, OtherTimersPostData>,
  NormalizedOtherTimersApiResponse,
  Error
>()

export const buildRequestData = (
  postData: OtherTimersPostData,
): RequestOptions => {
  const { startedAt, subTimerType, timerType, userId } = postData
  const attributes: OtherTimerPostApiAttributes = {
    started_at: apiDateStringWithSeconds(startedAt),
    // TODO: rename to other timer when API ready
    sub_task_type: subTimerType,
    task_type: timerType,
  }

  return {
    data: {
      attributes,
      relationships: {
        ...setRequestRelationship('user', userId),
      },
      type: 'task_time',
    },
  }
}

export const createOtherTimer =
  (postData: OtherTimersPostData) => async dispatch => {
    try {
      const requestData = buildRequestData(postData)
      const request = otherTimersService.createOtherTimer.bind(
        null,
        requestData,
      )
      const result = await dispatch(
        createOtherTimerAction.request({ data: postData, request }),
      )
      dispatch(createOtherTimerAction.success(result))

      // TODO: rename to other timer when API ready
      const newOtherTimeId = Object.keys(result.normalized.taskTime)[0]
      await dispatch(fetchOtherTimerById(newOtherTimeId))
    } catch (error) {
      if (error === OfflineError) return
      dispatch(createOtherTimerAction.failure(error))
      throw error
    }
  }
