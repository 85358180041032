import { createSelector } from 'reselect'

import { getUsers } from '../../users/selectors'
import { getCurrentCoverage } from './getCurrentCoverage'

export const getCurrentCoverageUser = createSelector(
  getCurrentCoverage,
  getUsers,
  (currentCoverage, users) =>
    currentCoverage && users.find(user => user.id === currentCoverage.userId),
)
