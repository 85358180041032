import { createAsyncAction } from 'typesafe-actions'

import {
  RequestConfig,
  RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import { NormalizedTicketCommentsApiResponse } from '../../ticketComments'
import { UserApiFields } from '../../users'
import { ticketCommentsService } from '../ticketComments.service'
import { TicketCommentsActionTypes } from '../ticketComments.types'
import { TicketCommentApiFields } from '../ticketComments.types'

export const fetchTicketCommentByIdAction = createAsyncAction(
  TicketCommentsActionTypes.FETCH_TICKET_COMMENT,
  TicketCommentsActionTypes.FETCH_TICKET_COMMENT_SUCCESS,
  TicketCommentsActionTypes.FETCH_TICKET_COMMENT_FAILURE,
)<
  RequestConfig<NormalizedTicketCommentsApiResponse>,
  NormalizedTicketCommentsApiResponse,
  Error
>()

export const getParams = (): RequestOptions => ({
  fields: {
    ticket: [],
    ticket_comment: TicketCommentApiFields,
    user: UserApiFields,
  },
  include: ['ticket', 'user'],
})

export const fetchTicketCommentById =
  (ticketCommentId: string) => async dispatch => {
    try {
      const params = getParams()
      const request = ticketCommentsService.fetchTicketCommentById.bind(
        null,
        ticketCommentId,
        params,
      )
      const result = await dispatch(
        fetchTicketCommentByIdAction.request({ request }),
      )
      dispatch(fetchTicketCommentByIdAction.success(result))
      return result.normalized
    } catch (error) {
      dispatch(fetchTicketCommentByIdAction.failure(error))
      throw error
    }
  }
