import upperFirst from 'lodash/upperFirst'
import React from 'react'

import { ClipboardText } from 'packages/common'
import { IconName, SvgIcon } from 'packages/iconic'

import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { Amenities } from 'app/fieldapp/store/units'

import styles from '../../CleanDetail.module.scss'

export type WifiInfoProps = {
  amenities: Amenities
}

export const WifiInfo: React.FC<WifiInfoProps> = React.memo(({ amenities }) => {
  const { t } = useI18n()
  return amenities.wifi ? (
    <div className={styles.cleanUnitInfoContainer}>
      <SvgIcon
        className={styles.cleanDetailIcon}
        icon={IconName.wifi}
        size={14}
      />
      <div className={styles.details}>
        <div>
          <strong>{upperFirst(t(Slugs.wifi))}</strong>
        </div>

        <div>
          <span className={styles.amenityInfoSection}>
            {`${upperFirst(t(Slugs.network))}: `}
          </span>
          <span className={styles.wifiNetworkText}>
            {amenities.wifiNetwork}
          </span>
        </div>

        <div>
          <span className={styles.amenityInfoSection}>
            {`${upperFirst(t(Slugs.password))}:`}
          </span>

          {amenities.wifiPassword && (
            <ClipboardText>{amenities.wifiPassword}</ClipboardText>
          )}
        </div>
      </div>
    </div>
  ) : null
})
