import { xor } from 'lodash/fp'
import { createAsyncAction } from 'typesafe-actions'

import { apiFilterExcludeCleans } from 'packages/grimoire'
import {
  RequestConfig,
  RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import { getDateRange } from '../../cleans/actions/fetchCleans'
import { HousekeeperApiFields } from '../../housekeepers'
import { LockboxApiFields } from '../../lockboxes'
import { ReservationApiFields } from '../../reservations'
import { SmartLockApiFields } from '../../smartlocks'
import { NormalizedTasksApiResponse } from '../../tasks'
import { tasksService } from '../../tasks/tasks.service'
import { TicketApiFields } from '../../tickets'
import { UnitApiFields } from '../../units'
import { UserApiFields } from '../../users'
import { VisitApiFields, VisitsActionTypes } from '../visits.types'

export const fetchVisitsAction = createAsyncAction(
  VisitsActionTypes.FETCH_VISITS,
  VisitsActionTypes.FETCH_VISITS_SUCCESS,
  VisitsActionTypes.FETCH_VISITS_FAILURE,
)<
  RequestConfig<NormalizedTasksApiResponse>,
  NormalizedTasksApiResponse,
  Error
>()

// omit inspectionChecklist from the "all visits" request for performance reasons
const VisitFields = xor(['inspection_checklist'], VisitApiFields)

export const getParams = (
  userId: string,
  dateRange: string | { range: string[] },
): RequestOptions => {
  return {
    fields: {
      assignment: [],
      housekeeper: HousekeeperApiFields,
      lock_box: LockboxApiFields,
      reservation: ReservationApiFields,
      smart_lock: SmartLockApiFields,
      task: VisitFields,
      ticket: TicketApiFields,
      unit: UnitApiFields,
      user: UserApiFields,
    },
    filter: {
      'active_assignments.housekeeper.user': userId,
      effective_date: dateRange,
      is_active: true,
      ...apiFilterExcludeCleans,
    },
    include: [
      'active_assignments',
      'active_assignments.housekeeper',
      'active_assignments.housekeeper.user',
      'assigned_housekeepers.user',
      'reservation',
      'smart_locks',
      'tickets',
      'unit',
      'unit.current_reservation',
      'unit.lock_box',
      'unit.next_reservation',
      'unit.previous_reservation',
    ],
    page: { size: 100 },
    sort: ['effective_date'],
  }
}

export const fetchVisits =
  (userId: string, startDate: string, endDate?: string) => async dispatch => {
    try {
      const dateRange = getDateRange(startDate, endDate)
      const params = getParams(userId, dateRange)
      const request = tasksService.fetchTasks.bind(null, params)
      const result: NormalizedTasksApiResponse = await dispatch(
        fetchVisitsAction.request({ request }),
      )
      dispatch(fetchVisitsAction.success(result))
      return result.normalized
    } catch (error) {
      dispatch(fetchVisitsAction.failure(error))
      throw error
    }
  }
