import { RequestOptions } from 'packages/utils/store/jsonapi.types'

import {
  TicketCommentsServiceResponse,
  TicketCommentsApiResponse,
} from 'app/fieldapp/store/ticketComments'

import { api } from '../axiosInstance'
import { emptyTicketCommentsResponse } from './ticketComments.utils'

const URL = '/ticket_comments'

export const ticketCommentsService = {
  async createTicketComment(
    data: RequestOptions,
  ): TicketCommentsServiceResponse {
    const response: TicketCommentsApiResponse = await api.post(URL, data)

    return response?.data || emptyTicketCommentsResponse
  },

  async fetchTicketCommentById(
    id: string,
    params: RequestOptions = {},
  ): TicketCommentsServiceResponse {
    const response: TicketCommentsApiResponse = await api.get(`${URL}/${id}`, {
      params,
    })
    return response?.data || emptyTicketCommentsResponse
  },
}
