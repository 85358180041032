import * as React from 'react'

import { TimerType } from 'packages/grimoire/src/utils/timers.types'
import { useI18n } from 'packages/i18n'
import { formatSecondsAsDigitalClock } from 'packages/utils/mathHelpers'

import { Slugs } from 'app/fieldapp/i18n'

import { StopTimerButton } from '../../Buttons/StopTimerButton'
import {
  DetailWrapper,
  ActiveTimeContainer,
  TimeDisplay,
  TimerName,
} from '../ActiveTimers.styles'
import { useActiveTicketTimer } from './useActiveTicketTimer'

export enum ActiveTicketTimeTestIds {
  container = 'ActiveTicketTimer__container',
  pauseBtn = 'ActiveTicketTimer__pauseBtn',
}

export const ActiveTicketTimer: React.FC = React.memo(() => {
  const { isLoading, stopActiveTimer, time, unit } = useActiveTicketTimer()
  const { ut } = useI18n()

  const timeString = formatSecondsAsDigitalClock(time)

  const unitString = unit
    ? `${unit.unitCode} - ${unit.name}`
    : ut(Slugs.unknown)

  return (
    <ActiveTimeContainer
      data-testid={ActiveTicketTimeTestIds.container}
      timerColorType={TimerType.TICKET}
    >
      <DetailWrapper>
        <TimerName>{unitString}</TimerName>
      </DetailWrapper>

      <TimeDisplay>{timeString}</TimeDisplay>

      <StopTimerButton
        dataTestId={ActiveTicketTimeTestIds.pauseBtn}
        isLoading={isLoading}
        onClick={stopActiveTimer}
      />
    </ActiveTimeContainer>
  )
})
