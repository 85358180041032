import { produce } from 'immer'
import { merge } from 'lodash/fp'
import { ActionType, getType } from 'typesafe-actions'

import { setAuthUser } from 'packages/utils/misc'
import { setLoggingUser } from 'packages/wiretap/logging'

import {
  fetchCleanByIdAction,
  fetchCleansAction,
} from 'app/fieldapp/store/cleans/actions'

import { fetchCoverageByDateRange } from '../coverage/actions'
import { fetchCrossCoverageById } from '../crossCoverage/actions'
import { fetchHousekeeperManagersByZoneAction } from '../housekeepers/actions'
import { fetchTicketByIdAction, fetchTicketsAction } from '../tickets/actions'
import { fetchUnitByIdAction } from '../units/actions'
import { fetchVisitByIdAction, fetchVisitsAction } from '../visits/actions'
import {
  fetchCurrentUserAction,
  fetchCurrentCoveragePartnerAction,
  fetchCoveragePartnerOptionsAction,
  searchUsersAction,
  setActiveUserAction,
} from './actions'
import { RawUser, UsersState } from './users.types'
import { emptyNormalizedUsersData } from './users.utils'

const initialState: UsersState = {
  activeUserHkId: '',
  activeUserId: '',
  authUserId: '',
  coveragePartnerOptions: {},
  currentCoveragePartner: undefined,
  data: {},
  searchResults: {},
}

const actions = {
  fetchCleanByIdAction,
  fetchCleansAction,
  fetchCoverageByDateRange,
  fetchCoveragePartnerOptionsAction,
  fetchCrossCoverageById,
  fetchCurrentCoveragePartnerAction,
  fetchCurrentUserAction,
  fetchHousekeeperManagersByZoneAction,
  fetchTicketByIdAction,
  fetchTicketsAction,
  fetchUnitByIdAction,
  fetchVisitByIdAction,
  fetchVisitsAction,
  searchUsersAction,
  setActiveUserAction,
}

type UsersActionsTypes = ActionType<typeof actions>

export const usersReducer = (
  state = initialState,
  action: UsersActionsTypes,
): UsersState =>
  produce(state, (draft: UsersState) => {
    switch (action.type) {
      case getType(fetchCleanByIdAction.success):
      case fetchCrossCoverageById.fulfilled.toString():
      case getType(fetchCleansAction.success):
      case getType(fetchHousekeeperManagersByZoneAction.success):
      case getType(fetchTicketByIdAction.success):
      case getType(fetchTicketsAction.success):
      case getType(fetchVisitByIdAction.success):
      case getType(fetchVisitsAction.success):
      case fetchCoverageByDateRange.fulfilled.toString():
      case getType(fetchUnitByIdAction.success): {
        const normalized = action.payload.normalized || emptyNormalizedUsersData

        if (normalized.user) {
          Object.values(normalized.user).forEach(incomingUser => {
            const existingUser = state.data[incomingUser.id] || {}
            const mergedUser = merge(existingUser, incomingUser)
            draft.data[incomingUser.id] = mergedUser
          })
        }

        return
      }

      case getType(fetchCurrentCoveragePartnerAction.success): {
        const normalized =
          action.payload?.[0].normalized || emptyNormalizedUsersData
        const existingUserId = action.payload?.[1]
        draft.currentCoveragePartner = Object.values(normalized.user).filter(
          ({ id }) => id !== existingUserId,
        )[0] as RawUser

        return
      }

      case getType(fetchCurrentUserAction.success): {
        const normalized =
          action.payload?.normalized || emptyNormalizedUsersData

        const authUser = Object.values(normalized.user)[0] as RawUser
        draft.activeUserId = authUser.id
        draft.authUserId = authUser.id
        draft.data = normalized.user
        draft.activeUserHkId =
          Object.keys(normalized.housekeeper || [])[0] || ''

        setAuthUser(authUser)
        setLoggingUser(draft.authUserId, draft.activeUserId)

        // add the authenticated user ID to Google Tag Manager's variables
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const dataLayer = (window as any).dataLayer
        if (dataLayer) {
          dataLayer.push({ userId: authUser.id })
        }

        return
      }

      case getType(searchUsersAction.success): {
        const normalized =
          action?.payload?.normalized || emptyNormalizedUsersData
        draft.searchResults = normalized.user || {}
        return
      }

      case getType(fetchCoveragePartnerOptionsAction.success): {
        const normalized =
          action?.payload?.normalized || emptyNormalizedUsersData
        draft.coveragePartnerOptions = normalized.user || {}
        return
      }

      case getType(setActiveUserAction): {
        const user = action.payload
        draft.activeUserId = user.id
        draft.activeUserHkId = user.housekeeperId || 'Unknown'
        setLoggingUser(state.authUserId, draft.activeUserId)

        // if this is a user we have selected from a search, we need to copy
        // said user over to "data" so it does not get cleared on the next search
        const userFromSearch = state.searchResults[user.id]
        if (!state.data[user.id] && userFromSearch) {
          draft.data[user.id] = userFromSearch
        }

        return
      }
    }
  })
