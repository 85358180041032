import React from 'react'
import { useDispatch } from 'react-redux'

import { TimerType } from 'packages/grimoire/src/utils/timers.types'
import { track, Events } from 'packages/wiretap'

import { useTimerErrors } from 'app/fieldapp/components/timers/hooks'
import {
  clearTimerSubmission,
  useTimers as useTimersContext,
} from 'app/fieldapp/components/timers/state'
import { silentDeleteCleanTime } from 'app/fieldapp/store/cleantimes/actions'

type UseHandleMissingClean = {
  handleMissingClean: (cleanTimeId: string, assignmentId: string) => void
}

export const useHandleMissingClean = (): UseHandleMissingClean => {
  const reduxDispatch = useDispatch()

  const { dispatch: timersDispatch } = useTimersContext()
  const { handleUnassignedEntity } = useTimerErrors()

  const handleMissingClean = React.useCallback(
    (cleanTimeId: string, assignmentId: string) => {
      /* eslint-disable @typescript-eslint/naming-convention */
      track(Events.deleteReassignedCleanTime, {
        assignment_id: assignmentId,
        cleantime_id: cleanTimeId,
      })
      /* eslint-enable @typescript-eslint/naming-convention */

      // handle local/UI updates before submitting any API requests
      timersDispatch(clearTimerSubmission())
      handleUnassignedEntity(TimerType.CLEAN)

      // send a request to delete the associated timer
      // this is a background request, so we won't show loading state or handle errors
      try {
        reduxDispatch(silentDeleteCleanTime(cleanTimeId))
      } catch (err) {
        // no error handling necessary here
      }
    },
    [handleUnassignedEntity, reduxDispatch, timersDispatch],
  )

  return { handleMissingClean }
}
