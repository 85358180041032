import styled from '@emotion/styled'
import React from 'react'

import { SanitizedHTML } from 'packages/common/src/SanitizedHTML'
import { fullName } from 'packages/grimoire'
import { IconName, SvgIcon } from 'packages/iconic'
import { colors, text } from 'packages/styles'
import { DateFormat, formatLocalized } from 'packages/utils/dateHelpers'

import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { TicketComment } from 'app/fieldapp/store/ticketComments'
import { User } from 'app/fieldapp/store/users'

const St = {
  Comment: styled.div`
    &:not(:first-of-type) {
      margin-top: 24px;
    }
  `,
  CommentHeader: styled.div`
    align-items: center;
    display: flex;
    grid-gap: 6px;
    justify-content: flex-start;
  `,
  Date: styled.span`
    ${text.bodyRegularTiny};
    color: ${colors.dusk60};
  `,
  Icon: styled(SvgIcon)`
    color: ${colors.dusk};
  `,
  Note: styled(SanitizedHTML)`
    ${text.bodyRegularDefault};
    margin-top: 2px;
  `,
  User: styled.span`
    ${text.bodyBoldSmall};
    align-items: center;
    display: flex;
    gap: 6px;
  `,
}

const useTranslations = () => {
  const { ut } = useI18n()

  return {
    owner: ut(Slugs.owner),
    unknown: ut(Slugs.unknown),
  }
}

export type TicketCommentDisplayProps = {
  comment: TicketComment
  user: User | undefined
}

export const TicketCommentDisplay: React.FC<TicketCommentDisplayProps> =
  React.memo(({ comment, user }) => {
    const strings = useTranslations()

    const userName = user ? fullName(user) : strings.unknown
    const createdDate = formatLocalized(
      comment.monolithTimestamp,
      DateFormat.SlashesWithTime,
    )

    const commentIcon = comment.external ? IconName.users : IconName.lock

    return (
      <St.Comment>
        <St.CommentHeader>
          <St.User>
            {user?.isHomeowner ? (
              <>
                <St.Icon icon={IconName.crown} size={24} /> {userName} (
                {strings.owner})
              </>
            ) : (
              userName
            )}
          </St.User>
          <St.Date>{createdDate}</St.Date>
          {<St.Icon icon={commentIcon} size={14} />}
        </St.CommentHeader>
        <St.Note html={comment.note} />
      </St.Comment>
    )
  })
