import { createAsyncThunk } from '@reduxjs/toolkit'

import { createDateObject } from 'packages/utils/dateHelpers'
import { Events, track } from 'packages/wiretap'

import { isViewingSharedClean } from 'app/fieldapp/components/schedule/schedule.utils'

import { getDelegateUserId } from '../../auth/selectors'
import { ApplicationState } from '../../store'
import { getActiveUser } from '../../users/selectors'
import { updateVisit } from './updateVisit'

type completeVisitInspectionParams = {
  visitId: string
}

export const completeVisitInspection = createAsyncThunk(
  'visits/completeVisitInspection',
  async (
    { visitId }: completeVisitInspectionParams,
    { dispatch, getState },
  ) => {
    const inspectionCompletedAt = createDateObject()

    const userId = getActiveUser(getState() as ApplicationState)?.id
    const delegateUserId = getDelegateUserId(getState() as ApplicationState)

    /* eslint-disable @typescript-eslint/naming-convention */
    const trackingSuccessPayload = {
      inspection_completed_at: inspectionCompletedAt,
      is_delegate: isViewingSharedClean(),
      user_id: userId || delegateUserId || 'unknown',
      visit_id: visitId,
    }
    /* eslint-enable @typescript-eslint/naming-convention */

    try {
      await dispatch(
        updateVisit({
          id: visitId || '',
          inspectionCompletedAt,
        }),
      )

      track(Events.fieldAppInspectionMarkComplete, trackingSuccessPayload)
    } catch (err) {
      track(Events.fieldAppInspectionMarkCompleteError, {
        ...trackingSuccessPayload,
        error: err?.message || 'Unknown Error',
      })
    }
  },
)
