import classNames from 'classnames'
import React, { useMemo } from 'react'

import { useI18n } from 'packages/i18n'

import { Slugs } from 'app/fieldapp/i18n'
import { Clean } from 'app/fieldapp/store/cleans'
import { Visit } from 'app/fieldapp/store/visits'

import { CleanCard } from '../CleanCard'
import styles from '../CleansTimeline/CleansTimeline.module.scss'
import { VisitCardContainer } from '../VisitCard/VisitCard.container'

export type TimelineDayProps = {
  cleans: Clean[]
  isStartOfSection: boolean
  isToday: boolean
  onCleanClick: (id: string) => void
  visits: Visit[]
}

// TPD-6570 Show highest scoring visits at the top of the schedule
// Show visits without scores (manually made) at the bottom of the schedule
const sortVisits = (a: Visit, b: Visit) => {
  if (!a.metadata?.priorityScore?.totalScore) return 1
  if (!b.metadata?.priorityScore?.totalScore) return -1
  return (
    b.metadata?.priorityScore?.totalScore -
    a.metadata?.priorityScore?.totalScore
  )
}

export const TimelineDay: React.FC<TimelineDayProps> = React.memo(
  ({ cleans, isToday, isStartOfSection, onCleanClick, visits }) => {
    const { t } = useI18n()

    const hasCleans = !!cleans.length
    const hasVisits = !!visits.length

    const sortedVisits = useMemo(() => [...visits].sort(sortVisits), [visits])

    return (
      <div
        className={classNames(styles.timelineData, {
          [styles.today]: isToday,
          [styles.startOfMonthBucket]: isStartOfSection,
        })}
      >
        {hasCleans &&
          cleans.map(clean => (
            <CleanCard clean={clean} key={clean.id} onClick={onCleanClick} />
          ))}

        {hasVisits &&
          sortedVisits.map(visit => (
            <VisitCardContainer key={visit.id} visit={visit} />
          ))}

        {!hasCleans && !hasVisits && (
          <div className={styles.noCleans}>{t(Slugs.noCleans)}</div>
        )}
      </div>
    )
  },
)
