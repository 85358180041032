import { isToday, createDateObject } from 'packages/utils/dateHelpers'
import { twentyFourHourMap } from 'packages/utils/timestamp'

import { TimeOption } from '../CrossCoverageForm/components/TimeSelect'

export function defaultStartTime(startDate: Date): TimeOption {
  const date = createDateObject()
  const hourOfDay = date.getHours() * 100
  const minuteOfDay = date.getMinutes()

  if (!isToday(startDate) || hourOfDay >= 2300) {
    return { hourOfDay: 800, timeOfDay: twentyFourHourMap[800] }
  }

  if (minuteOfDay > 30) {
    return {
      hourOfDay: hourOfDay + 100,
      timeOfDay: twentyFourHourMap[hourOfDay + 100],
    }
  }

  return {
    hourOfDay: hourOfDay + 30,
    timeOfDay: twentyFourHourMap[hourOfDay + 30],
  }
}

export const defaultEndTime = (): TimeOption => {
  return { hourOfDay: 2300, timeOfDay: twentyFourHourMap[2300] }
}
