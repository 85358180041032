import styled from '@emotion/styled'

import { SvgIcon } from 'packages/iconic'
import { colors, text, truncateTextWithEllipsis } from 'packages/styles'

export const ReviewHeader = styled.div<{ overallRating: number }>`
  ${text.bodyRegularSmall};
  background-color: ${({ overallRating }) => {
    if (overallRating >= 4) return colors.success // High overallRating
    if (overallRating >= 2) return colors.keyWest // Medium overallRating
    return colors.laguna // Low overallRating
  }};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  border-radius: 4px 4px 0 0;
`
export const TinyHeader = styled.div<{ overallRating: number }>`
  ${text.bodyRegularSmall};
  background-color: ${({ overallRating }) => {
    if (overallRating >= 4) return colors.success // High overallRating
    if (overallRating >= 2) return colors.keyWest // Medium overallRating
    return colors.laguna // Low overallRating
  }};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  border-radius: 4px 4px 4px 4px;
`

export const TinyId = styled.div`
  ${truncateTextWithEllipsis};
  color: black;
  font-weight: bold;
  padding: 4px 4px;
  flex-grow: 1;
  border-radius: 4px 0 0 0; // Rounded corner on the left side only
`

export const ReviewId = styled.div<{ isGreaterThanSmall: boolean }>`
  ${truncateTextWithEllipsis};
  color: black;
  font-weight: bold;
  padding: ${({ isGreaterThanSmall }) =>
    isGreaterThanSmall ? '8px 16px' : '4px 4px'};
  flex-grow: 1;
  border-radius: 4px 0 0 0; // Rounded corner on the left side only
`

export const PlatformIconContainer = styled.div<{
  isGreaterThanSmall: boolean
  overallRating: number
}>`
  background-color: ${({ overallRating }) => {
    if (overallRating >= 4) return colors.success70 // Lighter version of success for contrast
    if (overallRating >= 2) return colors.keyWestLight // Lighter version of keyWest for contrast
    return colors.lagunaLight // Lighter version of laguna for contrast
  }};
  padding: ${({ isGreaterThanSmall }) =>
    isGreaterThanSmall ? '8px' : '10px 4px'};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 4px 0 0; // Rounded corner on the right top side
`

export const PlatformIcon = styled(SvgIcon)`
  color: ${colors.white};
`
