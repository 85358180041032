import { createAsyncAction } from 'typesafe-actions'

import { RequestConfig } from 'packages/utils/store/jsonapi.types'

import {
  NormalizedUsersApiResponse,
  UserApiFieldsWithoutPII,
  UsersActionTypes,
  usersService,
} from 'app/fieldapp/store/users'

export const fetchCoveragePartnerOptionsAction = createAsyncAction(
  UsersActionTypes.FETCH_COVERAGE_OPTIONS,
  UsersActionTypes.FETCH_COVERAGE_OPTIONS_SUCCESS,
  UsersActionTypes.FETCH_COVERAGE_OPTIONS_FAILURE,
)<
  RequestConfig<NormalizedUsersApiResponse>,
  NormalizedUsersApiResponse,
  Error
>()

export const getParams = (search?: string) => ({
  fields: {
    user: UserApiFieldsWithoutPII,
  },
  filter: {
    eligible_for_search: true,
    exclude_me: true,
    is_active: true,
    search,
  },
  page: { size: 500 },
})

export const fetchCoveragePartnerOptions =
  (search?: string) => async dispatch => {
    try {
      const params = getParams(search)
      const request = usersService.fetchUsers.bind(null, params)
      const result = await dispatch(
        fetchCoveragePartnerOptionsAction.request({ request }),
      )
      dispatch(fetchCoveragePartnerOptionsAction.success(result))
      return result.normalized
    } catch (error) {
      dispatch(fetchCoveragePartnerOptionsAction.failure(error))
      throw error
    }
  }
