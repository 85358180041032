import styled from '@emotion/styled'
import React from 'react'

import { IconName, SvgIcon } from 'packages/iconic'
import { colors } from 'packages/styles'

import { Clean } from 'app/fieldapp/store/cleans'
import { useActiveUser } from 'app/fieldapp/utils/hooks/useActiveUser'

import { CleanTimerControlsContainer } from './CleanTimerControls'
import { ContractorCleanControlsContainer } from './ContractorCleanControls'

const St = {
  CompleteIcon: styled(SvgIcon)`
    bottom: 0;
    color: ${colors.success};
    padding: 12px;
    position: absolute;
    right: 0;
  `,
}

export enum CleanStatusControlsTestIds {
  complete = 'CleanStatusControls__complete',
  container = 'CleanStatusControls__container',
}

export type CleanStatusControlsProps = { clean: Clean }

export const CleanStatusControls: React.FC<CleanStatusControlsProps> =
  React.memo(({ clean }) => {
    const { isEmployee } = useActiveUser()

    if (!!clean.completedAt) {
      return (
        <St.CompleteIcon
          dataTestId={CleanStatusControlsTestIds.complete}
          icon={IconName.checkCircle}
          size={30}
        />
      )
    }

    return isEmployee ? (
      <CleanTimerControlsContainer clean={clean} />
    ) : (
      <ContractorCleanControlsContainer clean={clean} />
    )
  })
