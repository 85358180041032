import { createSelector } from 'reselect'

import { createDateObject, isAfter, isBefore } from 'packages/utils/dateHelpers'

import { ApplicationState } from '../../store'
import { Coverage } from '../coverage.types'
import { hydrateRawCoverage } from '../coverage.utils'

export const getCurrentCoverage = createSelector(
  (state: ApplicationState) => state.coverage.data,
  () => createDateObject(),
  (coverageData, currentDate): Coverage | undefined => {
    const rawCoverage =
      coverageData &&
      Object.values(coverageData).find(
        coverage =>
          isBefore(coverage.attributes.start, currentDate) &&
          isAfter(coverage.attributes.end, currentDate),
      )

    return rawCoverage && hydrateRawCoverage(rawCoverage)
  },
)
