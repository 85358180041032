import { AxiosResponse } from 'axios'
import { snakeCase } from 'lodash/fp'

import {
  AllCrossCoverageAttributeNames,
  AllCrossCoverageAttributes,
} from 'packages/grimoire/src/crossCoverage'
import {
  JSONApiObjectWithRelationships,
  JSONApiObjectWithRelationshipsMap,
  JsonApiErrorResponse,
  NormalizedJSONApiResponse,
  ToOneRelationship,
} from 'packages/grimoire/src/utils'

import { JSONApiUsersMap } from '../users'

/**********************************************************
 * REDUX TYPES
 *********************************************************/
export enum CrossCoverageActionTypes {
  CREATE_CROSS_COVERAGE = 'crossCoverages/createCrossCoverage/pending',
  CREATE_CROSS_COVERAGE_FAILURE = 'CROSS_COVERAGE/CREATE_CROSS_COVERAGE_FAILURE',
  CREATE_CROSS_COVERAGE_SUCCESS = 'CROSS_COVERAGE/CREATE_CROSS_COVERAGE_SUCCESS',

  DELETE_CROSS_COVERAGE = 'crossCoverages/deleteCrossCoverage/pending',
  DELETE_CROSS_COVERAGE_FAILURE = 'CROSS_COVERAGE/DELETE_CROSS_COVERAGE_FAILURE',
  DELETE_CROSS_COVERAGE_SUCCESS = 'CROSS_COVERAGE/DELETE_CROSS_COVERAGE_SUCCESS',

  FETCH_CROSS_COVERAGES = 'crossCoverages/fetchCrossCoverages/pending',
  FETCH_CROSS_COVERAGES_FAILURE = 'CROSS_COVERAGES/FETCH_CROSS_COVERAGE_FAILURE',
  FETCH_CROSS_COVERAGES_SUCCESS = 'CROSS_COVERAGES/FETCH_CROSS_COVERAGE_SUCCESS',

  FETCH_CROSS_COVERAGE_BY_ID = 'crossCoverages/fetchCrossCoverageById/pending',
  FETCH_CROSS_COVERAGE_BY_ID_FAILURE = 'CROSS_COVERAGE/FETCH_CROSS_COVERAGE_BY_ID_FAILURE',
  FETCH_CROSS_COVERAGE_BY_ID_SUCCESS = 'CROSS_COVERAGE/FETCH_CROSS_COVERAGE_BY_ID_SUCCESS',

  UPDATE_CROSS_COVERAGE = 'crossCoverages/updateCrossCoverage/pending',
  UPDATE_CROSS_COVERAGE_FAILURE = 'crossCoverage/updateCrossCoverage/failure',
  UPDATE_CROSS_COVERAGE_SUCCESS = 'crossCoverage/updateCrossCoverage/success',
}

export type CrossCoverageState = {
  data: JSONApiCrossCoverageMap
  error?: JsonApiErrorResponse
}

/**********************************************************
 * SERVICE TYPES
 *********************************************************/
export type CrossCoverageResponse = {
  crossCoverage: JSONApiCrossCoverageMap
  user: JSONApiUsersMap
}

export type NormalizedCrossCoverageApiResponse =
  NormalizedJSONApiResponse<CrossCoverageResponse>

export type CrossCoverageServiceResponse =
  Promise<NormalizedCrossCoverageApiResponse>

export type CrossCoverageApiResponse =
  AxiosResponse<NormalizedCrossCoverageApiResponse>

export type CrossCoveragePostData = {
  endDate: Date
  endTimeLocal: number
  friday?: boolean
  isRepeating?: boolean
  monday?: boolean
  saturday?: boolean
  startDate: Date
  startTimeLocal: number
  sunday?: boolean
  thursday?: boolean
  tuesday?: boolean
  tz: string
  userCoveredId: string
  userCoveringId: string
  wednesday?: boolean
}

export type CrossCoveragePatchData = {
  endDate: Date
  endTimeLocal: number
  friday?: boolean
  isRepeating?: boolean
  monday?: boolean
  saturday?: boolean
  startDate: Date
  startTimeLocal: number
  sunday?: boolean
  thursday?: boolean
  tuesday?: boolean
  tz: string
  userCoveredId: string
  userCoveringId: string
  wednesday?: boolean
}

export type CrossCoveragePostAPIAttributes = {
  end_date: string
  end_time_local: string
  friday?: boolean
  is_repeating?: boolean
  monday?: boolean
  saturday?: boolean
  start_date: string
  start_time_local: string
  sunday?: boolean
  thursday?: boolean
  tuesday?: boolean
  tz: string
  user_covered_id: string
  user_covering_id: string
  wednesday?: boolean
}

/**********************************************************
 * CROSS COVERAGE
 *********************************************************/
export type CrossCoverage = {
  endDateTimeUTC: string
  id: string
  startDateTimeUTC: string
  userCoveringId: string
} & CrossCoverageAttributes

export type CrossCoverageAttributes = AllCrossCoverageAttributes
export const CrossCoverageAttributeNames = AllCrossCoverageAttributeNames
// endDateTimeUTC and startDateTimeUTC are used internally in FA and are not valid fields to request from the API so we
// need to remove them here
export const CrossCoverageApiFields: string[] =
  CrossCoverageAttributeNames.filter(
    attr => attr !== 'endDateTimeUTC' && attr !== 'startDateTimeUTC',
  ).map(snakeCase)

export type CrossCoverageRelationships = {
  userCovered: ToOneRelationship
  userCovering: ToOneRelationship
}

export type RawCrossCoverage = JSONApiObjectWithRelationships<
  CrossCoverageAttributes,
  CrossCoverageRelationships
>

export type JSONApiCrossCoverageMap = JSONApiObjectWithRelationshipsMap<
  CrossCoverageAttributes,
  CrossCoverageRelationships
>
