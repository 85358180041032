import React from 'react'

import { getTaskProgressStatus, TaskProgressStatus } from 'packages/grimoire'
import {
  isUnitCleanInProgress,
  isUnitOccupied,
} from 'packages/grimoire/src/unit/unit.helpers'
import { IconName } from 'packages/iconic'
import { unitFullAddressString } from 'packages/utils/hkStringHelpers'
import { AsyncState } from 'packages/utils/hooks'
import { useWrappedEvent } from 'packages/utils/hooks/useWrappedEvent'

import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { Unit } from 'app/fieldapp/store/units'
import { Visit } from 'app/fieldapp/store/visits'

import { showUnitStatus } from '../../schedule.utils'
import { VisitStatusIconsContainer } from '../VisitDetail/components'
import * as St from './VisitCard.styles'

const getUnitStatusWarningText = (
  unit: Unit,
  strings: ReturnType<typeof useTranslations>,
): string | undefined => {
  if (isUnitOccupied(unit)) {
    return `${strings.unitOccupied}`
  }

  if (isUnitCleanInProgress(unit)) {
    return `${strings.cleanInProgress}`
  }

  return undefined
}

const unitIconTextStyles: React.CSSProperties = {
  marginLeft: 8,
  paddingTop: 1,
}

const useTranslations = (status: TaskProgressStatus) => {
  const { ut } = useI18n()

  const buttonTextByStatus: Record<TaskProgressStatus, string> = {
    completed: '',
    inProgress: ut(Slugs.markDone),
    notStarted: ut(Slugs.start),
  }

  return {
    buttonText: buttonTextByStatus[status],
    cleanInProgress: ut(Slugs.cleanInProgress),
    start: ut(Slugs.start),
    unitOccupied: ut(Slugs.unitOccupied),
    visit: ut(Slugs.visit),
  }
}

export enum VisitCardTestIds {
  container = 'VisitCard__container',
  unitStatusSection = 'VisitCard__section',
}

export type VisitCardProps = {
  canUpdateVisit: boolean
  onClick: () => void
  onUpdateVisit: () => void
  requestState: AsyncState<unknown>
  unit: Unit
  visit: Visit
}

export const VisitCard: React.FC<VisitCardProps> = React.memo(
  ({ canUpdateVisit, onClick, onUpdateVisit, requestState, unit, visit }) => {
    const { t } = useI18n()
    const { stopEvent } = useWrappedEvent()
    const status = getTaskProgressStatus(visit)
    const strings = useTranslations(status)
    const unitAddress = unitFullAddressString(unit)
    const unitStatusWarningText = getUnitStatusWarningText(unit, strings)
    const totalVPSScore = visit.metadata?.priorityScore?.totalScore
    const isHighestScore = visit.metadata?.priorityScore?.isHighestScore

    return (
      <St.TaskCardContainer
        dataTestId={VisitCardTestIds.container}
        onClick={onClick}
      >
        <St.VisitHeader>
          <St.VisitHeaderLeftIcons>
            <St.TaskIcon
              centerItems={true}
              icon={IconName.maintenance}
              size={16}
              text={strings.visit}
              textFontSize={'inherit'}
            />
            {visit.serviceType === 'scheduled' && (
              <St.ScheduledVisitIcon
                centerItems={true}
                icon={IconName.refreshCw}
                size={13}
              />
            )}
          </St.VisitHeaderLeftIcons>
          <VisitStatusIconsContainer unitId={unit.id} />
        </St.VisitHeader>

        <St.TaskCardContent>
          <St.CardHeader>
            <div>
              <St.UnitCode>
                {unit.unitCode}
                {totalVPSScore &&
                  `, ${t(Slugs.visitCardVPSHeader)}${' '}
                ${totalVPSScore}`}
              </St.UnitCode>
              <St.UnitName>{unit.name}</St.UnitName>
            </div>
            {isHighestScore && <St.StarIcon icon={IconName.star} size={22} />}
          </St.CardHeader>

          <St.ContentGrid>
            {showUnitStatus(unit) && (
              <St.UnitStatusIcon
                centerItems={true}
                dataTestId={VisitCardTestIds.unitStatusSection}
                icon={IconName.alertCircle}
                size={14}
                text={unitStatusWarningText}
                textFontSize={14}
                textStyleOverrides={unitIconTextStyles}
              />
            )}

            <St.UnitAddress>{unitAddress}</St.UnitAddress>

            {status === 'completed' ? (
              <St.CompleteIcon icon={IconName.checkCircle} size={30} />
            ) : (
              <St.UpdateVisitButton
                buttonType={status === 'inProgress' ? 'danger' : 'primary'}
                disabled={!canUpdateVisit}
                isLoading={requestState.loading}
                onClick={stopEvent(onUpdateVisit)}
              >
                {strings.buttonText}
              </St.UpdateVisitButton>
            )}
          </St.ContentGrid>
        </St.TaskCardContent>
      </St.TaskCardContainer>
    )
  },
)
