import { merge } from 'lodash/fp'
import { createSelector } from 'reselect'

import { ApplicationState } from 'app/fieldapp/store/store'

import { CleanTime, RawCleanTime } from '../cleantimes.types'
import { getMergedCleanTimeById } from './getMergedCleanTimeById'

const cleanTimeIsActive = (rct: RawCleanTime) =>
  !!rct.attributes.startedAt && !rct.attributes.stoppedAt

const getFullState = (state: ApplicationState): ApplicationState => state

export const getActiveCleanTime = createSelector(
  getFullState,
  (fullState): CleanTime | undefined => {
    const { data, offlineData } = fullState.cleanTimes
    const mergedCleanTimes: RawCleanTime[] = Object.values(
      merge(data, offlineData),
    )
    const rawActiveCleanTime = mergedCleanTimes.find(cleanTimeIsActive)

    if (!rawActiveCleanTime) return undefined

    /*
     * Check for an offline timer with the active id -
     * if it exists and is stopped offline, it belongs in paused timers
     */
    const offlineCleanTime =
      fullState.cleanTimes.offlineData[rawActiveCleanTime.id]
    if (offlineCleanTime?.attributes.stoppedAt) {
      return undefined
    }

    return getMergedCleanTimeById(fullState, rawActiveCleanTime.id)
  },
)
