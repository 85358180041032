import React from 'react'
import { useDispatch } from 'react-redux'

import { useSuperModal, useToast } from 'packages/common'
import {
  Assignment,
  CleanTime,
  CleanTimePostData,
  taskIsVisit,
} from 'packages/grimoire'
import {
  PartialAllExceptRequired,
  ReduxDispatch,
} from 'packages/grimoire/src/utils'
import {
  GenericTimer,
  TimerType,
} from 'packages/grimoire/src/utils/timers.types'
import { useI18n } from 'packages/i18n'
import { createDateObject } from 'packages/utils/dateHelpers'
import { useOnlineStatus } from 'packages/utils/hooks/useOnlineStatus'

import {
  Timer,
  clearLoadingTimer,
  clearTimerSubmission,
  setLoadingTimer,
  useTimers,
  setSubmissionData,
} from 'app/fieldapp/components/timers/state'
import { StartedOfflineCleanTime } from 'app/fieldapp/components/timers/timers.types'
import { Slugs } from 'app/fieldapp/i18n'
import { updateClean } from 'app/fieldapp/store/cleans/actions'
import { CleanPatchData } from 'app/fieldapp/store/cleans/cleans.types'
import { Clean } from 'app/fieldapp/store/cleans/cleans.types'
import {
  updateCleanTime,
  deleteCleanTime,
  createCleanTime,
  updateCleanTimeOffline,
  createFinalizedCleanTime,
} from 'app/fieldapp/store/cleantimes/actions'
import { ApplicationState } from 'app/fieldapp/store/store'
import { taskIsClean } from 'app/fieldapp/store/tasks/utils/tasks.utils'
import { Visit } from 'app/fieldapp/store/visits'
import { fetchVisitById } from 'app/fieldapp/store/visits/actions'

import { useCleanCompletionModal } from '../../useCleanCompletionModal'
import { useTimerErrors } from '../../useTimerErrors'
import {
  convertTimestamps,
  NEW_TIMER_ID_PLACEHOLDER,
  createConfirmTimerDeletionModal,
} from '../useTimerActions.helpers'

type TimerWithNotes = Timer & {
  notes: string
}

type CleanTimeWithAssignmentOrAssignmentId = PartialAllExceptRequired<
  CleanTime & { assignment?: Assignment; assignmentId?: string },
  'id'
>

export type UseCleanTimerActions = {
  dispatchBeginCleanTimeStartedOfflineSubmission: (
    cleanTime: GenericTimer,
  ) => Promise<void>
  dispatchBeginCleanTimeSubmission: (cleanTime: GenericTimer) => Promise<void>
  dispatchCancelStopCleanTimer: (id: string) => Promise<void>
  dispatchCancelStopOfflineCleanTimer: (id: string) => Promise<void>
  dispatchCompleteStartedOfflineCleanTimer: (
    timer: StartedOfflineCleanTime,
    clean: Clean | Visit | undefined,
  ) => Promise<void>
  dispatchConfirmCleanTimerSubmission: (
    clean: Clean | Visit,
    cleanTimes: TimerWithNotes[],
  ) => Promise<void>
  dispatchDeleteCleanTimerWithModalWarning: (id: string) => Promise<void>
  dispatchStartCleanTimer: (
    data: CleanTimePostData,
    startCleanData?: CleanPatchData | undefined,
  ) => Promise<void>
}

export function useCleanTimerActions(): UseCleanTimerActions {
  const { t } = useI18n()
  const reduxDispatch = useDispatch<ReduxDispatch<ApplicationState>>()

  const { showModal } = useSuperModal()
  const { showCleanCompletionModal } = useCleanCompletionModal()
  const { showToast } = useToast()
  const { dispatch: timersDispatch } = useTimers()
  const { clearExistingErrors, handleError: showErrorBanner } = useTimerErrors()
  const isOnline = useOnlineStatus().isOnline()

  /**
   * @deprecated Do not use this in any new code. It suppresses errors that end up not getting handled properly.
   */
  const reduxDispatchWithErrorHandling = React.useCallback(
    async action => {
      try {
        await reduxDispatch(action)
      } catch (e) {
        showErrorBanner(e)
      }
    },
    [reduxDispatch, showErrorBanner],
  )

  /**************************************************
   * Start Functions
   **************************************************/
  const dispatchStartClean = async (data: CleanPatchData) => {
    clearExistingErrors()
    timersDispatch(setLoadingTimer(NEW_TIMER_ID_PLACEHOLDER))
    await reduxDispatchWithErrorHandling(updateClean(data))
    timersDispatch(clearLoadingTimer())
  }

  const dispatchStartCleanTimer = async (
    data: CleanTimePostData,
    startCleanData?: CleanPatchData,
  ) => {
    clearExistingErrors()
    timersDispatch(setLoadingTimer(NEW_TIMER_ID_PLACEHOLDER))
    await reduxDispatchWithErrorHandling(createCleanTime(data))
    if (!!startCleanData && isOnline) await dispatchStartClean(startCleanData)
    timersDispatch(clearLoadingTimer())
  }

  /**************************************************
   * Stop Functions
   **************************************************/
  const dispatchStopActiveCleanTimer = async (id: string) => {
    timersDispatch(setLoadingTimer(id))

    try {
      await reduxDispatch(
        updateCleanTime({
          id,
          stoppedAt: createDateObject(),
        }),
      )
    } catch (err) {
      throw Error(err)
    } finally {
      timersDispatch(clearLoadingTimer())
    }
  }

  const dispatchCancelStopCleanTimer = React.useCallback(
    async (id: string) => {
      timersDispatch(setLoadingTimer(id))
      clearExistingErrors()

      await reduxDispatchWithErrorHandling(
        updateCleanTime({
          id,
          stoppedAt: null,
        }),
      )

      timersDispatch(clearLoadingTimer())
      timersDispatch(clearTimerSubmission())
    },
    [clearExistingErrors, reduxDispatchWithErrorHandling, timersDispatch],
  )

  const dispatchCancelStopOfflineCleanTimer = React.useCallback(
    async (id: string) => {
      timersDispatch(setLoadingTimer(id))
      clearExistingErrors()

      try {
        // Even though we are not necessarily offline, we only care about updating offline data,
        // since the timer has unsynced data
        await reduxDispatch(
          updateCleanTimeOffline({
            data: {
              id,
              stoppedAt: null,
            },
          }),
        )
      } catch (err) {
        throw Error(err)
      } finally {
        timersDispatch(clearLoadingTimer())
        timersDispatch(clearTimerSubmission())
      }
    },
    [clearExistingErrors, reduxDispatch, timersDispatch],
  )

  /** Will conditionally post a started offline clean timer or update it locally, depending on online status*/
  const dispatchCompleteStartedOfflineCleanTimer = React.useCallback(
    async (
      timer: CleanTimeWithAssignmentOrAssignmentId,
      task: Clean | Visit | undefined,
    ) => {
      clearExistingErrors()
      try {
        const { assignment, assignmentId, startedAt, stoppedAt, notes, id } =
          timer

        const timerAssignmentId = assignment?.id || assignmentId

        /*
         * There is no reason these should not be defined, but in the case they are,
         * don't try anything, because we'll end post/patching bad data
         */
        if (timerAssignmentId && stoppedAt && startedAt) {
          /** Attributes for both patch and post requests */
          const sharedPayload = {
            assignmentId: timerAssignmentId,
            isFinalized: true,
            notes,
            ...convertTimestamps(startedAt, stoppedAt),
          }
          if (isOnline) {
            // Here, we post the whole timer
            await reduxDispatch(createFinalizedCleanTime(id, sharedPayload))
          } else {
            // If we're offline, we save this so it becomes a "paused timer"
            await reduxDispatch(
              updateCleanTimeOffline({
                data: {
                  id,
                  ...sharedPayload,
                },
              }),
            )
          }
        }

        if (task && taskIsClean(task)) showCleanCompletionModal(task)
        timersDispatch(clearTimerSubmission())
        showToast({
          message: t(isOnline ? Slugs.hoursSubmitted : Slugs.timerSavedOffline),
        })
      } catch (error) {
        showErrorBanner(error)
      }
    },
    [
      clearExistingErrors,
      isOnline,
      reduxDispatch,
      showCleanCompletionModal,
      showErrorBanner,
      showToast,
      t,
      timersDispatch,
    ],
  )

  const dispatchBeginCleanTimeStartedOfflineSubmission = async (
    cleanTime: GenericTimer,
  ) => {
    timersDispatch(clearTimerSubmission())

    try {
      if (!cleanTime.stoppedAt) {
        timersDispatch(setLoadingTimer(cleanTime.id))

        try {
          await reduxDispatch(
            updateCleanTimeOffline({
              data: {
                id: cleanTime.id,
                stoppedAt: createDateObject(),
              },
            }),
          )
        } catch (err) {
          throw Error(err)
        } finally {
          timersDispatch(clearLoadingTimer())
        }
      }

      timersDispatch(
        setSubmissionData({
          timer: cleanTime,
          type: TimerType.CLEAN,
        }),
      )
    } catch (err) {
      showErrorBanner(err)
    }
  }

  /**************************************************
   * Submission Functions
   **************************************************/

  const dispatchConfirmCleanTimerSubmission = React.useCallback(
    async (task: Clean | Visit, cleanTimes: TimerWithNotes[]) => {
      clearExistingErrors()
      timersDispatch(setLoadingTimer(NEW_TIMER_ID_PLACEHOLDER))

      const isVisitInpsectionTimer = taskIsVisit(task)

      try {
        await Promise.all(
          cleanTimes.map(ct =>
            reduxDispatch(
              updateCleanTime({
                id: ct.id,
                isFinalized: true,
                isVisitInpsectionTimer,
                notes: ct.notes,
                ...convertTimestamps(ct.startedAt, ct.stoppedAt),
              }),
            ),
          ),
        )

        if (taskIsClean(task)) showCleanCompletionModal(task)
        else await reduxDispatch(fetchVisitById(task.id))
      } catch (error) {
        showErrorBanner(error)
      } finally {
        timersDispatch(clearTimerSubmission())
        timersDispatch(clearLoadingTimer())
      }
    },
    [
      clearExistingErrors,
      reduxDispatch,
      showCleanCompletionModal,
      showErrorBanner,
      timersDispatch,
    ],
  )

  const dispatchBeginCleanTimeSubmission = async (cleanTime: GenericTimer) => {
    timersDispatch(clearTimerSubmission())

    try {
      if (!cleanTime.stoppedAt) {
        await dispatchStopActiveCleanTimer(cleanTime.id)
      }

      timersDispatch(
        setSubmissionData({
          timer: cleanTime,
          type: TimerType.CLEAN,
        }),
      )
    } catch (err) {
      showErrorBanner(err)
    }
  }

  /**************************************************
   * Delete Functions
   **************************************************/
  const dispatchDeleteCleanTime = React.useCallback(
    async (id: string) => {
      clearExistingErrors()
      await reduxDispatchWithErrorHandling(deleteCleanTime(id))
      timersDispatch(clearLoadingTimer())
      timersDispatch(clearTimerSubmission())
    },
    [clearExistingErrors, reduxDispatchWithErrorHandling, timersDispatch],
  )

  const dispatchDeleteCleanTimerWithModalWarning = React.useCallback(
    async (id: string) => {
      showModal(
        createConfirmTimerDeletionModal(t)(async () => {
          timersDispatch(setLoadingTimer(id))
          dispatchDeleteCleanTime(id)
        }),
      )
    },
    [dispatchDeleteCleanTime, showModal, t, timersDispatch],
  )

  return {
    dispatchBeginCleanTimeStartedOfflineSubmission,
    dispatchBeginCleanTimeSubmission,
    dispatchCancelStopCleanTimer,
    dispatchCancelStopOfflineCleanTimer,
    dispatchCompleteStartedOfflineCleanTimer,
    dispatchConfirmCleanTimerSubmission,
    dispatchDeleteCleanTimerWithModalWarning,
    dispatchStartCleanTimer,
  }
}
