import { createSelector } from 'reselect'

import { ApplicationState } from 'app/fieldapp/store/store'

import { TicketTime } from '../ticket-times.types'
import { hydrateRawTicketTime } from '../ticket-times.utils'

export const getActiveTicketTimerByVisitId = createSelector(
  (state: ApplicationState) => state.ticketTimes.data,
  (state: ApplicationState) => state.visits.data,
  (_, visitId: string) => visitId,
  (ticketTimesData, visitsData, visitId): TicketTime | undefined => {
    const rawVisit = visitsData[visitId]
    if (!rawVisit) return undefined

    const visitTicketIds = (rawVisit.relationships?.tickets?.data || []).map(
      ({ id }) => id,
    )

    const rawTicketTime = Object.values(ticketTimesData).find(rtt =>
      visitTicketIds.includes(rtt?.relationships?.ticket?.data?.id),
    )

    return rawTicketTime && hydrateRawTicketTime(rawTicketTime)
  },
)
