import { concat, filter, map, merge, reduce } from 'lodash/fp'
import { createSelector } from 'reselect'

import { hydrateRawCleanTime } from 'app/fieldapp/store/cleantimes'
import {
  hydrateRawOtherTime,
  OfflineOtherTimer,
} from 'app/fieldapp/store/otherTimers'
import { ApplicationState } from 'app/fieldapp/store/store'
import {
  hydrateRawTicketTime,
  OfflineTicketTime,
} from 'app/fieldapp/store/ticket-times'

import { TimersMap } from '../timers.types'

const mergeTimers = offlineData => (acc, rawTimer) => {
  const id = rawTimer.id
  const offlineTimer = offlineData[id]

  if (offlineTimer) {
    acc.push(merge(rawTimer, offlineTimer))
  }

  return acc
}

const getFullState = (state: ApplicationState): ApplicationState => state

export const getAllOfflineMergedTimers = createSelector(
  getFullState,
  (fullState): TimersMap => {
    const { otherTimers, cleanTimes, ticketTimes } = fullState

    /**********************************************************
     * OTHER TIMES
     * *******************************************************/
    const rawOtherTimers = Object.values(otherTimers.data)
    const rawOfflineOtherTimers = Object.values(otherTimers.offlineData)

    const partialOfflineOtherTimers = reduce(
      mergeTimers(otherTimers.offlineData),
      [],
    )(rawOtherTimers)

    const startedOfflineOtherTimers = filter(
      (timer: OfflineOtherTimer) => !!timer.attributes.startedOffline,
    )(rawOfflineOtherTimers)

    const allRawOtherTimers = concat(
      partialOfflineOtherTimers,
      startedOfflineOtherTimers,
    )
    const mergedOtherTimers = map(hydrateRawOtherTime)(allRawOtherTimers)

    /**********************************************************
     * ClEAN TIMES
     * *******************************************************/

    const rawCleanTimes = Object.values(cleanTimes.data)
    const rawOfflineCleanTimes = Object.values(cleanTimes.offlineData)

    const partialOfflineCleanTimers = reduce(
      mergeTimers(cleanTimes.offlineData),
      [],
    )(rawCleanTimes)

    const startedOfflineCleanTimers = filter(
      (timer: OfflineOtherTimer) => !!timer.attributes.startedOffline,
    )(rawOfflineCleanTimes)

    const allRawCleanTimes = concat(
      partialOfflineCleanTimers,
      startedOfflineCleanTimers,
    )

    const mergedCleanTimes = map(hydrateRawCleanTime(fullState))(
      allRawCleanTimes,
    )

    /**********************************************************
     * TICKET TIMES
     * *******************************************************/
    const rawTicketTimes = Object.values(ticketTimes.data)
    const rawOfflineTicketTimes = Object.values(ticketTimes.offlineData)

    const partialOfflineTicketTimers = reduce(
      mergeTimers(ticketTimes.offlineData),
      [],
    )(rawTicketTimes)

    const startedOfflineTicketTimers = filter(
      (timer: OfflineTicketTime) => !!timer.attributes.startedOffline,
    )(rawOfflineTicketTimes)

    const allRawTicketTimes = concat(
      partialOfflineTicketTimers,
      startedOfflineTicketTimers,
    )

    const mergedTicketTimes = map(hydrateRawTicketTime)(allRawTicketTimes)

    return {
      cleanTimes: mergedCleanTimes,
      otherTimers: mergedOtherTimers,
      ticketTimes: mergedTicketTimes,
    }
  },
)
