import styled from '@emotion/styled'
import React from 'react'

import { StartTimerButton } from 'app/fieldapp/components/timers/components/Buttons/StartTimerButton'
import { StopTimerButton } from 'app/fieldapp/components/timers/components/Buttons/StopTimerButton'

const St = {
  ContractorCleanControls: styled.div`
    display: flex;
  `,
}
export enum ContractorCleanControlsTestIds {
  container = 'ContractorCleanControls__container',
  startBtn = 'ContractorCleanControls__startBtn',
  stopBtn = 'ContractorCleanControls__stopBtn',
}

export type ContractorCleanControlsProps = {
  disabled: boolean
  isRunning: boolean
  loading: boolean
  onClick: () => void
}

export const ContractorCleanControls: React.FC<ContractorCleanControlsProps> =
  React.memo(({ loading, isRunning, ...passThroughProps }) => {
    return (
      <St.ContractorCleanControls
        data-testid={ContractorCleanControlsTestIds.container}
      >
        {isRunning ? (
          <StopTimerButton
            ariaLabel="Stop the clean"
            dataTestId={ContractorCleanControlsTestIds.stopBtn}
            isLoading={loading}
            {...passThroughProps}
          />
        ) : (
          <StartTimerButton
            ariaLabel="Start the clean"
            dataTestId={ContractorCleanControlsTestIds.startBtn}
            isLoading={loading}
            {...passThroughProps}
          />
        )}
      </St.ContractorCleanControls>
    )
  })
