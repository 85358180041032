import { Slugs } from 'app/fieldapp/i18n'
import { Review } from 'app/fieldapp/store/reviews/reviews.types'

export function generateRatings(
  review: Review,
): { slug: Slugs; value: number }[] {
  // Mapping of Review keys to their corresponding slug values
  const slugMap: { [key in keyof Partial<Review>]: Slugs } = {
    accuracyRating: Slugs.reviewAccuracyRating,
    checkinRating: Slugs.reviewCheckinRating,
    cleanlinessRating: Slugs.reviewCleanlinessRating,
    comfortRating: Slugs.reviewComfortRating,
    communicationRating: Slugs.reviewCommunicationRating,
    facilitiesRating: Slugs.reviewFacilitiesRating,
    locationRating: Slugs.reviewLocationRating,
    onlineListingRating: Slugs.reviewOnlineListingRating,
    propertyConditionRating: Slugs.reviewPropertyConditionRating,
    serviceRating: Slugs.reviewServiceRating,
    staffRating: Slugs.reviewStaffRating,
    valueRating: Slugs.reviewValueRating,
    wifiRating: Slugs.reviewWifiRating,
  }

  return (
    (Object.keys(slugMap) as (keyof Review)[])
      // Filter out undefined values in both review and slugMap
      .filter(key => review[key] !== undefined && slugMap[key] !== undefined)
      .map(key => {
        const slug = slugMap[key]
        const value = review[key] as number

        // Only return if both slug and value are defined
        if (slug !== undefined && value !== undefined && value !== null) {
          return { slug, value }
        }

        return null
      })
      .filter((item): item is { slug: Slugs; value: number } => item !== null)
  )
}
