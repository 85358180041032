import styled from '@emotion/styled'
import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import { Loader, VersionDisplay } from 'packages/common'
import { colors } from 'packages/styles'
import { splitSearchQuery } from 'packages/utils/misc'

import { fanOutDataBatchRequests } from 'app/fieldapp/store/app/actions'
import { getFanOutBatchRequests } from 'app/fieldapp/store/app/selectors'
import { useAppDispatch, useAppSelector } from 'app/fieldapp/store/hooks'

import { CleanDetailDrawerContainer as CleanDetailDrawer } from '../components/CleanDetail'
import { CleansTimelineContainer } from '../components/CleansTimeline/CleanTimeline.container'
import { ReviewDrawerContainer } from '../components/Reviews/ReviewDrawer'
import { VisitDetailDrawerContainer } from '../components/VisitDetail/VisitDetailDrawer'
import { VisitTicketDrawerContainer } from '../components/VisitTicketDrawer'

const St = {
  SchedulePage: styled.div`
    background-color: ${colors.midnight4};
    // This is used to ensure the version is not hidden by other timers
    padding-bottom: 24px;
  `,
  VersionDisplay: styled(VersionDisplay)`
    padding-right: 8px;
  `,
}

export const SchedulePage: React.FC = () => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const fanOutDataBatchRequestsState = useAppSelector(getFanOutBatchRequests)
  const { selectedClean = '' } = splitSearchQuery(location.search)

  /**
   * Handler for clicking on a clean card; triggers CleanDetail view for that clean
   */
  const handleCleanClick = React.useCallback(
    (cleanId: string): void => {
      navigate(`/cleans?selectedClean=${cleanId}`)
    },
    [navigate],
  )

  /*
   * TODO: TPD-6626 - Rewrite this ref and hook using a less clever strategy
   */
  const isInitialAppRender = React.useRef(
    fanOutDataBatchRequestsState !== 'fulfilled',
  )

  React.useEffect(() => {
    if (isInitialAppRender.current) return
    dispatch(fanOutDataBatchRequests())
  }, [dispatch])

  if (fanOutDataBatchRequestsState === 'pending') {
    return <Loader />
  }

  return (
    <St.SchedulePage>
      <CleansTimelineContainer onCleanClick={handleCleanClick} />
      <St.VersionDisplay />

      <VisitDetailDrawerContainer />
      <VisitTicketDrawerContainer />
      <ReviewDrawerContainer />
      <CleanDetailDrawer cleanId={selectedClean} />
    </St.SchedulePage>
  )
}
