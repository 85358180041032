import React from 'react'

import { useUnitVisits } from '../hooks'
import { CreateVisitWarnings } from './CreateVisitWarnings'

export type CreateVisitWarningsContainerProps = {
  unitId: string
}

export const CreateVisitWarningsContainer: React.FC<CreateVisitWarningsContainerProps> =
  React.memo(({ unitId }) => {
    const { currentReservation, otherVisitUser, userHasExistingVisit } =
      useUnitVisits(unitId)

    return (
      <CreateVisitWarnings
        currentReservation={currentReservation}
        otherVisitUser={otherVisitUser}
        userHasExistingVisit={userHasExistingVisit}
      />
    )
  })
