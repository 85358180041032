import { createSelector } from 'reselect'

import { Housekeeper } from '../../housekeepers'
import { ApplicationState } from '../../store'
import { User } from '../users.types'
import { getUserById } from './getUserById'

export const getFilteredUsersByHkIds = createSelector(
  (state: ApplicationState) => state,
  (_, hks: Housekeeper[]) => hks,
  (state, hks) => {
    return hks
      .map(hk => (!!hk?.userId ? getUserById(state, hk?.userId) : undefined))
      .filter(Boolean) as User[]
  },
)
