import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMount } from 'react-use'

import { AppDispatch } from 'app/fieldapp/store/store'
import { Disposition } from 'app/fieldapp/store/tickets'
import { fetchTicketingMaintenanceDispositions } from 'app/fieldapp/store/tickets/actions'
import { getTierTwoMaintenanceRequestDispositions } from 'app/fieldapp/store/tickets/selectors'
import { getHousekeepingAndMaintenanceDispositions } from 'app/fieldapp/store/tickets/selectors/getHousekeepingAndMaintenanceDispositions'

export const useMaintenanceDispositions = (
  useHousekeepingSelector: boolean = false,
): null | Disposition[] | 'error' => {
  const dispatch: AppDispatch = useDispatch()

  const [error, setError] = React.useState(false)

  const dispositions = useSelector(
    useHousekeepingSelector
      ? getHousekeepingAndMaintenanceDispositions
      : getTierTwoMaintenanceRequestDispositions,
  )

  const dispositionsExistInStore = React.useMemo(
    () => dispositions.length,
    [dispositions],
  )

  const fetchDispositions = React.useCallback(async () => {
    try {
      await dispatch(fetchTicketingMaintenanceDispositions)
      setError(false)
    } catch (e) {
      setError(true)
    }
  }, [dispatch])

  useMount(async () => {
    if (!dispositionsExistInStore) fetchDispositions()
  })

  if (dispositionsExistInStore) return dispositions

  return error ? 'error' : null
}
