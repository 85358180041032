import styled from '@emotion/styled'
import * as React from 'react'

import { layers } from 'packages/styles/jss'

const ChipContainer = styled.div`
  background-color: rgba(44, 52, 57, 0.9);
  border-radius: 8px;
  color: white;
  display: inline-flex;
  padding: 8px;
  z-index: ${layers.tooltip};
`

export enum ChipTestIds {
  container = 'Chip__container',
}

export type ChipProps = {
  className?: string
  testId?: string
}

export const Chip: React.FC<React.PropsWithChildren<ChipProps>> = React.memo(
  ({ children, className, testId }) => {
    return (
      <ChipContainer
        className={className}
        data-testid={testId || ChipTestIds.container}
      >
        {children}
      </ChipContainer>
    )
  },
)
