import React from 'react'

import { Rating } from 'packages/common'

import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { Review } from 'app/fieldapp/store/reviews/reviews.types'

import {
  cardIsTiny,
  CardSize,
  cardSizeGreaterThan,
} from '../../../../../hkhub/components/schedule/helpers/scheduleMeasure'
import * as St from './ReviewCard.styles'
import { ReviewCardHeader } from './ReviewCardHeader'

export type ReviewCardProps = {
  onClick: () => void
  review: Review
  size?: CardSize
}

const useTranslations = () => {
  const { ut } = useI18n()

  return {
    reviewDate: ut(Slugs.reviewDate),
    reviewOverallRating: ut(Slugs.reviewOverallRating),
  }
}

export enum ReviewCardTestIds {
  container = 'ReviewCard__container',
}

export const ReviewCard: React.FC<ReviewCardProps> = React.memo(
  ({ review, onClick, size = 'medium' }) => {
    const strings = useTranslations()

    if (cardIsTiny(size)) {
      return (
        <St.TinyCard onClick={onClick}>
          <ReviewCardHeader
            id={review.reviewId}
            reviewSource={review.reviewSource}
            overallRating={review.overallRating}
            isReplied={!!review.reviewReply}
            size={size}
          />
        </St.TinyCard>
      )
    }

    return (
      <St.ReviewCard dataTestId={ReviewCardTestIds.container} onClick={onClick}>
        <ReviewCardHeader
          id={review.reviewId}
          reviewSource={review.reviewSource}
          overallRating={review.overallRating}
          isReplied={!!review.reviewReply}
          size={size}
        />

        <St.CardContent>
          {review.reviewHeadline && (
            <St.ReviewTitle>{review.reviewHeadline}</St.ReviewTitle>
          )}

          {review.publicReviewText && (
            <St.ReviewContent>{review.publicReviewText}</St.ReviewContent>
          )}
          <St.Rating>
            {cardSizeGreaterThan(size, 'small') && (
              <b>{strings.reviewOverallRating}:&nbsp;&nbsp;</b>
            )}
            <Rating value={review.overallRating} />
          </St.Rating>
          <St.ReviewDate>
            {cardSizeGreaterThan(size, 'small') && (
              <b>{strings.reviewDate}:&nbsp;&nbsp;</b>
            )}
            {review.reviewDate}
          </St.ReviewDate>
        </St.CardContent>
      </St.ReviewCard>
    )
  },
)
