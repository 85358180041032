import { filter, last, map, pipe, sortBy, values } from 'lodash/fp'
import { createSelector } from 'reselect'

import { createDateString, isAfter, isBefore } from 'packages/utils/dateHelpers'
import { logWarning } from 'packages/wiretap/logging'

import { ApplicationState } from '../../store'
import { StandardAvailability } from '../standardAvailability.types'
import { hydrateRawStandardAvailability } from '../standardAvailability.utils'

const mishappenDataLogger = (
  availabilities: StandardAvailEntry[],
  name: 'current' | 'upcoming',
) => {
  if (availabilities.length > 1) {
    logWarning(
      `Received more than one set of ${name} standard availabilities`,
      {
        availabilityIds: availabilities.map(availability => availability?.id),
      },
    )
  }
}

type StandardAvailEntry = StandardAvailability | undefined

type StandardAvailabilityForUser = [StandardAvailEntry, StandardAvailEntry]

/**
  This selector returns a standard availability tuple. The reason for this is so
  we can get the users current and upcoming standard availability seperated and also so we can determine
  whether or not they HAVE any standard availability. The possible return values for this selector are:
    1. [currentAvailability, upcomingAvailability] (user has both current and upcoming availability in redux)
    2. [currentAvailability, undefined] (user only has current availability in redux)
    3. [undefined, undefined] (user has no current/upcoming availability in redux)

  If we ever get more than one current and/or upcoming standard availability something has gone wrong
  and we log it to DD
*/
export const getStandardAvailability = createSelector(
  (state: ApplicationState) => state.standardAvailability,
  (standardAvailabilityState): StandardAvailabilityForUser => {
    if (!standardAvailabilityState?.data) return [undefined, undefined]

    const mapAndSort = pipe(
      values,
      map(hydrateRawStandardAvailability),
      sortBy('startDate'),
    )

    const currentStandardAvailability: StandardAvailEntry = pipe(
      mapAndSort,
      filter((standardAvailability: StandardAvailability) =>
        isBefore(standardAvailability.startDate, createDateString()),
      ),
      (standardAvailabilities: StandardAvailability[]) => {
        mishappenDataLogger(standardAvailabilities, 'current')
        return standardAvailabilities
      },
      last,
    )(standardAvailabilityState?.data)

    const upcomingStandardAvailability: StandardAvailEntry = pipe(
      mapAndSort,
      filter((standardAvailability: StandardAvailability) =>
        isAfter(standardAvailability.startDate, createDateString()),
      ),
      (standardAvailabilities: StandardAvailability[]) => {
        mishappenDataLogger(standardAvailabilities, 'upcoming')
        return standardAvailabilities
      },
      last,
    )(standardAvailabilityState?.data)

    return [currentStandardAvailability, upcomingStandardAvailability]
  },
)
