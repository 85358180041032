import { RequestOptions } from 'packages/utils/store/jsonapi.types'

import { api } from '../axiosInstance'
import {
  StandardAvailabilityApiResponse,
  StandardAvailabilityServiceResponse,
} from './standardAvailability.types'
import { emptyStandardAvailabilityResponse } from './standardAvailability.utils'

const URL = '/standard_availability'

export const standardAvailabilityService = {
  async fetchStandardAvailability(
    params: RequestOptions = {},
  ): StandardAvailabilityServiceResponse {
    const response: StandardAvailabilityApiResponse = await api.get(URL, {
      params,
    })
    return response?.data || emptyStandardAvailabilityResponse
  },

  async updateStandardAvailabilityById(
    standardAvailabilityId: string,
    data: RequestOptions,
  ): StandardAvailabilityServiceResponse {
    const response: StandardAvailabilityApiResponse = await api.patch(
      `${URL}/${standardAvailabilityId}`,
      data,
    )

    return response?.data || emptyStandardAvailabilityResponse
  },

  async createStandardAvailability(
    data: RequestOptions,
  ): StandardAvailabilityServiceResponse {
    const response: StandardAvailabilityApiResponse = await api.post(URL, data)

    return response?.data || emptyStandardAvailabilityResponse
  },

  async deleteStandardAvailabilityById(
    standardAvailabilityId: string,
  ): StandardAvailabilityServiceResponse {
    const response: StandardAvailabilityApiResponse = await api.delete(
      `${URL}/${standardAvailabilityId}`,
    )

    return response?.data || emptyStandardAvailabilityResponse
  },
}
