import { noop } from 'lodash/fp'
import React from 'react'

type UseShareApiProps = {
  onShareError?: (error: Error) => void
}

type ShareArgs = {
  text: string
  title?: string
}

type UseShareApi = {
  canShare: boolean
  share: (ShareArgs) => void
}

export const useShareApi = ({
  onShareError = noop,
}: UseShareApiProps = {}): UseShareApi => {
  const share = React.useCallback(
    async ({ text, title }: ShareArgs) => {
      if (!navigator?.share) return

      try {
        await navigator.share({ text, title })
      } catch (err) {
        // "AbortErrors" occur when the "share" menu is open, but the user clicks away; we can ignore this one
        if (err.name !== 'AbortError') {
          onShareError(err)
        }
      }
    },
    [onShareError],
  )

  return {
    canShare: !!navigator?.share,
    share,
  }
}
