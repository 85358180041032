import styled from '@emotion/styled'
import { every, flatMap, pipe, prop } from 'lodash/fp'
import React from 'react'
import { useDispatch } from 'react-redux'

import { Alert, Button, Loader } from 'packages/common'
import { centerWithFlex, colors } from 'packages/styles'
import { createDateObject } from 'packages/utils/dateHelpers'
import { Events, track } from 'packages/wiretap'

import { Slugs, useI18n } from 'app/fieldapp/i18n'
import {
  toggleInspectionItem,
  updateClean,
} from 'app/fieldapp/store/cleans/actions'
import { AppDispatch } from 'app/fieldapp/store/store'
import {
  InspectionCategory,
  InspectionListItem,
} from 'app/fieldapp/store/tasks'
import { toggleIsCompletedString } from 'app/fieldapp/store/tasks/utils/tasks.utils'
import { useActiveUser } from 'app/fieldapp/utils/hooks/useActiveUser'

import { isViewingSharedClean } from '../../schedule.utils'
import { scrollToDrawerTop } from '../CleanDetail/CleanDetailDrawer/CleanDetailDrawer.utils'
import { CreateTicketDrawer } from '../common/CreateTicket/CreateTicketDrawer'
import { CompletedInspection } from './components/CompletedInspection'
import { InspectionCategory as InspectionCategoryComponent } from './components/InspectionCategory'
import { InspectionDisabledWarning } from './components/InspectionDisabledWarning'
import { InspectionListDetail } from './components/InspectionListDetail'
import { useInspectionContext } from './context/InspectionChecklist.context'

const allItemsComplete = pipe(
  flatMap(prop('items')),
  every(['isComplete', 'checked']),
)

const St = {
  Alert: styled(Alert)`
    border: none;
    margin-bottom: 0;
  `,
  ChecklistItem: styled.div`
    padding: 20px;

    &:not(:last-child) {
      border-bottom: 1px ${colors.midnight20} solid;
    }
  `,

  CleanNotStarted: styled.div`
    margin: auto;
    padding-top: 40%;
    text-align: center;
    width: 80%;
  `,

  CompleteButtonError: styled.div`
    ${centerWithFlex};
    color: ${colors.alert};
  `,

  CreateTicket: styled.div`
    font-size: 16px;
    padding: 20px;
  `,

  InspectionChecklist: styled.div<{ selectedCategoryIdx: number | null }>`
    background-color: white;
    overflow-y: scroll;
    // This keeps the last checklist item from being covered by timers
    padding-bottom: 64px;
    padding-top: 16px;
    position: relative;

    // hide completely when a category is selected
    display: ${({ selectedCategoryIdx }) =>
      selectedCategoryIdx !== null ? 'none' : 'inherit'};
  `,

  InspectionControls: styled.div`
    padding: 40px 12px 0 12px;
  `,

  InspectionCreateTicketDrawer: styled(CreateTicketDrawer)`
    padding: 16px 24px 16px 24px;
  `,

  LoaderWrapper: styled.div`
    height: 32px;
    position: relative;
    margin-top: 32px;
  `,
}

const useTranslations = () => {
  const { t } = useI18n()

  return {
    completeInspection: t(Slugs.inspectionCompleteInspection),
    completeInspectionError: t(Slugs.inspectionErrorCompleteInspection),
    createTicket: t(Slugs.createTicket),
    inspectionCleanNotStarted: t(Slugs.inspectionCleanNotStarted),
    inspectionNeedMoreHelp1: t(Slugs.inspectionNeedMoreHelp1),
    inspectionNeedMoreHelp2: t(Slugs.inspectionNeedMoreHelp2),
    inspectionStepThreeText: t(Slugs.inspectionStepThreeText),
    stepWithNumber: stepNumber => t(Slugs.stepWithNumber, { stepNumber }),
  }
}

export const InspectionChecklistTestIds = {
  completeBtn: 'InspectionChecklist__completeBtn',
  container: 'InspectionChecklist__container',
}

export type InspectionChecklistProps = {
  isLoadingClean: boolean
}

export const InspectionChecklist: React.FC<InspectionChecklistProps> =
  React.memo(({ isLoadingClean }) => {
    const dispatch: AppDispatch = useDispatch()

    const { delegateUserId, user } = useActiveUser()
    const { clean, uiDisabledState, uiState, uiStateDispatch } =
      useInspectionContext()

    const strings = useTranslations()

    const { inspectionChecklist } = clean
    const checklists = inspectionChecklist?.checklists || []
    const isInspectionCompletable =
      !!checklists.length && allItemsComplete(checklists)

    const hideAdminLinks = isViewingSharedClean()

    const [selectedCategoryIdx, setSelectedCategoryIdx] = React.useState<
      number | null
    >(null)

    const handleCategoryClick = React.useCallback((idx: number) => {
      setSelectedCategoryIdx(idx)
      scrollToDrawerTop()
    }, [])

    const handleBackClick = React.useCallback(() => {
      setSelectedCategoryIdx(null)
    }, [])

    const handleToggleItem = React.useCallback(
      async (category: InspectionCategory, item: InspectionListItem) => {
        uiStateDispatch({ payload: { id: item.id }, type: 'addLoadingItem' })

        /* eslint-disable @typescript-eslint/naming-convention */
        const trackingSuccessPayload = {
          category_id: category.id,
          clean_id: clean.id,
          is_complete: toggleIsCompletedString(item.isComplete),
          is_delegate: isViewingSharedClean(),
          item_id: item.id,
          user_id: user?.id || delegateUserId || 'unknown',
        }
        /* eslint-enable @typescript-eslint/naming-convention */

        try {
          await dispatch(
            toggleInspectionItem({
              category,
              cleanId: clean.id,
              item,
            }),
          )

          track(Events.fieldAppInspectionToggleItem, trackingSuccessPayload)
        } catch (err) {
          uiStateDispatch({ payload: { id: item.id }, type: 'addErrorItem' })

          track(Events.fieldAppInspectionToggleItemError, {
            ...trackingSuccessPayload,
            error: err?.message || 'Unknown Error',
          })
        } finally {
          uiStateDispatch({
            payload: { id: item.id },
            type: 'removeLoadingItem',
          })
        }
      },
      [clean.id, delegateUserId, dispatch, uiStateDispatch, user?.id],
    )

    const completeCleanInspection = React.useCallback(async () => {
      uiStateDispatch({
        payload: { error: false, loading: true },
        type: 'setCompleteButtonState',
      })

      const inspectionCompletedAt = createDateObject()

      /* eslint-disable @typescript-eslint/naming-convention */
      const trackingSuccessPayload = {
        clean_id: clean.id,
        inspection_completed_at: inspectionCompletedAt,
        is_delegate: isViewingSharedClean(),
        user_id: user?.id || delegateUserId || 'unknown',
      }
      /* eslint-enable @typescript-eslint/naming-convention */

      try {
        await dispatch(
          updateClean({
            id: clean.id,
            inspectionCompletedAt,
          }),
        )

        track(Events.fieldAppInspectionMarkComplete, trackingSuccessPayload)
      } catch (err) {
        uiStateDispatch({
          payload: { error: true },
          type: 'setCompleteButtonState',
        })

        track(Events.fieldAppInspectionMarkCompleteError, {
          ...trackingSuccessPayload,
          error: err?.message || 'Unknown Error',
        })
      } finally {
        uiStateDispatch({
          payload: { loading: false },
          type: 'setCompleteButtonState',
        })
      }
    }, [uiStateDispatch, clean.id, user?.id, delegateUserId, dispatch])

    if (!checklists.length) {
      return isLoadingClean ? (
        <St.LoaderWrapper>
          <Loader />
        </St.LoaderWrapper>
      ) : (
        <St.CleanNotStarted>
          {strings.inspectionCleanNotStarted}
        </St.CleanNotStarted>
      )
    }

    return !clean.inspectionCompletedAt ? (
      <>
        <InspectionDisabledWarning disabledState={uiDisabledState} />
        <St.InspectionChecklist
          data-testid={InspectionChecklistTestIds.container}
          selectedCategoryIdx={selectedCategoryIdx}
        >
          {checklists.map((checklist, idx) => (
            <InspectionCategoryComponent
              checklist={checklist}
              key={checklist.category.id}
              onClick={() => handleCategoryClick(idx)}
            />
          ))}

          <St.InspectionControls>
            {uiState.completeBtn.error && (
              <St.CompleteButtonError>
                {strings.completeInspectionError}
              </St.CompleteButtonError>
            )}

            <Button
              block={true}
              dataTestId={InspectionChecklistTestIds.completeBtn}
              disabled={!isInspectionCompletable || !!uiDisabledState}
              isLoading={uiState.completeBtn.loading}
              onClick={completeCleanInspection}
              buttonType={'primary'}
            >
              {strings.completeInspection}
            </Button>
          </St.InspectionControls>

          {!hideAdminLinks && (
            <St.InspectionCreateTicketDrawer
              taskId={clean.id}
              unit={clean.unit}
            />
          )}
        </St.InspectionChecklist>

        {selectedCategoryIdx !== null ? (
          <InspectionListDetail
            checklist={checklists[selectedCategoryIdx]}
            hideAdminLinks={hideAdminLinks}
            onBackClick={handleBackClick}
            onToggleItem={handleToggleItem}
          />
        ) : null}
      </>
    ) : (
      <CompletedInspection />
    )
  })
