import { createSelector } from 'reselect'

import { isToday } from 'packages/utils/dateHelpers'

import {
  OtherTimer,
  RawOtherTime,
} from 'app/fieldapp/store/otherTimers/otherTimers.types'
import { ApplicationState } from 'app/fieldapp/store/store'

import { getOtherTimeById } from './getOtherTimerById'

const otherTimerIsSubmittable = (rtt: RawOtherTime): boolean =>
  !!rtt.attributes.startedAt &&
  !!rtt.attributes.stoppedAt &&
  !rtt.attributes.isFinalized &&
  isToday(rtt.attributes.stoppedAt)

const getFullState = (state: ApplicationState): ApplicationState => state

/**
 * Finds and returns the first OtherTimer in a "submittable" state. This means that the timer
 * has been stopped but not finalized, and has no associated offline data currently saved.
 * These are effectively "rogue" timers that need to immediately be either submitted
 * or deleted, as we no longer have any UI concept for "paused" timers.
 */
export const getSubmittableOtherTimer = createSelector(
  getFullState,
  (fullState): OtherTimer | undefined => {
    const rawOtherTimers: RawOtherTime[] = Object.values(
      fullState.otherTimers.data,
    )
    const rawSubmittableOtherTime = rawOtherTimers.find(otherTimerIsSubmittable)
    if (!rawSubmittableOtherTime) return undefined

    // ignore any timers with associated offline data (these will show up in offline timers UI)
    const offlineTimer =
      fullState.otherTimers.offlineData[rawSubmittableOtherTime.id]
    if (offlineTimer) return undefined

    return getOtherTimeById(fullState, rawSubmittableOtherTime.id)
  },
)
