import styled from '@emotion/styled'
import React from 'react'

import { Alert, ExternalLink } from 'packages/common'
import { IconName, SvgIcon } from 'packages/iconic'
import { colors } from 'packages/styles'

import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { InspectionList } from 'app/fieldapp/store/tasks'

import { CreateTicketDrawer } from '../../../common/CreateTicket/CreateTicketDrawer'
import { useInspectionVisitWithRelationships } from '../../useInspectionVisitWithRelationships'
import { VisitInspectionCategoryPhotos } from '../VisitInspectionCategoryPhotos'
import { VisitInspectionChecklistItem } from '../VisitInspectionChecklistItem'
import { RequestStatus } from '../VisitInspectionImageUploader/hooks'

const iconSize = 24

const St = {
  Alert: styled(Alert)`
    border: none;
  `,

  CategoryTitle: styled.span`
    flex-grow: 1;
    font-weight: 700;
    margin-right: ${iconSize}px;
    text-align: center;
  `,

  CreateTicket: styled.div`
    font-size: 16px;
    padding: 20px;
  `,

  Error: styled.div`
    color: ${colors.alert};
  `,

  Header: styled.div`
    align-items: center;
    display: flex;
    justify-content: flex-start;
    padding: 20px;
  `,

  InspectionListDetail: styled.div`
    background: white;
    color: ${colors.midnight};
    padding-bottom: 128px;
    overflow-y: scroll;
  `,

  InspectionListDetailCreateTicketDrawer: styled(CreateTicketDrawer)`
    padding: 0px 24px 0px 24px;
  `,

  Step: styled.div`
    font-size: 15px;

    &:not(:last-of-type) {
      margin-bottom: 16px;
    }
  `,

  StepTitle: styled.div`
    padding: 20px;
  `,

  TicketLink: styled(ExternalLink)`
    padding: 0 4px;
    font-size: 16px;
  `,

  TicketLinkContainer: styled.div`
    padding: 20px;
  `,
}

const useTranslations = () => {
  const { t } = useI18n()

  return {
    createTicket: t(Slugs.createTicket),
    errorDelete: t(Slugs.inspectionPhotoDeleteError),
    errorUpload: t(Slugs.inspectionPhotoUploadError),
    inspectionNeedMoreHelp2: t(Slugs.inspectionNeedMoreHelp2),
    inspectionStepThreeText: t(Slugs.inspectionStepThreeText),
    seeAProblem: t(Slugs.seeAProblem),
    step1: t(Slugs.inspectionStepVisuallyCheck),
    step2: t(Slugs.inspectionStepUploadImages),
    stepWithNumber: stepNumber => t(Slugs.stepWithNumber, { stepNumber }),
  }
}

export const InspectionListDetailTestIds = {
  back: 'InspectionListDetail__back',
  container: 'InspectionListDetail__container',
}

export type VisitInspectionListDetailProps = {
  checklist: InspectionList | null
  hideAdminLinks: boolean
  onBackClick: () => void
}

export const VisitInspectionListDetail: React.FC<VisitInspectionListDetailProps> =
  React.memo(({ checklist, hideAdminLinks, onBackClick }) => {
    const strings = useTranslations()
    const items = checklist?.items || []

    const { unit, visit } = useInspectionVisitWithRelationships()

    const [hasDeleteError, setHasDeleteError] = React.useState(false)
    const [hasUploadError, setHasUploadError] = React.useState(false)

    const handleDeletePhotoStatus = React.useCallback(
      (newStatus: RequestStatus) => setHasDeleteError(newStatus === 'error'),
      [],
    )

    return checklist ? (
      <St.InspectionListDetail
        data-testid={InspectionListDetailTestIds.container}
      >
        <St.Header>
          <SvgIcon
            dataTestId={InspectionListDetailTestIds.back}
            height={14}
            icon={IconName.leftArrow}
            onClick={onBackClick}
            size={iconSize}
            width={18}
          />

          <St.CategoryTitle>{checklist.category.title}</St.CategoryTitle>
        </St.Header>

        <St.Step>
          <St.StepTitle>
            <strong>{strings.stepWithNumber(1)}: </strong>
            <span>{strings.step1}</span>
          </St.StepTitle>

          {items.map(item => (
            <VisitInspectionChecklistItem
              category={checklist.category}
              checklistItem={item}
              key={item.id}
            />
          ))}
        </St.Step>

        <St.Step>
          <St.StepTitle>
            <strong>{strings.stepWithNumber(2)}: </strong>
            <span>{strings.step2}</span>
            {hasDeleteError && <St.Error>{strings.errorDelete}</St.Error>}
            {hasUploadError && <St.Error>{strings.errorUpload}</St.Error>}
          </St.StepTitle>

          <VisitInspectionCategoryPhotos
            categoryId={checklist.category.id}
            onDeletePhotoStatusChange={handleDeletePhotoStatus}
            onUploadError={() => setHasUploadError(true)}
            onUploadSuccess={() => setHasUploadError(false)}
          />
        </St.Step>

        {!hideAdminLinks && (
          <>
            <St.CreateTicket>
              <strong>{strings.stepWithNumber(3)}: </strong>
              {strings.inspectionStepThreeText}
            </St.CreateTicket>
            <St.InspectionListDetailCreateTicketDrawer
              isDrawerSection={false}
              taskId={visit.id}
              unit={unit}
            />
          </>
        )}
      </St.InspectionListDetail>
    ) : null
  })
