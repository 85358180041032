import React from 'react'
import { useSelector } from 'react-redux'

import { getAuthToken } from 'app/fieldapp/store/auth/selectors'
import { Clean } from 'app/fieldapp/store/cleans'

import { ShareCleanButton } from './ShareCleanButton'

export type ShareCleanButtonContainerProps = {
  clean: Clean
}

export const ShareCleanButtonContainer: React.FC<ShareCleanButtonContainerProps> =
  React.memo(({ clean }) => {
    const idToken = useSelector(getAuthToken)

    if (!idToken) return null

    return <ShareCleanButton clean={clean} idToken={idToken} />
  })
