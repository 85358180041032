import { User } from 'packages/grimoire'
import { daysOfWeek } from 'packages/grimoire/src/utils/dates.utils'
import { getSystemTZ } from 'packages/utils/timezone.helpers'

import { CrossCoveragePostData } from 'app/fieldapp/store/crossCoverage/crossCoverage.types'

import { CrossCoverageFormState } from '../CrossCoverageForm/state/CrossCoverageForm.reducer'

export type FormValidationErrorCause = {
  endTime?: boolean
  selectedAssignee?: boolean
  startTime?: boolean
}

export class FormValidationError extends Error {
  constructor(message: string, cause?: FormValidationErrorCause) {
    super(message, { cause })
    Object.setPrototypeOf(this, FormValidationError.prototype)
    this.cause = cause
  }

  cause?: FormValidationErrorCause
}

export const makeCrossCoveragePayload = (
  // We MUST have a selectedAssignee to make a cross coverage`
  state: CrossCoverageFormState & { selectedAssignee: User },
  activeUserId: string,
): CrossCoveragePostData => {
  // If we have repeated CC,
  // explicitly set each day not included in the repeatDays to false so that
  // if days have been removed from the repeatDays, they are correctly set to false
  const crossCoverageAppliedDays = state.isRepeating
    ? daysOfWeek.reduce((memo, currentDay) => {
        return {
          ...memo,
          [currentDay]: !!state.repeatDays.includes(currentDay),
        }
      }, {})
    : {}

  const payload = {
    endDate: state.endDate,
    endTimeLocal: state.endTime.hourOfDay,
    isRepeating: Boolean(state.isRepeating),
    startDate: state.startDate,
    startTimeLocal: state.startTime.hourOfDay,
    tz: getSystemTZ(),
    userCoveredId: activeUserId,
    userCoveringId: state.selectedAssignee.id,
    ...crossCoverageAppliedDays,
  }

  return payload
}
