import { noop } from 'lodash/fp'
import React from 'react'

import { BooleanSelect, Button, Switch } from 'packages/common'
import { daysOfWeek } from 'packages/grimoire/src/utils'
import { IconName, SvgIcon } from 'packages/iconic'
import {
  addDays,
  DateFormat,
  format,
  startOfToday,
} from 'packages/utils/dateHelpers'

import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { useDayNameTranslationsShort } from 'app/fieldapp/utils/hooks/useDateTranslations'

import { StandardAvailabilityFormValues } from './StandardAvailabilityForm.container'
import * as St from './StandardAvailabilityForm.styles'

const useTranslations = () => {
  const { t } = useI18n()

  return {
    availabilityText: (key: boolean) => t(Slugs[key ? 'onCall' : 'off']),
    cancel: t(Slugs.cancel),
    currentAvailability: t(Slugs.currentAvailability),
    currentAvailabilityDesc: t(Slugs.currentAvailabilityDescription),
    deleteInfo: t(Slugs.standardAvailabilityDeleteInfo),
    fetchAvailabilityError: t(Slugs.fetchAvailabilityError),
    startDate: t(Slugs.startDate),
    submit: t(Slugs.submit),
    upcomingAvailability: t(Slugs.upcomingAvailability),
    upcomingAvailabilityDesc: t(Slugs.upcomingAvailabilityDescription),
  }
}

const DaysOfWeekBooleanSelects = ({
  dataTestId,
  isDisabled,
  onChange,
  values,
}: {
  dataTestId: string
  isDisabled: boolean
  onChange:
    | ((
        section: keyof StandardAvailabilityFormValues,
      ) => (val: boolean) => void)
    | undefined
  values?: StandardAvailabilityFormValues | null
}) => {
  const dayStrings = useDayNameTranslationsShort()
  const strings = useTranslations()
  const curriedNoop = () => noop

  const handleOnChange = onChange ?? curriedNoop

  return (
    <St.Section data-testid={dataTestId}>
      {daysOfWeek.map(key => {
        return (
          <St.LabelInputContainer key={key}>
            <St.Label htmlFor={key}>{dayStrings[key]}</St.Label>
            <BooleanSelect
              disabled={isDisabled}
              fullWidth={true}
              id={key}
              onChange={handleOnChange(key)}
              options={[true, false]}
              translateValue={strings.availabilityText}
              value={values?.[key] ?? null}
            />
          </St.LabelInputContainer>
        )
      })}
    </St.Section>
  )
}

export enum StandardAvailabilityFormTestIds {
  cancel = 'StandardAvailabilityForm__cancel',
  currentAvailabilityContainer = 'StandardAvailabilityForm__currentAvailabilityContainer',
  submit = 'StandardAvailabilityForm__submit',
  toggleUpcomingAvailability = 'StandardAvailabilityForm__toggleUpcomingAvailability',
  upcomingAvailabilityContainer = 'StandardAvailabilityForm__upcomingAvailabilityContainer',
}

export type StandardAvailabilityFormProps = {
  closeDrawer: () => void
  error?: Error | string
  formState: {
    currentValues: StandardAvailabilityFormValues | null
    isUpcomingSwitch: boolean
    upcomingValues: StandardAvailabilityFormValues | null
  }
  handlers: {
    onCurrentSelectChange?: (
      section: keyof StandardAvailabilityFormValues,
    ) => (val: boolean) => void
    onSwitchChange: (val: boolean) => void
    onUpcomingSelectChange?: (
      section: keyof StandardAvailabilityFormValues,
    ) => (val: boolean) => void
    submit: React.FormEventHandler<Element>
  }
  isLoading: boolean
  isSubmitDisabled: boolean
  shouldDeleteUpcoming?: boolean
  showDatePickerModal: () => void
}

export const StandardAvailabilityForm: React.FC<StandardAvailabilityFormProps> =
  React.memo(
    ({
      closeDrawer,
      error,
      formState,
      handlers,
      isLoading,
      isSubmitDisabled,
      shouldDeleteUpcoming,
      showDatePickerModal,
    }) => {
      const strings = useTranslations()
      const {
        onCurrentSelectChange,
        onSwitchChange,
        onUpcomingSelectChange,
        submit,
      } = handlers

      const curriedNoop = () => noop

      // if the handlers are not defined, we want call noop if the form changes, and need a curried function to do that
      const setCurrentValue = onCurrentSelectChange ?? curriedNoop
      const setUpcomingValues = onUpcomingSelectChange ?? curriedNoop

      const TOMORROW = addDays(startOfToday(), 1)
      const startDate = format(
        formState?.upcomingValues?.startDate
          ? formState.upcomingValues.startDate
          : TOMORROW,
        DateFormat.SlashesWithShortYear,
      )

      return (
        <form onSubmit={submit}>
          {error && (
            <St.FetchAlert alertType={'danger'}>
              {strings.fetchAvailabilityError}
            </St.FetchAlert>
          )}

          {/* Current Availability */}
          <St.Section>
            <St.Title>{strings.currentAvailability}</St.Title>
            <St.SubTitle>{strings.currentAvailabilityDesc}</St.SubTitle>
          </St.Section>

          <DaysOfWeekBooleanSelects
            dataTestId={
              StandardAvailabilityFormTestIds.currentAvailabilityContainer
            }
            onChange={setCurrentValue}
            isDisabled={!!error || isLoading}
            values={formState?.currentValues}
          />

          {/* Upcoming Availability */}
          <St.Section>
            <St.SwitchContainer>
              <St.Title>{strings.upcomingAvailability}</St.Title>
              <Switch
                checked={formState.isUpcomingSwitch}
                dataTestId={
                  StandardAvailabilityFormTestIds.toggleUpcomingAvailability
                }
                disabled={!!error || isLoading}
                id="upcomingAvailability"
                onToggle={onSwitchChange}
              />
            </St.SwitchContainer>
            <St.SubTitle>{strings.upcomingAvailabilityDesc}</St.SubTitle>
          </St.Section>
          {formState.isUpcomingSwitch && (
            <>
              <St.Section>
                <St.Label>
                  {strings.startDate}
                  <St.Asterisk>*</St.Asterisk>
                </St.Label>
                <St.StartDateButton
                  block={true}
                  buttonType={'utility'}
                  disabled={!!error || isLoading}
                  onClick={showDatePickerModal}
                >
                  <St.StartDateButtonContentContainer>
                    <St.StartDate>{startDate}</St.StartDate>
                    <SvgIcon icon={IconName.calendar} size={24} />
                  </St.StartDateButtonContentContainer>
                </St.StartDateButton>
              </St.Section>

              <DaysOfWeekBooleanSelects
                dataTestId={
                  StandardAvailabilityFormTestIds.upcomingAvailabilityContainer
                }
                onChange={setUpcomingValues}
                isDisabled={!!error || isLoading}
                values={formState?.upcomingValues}
              />
            </>
          )}

          {shouldDeleteUpcoming && (
            <St.Section>
              <St.DeleteAlert alertType={'info'}>
                {strings.deleteInfo}
              </St.DeleteAlert>
            </St.Section>
          )}

          <St.Section>
            <St.ButtonContainer>
              <Button
                block={true}
                buttonType={'utility'}
                dataTestId={StandardAvailabilityFormTestIds.cancel}
                onClick={closeDrawer}
              >
                {strings.cancel}
              </Button>
              <Button
                block={true}
                buttonType={'primary'}
                dataTestId={StandardAvailabilityFormTestIds.submit}
                disabled={isSubmitDisabled}
                isFormSubmit={true}
                isLoading={isLoading}
              >
                {strings.submit}
              </Button>
            </St.ButtonContainer>
          </St.Section>
        </form>
      )
    },
  )
