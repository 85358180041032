import styled from '@emotion/styled'
import { noop } from 'lodash/fp'
import React from 'react'

import {
  BannerTestIds,
  DrawerContainerProps,
  LIGHTBOX_CLASS_SELECTOR,
} from 'packages/common'
import { Drawer } from 'packages/common'

import { CREATE_TICKET_DRAWER_ID } from 'app/fieldapp/components/schedule/components/common'
import { TEST_ID_TIMERS } from 'app/fieldapp/components/timers'
import { Clean } from 'app/fieldapp/store/cleans'

import { InspectionChecklistProps } from '../../InspectionChecklist/InspectionChecklist'
import { CleanDetailProps } from '../CleanDetail'
import { CleanDetailWrapper } from '../CleanDetailWrapper'
import { CleanDetailDrawerId } from './CleanDetailDrawer.utils'

const St = {
  Drawer: styled(Drawer)`
    max-width: 480px;
    min-width: min(360px, 100%);

    & > button:first-of-type {
      // ensure the absolute-position button stays on top of relative-positioned tab content
      z-index: 2;
    }
  `,
}

const ignoreClickEventsOn: DrawerContainerProps['ignoreClickEventsOn'] = [
  `#${TEST_ID_TIMERS}`,
  LIGHTBOX_CLASS_SELECTOR,
  `#${BannerTestIds.container}`,
  `#${CREATE_TICKET_DRAWER_ID}`,
  `#${CREATE_TICKET_DRAWER_ID}__backdrop`,
]

type PickedDrawerProps = Pick<
  DrawerContainerProps,
  'afterExit' | 'afterOpen' | 'isOpen'
>
type PickedCleanDetailProps = Pick<CleanDetailProps, 'lockbox' | 'smartLock'>

type PickedInspectionChecklistProps = Pick<
  InspectionChecklistProps,
  'isLoadingClean'
>

export type CleanDetailDrawerProps = PickedDrawerProps &
  PickedCleanDetailProps &
  PickedInspectionChecklistProps & {
    clean: Clean | undefined
    isLoadingClean: boolean
  }

export const CleanDetailDrawer: React.FC<CleanDetailDrawerProps> = React.memo(
  ({
    afterExit = noop,
    afterOpen = noop,
    clean,
    isLoadingClean,
    isOpen,
    lockbox,
    smartLock,
  }) => {
    const handleAfterExit = React.useCallback(() => {
      setIsDrawerForceClosed(false)
      afterExit()
    }, [afterExit])

    const [isDrawerForceClosed, setIsDrawerForceClosed] = React.useState(false)
    const forceCloseDrawer = () => setIsDrawerForceClosed(true)

    if (!clean) return null

    return (
      <St.Drawer
        afterExit={handleAfterExit}
        afterOpen={afterOpen}
        forceClose={isDrawerForceClosed}
        hideCloseButton={true}
        id={CleanDetailDrawerId}
        data-testid={CleanDetailDrawerId}
        ignoreClickEventsOn={ignoreClickEventsOn}
        isOpen={isOpen}
      >
        <CleanDetailWrapper
          clean={clean}
          isLoadingClean={isLoadingClean}
          onCloseDrawerClick={forceCloseDrawer}
          lockbox={lockbox}
          smartLock={smartLock}
        />
      </St.Drawer>
    )
  },
)
