import styled from '@emotion/styled'
import React from 'react'

import { Button, Drawer, UseManageDrawerState } from 'packages/common'
import { centerWithFlex, colors, text } from 'packages/styles'
import { AsyncState } from 'packages/utils/hooks'

import { Slugs, useI18n } from 'app/fieldapp/i18n'

import { StandardAvailabilityFormContainer } from '../StandardAvailabilityForm'

const St = {
  Drawer: styled(Drawer)`
    width: min(420px, 100vw);
  `,
  DrawerContentsContainer: styled.div`
    padding-bottom: 96px;
  `,
  Header: styled.div`
    ${centerWithFlex};
    ${text.bodyBoldSmall};
    background: ${colors.midnight10};
    color: ${colors.midnight};
    height: 90px;
  `,
}

const useTranslations = () => {
  const { t } = useI18n()

  return {
    editStandardAvailability: t(Slugs.editStandardAvailability),
    myStandardAvailability: t(Slugs.myStandardAvailability),
  }
}

export type StandardAvailabilityDrawerProps = {
  drawerManager: UseManageDrawerState
  initialFetchRequestState: AsyncState<unknown>
}

export const StandardAvailabilityDrawer: React.FC<StandardAvailabilityDrawerProps> =
  React.memo(({ drawerManager, initialFetchRequestState }) => {
    const strings = useTranslations()

    return (
      <>
        <Button
          block={true}
          buttonType={'utility'}
          isLoading={initialFetchRequestState.loading}
          onClick={drawerManager.openDrawer}
        >
          {strings.editStandardAvailability}
        </Button>
        <St.Drawer
          afterExit={drawerManager.completeDrawerClose}
          forceClose={drawerManager.drawerState.forceClose}
          isOpen={drawerManager.drawerState.isOpen}
        >
          <St.DrawerContentsContainer>
            <St.Header>{strings.myStandardAvailability}</St.Header>

            <StandardAvailabilityFormContainer
              closeDrawer={drawerManager.closeDrawer}
              initialFetchRequestState={initialFetchRequestState}
            />
          </St.DrawerContentsContainer>
        </St.Drawer>
      </>
    )
  })
