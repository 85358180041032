import { createSelector } from 'reselect'

import { ApplicationState } from '../../store'
import { RawUnit, UnitsState } from '../../units'
import { Lockbox, LockboxesState, RawLockbox } from '../lockboxes.types'
import { hydrateRawLockbox } from '../lockboxes.utils'

const getLockboxesState = (state: ApplicationState): LockboxesState =>
  state.lockboxes
const getUnitsState = (state: ApplicationState): UnitsState => state.units
const getUnitId = (_, id: string) => id

/**
 * Returns a lockbox based on the provided Unit ID relationship.
 *
 * This selector starts at the unit and finds the Lockbox ID in that relationship,
 * vs the other which starts at the lockbox and looks up the Unit ID there.
 *
 * A couple reasons this is necessary:
 * - When a lock in included in a /cleans request, rather than fetched directly, it does not include
 *      the unit ID in its own data, so we literally cannot look it up from that direction.
 * - This path is slightly more efficient, since we can look up the Unit directly,
 *      rather than iterating over all lockboxes until we find a match.
 */
export const getIncludedLockboxByUnitId = createSelector(
  getLockboxesState,
  getUnitsState,
  getUnitId,
  (lockboxesState, unitsState, unitId): Lockbox | undefined => {
    const rawUnit: RawUnit = unitsState.data[unitId]
    if (!rawUnit) return undefined

    const lockboxId = rawUnit.relationships?.lockBox?.data?.id || ''
    const rawLockbox: RawLockbox = lockboxesState.data[lockboxId]

    return rawLockbox ? hydrateRawLockbox(rawLockbox) : undefined
  },
)
