import classNames from 'classnames'
import React from 'react'

import styles from './NotificationBar.module.scss'

export const notificationBarTypeValues = ['default', 'lake'] as const
export type NotificationBarTypes = (typeof notificationBarTypeValues)[number]

export type NotificationBarProps = {
  className?: string
  onClick?: () => void
  type?: NotificationBarTypes
}

export const NotificationBar: React.FunctionComponent<
  React.PropsWithChildren<NotificationBarProps>
> = React.memo(({ children, className, onClick, type = 'default' }) => (
  <div
    className={classNames(styles[type], styles.bar, className)}
    onClick={onClick}
  >
    {children}
  </div>
))
