import styled from '@emotion/styled'

import { ExternalLink } from 'packages/common'
import { SvgIcon } from 'packages/iconic'
import { centerWithFlex, colors } from 'packages/styles'

const headerHeight = '48px'

export const ArrowWrapper = styled.div`
  ${centerWithFlex};
  height: 44px;
  width: 44px;
`

export const BackArrow = styled(SvgIcon)`
  color: white;
`

export const OtherTimersContainer = styled.div<{ height: number }>`
  background: ${colors.midnight};
  border-radius: 24px 24px 0 0;
  color: white;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: height 300ms ease;

  height: ${({ height }) => height}px;
`

export const OtherTimersHeader = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  font-weight: bold;
  height: ${headerHeight};
  justify-content: center;
  position: relative;
  user-select: none;
`

export const ChevronIcon = styled(SvgIcon)<{ isPointedUp: boolean }>`
  position: absolute;
  right: 32px;
  transition: transform 300ms ease;

  transform: ${({ isPointedUp }) =>
    isPointedUp ? `rotate(90deg)` : `rotate(-90deg)`};
`
export const SubMenuTitleContainer = styled.div`
  align-items: center;
  display: flex;
  padding-left: 20px;
  margin-top: 32px;
`
export const SubMenuTitle = styled.div`
  flex-grow: 1;
  font-size: 20px;
  font-weight: 700;
  padding-right: 44px;
  text-align: center;
`

export const CreateATicketContainer = styled.div`
  align-items: flex-end;
  display: flex;
  flex-grow: 1;
  overflow: auto;
`
/**
 * This is necessary to keep the padding that would typically be in
 *  "CreateATicketContainer" from impacting the state of the closed.
 */
export const CreateATicketPaddingWrapper = styled.div`
  padding: 0 0 60px 16px;
`

export const CreateATicket = styled(ExternalLink)`
  color: ${colors.lake40};
  font-size: 16px;
  padding-left: 8px;
`
