import styled from '@emotion/styled'
import React from 'react'

import { Alert, Button } from 'packages/common'
import { TicketStatus } from 'packages/grimoire/src/ticket'
import { AsyncState } from 'packages/utils/hooks'

import { Slugs, useI18n } from 'app/fieldapp/i18n'

import { TicketVisitStatus } from '../VisitTicketDrawer.helpers'

const renderableStatuses: TicketVisitStatus[] = [
  'assignedAndAttached',
  'onlyAssigned',
  'neitherAssignedNorAttached',
]

const St = {
  Alert: styled(Alert)`
    margin-bottom: 0;
  `,
  VisitTicketStatusInfo: styled.div`
    margin-top: 12px;
  `,
}

const useTranslations = (ticketStatus: TicketStatus) => {
  const { ut } = useI18n()

  return {
    buttonText:
      ticketStatus === TicketStatus.RESOLVED_COMPLETED
        ? ut(Slugs.completed)
        : ut(Slugs.ticketStatusActionComplete),
    visitTicketAttach: ut(Slugs.visitTicketAttach),
    visitTicketReassign: ut(Slugs.visitTicketReassign),
  }
}

export type VisitTicketStatusInfoProps = {
  completeTicketRequestState: AsyncState<unknown>
  onCompleteTicketClick: () => void
  status: TicketVisitStatus
  ticketStatus: TicketStatus
}

export const VisitTicketStatusInfo: React.FC<VisitTicketStatusInfoProps> =
  React.memo(
    ({
      completeTicketRequestState,
      onCompleteTicketClick,
      status,
      ticketStatus,
    }) => {
      const strings = useTranslations(ticketStatus)
      const hasRenderableStatus = renderableStatuses.includes(status)

      return hasRenderableStatus ? (
        <St.VisitTicketStatusInfo>
          {status === 'neitherAssignedNorAttached' && (
            <St.Alert alertType={'info'}>
              {strings.visitTicketReassign}
            </St.Alert>
          )}

          {status === 'onlyAssigned' && (
            <St.Alert alertType={'info'}>{strings.visitTicketAttach}</St.Alert>
          )}

          {status === 'assignedAndAttached' && (
            <Button
              block={true}
              buttonType={'utility'}
              disabled={ticketStatus === TicketStatus.RESOLVED_COMPLETED}
              isLoading={completeTicketRequestState.loading}
              onClick={onCompleteTicketClick}
            >
              {strings.buttonText}
            </Button>
          )}
        </St.VisitTicketStatusInfo>
      ) : null
    },
  )
